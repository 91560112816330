import React, { Fragment, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { setPaymentInfo } from "redux/actions/actionTypes";
import PropTypes from "prop-types";

import { startSubscriptionAPI, subscriptionContactSalesAPI } from "apis/controllers/SubscriptionController";

import chevronAsset from "../../assets/icons/common/chevron.svg";

import Alert, { SUCCESS, ERROR } from "templates/Alert";
import Card from "templates/cards/Card";
import Modal from "templates/Modal";
import SubscriptionCard from "templates/cards/SubscriptionCard";
import SignUpModal from "templates/customModals/SignUpModal";
import LoginModal from "templates/customModals/LoginModal";

import messageMap from "Utilities/MessageMaps";
import { convertArrayToObject } from "Utilities/ObjectUtility";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";
import {
  EXER_INSTITUTE_BRINGS_YOU, FEATURE_MATRIX_ROW_LABELS, FEATURE_MATRIX_ROW_VALUES,
  importSubscriptionIcons, SUBSCRIPTION_CARD_ATTRIBUTES,
  TIERS, contactSalesInputsSchema
} from "./SubscriptionUtilities";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";


function Subscription(props) {

  // History and state.
  const history = useHistory(),
    [displayFeatureMatrix, setDisplayFeatureMatrix] = useState(true),
    [areTiersLoaded, setAreTiersLoaded] = useState(false),
    [signUpModal, setSignUpModal] = useState(null),
    [loginModal, setLoginModal] = useState(null),
    [contactSalesModal, setContactSalesModal] = useState(null),
    [alert, setAlert] = useState(null);
  const cardProps = useRef({});
  // Identity Handling
  const ownerId = props.account.ownerId;
  const validIdentity = isValidIdentity(ownerId);
  // Other Constants
  const i18nSubscriptionPrefixes = convertArrayToObject(["cards", "featureMatrix", "exerInstituteBringsYou"]);
  const tiers = Object.keys(TIERS);
  const redirectLocationAfterFreeButtonIsClicked = "/";
  const INCLUDE_RIGHT_BORDER = "include-right-border";

  useEffect(
    () => {
      tiers.forEach(
        tier => {
          cardProps.current[tier] = {};
          SUBSCRIPTION_CARD_ATTRIBUTES.forEach(attribute => {
            cardProps.current[tier][attribute] = getSubscriptionMessage(`${i18nSubscriptionPrefixes.cards}.${tier}.${attribute}`);
          });
        }
      );
      setAreTiersLoaded(true);
    },
    []
  );

  function getSubscriptionMessage(key) {
    return messageMap(`subscription.${key}`, "generic");
  }

  function toggleFullFeatureMatrixChevron() {
    setDisplayFeatureMatrix(!displayFeatureMatrix);
  }

  function setTableCellClassName(cellCol) {
    //* Determines whether there should be a vertically dividing line (via className "include-right-border") */
    return cellCol < 2 ? INCLUDE_RIGHT_BORDER : "";
  }

  function describeWhatExerBringsYou(descriptionList) {
    return <ul className="describe-what-exer-brings-you-list">
      {descriptionList.map(descriptionBulletPoint => {
        return (
          <li key={descriptionBulletPoint}>
            {descriptionBulletPoint}
          </li>
        );
      })}
    </ul>
  }

  function openAnotherModal(type) {
    if (type === "login") {
      setLoginModal(
        <LoginModal closeHandler={closeLoginModal} submitHandler={closeLoginModal} />
      );
    }
  }

  function closeSignUpModal(e) {
    if (e == null || (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className))) {
      hideModal("signUp");
    }
  }

  function closeLoginModal(e) {
    if (e == null || (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className))) {
      hideModal("login");
    }
  }

  function hideModal(modalType) {
		const modalMap = {
			login: setLoginModal,
			signUp: setSignUpModal,
      sales: setContactSalesModal
		};

		setTimeout(() => {
			modalMap[modalType](null);
		}, MODAL_CLOSE_TIMEOUT);
	}

  function closeResponse(e) {
    if (["icon", "modal-block", "close-button"].includes(e.target.className) || e.target.type === "submit") {
      hideModal("sales");
    }
  }

  function startSubscriptionWrapper(tier) {
    let startSubscription = {};

    /* 
    1. Open Enterprise Contact Sales Modal
    2. Ask user to sign-up or login, in that order.
    3. Start Subscription
    */
    if (tier === TIERS.enterprise) {
      startSubscription = () => {
        setContactSalesModal(
          <Modal closeType="xButton" closeHandler={closeResponse} submitText="Submit" submitHandler={submitContactSalesForm}
            title={messageMap("subscription.contactSales.title", "generic")} {...contactSalesInputsSchema}>
          </Modal>
        )
      }
    }
    else if (!validIdentity) {
      startSubscription = () => {
        setSignUpModal(
          // TODO: use Redux to have NavBar handle this instead
          <SignUpModal title={messageMap("account.signUp.signUpFirst", "generic")}
            closeModal={closeSignUpModal}
            redirectLocationAfterSignUp={redirectLocationAfterFreeButtonIsClicked}
            subHeader={(
              <Fragment>
                <div>
                  {messageMap("account.signUp.existingAccount", "generic")}
                  <button onClick={() => openAnotherModal("login", 1)}
                    style={{ border: "none", color: "#007bff", backgroundColor: "white" }} tabIndex="0">
                    {messageMap("links.login", "button")}
                  </button>
                </div>
              </Fragment>
            )} />
        );
      }
    }
    else if (tier === TIERS.free) {
      startSubscription = () => {
        history.push(redirectLocationAfterFreeButtonIsClicked);
      }
    }
    else {
      startSubscription = () => {
        startSubscriptionAPI(
          // Payload
          {
            ownerId: ownerId,
            subscriptionPlan: TIERS.pro,
            billingProcessor: "stripe"
          },
          // startSubscription
          (resp) => {
            props.setPaymentInfo({
              imgRef: 4,
              price: 8.99,
              quantity: 1,
              description: messageMap("payments.checkout.proSubscriptionPlan", "generic"),
              purpose: "subscription"
            });
            history.push({
              pathname: "/checkout",
              state: {
                stripePubKey: resp.stripePubKey,
                clientSecret: resp.stripePaymentSessionClientSecret
              }
            });
          }
        );
      }
    }

    if (startSubscription) {
      return {
        onClick: () => startSubscription()
      }
    }
  }

  function submitContactSalesForm(form) {

    const formattedFormValues = Object.fromEntries(
      Object.entries(form)
        .filter(([_, field]) => field && typeof field === 'object' && 'value' in field)
        .map(([key, field]) => [key, field.value])
    );

    const payload = {
      ownerId: ownerId,
      billingDetails: formattedFormValues
    };

    subscriptionContactSalesAPI(payload, (resp) => {
      const alertType = resp.includes("success") ? SUCCESS : ERROR;
      setAlert(
        <Alert closeHandler={() => setAlert(null)} type={alertType} msg={messageMap(resp, "api")} />
      );
    });
  }

  return (
    <div className="subscription-container">

      <Helmet>
        <title>{messageMap("subscription.title", "headerTag")}</title>
        <meta name="description" content={messageMap("subscription.description", "headerTag")}></meta>
      </Helmet>

      {alert}

      <h1 className="title">{getSubscriptionMessage("title.lineOne")}
        <br />
        {getSubscriptionMessage("title.lineTwo")}
      </h1>

      <div className="subscription-cards-subcontainer">
        {areTiersLoaded === true ?
          tiers.map(
            tier => <SubscriptionCard key={tier} buttonProps={startSubscriptionWrapper(tier)} {...cardProps.current[tier]} />
          )
          :
          null
        }
      </div>

      <div className="feature-matrix-button-container">
        {getSubscriptionMessage(`${i18nSubscriptionPrefixes.featureMatrix}.comparePlans`)}
        <button onClick={toggleFullFeatureMatrixChevron} className="chevron-button">
          <img className={displayFeatureMatrix ? "chevron-up" : "chevron-down"} src={chevronAsset}
            alt={messageMap("chevron.text", "image")}
          />
        </button>
      </div>

      <div className={displayFeatureMatrix ? "feature-matrix-container" : "hidden"}>
        <table className="feature-matrix-table">
          <thead className="feature-matrix-thead">
            <tr className="feature-matrix-row">
              <th className={`feature-matrix-label-col feature-matrix-top-row ${INCLUDE_RIGHT_BORDER}`}>
                {getSubscriptionMessage(`${i18nSubscriptionPrefixes.featureMatrix}.keyFeatures`)}
              </th>
              {tiers.map(
                (tier, tierCol) =>
                  <th key={tier} className={`${setTableCellClassName(tierCol)} feature-matrix-top-row`}>
                    {getSubscriptionMessage(`${i18nSubscriptionPrefixes.cards}.${tier}.title`)}
                  </th>
              )}
            </tr>
            <tr>
              <th className={`feature-matrix-header ${INCLUDE_RIGHT_BORDER}`}></th>
              {tiers.map(
                (tier, tierCol) =>
                  <th key={tier} className={`feature-matrix-header ${setTableCellClassName(tierCol)} feature-matrix-second-row`}>
                    <button className="feature-matrix-table-button" {...startSubscriptionWrapper(tier)}>
                      {messageMap(`subscription.${tier}`, "button")}
                    </button>
                  </th>
              )}
            </tr>
          </thead>
          <tbody>
            {FEATURE_MATRIX_ROW_LABELS.map(
              (label, labelRow) => {
                return (
                  <tr key={`${label}`} className="feature-matrix-row">
                    <td key={`${label}-${labelRow}`} className={`feature-matrix-cell feature-matrix-label-col ${INCLUDE_RIGHT_BORDER}`}>
                      {getSubscriptionMessage(`${i18nSubscriptionPrefixes.featureMatrix}.rowLabels.${label}`)}
                    </td>
                    {
                      tiers.map(
                        (tier, tierCol) => {
                          let rowValue = FEATURE_MATRIX_ROW_VALUES[tier][label];
                          return (
                            <td key={`${tier}-${label}`} className={`feature-matrix-cell ${setTableCellClassName(tierCol)}`}>
                              {rowValue &&
                                getSubscriptionMessage(`${i18nSubscriptionPrefixes.featureMatrix}.rowValues.${rowValue}`)
                              }
                            </td>
                          );
                        }
                      )
                    }
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>

      <h1 className="title mid-page-title">
        {getSubscriptionMessage("title.exerInstituteBringsYou")}
      </h1>

      <div className="exer-brings-you-grid">
        {EXER_INSTITUTE_BRINGS_YOU.map(
          category => {
            return (
              <Card key={category} {...importSubscriptionIcons(category)} href=""
                title={getSubscriptionMessage(`${i18nSubscriptionPrefixes.exerInstituteBringsYou}.${category}.title`)}
                description={describeWhatExerBringsYou(getSubscriptionMessage(`${i18nSubscriptionPrefixes.exerInstituteBringsYou}.${category}.description`))} />
            );
          }
        )}
      </div>

      {signUpModal}
      {loginModal}
      {contactSalesModal}

    </div>
  );
}

Subscription.propTypes = {
  // redux props
  account: PropTypes.object.isRequired,
  setPaymentInfo: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { account, payments } = state;
  return { account, payments };
}


export default connect(
  mapStateToProps,
  { setPaymentInfo }
)(Subscription);
