import React, { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";

import Alert, { ERROR } from "templates/Alert";

import messageMap from "Utilities/MessageMaps";
import { checkHasExplicitWords } from "Utilities/Validators/ContentValidator";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";


function EditTwoFieldValuesModalContent(props) {

	const [newFirstValue, setNewFirstValue] = useState(props.fieldOneValue),
		[newSecondValue, setNewSecondValue] = useState(props.fieldTwoValue),
		[isNewFirstValueValid, setIsNewFirstValueValid] = useState(true),
		[isNewSecondValueValid, setIsNewSecondValueValid] = useState(true);

	const updateFieldsButtonRef = useRef();

	const onChangeMap = {
		[props.fieldOneKey]: setNewFirstValue,
		[props.fieldTwoKey]: setNewSecondValue
	};

	const isValidMap = {
		[props.fieldOneKey]: isNewFirstValueValid,
		[props.fieldTwoKey]: isNewSecondValueValid
	};
	const isValidSetterMap = {
		[props.fieldOneKey]: setIsNewFirstValueValid,
		[props.fieldTwoKey]: setIsNewSecondValueValid
	};

	function onChangeField(e, changeType) {
		const target = e.target,
			text = target.value;

		onChangeMap[changeType](text);

		if (text === "") {
			onChangeMap[changeType](null);
			target.className = "page-field-input";
			return;
		}

		let isFirstValid = isValidMap[props.fieldOneKey],
			isLastValid = isValidMap[props.fieldTwoKey];

		if (changeType === props.fieldOneKey) {
			if (!checkHasExplicitWords(text)) {
				isValidSetterMap[changeType](true);
				isFirstValid = true;
				target.className = "page-field-input";
			}
			else {
				isValidSetterMap[changeType](false);
				isFirstValid = false;
				target.className = "page-field-input warning";
			}
		}
		else if (changeType === props.fieldTwoKey) {
			if (!checkHasExplicitWords(text)) {
				isValidSetterMap[changeType](true);
				isLastValid = true;
				target.className = "page-field-input";
			}
			else {
				isValidSetterMap[changeType](false);
				isLastValid = false;
				target.className = "page-field-input warning";
			}
		}

		updateFieldsButtonRef.current.className = isFirstValid && isLastValid ? "update-two-fields" : "update-two-fields not-allowed";
	}

	function checkBeforeUpdatingForm() {
		if (newFirstValue === props.fieldOneValue) {
			props.setAlert(
				<Alert closeHandler={closeAlert} type={ERROR} msg={messageMap(`account.${props.fieldOneKey}.sameAsOld`, "validation")} />
			);
		}
		else if (newSecondValue === props.fieldTwoValue) {
			props.setAlert(
				<Alert closeHandler={closeAlert} type={ERROR} msg={messageMap(`account.${props.fieldTwoKey}.sameAsOld`, "validation")} />
			);
		}
		else if (isNewFirstValueValid && isNewSecondValueValid && updateFieldsButtonRef.current.className === "update-two-fields") {
			props.updateField(props.fieldOneKey, newFirstValue);
			props.updateField(props.fieldTwoKey, newSecondValue);

			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	return (
		<Fragment>
			<div className="field-generic">
				{messageMap(`account.fields.modal.${props.newLabelKey1}`, "generic")}
			</div>
			<input placeholder={props.fieldOneValue} className="page-field-input"
				maxLength={50}
				onChange={e => onChangeField(e, props.fieldOneKey)} >
			</input>

			<div className="field-generic">
				{messageMap(`account.fields.modal.${props.newLabelKey2}`, "generic")}
			</div>
			<input placeholder={props.fieldTwoValue} className="page-field-input"
				maxLength={50}
				onChange={e => onChangeField(e, props.fieldTwoKey)} >
			</input>

			<button className="update-two-fields not-allowed"
				onClick={checkBeforeUpdatingForm} ref={updateFieldsButtonRef} >
				{messageMap(`account.${props.updateButtonKey}`, "button")}
			</button>
		</Fragment>
	);
}

EditTwoFieldValuesModalContent.propTypes = {
	setModal: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,

	fieldOneValue: PropTypes.string,
	fieldTwoValue: PropTypes.string,
	fieldOneKey: PropTypes.string,
	fieldTwoKey: PropTypes.string,

	newLabelKey1: PropTypes.string,
	newLabelKey2: PropTypes.string,
	updateButtonKey: PropTypes.string.isRequired,
	updateField: PropTypes.func.isRequired
};

export default EditTwoFieldValuesModalContent;
