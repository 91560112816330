// Reference if you need to implement or extend some algos: https://www.bigocheatsheet.com/
import {helpingVerbs, possessivePronouns, prepositions, misc} from "Utilities/Constants/TokenFilters";


/**
 * @description can handle array and object. Expects sorted list, just fyi.
 * @param {Object or Array} arrObj
 * array: like any other binary search
 * object: {
 * 	keys: [], // array of keys to find a match
 * 	values: [] // if found match, returns corresponding value with the same index as in key []
 * }
 * @param {Number} lowIndex lower boundary for index search
 * @param {Number} highIndex upper boundary for index search
 * @param {String/Number} matchingEl element to find match for
 * @param {String} elKey? optional object's key string
 * @returns returns values[index] if found 	match, else null
 */
function binarySearch(arrObj, lowIndex, highIndex, matchingEl, elKey= null) {
	let keys = arrObj,
			values = arrObj,
			matchValue = null;
	if (!Array.isArray(arrObj)) {
		keys = arrObj.keys;
		values = arrObj.values;
	}

	while (lowIndex <= highIndex) {
		const index = Math.floor((highIndex + lowIndex) / 2);
		let toCompare = keys[index];
		if (elKey) {
			toCompare = keys[index][elKey];
		}

		if (matchingEl > toCompare) {
			lowIndex = index + 1;
		}
		else if (matchingEl < toCompare) {
			highIndex = index - 1;
		}
		else {
			matchValue = values[index];
			break;
		}
	}

	return matchValue;
}

/**
 * @description provides a random integer between inclusive min and max numbers
 * @param {Number} min minimum number
 * @param {Number} max maximum number
 * @returns a random integer between min and max
 */
function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * @description treats each line as a linked list and creates the dictionary tree via "pointers". It's
 * basically a trie (prefix tree)
 * @param {Array} strArr string array to create the alphabet dictionary map from
 * @param {Object} objMapToPopulate object to contain the prefix
 * @param {Boolean} hasEndSpaces is useful when parsing files with a "word" per line
 */
function createPrefixTree(strArr, objMapToPopulate, hasEndSpaces = false) {
	strArr.forEach(str => {
		hasEndSpaces && (str += "\n");
		let nodePointer = -1;

		const firstChar = str[0];
		if (objMapToPopulate[firstChar] == null) {
			objMapToPopulate[firstChar] = {};
		}

		nodePointer = objMapToPopulate[firstChar];

		for (let i = 1; i < str.length; ++i) {
			let curChar = str[i];
			if (curChar === "\r") {
				continue;
			}
			if (nodePointer[curChar] == null) {
				nodePointer[curChar] = {};
			}

			nodePointer = nodePointer[curChar];
		}
	});
}

/**
 * @description tokenizes every string and includes a reference of the string at the end of the tokenized
 * string last's character
 * @param {Array} strArr string array to create the alphabet dictionary map from
 * @param {Object} objMapToPopulate object to contain the prefix
 * @param {Boolean} hasEndSpaces is useful when parsing files with a "word" per line
 */
function createTokenizedPrefixTreeWithReferencing(strArr, objMapToPopulate, hasEndSpaces = false) {
	const tokensToRemove = [...helpingVerbs, ...possessivePronouns, ...prepositions, ...misc];

	strArr.forEach(str => {
		let tokenizedStrings = str.replaceAll("_", " ").split(" ");
		tokenizedStrings = tokenizedStrings.filter(el => {
			return !tokensToRemove.includes(el.toLowerCase());
		});

		tokenizedStrings.forEach(token => {
			token = token && token[0].toUpperCase() + token.slice(1, token.length);
			hasEndSpaces && (token += "\n");

			const firstChar = token[0];
			if (!["&", "(", "/n"].includes(firstChar)) {
				if (objMapToPopulate[firstChar] == null) {
					objMapToPopulate[firstChar] = {};
				}
	
				let nodePointer = -1;
				nodePointer = objMapToPopulate[firstChar];
	
				for (let i = 1; i < token.length; ++i) {
					let curChar = token[i];
					if (curChar === "\r") {
						continue;
					}
					if (nodePointer[curChar] == null) {
						nodePointer[curChar] = {};
					}
					if (i === token.length - 1) {
						const nodePointerKeys = Object.keys(nodePointer[curChar]);
						if (nodePointerKeys.length === 0) {
							nodePointer[curChar] = {
								ref: [str]
							};
						}
						else if (nodePointerKeys.length && !nodePointerKeys.includes("ref")) {
							nodePointer[curChar]["ref"] = [str];
						}
						else if (nodePointerKeys.includes("ref")) {
							nodePointer[curChar].ref.push(str);
						}
					}
	
					nodePointer = nodePointer[curChar];
				}
			}
		});
	});
}

/**
 * @param {Object} prefixTree prefix tree to create the list from
 * @param {Boolean} hasRefs true if we're creating a list from a tokenized prefix tree
 * @returns 
 */
function createListFromPrefixTree(prefixTree, hasRefs = false) {
	let wordList = [];

	for (const [key, value] of Object.entries(prefixTree)) {
		if (!hasRefs) {
			if (Object.values(value).length) {
				const tailingChars = createListFromPrefixTree(value, false);
				for (const chars of tailingChars) {
					wordList.push(key + chars);
				}
			}
			else {
				wordList.push(key);
			}
		}
		else {
			const valueKeys = Object.keys(value);
			const valueOfValues = Object.values(value);

			if (key === "ref" && Array.isArray(value)) {
				wordList = wordList.concat(value);
			}
			else if (valueOfValues.length && valueKeys.includes("ref") && valueOfValues.length > 1) {
				wordList = wordList.concat(value.ref);

				let newValue = structuredClone(value);
				delete newValue.ref;
				wordList = wordList.concat(createListFromPrefixTree(newValue, true));
			}
			else if (valueOfValues.length && !valueKeys.includes("ref")) {
				wordList = wordList.concat(createListFromPrefixTree(value, true));
			}
			else {
				wordList = wordList.concat(value.ref);
			}
		}
	}

	return wordList;
}

function searchWordInTrie(text, wordsTree) {
	if (!text.length) {
		return [false, ["", wordsTree]];
	}

	let foundMatch = false;
	let runningMatch = wordsTree;
	let prevMatch;

	let i = 0;
	let curChar = text[i];
	let runningWordMatch = "";
	let charLower = curChar.toLowerCase();
	let charUpper = curChar.toUpperCase();

	while (runningMatch[charLower] || runningMatch[charUpper]) {
		prevMatch = runningMatch;
		if (runningMatch[charLower]) {
			runningMatch = runningMatch[charLower];
			runningWordMatch += charLower;
		}
		else {
			runningMatch = runningMatch[charUpper];
			runningWordMatch += charUpper;
		}

		++i;
		curChar = text[i];
		if (curChar) {
			charLower = curChar.toLowerCase();
			charUpper = curChar.toUpperCase();
		}

		// curChar == null || 
		if ((runningMatch["\n"] != null && (runningMatch[charLower] == null && runningMatch[charUpper] == null)) ) {
			foundMatch = true;
			break;
		}
	}

	if (prevMatch && ![curChar, charLower, charUpper].some(el => Object.keys(prevMatch).includes(el))) {
		runningMatch = {};
	}

	return [foundMatch, [runningWordMatch, runningMatch]];
}


export {binarySearch, getRandomInt, createPrefixTree,
				createTokenizedPrefixTreeWithReferencing,
				createListFromPrefixTree, searchWordInTrie}

