import React, {useRef} from "react";
import { Helmet } from "react-helmet";

import { 
	WHY_EXER_TABS, TUTORS_TAB, TUTEES_TAB
} from "templates/utilities/PageTabs";
import TabbedPages from "templates/TabbedPages";

import ExerForTutees from "pages/Tutoring/subPages/subMenus/ExerForTutees";
import ExerForTutors from "pages/Tutoring/subPages/subMenus/ExerForTutors";

import messageMap, {messageMapGeneric} from "Utilities/MessageMaps";


function WhyExer(props) {

	const tuteeTabRef = useRef(),
		tutorTabRef = useRef();

	function showSubMenuPage(chosenMenuPage) {
		if (chosenMenuPage === TUTEES_TAB) {
			tuteeTabRef.current.className = "benefits-for-users";
			tutorTabRef.current.className = "benefits-for-users hide";
		}
		else if (chosenMenuPage === TUTORS_TAB) {
			tuteeTabRef.current.className = "benefits-for-users hide";
			tutorTabRef.current.className = "benefits-for-users";
		}
	}

	return (
		<div className="why-exer">
			<Helmet>
				<title>{messageMap("whyExerTutoring.title", "headerTag")}</title>
				<meta name="description" content={messageMap("whyExerTutoring.description", "headerTag")}></meta>
			</Helmet>

			<div className="header">
				{messageMapGeneric("tutoringPage.whyExer.header")}
			</div>

			<TabbedPages customClass={"center"} tabType={WHY_EXER_TABS} tabClickHandler={showSubMenuPage} />

			<div className="benefits-for-users" ref={tuteeTabRef} >
				<ExerForTutees />
			</div>

			<div className="benefits-for-users hide" ref={tutorTabRef}>
				<ExerForTutors />
			</div>
		</div>
	);
}

export default WhyExer;

