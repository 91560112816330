import React, { useEffect, useState, Fragment }  from "react";
import PropTypes from "prop-types";

import Alert, {ERROR} from "templates/Alert";

import messageMap from "Utilities/MessageMaps";
import {topicsInSubjects} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import {intToDayOfWeekMap, monthMap} from "templates/utilities/CalendarUtilities";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";

import calendarAsset from "assets/icons/common/calendar.svg";
import clockAsset from "assets/icons/common/clock.svg";


function BookingConfirmationModal(props) {

	const [formattedHour, setFormattedHour] = useState(),
		[formattedMinutes, setFormattedMinutes] = useState();
	const [additionalNote, setAdditionalNote] = useState();

	useEffect(() => {
		formatTime();
	}, []);

	function formatTime() {
		setFormattedHour(props.prevData.hour > 12 ? props.prevData.hour - 12 : props.prevData.hour);

		const minutes = props.prevData.minutes.split(".");
		setFormattedMinutes(minutes[0].length === 1 ? minutes[0] + "0" : minutes[0]);
	}

	function changeBooking() {
		props.prevStepHandler(props.prevData);
	}

	function saveAdditionalNote(e) {
		const description = e.target.value;

		if (checkHasExplicitWords(description)) {
			e.target.value = "";

			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring"
					msg={messageMap("input.explicit.text", "validation")} />
			);
		}
		else {
			setAdditionalNote(description);
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function passConfirmationToNext() {
		props.nextStepHandler({
			...props.prevData,
			addDescription: additionalNote
		});
	}

	return (
		<Fragment>
			<div className="booking-confirmation-container">
				<div className="booking-summary">
					<div className="session-subject">
						{`${topicsInSubjects[props.prevData.subject][props.prevData.topic]} ${messageMap("tutoringPage.tutorList.pane.modal.session", "generic")}`}
					</div>
					<div className="session-length">
						{`${props.prevData.duration} ${messageMap("tutoringPage.tutorList.pane.modal.minSession", "generic")}`}
					</div>
					<div className="session-date-time">
						<img className="calendar" src={calendarAsset} alt={messageMap("calendar", "image")} />
						<div className="session-day-of-month">
							{`${intToDayOfWeekMap[props.prevData.dayOfWeek]}, ${monthMap[props.prevData.month]} ${props.prevData.dayOfMonth}`}
						</div>
						<img className="clock" src={clockAsset} alt={messageMap("clock", "image")}/>
						<div>
							{`${formattedHour}:${formattedMinutes}${props.prevData.hour < 12 ? "AM" : "PM"}`}
						</div>
					</div>
					<button className="change-button" onClick={changeBooking}>
						{messageMap("tutoring.modal.change", "button")}
					</button>

					<div className="cost-header">
						{messageMap("tutoringPage.tutorList.pane.modal.cost", "generic")}
					</div>
					<div className="total-container">
						<div>
							{messageMap("tutoringPage.tutorList.pane.modal.total", "generic")}
						</div>
						<div className="cost">
							{`$${props.prevData.rate}`}
						</div>
					</div>
					<div className="taxes-fees">
						{messageMap("tutoringPage.tutorList.pane.modal.taxesFees", "generic")}
					</div>
					<div className="charge-time">
					{`${messageMap("tutoringPage.tutorList.pane.modal.chargeTime1", "generic")} ${props.tutorFirstName} ${messageMap("tutoringPage.tutorList.pane.modal.chargeTime2", "generic")}`}
					</div>
				</div>

				<div className="additional-message">
					<div className="message-to-tutor">
						{`${messageMap("tutoringPage.tutorList.pane.modal.addMessage", "generic")} ${props.tutorFirstName}`}
					</div>
					<textarea className="textarea" onChange={saveAdditionalNote}
						placeholder={messageMap("tutoringPage.tutorList.pane.modal.optional", "generic")}></textarea>
				</div>
			</div>

			<div className="booking-actions">
				<button className="payment-action" onClick={passConfirmationToNext}>
					{messageMap("tutoring.modal.sendRequest", "button")}
				</button>
				<div className="step-indicator">
					{messageMap("tutoringPage.tutorList.pane.modal.step22", "generic")}
				</div>
			</div>
		</Fragment>
	);
}

BookingConfirmationModal.propTypes = {
	tutorFirstName: PropTypes.string.isRequired,
	setAlert: PropTypes.func.isRequired,

	// attributes from Modal
	nextStepHandler: PropTypes.func.isRequired,
	prevData: PropTypes.object.isRequired,
	prevStepHandler: PropTypes.func.isRequired,
	nextData: PropTypes.object
};

export default BookingConfirmationModal;
