export const learningMaterialsMessageMap = {
	learningMisconceptions: {
		article: {
			title: "Misconceptions about learning preferences",
			description: "Learn the truth behind learning preferences and how we're actually supposed to learn.",
			thumbnailAlt: "A man teaching students about geometry."
		},
		texts: {
			header1: "Module introduction",
			p1: `Welcome to another module about optimizing our learning. Because our learning progress's speed partly depends 
				on our educational tools, <b>for this module, we'll learn about choosing the right learning materials</b>. We'll start by 
				addressing a very common misconception about how we prefer to learn. We will follow this by identifying the components 
				of a good goal and how we can evaluate our skills and knowledge. With a clearer picture of our goals, skills, and 
				knowledge, we can then choose the most suitable learning materials, while keeping a few important things in mind.`,
			p2: `And just a small side note, this module is mainly intended for learners, not teachers. But, if you happen to be 
				a teacher and you're looking for a more detailed walkthrough on how to look for good learning materials for your 
				students, you can visit the following link: <u>https://www.nap.edu/read/9607/chapter/5#44</u>. The link talks about how to 
				facilitate a review plan, carry out reviews of materials, select the materials, and evaluate the process and results.`,
			p3: `Anyway, without further ado, let's hop into the module's first topic.`,
			header2: "Introduction",
			p4: `I know we already learned about misconceptions in the mentality and attitude module, but I decided to separate 
				a few, because this is more related to our general impression of how we learn best, specifically our learning styles, 
				depending on the learning material at hand. But before we dive into the details, let's briefly look into how we generally 
				feel as to the best way we learn.`,
			p5: `What would you say is your learning style? Would you say that learn best with this method?`,
			header3: "Learning styles",
			p6: `When we say learning styles, we're typically referring to our preferred way of learning. One common acronym for this 
				is VARK, which stands for visual, auditory, read/write, and kinesthetic. When someone says that they're a visual learner, 
				they're saying that they learn best if the information is presented in a visual format, like charts and graphs. For auditory 
				learners, this can mean lectures and discussions. For reading and writing learners, it's essentially what the label says. 
				And for kinesthetic learners, they prefer to learn by doing something with their hands; kinesthetic, by the way, comes from 
				the word kinesthesia, which means the sensory perception of movement.`,
			p7: `If we happen to look into more learning styles, we'll find that there are also social, or interpersonal, learners, and 
				solitary, or intrapersonal, learners. Then, there is also the logical, or mathematical, learners, which means that they 
				learn using logic, reasoning, or via systems; this, in particular, never made any sense to me, because learning, in its very 
				essence, requires logic; logic is also the very foundation of new knowledge.`,
			header4: "What science says",
			p8: `Anyway, it turns out that <b>tailoring the material according to students' learning styles has no impact whatsoever in producing 
				better learning outcomes.</b> A student's learning style doesn't even predict the way the students prefer to be taught, or the 
				way they choose to study. And, the shocking truth about this is that about 89% of teachers surveyed, a survey conducted in 
				2020, believe that this learning-style-matching method works. The awful part about this is that both teachers and students end 
				up wasting their time on applying and learning from this faulty method; it actually takes more time for teachers to prepare the 
				material in a certain way to match students' learning styles and the student is missing out on other better learning opportunities, 
				which has a greater chance of success.`,
			header5: "Did the student actually understand the material?",
			p9: `A common reason that learning style proponents usually put forth for its use is that they find students learn better and faster 
				when they switch teaching styles. The dangerous assumption behind this is that, since they're able to do the work, it means they 
				understand the material. Not exactly. For example, just because I know how to use a scientific formula for a problem, doesn't mean 
				that I completely understand the entire concept behind that formula, such as how it was derived, or in which situations the formula 
				shouldn't be used. Another assumption from this reasoning is that their way of teaching style might've been wrong, or ineffective, 
				since the students are struggling to understand the material. <b>The reasons behind why students are struggling can be numerous</b>; the 
				students might be misinterpreting the information; the explanation isn't clear enough; or simply because they need to try harder, or 
				the material is just hard.`,
			p10: `Now, the belief behind this isn't completely false; <b>studies have shown that, although the method of fitting the material according 
				to students' learning styles doesn't improve learning, it can improve our ability to remember the material.</b> We'll discuss how this is 
				possible in the next module. Maybe this is one of the reasons why teachers believe this approach works, but this is simply a 
				matter of confusing the idea between learning and remembering; they are not the same - remembering is just a component of learning.`,
			header6: "Learning from different lenses",
			p11: `To be clear, this isn't to say that we should remove the presentation of information in different ways; in fact, we should <b>strive to 
				learn the material through many different lenses, since doing so gives us a more complete picture of what we're trying to learn.</b>`,
			p12: `What we instead need to acknowledge is that <b>how the material is best presented depends on the subject, not the person learning the 
				subject.</b> For example, the best way to learn how to cook is to actually cook; this is kinesthetic learning. But again, this is simply one 
				way of learning how to cook; we can read about the chemistry of why certain food tastes that way and how they were made to taste that 
				way; this is visual learning. We can listen to professional chefs talk about food and even talk to other people about food; this is auditory 
				and social/interpersonal learning in action. By exposing ourselves to the different ways of learning, we get a more complete picture of 
				what it means to learn how to cook; this is the main reason why, <b>even though students are being taught with their preferred 
				learning style, their learning outcome didn't improve, because the students and teachers are only utilizing one form of learning, as opposed 
				to multiple modes of learning.</b>`,
			header7: "Conclusion",
			p13: `So as we can see, <b>teaching and learning aren't unidimensional, but multidimensional</b>; we need to be comfortable with learning subjects 
				through multiple lenses to get a better understanding.`,
			p14: `There we have it. <b>Learning the material in different ways helps us better remember and learn the material, as opposed to just using one 
				learning style.</b>`
		},
		sources: {
			s1: `Flavin, B. (2019, May 6). Different types of learners: What college students should know. Rasmussen University. https://www.rasmussen.edu/student-experience/college-life/most-common-types-of-learners/ `,
			s2: `Hattie, J., & Yates, G. C. R. (2014a). Visible learning and the science of how we learn. Routledge, Taylor & Francis Group. `,
			s3: `National Academies Press, Washington DC. (n.d.). Read “Science teaching reconsidered: A handbook” at nap.edu. Chapter 7: Choosing and Using Instructional Resources | Science Teaching Reconsidered: A Handbook | The National Academies Press. https://www.nap.edu/read/5287/chapter/8#49 `,
			s4: `School of Education and Human Sciences, University of Kansas. (1970, February 25). Different learning styles-what teachers need to know. KU SOE. https://educationonline.ku.edu/community/learning-styles-what-teachers-need-to-know `
		}
	},
	goalsEvaluation: {
		article: {
			title: "How to set goals and evaluate your skills and knowledge to pick the right learning materials",
			description: "Learn the right way to make goals and how to evaluate your current skills and knowledge.",
			thumbnailAlt: "A cup of coffee on top of a planner."
		},
		texts: {
			header1: "Introduction",
			p1: `Now we come to the topic of <b>identifying our goals and evaluating our skills and knowledge, 
				in preparation for picking the right learning materials for the next article.</b> It often surprises me how goals are 
				sometimes stated at the beginning of classes, yet we rarely even consider the students' goals on a more personal 
				level, such as why are they taking that class, or even going to school, especially since <b>setting goals is probably 
				one of the most important factors that determines success in life.</b> But I'm jumping the gun here, we haven't even 
				done our usual question prompts to see how it all relates to us, so, here we go.`,
			p2: `Do you set any goals for your classes, work, or life? If you do, what are some of those goals? If you don't, what 
				are some things about your class, work, or life that you can turn into a goal? Besides thinking about the answers to 
				these questions, it'll be helpful if you also follow along by writing down your goals and improving them as we go 
				through this article.`,
			header2: "Importance of goals",
			p3: `So, first things first; we need to define our goals. It is highly important that we set goals in class, work, 
				and/or life, because setting goals and believing that we can achieve them are priming factors that help gather our 
				body's resources to help us succeed. <b>Research has shown that setting any goals and plans improves our performance in 
				areas completely unrelated to our goals and plans; this means that setting goals and plans can also potentially improve 
				our academics, work, and life.</b> By the way, priming here means to be ready, or to anticipate. So this means that by 
				having and believing in our goals, we're more focused and ready to achieve them.`,
			header3: "Components of a good goal",
			p4: `But before we can start setting our goals, we need to be aware of what makes up a good goal. Good goals have the 
				following properties:`,
			p5: `One, <b>they're specific</b>. To put as an example, let's say that I want to do well in my class. How well? Do I want to get 
				an A in this class, a B, or a C? Or, let's say that I want to be a scientist. What kind of scientist? There are biologists, 
				chemists, physicists, etc. and there are even specializations within those fields. We want to be specific because it gives 
				us a better idea of what we're aiming for, and it'll help us determine if we actually succeeded.`,
			p6: `On the same note of being specific, it would also be nice if the goal is measurable. I say 'it would be nice', because 
				not all goals are easily or actually measurable, for example when we get the job we wanted, or manage to cook the dish we 
				wanted to cook. We want the goal to be measurable because it can help us break down our journey into milestones, so we can 
				closely monitor our progress. A good example of this is wanting to bench let's say 100lbs, but we're only currently able to 
				bench about 40lbs. By having a measurable goal, we can break up our journey into sets of 5 additional pounds every 2 - 3 weeks, 
				depending on how quickly we progress on our gains.`,
			p7: `A second important property of <b>a good goal would be something that pushes us to keep moving forward</b>. An example of this would 
				be a goal that is exciting, important, time-specific, and/or challenging. These properties are important, because the more reasons 
				we can find the faster our progress would be compared to setting a goal that we don't really care about.`,
			p8: `And lastly, we need to set <b>a goal that's achievable, or realistic</b>, because the last thing we want to do is to set unrealistic 
				goals and get sad about them when we don't reach them.`,
			p9: `With all the properties laid out, please take a moment to see if you can improve the goals you've written down so far.`,
			header4: "Creating roadmaps",
			p10: `Once we've defined our goals, <b>we then need to see how far, or close, we are from our goals. To do that, we need to create a roadmap.</b> 
				For example, if I want to become some type of engineer, this might be the roadmap that I'll most likely go through:`,
			p11: `Please note that I intentionally created a generic roadmap to become an engineer, since indicating the specific courses for a 
				specific type of engineering would take more screen space.`,
			p12: `Anyway, to create a roadmap for our goals, we're going to need to do our own research; this involves looking for and listening to 
				people who have already reached our goals, reading research papers and articles talking about how to get there and what to watch out for, 
				or simply doing a google search. I intentionally didn't create a strategy for creating a roadmap, since each of our goals can be as simple 
				and as complicated as they can be, so the approach can vary depending on the goal. We'll learn more about goal setting and tackling them 
				in our “Becoming-an-expert-to-anything” module. For now, we can make do with a rough outline; the important part is to get things started; 
				our roadmap doesn't need to be overly complex and detailed, since we'll update it as we go along.`,
			header5: "Evaluating our skills and knowledge",
			p13: `Once we've mapped our journey, we now need to evaluate our current skills and knowledge, since this would help us determine at what 
				point in our journey we can begin.`,
			p14: `Much like mapping our roadmap, there isn't a clear strategy for evaluating our skills and knowledge. If you ever happen to search for 
				books, articles, and/or websites online on how to assess one's skills, you'll quickly realize that they're all intended for assessing 
				someone's skills and knowledge instead of yours, and, since this module is mainly intended for choosing the right learning material for 
				us, I, again, decided to move this more detailed discussion to “Becoming-an-expert” module, because accurately and thoroughly assessing 
				oneself can be quite complex.`,
			p15: `So for now, <b>our best bet is to consult an expert, or some teacher, to help us determine our capabilities.</b> We want another person, ideally 
				an expert, to evaluate us, because they can provide a more objective view of our current capabilities, help identify some of the challenges 
				that we're currently facing, and even provide suggestions for properly handling them.`,
			p16: `However, if they're not available, we'll then need to evaluate ourselves. It's important to be honest with ourselves on this, since, if 
				we overestimate our current capabilities, we might eventually reach a point when the fundamental skills and knowledge that we should've addressed 
				early on might eventually become our barriers to succeeding in our goals, so it's important to be meticulous in our approach in self-evaluation.`,
			header6: "Breaking down our skills and knowledge",
			p17: `Anyway, <b>our evaluations depend on our goal's components</b>. For example, if we're trying to become really good at basketball, the components 
				that make up the game include, among other things, the game's rules, player positions, shooting, dribbling, passing, etc. <b>We then test ourselves 
				on how well we know and can execute each element.</b> Taken into an academic context, if we're trying to get really good at physics, we then need to 
				break down our current understanding of physics, such as its concepts, equations, and applications, and then test ourselves on how much we can do 
				for each component. So, a second option to evaluate ourselves would be to break our goals down into components that we can test ourselves against.`,
			p18: `As a short aside, we should also be aware that our lack of awareness of how certain things break down is also an indicator of how well we know 
				the subject. So back to our basketball example, if we're trying to be really good at basketball but don't know anything about it, then that's currently 
				our level of skills and knowledge regarding our goal.`,
			header7: "Conclusion",
			p19: `And there we have it. <b>To determine the right learning materials, we must first define our goals and determine our current progress. Our goals 
				need to be specific, need to be something that continually motivates us to keep moving forward, and need to be achievable. Our progress towards our 
				goals depends on our current skills and knowledge, which can be evaluated by testing ourselves, or having some teacher or expert test us, against 
				our goals' components; this would then give us an idea of where we are on our roadmap.</b>`
		},
		sources: {
			s1: `Dotson, R. (2016). Goal setting to increase student academic performance. Journal of School Administration Research and Development, 1(1), 45–46. https://doi.org/10.32674/jsard.v1i1.1908 `,
			s2: `Hattie, J., & Yates, G. C. R. (2014a). Visible learning and the science of how we learn. Routledge, Taylor & Francis Group. `,
			s3: `Schippers, M. C., Morisano, D., Locke, E. A., Scheepers, A. W. A., Latham, G. P., & de Jong, E. M. (2020). Writing about personal goals and plans regardless of goal type boosts academic performance. Contemporary Educational Psychology, 60, 101823. https://doi.org/10.1016/j.cedpsych.2019.101823 `
		}
	},
	learningMaterials: {
		article: {
			title: "How to choose the right learning materials",
			description: "Learn the two components that determines a learning material's quality and the many types of learning materials.",
			thumbnailAlt: "A study room showing three people surrounded by books."
		},
		texts: {
			header1: "Introduction",
			p1: `Finally, after defining our goals and testing our current skills and knowledge, we're now ready to look into 
				the learning materials that would take us to the next stage in our roadmap. For this article, <b>we'll learn about 
				identifying a learning material's utility and quality, to see if such a material fits our needs.</b> But before going 
				into the details, let's recount the learning materials we've used so far and their effectiveness.`,
			p2: `If any, what learning materials have you used so far? How effective do you think are such materials in helping 
				you learn?`,
			p3: `Now, there are numerous learning materials out there and it's easy to get lost in the many options we have. So, 
				I decided to not list them here and I, instead, created a diagram of them, along with this lesson's mental model, 
				at the bottom of this article, so we can refer to it later if we need to.`,
			p4: `Additionally, <b>because it'll ultimately be our call on whether a material is good, or bad, we instead have to focus 
				on developing the process that would help us identify whether a material is good, or bad.</b>`,
			p5: `And as previously mentioned, there are two things that we need to concern ourselves with and that is the material's 
				utility and quality.`,
			header2: "How we define utility and quality",
			p6: `Just to be on the same page here, when I say <b><u>utility</u>, I mean how useful an object is</b>, and, when I say <b><u>quality</u>, I'm 
				referring to the material's contents.</b> We want to maximize an object's utility, because, of course, we want to avoid 
				wasting time, effort, and, if possible, additional cost in using and acquiring the learning material. We also want to 
				maximize an object's quality, because the last thing we want to do is to learn from something that isn't completely 
				accurate and/or up to date.`,
			header3: "Gauging a material's utility",
			p7: `Anyway, to determine <b>a learning material's utility</b>, we just need to be aware of two things, and those are 
				<b>the amount of information and the amount of practice we can extract from the material</b>. If we're to compare two books 
				that talk about kinematics, clearly the book that explains more concepts would be more useful. In the same vein, if we're 
				to compare two basketballs, one old and one new, clearly the newer basketball would provide more practice, or use, since 
				there's a good chance that it'll last longer. From the context of a textbook, this would mean plenty of exercises to apply 
				the information.`,
			header4: "Gauging a material's quality",
			p8: `On the other hand, if we're talking about <b>a material's quality</b>, we now need to think about <b>its contents and 
				the material itself</b>. For example, if we're determining a book's content quality, we need to look into how recently it 
				was published, who the authors were, if the presented information is accurate, and how the information was organized. If 
				we were to determine a material's quality, for example a violin, we need to look at its components, such as its string, 
				tuning pegs, and its body.`,
			header5: "Exceptions to the criteria",
			p9: `Having laid out all these factors, we should also be aware that it's possible to encounter learning materials that have 
				varying degrees of success in meeting each of these criteria and still be a good learning material. For example, we can 
				easily find an old book that might be 15 to 20 years old, yet there are little to no typos; its information is still accurate; 
				and it provides a wealth of information on a topic.`,
			header6: "Shortcut?",
			p10: `Now, I know what some of us might be thinking. These factors are simply too much to just pick up a book, or some instrument 
				or tools, to get things going. And yes, I acknowledge that considering all these can be a daunting task, but it's the approach 
				that we must take, and get used to, if we are to really take charge of our own learning. However, having said all these, a compromise 
				would be to simply look for commonly recommended learning materials, which can be found through a simple <b>Google search, asking 
				your friends, family, people, experts, and simply looking into the reviews.</b>`,
			header7: "Conclusion",
			p11: `And that's it for this article. The following is the mental model of what was discussed. Please note that the list of learning 
				materials is mainly for reference purposes. What we do need to remember is how one would determine a material's utility and quality.`,
			p12: `Congratulations on finishing another module. As some of us might have noticed, fully taking control of one's learning journey 
				can be challenging and complicated, as detailed by the topics we've learned in this module, which includes <b>spotting common misconceptions 
				about learning preferences, determining what our learning materials depend on, and being aware of some key concepts when choosing the 
				learning materials themselves.</b> Additionally, some of us might've also noticed that I commonly explained the many benefits of consulting 
				teachers and experts, since they themselves have already gone through the challenges of what we're going through right now and are 
				therefore more aware of the next steps to proceed with our journey.`
		},
		sources: {
			s1: `National Academies Press, Washington DC. (n.d.). Read “Science teaching reconsidered: A handbook” at nap.edu. Chapter 7: Choosing and Using Instructional Resources | Science Teaching Reconsidered: A Handbook | The National Academies Press. https://www.nap.edu/read/5287/chapter/8#49 `,
			s2: `Oakley, B. A., Rogowsky, B., & Sejnowski, T. J. (2021). Uncommon sense teaching: Practical insights in brain science to help students learn. TarcherPerigee, an imprint of Penguin Random House LLC. `,
			s3: `Knowledge, skills and abilities (KSA). edyoucated GmbH - All-in-One-Plattform für Skills und Lernen. (n.d.). https://edyoucated.org/en/glossary/knowledge-skills-and-abilities `
		}
	}
};