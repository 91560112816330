import React, {Fragment} from "react";

import { messageMapGeneric } from "Utilities/MessageMaps";


export const TUTORING_PRICING_STRUCTURE = [
	(
		<Fragment key="struct1">
			<div className="bold">
				{messageMapGeneric("profilePage.tutoring.modal.requirements.p11")}
			</div>
			{messageMapGeneric("profilePage.tutoring.modal.requirements.p12")}
		</Fragment>
	),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p13"),
	(
		<Fragment key="struct5">
			<div className="bold">
				{messageMapGeneric("profilePage.tutoring.modal.requirements.p23")}
			</div>
			{messageMapGeneric("profilePage.tutoring.modal.requirements.p24")}
			<div className="bold">
				{messageMapGeneric("profilePage.tutoring.modal.requirements.p25")}
			</div>
			{messageMapGeneric("profilePage.tutoring.modal.requirements.p26")}
		</Fragment>
	),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p30")
];

function PricingStructure(props) {
	return (
		<div>
			<div className="title">
				{messageMapGeneric("tutoringPage.faqs.tutors.pricingStructure")}
			</div>

			{
				TUTORING_PRICING_STRUCTURE.map((requirement, index) =>
					<div key={`requirement_${index}`} className="faq-text">
						{requirement}
					</div>
				)
			}

		</div>
	);
}

export default PricingStructure;
