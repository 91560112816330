import React from 'react';

import messageMap from "Utilities/MessageMaps";

import comingSoonAsset from "assets/images/tbd/coming_soon.jpg";

// Good chance that this page will eventually go to a forum
function Feedback() {
	return (
		<div className="feedback">
			<h1>
				{messageMap("company.feedback.title", "generic")}
			</h1>
			<img src={comingSoonAsset} alt={messageMap("tbd", "image")}></img>
		</div>
	);
}

export default Feedback;