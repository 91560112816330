import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/environment/thumbnailEyes.jpg";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";


export const environmentEyeArticle = {
	id: "I. Environment_0_0 How the things we see affect our learning",
	title: messageMap("sight.article.title", "learningOptimization"),
	description: messageMap("sight.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("sight.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4",
		"text5", "text6",
		"text7", "text8", "text9",
		"text10", "text11",
		"text12", "text13", "text14", "text15", "text16",
		"text17", "text18",
		"text19", "text20", "text21", "text22", "text23", "text24"
	],
	datePublished: "2023-11-30",
	dateModified: "2024-06-21"
};

export const environmentEyeArticleTexts = {
	text1: {
		text: messageMap("sight.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("sight.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("sight.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("sight.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("sight.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("sight.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("sight.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text8: {
		text: messageMap("sight.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("sight.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("sight.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text11: {
		text: messageMap("sight.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("sight.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text13: {
		text: messageMap("sight.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("sight.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("sight.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("sight.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("sight.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text18: {
		text: messageMap("sight.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("sight.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text20: {
		text: messageMap("sight.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text22: {
		text: messageMap("sight.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text23: {
		text: messageMap("sight.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text24: {
		text: messageMap("sight.sources.s3", "learningOptimization"),
		type: SOURCE
	}
};


