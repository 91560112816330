import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";

import {CORRECT, WRONG} from "pages/Classes/Practice/constants/PracticeConstants";

/**
 * @param {String} matchId // parent prop for re-rendering
 * @param {Object} answer // to determine if user's answer is right/wrong
 * @param {Function} setAnswerCorrectness // parent function used to check if user's answer is right/wrong
 * @param {Function} setClearResponseFunc // to clear user's answer
 */
function FillInTheBlank(props) {

	const userAnswerInputRef = useRef();

	useEffect(() => {
		attachParentProps();
	}, [props.matchId]);

	function attachParentProps() {
		props.setClearResponseFunc(clearResponse);
	}

	function clearResponse() {
		userAnswerInputRef.current.value = null;
	}

	function solveExpression(event) {
		if (event.target.value) {
			if (props.answer.current.toLowerCase() === event.target.value.toLowerCase()) {
				props.setAnswerCorrectness(CORRECT);
			}
			else {
				props.setAnswerCorrectness(WRONG);
			}
		}
	}

	return (
		<input type="text" className="number-input" ref={userAnswerInputRef}
						onChange={solveExpression}></input>
	);
}

FillInTheBlank.propTypes = {
	matchId: PropTypes.string.isRequired,
	answer: PropTypes.object.isRequired,
	setAnswerCorrectness: PropTypes.func.isRequired,
	setClearResponseFunc: PropTypes.func.isRequired
}

export default FillInTheBlank;

