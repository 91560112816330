import React from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

import messageMap, {messageMapDocument} from "Utilities/MessageMaps";

import TuteeSessionPolicy from "./components/TuteeSessionPolicy";
import TuteeCancellationPolicy from "./components/TuteeCancellationPolicy";
import TuteePolicyViolations from "./components/TuteePolicyViolations";


function TuteePolicy(props) {

	const classStr = props.contentType === "modal" ? "modal-container-tutor-tutee-policy" : "agreement-container";

	return (
		<div className="tutor-tutee-policy">
			<Helmet>
				<title>{messageMap("tuteePolicy.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tuteePolicy.description", "headerTag")}></meta>
			</Helmet>

			<div className={classStr}>
				<h1 className="title">
					{messageMapDocument("tuteePolicy.title")}
				</h1>
				<div className="agreement">
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.header.date")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.header.toUse1")}
						<span>
							{messageMapDocument("tuteePolicy.header.toUse2")}
						</span>
						{messageMapDocument("tuteePolicy.header.toUse3")}
						<a href="https://exerinstitute.com/terms-and-conditions">https://exerinstitute.com/terms-and-conditions</a>
						{messageMapDocument("tuteePolicy.header.toUse5")}
						<span>
							{messageMapDocument("tuteePolicy.header.toUse6")}
						</span>
						{messageMapDocument("tuteePolicy.header.toUse7")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.header.unauthorizedUse")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.header.policyGoal")}
					</p>

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("tuteePolicy.section1.title")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("tuteePolicy.section1.point1.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("tuteePolicy.section1.point1.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section1.point1.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section1.point1.point3.text")}
									</p>
								</li>
							</ul>
						</div>

						<TuteeSessionPolicy />

						<TuteeCancellationPolicy />

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("tuteePolicy.section1.point4.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("tuteePolicy.section1.point4.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section1.point4.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section1.point4.point3.text")}
									</p>
								</li>
							</ul>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("tuteePolicy.section1.point5.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("tuteePolicy.section1.point5.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section1.point5.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section1.point5.point3.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section1.point5.point4.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section1.point5.point5.text")}
									</p>
								</li>
							</ul>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("tuteePolicy.section2.title")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("tuteePolicy.section2.point1.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("tuteePolicy.section2.point1.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section2.point1.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section2.point1.point3.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMapDocument("tuteePolicy.section2.point1.point4.text1")}
										</span>
										{messageMapDocument("tuteePolicy.section2.point1.point4.text2")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMapDocument("tuteePolicy.section2.point1.point5.text1")}
										</span>
										{messageMapDocument("tuteePolicy.section2.point1.point5.text2")}
									</p>
								</li>
							</ul>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMapDocument("tuteePolicy.section2.point2.text")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMapDocument("tuteePolicy.section2.point2.point1.text")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section2.point2.point2.text")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMapDocument("tuteePolicy.section2.point2.point3.text")}
									</p>
								</li>
							</ul>
						</div>
					</div>

					<TuteePolicyViolations />

					<div className="section-div">
						<h2 className="head-label">
							{messageMapDocument("tuteePolicy.section4.title")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMapDocument("tuteePolicy.section4.point1.text")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMapDocument("tuteePolicy.section4.point2.text")}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

TuteePolicy.propTypes = {
	contentType: PropTypes.string
};

export default TuteePolicy;

