import {SET_QUEST_PATH, SET_CURRENT_TOPIC} from "redux/actions/knowledgeConstants";

const initialState = {
	quest: null,
	currentTopic: null
};

export default function (state = initialState, action) {
	if (action.type === SET_QUEST_PATH) {
		return {
			...state,
			quest: action.payload
		};
	}
	else if (action.type === SET_CURRENT_TOPIC) {
		return {
			...state,
			currentTopic: action.payload
		};
	}
	else {
		return state;
	}
}
