import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function Card(props) {
	return (
		<Link className="item-card-container"
			onClick={props.clickHandler}
			to={{
				pathname: props.href,
				state: props.linkState
			}}>

			{
				props.isASet
				? <div className="set-indicator"></div>
				: null
			}
			<div className="thumbnail">
				<img className="image" alt={props.thumbnailAlt} src={props.thumbnailSrc}></img>
				<div className="duration">
					{props.duration}
				</div>
			</div>

			<div className="description-container">
				<div className={`title-container-default ${props.customTitleContainer}`}>
					{
						props.creatorInitial
						? (
							<div className="user-initial">
								{props.creatorInitial}
							</div>
						)
						: null
					}
					<h2 className={`title-default ${props.customTitle}`}>
						{props.title}
					</h2>
				</div>

				{
					props.creatorUsername
					? (
						<div className="uploader">
							{props.creatorUsername}
						</div>
					)
					: null
				}

				{
					props.description
					? (
						<div className="description">
							{props.description}
						</div>
					)
					: null
				}

				<div className="count-publish-date-container">
					<div className="view-count">
						{props.viewCount}
					</div>
					<div className="since-published">
						{props.uploadTimestamp}
					</div>
				</div>
				{props.children}

			</div>
		</Link>
	);
}

Card.defaultProps = {
	isASet: false,
	customTitle: "",
	customTitleContainer: ""
};

Card.propTypes = {
	href: PropTypes.string,
	linkState: PropTypes.object,
	clickHandler: PropTypes.func,
	thumbnailSrc: PropTypes.string.isRequired,
	thumbnailAlt: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	customTitle: PropTypes.string,
	customTitleContainer: PropTypes.string,

	creatorInitial: PropTypes.string,
	creatorUsername: PropTypes.string,
	isASet: PropTypes.bool,
	duration: PropTypes.string,
	viewCount: PropTypes.number,
	uploadTimestamp: PropTypes.object,

	description: PropTypes.oneOfType(
		[PropTypes.string, PropTypes.object]
	),

	children: PropTypes.object
};

export default Card;