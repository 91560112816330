import messageMap from "Utilities/MessageMaps";

export const ALL_TUTORS = "allTutors";
export const SAVED_TUTORS = "savedTutors";

export const ACCEPT_DECISION = "accept";
export const DECLINE_DECISION = "decline";

export const SENT_DIRECTION = "sent";
export const RECEIVED_DIRECTION = "received";

export const TUTEE_USER = "tutee";
export const TUTOR_USER = "tutor";

// session request states
export const OPEN_STATE = "open";
export const PENDING_STATE = "pending";
export const SCHEDULED_STATE = "scheduled";
export const LIVE_STATE = "live";
export const COMPLETED_STATE = "completed";
export const DISPUTED_STATE = "disputed";
export const DISPUTE_RESOLVED_STATE = "disputeResolved";
export const TUTEE_CANCELED_STATE = "tuteeCanceled";
export const TUTEE_NO_SHOW_STATE = "tuteeNoShow";
export const TUTOR_NO_SHOW_STATE = "tutorNoShow";

export const stateToStateLabel = {
	[PENDING_STATE]: messageMap("tutoringPage.session.pending", "generic"),
	[SCHEDULED_STATE]: messageMap("tutoringPage.session.scheduled", "generic"),
	[LIVE_STATE]: messageMap("tutoringPage.session.live", "generic"),
	[COMPLETED_STATE]: messageMap("tutoringPage.session.completed", "generic"),
	[DISPUTED_STATE]: messageMap("tutoringPage.session.disputed", "generic"),
	[DISPUTE_RESOLVED_STATE]: messageMap("tutoringPage.session.disputeResolved", "generic"),
	[TUTEE_CANCELED_STATE]: messageMap("tutoringPage.session.tuteeCanceled", "generic"),
	[TUTEE_NO_SHOW_STATE]: messageMap("tutoringPage.session.tuteeNoShow", "generic"),
	[TUTOR_NO_SHOW_STATE]: messageMap("tutoringPage.session.tutorNoShow", "generic")
};
