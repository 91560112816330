import React from 'react';
import PropTypes from "prop-types";

import underlineAsset from "assets/images/home/underline.svg";

export default function MarketingDetails(props) {
	let detailsArr = [];

	props.details.forEach((value, index) => {
		const containerClass = (index + 1) % 2 === 1 ? "reasons-container" : "reasons-container flipped";

		detailsArr.push(
			// className={containerClass}
			<div className={containerClass} key={value.img}>
				<div className="img-cell">
					<img src={value.img}></img>
				</div>
				<div className="description-cell">
					<h3 className="title">
						{value.title}
					</h3>
					<div className="detail">
						{value.description}
					</div>
				</div>
			</div>
		);
	});

	return (
		<div className="reasons">
			<h2 className="title">{props.title}</h2>
			<img src={underlineAsset} className="underline"></img>
			<div className="reason-set">
				{detailsArr}
			</div>
		</div>
	);
}

MarketingDetails.propTypes = {
	details: PropTypes.arrayOf(PropTypes.object).isRequired,
	title: PropTypes.string.isRequired
};