import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import account from "redux/selectors/accountSelector";
import { ConnectedPurchasePopUp } from './PurchasePopUp';

import Alert, {SUCCESS} from "templates/Alert";
import Spinner from "templates/Spinner";
import Pagination from "templates/Pagination";
import Search from "templates/Search";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown, escape } from "Utilities/Accessibility";
import { debounce } from "Utilities/CustomHooks";
import { promiseAll, GET, POST } from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";

import { createVideoBlocks, createModuleBlocks, goToPageHandler, generateStructuredData } from "pages/Classes/Videos/VideoListUtilities";

import { listAllVideosAPI, getVideoEntriesCountAPI, GET_VIDEO_SEARCH_LIST, LIST_ALL_VIDEOS } from "apis/controllers/video/VideoController";
import { listAllModulesAPI, GET_MODULE_SEARCH_LIST, LIST_ALL_MODULES } from "apis/controllers/video/VideoModuleController";


function VideoList(props) {

	const urlLocation = window.location.href;
	const paginationRegEx = new RegExp("(?<=page=)[0-9]");

	const [alert, setAlert] = useState(null),
		[spinner, setSpinner] = useState(""),
		// will be used for infinite scrolling and pagination
		[currentPage, setCurrentPage] = useState(
			urlLocation.includes("page") ? paginationRegEx.exec(urlLocation)[0] - 1 : 0
		),
		[pageSize, setPageSize] = useState(12),
		// Auth-related modals
		[signUpModal, setSignUpModal] = useState(null),
		[logInModal, setLoginModal] = useState(null),
		// DOM view states
		[pageNumbersDom, setPageNumbersDom] = useState(null),
		[videosDom, setVideosDom] = useState([]),
		[videosDom1, setVideosDom1] = useState([]),
		[modulesDom, setModulesDom] = useState([]),
		[searchDom, setSearchDom] = useState(),
		[showPopUp, setShowPopUp] = useState(false),
		// json-ld
		[structuredListJSON, setStructuredListJSON] = useState();

	// function reference
	const closeHandlerPointerReferenceRef = useRef(),
		clearSetInputPointerReferenceRef = useRef(),
		// value ref
		searchStringsCountRef = useRef(),
		videoPurchaseRef = useRef();
	let blockingListCall = false;
	const setAuthModals = {// For passing into createVideoBlocks
		setSignUpModal,
		setLoginModal
	};

	// onMount effects
	useEffect(() => {
		createSearchLists();
	}, [props.ownerId]);

	useEffect(() => {
		createVideoDomBlocks();
		createModuleDomBlocks();
		// TODO: revisit infinite scrolling later
		// checkCurrentScrollPosition();
	}, [currentPage]);

	function createSearchLists() {
		const apiArr = [
			{
				api: GET_VIDEO_SEARCH_LIST,
				type: GET
			}, {
				api: GET_MODULE_SEARCH_LIST,
				type: GET
			}
		];

		promiseAll(apiArr, resp => {
			const videoSearchList = resp[0].data;
			const moduleSearchList = resp[1].data;

			let masterSearchMap = {};
			for (const [key, value] of Object.entries(videoSearchList)) {
				masterSearchMap[key] = value + "_video";
			}
			for (const [key, value] of Object.entries(moduleSearchList)) {
				masterSearchMap[key] = value + "_module";
			}

			createMasterSearchList(masterSearchMap);
		});
	}

	// for modules and videos
	function createMasterSearchList(searchMap) {
		searchStringsCountRef.current = Object.entries(searchMap).length;
		if (Object.keys(searchMap).length) {
			setSearchDom(
				<Search searchType="videos" customSearchMap={searchMap}
					chosenResultClickHandler={getSearchItem}
					clearSearchHandler={createVideoDomBlocks}
					resultsGroupEnterHandler={getItemSearchResults}
					closeHandlerPointerReference={closeHandlerPointerReferenceRef}
					clearSetInputPointerReference={clearSetInputPointerReferenceRef}
					inputPlaceholder={messageMap("video.listPage.placeholder.search", "generic")} />
			);
		}
	}

	function createModuleDomBlocks() {
		const payloadMap = {
			module: {}
		};
		fetchSearchResults(payloadMap, true);
	}

	function createVideoDomBlocks() {
		const payloadMap = {
			video: {
				ownerId: localStorage.getItem("ownerId")
			}
		};
		fetchSearchResults(payloadMap, true);
	}

	function getItemSearchResults(itemDetails) {
		let idsToFetch = [];
		let itemTypeToIds = {};
		for (let i = 0; i < itemDetails.length; ++i) {
			const itemId = itemDetails[i].id;
			idsToFetch.push(itemId);

			const itemType = itemDetails[i].itemType;
			if (itemTypeToIds[itemType]) {
				itemTypeToIds[itemType].push(itemId);
			}
			else {
				itemTypeToIds[itemType] = [itemId];
			}
		}

		if (searchStringsCountRef.current.length !== idsToFetch.length && idsToFetch.length) {
			const itemTypeToIdsKeys = Object.keys(itemTypeToIds);
			let payloadMap;
			if (itemTypeToIdsKeys.length === 1) {
				const searchType = itemTypeToIdsKeys[0];
				payloadMap = idsToFetch.length
					? {
						[itemTypeToIdsKeys.includes("video") ? "video" : "module"]: {
							videoIds: itemTypeToIds[searchType]
						}
					}
					: {
						[itemTypeToIdsKeys.includes("video") ? "video" : "module"]: {
							ownerId: localStorage.getItem("ownerId")
						}
					};
			}
			else if (itemTypeToIdsKeys.length === 2) {
				payloadMap = {
					video: {
						videoIds: itemTypeToIds["video"]
					},
					module: {
						videoIds: itemTypeToIds["module"]
					}
				}
			}

			fetchSearchResults(payloadMap);
		}
	}

	function getSearchItem(video, itemDetails) {
		clearSetInputPointerReferenceRef.current.set(video);
		const payloadMap = {
			[itemDetails.itemType]: {
				videoIds: [itemDetails.id]
			}
		};
		fetchSearchResults(payloadMap);
	}

	function fetchSearchResults(payloadMap, showOtherGroup = false) {
		const payloadMapKeys = Object.keys(payloadMap);

		let pathVariables = {
			pageCount: currentPage,
			pageSize: pageSize,
			downloadTypes: ["thumbnail"]
		};

		setSpinner(
			<Spinner />
		);
		// doesn't include both 'module' and 'video', just one or the other
		if (payloadMapKeys.length === 1) {
			let listAllAPI = listAllVideosAPI;
			let actualPayload = payloadMap["video"];
			if (payloadMapKeys.includes("module") && !payloadMapKeys.includes("video")) {
				delete pathVariables.downloadTypes;
				listAllAPI = listAllModulesAPI;
				actualPayload = payloadMap["module"];
			}

			listAllAPI(pathVariables, actualPayload, response => {
				if (payloadMapKeys.includes("module")) {
					renderModules(response, actualPayload);

					if (!showOtherGroup) {
						setVideosDom(null);
						setVideosDom1(null);
					}
				}
				else if (payloadMapKeys.includes("video")) {
					renderVideos(response, actualPayload);

					if (!showOtherGroup) {
						setModulesDom(null);
					}
				}

				setSpinner(null);
			}, error => {
				console.log("error: ", error);
				// history.push({
				// 	pathname: "/maintenance"
				// });
			});
		}
		else {
			const pathVariables = {
				pageCount: currentPage,
				pageSize: pageSize
			};

			const apiArr = [
				{
					api: $replace(LIST_ALL_VIDEOS, { ...pathVariables, downloadTypes: ["thumbnail"] }),
					type: POST,
					payload: payloadMap["video"]
				}, {
					api: $replace(LIST_ALL_MODULES, pathVariables),
					type: POST,
					payload: payloadMap["module"]
				}
			];

			promiseAll(apiArr, resp => {
				renderVideos(resp[0].data, payloadMap["video"]);
				renderModules(resp[1].data, payloadMap["module"]);
				setSpinner(null);
			});
		}
	}

	function renderVideos(apiResponse, apiPayload) {
		let videoBlocks = createVideoBlocks(apiResponse, entityClickHandler, setAuthModals);
		setVideosDom(videoBlocks[0]);
		setVideosDom1(videoBlocks[1]);
		setStructuredListJSON(apiResponse.length ? generateStructuredData(apiResponse, "videos") : null);

		const videoIdsCount = apiPayload.videoIds ? (apiPayload.videoIds.length) : null;
		createPageNavigation(videoIdsCount);
	}

	function renderModules(apiResponse, apiPayload) {
		setModulesDom(createModuleBlocks(apiResponse, entityClickHandler));
		// TODO: include section in structured data for modules
		// setStructuredListJSON(response.length ? generateStructuredData(response, "videos") : null);

		const videoIdsCount = apiPayload.videoIds ? (apiPayload.videoIds.length) : null;
		createPageNavigation(videoIdsCount);
	}

	function closeSearch() {
		let closeHandler = closeHandlerPointerReferenceRef.current;
		if (closeHandler) {
			closeHandler();
		}
	}

	function createPageNavigation(itemIdsCount) {
		if (itemIdsCount === null) {
			getVideoEntriesCountAPI(null, response => {
				createPagination(response);
			});
		}
		else {
			createPagination(itemIdsCount);
		}

		function createPagination(pageCount) {
			const numberOfPages = pageCount / pageSize;
			const finalPageNumber = Math.ceil(numberOfPages);

			setPageNumbersDom(
				<Pagination baseUrl="/" itemCount={pageCount} currentPage={currentPage}
					pageSize={pageSize} totalPageCount={finalPageNumber}
					pageHandler={goToPage} />
			);
		}
	}

	/**
	 * @param {Number / String} pageNumber can be number (1, 2, 3, etc.) or string ('previous', 'next')
	 */
	function goToPage(pageNumber, totalPageNumber) {
		goToPageHandler(pageNumber, currentPage, totalPageNumber, setCurrentPage);
	}

	function entityClickHandler(entityData) {
		const price = entityData.price;

		if (price) {
			let thumbnailFile = ((entityData.downloadTypeToFiles || {}).thumbnail || [{}])[0].file;
			videoPurchaseRef.current = {
				price: price,
				thumbnail: thumbnailFile,
				thumbnailAlt: entityData.thumbnailDescription,
				videoId: entityData.videoId,
				channel: entityData.uploaderUsername,
				title: entityData.title.replaceAll(" ", "_")
			};
			setShowPopUp(true);
		}
	}

	function setToPagination() {
		console.log("Setting to pagination");
	}

	function setToInfiniteScroll() {
		console.log("Setting to infinite scroll");
	}

	function checkCurrentScrollPosition() {
		const rootElement = document.getElementById("root");
		const debounceBottomCheck = debounce(scrollEventHandler, 1000);
		// rootElement.addEventListener("wheel", debounceBottomCheck);
		rootElement.addEventListener("wheel", scrollEventHandler);
	}

	function scrollEventHandler(e) {
		// simplified approach to detecting if user reached the bottom page: http://blog.sodhanalibrary.com/2016/08/detect-when-user-scrolls-to-bottom-of.html#.YuX4mnbMKHs
		if (!blockingListCall && (window.innerHeight + window.pageYOffset) >= (document.body.scrollHeight - 200)) {
			// if (localStorage.getItem("blockingListCallLS") === "false" && (window.innerHeight + window.pageYOffset) >= (document.body.scrollHeight - 200)) {
			// using a simple global variable, because we want it to be as fast as possible
			// doing the react way is slow and doesn't end up functioning well
			// it's very important that this is the first statement in this code block
			blockingListCall = true;

			// const nextPage = pageCount.current + 1;
			// pageCount.current = nextPage;
			const nextPage = currentPage + 1;
			setCurrentPage(nextPage);
			console.log("Reached the bottom. Current page count: ", nextPage);

			const payload = {
				pageCount: nextPage,
				downloadTypes: ["thumbnail"]
			};

			setSpinner(<Spinner />);
			listAllVideosAPI(payload, response => {
				const updatedVideosDom = videosDom.concat(createVideoBlocks(response, entityClickHandler, setAuthModals));
				setVideosDom(updatedVideosDom);
				setSpinner(null);

				if (response.length === 10) {
					blockingListCall = false;
				}
			});
		}
	}

	return (
		<Fragment>
			{alert}

			<div className="video-list" role="button" tabIndex={0}
				onClick={closeSearch} onKeyDown={e => escape(e, closeSearch, [e])}>
				<Helmet>
					<title>{messageMap("videoList.title", "headerTag")}</title>
					<meta name="description" content={messageMap("videoList.description", "headerTag")}></meta>
					<script type="application/ld+json">{structuredListJSON}</script>
				</Helmet>

				<h1 className="title">
					{messageMap("video.listPage.title", "generic")}
				</h1>

				{searchDom}

				{/* TODO: enable when infinite scrolling is supported. We'll use simply pagination for now*/}
				{/* <div className="page-toggle-container">
					<PillToggle option1="Infinite Scroll" option2="Pagination"
											option1Handler={setToInfiniteScroll} option2Handler={setToPagination}
											chosenOption="option1">
					</PillToggle>
				</div> */}

				<div className="list">
					{spinner}

					{videosDom}
					{modulesDom}


					{videosDom1}
				</div>

				{pageNumbersDom}
			</div>

			{signUpModal}
			{logInModal}

		</Fragment>
	);
}

VideoList.propTypes = {
	// redux props
	ownerId: PropTypes.string,
	username: PropTypes.string,
	currencySymbol: PropTypes.string
};

export default connect(
	account,
	null
)(VideoList);
