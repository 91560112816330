/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";


const BASE = "/services/resource/purchase";

const PURCHASE_VIDEO = BASE + "/${videoId}";
const PURCHASE_HINT = BASE + "/hint?asItem=${asItem}";
const PURCHASE_ANSWER = BASE + "/answer?asItem=${asItem}";
const PURCHASE_LEVEL = BASE + "/level?asItem=${asItem}";


/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = {
 * 	ownerId = String,
 * 	filters = [String]
 * }
 */
export function purchaseVideoAPI(pathVariables, payload, callback) {
	const api = $replace(PURCHASE_VIDEO, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariable = {
 * 	asItem: Boolean
 * }
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	resourceCost: Number
 * }
 */
export function purchaseHintAPI(pathVariable, payload, callback) {
	const api = $replace(PURCHASE_HINT, pathVariable);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariable = {
 * 	asItem: Boolean
 * }
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	resourceCost: Number
 * }
 */
export function purchaseAnswerAPI(pathVariable, payload, callback) {
	const api = $replace(PURCHASE_ANSWER, pathVariable);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariable = {
 * 	asItem: Boolean
 * }
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	resourceCost: Number
 * }
 */
export function purchaseLevelAPI(pathVariable, payload, callback) {
	const api = $replace(PURCHASE_LEVEL, pathVariable);
	request(api, POST, payload, callback);
}

