import React, {Fragment} from "react";

import { messageMapGeneric, messageMapImage } from "Utilities/MessageMaps";

import moneyAsset from "assets/images/marketing/money.svg";
import penOnPaperAsset from "assets/images/marketing/penOnPaper.svg";
import personTrainingAsset from "assets/images/marketing/personTraining.svg";
// TODO: update this with video produced by our contractor
import placeholderVideoAsset from "assets/videos/placeholderVideo.mp4";


function ExerForTutors() {
	const iconWithDetails = [
		{
			key: "moneyAsset",
			icon: moneyAsset,
			alt: messageMapImage("tutoring.whyExer.money"),
			text: messageMapGeneric("tutoringPage.whyExer.whyUse.pricingFlexibility")
		}, {
			key: "penOnPaperAsset",
			icon: penOnPaperAsset,
			alt: messageMapImage("tutoring.whyExer.penOnPaper"),
			text: messageMapGeneric("tutoringPage.whyExer.whyUse.weCare")
		}, {
			key: "personTrainingAsset",
			icon: personTrainingAsset,
			alt: messageMapImage("tutoring.whyExer.personTraining"),
			text: messageMapGeneric("tutoringPage.whyExer.whyUse.upToDate")
		}
	];

	return (
		<Fragment>
			<div className="section-header">
				{messageMapGeneric("tutoringPage.whyExer.amazingForTutors")}
			</div>
			<div className="video-container">
				{/* TODO: update this once we have a video */}
				TBD
				{/* <video className="video" controls controlsList="nodownload" disablePictureInPicture>
					<source src={placeholderVideoAsset} type="video/mp4"/>
					<track default kind="captions" srcLang="en"/>
				</video> */}
			</div>

			<div className="section-header">
				{messageMapGeneric("tutoringPage.whyExer.whyUse.text")}
			</div>
			<div className="details-container">
				{
					iconWithDetails.map(iconDetails =>
						<div key={iconDetails.key}>
							<img src={iconDetails.icon} alt={iconDetails.alt} className="detail-image"/>
							<div className="details">
								{iconDetails.text}
							</div>
						</div>
					)
				}
			</div>
		</Fragment>
	);
}

export default ExerForTutors;