import React, { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { userNameValidator } from "Utilities/Validators/InputValidators";
import { checkHasExplicitWords } from "Utilities/Validators/ContentValidator";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import { usernameAvailabilityAPI } from "apis/controllers/person/AccountsController";

import checkAsset from "assets/icons/home/check.svg";
import exAsset from "assets/icons/home/ex.svg";


function EditUsernameModalContent(props) {

	const [newUsername, setNewUsername] = useState(props.username),
		[isNewUsernameValid, setIsNewUsernameValid] = useState(true),
		[usernameAvailability, setUsernameAvailability] = useState(null);

	const usernameInputRef = useRef(),
		updateUsernameButtonRef = useRef(),
		usernameAvailabilityRef = useRef();

	function onChangeUsername(e) {
		const target = e.target,
			text = target.value;

		setNewUsername(text);

		if (text === "") {
			target.className = "page-field-input";
			return;
		}

		let usernameValidationResults = userNameValidator(text);
		updateUsernameButtonRef.current.className = "update-username not-allowed";
			if (usernameValidationResults.passed && !checkHasExplicitWords(text)) {
				setIsNewUsernameValid(true);
				target.className = "page-field-input";
				usernameAvailabilityRef.current.className = "username-check";
			}
			else {
				setIsNewUsernameValid(false);
				target.className = "page-field-input warning";
				usernameAvailabilityRef.current.className = "username-check not-allowed";
			}
	}

	function checkUsernameAvailability(e) {
		if (e.target.className === "username-check not-allowed" || !newUsername) {
			return;
		}

		usernameAvailabilityAPI({ username: newUsername }, resp => {
			let isUsed;

			if (resp) {
				isUsed = exAsset;
				setIsNewUsernameValid(false);
				usernameInputRef.current.className = "page-field-input invalid";
				updateUsernameButtonRef.current.className = "update-username not-allowed";
			}
			else {
				isUsed = checkAsset;
				setIsNewUsernameValid(true);
				updateUsernameButtonRef.current.className = "update-username";
			}

			const style = {
				"display": "inline-block",
				"marginLeft": "5px",
				"height": "25px"
			};

			setUsernameAvailability(
				<img src={isUsed} alt={messageMap("checkUsername.check", "image")} style={style} ></img>
			);
		});
	}

	function checkBeforeUpdatingForm() {
		if (isNewUsernameValid && updateUsernameButtonRef.current.className === "update-username") {
			props.updateField("username", newUsername);

			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	return (
		<Fragment>
			<div className="field-requirements">
				{messageMap("account.username.text", "validation")}
			</div>

			<div className="field-name">
				{messageMap("account.fields.modal.newUsername", "generic")}
			</div>
			<input placeholder={props.username} className="page-field-input"
					maxLength={20}
					onChange={onChangeUsername} ref={usernameInputRef}>
			</input>

			<div className="username-check-container">
				<button className="username-check not-allowed"
					onClick={checkUsernameAvailability} ref={usernameAvailabilityRef}>
					{messageMap("account.fields.usernameAvailability", "generic")}
				</button>
				{usernameAvailability}
			</div>

			<button className="update-username not-allowed"
				onClick={checkBeforeUpdatingForm} ref={updateUsernameButtonRef} >
				{messageMap("account.changeUsername", "button")}
			</button>
		</Fragment>
	);
}

EditUsernameModalContent.propTypes = {
	setModal: PropTypes.func.isRequired,
	username: PropTypes.string,
	updateField: PropTypes.func.isRequired
};

export default EditUsernameModalContent;
