import React, {useEffect, useState, Fragment} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import {NODES_MAP, generateKnowledgeNetwork} from "diagrams/utilities/NetworkGenerator";


/**
 * @param {Object} node // contains details of node
 * @param {String} workbookType // grades, degrees, or jobs
 * @param {Boolean} hasBackButton? // to include back button or not
 * @param {Boolean} isPartOfModal // to differentiate functionalities between topic assignment and practice topics
 * @param {Boolean} includeChevron? // default: false, to display chevron or not
 * @param {Function} missingItemSuggestionHandler? //  handler to trigger when there aren't any topics for the specific item
 * @param {Function} topicClickHandler? // customized handler when clicking on practice category
 */
function RowNesting(props) {

	const [subItemRows, setSubItemRows] = useState(null),
		[topicBreakdownDom, setTopicBreakdownDom] = useState();

	useEffect(() => {
		renderDom();
	}, []);

	function renderDom() {
		const innerDom = (
			<Fragment>
				<div className="item-row-label" workbookname={props.workbookType}
							nodeid={props.rowType === "topic" ? props.node.id : null}>
					{
						props.isPartOfModal
						? props.node.label
						: (
								props.topicClickHandler && props.isPartOfModal === false && props.node.practiceCategory != null
								? props.node.label
								: props.node.label + ` [${messageMap("knowledgeTree.quest.construction", "generic")}]`
							)
					}
				</div>
				{
					props.includeChevron ? 
					(
						<div className="chevron-down"></div>
					)
					: null
				}
			</Fragment>
		);

		if (props.rowType === "topic" && !props.isPartOfModal) {
			setTopicBreakdownDom(
				<Link to={{
					pathname: "/exercise?subject=" + props.node.rootLabel + "&topic=" + props.node.id.replaceAll(" ", "-"),
					state: {
						topicId: props.node.id,
						topicDetails: props.node,
						subject: props.node.rootLabel,
						workbook: props.workbookType,
						isJourney: false
					}
				}} className="row-item" onClick={defaultLinkAction}>
					{innerDom}
				</Link>
			);
		}
		else {
			setTopicBreakdownDom(
				<div className="row-item" tabIndex={0} role="button"
							nodeid={props.rowType === "topic" ? props.node.id : null}
							workbookname={props.workbookType}
							onClick={e => displayItemBreakdown(e.target, props.node.id)}
							onKeyPress={e => onKeyDown(e, displayItemBreakdown, [e.target, props.node.id])}>
					{innerDom}
				</div>
			);
		}
	}

	function displayItemBreakdown(target, topic) {
		if (props.isPartOfModal
				|| (props.topicClickHandler && props.isPartOfModal === false && props.node.practiceCategory != null)) {
			let originalChevronDirection = toggleChevronAndGetDirection(target);

			if (originalChevronDirection) {
				if (originalChevronDirection === "up") {
					setSubItemRows(null);
				}
				else {
					generateKnowledgeNetwork(topic, 1, 0, props.workbookType, "id",
																	() => displaySubItems(topic));
				}
			}
	
			if (props.topicClickHandler && target.getAttribute("nodeid")) {
				props.topicClickHandler(target.getAttribute("nodeid"), props.node);
			}
		}
	}

	function toggleChevronAndGetDirection(target) {
		if (props.includeChevron) {
			let originalChevronDirection;
	
			if (target.className === "row-item") {
				if (target.childNodes[1].className === "chevron-down") {
					target.childNodes[1].className = "chevron-up";
					originalChevronDirection = "down";
				}
				else {
					target.childNodes[1].className = "chevron-down";
					originalChevronDirection = "up";
				}
			}
			else if (["chevron-down", "chevron-up"].includes(target.className)) {
				if (target.className === "chevron-down") {
					target.className = "chevron-up";
					originalChevronDirection = "down";
				}
				else {
					target.className = "chevron-down";
					originalChevronDirection = "up";
				}
			}
			else if (["chevron-down", "chevron-up"].includes(target.nextElementSibling.className)) {
				if (target.nextElementSibling.className === "chevron-down") {
					target.nextElementSibling.className = "chevron-up";
					originalChevronDirection = "down";
				}
				else {
					target.nextElementSibling.className = "chevron-down";
					originalChevronDirection = "up";
				}
			}
	
			return originalChevronDirection;
		}
		else {
			return null;
		}
	}

	function displaySubItems(topic) {
		let subItemRows = [];
		if (NODES_MAP[props.workbookType].length > 1) {
			Array.isArray(NODES_MAP[props.workbookType]) && NODES_MAP[props.workbookType].forEach(node => {
				if (node.id !== topic) {
					let includeChevron = true;
					let rowType = "subtree";

					if (node.type.includes("topic")) {
						includeChevron = false;
						rowType = "topic"
					}
	
					subItemRows.push(
						<RowNesting key={node.id} workbookType={props.workbookType}
												node={node} includeChevron={includeChevron} rowType={rowType}
												missingItemSuggestionHandler={props.missingItemSuggestionHandler}
												topicClickHandler={props.topicClickHandler} isPartOfModal={props.isPartOfModal}/>
					);
				}
			});

			setSubItemRows(subItemRows);
		}
		else {
			props.missingItemSuggestionHandler("row", topic);
		}
	}

	return (
		<Fragment>
			<div className="row-nest" workbookname={props.workbookType}
						nodeid={props.rowType === "topic" ? props.node.id : null}>

				{topicBreakdownDom}

				{subItemRows}
			</div>
		</Fragment>
	);
}

RowNesting.propTypes = {
	node: PropTypes.object.isRequired,
	rowType: PropTypes.string,
	workbookType: PropTypes.string.isRequired,
	hasBackButton: PropTypes.bool,
	isPartOfModal: PropTypes.bool,
	includeChevron: PropTypes.bool,
	missingItemSuggestionHandler: PropTypes.func,
	topicClickHandler: PropTypes.func
}

export default RowNesting;