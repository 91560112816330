// CHAT CONSTANTS
// chat themes
export const CHAT_THEME_DEFAULT = "light";
export const CHAT_THEME_DARK = "dark";
export const CHAT_THEME_SUPPORT = "support";
export const CHAT_THEME_SUPPORT_DARK = "support-dark";
export const CHAT_THEME_EVENT = "event";
export const CHAT_THEME_EVENT_DARK = "event-dark";



