import React from "react";

import { messageMapDocument } from "Utilities/MessageMaps";

function TuteePolicyViolations(props) {
	return (
		<div className="section-div">
			<h2 className="head-label">
				{messageMapDocument("tuteePolicy.section3.title")}
			</h2>
			<p className="margin-bottom-spacing">
				{messageMapDocument("tuteePolicy.section3.point1.text")}
			</p>
			<ul>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.section3.point2.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.section3.point3.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.section3.point4.text")}
					</p>
				</li>
			</ul>
			<p className="margin-bottom-spacing">
				{messageMapDocument("tuteePolicy.section3.point5.text")}
			</p>
			<ul>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.section3.point6.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.section3.point7.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("tuteePolicy.section3.point8.text")}
					</p>
				</li>
			</ul>
		</div>
	);
}

export default TuteePolicyViolations;


