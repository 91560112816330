import React, { useEffect, useState, useRef, Fragment }  from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";

import {
	dayOfWeekToIntMap, monthMap, timeSlotsMap
} from "templates/utilities/CalendarUtilities";
import messageMap from "Utilities/MessageMaps";

import chevronAsset from "assets/icons/common/chevron.svg";


function Calendar(props) {

	// today's date
	const [currentDate, setCurrentDate] = useState(new Date()),
		// modified dates
		[newDate, setNewDate] = useState(new Date()),
		[newMonthDate, setNewMonthDate] = useState(),
		[chosenTimeSlot, setChosenTimeSlot] = useState(),
		[chosenDuration, setChosenDuration] = useState(),
		// DOM
		[slotDropdown, setSlotDropdown] = useState(),
		[days, setDays] = useState(),
		[availableTimeSlots, setAvailableTimeSlots] = useState();

	// value refs
	const tutorScheduleRef = useRef(),
		filledTimeSlotsRef = useRef(),
		dropdownVisibilityRef = useRef();

	useEffect(() => {
		parseTutorAvailability();
	}, []);

	useEffect(() => {
		createDays(newDate);
		createTimeSlots(null);

		setSlotDropdown(
			<Dropdown key={newDate.getDate()} customDropdownItemAttribute="sessionLength" dropdownItemClickHandler={createTimeSlots}
				customContainerClass="dropdown-session-interval" dropdownOptions={timeSlotsMap} preselectedKey={props.preSelectedTimeRange} />
		);
	}, [newDate]);

	function parseTutorAvailability() {
		let weekAvailability = {};

		props.schedule.split("|").forEach(schedule => {
			const tuple = schedule.split(":");
			if (tuple[1].includes("unavailable")) {
				weekAvailability[dayOfWeekToIntMap[tuple[0]]] = -1;
			}
			else {
				const fromMinToHourTuple = tuple[2].split("_");

				weekAvailability[dayOfWeekToIntMap[tuple[0]]] = {
					from: `${tuple[1]}_${fromMinToHourTuple[0]}`,
					to: `${fromMinToHourTuple[1]}_${tuple[3]}`
				};
			}
		});

		let filledSessionSlots = {};
		props.filledTimeSlots.forEach(timeSlot => {
			const filledSlot = new Date(Number(timeSlot.date));
			const monthDate = filledSlot.getMonth();
			const dayDate = filledSlot.getDate();
			const hoursDate = filledSlot.getHours();
			const minutesDate = filledSlot.getMinutes();

			if (filledSessionSlots[monthDate] == null) {
				filledSessionSlots[monthDate] = {};
			}
			if (filledSessionSlots[monthDate][dayDate] == null) {
				filledSessionSlots[monthDate][dayDate] = {};
			}
			if (filledSessionSlots[monthDate][dayDate][hoursDate] == null) {
				filledSessionSlots[monthDate][dayDate][hoursDate] = {};
			}

			filledSessionSlots[monthDate][dayDate][hoursDate][minutesDate] = timeSlot.duration;
		});

		tutorScheduleRef.current = weekAvailability;
		filledTimeSlotsRef.current = filledSessionSlots;
	}

	function createDays(newDate) {
		const numDaysThisMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
		const monthsStartingDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);

		let weekRows = [];
		let days = [];
		// beginning of month blank squares
		if (monthsStartingDay.getUTCDay() !== 0) {
			for (let i = 0; i < monthsStartingDay.getUTCDay(); ++i) {
				days.push(
					<button key={`starting-empty-${i}`} className="day blank"></button>
				);
			}
		}
		// dated squares
		let preselectedDayCounter = 1;
		for (let i = 0; i < numDaysThisMonth; ++i) {
			if (days.length === 7) {
				weekRows.push(
					<div key={weekRows.length} className="week-container">
						{days}
					</div>
				);
				days = [];
			}

			let dayClass = "day";
			if (tutorScheduleRef.current[days.length % 7] === -1) {
				dayClass = "day unavailable-day";
			}
			else if (newDate.getUTCMonth() === currentDate.getUTCMonth()) {
				if (currentDate.getDate() === i + 1) {
					dayClass = "day current";
				}
				else if (i + 1 < currentDate.getDate()) {
					dayClass = "day past-day";
				}
			}
			else if (tutorScheduleRef.current[days.length % 7] !== -1 && preselectedDayCounter) {
				dayClass = "day current";
				--preselectedDayCounter;
			}
			days.push(
				<button key={i} className={dayClass} onClick={e => createTimeSlotsForDay(e, dayClass)} >
					{i + 1}
				</button>
			);
		}
		// end of month blank squares
		if (days.length !== 7) {
			while (days.length !== 7) {
				days.push(
					<button key={`ending-empty-${days.length}`} className="day blank"></button>
				);
			}
		}
		weekRows.push(
			<div key={weekRows.length} className="week-container">
				{days}
			</div>
		);

		setNewMonthDate(monthMap[newDate.getUTCMonth()]);
		setDays(weekRows);
	}

	function goToPrevMonth() {
		if (newDate.getUTCFullYear() > currentDate.getUTCFullYear() || newDate.getUTCMonth() - 1 >= currentDate.getUTCMonth()) {
			const newMonthDate = new Date(newDate.getFullYear(), newDate.getMonth() - 1, 1);

			setNewMonthDate(monthMap[newDate.getUTCMonth() - 1]);
			setNewDate(newMonthDate);
		}
	}

	function goToNextMonth() {
		const newMonthDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);

		setNewMonthDate(monthMap[newDate.getUTCMonth() + 1]);
		setNewDate(newMonthDate);
	}

	function saveSelectedTimeSlot(e, selectedTimestamp) {
		clearSelectedTimeSlots();
		e.target.className = "session-slot clicked";

		setChosenTimeSlot(selectedTimestamp);
	}

	function clearSelectedTimeSlots() {
		document.querySelectorAll(`button[class="session-slot clicked"]`).forEach(prevSelectSlot => {
			prevSelectSlot.className = "session-slot";
		});
	}

	function createTimeSlots(e) {
		if (e == null) {
			setAvailableTimeSlots(generateTimeSlots(tutorScheduleRef.current, filledTimeSlotsRef.current, props.preSelectedTimeRange));
			setChosenDuration(props.preSelectedTimeRange);
		}
		else {
			const sessionLength = e.target.getAttribute("sessionLength");
			setAvailableTimeSlots(generateTimeSlots(tutorScheduleRef.current, filledTimeSlotsRef.current, Number(sessionLength)));
			setChosenDuration(sessionLength);
		}
	}

	function createTimeSlotsForDay(e, dayType) {
		if (["day current", "day"].includes(dayType)) {
			document.querySelectorAll(`button[class="day current"]`).forEach(prevSelectedDay => {
				prevSelectedDay.className = "day";
			});
			e.target.className = "day current";

			clearSelectedTimeSlots();
			setChosenTimeSlot(null);
			setNewDate(new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), e.target.innerText));
		}
	}

	function generateTimeSlots(weekAvailability, filledSessionSlots, sessionInterval) {
		let dayOfWeek = newDate.getDay();
		if (newDate.getUTCMonth() === currentDate.getUTCMonth() && newDate.getUTCDate() < currentDate.getUTCDate()) {
			dayOfWeek = currentDate.getDay();
		}
		while(tutorScheduleRef.current[dayOfWeek] === -1) {
			++dayOfWeek;
			dayOfWeek = dayOfWeek % 7;
		}

		let timeSlots = [];
		if (weekAvailability[dayOfWeek] !== -1) {
			const monthDate = newDate.getMonth();
			const dayOfMonth = newDate.getDate();

			let daysFilledSessionsSlots = [];
			if (filledSessionSlots[monthDate] && filledSessionSlots[monthDate][dayOfMonth]) {
				for (const [key, value] of Object.entries(filledSessionSlots[monthDate][dayOfMonth])) {
					const hour = Number(key);

					for (const [minStart, minDuration] of Object.entries(value)) {
						const filledStartingTime = (hour * 60) + Number(minStart);

						for (let i = filledStartingTime; i < filledStartingTime + minDuration; i += 15) {
							daysFilledSessionsSlots.push(i);
						}
					}
				}
			}

			const fromTime = weekAvailability[dayOfWeek].from.split("_");
			const toTime = weekAvailability[dayOfWeek].to.split("_");
			const adjustedStartingTime = (Number(fromTime[0]) * 60) + adjustedMinutes(Number(fromTime[1]));
			const adjustedEndingTime = (Number(toTime[0]) * 60) + adjustedMinutes(Number(toTime[1]));

			let preSelectedSlotCounter = 1;
			let i = adjustedStartingTime;
			while (i < adjustedEndingTime) {
				// determines if next interval includes an already filled slot
				const nextIntervalSlots = sessionInterval / 15;
				let invalidSlots = [];
				for (let j = 0; j < nextIntervalSlots; ++j) {
						invalidSlots.push(i + j * 15);
				}
				const includesFilledSlots = invalidSlots.some(el => daysFilledSessionsSlots.includes(el));

				if (!daysFilledSessionsSlots.includes(i) && !includesFilledSlots) {
					const amPm = i < 720 ? "AM" : "PM";
					const origHour = Math.floor(i / 60);
					const adjustedHour = origHour > 12 ? origHour - 12 : origHour;
					const minStr = String(i % 60);
					const time = `${adjustedHour}:${minStr.length === 1 ? `0${minStr}` :minStr} ${amPm}`;
					// decided to do this, so I don't have to create a Date() for every button - that's sloooowww
					const timestamp = monthDate + "_" + dayOfMonth + "_" + newDate.getDay() + "_" + origHour + "_" + minStr;

					let slotClassName = "session-slot";
					if (preSelectedSlotCounter === 1) {
						slotClassName = "session-slot clicked";
						setChosenTimeSlot(timestamp);
						--preSelectedSlotCounter;
					}

					timeSlots.push(
						<button key={time} className={slotClassName}
							onClick={e => saveSelectedTimeSlot(e, timestamp)}>
							{time}
						</button>
					);

					i += sessionInterval;
				}
				else {
					i += 15;
				}
			}

			dropdownVisibilityRef.current = "";
		}
		else {
			timeSlots = (
				<div className="unavailable-slots">
					{messageMap("tutoringPage.tutorList.pane.unavailable", "generic")}
				</div>
			);

			dropdownVisibilityRef.current = "hide";
		}

		return timeSlots;
	}

	function adjustedMinutes(unadjustedMinutes) {
		let adjustMinutes = unadjustedMinutes;

		if (unadjustedMinutes % 15 !== 0) {
			adjustMinutes = unadjustedMinutes - (unadjustedMinutes % 15) + 15;
		}

		return adjustMinutes;
	}

	function passSelectionToNext() {
		if (chosenTimeSlot) {
			const timestamp = chosenTimeSlot.split("_");

			props.nextStepHandler({
				month: timestamp[0],
				dayOfMonth: timestamp[1],
				dayOfWeek: timestamp[2],
				hour: timestamp[3],
				minutes: timestamp[4],
				duration: chosenDuration,
				rate: props.topicRates[chosenDuration],
				subject: props.subject,
				topic: props.topic
			});
		}
	}

	return (
		<Fragment>
			<div className="calendar-component">

				<div className="calendar">
					<div className="month-navigation-container">
						<button className="month-nav-button" onClick={goToPrevMonth} >
							<img className="nav-chevron left" src={chevronAsset} alt={`${messageMap("chevron.left.text", "image")}. ${messageMap("chevron.left.actions.prevMonth", "image")}`} />
						</button>
						<div className="current-month">
							{newMonthDate}
						</div>
						<button className="month-nav-button" onClick={goToNextMonth} >
							<img className="nav-chevron right" src={chevronAsset} alt={`${messageMap("chevron.right.text", "image")}. ${messageMap("chevron.left.actions.nextMonth", "image")}`} />
						</button>
					</div>

					<div className="day-of-week-container">
						{
							["S", "M", "T", "W", "T", "F", "S"].map(day =>
								<div key={day} className="day-of-week">
									{day}
								</div>
							)
						}
					</div>

					{days}
				</div>

				<div className="day-availabilities">
					<div className={`session-lengths-dropdown ${dropdownVisibilityRef.current}`}>
						{slotDropdown}
					</div>
					<div className="time-slots">
						{availableTimeSlots}
					</div>
				</div>
			</div>

			<div className="calendar-actions">
				<button className="action-button" onClick={passSelectionToNext} >
					{messageMap("tutoringPage.tutorList.pane.modal.continue", "generic")}
				</button>
				<div className="step-indicator">
					{messageMap("tutoringPage.tutorList.pane.modal.step12", "generic")}
				</div>
			</div>
		</Fragment>
	);
}

Calendar.propTypes = {
	preSelectedTimeRange: PropTypes.number.isRequired,
	subject: PropTypes.string.isRequired,
	topic: PropTypes.string.isRequired,
	topicRates: PropTypes.object.isRequired,
	schedule: PropTypes.string.isRequired,
	filledTimeSlots: PropTypes.array.isRequired,
	// props connecting current component to next modal component
	nextStepHandler: PropTypes.func.isRequired,
	nextData: PropTypes.object
};

export default Calendar;

