export const labelPlaceholderMessageMap = {
	yes: {
		text: "Yes"
	},
	no: {
		text: "No"
	},
	knowledgeTree: {
		view: {
			saved: "Views"
		}
	},
	search: "Search for anything",
	searchClasses: "Search for classes here...",
	email: "Email",
	password: {
		new: "New password",
	},
	feedback: "Feedback / suggestions",
	comment: "Add a comment...",
	tutoring: {
		profile: {
			firstName: "First name",
			lastName: "Last name",
			fullName: "Full name",
			linkedIn: "LinkedIn profile url",
			website: "Personal website url",
			phone: "Phone",
			email: "Email",
			flatRate: "Flat rate",
			variableRate: "Variable rate",
			yes: "Yes",
			no: "No",
			unavailable: "Unavailable"
		},
		requests: {
			now: "Now",
			later: "Schedule Later",
			allTutors: "All tutors",
			savedTutors: "Saved tutors"
		},
		sessions: {
			misconduct: "Sexual misconduct",
			personalInfo: "Shared personal contact information",
			money: "Exchanging money outside the platform",
			profanity: "Profanity and/or inappropriate language",
			other: "Other",
			optional: "Optional"
		}
	},
	payouts: {
		card: "card",
		bank: "bank",
		cardNumber: "card number",
		securityCode: "security code",
		checkingAccount: "checking account number",
		routingNumber: "routing number"
	},
};