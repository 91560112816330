import React from "react";

import { messageMapGeneric } from "Utilities/MessageMaps";

export const TUTORING_PAYMENT_STRUCTURE = [
	messageMapGeneric("profilePage.tutoring.modal.requirements.p32"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p33"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p33a")
];

function PaymentStructure(props) {
	return (
		<div>
			<div className="title">
				{messageMapGeneric("tutoringPage.faqs.tutors.payoutStructure")}
			</div>

			{
				TUTORING_PAYMENT_STRUCTURE.map((requirement, index) =>
					<div key={`requirement_${index}`} className="faq-text">
						{requirement}
					</div>
				)
			}
		</div>
	);
}

export default PaymentStructure;

