/* eslint-disable no-template-curly-in-string */
import request, { POST } from "Utilities/Fetches";


const BASE = "/services/person/progress";

const GET_PERSON_PROGRESS = BASE + "/get";

const GET_QUESTS_PROGRESS = BASE + "/get/quests/progress";

const SAVE_QUESTS_PROGRESS = BASE + "/save/quests/progress";
const SAVE_QUEST_POINTS_PROGRESS = BASE + "/save/quest/points";
const SAVE_QUEST_LEVEL_PERCENTAGE = BASE + "/save/quest/levelPercentage";
const SAVE_QUEST_LEVEL_PROGRESS = BASE + "/save/quest/level";
const UPDATE_INVENTORY_ITEM_COUNT = BASE + "/update/inventory";


/**
 * @param {String} payload = ownerId
 */
export function getPersonProgressAPI(payload, callback) {
	request(GET_PERSON_PROGRESS, POST, payload, callback);
}

/**
 * @param {String} payload = ownerId
 */
export function getQuestProgressAPI(payload, callback) {
	request(GET_QUESTS_PROGRESS, POST, payload, callback);
}

/**
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	stringToStringMap: {
 * 		[subject]: topic
 * 	}
 * }
 */
export function saveQuestProgressAPI(payload, callback) {
	request(SAVE_QUESTS_PROGRESS, POST, payload, callback);
}

/**
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	stringToIntegerMap: {
 * 		[subject]: points
 * 	}
 * }
 */
export function saveQuestExpPointsAPI(payload, callback) {
	request(SAVE_QUEST_POINTS_PROGRESS, POST, payload, callback);
}

/**
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	stringToIntegerMap: {
 * 		[subject]: percentage
 * 	}
 * }
 */
export function saveQuestLevelPercentageAPI(payload, callback) {
	request(SAVE_QUEST_LEVEL_PERCENTAGE, POST, payload, callback);
}

/**
 * @param {Object} payload = {
 * 	ownerId: String
 * 	stringToIntegerMap: {
 * 		[subject]: points
 * 	}
 * }
 */
export function saveQuestLevelAPI(payload, callback) {
	request(SAVE_QUEST_LEVEL_PROGRESS, POST, payload, callback);
}

/**
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	stringToIntegerMap: {
 * 		[inventoryName]: newQuantity
 * 	}
 * }
 */
export function updateInventoryItemCountAPI(payload, callback) {
	request(UPDATE_INVENTORY_ITEM_COUNT, POST, payload, callback);
}

