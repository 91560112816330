import React from 'react';
import PropTypes from "prop-types";

import Alert, {SUCCESS, ERROR} from "templates/Alert";

import {updateNoteAPI, deleteNoteAPI} from "apis/controllers/video/VideoNoteController";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import {truncateString} from "Utilities/Strings";

/**
 * @param {Array} noteList objects array = {
 * 	videoTs: string, 	// video timestamp when note was saved
 * 	note: string, 		// saved note
 * }
 * @param {Function} alertHandler function passed from parent to handler alerts
 * @param {Function} closeAlertHandler function passed from parent to close alerts
 * @param {Function} refreshHandler function passed from parent to refresh NoteList after deletes and saves
 * @param {Function} jumpToTimestamp function passed from parent to immediately jump to a specific timestamp
 * @param {String} ownerId user's ownerId
 */
export default class NoteList extends React.Component {
	constructor(props) {
		super(props);

		let tempState = {};
		this.props.noteList.forEach((note, index) => {
			tempState[`toggleState-${index}`] = "note-close"
			tempState[`display-${index}`] = "more";
			tempState[`note-${index}`] = note.note
		});

		tempState.alert = null;

		this.state = tempState;

		this.toggleList = this.toggleList.bind(this);
		this.saveNewNote = this.saveNewNote.bind(this);
		this.deleteExistingNote = this.deleteExistingNote.bind(this);
		this.jumpToTimestamp = this.jumpToTimestamp.bind(this);
	}

	toggleList(curState, index) {
		curState === "more"
		?
		this.setState({
			[`toggleState-${index}`]: "note-open",
			[`display-${index}`]: "less"
		})
		:
		this.setState({
			[`toggleState-${index}`]: "note-close",
			[`display-${index}`]: "more"
		});
	}

	saveNewNote(noteValue, noteDetails) {
		const payload = {
			ownerId: this.props.ownerId,
			videoId: noteDetails.videoId,
			videoTs: noteDetails.videoTs,
			note: noteValue
		};
		updateNoteAPI(payload, resp => {
			this.props.alertHandler(
				<Alert type={SUCCESS} msg={messageMap(resp, "api")} closeHandler={this.props.closeAlertHandler}/>
			);
			this.props.refresh();
		});
	}

	deleteExistingNote(noteDetails) {
		const payload = {
			ownerId: this.props.ownerId,
			videoId: noteDetails.videoId,
			videoTs: noteDetails.videoTs
		};
		deleteNoteAPI(payload, resp => {
			const type = resp === "note.delete.deleted" ? SUCCESS : ERROR;
			this.props.alertHandler(<Alert type={type} msg={messageMap(resp, "api")} closeHandler={this.props.closeAlertHandler}/>);
			this.props.refresh();
		});
	}

	jumpToTimestamp(timestamp) {
		this.props.jumpToTimestamp(timestamp);
	}

	render() {
		let noteList = [];

		this.props.noteList.forEach((note, index) => {
			noteList.push(
				<div key={note.videoTs} className="note-container">
					<div className="note-header" role="button" tabIndex={0}
							onClick={() => this.toggleList(this.state[`display-${index}`], index)}
							onKeyPress={e => onKeyDown(e, this.toggleList, [this.state[`chevron-${index}`], index])}>
						<div>{note.videoTs}</div>
						<div className="description">{truncateString(note.note, 50)}</div>
						<div className="more-less">{this.state[`display-${index}`]}</div>
					</div>
					<div className={this.state[`toggleState-${index}`]}>
						<textarea className="saved-note"
											onChange={(e) => this.setState({ [`note-${index}`] : e.target.value })}
											defaultValue={note.note}>
						</textarea>
						<button className="save" onClick={() => this.jumpToTimestamp(note.videoTs)}>
							{messageMap("navigate.to", "button")}
						</button>
						<button className="save" onClick={() => this.saveNewNote(this.state[`note-${index}`], note)}>
							{messageMap("update.text", "button")}
						</button>
						<button className="delete" onClick={() => this.deleteExistingNote(note)}>
							{messageMap("delete.text", "button")}
						</button>
					</div>
				</div>
			);
		});
		return (
			<div className="note-list">
				{this.state.alert}
				<div className="note-title">
					{messageMap("saved.notes", "button")}
				</div>
				{noteList}
			</div>
		);
	}
}

NoteList.propTypes = {
	noteList: PropTypes.arrayOf(PropTypes.object).isRequired,
	alertHandler: PropTypes.func.isRequired,
	closeAlertHandler: PropTypes.func.isRequired,
	refresh: PropTypes.func.isRequired,
	jumpToTimestamp: PropTypes.func.isRequired,

	ownerId: PropTypes.string.isRequired
};