import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import Dropdown from "templates/Dropdown";
import Calendar from "templates/Calendar";
import Modal from "templates/Modal";
import {askUserToSignUpFirst} from "templates/customModals/utilities/SignUpLoginUtilities";

import BookingConfirmationModal from "pages/Tutoring/subPages/components/BookingConfirmationModal";

import messageMap from "Utilities/MessageMaps";
import { LOW, MID, HIGH } from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import {topicsInSubjects} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";


function TopicDetailsPriceBreakdown(props) {

	const [rateDropdown, setRateDropdown] = useState(),
		[rateMap, setRateMap] = useState();

	const chosenTimeLengthRef = useRef("15");

	useEffect(() => {
		calculateRateBreakdown();
	}, [props.ownerId]);

	function calculateRateBreakdown() {
		const rate = Number(props.rate);
		const tutorProtectionFee = rate * 0.1;
		let rateBreakdown = {
			60: rate,
			45: (rate * 0.75) + tutorProtectionFee,
			30: (rate * 0.5),
			15: (rate * 0.25) + tutorProtectionFee
		};

		const tuteeFee = props.tutorTier === LOW ? 0.15 : props.tutorTier === MID ? 0.1 : 0.05;
		let calculatedRatesMap = {};
		for (const [key, value] of Object.entries(rateBreakdown)) {
			let forText = messageMap(`tutoringPage.tutorList.modal.${key}`, "generic");
			const sessionCost = ((value * tuteeFee) + value).toFixed(2);

			rateBreakdown[key] = `$${sessionCost} for ${forText}`;
			calculatedRatesMap[key] = sessionCost;
		}

		setRateMap(calculatedRatesMap);
		setRateDropdown(
			<Dropdown dropdownOptions={rateBreakdown} dropdownItemClickHandler={chooseBookingTimeLength}
				customDropdownItemAttribute="topicRate" customContainerClass="tutoring-rates" />
		);
	}

	function chooseBookingTimeLength(e) {
		chosenTimeLengthRef.current = e.target.getAttribute("topicRate")
	}

	function sendBookSessionRequest() {
		if (props.ownerId == null) {
			askUserToSignUpFirst(props.setModal, props.setModal1);
		}
		else {
			const carouselSteps = [
				{
					customClass: "calendar",
					title: `${messageMap("tutoringPage.tutorList.pane.modal.bookAn", "generic")} ${topicsInSubjects[props.subject][props.topic]} ${messageMap("tutoringPage.tutorList.pane.modal.session", "generic")}`,
					component: Calendar,
					attributes: {
						key: "calendar",
						filledTimeSlots: props.filledSlotSessions,
						schedule: props.schedule,
						topicRates: rateMap,
						subject: props.subject,
						topic: props.topic,
						preSelectedTimeRange: Number(chosenTimeLengthRef.current)
					}
				}, {
					customClass: "booking-confirmation",
					title: messageMap("tutoringPage.tutorList.pane.modal.confirmBooking", "generic"),
					component: BookingConfirmationModal,
					attributes: {
						key: "booking-confirmation",
						tutorFirstName: props.tutorFirstName,
						setAlert: props.setAlert
					},
				}
			];
	
			const stateProps = [
				{
					state: "carouselIndex",
					defValue: 0
				}
			];
	
			props.setModal1(
				<Modal closeType="xButton" closeHandler={closeModal} carouselContent={carouselSteps}
					stateProps={stateProps} />
			);
		}
		// TODO: automatically redirect users to Message page and have the chat history show that you sent a booking session

	}

	function closeModal(e) {
		if (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className)) {
			props.setModal1(null);
		}
	}

	return (
		<div className="topic-details-price-breakdown-message">
			<div className="topic-rate-container">
				<div className="topic">
					{topicsInSubjects[props.subject][props.topic]}
				</div>
				<div>
					{rateDropdown}
				</div>
			</div>
			<div className="button-container">
				{
					props.showBookOption && (
						<button className="book-button" onClick={sendBookSessionRequest}>
							{messageMap("tutoring.tutorList.book", "button")}
						</button>
					)
				}
			</div>
		</div>
	);
}

TopicDetailsPriceBreakdown.propTypes = {
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,

	showBookOption: PropTypes.bool.isRequired,
	subject: PropTypes.string.isRequired,
	topic: PropTypes.string.isRequired,
	rate: PropTypes.string.isRequired,
	tutorFirstName: PropTypes.string.isRequired,
	tutorTier: PropTypes.string.isRequired,
	filledSlotSessions: PropTypes.array,
	schedule: PropTypes.string.isRequired,

	// redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(TopicDetailsPriceBreakdown);

