import { SET_SHINY_NEURONS_CONVERSION_RATE } from "redux/actions/metadataConstants";

const initialState = {
	shinyNeuronsConversionRate: null
};

export default function (state = initialState, action) {
	if (action.type === SET_SHINY_NEURONS_CONVERSION_RATE) {
		return {
			...state,
			shinyNeuronsConversionRate: action.payload
		};
	}
	else {
		return state;
	}
}