
// list was taken from https://en.wikipedia.org/wiki/Outline_of_academic_disciplines
// TODO:
// 1.) transfer all the descriptions and even the subjects to message maps that can be translatable into different languages
// 2.) need to come up a key naming-scheme as well, since the code will break once we start using translated subjects; this is
//	due to the code's reliance on the keys as the DOM's key Prop
// NOTES*:
//	1.) keep all keys in alphabetic order
//	2.) 'description' is an OPTIONAL keyword
export default function getSubjectsMap() {
	return {
		Humanities: {
			History: {
				"African History": {},
				"American History": {},
				"Ancient History": {
					description: "Ancient history between 3000BC - AD 500",
					"Ancient Egypt": {},
					"Carthage": {},
					"Ancient Greek": {},
					"Ancient Roman": {},
					"Bronze Age": {},
					"Mesopotamian History": {},
					"The Stone Age": {}
				},
				"Asian History": {},
				"Australian History": {},
				"Economic History": {},
				"European History": {},
				"Latin American History": {},
				"Philosophical History": {},
				"Political History": {},
				"Religious History": {},
				"Scientific History": {},
				"Technological History": {},
				"World History": {}
			},
			"Languages and Literature": {
				"Comics Studies": {},
				"Creating Writing": {},
				"History of Literature": {},
				Lingquistics: {
					description: "The study of human language.",
					Composition: {
						description: "The study of writing, research, and instruction about writing."
					},
					Etymology: {
						description: "The study of words' evolution in meaning and form."
					},
					Grammar: {},
					Phonetics: {
						description: "The study of how humans perceive and produce sounds."
					},
					"Rhetoric": {
						description: "The art of persuasion."
					}
				},
				Poetry: {},
				"World Literature": {},
			},
			Law: {
				"Administrative Law": {},
				"Canon Law": {},
				"Civil Law": {
					"Animal Law/Rights": {},
					"Common Law": {
						description: "Law created by judges and tribunals through their written opinion."
					},
					"Contract Law": {},
					"Corporate Law": {},
					"Environmental Law": {},
					"Family Law": {},
					"Federal Law": {
						description: "Law created by the federal government."
					},
					"International Law": {
						description: "Law,, which includes rules, norms, and guidelines, recognized between states.",
					},
					"Labor Law": {},
					"Property Law": {},
					"Tax Law": {},
				},
				"Comparative Law": {
					description: "The study of different legals systems between countries."
				},
				"Competition Law": {
					description: "Law for regulation anti-competitive conducts by companies."
				},
				"Constitutional Law": {
					description: "Law that defines the roles, powers, and structures within a state."
				},
				"Criminal Law": {},
				"Islamic Law": {},
				"Jewish Law": {},
				"Jurisprudence": {
					description: "The philosophical, or scientific, study of law."
				},
				"Procedural Law": {
					description: "Law by which a court hears and determines what happens in a criminal, lawsuit, civil, or administrative proceedings."
				}
			},
			"Performing Arts": {
				description: "Arts that are performed for an audience.",
				Dance: {
					description: "The art of the body's movements that often bears aesthetic and symbolic meanings.",
					Choreography: {
						description: "The designing of sequence of movements that specifies the motions and/or forms."
					},
					"Dance Notation": {
						description: "Symbolic representation of dance movements."
					},
					"Dance History": {}
				},
				Film: {
					Animation: {
						// test1: "some"
					},
					Filmmaking: {},
					"Film Theory": {}
				},
				Music: {
					description: "Sound arranged in some form of harmony, rhythm, and melody.",
					Conducting: {
						description: "The art of directing a musical performance."
					},
					"Musical Composition": {
						description: "The writing of a musical piece."
					},
					"Music History": {},
					Musicology: {
						description: "Research and analysis of music."
					},
					"Music Theory": {
						description: "The study of the components of music to make music. This applies to learning how to play an instrument by learning its fundamentals."
					}
				},
				Theater: {
					description: "A performance by actors and/or actresses depicting an story.",
					Acting: {},
					Directing: {},
					"Theater History": {},
					"Musical Theater": {},
					Playwriting: {
						description: "The writing of plays."
					},
					Puppetry: {},
					"Stage Design": {}
				}
			},
			Philosophy: {
				"Applied Philosophy": {},
				Epistemology: {
					description: "Philosophy concerned with knowledge."
				},
				Ethics: {
					description: "Philosophy concerned with what is right and wrong."
				},
				Logic: {
					description: "Study of reasoning through formal and informal means."
				},
				Metaphysics: {
					description: "The study of the nature of reality, being, identity, change, and causality."
				},
				"Philosophical Traditions and Schools": {
					"Analytical Philosophy": {},
					Aristotelianism: {},
					"Eastern Philosophy": {},
					"Platonism": {}
				}
			},
			"Religious Studies": {
				"Anthropology of Religion": {},
				"History of Religion": {},
				"Psychology of Religion": {},
				"Sociology of Religion": {
					description: "The study of religious beliefs, practices and organizational forms using sociological methods."
				}
			},
			Theology: {
				description: "The study of the nature of the divine.",
				"Biblical Studies": {},
				"Buddhist Theology": {},
				"Christian Theology": {},
				"Hindu Theology": {},
				"Jewish Theology": {},
				"Muslim Theology": {}
			},
			"Visual Arts": {
				description: "Arts forms that are visual in nature.",
				Animation: {
					// test1: "some"
				},
				Calligraphy: {
					description: "Visual art related to writing."
				},
				"Digital Art": {},
				Drawing: {},
				Fashion: {},
				Painting: {},
				Photography: {},
				Sculpting: {}
			}
		},
		"Social Science": {
			Anthropology: { 
				description: "The study of humanity from the perspective of biology, cultures, societies, and linguistics.",
				"Biological Anthropology": {},
				"Linguistic Anthropology": {},
				"Cultural Anthropology": {},
				"Social Anthropology": {}
			},
			Archeology: {
				description: "The study of human activities through time by recovering cultural artifacts.",
				"Evolutionary Anthropology": {},
				"Maritime Archeology": {},
			},
			Economics: {
				description: "The study of production, distribution, and consumption of goods and services.",

			}
		}
	}
}

export function getKeysToInitiallyHide() {
	return [
		// Under Humanities
		"Performing Arts",
		"Visual Arts",
		"History",
		"Languages and Literature",
		"Law",
		"Philosophy",
		"Religious Studies",
		"Theology",
		// Social Science
		"Anthropology",
		"Archeology",
		"Economics"
	];
}
