import React from "react";
import {Link} from "react-router-dom";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";

import subjectsAsset from "assets/images/home/subjects.svg";
import clockAsset from "assets/images/home/clock.png";
import researchAsset from "assets/images/home/research.svg";
import assistanceAsset from "assets/images/home/assistance.jpg";
import premiumAsset from "assets/images/home/premium.svg";
import educateAsset from "assets/images/home/educate.svg";
import pointsAsset from "assets/images/home/points.png";
import userAsset from "assets/icons/navigation/user.svg";
import emailAsset from "assets/icons/navigation/email.svg";
import lockAsset from "assets/icons/navigation/lock.svg";

export const detailsSchema = {
	learnerDetails: [
		{
			img: subjectsAsset,
			title: messageMap("home.learners.category1.title", "generic"),
			// TODO: Switch to this once we actually have classes
			// description: `Choose from a growing number of courses created, maintained, and improved by a community 
			// of educators and learners.`
			description: messageMap("home.learners.category1.description", "generic")
		},
		// TODO: Only include once we're offering classes
		// {
		// 	img: clockAsset,
		// 	title: "COMPLETE TIME FLEXIBILITY",
		// 	description: `You control where and when you want to learn. You decide on how fast you want to progress
		// 	and your eventual career path. We'll be right there with you, guiding you at every step of
		// 	the way.`
		// },
		{
			img: researchAsset,
			title: messageMap("home.learners.category2.title", "generic"),
			description: messageMap("home.learners.category2.description", "generic")
		}, 
		// {
		// 	img: assistanceAsset,
		// 	title: "PERSONALIZED APPROACH",
		// 	description: `Get access to our top teachers, who will assist you in any academic challenges, and our supportive
		// 	counselors, who can help guide you in your future. Just contact them through text, or email, and they'll respond
		// 	within 24 hrs.`
		// }, {
		// 	img: collaborateJPGAsset,
		// 	title: "ACTIVE LEARNING COMMUNITY",
		// 	description: `Take part in research, discussions, and awesome projects in our vibrant community.`
		// }
	],
	creatorDetails: [
		{
			img: premiumAsset,
			title: messageMap("home.educators.category1.title", "generic"),
			description: messageMap("home.educators.category1.description", "generic")
		}, {
			// TODO: find a better image
			img: educateAsset,
			title: messageMap("home.educators.category2.title", "generic"),
			description: messageMap("home.educators.category2.description", "generic")
		}
		// TODO: include when this feature is ready
		// {
		// 	img: pointsAsset,
		// 	title: "EARN ADDITIONAL POINTS",
		// 	description: `If you help improve our courses, you will also earn points to help you purchase any course you want.`
		// }
	]
};

export function modalSchema(closeModal, openModalLaw, checkUsername) {
	return {
		"signUp": {
			subHeader: (
				<div>
					{messageMap("account.signUp.creditSignUp.text", "generic")}
					{/* to="/signUp/createFullAccount" */}
					<Link to="/learn" className="fullRegistration" onClick={e => {
						defaultLinkAction();
						closeModal(e);
					}}>
						{messageMap("account.signUp.creditSignUp.now", "generic")}
					</Link>
				</div>
			),
			disclaimer: (
				<div>
					{messageMap("account.signUp.agreement.text", "generic")}
					<button className="terms-policy" onClick={() => openModalLaw("terms")}>
						{messageMap("account.signUp.agreement.termOfUse", "generic")}
					</button>
					{messageMap("account.signUp.agreement.and", "generic")}
					<button className="terms-policy" onClick={() => openModalLaw("privacy")}>
						{messageMap("account.signUp.agreement.privacyPolicy", "generic")}
					</button>
				</div>
			),
			inputs: [
				{
					label: messageMap("account.fields.username", "generic"),
					maxLength: 20,
					required: true,
					value: "signUpUser",
					type: "username",
					inputStyle: {
						display: "inline-block"
					},
					icon: userAsset,
					tooltip: {
						classStr: "tooltip-bottom-left",
						subheader: messageMap("account.username.title", "validation"),
						type: "username",
						list: [
							{
								key: "hasLength",
								text: messageMap("account.username.text", "validation")
							},
						]
					},
					additionalElems: [
						{
							type: "button",
							props: {
								onClick: checkUsername,
								style: {
									"color": "white",
									"fontSize": "12px",
									"borderRadius": "5px",
									"border": "none",
									"backgroundColor": "#34B2E3",
									"padding": "2.5px 5px",
									"marginTop": "5px"
								}
							},
							children: [
								messageMap("account.fields.usernameAvailability", "generic")
							],
							onClickArgs: ["event", "signUpUser"]
						}
					]
				}, {
					label: messageMap("account.fields.email", "generic"),
					required: true,
					value: "signUpEmail",
					type: "email",
					icon: emailAsset
				}, {
					label: messageMap("account.fields.password", "generic"),
					required: true,
					value: "signUpPass",
					type: "password",
					togglePassText: true,
					icon: lockAsset,
					tooltip: {
						classStr: "tooltip-bottom-left",
						subheader: messageMap("account.password.title", "validation"),
						type: "password",
						list: [
							{
								key: "hasLength",
								text: messageMap("account.password.text1", "validation")
							}, {
								key: "hasUpper",
								text: messageMap("account.password.text2", "validation")
							}, {
								key: "hasLower",
								text: messageMap("account.password.text3", "validation")
							}, {
								key: "hasNumber",
								text: messageMap("account.password.text4", "validation")
							}, {
								key: "hasSpecial",
								text: messageMap("account.password.text5", "validation")
							}
						]
					}
				}, {
					label: messageMap("account.fields.passwordConfirm", "generic"),
					required: true,
					value: "signUpPassConfirm",
					type: "passwordConfirm",
					icon: lockAsset,
					onEnter: true
				}
			],
			stateProps: [
				{
					state: "signUpUser",
					defValue: {
						isValid: false,
						value: ""
					}
				}, {
					state: "signUpEmail",
					defValue: {
						isValid: false,
						value: ""
					}
				}, {
					state: "signUpPass",
					defValue: {
						isValid: false,
						value: ""
					}
				}, {
					state: "signUpPassConfirm",
					defValue: {
						isValid: false,
						value: ""
					}
				}, {
					state: "signUpPassTooltip"
				}, {
					state: "signUpUserTooltip"
				}
			]
		},
		"betaKey": {
			inputs: [
				{
					label: messageMap("account.fields.email", "generic"),
					required: true,
					value: "userEmail",
					icon: emailAsset,
					alt: messageMap("account.email", "image")
				}
			],
			stateProps: [
				{
					state: "userEmail",
					defValue: ""
				}
			]
		}
	};
}

export const modalLawSchema = {
	closeButtonStyle: {
		"position": "absolute",
		"margin-left": "195px",
		"margin-top": "15px"
	},
	modalContainerStyle: {
		"padding": "0px"
	}
};