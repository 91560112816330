import {ERROR, SUCCESS} from "templates/Alert";

import messageMap from "Utilities/MessageMaps";

export function parseDecisionResponse(resp) {
	const responseCode = resp.responseCode;
	let msg = messageMap(responseCode, "api");

	if (responseCode.includes("accepted.success")) {
		if (resp.urgency !== "now") {
			let date = new Date(Number(resp.urgency));
			date = date.toLocaleString().split(" ");
			let time = date[1].split(":");
			time = [time[0], time[1]].join(":");

			msg = messageMap(responseCode, "api") + " " + date[0] + " " + time + " " + date[2] + ". " + messageMap("tutor.application.accepted.success.scheduleNextSteps", "api");
		}
		else {
			msg = messageMap("tutor.application.accepted.success.text1", "api") + " " + messageMap("tutor.application.accepted.success.scheduleNextSteps", "api");
		}
	}

	return {
		msg: msg,
		executionStatus: responseCode.includes("success") ? SUCCESS : ERROR
	};
}