import React, {useEffect, useState, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";
import Alert, {ERROR} from "templates/Alert";
import Modal from "templates/Modal";

import {
	cancellationTimeRanges, OVER, BETWEEN, UNDER
} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import messageMap from "Utilities/MessageMaps";
import {numberValidator} from "Utilities/Validators/InputValidators";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import closeAsset from "assets/icons/common/close.svg";


function CancellationPolicy(props) {

	const [cancellationPolicies, setCancellationPolicies] = useState(props.cancellationPolicies),
		[cancellableCancellationPolicy, setCancellableCancellationPolicy] = useState();

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (Object.keys(props.cancellationPolicies).length) {
			const cancellationPoliciesDOM = createCancellablePolicies(props.cancellationPolicies);
			setCancellableCancellationPolicy(cancellationPoliciesDOM);
		}
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			hideModal();
		}
	}
	function hideModal() {
		setTimeout(() => {
			props.setModal(null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function createCancellationPolicy(e) {
		const selectedTimeRange = e.target.getAttribute("cancellationKey");

		if (selectedTimeRange === "chooseTimeRange") {
			return;
		}

		let timeRangeDOM;
		if (selectedTimeRange === OVER) {
			timeRangeDOM = (
				<div className="multi-dom-line">
					<div>
						{messageMap("profilePage.tutoring.modal.cancellation.over", "generic")}
					</div>
					<input className="page-field-value" type="number"
						onChange={e => updateCancellationPolicy(e, OVER)}></input>
					<div>
						{`${messageMap("profilePage.tutoring.modal.cancellation.hours", "generic")}, `}
					</div>
				</div>
			);
		}
		else if (selectedTimeRange === BETWEEN) {
			timeRangeDOM = (
				<div className="multi-dom-line">
					<div>
						{messageMap("profilePage.tutoring.modal.cancellation.between", "generic")}
					</div>
					<input className="page-field-value" type="number"
						onChange={e => updateCancellationPolicy(e, BETWEEN, 1)}></input>
					<div>
						{messageMap("profilePage.tutoring.modal.cancellation.to", "generic")}
					</div>
					<input className="page-field-value" type="number"
						onChange={e => updateCancellationPolicy(e, BETWEEN, 2)}></input>
					<div>
						{`${messageMap("profilePage.tutoring.modal.cancellation.hours", "generic")}, `}
					</div>
				</div>
			);
		}
		else if (selectedTimeRange === UNDER) {
			timeRangeDOM = (
				<div className="multi-dom-line">
					<div>
						{messageMap("profilePage.tutoring.modal.cancellation.under", "generic")}
					</div>
					<input className="page-field-value" type="number"
						onChange={e => updateCancellationPolicy(e, UNDER)}></input>
					<div>
						{`${messageMap("profilePage.tutoring.modal.cancellation.noShow", "generic")}, `}
					</div>
				</div>
			);
		}

		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal} submitHandler={e => showCancellationPolicies(selectedTimeRange)}
						modalClass="become-tutor-modal"
						title={messageMap("profilePage.tutoring.modal.application.cancellationPolicy", "generic")}
						submitText={messageMap("tutoring.modal.setPolicy", "button")}>
				{timeRangeDOM}
				<div className="multi-dom-line">
					{
						selectedTimeRange === OVER
						? (
							<div>
								100
							</div>
						)
						: (
						<input className="page-field-value" type="number"
							onChange={e => updateCancellationPolicy(e, "percentageOff", selectedTimeRange)}></input>
						)
					}
					<div>
						{messageMap("profilePage.tutoring.modal.cancellation.deductedCost", "generic")}
					</div>
				</div>
			</Modal>
		);
	}

	function updateCancellationPolicy(e, updateType, specifier) {
		const value = e.target.value;

		if (!numberValidator(value)) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.notNumber", "validation")}></Alert>
			);
			e.target.value = "";
		}
		else if (value > 100) {
			if (updateType === "percentageOff") {
				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.percentage.tooHigh", "validation")}></Alert>
				);
			}
			else {
				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.hours.tooHigh", "validation")}></Alert>
				);
			}
			e.target.value = 99;
		}
		else {
			let updateCancelPolicies = cancellationPolicies;

			if (updateType === OVER) {
				updateCancelPolicies[updateType] = {
					hours: value,
					percentage: 100
				};
			}
			else if (UNDER === updateType) {
				updateCancelPolicies[updateType] = {
					hours: value
				};
			}
			else if (updateType === BETWEEN) {
				if (updateCancelPolicies[BETWEEN] == null) {
					updateCancelPolicies[BETWEEN] = {};
				}
				updateCancelPolicies[BETWEEN][specifier] = {
					hours: value
				};
			}
			else if (updateType === "percentageOff") {
				if (updateCancelPolicies[specifier] == null) {
					updateCancelPolicies[specifier] = {};
				}
				updateCancelPolicies[specifier]["percentage"] = value;
			}
	
			setCancellationPolicies(updateCancelPolicies);
			props.setCancellationPolicies(updateCancelPolicies);
		}
	}

	function showCancellationPolicies(timeRange) {
		if (timeRange !== null
				&& cancellationPolicies[timeRange] != null && cancellationPolicies[timeRange]["percentage"] != null && (
					(
						// not as clean as using the array 'every' function, but it's faster
						[OVER, UNDER].includes(timeRange) && (cancellationPolicies[timeRange]["hours"] != null || cancellationPolicies[timeRange]["hours"] !== "")
					) || (
						timeRange === BETWEEN
						&& cancellationPolicies[timeRange][1] != null && (cancellationPolicies[timeRange][1]["hours"] != null && cancellationPolicies[timeRange][1]["hours"] !== "")
						&& cancellationPolicies[timeRange][2] != null && (cancellationPolicies[timeRange][2]["hours"] != null && cancellationPolicies[timeRange][2]["hours"] !== "")
					)
				)
			) {
			const cancellationPoliciesDOM = createCancellablePolicies(cancellationPolicies);
			setCancellableCancellationPolicy(cancellationPoliciesDOM);
			hideModal();
		}
	}

	function removeCancellationPolicy(policyRange) {
		let updateCancelPolicies = cancellationPolicies;
		delete updateCancelPolicies[policyRange];

		setCancellationPolicies(updateCancelPolicies);
		props.setCancellationPolicies(updateCancelPolicies);

		const cancellationPoliciesDOM = createCancellablePolicies(updateCancelPolicies);
		setCancellableCancellationPolicy(cancellationPoliciesDOM);
	}

	function createCancellablePolicies(policyObjects) {
		let cancellationPoliciesDOM = [];
		for (const [key, value] of Object.entries(policyObjects)) {
			let policyDom;
			if (key === OVER) {
				policyDom = (
					<Fragment>
						<div>
							{messageMap("profilePage.tutoring.modal.cancellation.over", "generic")}
						</div>
						<div className="cancel-hour">
							{value.hours}
						</div>
						<div className="policy-text">
							{`${messageMap("profilePage.tutoring.modal.cancellation.hours", "generic")}, `}
						</div>
					</Fragment>
				);
			}
			else if (key === BETWEEN) {
				policyDom = (
					<Fragment>
						<div>
							{messageMap("profilePage.tutoring.modal.cancellation.between", "generic")}
						</div>
						<div className="cancel-hour">
							{value[1].hours}
						</div>
						<div>
							{messageMap("profilePage.tutoring.modal.cancellation.to", "generic")}
						</div>
						<div className="cancel-hour">
							{value[2].hours}
						</div>
						<div className="policy-text">
							{`${messageMap("profilePage.tutoring.modal.cancellation.hours", "generic")}, `}
						</div>
					</Fragment>
				);
			}
			else if (key === UNDER) {
				policyDom = (
					<Fragment>
						<div>
							{messageMap("profilePage.tutoring.modal.cancellation.under", "generic")}
						</div>
						<div className="cancel-hour">
							{value.hours}
						</div>
						<div className="policy-text">
							{`${messageMap("profilePage.tutoring.modal.cancellation.noShow", "generic")}, `}
						</div>
					</Fragment>
				);
			}

			cancellationPoliciesDOM.push(
				<div key={key} className="cancel-policies-container">
					{policyDom}
					<div className="percentage-off">
						{value.percentage}
					</div>
					<div className="policy-text">
						{messageMap("profilePage.tutoring.modal.cancellation.deductedCost", "generic")}
					</div>
					<button className="remove-cancellation-policy" onClick={e => removeCancellationPolicy(key)}>
						<img className="x-icon" src={closeAsset} alt="ex icon"></img>
					</button>
				</div>
			);
		}

		return cancellationPoliciesDOM;
	}

	return (
		<div className="page-field multi-lines">
			<label className="page-field-label align-to-form">
				<div className="div-label">
					{messageMap("profilePage.tutoring.modal.application.cancellationPolicy", "generic")}
				</div>
				*
			</label>
			<div className="inline-elements">
				<div className="multi-line-inlines">
					<Dropdown customDropdownItemAttribute="cancellationKey" customContainerClass="tutoring-application"
						dropdownOptions={cancellationTimeRanges} dropdownItemClickHandler={createCancellationPolicy}/>
					<div>
						{cancellableCancellationPolicy}
					</div>
				</div>
			</div>
		</div>
	);
}

CancellationPolicy.propTypes = {
	setModal: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	cancellationPolicies: PropTypes.object,
	setCancellationPolicies: PropTypes.func.isRequired
};

export default CancellationPolicy;
