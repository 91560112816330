import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

import {logInUser, setLogInTimeoutStartTime, clearLogInTimeout} from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";
import {LOG_IN_TIMEOUT} from "redux/actions/accountConstants";

import Modal from "templates/Modal";

import {modalSchema} from "../schemas/navBarSchemas";

import {loginAPI} from "apis/controllers/person/AccountsController";
import {unblockIpAPI, blockIpPostAPI, blockIpGetAPI, getIPAPI} from "apis/controllers/IPsController";

import messageMap from "Utilities/MessageMaps";
import { getTimezoneOffset } from "templates/utilities/CalendarUtilities";

/**
 * @description custom modal for logging in and confirming that the user is actually the user
 * @param {String} title modal's title
 * @param {String} subHeader? additional confirmation text
 * @param {String} confirmType? if none, default behavior is to log user in
 * @param {Function} closeModal handler for closing the modal
 * @param {Object} closeArgs? additional arguments to pass closeModal
 * @param {Function} logInUser // redux actions: sets ownerId
 */
function ConfirmModal(props) {
	const userSessionRef = useRef({});

	const confirmUserSchema = modalSchema(null, null, null)["login"];
	const history = useHistory();
	let loginAttemptCount = 0;

	const [confirmModal, setConfirmModal] = useState();

	useEffect(() => {
		getIPAPI(checkForLockedIp);
		renderModal();
	}, [userSessionRef.current.ip]);

	function checkForLockedIp(ip) {
		blockIpGetAPI({ip: ip}, response => {
			userSessionRef.current["ip"] = ip;
			if (response.ip) {
				userSessionRef.current["ipCreatedAt"] = response.createdAt;
				userSessionRef.current["ipLocked"] = true;
			}
			else {
				userSessionRef.current["ipLocked"] = false;
			}


		});
	}

	function pushHistoryTo(url) {
		window.scrollTo(0, 0);
		history.push(url);
	}

	function logIn(loginStates, statusHandler, inputDisabler) {
		const payload = {
			username: loginStates.loginUser.value,
			password: loginStates.loginPass.value,
			timezone: getTimezoneOffset()
		};

		if (payload.username != null && payload.username !== props.username) {
			let msg = (
				<div className="error-msg">
					{messageMap("account.usernamePassword", "validation")}
				</div>
			);

			++loginAttemptCount;
			statusHandler(msg);
		}
		else if (payload.username != null && payload.password != null) {
			const credentials = {
				username: payload.username,
				password: payload.password
			};
			loginAPI(payload, credentials, (response) => {
				if (!response) {
					let msg;
	
					if (loginAttemptCount === 2 || props.timedOutStartTime !== 0) {
						msg = (
							<div className="error-msg w275">
								{messageMap("account.login.confirm", "generic")}
							</div>
						);
	
						inputDisabler();
						userSessionRef.current["ipLocked"] = true;
						blockIpPostAPI({ip: userSessionRef.current.ip});
						props.setLogInTimeoutStartTime(Date.now(), LOG_IN_TIMEOUT, () => {
							props.clearLogInTimeout();
							loginAttemptCount = 0;
							unblockIpAPI({ip: userSessionRef.current.ip});
							statusHandler("");
							inputDisabler(false);
						});
					}
					else {
						msg = (
							<div className="error-msg">
								{messageMap("account.usernamePassword", "validation")}
							</div>
						);

						++loginAttemptCount;
					}
	
					statusHandler(msg);
				}
				else {
					if (props.confirmType === undefined) {
						props.logInUser(response);
					}
					closeConfirmModal();
					if (history.location.pathname === "/") {
						window.scrollTo(0, 0);
						pushHistoryTo("/video-list");
					}
				}
			}, errorResponse => {
				++loginAttemptCount;
				statusHandler((
					<div className="error-msg">
						{messageMap("account.usernamePassword", "validation")}
					</div>
				));
			}
		);
		}
	}

	function closeConfirmModal(args) {
		props.closeModal(null, props.closeArgs);
	}

	function renderModal() {
		let confirmObject = {};
		if (props.confirmType === "verify") {
			confirmObject = {
				stateProps: confirmUserSchema.stateProps,
				inputs: confirmUserSchema.inputs,
				submitHandler: logIn
			};
		}
		else if (props.confirmType === "ensure") {
			confirmObject = {
				submitText: messageMap("yes.text", "button"),
				cancelText: messageMap("no.text", "button"),
				closeArgs: "no",
				submitHandler: closeConfirmModal
			};
		}

		setConfirmModal(
			<Modal title={props.title} subHeader={props.subHeader}
				closeHandler={props.closeModal} {...confirmObject}/>
		);
	}

	return (
		<div className="confirmUserModal">
			{confirmModal}
		</div>
	);

}

ConfirmModal.propTypes = {
	title: PropTypes.string.isRequired,
	subHeader: PropTypes.string,
	confirmType: PropTypes.string,

	closeModal: PropTypes.func.isRequired,
	closeArgs: PropTypes.any,

	// Redux props
	username: PropTypes.string,
	logInUser: PropTypes.func.isRequired,
	setLogInTimeoutStartTime: PropTypes.func.isRequired,
	clearLogInTimeout: PropTypes.func.isRequired,
	timedOutStartTime: PropTypes.number.isRequired
};

export default connect(
	account,
	{logInUser, setLogInTimeoutStartTime, clearLogInTimeout}
)(ConfirmModal);