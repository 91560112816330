import { mathMessageMap } from "i18n/tutoring/MathMessageMap";
import { scienceMessageMap } from "i18n/tutoring/science/ScienceMessageMap";
import { socialScienceMessageMap } from "i18n/tutoring/socialScience/SocialScienceMessageMap";
import { engineeringMessageMap } from "i18n/tutoring/engineering/EngineeringMessageMap";

export const tutoringMessageMap = {
	...mathMessageMap,
	...scienceMessageMap,
	...socialScienceMessageMap,
	...engineeringMessageMap
};