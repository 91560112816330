import React from "react";

import messageMap from "Utilities/MessageMaps";

import ChangeableShape from "assets/topicArticles/Math/components/ChangeableShape";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import StaticImages from "assets/topicArticles/Math/components/StaticImages";

import thumbnail from "assets/topicArticles/Math/Level 01 Math/I- Figures/naming3DShapes.jpg";

import shapePartsAsset from "assets/topicArticles/Math/Level 01 Math/I- Figures/3dParts.svg";

// 3D Spheres
import sphereAsset from "assets/images/practice/math/3dShapes/sphere.svg";
import ellipsoidAsset from "assets/images/practice/math/3dShapes/ellipsoid.svg";
// 3D Circles
import coneAsset from "assets/images/practice/math/3dShapes/cone.svg";
import cylinderAsset from "assets/images/practice/math/3dShapes/cylinder.svg"
// 3D Prisms
import triangularPrismAsset from "assets/images/practice/math/3dShapes/triangularPrism.svg";
import cubeAsset from "assets/images/practice/math/3dShapes/cube.svg";
import squarePrismAsset from "assets/images/practice/math/3dShapes/squarePrism.svg";
import rectangularPrismAsset from "assets/images/practice/math/3dShapes/rectangularPrism.svg";
import pentagonalPrismAsset from "assets/images/practice/math/3dShapes/pentagonalPrism.svg";
import hexagonalPrismAsset from "assets/images/practice/math/3dShapes/hexagonalPrism.svg";
// 3D Pyramids
import triangularPyramidAsset from "assets/images/practice/math/3dShapes/triangularPyramid.svg";
import squarePyramidAsset from "assets/images/practice/math/3dShapes/squarePyramid.svg";
import pentagonalPyramidAsset from "assets/images/practice/math/3dShapes/pentagonalPyramid.svg";
import hexagonalPyramidAsset from "assets/images/practice/math/3dShapes/hexagonalPyramid.svg";
// Special 3D Shape
import torusAsset from "assets/images/practice/math/3dShapes/torus.svg";


export const threeDShapesDifficultyMap = {
	1: ["cube" ,"sphere", "ellipsoid", "cone", "cylinder"],
	2: [
		"triangularPrism", "squarePrism", "rectangularPrism", "pentagonalPrism", "hexagonalPrism",
		"triangularPyramid", "squarePyramid", "pentagonalPyramid", "hexagonalPyramid"
	],
	3: ["torus"]
};
export const threeDShapesToAnswerMap = {
	"cube": "Cube",
	"sphere": "Sphere",
	"ellipsoid": "Ellipsoid",
	"cone": "Cone",
	"cylinder": "Cylinder",
	"triangularPrism": "Triangular Prism",
	"squarePrism": "Square Prism",
	"rectangularPrism": "Rectangular Prism",
	"pentagonalPrism": "Pentagonal Prism",
	"hexagonalPrism": "Hexagonal Prism",
	"triangularPyramid": "Triangular Pyramid",
	"squarePyramid": "Square Pyramid",
	"pentagonalPyramid": "Pentagonal Pyramid",
	"hexagonalPyramid": "Hexagonal Pyramid",
	"torus": "Torus"
};
export const threeDShapesToHintsMap = {
	"cube": messageMap("naming3DShapes.threeDShapesToHintsMap.cube", "math"),
	"sphere": messageMap("naming3DShapes.threeDShapesToHintsMap.sphere", "math"),
	"ellipsoid": messageMap("naming3DShapes.threeDShapesToHintsMap.ellipsoid", "math"),
	"cone": messageMap("naming3DShapes.threeDShapesToHintsMap.cone", "math"),
	"cylinder": messageMap("naming3DShapes.threeDShapesToHintsMap.cylinder", "math"),

	"triangularPrism": messageMap("naming3DShapes.threeDShapesToHintsMap.triangularPrism", "math"),
	"squarePrism": messageMap("naming3DShapes.threeDShapesToHintsMap.squarePrism", "math"),
	"rectangularPrism": messageMap("naming3DShapes.threeDShapesToHintsMap.rectangularPrism", "math"),
	"pentagonalPrism": messageMap("naming3DShapes.threeDShapesToHintsMap.pentagonalPrism", "math"),
	"hexagonalPrism": messageMap("naming3DShapes.threeDShapesToHintsMap.hexagonalPrism", "math"),

	"triangularPyramid": messageMap("naming3DShapes.threeDShapesToHintsMap.triangularPyramid", "math"),
	"squarePyramid": messageMap("naming3DShapes.threeDShapesToHintsMap.squarePyramid", "math"),
	"pentagonalPyramid": messageMap("naming3DShapes.threeDShapesToHintsMap.pentagonalPyramid", "math"),
	"hexagonalPyramid": messageMap("naming3DShapes.threeDShapesToHintsMap.hexagonalPyramid", "math"),

	"torus": messageMap("naming3DShapes.threeDShapesToHintsMap.torus", "math")
};
export const threeDShapesToImgAltsMap = {
	"cube": messageMap("naming3DShapes.threeDShapesToImgAltsMap.cube", "math"),
	"sphere": messageMap("naming3DShapes.threeDShapesToImgAltsMap.sphere", "math"),
	"ellipsoid": messageMap("naming3DShapes.threeDShapesToImgAltsMap.ellipsoid", "math"),
	"cone": messageMap("naming3DShapes.threeDShapesToImgAltsMap.cone", "math"),
	"cylinder": messageMap("naming3DShapes.threeDShapesToImgAltsMap.cylinder", "math"),

	"triangularPrism": messageMap("naming3DShapes.threeDShapesToImgAltsMap.triangularPrism", "math"),
	"squarePrism": messageMap("naming3DShapes.threeDShapesToImgAltsMap.squarePrism", "math"),
	"rectangularPrism": messageMap("naming3DShapes.threeDShapesToImgAltsMap.rectangularPrism", "math"),
	"pentagonalPrism": messageMap("naming3DShapes.threeDShapesToImgAltsMap.pentagonalPrism", "math"),
	"hexagonalPrism": messageMap("naming3DShapes.threeDShapesToImgAltsMap.hexagonalPrism", "math"),

	"triangularPyramid": messageMap("naming3DShapes.threeDShapesToImgAltsMap.triangularPyramid", "math"),
	"squarePyramid": messageMap("naming3DShapes.threeDShapesToImgAltsMap.squarePyramid", "math"),
	"pentagonalPyramid": messageMap("naming3DShapes.threeDShapesToImgAltsMap.pentagonalPyramid", "math"),
	"hexagonalPyramid": messageMap("naming3DShapes.threeDShapesToImgAltsMap.hexagonalPyramid", "math"),

	"torus": messageMap("naming3DShapes.threeDShapesToImgAltsMap.torus", "math")
};
export const threeDShapesToAssetsMap = {
	"cube": cubeAsset,
	"sphere": sphereAsset,
	"ellipsoid": ellipsoidAsset,
	"cone": coneAsset,
	"cylinder": cylinderAsset,

	"triangularPrism": triangularPrismAsset,
	"squarePrism": squarePrismAsset,
	"rectangularPrism": rectangularPrismAsset,
	"pentagonalPrism": pentagonalPrismAsset,
	"hexagonalPrism": hexagonalPrismAsset,

	"triangularPyramid": triangularPyramidAsset,
	"squarePyramid": squarePyramidAsset,
	"pentagonalPyramid": pentagonalPyramidAsset,
	"hexagonalPyramid": hexagonalPyramidAsset,

	"torus": torusAsset
};

const prismBaseEdgeCountToShape = {
	3: "triangularPrism",
	4: "squarePrism",
	5: "pentagonalPrism",
	6: "hexagonalPrism"
};
const prismBaseEdgeCountToShapeTip = {
	3: messageMap("naming3DShapes.prismBaseEdgeCountToShapeTip.3", "math"),
	4: messageMap("naming3DShapes.prismBaseEdgeCountToShapeTip.4", "math"),
	5: messageMap("naming3DShapes.prismBaseEdgeCountToShapeTip.5", "math"),
	6: messageMap("naming3DShapes.prismBaseEdgeCountToShapeTip.6", "math")
};
const prismAlterableProperties = {
	"face's edge": {
		range: [3, 6],
		keysMap: prismBaseEdgeCountToShape,
		tipMap: prismBaseEdgeCountToShapeTip,
		nameMap: threeDShapesToAnswerMap,
		imgMap: threeDShapesToAssetsMap,
		imAltMap: threeDShapesToImgAltsMap
	}
};
const pyramidBaseEdgeCountToShape = {
	3: "triangularPyramid",
	4: "squarePyramid",
	5: "pentagonalPyramid",
	6: "hexagonalPyramid"
};
const pyramidBaseEdgeCountToShapeTip = {
	3: messageMap("naming3DShapes.pyramidBaseEdgeCountToShapeTip.3", "math"),
	4: messageMap("naming3DShapes.pyramidBaseEdgeCountToShapeTip.4", "math"),
	5: messageMap("naming3DShapes.pyramidBaseEdgeCountToShapeTip.5", "math"),
	6: messageMap("naming3DShapes.pyramidBaseEdgeCountToShapeTip.6", "math")
};
const pyramidAlterableProperties = {
	"base's edge": {
		range: [3, 6],
		keysMap: pyramidBaseEdgeCountToShape,
		tipMap: pyramidBaseEdgeCountToShapeTip,
		nameMap: threeDShapesToAnswerMap,
		imgMap: threeDShapesToAssetsMap,
		imAltMap: threeDShapesToImgAltsMap
	}
};
const staticProperties = {
	"sphere": {
		edges: 0,
		vertices: 0,
		faces: 1
	},
	"ellipsoid": {
		edges: 0,
		vertices: 0,
		faces: 1
	},
	"cone": {
		edges: 1,
		faces: 1
	},
	"cylinder": {
		edges: 2,
		faces: 2
	},
	"triangularPrism": {
		edges: 9,
		faces: 5,
		vertices: 6,
		baseEdgeCount: 3
	},
	"squarePrism": {
		edges: 12,
		faces: 6,
		vertices: 8,
		baseEdgeCount: 4
	},
	"pentagonalPrism": {
		edges: 15,
		faces: 7,
		vertices: 10,
		baseEdgeCount: 5
	},
	"hexagonalPrism": {
		edges: 18,
		faces: 8,
		vertices: 12,
		baseEdgeCount: 6
	},
	"cube": {
		edges: 12,
		faces: 6,
		vertices: 8,
		baseEdgeCount: 4
	},
	"rectangularPrism": {
		edges: 12,
		faces: 6,
		vertices: 8,
		baseEdgeCount: 4
	},
	"triangularPyramid": {
		edges: 6,
		faces: 4,
		vertices: 4,
		baseEdgeCount: 3
	},
	"squarePyramid": {
		edges: 8,
		faces: 5,
		vertices: 5,
		baseEdgeCount: 4
	},
	"pentagonalPyramid": {
		edges: 10,
		faces: 6,
		vertices: 6,
		baseEdgeCount: 5
	},
	"hexagonalPyramid": {
		edges: 12,
		faces: 7,
		vertices: 7,
		baseEdgeCount: 6
	},
	"torus": {
		edges: 0,
		vertices: 0,
		faces: 1
	}
};

export const naming3DShapesArticle = {
	id: "I: Figures_B. Naming 3D Shapes",
	title: messageMap("naming3DShapes.article.title", "math"),
	description: messageMap("naming2DShapes.article.description", "math"),
	thumbnail: thumbnail,
	thumbnailAlt: messageMap("naming3DShapes.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1",
		"text3", "html2", "html3", "text4", "html4", "html5",
		"text5", "html6", "html7", "html8",
		"text6", "html9",
		"text7", "html10", "text8"
	],
	datePublished: "2024-06-03",
	dateModified: "2024-06-03"
};

export const naming3DShapesArticleTexts = {
	text1: {
		text: messageMap("naming3DShapes.naming3DShapesArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("naming3DShapes.naming3DShapesArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("naming3DShapes.naming3DShapesArticleTexts.text3", "math"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("naming3DShapes.naming3DShapesArticleTexts.text4", "math"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("naming3DShapes.naming3DShapesArticleTexts.text5", "math"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("naming3DShapes.naming3DShapesArticleTexts.text6", "math"),
		type: SUB_HEADER
	},
	text7: {
		text: messageMap("naming3DShapes.naming3DShapesArticleTexts.text7", "math"),
		type: SUB_HEADER
	},
	text8: {
		text: messageMap("naming3DShapes.naming3DShapesArticleTexts.text8", "math"),
		type: DESCRIPTION
	}
};

export const naming3DShapesArticleHTMLs = {
	html1: (
		<StaticImages key="shapeParts" imgContainerClass="center"
			images={{
				left: {
					img: shapePartsAsset,
					alt: "Showing a shapes face, edge, and vertex.",
					class: "two-hundred-eighty-height"
				}
			}} />
	),
	html2: (
		<ChangeableShape key="Sphere" domkey="sphere" img={sphereAsset} imgAlt={threeDShapesToImgAltsMap["sphere"]}
			shapeName="Sphere" description={messageMap("naming3DShapes.naming3DShapesArticleHTMLs.sphere", "math")}
			staticProperties={staticProperties}/>
	),
	html3: (
		<ChangeableShape key="Ellipsoid" domkey="ellipsoid" img={ellipsoidAsset} imgAlt={threeDShapesToImgAltsMap["ellipsoid"]}
			shapeName="Ellipsoid" description={messageMap("naming3DShapes.naming3DShapesArticleHTMLs.ellipsoid", "math")}
			staticProperties={staticProperties}/>
	),
	html4: (
		<ChangeableShape key="Cone" domkey="cone" img={coneAsset} imgAlt={threeDShapesToImgAltsMap["cone"]}
			shapeName="Cone" description={messageMap("naming3DShapes.naming3DShapesArticleHTMLs.cone", "math")}
			staticProperties={staticProperties}/>
	),
	html5: (
		<ChangeableShape key="Cylinder" domkey="cylinder" img={cylinderAsset} imgAlt={threeDShapesToImgAltsMap["cylinder"]}
			shapeName="Cylinder" description={messageMap("naming3DShapes.naming3DShapesArticleHTMLs.cylinder", "math")}
			staticProperties={staticProperties}/>
	),
	html6: (
		<ChangeableShape key="TriangularPrism" domkey="triangularPrism" img={triangularPrismAsset} imgAlt={threeDShapesToImgAltsMap["triangularPrism"]}
			shapeName="Triangular Prism" description={prismBaseEdgeCountToShapeTip[3]}
			staticProperties={staticProperties} alterableProperties={prismAlterableProperties}/>
	),
	html7: (
		<ChangeableShape key="Cube" domkey="cube" img={cubeAsset} imgAlt={threeDShapesToImgAltsMap["cube"]}
			shapeName="Cube" description={messageMap("naming3DShapes.naming3DShapesArticleHTMLs.cube", "math")}
			staticProperties={staticProperties}/>
	),
	html8: (
		<ChangeableShape key="RectangularPrism" domkey="rectangularPrism" img={rectangularPrismAsset} imgAlt={threeDShapesToImgAltsMap["rectangularPrism"]}
			shapeName="Rectangular Prism" description={messageMap("naming3DShapes.naming3DShapesArticleHTMLs.rectangularPrism", "math")}
			staticProperties={staticProperties}/>
	),
	html9: (
		<ChangeableShape key="TriangularPyramid" domkey="triangularPyramid" img={triangularPyramidAsset} imgAlt={threeDShapesToImgAltsMap["triangularPyramid"]}
			shapeName="Triangular Pyramid" description={pyramidBaseEdgeCountToShapeTip[3]}
			staticProperties={staticProperties} alterableProperties={pyramidAlterableProperties}/>
	),
	html10: (
		<ChangeableShape key="Torus" domkey="torus" img={torusAsset} imgAlt={threeDShapesToImgAltsMap["torus"]}
			shapeName="Torus" description={messageMap("naming3DShapes.naming3DShapesArticleHTMLs.torus", "math")}
			staticProperties={staticProperties}/>
	)
}


