import React from "react";

import { messageMapGeneric } from "Utilities/MessageMaps";


function FifteenMinuteTrial(props) {
	return (
		<div>
			<div className="title">
				{messageMapGeneric("tutoringPage.faqs.tutees.fifteenMinuteTrials")}
			</div>

			<div className="faq-text">
				{messageMapGeneric("tutoringPage.faqs.tutees.fifteenMinuteTrialsDescriptionFull1")}
			</div>
			<div className="faq-text">
				{messageMapGeneric("tutoringPage.faqs.tutees.fifteenMinuteTrialsDescriptionFull2")}
			</div>
		</div>
	);
}

export default FifteenMinuteTrial;
