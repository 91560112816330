import React, { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";

import Alert, { ERROR } from "templates/Alert";

import messageMap from "Utilities/MessageMaps";
import { emailValidator } from "Utilities/Validators/InputValidators";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";


function EditEmailModalContent(props) {

	const [newEmail, setNewEmail] = useState(props.email),
		[emailConfirm, setEmailConfirm] = useState(),
		[isNewEmailValid, setIsNewEmailValid] = useState(true),
		[isConfirmEmailValid, setIsConfirmEmailValid] = useState(false);

	const updateEmailButtonRef = useRef(),
		emailConfirmRef = useRef();

	const onChangeMap = {
		email: setNewEmail,
		emailConfirm: setEmailConfirm
	};

	const isValidMap = {
		email: isNewEmailValid,
		emailConfirm: isConfirmEmailValid
	};
	const isValidSetterMap = {
		email: setIsNewEmailValid,
		emailConfirm: setIsConfirmEmailValid
	};

	function onChangeEmail(e, changeType) {
		const target = e.target,
			text = target.value;

		onChangeMap[changeType](text);

		if (text === "") {
			target.className = "page-field-input";
			return;
		}

		let isEmailValid = isValidMap["email"],
			isConfirmEmailValid = isValidMap["emailConfirm"];
		if (changeType === "email") {
			emailConfirmRef.current.value = "";

			if (emailValidator(text)) {
				isValidSetterMap[changeType](true);
				isEmailValid = true;
				target.className = "page-field-input";
				emailConfirmRef.current.className = "page-field-input";
			}
			else {
				isValidSetterMap[changeType](false);
				isEmailValid = false;
				target.className = "page-field-input warning";
				emailConfirmRef.current.className = "page-field-input not-allowed";
			}
		}
		else if (changeType === "emailConfirm") {
			if (text === newEmail) {
				isValidSetterMap[changeType](true);
				isConfirmEmailValid = true;
				target.className = "page-field-input";
			}
			else {
				isValidSetterMap[changeType](false);
				isConfirmEmailValid = false;
				target.className = "page-field-input warning";
			}
		}

		updateEmailButtonRef.current.className = isEmailValid && isConfirmEmailValid ? "update-email" : "update-email not-allowed";
	}

	function checkBeforeUpdatingForm() {
		if (newEmail === props.email) {
			props.setAlert(
				<Alert closeHandler={closeAlert} type={ERROR} msg={messageMap("account.lastName.sameAsOld", "validation")} />
			);
		}
		else if (isNewEmailValid && isConfirmEmailValid && updateEmailButtonRef.current.className === "update-email") {
			props.updateField("email", newEmail);

			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	return (
		<Fragment>
			<div className="field-name">
				{messageMap("account.fields.modal.newEmail", "generic")}
			</div>
			<input placeholder={props.email} className="page-field-input"
					onChange={e => onChangeEmail(e, "email")} >
			</input>

			<div className="field-name">
				{messageMap("account.fields.modal.reTypeEmail", "generic")}
			</div>
			<input placeholder={props.email} className="page-field-input"
					onChange={e => onChangeEmail(e, "emailConfirm")} ref={emailConfirmRef}>
			</input>

			<button className="update-email not-allowed"
				onClick={checkBeforeUpdatingForm} ref={updateEmailButtonRef} >
				{messageMap("account.changeEmail", "button")}
			</button>
		</Fragment>
	);
}

EditEmailModalContent.propTypes = {
	setModal: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	email: PropTypes.string,
	updateField: PropTypes.func.isRequired
};

export default EditEmailModalContent;
