export const KB = "KB";
export const MB = "MB";
export const GB = "GB";
export const TB = "TB";

/**
 * @description converts provided bytes to specified unite
 * @param {Number} bytes number of bytes to convert
 * @param {String} toUnit allows KB, MB, GB, TB
 */
export function fileSizeConvert(bytes, toUnit) {
	if (typeof bytes !== "number") {
		console.error("Expected 'bytes' to be a Number");
	}
	if (![KB, MB, GB, TB].includes(toUnit)) {
		console.error("Only accepts the following units: 'KB', 'MB', 'GB', 'TB'");
	}

	let newValue = 0;
	switch(toUnit) {
		case "KB":
			newValue = bytes / Math.pow(1024, 1);
			break;
		case "MB":
			newValue = bytes / Math.pow(1024, 2);
			break;
		case "GB":
			newValue = bytes / Math.pow(1024, 3);
			break;
		case "TB":
			newValue = bytes / Math.pow(1024, 4);
			break;
		default:
			console.error("Your sorcerous code managed to find its way here. Well done! But how did you do it?");
			break;
	}

	return newValue;
}