import React from "react";

import { messageMapGeneric } from "Utilities/MessageMaps";


export const TUTOR_REQUIREMENTS = [
	messageMapGeneric("profilePage.tutoring.modal.requirements.p1"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p2"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p4"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p5")
];

function TutorRequirements(props) {
	return (
		<div>
			<div className="title">
				{messageMapGeneric("tutoringPage.faqs.tutors.tutoringRequirements")}
			</div>

			<ol>
				{
					TUTOR_REQUIREMENTS.map((requirement, index) =>
						<li key={`requirement_${index}`} className="faq-text">
							{requirement}
						</li>
					)
				}
			</ol>
		</div>
	);
}

export default TutorRequirements;
