export const INCREMENT_LOGIN_ATTEMPT_COUNT = "INCREMENT_LOGIN_ATTEMPT_COUNT";
export const CLEAR_LOGIN_ATTEMPT_COUNT = "CLEAR_LOGIN_ATTEMPT_COUNT";

export const OPEN_SIGNUP_MODAL = "OPEN_SIGNUP_MODAL";
export const CLOSE_SIGNUP_MODAL = "CLOSE_SIGNUP_MODAL";
export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

export const SET_USER_SESSION = "SET_USER_SESSION";
export const CLEAR_USER_SESSION = "CLEAR_USER_SESSION";

export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const UNCLEAR_NOTIFICATIONS = "UNCLEAR_NOTIFICATIONS";