export const wellBeingMessageMap = {
	diet: {
		article: {
			title: "How our diet affects our brain health and the foods we should eat and avoid",
			description: "Learn how to optimize your diet to improve your thinking.",
			thumbnailAlt: "Text 'goal' on top of a black background."
		},
		texts: {
			header1: "Module introduction",
			p1: `Welcome to another one of our modules on how to optimize your learning. For this module, <b>we'll 
				learn the importance of keeping one's well-being and how it relates to our learning.</b> We'll learn the 
				importance of diet, exercise, the different kinds of stress, controlling one's stress by properly framing our state 
				of mind, and the many benefits of rest. While the things that we'll discuss here can be applied in an academic context, 
				this module is meant to be applicable in a more general context, like in our day-to-day lives. So, without further 
				ado, let's get started.`,
			header2: "Introduction",
			p2: `Diet. It's an extremely confusing topic because, depending on what you're trying to do, whether to lose 
				weight, gain muscle mass, slow down your aging, improve your complexion, optimize your brain's performance, 
				etc. etc. etc., you will have many people giving you many different, sometimes conflicting, advice. But for 
				our purpose, we'll limit this module's discussion to optimizing brain performance. And, since there are 
				simply too many things to learn when it comes to diet, additionally what specific kind of food you should 
				eat, avoid, how much, and how often, we'll leave this decision to your doctor, since, after all, <b>there is no 
				exact one-size-fits-all approach when it comes to diet</b>; everyone has their own lifestyles and their diet should 
				try to conform to that.`,
			p3: `Ok, enough of these side notes. Let's start getting into the topic by first reflecting on how it relates to 
				us. As we all know, there is a proverb that goes: 'You are what you eat.' This proverb turns out to be true for 
				several fields, such as health, physical development, and even psychology, so it's important to see how our diet 
				affects our day-to-day lives.`,
			header3: "Observing our diet",
			p4: `Now, we might struggle a bit to see the direct connection between what we eat and how this affects our life, 
				because, for one, what we eat and what happens to our body afterward depends much on our body's state when we 
				ate that food. Let's say that our food of interest is bread. If we're starving and we eat enough bread, let's 
				say 4 slices, then we'll get full. If we're not that hungry but decided to eat 4 slices of bread anyway, now we'll 
				feel really full. Now this is all obvious, but let's now throw in the factor of metabolism. If our metabolism is 
				high, even if we decide to eat 4 slices of bread when we're not hungry, we're not going to feel as full, compared 
				to someone whose metabolism is 'normal' or low. This topic gets even more complicated if we start factoring in our 
				gut microbiome, since this has implications on how exactly the food is broken down and metabolized. Taking this 
				scenario at a longer period, this will also have some implications on our weight gain. So, a general rule of thumb 
				to see how our diet affects us is by changing one variable in our diet, while keeping everything the same, and 
				carefully observing if anything changes during the next few days. Or, better yet, we can consult a nutritionist, 
				since they can advise us on how food affects our health and general well-being.`,
			p5: `Now that we have a sense of how we can observe how our diet affects us and that its effects depend on our 
				body's state, we can now focus on some of the food we can eat to improve our learning.`,
			header4: "Our anatomy and physiology",
			p6: `To get a good understanding of why we should eat certain types of food for brain health, performance, and 
				development, we need to first understand our brain's anatomy, which is the brain's structure and its parts, and 
				our brain's physiology, which is how these components work.`,
			p7: `Here is our nervous system, as shown in Body Worlds, Amsterdam.`,
			p8: `Kinda cool and a little creepy don't you think? Anyway, as you can see, we have these long thick strings coming 
				from the center of our body; these strings are nerve cells, or neurons.`,
			p9: `This is one of four types of neurons. <b>Neurons are responsible for relaying information from and to our brains.</b> 
				And as you can see from this diagram, information travels as electrical impulses. Starting from the left, neurons 
				receive information as electrical impulses from their dendrites and make their way through the axon, which is this 
				narrow tube, and eventually to the axon terminals, where it would then get received by another neuron, which then 
				relays the information to the next neuron, and so on and so on.`,
			p10: `Now, since our brain is made of about 100 billion of these and that these neurons are responsible for relaying 
				information to and from our brain, it would only make sense that we should try to keep these cells happy and healthy 
				to help ensure our brain's health, performance, and development. Okay, so then you might be wondering, <b>what powers 
				up these neurons and what keeps them healthy? The answer is carbohydrates and fats.</b>`,
			header5: "Carbohydrates",
			p11: `<b>There are many sources of both carbohydrates and fats, but not all sources are always good for us.</b> Starting with 
				carbohydrates, there are three types of them: fiber, starches, and sugar.`,
			p12: `Going from right to left, <b>fiber is good for your health, since it helps keep our digestive system clean by cultivating 
				the good bacteria in our gut</b>; this helps remove harmful compounds from our body, which helps reduce our risk of certain 
				cancers. <b>For starch, it gets broken down into glucose, which then becomes the body's and brain's, source of energy.</b> While 
				sugar also gets broken down into glucose and eventually gets used by the body as energy, where it's stored and the amount 
				of it can become harmful. Eating too much of it can harm our liver, damage our cells, and cause inflammation. And as 
				studies have shown, <b>having too much sugar in our diet harms proper brain development and prevents our brain from performing optimally.</b>`,
			p13: `Starch and fiber are usually found together, and common sources are whole grains, fruits, beans, and nuts. While 
				naturally occurring sugar is also found in fruits in small amounts, processed sugar, which is usually found in high amounts 
				in junk food such as chocolates, candies, and certain desserts, should be avoided, or, at least, eaten in moderation. Again, 
				eating too much of these can harm our brain and damage our cells, among a long list of other health-related problems.`,
			header6: "Fats",
			p14: `Now for fats. There are three general types of fats: (1) Unsaturated, (2) saturated, and (3) trans-fat.`,
			p15: `Going back to our earlier diagram, part of the reason why fats are important to our brain health is because of 
				the myelin sheath. This sheath that coats the axon is responsible for helping the electrical impulse travel through the 
				axon, in addition to creating a protective layer around the axon. And to create these sheaths, the brain needs lipids, 
				or fats.`,
			p16: `Another reason why fats are good for us is because they serve as a secondary source of energy, if our body, and 
				mind, are running low in glucose. Ketones, which are a type of stored fat molecules, get released into the body to be 
				used as another source of energy. However, compared to glucose, it takes longer for our body to start using ketones as 
				a source of energy, whereas glucose is more immediate.`,
			p17: `However, much like carbohydrates, not all fats are good for us. As shown in the picture, unsaturated fats are found 
				in olive oil, avocado, fish, nuts, and eggs. Among the many benefits they have, these types of fats, specifically omega 
				3 and omega 6 fatty acids, are excellent anti-inflammatory food, meaning they can help slow aging, mental decline, prevent 
				cancer, fight depression and anxiety, improve bone health, and even improve your sleep and skin.`,
			p18: `Trans fats, on the other hand, are the kinds of fat you should try avoiding. They clog your arteries, which are blood 
				vessels that help carry nutrients and oxygen throughout your body. By clogging your arteries, they make it harder for you 
				to breathe and for your body to efficiently keep your cells healthy. Because of these, you have a higher chance of heart 
				attacks, strokes, becoming diabetic, and even have your lifespan shortened. Trans fats can be found in baked goods, fried 
				foods, and margarine.`,
			p19: `Finally, for saturated fats. They lie in between unsaturated and trans-fat. They are fine in moderation but consuming 
				them would be best when you also exercise. The reason for this is because saturated fats raise both your LDL and HDL. `,
			p20: `LDL stands for low-density lipoprotein, and HDL stands for high-density lipoprotein. LDL is considered “bad”, since 
				it raises bad cholesterol, which is a type of fat-like substance that clogs your arteries and makes them hard and narrow. 
				HDL is considered “good”, since it takes any excess cholesterol and brings it back to your liver for later use.`,
			p21: `And as you guys might've already guessed, <b>unsaturated fats are good, since they increase HDL, but lower LDL. Trans fats 
				are bad since they lower HDL but increase LDL. And, saturated fats are in between since they both raise HDL and LDL.</b>`,
			header7: "Eating before studying",
			p22: `Since we now know what fuels the brain, if we're going to use carbs as our primary source of energy, <b>we suggest that, 
				about 30 minutes before studying, we make sure that we have enough fuel in our brain to help make sense of what we're studying.</b> 
				And in times when we've been studying for a while and we notice that our concentration is starting to wonder, we need to feel 
				free to take a break and maybe eat a light snack to replenish our reserves.`,
			header8: "Ego depletion",
			p23: `By the way, this phenomenon wherein <b>our ability to self-regulate our attention continues to decline as time goes by, because 
				we're burning through our glucose fuel, is called <u>ego depletion</u></b>. Again, our primary brain fuel is glucose, which comes from 
				carbs. When glucose runs out, we then get our energy from fats. So, if we're studying for a while, the moment we start zoning 
				out, or wonder about something else, is a sign that our brain is trying to conserve what little glucose fuel it has left, unless 
				of course we're just distracted, or just not interested in what we're learning.`,
			header9: "Moderation",
			p24: `Now, just to be safe, there is a chance that some of us might think: “well, if these are what fuels my brain, then it might be 
				a good idea to eat a lot, so that my brain gets supercharged for studying.” Absolutely not! First of all, eating too much increases 
				the blood flow going through our stomach, not our brain, so eating too much will cause our brain to not work at its peak. Second, as 
				in almost everything, balance and moderation are key; we also need to consider the other energy sources to help repair, maintain, 
				and develop our body.`,
			header10: "Conclusion",
			p25: `So, to summarize what we've learned so far. Our brain is made of billions of neurons, which are responsible for relaying information 
				from and to our brain. And to keep these neurons healthy and to help create myelin sheaths to protect and support the axons in these 
				neurons, we need to consume carbohydrates and fats. However, not all forms of carbohydrates and fats are good for our body. We need to 
				limit refined sugars, which are commonly found in sweets, and limit our consumption of trans fats, which are commonly found in baked 
				goods and fried foods. And lastly, we need to take breaks to give our brain some time to rest, and maybe to eat a light snack to replenish 
				our reserves.`
		},
		sources: {
			s1: `Biga, L. M., Bronson, S., Dawson, S., Harwell, A., Hopkins, R., Kaufmann, J., LeMaster, M., Matern, P., Morrison-Graham, K., Oja, K., Quick, D., Runyeon, J., & Oeru, O. (2019, September 26). 24.3 lipid metabolism. Anatomy Physiology. https://open.oregonstate.education/aandp/chapter/24-3-lipid-metabolism/ `,
			s2: `Mayo Foundation for Medical Education and Research. (2022, February 23). Trans fat: Double trouble for your heart. Mayo Clinic. https://www.mayoclinic.org/diseases-conditions/high-blood-cholesterol/in-depth/trans-fat/art-20046114 `,
			s3: `Mayo Foundation for Medical Education and Research. (n.d.). Physical Medicine and Rehabilitation. Mayo Clinic. https://www.mayoclinic.org/medical-professionals/physical-medicine-rehabilitation/news/analyzing-the-role-of-diet-and-exercise-in-myelin-production/mac-20429394#:~:text=Myelin%20is%20essential%20to%20the,stroke%2C%20and%20some%20neuropsychiatric%20disorders `,
			s4: `News & insights. Dana Foundation. (2024, May 1). https://www.dana.org/article/food-for-thought-what-fuels-brain-cells/ `
		}
	},
	exercise: {
		article: {
			title: "How exercise keeps our brain healthy",
			description: "Learn the positive and negative effects of exercising.",
			thumbnailAlt: "Two people carrying a 10kg barbell about to do a squat."
		},
		texts: {
			header1: "Introduction",
			p1: `Exercise. Some people like it, some don't. Personally, I find it fun. Get the blood pumping, play some sports with 
				some friends, hike, bike, or just go to the gym to workout. I'm sure we've already heard this about a thousand times 
				by now, that exercise is good for both our mind and body, but do we exactly know why it's good?`,
			p2: `If you happen to do some googling trying to answer that question, props to you, but you'll also notice that there's a long 
				list of reasons why it's good for our body. We'll mostly focus on three big physiological effects of exercise and how they relate to our brain.`,
			header2: "Effects of exercise",
			p3: `First, <b>exercise increases dopamine, serotonin, and norepinephrine production in our body, which helps us focus, feel more 
				relaxed, and mentally alert.</b>`,
			p4: `Second, <b>exercise helps grow more blood vessels, which improves the glucose and oxygen distribution to our cells, which 
				ultimately promotes the growth of new neurons.</b> This process of growing new neurons is called <b><u>neurogenesis</u>.</b.`,
			p5: `And third, <b>exercise helps stimulate the production of a protein called <u>BDNF</u></b>, which stands for brain-derived neurotrophic 
				factor. BDNF is important because it's <b>a key molecule related to learning and memory, since it's pretty much involved in maintaining 
				brain plasticity</b>. And as you might have guessed, as we age, our brain becomes less plastic, and research shows that this 
				decreasing plasticity involves a decrease in BDNF levels.`,
			header3: "Overexercising",
			p6: `As we can see, exercise is highly beneficial for our brain and mental health, but, as with most things, we need to practice 
				moderation, because too much exercise can actually be bad for us. <b>Research has shown that people who exercise for more than 5 
				or 6 hours a week are at risk of developing a long list of problems, which includes bad sleep quality, increased chances of getting 
				a heart attack and stroke, worse immune system, irregular heart beating, bone mineral loss, and lower testosterone levels.</b> So, 
				if we exercise too much, we're actually doing more harm than good to our body.`,
			p7: `We might then wonder: “what exactly is too much exercise?” What is too much depends on a person's age, health, and choice of 
				workouts, but the U.S. Department of Health and Human Services, or HHS, recommends about 150 minutes of moderate aerobic activity 
				per week for adults aged 18 to 64. Since we tend to have our own exercise routines with varying intensities, it's kind of challenging 
				to accurately measure how much is too much. So, we've compiled a small list of signs to watch out for to see if you're overexercising`,
			p8: "1.) We feel fatigued or burnt out",
			p9: "2.) We're gaining weight, rather than losing weight",
			p10: "3.) We're getting mood swings",
			p11: "4.) We feel depressed",
			p12: "5.) Our muscles are very sore and/or they feel heavy",
			header4: "Conclusion",
			p13: `And that's it for exercising. To summarize what we learned, <b>exercise is good for overall brain health, development, and 
				growth, and that it's beneficial only if it's light to moderate in intensity.</b>`
		},
		sources: {
			s1: `Exercise and your arteries. Harvard Health. (2023, July 31). https://www.health.harvard.edu/heart-health/exercise-and-your-arteries `,
			s2: `Miranda, M., Morici, J. F., Zanoni, M. B., & Bekinschtein, P. (2019, July 25). Brain-derived neurotrophic factor: A key molecule for memory in the healthy and the pathological brain. Frontiers. https://www.frontiersin.org/articles/10.3389/fncel.2019.00363/full `,
			s3: `Romero, M. S. G., & Polan, S. (n.d.). What overexercising does to your body and brain. Business Insider. https://www.businessinsider.com/what-over-exercise-does-body-brain-health-2018-4#:~:text=Exercise%20is%20supposed%20to%20be,injuries%2C%20and%20make%20you%20addicted. `
		}
	},
	goodBadStress: {
		article: {
			title: "How to identify good stress vs bad stress and its effects on our brain",
			description: "Learn the different types of and levels of stress and how they affect our brain.",
			thumbnailAlt: "Woman intently looking at the screen."
		},
		texts: {
			header1: "Introduction",
			p1: `Ah, yes. Stress. They're everywhere in our lives, and there are different types of them; there's physical, 
				mental, and even emotional stress. Their intensity and duration also vary; there is positive, tolerable, and 
				toxic stress. I know that we generally see stress as a bad thing and that we usually believe that we're just 
				at the very mercy of it, but we'll soon learn that this is not completely true. And to see how all these relate 
				to us, let's briefly reflect on how big of a factor stress is in our lives. Do you usually feel rested after a 
				night's sleep and after a break? What do you usually do before going to sleep and what are some things that you 
				do during your break?`,
			p2: `Identifying stressors in our lives helps us better understand how we can improve our situation and possibly 
				even use them to our advantage.`,
			header2: "Defining stress",
			p3: `Before going any further, to ensure we're all on the same page, we'll define <b><u>stress</u></b> to mean the following: <b>a 
				level of exertion to do some work.</b> So, when we say physical stress, we're referring to our body doing work, such 
				as when we're trying to carry something, or running really fast. And depending on how much we exert ourselves, 
				different tasks will require different levels of stress. So, the more we exert ourselves trying to do something, 
				the higher the physical stress we're putting our body through. Similarly, when we say mental stress, we're referring 
				to the amount of exertion we're putting our mind through; the same goes for emotional stress, when we're feeling 
				overwhelmed, exhausted, etc.`,
			header3: "Types of stress",
			p4: `Now let's learn the different levels of stress. We mentioned positive, tolerable, and toxic stress. <b>Positive 
				stress, or <u>eustress</u>, is a kind of stress that allows us to perform at a high level</b>, such as when we're making a speech, 
				running a mile, or doing a test. It's a kind of stress that places us into a focused state, which helps rally our body's 
				energy reserves to get the job done. <b>This kind of stress is brief and we usually have a choice if we want to go through 
				it or not.</b>`,
			p5: `<b><u>Tolerable stress</u> is more prolonged, lasting for about a few hours to a few days.</b> This kind of stress can throw us 
				off balance. Examples of this include having a fight with someone, experiencing the death of a family member, or going 
				through a natural disaster. <b>The reason why this is referred to as tolerable stress is because we have a support structure, 
				usually our friends and/or family, that aids our adaptive coping, which provides our brain the opportunity to recover from 
				potentially damaging effects.</b>`,
			p6: `<b><u>Toxic stress</u> occurs frequently and over a long period of time, usually from a few days to a few weeks, sometimes to a 
				few years, and it happens in the absence of a support structure.</b> This is a kind of stress that really wears us down, such 
				as when we're constantly bullied in school, we're experiencing family violence, or extreme poverty. <b>This a type of stress 
				that should be avoided at all costs, since it has the potential to completely restructure our brain's architecture permanently</b>, 
				if we don't do anything about it. Additionally, it lowers our stress threshold, which means it becomes easier to stress us out. 
				And lastly, it can increase risks of stress-related physical and mental illness.`,
			p7: `As we can see here, there are varying levels of stress defined by its duration, the presence of a support structure, and 
				its effects on our mental health.`,
			header4: "Conclusion",
			p8: `<b>You might've noticed that having a support structure becomes one of the defining elements of whether the stress is tolerable, 
				or toxic.</b> To those of us who are fortunate enough to have a support structure, that's great; I'm happy for you, because having a 
				support structure can sometimes be the determining factor whether we come out of extremely stressful situations as better, or worse, 
				people. To those of us who aren't fortunate enough to have an immediate support structure, we're going to need to rely on ourselves 
				more, especially if we also don't have access to therapists. We will learn more about some of the things that we can do in the next 
				article.`
		},
		sources: {
			s1: `Kapur, R. (n.d.). (PDF) understanding the types of stress. ResearchGate. https://www.researchgate.net/publication/350342850_Understanding_the_Types_of_Stress `,
			s2: `Weiner, C. (2021, November 30). Positive, tolerable and toxic stress responses; what are the differences?. EFT International. https://eftinternational.org/positive-tolerable-and-toxic-stress-responses-what-are-the-differences/ `
		}
	},
	handlingStress: {
		article: {
			title: "Ways for handling stress",
			description: "Learn the four categories of handling stress.",
			thumbnailAlt: "Two people on a hammock watching the sunset."
		},
		texts: {
			header1: "Introduction",
			p1: `Hello everyone. Welcome to another article about stress. Before we start, I just want to make a few things clear: 
				I'm not a psychologist, or a therapist, and my knowledge of properly handling stress is limited. So if you're at a 
				point in which stress is getting too much to handle, please consult a therapist and/or doctor, since not properly 
				handling stress can even be life-threatening. What I can offer in this article is some suggestions extracted from 
				studies about how to properly cope with stress, when there isn't an immediate support structure around you. And as 
				with most articles, let's first try to get ourselves involved in the topic by asking how it all relates to us. How 
				do you respond and/or cope with the stressor(s)?`,
			p2: `We need to analyze how we typically respond to and cope with stress, because this can have implications on our 
				health and how we approach life overall. Analyzing how we handle stress also allows us to improve ourselves, so we're 
				better equipped the next time we encounter the same stressors. For this article, we'll focus on <b>four categories of 
				handling stress; they are the following: 1.) thoughts and feelings, 2.) physiology, 3.) environment, and 4.) health 
				and well-being.</b>`,
			p3: `To make the best use of these guides and to see how we can apply them, we'll use a simple scenario as we go through 
				the categories. The scenario is the following:`,
			header2: "The situation",
			p4: `Imagine you're in college and it's the first semester you're attending this school. So, you don't know anyone and 
				your parents can't really help with your studies. Furthermore, you're already halfway through the semester and you just 
				received your first midterm results; you got a D. What is unfortunate about this, is that, if you fail this course, 
				you'll need to repeat it, your graduation date will get delayed for a semester, and you won't get priority timeslot when 
				all the students are picking their classes, which might even delay your graduation date even more. You're basically done 
				for if you don't manage to get a good grade in the next midterm. So, what do you do? Let's go through the categories.`,
			header3: "Thoughts and feelings",
			p5: `The first category is thoughts and feelings; we need to understand our thoughts and feelings when experiencing the 
				stressor to help us refocus on things that matter more. Having just received your grade, there's a good chance that 
				you're panicking a bit and that you might feel like screaming at the top of your lungs; experiencing these things means 
				that you understand the situation and the emotions you have and that you're allowing yourself to express them. If you're 
				struggling a bit to understand your situation, some people find it helpful to write them down, while some find it helpful 
				to talk about it with their friends and/or family. <b>Concentrating on your response to the stressor, instead of continuing to 
				focus on the stress, allows you to shift your focus from a first-person point-of-view to a third-person point-of-view; 
				this effectively breaks any negative mental and emotional feedback loop that numerous people experience when finding 
				themselves in these types of situations.</b>`,
			p6: `Once you've broken out of the negative mental and emotional feedback loop, you can now focus on reframing your thoughts 
				and feelings by reminding yourself that you've gone through much worse, that you can do it, and that you just need to 
				improve your approach to learning. If this is absolutely the worst thing you've encountered in your life, then there's a 
				good chance that, once you get through this, you'll be even more prepared to face any challenges in the future, unless 
				it's another worst thing that happened in your life. <b>It's important to reframe things in a more positive light, since this 
				has the physiological effect of making you feel better and more prepared to do what needs to get done.</b>`,
			p7: `This category is probably the most difficult of the four categories we'll learn, because we can have multiple stressors 
				in a given day and reframing our thoughts and feelings is the most readily accessible method in addressing the stress, compared 
				to the other three categories. And depending on how we react to these stressors, they can either collectively combine into this 
				huge ball of stress, or something completely small, or negligible. So when we can, we should try to frame events more positively 
				and have the capacity to acknowledge when things are actually bad and should be addressed.`,
			p8: `Additionally, this can serve as a temporary measure if we simply don't have the time to properly process and act on the things 
				that just happened, even after refocusing on the positives and on the work itself. This is part of the reason why I highly encourage 
				everyone to create a support structure if they don't already have one.`,
			header4: "Physiology",
			p9: `Now for the second category for handling stress, physiology. Even though we've refocused our minds on more positive things and 
				that we've bolstered our motivation to try to learn better, <b>our bodies might still be in the same stressful state as when we first 
				experienced the stressor. To fix this, we can do the following:</b>`,
			p10: "1.) Breathing exercises",
			p11: "2.) Stretch to loosen the muscle",
			p12: "3.) Take breaks to clear the mind",
			header5: "Environment",
			p13: "Category three, which is the environment, can also help with this. You can:",
			p14: "1.) Spend some time in nature, for example going to the park",
			p15: "2.) Get some morning sun for vitamin D, to prevent depression and improve your response to stressors",
			p16: "3.) Adjust, or modify, your space to make it more comfortable",
			p17: `4.) Eliminate some clutter around you to reduce anxiety and improve focus; this is actually a good reason why doing chores makes 
				it easier to relax and allows you to breathe better`,
			p18: `5.) Listen to leaves rustling from the wind or the beach waves crashing onto the shore. If you're like most of us and you don't 
				have time for this, listening to relaxing music can also help`,
			header6: "Health and well-being",
			p19: `The fourth category is working on your health and well-being. Stress will be a constant in our lives. So, some of the best ways 
				to improve how our body reacts to it include:`,
			p20: "1.) Having a well-balanced diet",
			p21: "2.) Sticking to an exercise regiment",
			p22: "3.) Getting adequate sleep",
			header7: "Conclusion",
			p23: `There we have it. To properly handle stress, we can remind ourselves of the following four categories:`,
			p24: `1.) The thoughts and feelings we have when stressors occur can be reframed to something more positive`,
			p25: `2.) Our physiological response can be improved through breathing exercises, stretching, and taking breaks`,
			p26: "3.) We can go to a more relaxing environment and/or tidy up our place",
			p27: "4.) And, we can work on our health and well-being to improve our resilience toward stress"
		},
		sources: {
			s1: `Weiner, C. (2021, November 30). Positive, tolerable and toxic stress responses; what are the differences?. EFT International. https://eftinternational.org/positive-tolerable-and-toxic-stress-responses-what-are-the-differences/ `
		}
	},
	restSleep: {
		article: {
			title: "How to get good quality rest: sleep",
			description: "Learn the causes of bad sleep and how to improve it.",
			thumbnailAlt: "A cat sleeping on the bed under a blanket."
		},
		texts: {
			header1: "Introduction",
			p1: `In this article, we'll learn how to get good quality rest, which will include, you guessed it, sleep and breaks. 
				We'll specifically learn some causes of bad sleep and how to improve it, in addition to recognizing when to 
				take breaks, some elements of a good break, why it's important, and some break suggestions. Since this is a fairly 
				large topic, it'll be broken up into two parts; we'll learn about sleep in this article, and taking breaks in the 
				next article. It's important to understand how to get good quality rest, because much of our brain's health, 
				efficiency, and development depends on it. And to see how we can benefit from this, let's briefly think of how our 
				sleep and break quality usually are.`,
			header2: "Causes of bad sleep",
			p2: `There are numerous factors to consider when trying to improve our rest quality, one of them is considering what contributes to its poor quality. For sleep, there are many. The following is just a handful of them:`,
			p3: "1.) Environmental issues",
			p4: `	<div style="margin-left: 24px;">a. It's too bright</div>`,
			p5: `	<div style="margin-left: 24px;">b. Noise</div>`,
			p6: `	<div style="margin-left: 24px;">c. Exposure to tobacco in the room, room's air quality, and room's scent</div>`,
			p7: "2.) Lifestyle",
			p8: `	<div style="margin-left: 24px;">a. Not exercising</div>`,
			p9: `	<div style="margin-left: 24px;">b. Too much screen time before bed</div>`,
			p10: `	<div style="margin-left: 24px;">c. Irregular or long daytime naps</div>`,
			p11: `	<div style="margin-left: 24px;">d. Extended time in bed</div>`,
			p12: `	<div style="margin-left: 24px;">e. Irregular sleep schedule</div>`,
			p13: "3.) Psychiatric, psychological, and physical problems",
			p14: `	<div style="margin-left: 24px;">a. Depression</div>`,
			p15: `	<div style="margin-left: 24px;">b. Anxiety, stress, tension</div>`,
			p16: `	<div style="margin-left: 24px;">c. Sadness, depression</div>`,
			p17: `	<div style="margin-left: 24px;">d. PTSD</div>`,
			p18: `	<div style="margin-left: 24px;">e. Strenuous physical activity, fatigue</div>`,
			p19: "4.) Medication and drugs",
			p20: `	<div style="margin-left: 24px;">a. Pain</div>`,
			p21: "5.) Genetics",
			p22: `	<div style="margin-left: 24px;">a. Researchers found a genetic basis for narcolepsy</div>`,
			p23: `	<div style="margin-left: 24px;">b. Sleep apnea</div>`,
			header3: "Improving sleep",
			p24: `As we can see here, we have <b>several categories contributing to poor sleep; we have environmental issues, 
				our lifestyle, if we're experiencing psychiatric, psychological and/or physical problems, if we're taking 
				any medication or drugs, and, lastly, if we inherited any genes that make us susceptible to sleep disorders</b>, 
				such as sleep apnea, or narcolepsy, which is basically an overwhelming daytime drowsiness. You might've noticed 
				that as we go down the list, we're more likely unable to address the issues ourselves; we'll need to consult 
				a doctor for the last three categories. So, the things that we can do to improve our sleep will mostly correspond 
				to the first two categories. And even if some of our sleep problems aren't included in the list, we can still 
				improve its overall quality by doing some of the following:`,
			p25: "1.) Environment",
			p26: `	<div style="margin-left: 24px;">a. Reduce blue light exposure in the evening (screens)</div>`,
			p27: `	<div style="margin-left: 24px;">b. Set a comfortable bedroom temperature (60 - 70F, 18 - 21C)</div>`,
			p28: `	<div style="margin-left: 24px;">c. Get a comfortable mattress and pillow</div>`,
			p29: `	<div style="margin-left: 24px;">d. Consider opening your window, or getting an air purifier</div>`,
			p30: "2.) Lifestyle",
			p31: `	<div style="margin-left: 24px;">a. Normalize your circadian rhythm with the correct light color exposure</div>`,
			p32: `	<div style="margin-left: 24px;">b. Reduce irregular or long (more than 30 minutes) daytime naps</div>`,
			p33: `	<div style="margin-left: 24px;">c. Routine</div>`,
			p34: `		<div style="margin-left: 48px;">i. Sleeping and waking up at consistent times (sleep schedule)</div>`,
			p35: `		<div style="margin-left: 48px;">ii. Exercise regularly - but not before bed</div>`,
			p36: `		<div style="margin-left: 48px;">iii. Bedtime routine (usually 30 minutes before going to bed)</div>`,
			p37: `		<div style="margin-left: 48px;">iv. Disconnect from phones and computers</div>`,
			p38: `		<div style="margin-left: 48px;">v. Take a relaxing bath or shower</div>`,
			p39: `		<div style="margin-left: 48px;">vi. Relax and clear your mind</div>`,
			p40: `	<div style="margin-left: 24px;">d. Pay attention to what you eat and drink</div>`,
			p41: `		<div style="margin-left: 48px;">i. Don't consume caffeine late in the day</div>`,
			p42: `		<div style="margin-left: 48px;">ii. Don't eat late in the evening</div>`,
			p43: `		<div style="margin-left: 48px;">iii. Don't drink too much liquid before bed and try to go to the bathroom before going to bed</div>`,
			p44: `		<div style="margin-left: 48px;">iv. Don't drink alcohol before bed</div>`,
			p45: "3.) Supplements (consider side-effects)",
			p46: `	<div style="margin-left: 24px;">a. Melatonin</div>`,
			p47: `	<div style="margin-left: 24px;">b. Ginkgo biloba</div>`,
			p48: `	<div style="margin-left: 24px;">c. Glycine</div>`,
			p49: `Starting with the environment, specifically our bedroom, we can't expect to have good sleep if our place 
				isn't comfortable to begin with. So first, we need to consider the room's lighting; remember from our environment 
				module that blue light, which often comes from screens, makes us more alert, while warmer orangey light makes us 
				more relaxed, and how the light is distributed and angled around the room will also contribute to this relaxing atmosphere.`,
			p50: `After lighting, we then need to consider the room's temperature; studies show that 65 to 70 degrees Fahrenheit, 
				or 18.33 to 21.11 degrees Celsius, is the most comfortable temperature range, but this, again, differs from person 
				to person.`,
			p51: `Next up, having a comfortable mattress and pillow; this one is kind of obvious.`,
			p52: `And finally, the smell. As we discussed in the environment module, different scents have varying effects on 
				our ability to think, and how easy it is to relax. Besides using essential oils, some people find it helpful to use 
				air purifiers, especially when opening your window isn't an option.`,
			p53: `The next category on our list is our lifestyle. We can normalize our circadian rhythm, which is our body's internal 
				clock, by getting some sunlight throughout the day. So by the time it starts getting dark outside, our body would more 
				naturally start winding down.`,
			p54: `Another thing we can do is to reduce, or avoid, irregular, or long, usually more than 30 minutes, daytime naps. 
				The reason for this is that naps decrease our sleep pressure throughout the day, and we need enough sleep pressure at 
				night to fall asleep.`,
			p55: `One good lifestyle change that we can do to promote better sleep is to follow a set of routines, which includes 
				sleeping and waking up at consistent times, exercising regularly, of course not before bed, and <b>cultivating a bedtime 
				routine, which includes disconnecting from our phones and computers, taking a relaxing bath, or shower, and trying to 
				relax and clear our mind</b>. By the way, having a sleep schedule is also another way to normalize our circadian rhythm.`,
			p56: `Another factor that we need to consider is what we eat and drink; it doesn't make sense for us to be caffeinated, 
				or feel full, and still expect to fall asleep; caffeine hypes us up, and having food in our stomach causes all the 
				blood to rush to our stomach to digest food, which doesn't help us sleep. Also, drinking a lot of liquid before going 
				to sleep can mean getting up late at night to go to the bathroom, which also disrupts our sleep quality. <b>One specific 
				drink to avoid before sleeping is alcohol, since it's been shown to increase symptoms of sleep apnea, snoring, disruptive 
				sleep patterns, and that it reduces the production of melatonin, which is a hormone that helps us sleep at night.</b>`,
			p57: `This brings us to the last category, which is supplements. As I mentioned, melatonin helps us sleep at night, but, if 
				we're like some people who don't produce enough of it, we can get some melatonin supplements over the counter. Other 
				sleep-inducing supplements are ginkgo biloba and glycine, although melatonin is the most popular among them. I do have 
				to warn people that we shouldn't depend on sleeping supplements for our sleeping problems, since, although not addicting, 
				we can become dependent on them and there are risks of taking too many sleeping supplements.`,
			header4: "Conclusion",
			p58: `To summarize what we learned about sleep, <b>the causes of bad sleep include environmental issues, our lifestyle, psychiatric, 
				psychological and physical problems, medication and drugs, and our genes. To improve our sleep, we can improve our environment 
				by adjusting the lighting, temperature, its overall comfort, and its airflow; we can improve our lifestyle by normalizing 
				our circadian rhythm, controlling our daytime naps, setting a routine, and paying attention to what we eat and drink before 
				going to bed; and, we can consider taking supplements, such as a melatonin, ginkgo biloba, and glycine.</b>`
		},
		sources: {
			s1: `Altun, I., Cinar, N., & Dede, C. (2012, June). The contributing factors to poor sleep experiences in according to the university students: A cross-sectional study. Journal of research in medical sciences : the official journal of Isfahan University of Medical Sciences. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3634295/#:~:text=The%20most%20frequent%20self%2Dreported,of%20the%20room%20(55%2C1 `,
			s2: `American Psychological Association. (n.d.). Factors that contribute to insomnia. Monitor on Psychology. https://www.apa.org/monitor/oct01/insomnia `,
			s3: `Mantua, J., & Spencer, R. M. C. (2017, September). Exploring the NAP paradox: Are mid-day sleep bouts a friend or Foe?. Sleep medicine. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5598771/#:~:text=At%20times%2C%20naps%20reduce%20sleep,been%20altered%20by%20extended%20wakefulness. `,
			s4: `Mayo Foundation for Medical Education and Research. (2022b, May 7). 6 steps to better sleep. Mayo Clinic. https://www.mayoclinic.org/healthy-lifestyle/adult-health/in-depth/sleep/art-20048379 `,
			s5: `Melinda Smith, M. A., & Robinson, L. (2024, May 10). How to sleep better: Tips to improve sleep quality. HelpGuide.org. https://www.helpguide.org/articles/sleep/getting-better-sleep.htm `,
			s6: `Prado, D. (n.d.). 15 proven tips to sleep better at night. Healthline. https://www.healthline.com/nutrition/17-tips-to-sleep-better#17.-Dont-drink-any-liquids-before-bed `,
			s7: `Suni, E., & Singh, Dr. A. (2023, December 8). 20 tips for how to sleep better. Sleep Foundation. https://www.sleepfoundation.org/sleep-hygiene/healthy-sleep-tips `,
			s8: `Team, S. (2024, January 24). How much sleep does the average American get?. The Checkup. https://www.singlecare.com/blog/news/sleep-statistics/#:~:text=National%20sleep%20statistics&text=70%20million%20Americans%20have%20chronic,18%20(CDC%2C%202018) `,
			s9: `WebMD. (n.d.). Causes of sleep problems. WebMD. https://www.webmd.com/sleep-disorders/sleep-disorders-causes `
		}
	},
	restBreak: {
		article: {
			title: "How to get good quality rest: breaks",
			description: "Learn when to take a break, the elements of a good break, and why it's important to take a break.",
			thumbnailAlt: "White neon sign of 'coffee break'."
		},
		texts: {
			header1: "Introduction",
			p1: `It's now time to learn the signs of <b>when to take a break, the elements of a good break, some suggestions on 
				how to take breaks, and why it's important to take breaks.</b>`,
			header2: "Reacting to work",
			p2: `<b>How we determine when to take a break depends on two factors. One, how we react to stressors in our lives, and 
				two, for how long we're exposed to those stressors.</b> To put this into an example, let's say that we're a student, or 
				employee, and that we need to get some work done. Now there are many ways that we can respond to the work, but we'll 
				simplify them into three responses: with excitement, with dread, because it's too hard, or with boredom, because it 
				might be too easy, or just unsatisfying work.`,
			p3: `If it's exciting work, there's a chance that we'll finish the work quickly and that we're also willing to take on 
				greater and more challenging work.`,
			p4: `If it's too hard, we might take longer to finish it, and we might feel incompetent along the way, and maybe 
				eventually become passive, or unmotivated.`,
			p5: `And if it's too easy, we might finish it quickly, or procrastinate until it's due, and, along the way, maybe feel 
				bored, trapped, and/or unsatisfied with the work. This is the reaction factor, let's now consider the duration factor, 
				how long we've been doing the work.`,
			header3: "Work duration",
			p6: `We'll split the duration between short-term and long-term. For the short-term, if we've been working for, let's say 
				an hour straight, eventually our ability to concentrate would get worse as time goes by; this is true regardless of our 
				reaction, since this is strictly how our biology works. And as we discussed before, this inability to maintain our focus 
				over a period of time due to decreasing glucose levels is called <b>ego depletion, and it's the first sign that we should 
				take a break.</b>`,
			p7: `For the long-term, assuming that we don't take proper and/or sufficient breaks in between, and depending on how we 
				feel about the task, we can expect to begin at varying points along the stages of eventually burning out.`,
			p8: `But before going there, we need to learn the three types of burnout: there is 1) overload / frenetic burnout, 
				2) neglect burnout, and 3) under-challenged burnout. <b><u>Overload, or frenetic burnout</u>, is when we try to work harder and 
				harder in pursuit of success; we're essentially in a prolonged excited state.</b> <b><u>Neglect burnout</u> is when we feel helpless, 
				incompetent, or like an imposter, since we're struggling to keep up with work demands</b>; eventually, we stop trying and 
				become passive and unmotivated; neglect burnout is the result of a prolonged pressured state.</b> And finally, <b><u>under-challenged 
				burnout</u> is when we feel underappreciated, bored, trapped, and/or unsatisfied in our job, task, or school; this happens 
				when we're in a prolonged passive state.</b>`,
			p9: `There are further details into the specific stages until we eventually burn out and the signs when we're actually burnt 
				out in the diagram. The important thing to remember here is that we need to take the right steps before finding ourselves 
				in one of these stages, or noticing that we're already exhibiting some burnout symptoms. And one way to check if we're 
				already at some point in this diagram is by memorizing the diagram's flow; this will really come in handy if we absolutely 
				have no choice, but to power through some of life's many struggles.`,
			header4: "What is a good break?",
			p10: `So now that we know when to take a break, we need to then figure out what constitutes a good break. They are the following:`,
			p11: `One, <b>independence</b> - whatever we do on our breaks should be completely our choice, since this should be our time to do 
				what relaxes us, and who better knows how to relax than us?`,
			p12: `Two, <b>a high degree of enjoyment</b> - it doesn't have to be really enjoyable, but the more enjoyable the better, since endorphins, 
				which I'd like to call feel-good hormones, get released, which actually aids in mental recovery.`,
			p13: `Lastly, <b>novelty</b>, which means new, original, or unusual. A good example of this is a new video game you want to play, a new 
				show you want to watch, or a new activity; you get the picture.`,
			header5: "Break suggestions",
			p14: `Now, the breaks will depend on each of our preferences, but I would also like to suggest a few, because of just the crazy 
				benefits these activities have. One, <b>spending time around nature</b>, or just the park where there are plenty of plants, has been shown 
				to improve our focus, build our confidence, prevent obesity, and a myriad of other psychological and physical health benefits. `,
			p15: `And two, <b>mindful meditation</b>, which is basically a type of meditation where you focus on what you're sensing and feeling in the 
				moment, without judging or interpreting it. This type of meditation helps reduce stress levels, improves how long we can stay focused, 
				and improves our self-regulation, in addition to other psychological and physical benefits. Please keep in mind that this is mindful 
				meditation; other types of meditation cause different effects on our mind and body.`,
			header6: "Cultivating lifelong habits",
			p16: `The elements of a good break and the suggestions I gave are good for short-term breaks. If we want to prevent ourselves from ever 
				burning out, we need to cultivate life-long habits, and they're essentially the following three: <b>exercising, eating a balanced diet, 
				and practicing good sleep habits</b>. Exercise is good for regulating physical and emotional health, and it helps us expend any excess 
				energy we might have, which also improves our sleep. `,
			p17: `Eating a balanced diet is essentially the fuel we're putting in our body, so if our diet is clean and healthy, then we'll feel good 
				throughout the day, and vice versa if we eat junk food.`,
			p18: `Practicing good sleeping habits helps ensure that we have plenty of energy the following day in addition to helping relieve the 
				stress we've accumulated throughout the day.`,
			header7: "Default mode network (DMN)",
			p19: `And finally, as to why taking proper breaks is important. Simply put, if the break isn't good quality, we might actually make our 
				stress worse, or at the least not help alleviate the stress, in which case we just wasted our time. Inversely, in addition to the benefits 
				we've learned, good breaks improve our <b><u>default mode network, or DMN</u>, which is the set of areas around our brain that gets fired 
				when we're on our downtime. Having a good DMN improves our cognitive ability; it is important for brain recovery; and it even helps us 
				properly process life events.</b>`,
			header8: `Conclusion & Summary`,
			p20: `There are definitely a lot of details in this article, so, like what I did to help us identify when to take breaks, I also included 
				further information on the elements of a good break and why it's important to take good breaks down below. In summary, <b>we need to take a 
				break when we're noticing that it's getting harder for us to concentrate and if we're recognizing signs of burnout. Our breaks should 
				have these three elements: enjoyment, independence, and novelty. As mentioned before, consider going out in nature and meditating because 
				of their many benefits, which include improving our stress levels, aiding our brain recovery, and helping with any self-reflection.</b>`,
			header9: "When to take a break",
			p21: "1.) Ego depletion",
			p22: "2.) Burn out: state of chronic stress",
			p23: `	<div style="margin-left: 24px;">a. Types of burn out</div>`,
			p24: `		<div style="margin-left: 48px;">i. Overload / frenetic burnout (prolonged excited state)</div>`,
			p25: `			<div style="margin-left: 72px;">- When you work harder and harder in the pursuit of success</div>`,
			p26: `		<div style="margin-left: 48px;">ii. Neglect burnout (prolonged pressured state)</div>`,
			p27: `			<div style="margin-left: 72px;">- You feel helpless, incompetent, or like an imposter due your inability to keep up with demands</div>`,
			p28: `			<div style="margin-left: 72px;">- You eventually stop trying and you become passive and unmotivated</div>`,
			p29: `		<div style="margin-left: 48px;">iii. Under-challenged burnout (prolonged passive state)</div>`,
			p30: `			<div style="margin-left: 72px;">- You feel underappreciated, bored, trapped, and/or unsatisfied in your job/task/school</div>`,
			p31: `	<div style="margin-left: 24px;">b. Stages</div>`,
			p32: `		<div style="margin-left: 48px;">i. Excessive drive/ambition</div>`,
			p33: `		<div style="margin-left: 48px;">ii. Pushing yourself to work harder</div>`,
			p34: `		<div style="margin-left: 48px;">iii. Neglecting your own needs</div>`,
			p35: `		<div style="margin-left: 48px;">iv. Displacement of conflict</div>`,
			p36: `			<div style="margin-left: 72px;">- Instead of acknowledging that you're pushing yourself to the max, you blame your boss, the demands of your job, or colleagues for your troubles</div>`,
			p37: `		<div style="margin-left: 48px;">v. No time for nonwork-related needs</div>`,
			p38: `		<div style="margin-left: 48px;">vi. Denial</div>`,
			p40: `			<div style="margin-left: 72px;">- Impatience with those around you mounts. Instead of taking responsibility for your behavior, you blame others, seeing them as incompetent, lazy, and overbearing</div>`,
			p41: `		<div style="margin-left: 48px;">vii. Withdrawal</div>`,
			p42: `		<div style="margin-left: 48px;">viii. Behavioral changes</div>`,
			p43: `			<div style="margin-left: 72px;">- May become more aggressive</div>`,
			p44: `		<div style="margin-left: 48px;">ix. Depersonalization</div>`,
			p45: `			<div style="margin-left: 72px;">- Feeling detached from your life and your ability to control your life</div>`,
			p46: `		<div style="margin-left: 48px;">x. Inner emptiness or anxiety</div>`,
			p47: `			<div style="margin-left: 72px;">- You may turn to thrill seeking behaviors to cope with this emotion, such as substance use, gambling, or overeating</div>`,
			p48: `		<div style="margin-left: 48px;">xi. Depression</div>`,
			p49: `			<div style="margin-left: 72px;">- Life loses its meaning and you begin to feel hopeless</div>`,
			p50: `		<div style="margin-left: 48px;">xii. Burnout syndrome</div>`,
			p51: `	<div style="margin-left: 24px;">c. Symptoms</div>`,
			p52: `		<div style="margin-left: 48px;">i. Exhaustion / chronic fatigue (physical and mental)</div>`,
			p53: `			<div style="margin-left: 72px;">- Physical symptoms may include headaches, stomachaches, and appetite or sleeping changes</div>`,
			p54: `		<div style="margin-left: 48px;">ii. Isolation</div>`,
			p55: `			<div style="margin-left: 72px;">- People feel overwhelmed which leads them to isolate themselves</div>`,
			p56: `		<div style="margin-left: 48px;">iii. Escape fantasies</div>`,
			p57: `			<div style="margin-left: 72px;">- People may turn to drugs, alcohol, or food as a way to numb their emotional pain</div>`,
			p58: `		<div style="margin-left: 48px;">iv. Irritability</div>`,
			p59: `		<div style="margin-left: 48px;">v. Frequent illness</div>`,
			p60: `			<div style="margin-left: 72px;">- Burn out weakens immune system</div>`,
			p61: `			<div style="margin-left: 72px;">- Can lead to health concerns like depression and anxiety</div>`,
			p62: `		<div style="margin-left: 48px;">vi. Insomnia</div>`,
			p63: `		<div style="margin-left: 48px;">vii. Forgetfulness/impaired concentrate</div>`,
			p64: `		<div style="margin-left: 48px;">viii. Loss of appetite</div>`,
			p65: `		<div style="margin-left: 48px;">ix. Physical symptoms includes the following:</div>`,
			p66: `			<div style="margin-left: 72px;">- Chest pain</div>`,
			p67: `			<div style="margin-left: 72px;">- Heart palpitations</div>`,
			p68: `			<div style="margin-left: 72px;">- Shortness of breath</div>`,
			p69: `			<div style="margin-left: 72px;">- Gastrointestinal pain</div>`,
			p70: `			<div style="margin-left: 72px;">- Dizziness</div>`,
			p71: `			<div style="margin-left: 72px;">- Fainting</div>`,
			p72: `			<div style="margin-left: 72px;">- Headaches</div>`,
			p73: `		<div style="margin-left: 48px;">x. Anxiety</div>`,
			p74: `		<div style="margin-left: 48px;">xi. Quick to anger</div>`,
			header10: "Taking care of yourself",
			p75: "1.) Good break",
			p76: `	<div style="margin-left: 24px;">a. Independence</div>`,
			p77: `	<div style="margin-left: 24px;">b. High degree of enjoyment</div>`,
			p78: `	<div style="margin-left: 24px;">c. Novelty</div>`,
			p79: "2.) Short-term suggestions (breaks) Pomodoro technique",
			p80: `	<div style="margin-left: 24px;">a. Park / nature time</div>`,
			p81: `		<div style="margin-left: 48px;">i. Can reduce symptoms of ADHD</div>`,
			p82: `		<div style="margin-left: 48px;">ii. Improves focus</div>`,
			p83: `		<div style="margin-left: 48px;">iii. Builds confidence</div>`,
			p84: `		<div style="margin-left: 48px;">iv. Prevents obesity</div>`,
			p85: `		<div style="margin-left: 48px;">v. Offers other psychological and physical health benefits</div>`,
			p86: `	<div style="margin-left: 24px;">b. Mindful meditation, following just lists a few of its benefits</div>`,
			p87: `		<div style="margin-left: 48px;">i. Reduces stress levels</div>`,
			p88: `		<div style="margin-left: 48px;">ii. Improves our ability to concentrate by allowing us to stay focused a lot longer and more intensely</div>`,
			p89: `		<div style="margin-left: 48px;">iii. Improves self-regulation</div>`,
			p90: "3.) Long-term habit suggestions (prevent burn out)",
			p91: `	<div style="margin-left: 24px;">a. Exercise</div>`,
			p92: `		<div style="margin-left: 48px;">i. Good for physical and emotional health</div>`,
			p93: `	<div style="margin-left: 24px;">b. Eating a balanced diet</div>`,
			p94: `		<div style="margin-left: 48px;">i. Diet filled with omega-3 fatty acids is a natural antidepressant</div>`,
			p95: `	<div style="margin-left: 24px;">c. Practice good sleep habits</div>`,
			header11: "Why proper breaks are important",
			p96: `1.) If done incorrectly`,
			p97: `	<div style="margin-left: 24px;">a. The 'break' can make stress worse, or at least not help alleviate it</div>`,
			p98: "2.) If done properly, you can improve your default mode network (DMN) toggling",
			p99: `	<div style="margin-left: 24px;">a. Helps process life events</div>`,
			p100: `		<div style="margin-left: 48px;">i. It's a system for self-reflection</div>`,
			p101: `			<div style="margin-left: 72px;">- This system is active when we're not focused on a task</div>`,
			p102: `			<div style="margin-left: 72px;">- Correlates with a strong sense of identity and a capacity for empathy</div>`,
			p103: `	<div style="margin-left: 24px;">b. Do better on tests of cognitive ability, includes:</div>`,
			p104: `		<div style="margin-left: 48px;">i. Memory</div>`,
			p105: `		<div style="margin-left: 48px;">ii. Flexibility of thought</div>`,
			p106: `		<div style="margin-left: 48px;">iii. Reading comprehension</div>`,
			p107: `		<div style="margin-left: 48px;">iv. Better mental health</div>`,
			p108: `	<div style="margin-left: 24px;">c. Important for brain recovery</div>`,
			p109: `		<div style="margin-left: 48px;">i. Conceptual integration</div>`,
			p110: `			<div style="margin-left: 72px;">- Important for storage permanence</div>`,
			p111: `			<div style="margin-left: 72px;">- Helps process complicated ideas in the background (without you knowing)</div>`,
			p112: `				<div style="margin-left: 96px;">+ This is why you would sometimes just understand some concepts randomly, when you're not doing anything related to it</div>`,
			p113: `Congratulations on finishing another module. I know that this is definitely one of the more challenging modules just from the 
				sheer breadth of concepts and details discussed. And like we discussed from the previous module's conclusion, much of the approach 
				and concepts discussed here share similarities with personalized coaching for top athletes, since, in addition to the diet and 
				lifestyle, we've also learned how to properly take care of ourselves in the long run. `,
			p114: `Since we've already covered details concerning our environment, our mentality, and our general well-being, for the remainder of 
				the class, we'll now focus on the tools and the process of actually learning optimally. The first three modules focus on getting us 
				to the best learning state and shape we can be; the remaining modules focus on the act itself, perfecting our practice and approach 
				when it comes to learning.`
		},
		sources: {
			s1: `Fraga, J. (2019, May 18). How to identify and prevent burnout. Healthline. https://www.healthline.com/health/tips-for-identifying-and-preventing-burnout#prevention `,
			s2: `Melinda Smith, M. A. (2024, May 30). Burnout: Symptoms, treatment, and coping strategy tips. HelpGuide.org. https://www.helpguide.org/articles/stress/burnout-prevention-and-recovery.htm# `,
			s3: `WebMD. (n.d.-a). Burnout: Symptoms, risk factors, prevention, treatment. WebMD. https://www.webmd.com/mental-health/burnout-symptoms-signs `
		}
	}
};