export const physiologyMessageMap = {
	declarativeSystem: {
		article: {
			title: "How information travels in our brain: the declarative system (Part 1)",
			description: "Learn the different parts of the declarative system and how it relates to learning.",
			thumbnailAlt: "A young boy shouting at a microphone."
		},
		texts: {
			header1: "Module introduction",
			p1: `Welcome to another module about optimizing our learning. <b>For this module, we'll focus on the physiological aspects 
				of how we learn. This will include how information travels in our brain, our different memory storages, and how we acquire 
				and develop knowledge. We'll then take what we learn from these sections and explore what it means to our learning and how 
				we should be taught. Then, we'll end the module with how our brain's development affects our learning.</b>`,
			p2: `One thing I want to point out before we begin is that this module has the heaviest number of scientific terms and that 
				the first 3 sections are highly interconnected, so the possibility of getting confused is higher. But fear not, this is also 
				the module that I've taken the greatest effort in carefully organizing the content to make the concepts easier to follow.`,
			p3: `Lastly, as I've said in the previous modules, the information we'll learn in this module is even more evidence that, 
				if we just put in the time and effort, we can be knowledgeable and smart. Evidence of how we can become smarter and more 
				creative will be discussed in detail in the “How-to-become-an-expert” module.`,
			p4: "And with that, let's get things started.",
			header2: "Introduction",
			p5: `First up on our list is how information travels in our brains. Have you ever wondered how information travels around our 
				brain? How does the information from what we read, watch, and do eventually get stored in there? Now, trying to understand 
				how information travels around the brain can get complicated. So, we'll mostly focus on the declarative and procedural systems. 
				But before we begin, why do you think it's important to understand how information travels in our brain in the context of learning?`,
			p6: `While there are many benefits of knowing how information travels in our brain, we're mainly concerned about how this further 
				improves our learning. We already briefly learned this in our environment module regarding the reduction of informational noise, 
				since it distracts us from fully focusing on and absorbing what we're learning. For this module specifically, by understanding 
				how information travels in our brain while we're learning, we will see why certain things that we do, such as reviewing, are 
				very important, and how the other commonly used methods of teaching and learning can be further improved.`,
			p7: `<b>For both declarative and procedural systems, we'll first learn what sort of information travels through them. Then, 
				we'll go into each system's components, and, finally, we'll end with how signals, or information, travel through each system.</b>`,
			header3: "Declarative system",
			p8: `So starting with the <b><u>declarative system</u>, the kinds of information that goes through this system are mostly conceptual 
				information, or the kinds of <u>information that we can “declare”</u> or be conscious of.</b>`,
			p9: `For example, when we're learning about the concept of force in physics, we're usually taught its (1) mathematical formula, (2) what `,
			p10: "situations we can apply the formula to, and (3) maybe even how",
			p11: `the formula is derived. We are aware of these concepts relating to force; again, we're dealing with information that we're 
				mostly conscious of, information that we can openly declare.`,
			header4: "Procedural system",
			p12: `This is in contrast to the information that travels through our <b><u>procedural system</u></b>, for example when we're shooting 
				a basketball; we're <b>not really aware of the many complicated steps, or <u>procedures</u>, of how</b> our body manages to throw a 
				ball to the hoop; we just know that we need to shoot the ball and our body somehow manages to throw the ball in the general direction 
				of the hoop. But I'm getting ahead of myself here; we'll learn more about the procedural system in part 2 of this topic. For now, we 
				can simplify the idea of declarative information as information that we're mostly aware of, and procedural information that we're 
				mostly unaware of.`,
			header5: "Components of declarative system",
			p13: "Regarding the <b><u>declarative system's components</u>, they are the following: <u>(1) the working memory, (2) the hippocampus,</u></b> ",
			p14: "and <b><u>(3) the neocortex</u></b>;",
			p15: `note that this is a side view of the brain straight from the very middle. Also, I decided to not show what the working memory is, 
				because it's actually a combination of different regions of the brain and isn't specific to a location compared to the hippocampus and 
				the neocortex. But for anyone who's interested, the <b>working memory is composed of the (1) central executive, (2) visuospatial sketchpad, 
				(3) phonological loop, and (4) episodic buffer</b>. Feel free to google what they look like. I've also provided links in the sources 
				section if anyone is interested in knowing more about them. But for our purposes, it's a lot simpler to just think of all these belonging 
				to the working memory.`,
			header6: "Information traveling through declarative system",
			p16: `Now that we have our components, we can now see how information travels through them. To simplify this discussion, we'll use the 
				following diagram:`,
			p17: `It all starts in our working memory, down at the bottom. Using the same example earlier when we're first learning about force, we 
				learn that force equals mass times acceleration, or F = ma. This information about the force's formula is currently stored in our <b><u>
				working memory</u>; this is the information we're currently working with.</b>`,
			p18: `<b>Information that's stored in the working memory is short-lived, and they're easy to forget.</b> So, if we're to suddenly lose 
				focus on what we're learning and concentrate on something else, there's a good chance that we'll forget the formula, because the information 
				is not easily retrievable from our long-term memory, which resides in the neocortex.`,
			p19: `<b>Information stored in our <u>long-term memory</u> lasts indefinitely, meaning that it can stay there from a few weeks to a few 
				months, and sometimes even for the rest of our lives, which is why it's called long-term memory. How long the information stays in our 
				long-term memory depends on how often we try to recall, or review, the information</b>; the more we try to recall the information, the 
				easier it is for us to retrieve it the next time we need it. And whenever we're trying to recall, or review, something, we use our hippocampus.`,
			p20: `<b>Our hippocampus' main job is to index information from our long-term memory</b>; it knows how to locate the information from our 
				long-term memory. So, <b>when the working memory tries to recall the formula for force, it sends this question to our hippocampus in step 
				1. Our hippocampus then sends the location of where to find the information to our neocortex in step 2. Then, our neocortex responds back 
				with the information, in step 3, that is located in the area that our hippocampus specified. Finally, our hippocampus returns this information 
				back to our working memory in step 4.</b>`,
			header7: "Memory consolidation",
			p21: `<b>Every time we go through steps 1 through 4, the hippocampus also helps link the concepts relating to force in our long-term memory. 
				Continually linking the concepts together in long-term memory helps strengthen their associations, and, at the same time, remove unnecessary 
				information that came along when we were first learning about force; <i>this process of strengthening conceptual relationships and removing 
				unnecessary information is called <u>memory consolidation</u></i>. So the more the information gets consolidated, the more permanent the 
				information gets stored in our long-term memory and the easier it gets to retrieve this same set of information.</b>`,
			p22: `Eventually, <b>with enough consolidation, information retrieval doesn't have to go through steps 1 through 4</b>; instead, the working 
				memory can directly communicate with our neocortex through steps 1a and 2b, as shown in the diagram. As an added bonus, <b>since we're directly 
				retrieving information from the neocortex, the size of information we can hold at a time in our working memory gets bigger.</b>`,
			header8: "Chunking",
			p23: `To elaborate further, in the beginning, we might be struggling to hold all the information about force, specifically its formula, application, 
				and derivation, since the information is completely new and that we're still seeing these pieces of information as mostly distinct and separated 
				from each other:`,
			p24: `In other words, in our minds, we might be seeing these as at least 3 distinct pieces of information. I say at least, because we might also 
				be associating these three concepts with other things in our mind. Anyway, <b>with enough memory consolidation, the next time we retrieve it, we 
				then see it as one <u>chunk</u> of information.</b>`,
			p25: `This is the main reason why experts appear to have numerous information readily accessible at their disposal relating to a specific topic; 
				it's due to their ability to chunk pieces of information as opposed to seeing them as individual concepts.`,
			header9: "Conclusion",
			p26: `And that's it for our declarative system. <b>Our declarative system relays information that we're conscious of. It's composed of the <u>working 
				memory</u>, which holds any information we're currently working with, the <u>hippocampus</u>, which is responsible for indexing and consolidating 
				information in the neocortex, and the <u>neocortex</u>, which holds our long-term memory.</b> We'll discuss the procedural system in Part 2 of this 
				topic. See you then.`
		},
		sources: {
			s1: `Hattie, J., & Yates, G. C. R. (2014a). Visible learning and the science of how we learn. Routledge, Taylor & Francis Group. `,
			s2: `Mcleod, S. (2023, November 20). Working memory model in psychology (baddeley & hitch). Simply Psychology. https://www.simplypsychology.org/working%20memory.html#:~:text=Working%20memory%20is%20a%20multi,for%20reasoning%2C%20learning%20and%20comprehension. `,
			s3: `Oakley, B. A., Rogowsky, B., & Sejnowski, T. J. (2021). Uncommon sense teaching: Practical insights in brain science to help students learn. TarcherPerigee, an imprint of Penguin Random House LLC. `,
			s4: `Working memory ao1 ao2 ao3. PSYCHOLOGY WIZARD. (n.d.-a). https://www.psychologywizard.net/working-memory-ao1-ao2-ao3.html `
		}
	},
	proceduralSystem: {
		article: {
			title: "How information travels in our brain: the procedural system (Part 2)",
			description: "Learn the different parts of the procedural system and how it relates to learning.",
			thumbnailAlt: "Focus on hands playing the piano."
		},
		texts: {
			header1: "Introduction",
			p1: "It's now time to discuss our procedural system.",
			p2: `As mentioned before, our <b><u>procedural system</u></b> deals with information that we're mostly unaware of. 
				To clarify, this <b>involves information associated with activities that are done procedurally, or activities that 
				require a specific set of steps, that eventually become automatic, or second nature, with more practice.</b> Examples 
				of this include learning how to shoot a basketball and eventually getting better at it; it's the same idea with 
				anything that requires us to do something with our body.`,
			p3: `Just to be on the same page, the <b>procedural system does not include activities that happen in the background, 
				such as regulating our body's hormones or controlling our body's excretory system.</b>`,
			header2: "Activities within procedural system",
			p4: `Working with information involved in the procedural system includes a level of automaticity that makes us hardly 
				aware of it, but it's not entirely out of our control. <b>The procedural system can be more specifically broken down 
				into two subsystems based on the types of activities: (1) goal-oriented activities, and (2) habitual activities:</b> `,
			p5: `<b><u>Goal-oriented activities</u> are activities that involve having a goal in mind that can be achieved procedurally</b>, 
				such as first learning how to shoot a basketball or learning how to write in cursive. <b><i>Our intention behind our 
				goal is what partially drives our body to move a certain way to achieve our goals.</i></b> Some of us might've noticed 
				that having a goal involves using our working memory; this is exactly correct, since this involves working with an 
				idea, in this case a goal, in our mind. <b>It's the goal's details that reside in our working memory, but it's the 
				procedural system that gets the job done. This is why it's extremely important to have a goal in mind, whenever you're 
				trying to achieve something, and that it's a lot better if that goal is crystal clear, since having a clear goal makes 
				it a lot easier for your procedural system to work more efficiently, which, in turn, helps you achieve your goal faster.</b>`,
			p6: `<b><u>Habitual activities</u></b>, on the other hand, are <b>activities we do out of habit without requiring a clear 
				goal</b>, such as going through the same route to and from work, or immediately checking our phone whenever we hear the 
				notification sound. These are the <b>things that we do so much out of habit that we're barely aware that we're doing 
				it, how we're doing it, or if we even did it</b>; this is why we often feel like we forget that we did something, like 
				closing the door on our way out, even though we've already done it.`,
			header3: "How goal-oriented activities become habitual activities",
			p7: `<b>Some of our goal-oriented activities eventually become habitual activities the more we do them.</b> From our earlier 
				example, the more we practice shooting the basketball, the more automatic it becomes and the less we think about our stance, 
				our knees straightening, our arms unfolding, and our wrists flicking as we try to shoot the ball. This is the <b><u>concept 
				behind muscle memory</u>; the more we practice something the more automatic the activity becomes and the less brain power 
				we use in doing it.</b> We should note that the term muscle memory is a misnomer, since it's actually our brain, specifically 
				our motor neurons in our motor cortex, that's mainly responsible for remembering our body's movements to execute a task.`,
			header4: "Components of procedural system",
			p8: `Now that we've defined what the procedural system is and its two sub-systems, we can now learn its components. Like the 
				declarative system, there are <b>three main components:</b>`,
			p9: `<b>We have the basal ganglia</b>, which is composed of the substantia nigra, the subthalamic nucleus, the globus pallidus, 
				the putamen, and the caudate nucleus. For simplicity's sake, we'll just refer to this whole group as the basal ganglia and I've 
				included a link below if anyone is interested in learning more about the basal ganglia in depth, in addition to what we'll 
				discuss in this article. Besides the basal ganglia, we also have our neocortex,`,
			p10: "and finally our <b>thalamus,</b>",
			p11: "which is lodged within the basal ganglia.",
			header5: "Information traveling through procedural system",
			p12: `Now that we've laid out all its major components, we can finally learn how information travels through this system. To 
				simplify our discussion, we'll use the following model:`,
			p13: `As we talked about earlier, our procedural system is driven by two types of activities: goal-oriented activities and habitual 
				activities. <b>Information received from goal-oriented activities comes from the working memory and makes its way toward the 
				front of the basal ganglia. On the other hand, information received through habitual activities coming from our sensory organs 
				makes its way towards the back of the basal ganglia; both of these are shown in step 1. Like the hippocampus, the basal ganglia 
				then creates conceptual links of the information in our neocortex in step 2. The signal eventually reaches the thalamus in steps 
				3 and 4, which then sends the signal to our motor cortex causing us to move parts of our body.</b>`,
			p14: `Again, <b>like our hippocampus in the declarative system, every time we go through steps 1 through 4, the basal ganglia help 
				link and consolidate concepts in our long-term memory; the difference here is that the basal ganglia work with information involving 
				both movements and intentions, or goals. Also, with enough practice, the procedural system has its own shortcut by going from 1a 
				and directly to 2a, which bypasses the basal ganglia, similar to how the declarative system bypasses the hippocampus</b>; this 
				allows activities to be executed a lot faster.`,
			header6: "Conclusion",
			p15: `And that's it for our procedural system. <b>Our procedural system relays information involving both movements and intentions. 
				It's composed of our basal ganglia, which is responsible for consolidating new skills, our neocortex, which holds our long-term 
				memory, and our thalamus, which helps relay the information to our motor neurons.</b> In the last part of this topic, which is 
				Part 3, we'll finish by briefly talking about how the two systems work together and compare them side-by-side.`
		},
		sources: {
			s1: `Hattie, J., & Yates, G. C. R. (2014a). Visible learning and the science of how we learn. Routledge, Taylor & Francis Group. `,
			s2: `Know your brain: Basal ganglia. Neuroscientifically Challenged. (n.d.). https://neuroscientificallychallenged.com/posts/what-are-basal-ganglia#:~:text=The%20basal%20ganglia%20are%20a,subthalamic%20nucleus%20in%20the%20diencephalon. `,
			s3: `Oakley, B. A., Rogowsky, B., & Sejnowski, T. J. (2021). Uncommon sense teaching: Practical insights in brain science to help students learn. TarcherPerigee, an imprint of Penguin Random House LLC. `,
			s4: `Perera, A. (2023, September 7). Sensory memory in psychology: Definition & examples. Simply Psychology. https://www.simplypsychology.org/sensory-memory.html `
		}
	},
	combinedSystem: {
		article: {
			title: "How information travels in our brain: combining the two systems (Part 3)",
			description: "Learn how to optimize our learning by discovering how the declarative and procedural systems work together.",
			thumbnailAlt: "Logical and creative parts of the brain."
		},
		texts: {
			header1: "Introduction",
			p1: `Welcome to the last part of this series, where <b>we learn how the two systems, namely the declarative and procedural 
				systems, work together and how they compare to each other. The comparisons will include how fast we learn from the two 
				systems, how quickly we can use the information coming from the two systems, and how flexible the information is when 
				it comes to applying what we learned from different situations.</b>`,
			p2: `Now some of us might be wondering, why do we need to learn how the two systems work together and compare from each 
				other? And the answer to that is, <b>depending on what we want to learn, we can determine which system, or systems, would 
				best suit our needs.</b>`,
			p3: `By the way, before we continue, if you haven't already read the two previous articles, I suggest reading them, since 
				this article builds on top of those two.`,
			header2: "Systems working together",
			p4: `Starting with how the two systems work together, some of us might've already noticed how the neocortex and procedural 
				system's goal-oriented portion is where the two systems intersect:`,
			p5: `As we can see in the diagram, <b>both systems eventually store their information in the long-term memory in the neocortex, 
				which allows the other system to access the current system's long-term memory. This is really nice, because it helps reduce 
				the amount of time we need to spend trying to learn the same thing on the other system.</b>`,
			p6: `Take for example learning how to play the piano. If we were to first learn how to play the piano declaratively, or conceptually, 
				we would read about, or watch, proper posture, some playing techniques, the different types of notes, and the different terms 
				of tempo, among other things. After instilling these concepts in our minds, we will notice that, once we touch the piano, 
				since we already have an idea of what is considered good, it's easier for us to monitor and shift our playing according to what 
				we think is good. <b>This is part of the reason why visualization improves our performance.</b>`,
			p7: `Notice that this is possible because we're drawing information from our long-term memory into our working memory and using 
				this information for our goal-oriented activity, in this case learning how to play the piano. Our goal, or intention, is to try 
				to match our playing with what we think is good.`,
			p8: `Conversely, if we are to first learn how to play the piano procedurally, we might not have as good of an idea of how we can 
				play better, but how we're playing the piano would eventually get stored in our long-term memory. From our experience, we eventually 
				develop concepts of how one should play the piano; this would later help create our foundation when we decide to learn things 
				declaratively. Additionally, we can use our declarative system to analyze our current skills to see which areas we can improve. 
				When we've identified what we need to improve, we can again load this idea to our procedural system as we continue to practice 
				playing the piano.`,
			p9: `<b>This back and forth between the two systems is actually the foundation of mastering any skills and gaining expertise in any 
				subject domain. We'll discuss this more in our “becoming-an-expert” module.</b>`,
			header3: "When to use which system",
			p10: `Having gone through these examples in addition to our experience in learning things in school, we might already have an idea 
				of which system we would want to use in which situations. As we go through these situations, as we mentioned before, we'll consider 
				three categories: the speed of learning, the speed of use, and the flexibility of using the system:`,
			p11: `Starting with the speed of learning. <b>Since the declarative system deals with purely conceptual information, it's relatively 
				quick and easy to use, even after being exposed to the information just once. The procedural system, on the other hand, requires 
				a good amount of repetition due partly to its more complex nature of having to deal with information involving movement and intention, 
				which really slows down the rate of learning.</b>`,
			p12: `However, when it comes to the speed of using the system, the table is flipped. <b>Even after the information is deeply held in our 
				long-term memory, there's still usually a small lag time when accessing and retrieving the information, although this can always be 
				improved. But for the majority of cases, this process is fast enough. For the procedural system, once we've learned a skill and have 
				spent some time practicing it, we tend to do things more automatically and faster. With even more practice, we spend even less time 
				thinking about doing it to the point that we sometimes don't even realize that we're doing it, or have done it already, so safe to 
				say that the speed is instantaneous, or near instantaneous.</b>`,
			p13: `And finally for flexibility. For this category, we're mostly concerned about applicability, how easily, or how hard, our previously 
				learned information can be applied to many situations, and updatability, or extensibility, how easy it is to update our understanding 
				with new information. <b>For our declarative system, the information is easier to generalize</b>, compared to the information going through our 
				procedural system, <b>since we're dealing with more abstract information; its conceptual nature allows us to more easily connect different, 
				but related, information together, as soon as we see how the concepts logically relate to one another. This makes our declarative system 
				quite flexible.</b> And again, <b>since our procedural system uses information containing both action and intention, how applicable our learned 
				skills are depends on how we learned and progressed through practice.</b> So, if we're trying to perform a skill that we have an idea of how 
				to do, but we never actually done it, or that we have no idea of how to do something and someone is guiding us along the way, we'll be 
				forced to learn that new skill from near scratch; <b>this makes our procedural system highly inflexible.</b>`,
			header4: "Conclusion",
			p14: `There we have it guys. <b>The declarative and procedural system cooperate via the long-term memory and the goal-oriented portion of the 
				procedural memory. This cooperation between the two helps reduce additional work when we decide to also learn the information using the 
				other system and learning how to work with the information through both systems helps us master the subject and skill.</b> Lastly, depending 
				on what we want to achieve with our learning, we can simply refer to this table for our needs. With that, we've finished the three-part 
				series of how information is relayed through our brain; let's give ourselves a pat on the back because that was definitely a lot of material 
				to go through.`
		},
		sources: {
			s1: `Hattie, J., & Yates, G. C. R. (2014a). Visible learning and the science of how we learn. Routledge, Taylor & Francis Group. `,
			s2: `Oakley, B. A., Rogowsky, B., & Sejnowski, T. J. (2021). Uncommon sense teaching: Practical insights in brain science to help students learn. TarcherPerigee, an imprint of Penguin Random House LLC. `
		}
	},
	efficientStorage: {
		article: {
			title: "How information gets stored in our brain and how we can do it more efficiently",
			description: "Learn our brain's different storage systems and what we can do move information through them more efficiently.",
			thumbnailAlt: "A mural of floppy disks."
		},
		texts: {
			header1: "Introduction",
			p1: `We will now talk about our <b>brain's different memory storages.</b> They are the iconic, sensory, or ultra-short-term 
				memory (I don't know why there are 3 different terms to refer to the same thing, but for simplicity's sake we'll use 
				the <b>sensory memory</b> term), <b>short-term and working memory, and long-term memory. For each memory storage, we'll define 
				what exactly it is, the issues we encounter from using it, and how we can efficiently move the information from the 
				current memory storage to the next.</b>`,
			p2: `By the way, if you've ever learned the different computer memory storages, these three are similar to caches, RAM, 
				and Disk, or an SSD, in terms of what type of information gets stored in them.`,
			p3: `Anyway, why do we even need to learn about the different memory storage? Well, aside from further realizing the 
				importance of reviewing, <b>becoming aware of some of our brain's functions helps us to use it more efficiently, and we 
				can do that by exploring some memory tools and techniques.</b> But before we continue our discussion, how does this topic 
				matter to us personally?`,
			p4: `What about our current way of learning can we do more efficiently, or how can we benefit from finding ways to more 
				efficiently use our brain?`,
			p5: `Personally speaking, especially from my experience back in school, I often had trouble juggling and remembering the 
				many details someone tells me when they're explaining something, so I would often find myself asking that person to repeat 
				themselves, which makes it seem that I'm not paying attention enough. After applying some of the techniques that we'll 
				discuss in this article, I find this issue happening less and less, which is great because I'm able to hold onto the 
				information more efficiently. Hopefully, you also find something useful in this article.`,
			header2: "Sensory memory",
			p6: `So starting with sensory memory. <b><u>Sensory memory</u> holds information from</b>, you guessed it, <b>our senses, and we also only 
				hold the information for a very brief time, usually 3 seconds depending on which sensory input received the information, 
				after the original stimuli have already ended.</b>`,
			p7: `An example of when you're using your sensory memory is when you're reading. If you're an avid reader, you might eventually 
				come to a point where you're easily able to read half a page in less than 15 seconds, maybe even less, and still be able 
				to remember a good chunk of what you read. At this rate, you're probably processing around 10 words / second, assuming half 
				a page is around 150 words. Your ability to read that quickly and still retain some information is partly due to your sensory 
				memory. But, despite this impressive speed, we should note that there is a chance that you might've already forgotten some 
				tiny details. Having noticed this, we can then maybe slow down our speed and pay attention to the details more.`,
			p8: `Herein lies both the issues and the solution to working with our sensory memory. <b>Our sensory memory can only hold information 
				within a very short time and even less if we don't pay enough attention.</b> Now some of us might be thinking, why don't we just 
				try to pay more attention? There's a catch; <b>if we do decide to pay more attention, we become less aware of the other sensory 
				information we're receiving, but we increase our chances of remembering what we're paying attention to. Conversely, if we 
				don't dwell on certain information for a long time, we can be more aware of more information, but we decrease our chances of 
				remembering each information.</b>`,
			header3: "Short-term and working memory",
			p9: `Interestingly, <b>the more attention we place on certain information, the more likely we'll move the information to our working 
				and short-term memory.</b> So starting again with our definitions, <b><u>short-term memory</u> refers to how much information we can hold at 
				a time, while <u>working memory</u> refers to what our brain is doing to the information that's currently in our short-term memory.</b>`,
			p10: `An example of these two in action is if we're to think of a chair, and its color, height, texture, and price. Of these variables 
				that we thought of, such as its color, or texture, we might be reminded of another object, maybe a sofa, having the same color, 
				or texture. The amount of information we can hold, in this case 5 seconds, is our short-term memory's capacity. How our brain 
				associates this chair with a sofa is our working memory doing its thing.`,
			p11: `From the previous example, <b>it's clear that our short-term memory's information capacity and how easily we forget the information 
				stored in our short-term memory are issues. These are the two biggest bottlenecks that make learning and remembering difficult; they 
				prevent us from efficiently persisting information in our long-term memory.</b> Because of its small capacity and how easily we can forget 
				the information, <b>we're forced to keep reviewing the information to maintain it within an active buffer; this active buffer is called 
				the <u>articulatory loop</u> and it's responsible for facilitating the transfer of information to our long-term memory.</b> Adding to this already 
				long delay from having to review the information multiple times, we also need to rest from time to time to allow our brain to consolidate 
				the new information. <b>This is the reason why it takes several days to a few weeks to fully learn and remember new concepts.</b>`,
			p12: `By the way, as a small side note, this process of learning and resting to help solidify concepts in our brain works similarly to exercising 
				and resting to grow muscles.`,
			header4: "Memory devices/tools for efficient storage",
			p13: `Back to the topic. Luckily for us, research shows that there are <b>memory tools that we can use to work through these challenges</b>; 
				these memory tools are more commonly known as mnemonic, or memory devices. <b>These tools allow us to retain and retrieve information 
				more easily. Examples of these include acronyms</b>, where we take the first letter of each word to make another word, the method of loci, 
				also known as memory palaces, where we associate objects to specific locations in an imaginary place, <b>and mental models</b>, which are 
				simplified models of how our complex world works. A mental model's structure is composed of interconnected information that you associate 
				for a given concept.`,
			p14: `These memory tools were used by many ancient civilizations, including the Greeks, for efficiently remembering countless pieces of 
				information, especially stories, and have recently been gaining popularity due to more people participating in memory competitions, which 
				is where people would often quickly memorize several deck cards within a few minutes. Yes, you heard correctly, memorizing several card 
				decks within a few minutes.`,
			p15: `I wouldn't go into the specifics on how the memory tools work, since there are countless good quality articles out there that already 
				talk about this; what I would like to discuss, instead, is why some of the tools work - I say some because how effective the tools are depends 
				on what we're using them for - and how should the tools be used.`,
			p16: `So, why do good memory devices work? <b>They work for two reasons: 1) they help us efficiently chunk information in our short-term memory, 
				and 2) they help us tie new information with old information. If we chunk new information in our short-term memory, we are less likely to 
				forget some details, which allows us to, piece by piece, associate parts of the chunk with established information in our long-term memory. 
				With this, we wouldn't have to review and rest as much to accommodate memory consolidation.</b> This is why memory devices work; they offer an 
				efficient way to handle information that conforms to how our brains work.`,
			p17: `As for how we should handle these tools, it goes back to what they allow us to do; to help associate new information with established 
				information. People typically stop at the point when they've memorized the information. Depending on our needs, this might be fine. But if 
				we're to use the information in the long term, we'll need to go the extra mile and also associate the new information with the old information. 
				And a way of doing this is seeing how the information is similar, or logically connected, to information we already know and why the information 
				makes sense. We'll talk more about the benefits of doing these associations when we talk about long-term memory.`,
			header5: "Long-term memory",
			p18: `Now that we've covered the techniques to efficiently transfer information from our short-term memory to our long-term memory, it's now time 
				to finally talk about the last memory storage, our long-term memory. Our <b><u>long-term memory</u> is essentially a container of information that can last 
				indefinitely, meaning that information can stay there for a few months, years, or maybe our entire lifetime.</b> I do need to point out that there 
				are theories stating that information in our long-term memory is never truly lost; they might just be inaccessible, since some of the neural connections 
				to that information might have weakened, or completely disappeared.`,
			p19: `To that note, this is also <b>the issue with using our long-term memory - we sometimes find it hard to access information that's already stored 
				there. But there is a way to make information more readily accessible, and this is by associating new information with a lot of established information. 
				A good tool for doing this is a mental model.</b> The benefits of using a mental model are the following:`,
			p20: `1.) associating new information with established information leads to bigger information chunks when loading related information into our 
				working memory; this helps us more readily analyze certain topics from multiple perspectives and apply our knowledge in many more different situations.`,
			p21: `2.) because the new information gets connected to well-established information, the chances of forgetting the new information significantly decreases.`,
			header6: "Conclusion",
			p22: `And there we have it. Another lengthy jam-packed learning about our brain's physiology. I commend you for reading through the entire article, because 
				it is mentally exhausting trying to learn all these things in one go. And as always, here is our mental model to summarize what we learned. `,
			p23: `<b>We talked about how information from our sensory organs initially makes its way to our sensory memory, which, with enough attention, can be 
				transferred to our short-term memory. Information from our short-term memory can then be transferred to our long-term memory by reviewing and resting, 
				but the process can be quickened by utilizing memory devices. And finally, our chances of being able to access information, and to do it quickly, from 
				our long-term memory in our working memory can be improved through additional information associations.</b>`
		},
		sources: {
			s1: `Alberini, C. M., & Ledoux, J. E. (n.d.). Memory reconsolidation. PubMed. https://pubmed.ncbi.nlm.nih.gov/24028957/ `,
			s2: `Hattie, J., & Yates, G. C. R. (2014a). Visible learning and the science of how we learn. Routledge, Taylor & Francis Group. `,
			s3: `U.S. Department of Health and Human Services. (2016, March 31). How sleep clears the brain. National Institutes of Health. https://www.nih.gov/news-events/nih-research-matters/how-sleep-clears-brain `
		}
	},
	acquiringAndRefining1: {
		article: {
			title: "How knowledge is acquired and refined (Part 1)",
			description: "Learn how we acquire information subconsciously via implicit learning.",
			thumbnailAlt: "A blacksmith hammering on something."
		},
		texts: {
			header1: "Introduction",
			p1: `We've talked about how information travels around our brain through our declarative and procedural system and which 
				memory storages it goes through during this journey. It's now time to talk about how knowledge is formed along the way. 
				But before we continue any further, let's discuss some terms and definitions to keep in mind and to be on the same page:`,
			header2: "Terms and definitions",
			p2: "Knowledge is knowing a set of information.",
			p3: "Learning is the process of acquiring knowledge, or acquiring a set of information.",
			p4: `Implicit learning is knowledge subconsciously acquired from patterns of information; such patterns are usually regularities 
				within our surroundings.`,
			p5: `Explicit learning is knowledge consciously acquired by interpreting, associating, and retrieving information.`,
			p6: `Now that we've handled that, let's continue with our topic. Knowledge acquired directly from our senses is called sensory 
				recognition, or sense, knowledge.`,
			header3: "Implicit and explicit learning in a nutshell",
			p7: `As to which learning process the information eventually goes to depends on how aware we are of the information. The more 
				aware we are and the more we work with the information, the more likely the information will make its way toward explicit learning; 
				else, it'll make its way toward implicit learning. Knowledge gained from implicit learning is called tacit knowledge. Knowledge 
				gained from explicit learning goes through three levels, depending on how complex that body of knowledge is; the three forms 
				are strings, ideas, and schemas.`,
			p8: `For all of these types of knowledge, we'll define what it is, provide an example of it, and how that knowledge is acquired.`,
			header4: "Why all of these really matter",
			p9: `But before we fully dive into the details, let's see why this topic is relevant to us; why should we care about how knowledge 
				is acquired and refined?`,
			p10: `Well, understanding this has many benefits, but there is one big important reason. How we learn has implications on what we 
				learn, which implies what kind of person we can become and how we interact with the world.`,
			header5: "Sensory recognition knowledge",
			p11: `So starting from how we even receive information from our environment through our sensory organs, our ability to recognize 
				what's happening around us, via hearing, seeing, touching, tasting, and smelling, and to discern the source's distance from us, 
				is known as sensory recognition knowledge.`,
			p12: `Examples of this knowledge include knowing that you're hearing a person's voice, looking at a tv, touching a chair, eating a 
				strawberry, and smelling food.`,
			p13: `This knowledge is acquired by recognizing the possible outcome depending on the sensory input. In the example of hearing a 
				person's voice, the sound of the voice is our sensory input through our ears, and our learning that it is indeed a sound coming 
				from a person confirms the association between the two, the sensory input, which is the voice, and the outcome, which is the person 
				saying something. So the next time we hear a sound similar to a person's voice, we'll know that the sound came from a person. 
				Recognizing how the two, the sensory input and the source, are associated with each other is how we acquire sensory recognition 
				knowledge.`,
			header6: "What determines where the information goes",
			p14: `As mentioned before, which path our sensory recognition knowledge goes depends on how much we work with the information. The 
				more aware we are and the more we work with the information, the more likely the information will make its way toward explicit 
				learning, since we're explicitly, or clearly, working with the information in our working memory. The less aware we are of the 
				information, the more likely it'll reach our implicit learning.`,
			p15: `We should note that the type of information plays a part in deciding whether the information would go to implicit or explicit 
				learning. There are two types of information we encounter when learning anything: biologically primary material and biologically 
				secondary material.`,
			p16: `We can think of biologically primary material as information material that is more closely associated with our senses, such as 
				recognizing faces and learning our first language. Biologically secondary material deals more with information that we can think of 
				conceptually, such as understanding the logic behind solving an equation, or trying to make sense of the concepts behind calculus 
				when you're first learning it.`,
			p17: `As we can see, working with biologically primary material is often easier, and more natural, than biologically secondary material. 
				And because we learn them more naturally, we tend to dwell less on these types of information in our working memory, but we still 
				eventually store the information in our long-term memory. If we're to quickly recall what we learned in the first three topics of 
				this module when we were talking about how information goes through the declarative and procedural systems, this process of learning 
				biologically primary material closely resembles the use of our procedural system; this is why this type of information often makes 
				its way through implicit learning.`,
			p18: `Conversely, our tendency to dwell and review more conceptual information is similar to how we're usually using our working memory 
				and hippocampus in our declarative system, when we're learning something new; this is why this type of information often goes through 
				explicit learning.`,
			p19: `And finally, just because the information has made its way to one learning path over the other doesn't mean that it's completely 
				inaccessible from the other learning system. As we discussed, similar to how the procedural and declarative systems can work together 
				using long-term memory, both learning systems also cooperate using long-term memory residing in our neocortex.`,
			header7: "Acquiring tacit knowledge through repetition",
			p20: `Now that we've covered the factors that determine if our sensory recognition knowledge goes to either implicit learning or explicit 
				learning, let's now talk about how we acquire tacit knowledge and the levels of explicit knowledge.`,
			p21: `Tacit knowledge is usually defined as knowledge that is often difficult to conceptualize, and thus difficult to express and verbalize.`,
			p22: `Examples of tacit knowledge are knowing how to ride a bike or playing the piano; the wisdom and insight we form over time is also 
				a form of tacit knowledge.`,
			p23: `Tacit knowledge is acquired through experience, specifically by: 1.) continually practicing some activity or 2.) being exposed to 
				regularly structured events.`,
			p24: `Going back to our example of playing the piano, years of practice would eventually make the many small details of playing the piano, 
				such as your posture, tempo, how loud you play the keys, and techniques, more automatic, because we've gotten really good at it. Over 
				time, because of its progressively automatic nature, we tend to eventually forget these small details, even though we're still able to 
				do the activity. This is the biggest reason why it's difficult to conceptualize and thus express this knowledge. As a side note, this is 
				part of the reason why experts struggle to effectively teach beginners, because they've already forgotten the many struggles that beginners 
				go through when they're first learning the skills and/or concepts.`,
			header8: "Acquiring tacit knowledge from our environment",
			p25: `Let's now address how one gains tacit knowledge by being exposed to regularly structured events; we'll use the example of our insight 
				into the world. Let's say that we're just a kid living in a relatively poor, possibly violent, neighborhood and that we've been living 
				there since we were born, having little to no exposure to life outside the neighborhood. Because of our lack of awareness of life outside 
				our neighborhood and that we're regularly exposed to factors that make this neighborhood poor, we'll eventually learn and internalize certain 
				behaviors and mentality that would allow us to live and survive in such a neighborhood. The things happening in the neighborhood and their 
				effects on us are the structured events that lead us to develop certain behaviors and mentality. This internalization of our behavior and 
				mentality is a form of tacit knowledge; it's difficult to express why we behave and think the way we do, because we've been regularly going 
				through these motions living in our neighborhood.`,
			p26: `This goes back to why learning how we learn is important; how we learn has implications on what we learn, and therefore implications on 
				what we might eventually become as a person. Implicit learning happens from what we observe in our surroundings and the people we interact 
				with; it happens whether we want to or not; it's always running in the back of our minds. One way to control what we learn through implicit 
				learning is to be more aware of it, to pay attention, which is what we'll discuss next with explicit learning in part 2 of this article.`
		},
		sources: {
			s1: `Hattie, J., & Yates, G. C. R. (2014a). Visible learning and the science of how we learn. Routledge, Taylor & Francis Group. `
		}
	}
};