/* eslint-disable no-template-curly-in-string */
import {VIDEO_FEEDBACK_VO} from "apis/models/Videos";

import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const BASE = "/services/video/comment";

const COMMENT_LIST_GET = BASE + "/list/${videoId}";
const COMMENT_POST = BASE + "/post";

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 */
export function commentsAPI(pathVariables, callback) {
	const api = $replace(COMMENT_LIST_GET, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} payload = VIDEO_FEEDBACK_VO
 */
export function postCommentAPI(payload, callback) {
	modelValidator(VIDEO_FEEDBACK_VO, payload) && request(COMMENT_POST, POST, payload, callback);
}