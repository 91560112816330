import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/thumbnailMisconception.jpg";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";


export const mentalityAndAttitudeMisconceptionArticle = {
	id: "II. Mentality & Attitude_1_0 Common misconceptions about learning and how learning actually works",
	title: messageMap("misconception.article.title", "learningOptimization"),
	description: messageMap("misconception.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("misconception.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4", "text5", "text6",
		"text7", "text8", "text9", "text10",
		"text11", "text12",
		"text13", "text14", "text15",
		"text16", "text17",
		"text18", "text19", "text20",
		"text21", "text22", "text23", "text24", "text25",
		"text26", "text27",
		"text28", "text29",
		"text30", "text31",
		"text32", "text33", "text34", "text35", "text36"
	],
	datePublished: "2023-12-16",
	dateModified: "2024-06-21"
};

export const mentalityAndAttitudeMisconceptionArticleTexts = {
	text1: {
		text: messageMap("misconception.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("misconception.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("misconception.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("misconception.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("misconception.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("misconception.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},


	text7: {
		text: messageMap("misconception.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text8: {
		text: messageMap("misconception.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("misconception.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("misconception.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("misconception.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text12: {
		text: messageMap("misconception.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("misconception.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text14: {
		text: messageMap("misconception.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("misconception.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("misconception.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text17: {
		text: messageMap("misconception.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("misconception.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text19: {
		text: messageMap("misconception.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("misconception.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("misconception.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text22: {
		text: messageMap("misconception.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text23: {
		text: messageMap("misconception.texts.p16", "learningOptimization"),
		type: DESCRIPTION
	},
	text24: {
		text: messageMap("misconception.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text25: {
		text: messageMap("misconception.texts.p18", "learningOptimization"),
		type: DESCRIPTION
	},
	text26: {
		text: messageMap("misconception.texts.header8", "learningOptimization"),
		type: SUB_HEADER
	},
	text27: {
		text: messageMap("misconception.texts.p19", "learningOptimization"),
		type: DESCRIPTION
	},
	text28: {
		text: messageMap("misconception.texts.header9", "learningOptimization"),
		type: SUB_HEADER
	},
	text29: {
		text: messageMap("misconception.texts.p20", "learningOptimization"),
		type: DESCRIPTION
	},
	text30: {
		text: messageMap("misconception.texts.header10", "learningOptimization"),
		type: SUB_HEADER
	},
	text31: {
		text: messageMap("misconception.texts.p21", "learningOptimization"),
		type: DESCRIPTION
	},
	text32: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text33: {
		text: messageMap("misconception.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text34: {
		text: messageMap("misconception.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text35: {
		text: messageMap("misconception.sources.s3", "learningOptimization"),
		type: SOURCE
	},
	text36: {
		text: messageMap("misconception.sources.s4", "learningOptimization"),
		type: SOURCE
	}
};


