import messageMap from "Utilities/MessageMaps";

import informationAsset from "assets/icons/video/guidelines/information.svg";
import mentalModelAsset from "assets/images/video/guidelines/mental_model.png";
import presentationAsset from "assets/icons/video/guidelines/presentation.svg";
import viewingAsset from "assets/icons/video/guidelines/viewing.svg";
import accessibilityAsset from "assets/icons/video/guidelines/accessibility.svg";
import miscellaneousAsset from "assets/icons/video/guidelines/miscellaneous.svg";

export function videoGuidelinesSchema(refList) {
	return {
		information: {
			title: messageMap("video.uploadPage.guidelines.information.title", "generic"),
			img: informationAsset,
			alt: messageMap("information", "image"),
			detailsDomRef: refList.information.detailsDomRef,
			chevronRef: refList.information.chevRef,
			body: [
				{
					header: messageMap("uploadVideo.information.gathering.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.information.gathering.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.gathering.subheaderA.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.gathering.subheaderA.point1.point1.text", "document")}`
										}, {
											text: `b. ${messageMap("uploadVideo.information.gathering.subheaderA.point1.point2.text", "document")}`
										}
									]
								}, {
									text: `2. ${messageMap("uploadVideo.information.gathering.subheaderA.point2.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.gathering.subheaderA.point2.point1.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.information.gathering.subheaderA.point2.point1.point1.text", "document")}`,
													details: [
														{
															text: `${messageMap("uploadVideo.information.gathering.subheaderA.point2.point1.point1.point1.text", "document")}`
														}
													]
												}, {
													text: `ii. ${messageMap("uploadVideo.information.gathering.subheaderA.point2.point1.point2.text", "document")}`,
													details: [
														{
															text: `${messageMap("uploadVideo.information.gathering.subheaderA.point2.point1.point2.point1.text", "document")}`
														}
													]
												}
											]
										}
									]
								}
							]
						}, {
							text: `B. ${messageMap("uploadVideo.information.gathering.subheaderB.text", "document")}`,
							details: [{
									text: `1. ${messageMap("uploadVideo.information.gathering.subheaderB.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.gathering.subheaderB.point1.point1.text", "document")}`
										}
									]
								}, {
									text: `2. ${messageMap("uploadVideo.information.gathering.subheaderB.point2.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.gathering.subheaderB.point2.point1.text", "document")}`
										}, {
											text: `b. ${messageMap("uploadVideo.information.gathering.subheaderB.point2.point2.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `C. ${messageMap("uploadVideo.information.gathering.subheaderC.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point1.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point1.point1.text", "document")}`
												}, {
													text: `ii. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point1.point2.text", "document")}`,
													details: [
														{
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point1.point2.point1.text", "document")}`
														}, {
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point1.point2.point2.text", "document")}`
														}
													]
												}
											]
										}, {
											text: `b. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point2.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point2.point1.text", "document")}`
												}, {
													text: `ii. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point2.point2.text", "document")}`,
													details: [
														{
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point2.point2.point1.text", "document")}`
														}, {
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point2.point2.point2.text", "document")}`
														}, {
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point2.point2.point3.text", "document")}`
														}
													]
												}
											]
										}, {
											text: `c. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point3.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point3.point1.text", "document")}`
												}, {
													text: `ii. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point3.point2.text", "document")}`,
													details: [
														{
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point3.point2.point1.text", "document")}`
														}, {
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point3.point2.point2.text", "document")}`
														}, {
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point3.point2.point3.text", "document")}`
														}, {
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point3.point2.point4.text", "document")}`
														}, {
															text: `${messageMap("uploadVideo.information.gathering.subheaderC.point1.point3.point2.point5.text", "document")}`
														}
													]
												}
											]
										}, {
											text: `d. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point4.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point4.point1.text", "document")}`
												}, {
													text: `ii. ${messageMap("uploadVideo.information.gathering.subheaderC.point1.point4.point2.text", "document")}`
												}
											]
										}
									]
								}, {
									text: `2. ${messageMap("uploadVideo.information.gathering.subheaderC.point2.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.gathering.subheaderC.point2.point1.text", "document")}`
										}, {
											text: `b. ${messageMap("uploadVideo.information.gathering.subheaderC.point2.point2.text", "document")}`
										}, {
											text: `c. ${messageMap("uploadVideo.information.gathering.subheaderC.point2.point3.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.information.gathering.subheaderC.point2.point3.point1.text", "document")}`
												}, {
													text: `ii. ${messageMap("uploadVideo.information.gathering.subheaderC.point2.point3.point2.text", "document")}`
												}
											]
										}, {
											text: `d. ${messageMap("uploadVideo.information.gathering.subheaderC.point2.point4.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.information.gathering.subheaderC.point2.point4.point1.text", "document")}`,
													img: mentalModelAsset,
													alt: `${messageMap("mentalModelExample", "image")}`
												}
											]
										}
									]
								}
							]
						}
					]
				}, {
					header: messageMap("uploadVideo.information.writing.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.information.writing.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.writing.subheaderA.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderA.point1.point1.text", "document")}`
										}
									]
								}, {
									text: `2. ${messageMap("uploadVideo.information.writing.subheaderA.point2.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderA.point2.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `B. ${messageMap("uploadVideo.information.writing.subheaderB.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.writing.subheaderB.point1.text", "document")}`
								}, {
									text: `2. ${messageMap("uploadVideo.information.writing.subheaderB.point2.text", "document")}`
								}, {
									text: `3. ${messageMap("uploadVideo.information.writing.subheaderB.point3.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderB.point3.point1.text", "document")}`
										}
									]
								}, {
									text: `4. ${messageMap("uploadVideo.information.writing.subheaderB.point4.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderB.point4.point1.text", "document")}`
										}
									]
								}, {
									text: `5. ${messageMap("uploadVideo.information.writing.subheaderB.point5.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderB.point5.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `C. ${messageMap("uploadVideo.information.writing.subheaderC.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.writing.subheaderC.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderC.point1.point1.text", "document")}`
										}, {
											text: `b. ${messageMap("uploadVideo.information.writing.subheaderC.point1.point2.text", "document")}`
										}
									]
								}, {
									text: `2. ${messageMap("uploadVideo.information.writing.subheaderC.point2.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderC.point2.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `D. ${messageMap("uploadVideo.information.writing.subheaderD.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.writing.subheaderD.point1.text", "document")}`
								}, {
									text: `2.	${messageMap("uploadVideo.information.writing.subheaderD.point2.text", "document")}`
								}
							]
						}, {
							text: `E. ${messageMap("uploadVideo.information.writing.subheaderE.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.writing.subheaderE.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderE.point1.point1.text", "document")}`
										}, {
											text: `b. ${messageMap("uploadVideo.information.writing.subheaderE.point1.point2.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `F. ${messageMap("uploadVideo.information.writing.subheaderF.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.writing.subheaderF.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderF.point1.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `G. ${messageMap("uploadVideo.information.writing.subheaderG.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.writing.subheaderG.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderG.point1.point1.text", "document")}`
										}
									]
								}, {
									text: `2. ${messageMap("uploadVideo.information.writing.subheaderG.point2.text", "document")}`
								}, {
									text: `3. ${messageMap("uploadVideo.information.writing.subheaderG.point3.text", "document")}`
								}, {
									text: `4. ${messageMap("uploadVideo.information.writing.subheaderG.point4.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderG.point4.point1.text", "document")}`
										}, {
											text: `b. ${messageMap("uploadVideo.information.writing.subheaderG.point4.point2.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `H. ${messageMap("uploadVideo.information.writing.subheaderH.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.information.writing.subheaderH.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.information.writing.subheaderH.point1.point1.text", "document")}`
										}
									]
								}
							]
						}
					]
				}
			]
		},
		delivery: {
			title: messageMap("video.uploadPage.guidelines.delivery.title", "generic"),
			img: presentationAsset,
			alt: messageMap("presentation", "image"),
			detailsDomRef: refList.delivery.detailsDomRef,
			chevronRef: refList.delivery.chevRef,
			body: [
				{
					header: messageMap("uploadVideo.delivery.connecting.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.delivery.connecting.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.connecting.subheaderA.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.connecting.subheaderA.point1.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `B. ${messageMap("uploadVideo.delivery.connecting.subheaderB.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.connecting.subheaderB.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.connecting.subheaderB.point1.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `C. ${messageMap("uploadVideo.delivery.connecting.subheaderC.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.connecting.subheaderC.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.connecting.subheaderC.point1.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `D. ${messageMap("uploadVideo.delivery.connecting.subheaderD.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.connecting.subheaderD.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.connecting.subheaderD.point1.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `E. ${messageMap("uploadVideo.delivery.connecting.subheaderE.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.connecting.subheaderE.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.connecting.subheaderE.point1.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `F. ${messageMap("uploadVideo.delivery.connecting.subheaderF.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.connecting.subheaderF.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.connecting.subheaderF.point1.point1.text", "document")}`
										}
									]
								}
							]
						}
					]
				}, {
					header: messageMap("uploadVideo.delivery.production.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.delivery.production.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.production.subheaderA.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.production.subheaderA.point1.point1.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.delivery.production.subheaderA.point1.point1.point1.text", "document")}`
												}, {
													text: `ii. ${messageMap("uploadVideo.delivery.production.subheaderA.point1.point1.point2.text", "document")}`
												}
											]
										}, {
											text: `b. ${messageMap("uploadVideo.delivery.production.subheaderA.point1.point2.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.delivery.production.subheaderA.point1.point2.point1.text", "document")}`,
													details: [
														{
															text: `${messageMap("uploadVideo.delivery.production.subheaderA.point1.point2.point1.point1.text", "document")}`
														}
													]
												}
											]
										}
									]
								}, {
									text: `2. ${messageMap("uploadVideo.delivery.production.subheaderA.point2.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.production.subheaderA.point2.point1.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.delivery.production.subheaderA.point2.point1.point1.text", "document")}`
												}
											]
										}, {
											text: `b. ${messageMap("uploadVideo.delivery.production.subheaderA.point2.point2.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.delivery.production.subheaderA.point2.point2.point1.text", "document")}`
												}
											]
										}
									]
								}
							]
						}, {
							text: `B. ${messageMap("uploadVideo.delivery.production.subheaderB.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.production.subheaderB.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.production.subheaderB.point1.point1.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.delivery.production.subheaderB.point1.point1.point1.text", "document")}`,
													details: [
														{
															text: `${messageMap("uploadVideo.delivery.production.subheaderB.point1.point1.point1.point1.text", "document")}`
														}
													]
												}
											]
										}, {
											text: `b. ${messageMap("uploadVideo.delivery.production.subheaderB.point1.point2.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.delivery.production.subheaderB.point1.point2.point1.text", "document")}`,
													details: [
														{
															text: `${messageMap("uploadVideo.delivery.production.subheaderB.point1.point2.point1.point1.text", "document")}`
														}
													]
												}
											]
										}
									]
								}
							]
						}, {
							text: `C. ${messageMap("uploadVideo.delivery.production.subheaderC.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.production.subheaderC.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.production.subheaderC.point1.point1.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.delivery.production.subheaderC.point1.point1.point1.text", "document")}`
												}
											]
										}
									]
								}
							]
						}, {
							text: `D. ${messageMap("uploadVideo.delivery.production.subheaderD.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.production.subheaderD.point1.text", "document")}`
								}
							]
						}, {
							text: `E. ${messageMap("uploadVideo.delivery.production.subheaderE.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.delivery.production.subheaderE.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.delivery.production.subheaderE.point1.point1.text", "document")}`,
											details: [
												{
													text: `i. ${messageMap("uploadVideo.delivery.production.subheaderE.point1.point1.point1.text", "document")}`
												}
											]
										}
									]
								}
							]
						}
					]
				}
			]
		},
		attraction: {
			title: messageMap("video.uploadPage.guidelines.viewers.title", "generic"),
			img: viewingAsset,
			alt: messageMap("viewers", "image"),
			detailsDomRef: refList.attraction.detailsDomRef,
			chevronRef: refList.attraction.chevRef,
			body: [
				{
					header: messageMap("uploadVideo.viewers.title.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.viewers.title.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.viewers.title.subheaderA.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.viewers.title.subheaderA.point1.point1.text", "document")}`
										}
									]
								}
							]
						}, {
							text: `B. ${messageMap("uploadVideo.viewers.title.subheaderB.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.viewers.title.subheaderB.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.viewers.title.subheaderB.point1.point1.text", "document")}`
										}
									]
								}
							]
						}
					]
				}, {
					header: messageMap("uploadVideo.viewers.subheading.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.viewers.subheading.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.viewers.subheading.subheaderA.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.viewers.subheading.subheaderA.point1.point1.text", "document")}`
										}
									]
								}
							]
						}
					]
				}, {
					header: messageMap("uploadVideo.viewers.thumbnail.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.viewers.thumbnail.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.viewers.thumbnail.subheaderA.point1.text", "document")}`
								}, {
									text: `2. ${messageMap("uploadVideo.viewers.thumbnail.subheaderA.point2.text", "document")}`
								}, {
									text: `3. ${messageMap("uploadVideo.viewers.thumbnail.subheaderA.point3.text", "document")}`
								}
							]
						}, {
							text: `B. ${messageMap("uploadVideo.viewers.thumbnail.subheaderB.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.viewers.thumbnail.subheaderB.point1.text", "document")}`
								}, {
									text: `2. ${messageMap("uploadVideo.viewers.thumbnail.subheaderB.point2.text", "document")}`
								}, {
									text: `3. ${messageMap("uploadVideo.viewers.thumbnail.subheaderB.point3.text", "document")}`
								}, {
									text: `4. ${messageMap("uploadVideo.viewers.thumbnail.subheaderB.point4.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.viewers.thumbnail.subheaderB.point4.point1.text", "document")}`
										}
									]
								}, {
									text: `5. ${messageMap("uploadVideo.viewers.thumbnail.subheaderB.point5.text", "document")}`,
									details: [
										{
											text: `a.	${messageMap("uploadVideo.viewers.thumbnail.subheaderB.point5.point1.text", "document")}`
										}
									]
								}
							]
						}
					]
				}
			]
		},
		accessibility: {
			title: messageMap("video.uploadPage.guidelines.accessibility.title", "generic"),
			img: accessibilityAsset,
			alt: messageMap("accessibility", "image"),
			detailsDomRef: refList.accessibility.detailsDomRef,
			chevronRef: refList.accessibility.chevRef,
			body: [
				{
					header: messageMap("uploadVideo.accessibility.subtitles.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.accessibility.subtitles.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.accessibility.subtitles.subheaderA.point1.text", "document")}`
								}, {
									// TODO: update customer contact
									text: `2. ${messageMap("uploadVideo.accessibility.subtitles.subheaderA.point2.text", "document")}`
								}
							]
						}
					]
				}, {
					// Place the burden of video and audio transcoding on users. Once we find a way to do this ourselves
					// in a profitable/break-even way, we should re-consider this instruction.
					// Update description to include instructions for transcoding video and audio files on several Ps (240p, 360p, 480p, 720P, 1080p). Incentive is that uploaders can have
					// wider reach, which means higher views, which means higher revenue from ADs/paid users
					header: messageMap("uploadVideo.accessibility.transcoding.title", "document"),
					details: [
						{
							text: `A.	${messageMap("uploadVideo.accessibility.transcoding.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.accessibility.transcoding.subheaderA.point1.text", "document")}`,
									details: [
										{
											text: `a. ${messageMap("uploadVideo.accessibility.transcoding.subheaderA.point1.point1.text", "document")}`
										}, {
											text: `b. ${messageMap("uploadVideo.accessibility.transcoding.subheaderA.point1.point2.text", "document")}`
										}
									]
								}, {
									text: `2. ${messageMap("uploadVideo.accessibility.transcoding.subheaderB.text", "document")}`,
									details: [
										{
											text: "a. .mp4"
										}
									]
								}, {
									text: `3. ${messageMap("uploadVideo.accessibility.transcoding.subheaderC.text", "document")}`,
									details: [
										{
											text: "a. H.264 (AVC)"
										}, {
											text: "b. VP9"
										}, {
											text: "c. AV1"
										}
									]
								}
							]
						}
					]
				}, {
					header: messageMap("uploadVideo.accessibility.audioTranscoding.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.accessibility.audioTranscoding.subheaderA.text", "document")}`
						}
					]
				}
			]
		},
		miscellaneous: {
			title: messageMap("video.uploadPage.guidelines.miscellaneous.title", "generic"),
			img: miscellaneousAsset,
			alt: messageMap("miscellaneous", "image"),
			detailsDomRef: refList.miscellaneous.detailsDomRef,
			chevronRef: refList.miscellaneous.chevRef,
			body: [
				{
					header: messageMap("uploadVideo.miscellaneous.accuracy.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.miscellaneous.accuracy.subheaderA.text", "document")}`
						}, {
							text: `B. ${messageMap("uploadVideo.miscellaneous.accuracy.subheaderB.text", "document")}`
						}, {
							text: `C. ${messageMap("uploadVideo.miscellaneous.accuracy.subheaderC.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.miscellaneous.accuracy.subheaderC.point1.text", "document")}`
								}, {
									text: `2. ${messageMap("uploadVideo.miscellaneous.accuracy.subheaderC.point2.text", "document")}`
								}
							]
						}, {
							text: `D. ${messageMap("uploadVideo.miscellaneous.accuracy.subheaderD.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.miscellaneous.accuracy.subheaderD.point1.text", "document")}`
								}, {
									text: `2. ${messageMap("uploadVideo.miscellaneous.accuracy.subheaderD.point2.text", "document")}`
								}
							]
						}
					]
				}, {
					header: messageMap("uploadVideo.miscellaneous.analogies.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.miscellaneous.analogies.subheaderA.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.miscellaneous.analogies.subheaderA.point1.text", "document")}`
								}
							]
						}
					]
				}, {
					header: messageMap("uploadVideo.miscellaneous.teachingMethod.title", "document"),
					details: [
						{
							text: `A. ${messageMap("uploadVideo.miscellaneous.teachingMethod.subheaderA.text", "document")}`,
							details: [
								{
									text: `1.	${messageMap("uploadVideo.miscellaneous.teachingMethod.subheaderA.point1.text", "document")}`
								}, {
									text: `2.	${messageMap("uploadVideo.miscellaneous.teachingMethod.subheaderA.point2.text", "document")}`
								}
							]
						}, {
							text: `B. ${messageMap("uploadVideo.miscellaneous.teachingMethod.subheaderB.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.miscellaneous.teachingMethod.subheaderB.point1.text", "document")}`
								}
							]
						}, {
							text: `C. ${messageMap("uploadVideo.miscellaneous.teachingMethod.subheaderC.text", "document")}`,
							details: [
								{
									text: `1. ${messageMap("uploadVideo.miscellaneous.teachingMethod.subheaderC.point1.text", "document")}`
								}
							]
						}
					]
				}
			]
		}
	};
}

export const tableOfContentsSchema = {
	"information": "Information",
	"delivery": "Delivery",
	"attracting": "Attracting Viewers",
	"accessibility": "Accessibility",
	"miscellaneous": "Miscellaneous",
	"content": "Uploaded Content"
}

export function tableOfContentsChevRefSchema(infoChevRef, deliveryChevRef, attractionChevRef, accessibilityChevRef, miscChevRef, createContentRef) {
	return {
		"information": infoChevRef,
		"delivery": deliveryChevRef,
		"attracting": attractionChevRef,
		"accessibility": accessibilityChevRef,
		"miscellaneous": miscChevRef,
		"content": createContentRef
	};
}