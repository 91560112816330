import React, { useState, useEffect, useRef, Fragment }  from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import TabbedPages from "templates/TabbedPages";

import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";
import {
	TUTORING_SESSIONS_TABS,
	SESSIONS_UPCOMING_SESSIONS, SESSIONS_LIVE_SESSIONS, SESSIONS_PAST_SESSIONS
} from "templates/utilities/PageTabs";
import { 
	TUTEE_USER, TUTOR_USER,
	SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE,
	stateToStateLabel
} from "pages/Tutoring/utilities/TutoringRequestConstants";
import { DEFAULT_TYPE, TUTOR_TYPE } from "pages/Profile/subPages/utilities/AccountUtility";
import RequestList from "pages/Tutoring/subPages/subMenus/RequestList";

import { getUserTypeAPI } from "apis/controllers/person/AccountsController";


function TutorSessions(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");
	const identityIsValid = isValidIdentity(ownerId);
	const history = useHistory();

	const [signUpFirstVisible, setSignUpFirstVisible] = useState(false);
	const [tabbedPagesDom, setTabbedPagesDom] = useState(null);
	const [tutorProfilePane, setTutorProfilePane] = useState();
	const [toggableUser, setToggableUser] = useState(false),
		[userLabel, setUserLabel] = useState(),
		[chosenSubPage, setChosenSubPage] = useState();

	const [defaultScheduledSessions, setDefaultScheduledSessions] = useState(),
		[defaultLiveSessions, setDefaultLiveSessions] = useState(),
		[defaultCompletedSessions, setDefaultCompletedSessions] = useState(),
		[tutorScheduledSessions, setTutorScheduledSessions] = useState(),
		[tutorLiveSessions, setTutorLiveSessions] = useState(),
		[tutorCompletedSessions, setTutorCompletedSessions] = useState(),
		[emptyDefaultScheduledSessions, setEmptyDefaultScheduledSessions] = useState(),
		[emptyDefaultLiveSessions, setEmptyDefaultLiveSessions] = useState(),
		[emptyDefaultCompletedSessions, setEmptyDefaultCompletedSessions] = useState(),
		[emptyTutorScheduledSessions, setEmptyTutorScheduledSessions] = useState(),
		[emptyTutorLiveSessions, setEmptyTutorLiveSessions] = useState(),
		[emptyTutorCompletedSessions, setEmptyTutorCompletedSessions] = useState();

	const sessionSetTypeRef = useRef(DEFAULT_TYPE);
	const upcomingSessionRef1 = useRef(),
		liveSessionsRef1 = useRef(),
		pastSessionsRef1 = useRef();
	const upcomingSessionRef2 = useRef(),
		liveSessionsRef2 = useRef(),
		pastSessionsRef2 = useRef();
	const emptyListsRef = useRef({});

	useEffect(() => {
		renderTabbedPages();
		navigateToSubMenu();
	}, []);

	useEffect(() => {
		getUserType();

		const viewTypeToNextSessionTypeMap = {
			[TUTEE_USER]: [SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE],
			[TUTOR_USER]: [SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE]
		};
		for (const [key, value] of Object.entries(viewTypeToNextSessionTypeMap)) {
			value.forEach(val => {
				showLiveAndCompletedSessions(key, val);
			});
		}
	}, [props.ownerId]);

	function navigateToSubMenu() {
		const urlLocation = window.location.search;
		if (urlLocation.includes("show")) {
			const urlParams = new URLSearchParams(urlLocation);

			if (urlParams.get("sub") != null) {
				showSubMenuPage(urlParams.get("sub"));
			}
			else if (urlParams.get("show") != null) {
				const subPage = urlParams.get("show") === "sessions" && SESSIONS_UPCOMING_SESSIONS;
				showSubMenuPage(subPage);
			}
		}
	}

	function renderTabbedPages(preselectedTab) {
		setTabbedPagesDom(
			<TabbedPages key={preselectedTab} tabType={TUTORING_SESSIONS_TABS} tabClickHandler={showSubMenuPage} />
		);
	}

	function showSubMenuPage(chosenMenuPage) {
		const refMap = {
			[TUTOR_TYPE]: {
				[SESSIONS_UPCOMING_SESSIONS]: upcomingSessionRef1,
				[SESSIONS_LIVE_SESSIONS]: liveSessionsRef1,
				[SESSIONS_PAST_SESSIONS]: pastSessionsRef1
			},
			[DEFAULT_TYPE]: {
				[SESSIONS_UPCOMING_SESSIONS]: upcomingSessionRef2,
				[SESSIONS_LIVE_SESSIONS]: liveSessionsRef2,
				[SESSIONS_PAST_SESSIONS]: pastSessionsRef2
			}
		};

		refMap[sessionSetTypeRef.current][SESSIONS_UPCOMING_SESSIONS].current.className = "upcoming-sessions hide";
		refMap[sessionSetTypeRef.current][SESSIONS_LIVE_SESSIONS].current.className = "live-sessions hide";
		refMap[sessionSetTypeRef.current][SESSIONS_PAST_SESSIONS].current.className = "past-sessions hide";
		if (chosenMenuPage === SESSIONS_UPCOMING_SESSIONS) {
			refMap[sessionSetTypeRef.current][SESSIONS_UPCOMING_SESSIONS].current.className = "upcoming-sessions";
			history.push(`/tutoring?show=sessions&sub=${SESSIONS_UPCOMING_SESSIONS}`, { shallow: true });
		}
		else if (chosenMenuPage === SESSIONS_LIVE_SESSIONS) {
			refMap[sessionSetTypeRef.current][SESSIONS_LIVE_SESSIONS].current.className = "live-sessions";
			history.push(`/tutoring?show=sessions&sub=${SESSIONS_LIVE_SESSIONS}`, { shallow: true });
		}
		else if (chosenMenuPage === SESSIONS_PAST_SESSIONS) {
			refMap[sessionSetTypeRef.current][SESSIONS_PAST_SESSIONS].current.className = "past-sessions";
			history.push(`/tutoring?show=sessions&sub=${SESSIONS_PAST_SESSIONS}`, { shallow: true });
		}

		setChosenSubPage(chosenMenuPage);
	}

	function getUserType() {
		if (identityIsValid) {
			getUserTypeAPI(ownerId, resp => {
				if (resp.length === 2) {
					sessionSetTypeRef.current = TUTOR_TYPE;
					setToggableUser(true);
					setUserLabel(messageMap("tutoringPage.request.userType.tutor", "generic"));
				}
				else {
					sessionSetTypeRef.current = DEFAULT_TYPE;
				}
			});
		}
		else {
			setSignUpFirstVisible(true);
		}
	}

	function changeUserMode() {
		if (sessionSetTypeRef.current === TUTOR_TYPE) {
			sessionSetTypeRef.current = DEFAULT_TYPE;
			setUserLabel(messageMap("tutoringPage.request.userType.default", "generic"));
		}
		else {
			sessionSetTypeRef.current = TUTOR_TYPE;
			setUserLabel(messageMap("tutoringPage.request.userType.tutor", "generic"));
		}

		showSubMenuPage(chosenSubPage);
	}

	function indicateEmptyList(sentOrReceived, listLength, listType, intendedUser, hideOrShow = "hide") {
		const emptyMessagesMap = {
			[TUTOR_USER]: {
				[SCHEDULED_STATE]: setEmptyTutorScheduledSessions,
				[LIVE_STATE]: setEmptyTutorLiveSessions,
				[COMPLETED_STATE]: setEmptyTutorCompletedSessions
			},
			[TUTEE_USER]: {
				[SCHEDULED_STATE]: setEmptyDefaultScheduledSessions,
				[LIVE_STATE]: setEmptyDefaultLiveSessions,
				[COMPLETED_STATE]: setEmptyDefaultCompletedSessions
			}
		};
		const followingStatementMap = {
			[SCHEDULED_STATE]: messageMap("tutoringPage.session.sessions2a", "generic"),
			[LIVE_STATE]: messageMap("tutoringPage.session.sessions2b", "generic"),
			[COMPLETED_STATE]: messageMap("tutoringPage.session.sessions2c", "generic")
		};

		if (hideOrShow === "hide") {
			const emptyClassName = listType === COMPLETED_STATE ? "empty-list-container half-page" : "empty-list-container";
			emptyMessagesMap[intendedUser][listType](
				<div className={emptyClassName}>
					{`${messageMap("tutoringPage.session.no", "generic")} ${stateToStateLabel[listType]} ${messageMap("tutoringPage.session.sessions1", "generic")} ${followingStatementMap[listType]}`}
				</div>
			);

			if (emptyListsRef.current[intendedUser] == null) {
				emptyListsRef.current[intendedUser] = [];
			}
			emptyListsRef.current[intendedUser].push(listType);
		}
		else {
			emptyMessagesMap[intendedUser][listType]("");
			emptyListsRef.current[intendedUser].splice(
				emptyListsRef.current[intendedUser].indexOf(listType), 1
			);
		}
	}

	function showLiveAndCompletedSessions(viewType, sessionState) {
		const viewTypeToNextSessionTypeMap = {
			[TUTEE_USER]: {
				[SCHEDULED_STATE]: setDefaultScheduledSessions,
				[LIVE_STATE]: setDefaultLiveSessions,
				[COMPLETED_STATE]: setDefaultCompletedSessions
			},
			[TUTOR_USER]: {
				[SCHEDULED_STATE]: setTutorScheduledSessions,
				[LIVE_STATE]: setTutorLiveSessions,
				[COMPLETED_STATE]: setTutorCompletedSessions
			}
		};

		// for re-render for subsequent updates
		const newDate = new Date();
		viewTypeToNextSessionTypeMap[viewType][sessionState](
			<RequestList key={`${newDate.getTime()}_${viewType}_${sessionState}`}
				intendedUser={viewType} listType={sessionState}
				setTutorProfilePane={setTutorProfilePane}
				emptyListHandler={indicateEmptyList}
				reRenderNextListStateHandler={[SCHEDULED_STATE, LIVE_STATE].includes(sessionState) ? showNextSessionState : null}
				setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
		);
	}

	function showNextSessionState(viewType, sessionState) {
		const stateToTabMap = {
			[LIVE_STATE]: SESSIONS_LIVE_SESSIONS,
			[COMPLETED_STATE]: SESSIONS_PAST_SESSIONS,
		};

		showSubMenuPage(stateToTabMap[sessionState]);
		showLiveAndCompletedSessions(viewType, sessionState);
		history.push(`/tutoring?show=sessions&sub=${stateToTabMap[sessionState]}`);
		indicateEmptyList(null, 0, sessionState, viewType, "show");
	}

	return (
		<div className="session-page">
			<Helmet>
				<title>{messageMap("tutoringSessions.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tutoringSessions.description", "headerTag")}></meta>
			</Helmet>

			{tutorProfilePane}

			{
				toggableUser ?
				(
					<div className="toggle-user">
						<label className="switch">
							<input type="checkbox" onClick={changeUserMode} />
							<span className="slider round"></span>
						</label>
						{userLabel}
					</div>
				)
				: ""
			}

			{
				signUpFirstVisible 
				? (
					<div className="empty-list-container">
						<div className="empty-list">
							{messageMap("tutoringPage.tutorList.signUpFirstSessions1", "generic")}
							<button className="sign-up-button"
								onClick={e => askUserToSignUpFirst(props.setModal, props.setModal1)}>
								{messageMap("here.text", "button")}
							</button>
							{messageMap("tutoringPage.tutorList.signUpFirstSessions2", "generic")}
						</div>
					</div>
				)
				: (
					<Fragment>
						{tabbedPagesDom}

						<div className={`request-group ${sessionSetTypeRef.current === DEFAULT_TYPE ? "hide" : ""}`}>
							<div className="upcoming-sessions" ref={upcomingSessionRef1}>
								{tutorScheduledSessions}
								{emptyTutorScheduledSessions}
							</div>

							<div className="live-sessions hide" ref={liveSessionsRef1}>
								{tutorLiveSessions}
								{emptyTutorLiveSessions}
							</div>

							<div className="past-sessions hide" ref={pastSessionsRef1}>
								{tutorCompletedSessions}
								{emptyTutorCompletedSessions}
							</div>
						</div>

						<div className={`request-group ${sessionSetTypeRef.current === DEFAULT_TYPE ? "" : "hide"}`}>
							<div className="upcoming-sessions" ref={upcomingSessionRef2}>
								{defaultScheduledSessions}
								{emptyDefaultScheduledSessions}
							</div>

							<div className="live-sessions hide" ref={liveSessionsRef2}>
								{defaultLiveSessions}
								{emptyDefaultLiveSessions}
							</div>

							<div className="past-sessions hide" ref={pastSessionsRef2}>
								{defaultCompletedSessions}
								{emptyDefaultCompletedSessions}
							</div>
						</div>
					</Fragment>
				)
			}
		</div>
	);
}

TutorSessions.propTypes = {
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func,

	// redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(TutorSessions);


