import React, {Fragment} from 'react';

import messageMap from "Utilities/MessageMaps";

export function openPromptSchema(skipPrompts, skipAllPrompt, tooltip) {
	return {
		titleStyle: {
			"fontWeight": "600",
			"fontSize": "24px",
			"marginTop": "10px",
			"marginBottom": "10px"
		},
		textArea: {
			rows: 4,
			columns: 100
		},
		customButtons: [
			(
				<Fragment key="skipButton">
					<button onClick={skipPrompts} style={{"backgroundColor": "#FF5455", color: "white"}}>
						Skip prompt
					</button>
					{skipAllPrompt}
				</Fragment>
			),
		],
		icon: {
			type: "information",
			tooltip: {
				subheader: tooltip,
				containerStyle: {
					"marginTop": "-50px"
				},
				tooltipStyle: {
					"marginTop": "0px"
				}
			}
		},
		iconState: [{
			state: "iconTooltipDisplay",
			defValue: "hide"
		}]
	}
}

export function noteGetApiSchema(timeObject, showSavedNotes, resp, deleteNote, closeNotes) {
	return {
		stateProps: [
			{
				state: "textArea",
				defValue: ""
			}, {
				state: "videoTs",
				defValue: `${timeObject.minutes}:${timeObject.seconds}`
			}
		],
		modalContainerStyle: {
			backgroundColor: "#fff",
			borderRadius: "16px",
			width: "80%"
		},
		titleStyle: {
			"fontWeight": "600",
			"fontSize": "24px",
			"marginTop": "10px",
			"marginBottom": "24px"
		},
		subHeader: (
			<div>
				<button className="button-link" onClick={showSavedNotes} style={{fontSize: "16px"}}>
					{messageMap("learn.list", "button")}
				</button>
			</div>
		),
		subHeaderStyle: {
			marginBottom: "16px",
			width: "auto",
			textAlign: "justify"
		},
		textArea: {
			value: resp && resp.note ? resp.note : ""
		},
		textAreaStyle: {
			borderRadius: "8px",
			width: "100%",
			height: "136px"
		},
		customButtons: [
			(
				<Fragment key="customNoteButtons">
					{
						resp && resp.note ?
						(<button onClick={() => deleteNote(`${timeObject.minutes}:${timeObject.seconds}`)}
										style={{"backgroundColor": "#FF5455", color: "white", borderRadius: "60px", fontSize: "16px"}}>
							{messageMap("delete.text", "button")}
						</button>)
						: ""
					}
					<button onClick={closeNotes} style={{borderRadius: "60px", fontSize: "16px"}}>
						{messageMap("cancel.text", "button")}
					</button>
				</Fragment>
			),
		]
	};
}

export const modalContainerStyleSchema = {
	overflowY: "auto",
	backgroundColor: "white",
	width: "80%",
	maxHeight: "900px"
};
