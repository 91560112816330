import messageMap from "Utilities/MessageMaps";

// API documentation for mp4box: https://github.com/gpac/mp4box.js
const MP4Box = require("mp4box");

// codecs for mp4: https://www.rfc-editor.org/rfc/rfc6381#section-3
const supportedAudioCodecs = ["mp4a"];
// "svc1", "mvc1", "mvc2", 
const supportedVideoCodecs = ["mp4v", "avc1", "avc2", "vp9", "vp09", "av1"];

export function videoProbe(videoArrayBuffer) {
	let videoProbePromise = new Promise((resolve, reject) => {
		let actualArrayBuffer = videoArrayBuffer.target.result;
		actualArrayBuffer.fileStart = 0;
		let mp4boxFile = MP4Box.createFile();
		mp4boxFile.onReady = function(info) {
			resolve(info);
		}
	
		mp4boxFile.appendBuffer(actualArrayBuffer);
	});

	return videoProbePromise;
}

export function validateVideoAndAudioCodecs(analysis) {
	let errorMsgs = "";

	if (analysis.audioTracks && analysis.audioTracks.length) {
		const audioCodec = analysis.audioTracks[0].codec;

		if (!supportedAudioCodecs.some(codec => audioCodec.includes(codec))) {
			errorMsgs += (messageMap("video.codec.audio.invalid", "validation") + " ");
		}
	}
	else {
		errorMsgs += (messageMap("video.codec.audio.missing", "validation") + " ");
	}

	if (analysis.videoTracks && analysis.videoTracks.length) {
		const videoCodec = analysis.videoTracks[0].codec;

		if (!supportedVideoCodecs.some(codec => videoCodec.includes(codec))) {
			errorMsgs += (messageMap("video.codec.video.invalid", "validation") + " ");
		}
	}
	else {
		errorMsgs += (messageMap("video.codec.video.missing", "validation") + " ");
	}

	return errorMsgs;
}