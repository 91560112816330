import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/wellBeing/thumbnailGoodBadStress.jpg";


export const wellBeingGoodBadStressArticle = {
	id: "III. Well Being_2_2 How to identify good stress vs bad stress and its effects on our brain",
	title: messageMap("goodBadStress.article.title", "learningOptimization"),
	description: messageMap("goodBadStress.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("goodBadStress.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3",
		"text4", "text5", "text6", "text7",
		"text8", "text9", "text10", "text11", "text12", "text13", "text14", "text15"
	],
	datePublished: "2022-03-06",
	dateModified: "2024-06-23"
};

export const wellBeingGoodBadStressArticleTexts = {
	text1: {
		text: messageMap("goodBadStress.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("goodBadStress.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("goodBadStress.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("goodBadStress.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("goodBadStress.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("goodBadStress.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text7: {
		text: messageMap("goodBadStress.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("goodBadStress.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("goodBadStress.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("goodBadStress.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("goodBadStress.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text12: {
		text: messageMap("goodBadStress.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text14: {
		text: messageMap("goodBadStress.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text15: {
		text: messageMap("goodBadStress.sources.s2", "learningOptimization"),
		type: SOURCE
	}
};



