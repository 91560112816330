import React from "react";

import { messageMapGeneric } from "Utilities/MessageMaps";

export const TUTOR_APPLICATION_PROCESS = [
	messageMapGeneric("profilePage.tutoring.modal.requirements.p6"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p7"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p8"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p9"),
	messageMapGeneric("profilePage.tutoring.modal.requirements.p10")
];

function ApplicationProcess(props) {

	return (
		<div>
			<div className="title">
				{messageMapGeneric("tutoringPage.faqs.tutors.applicationProcess")}
			</div>

			<ol>
				{
					TUTOR_APPLICATION_PROCESS.map((requirement, index) =>
						<li key={`process_${index}`} className="faq-text">
							{requirement}
						</li>
					)
				}
			</ol>
		</div>
	);
}

export default ApplicationProcess;

