import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {logInUser} from "redux/actions/actionTypes";

import {detailsSchema, modalSchema, modalLawSchema} from "pages/Home/schemas/homeSchema";

import Alert, {SUCCESS as ALERT_SUCCESS, INFORMATION as ALERT_INFORMATION, ERROR as ALERT_ERROR} from "templates/Alert";
import Modal from "templates/Modal";
import Spinner from "templates/Spinner";
import PrivacyPolicyModal from "templates/customModals/PrivacyPolicyModal";
import TermsNConditionsModal from "templates/customModals/TermsNConditionsModal";

import BrandIntroFull from "pages/Home/components/BrandIntroFull";
import MarketingDetails from "pages/Home/components/MarketingDetails";

import {signUpAPI, usernameAvailabilityAPI} from "apis/controllers/person/AccountsController";
import {getBetaKeyAPI} from "apis/controllers/customer/AccessController";
import {saveGeneralOrBugFeedbackAPI} from "apis/controllers/customer/FeedbackController";
import {accessAPI} from "apis/controllers/customer/PromotionsController";

import messageMap from "Utilities/MessageMaps";
import {emailValidator} from "Utilities/Validators/InputValidators";
import { getTimezoneOffset } from 'templates/utilities/CalendarUtilities';
import { AUTO_RELOAD_TIMEOUT, MODAL_CLOSE_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';

import checkAsset from "assets/icons/home/check.svg";
import exAsset from "assets/icons/home/ex.svg";
import rectangleTopAsset from "assets/images/home/rectangleTop.png";


function Home(props) {
	window.scrollTo(0, 0);

	const [learnerDetails, setLearnerDetails] = useState([]),
				[creatorDetails, setCreatorDetails] = useState([]),
				[domModal, setDomModal] = useState(null),
				[lawModal, setLawModal] = useState(null),
				[spinner, setSpinner] = useState(""),
				[alert, setAlert] = useState(null),
				[ctaDisplay, setCtaDisplay] = useState("submit"),
				[emailInputClassName, setEmailInputClassName] = useState(null),
				[emailInputValue, setEmailInputValue] = useState(null),
				[feedbackValue, setFeedbackValue] = useState(null);

	useEffect(() => {
		createDetails();

		if (localStorage.ownerId) {
			setCtaDisplay("submit hidden");
		}
		// showBetaKeyAccess(true);
	}, []);

	function showBetaKeyAccess(useTimeout) {
		const modalSchemaObj = modalSchema(closeModal, openModalLaw, checkUsername)["betaKey"];
		const modal = (
			<Modal stateProps={modalSchemaObj.stateProps} inputs={modalSchemaObj.inputs}
								submitText={messageMap("account.access.betaKey", "generic")}
								submitHandler={getBetaKey} closeHandler={closeModal}/>
		);

		if (useTimeout) {
			setTimeout(() => {
				setDomModal(modal);
			}, 15000);
		}
		else {
			setDomModal(modal);
		}
	}

	function getBetaKey(accessStates) {
		const pathVariables = {
			email: accessStates.userEmail.value
		};

		getBetaKeyAPI(pathVariables, response => {
			const respKeys = response.split("_");
			if (respKeys.length === 2) {
				setAlert(
					<Alert closeHandler={closeAlert} type={ALERT_SUCCESS} msg={messageMap(respKeys[0], "api") + respKeys[1]}/>
				);
			}
			else {
				setAlert(
					<Alert closeHandler={closeAlert} type={ALERT_INFORMATION} msg={messageMap(respKeys[0], "api")}/>
				);
			}

			hideModal("default");
		});
	}

	function createDetails() {
		setLearnerDetails(<MarketingDetails details={detailsSchema.learnerDetails} title={messageMap("home.learners.title", "generic")} />);
		setCreatorDetails(<MarketingDetails details={detailsSchema.creatorDetails} title={messageMap("home.educators.title", "generic")} />);
	}

	function openModal() {
		const modalSchemaObj = modalSchema(closeModal, openModalLaw, checkUsername)["signUp"];
		const inputsToValidate = modalSchemaObj.stateProps.slice(0, modalSchemaObj.stateProps.length - 2),
			errorMsg = (
				<div className="error-msg">
					Please correct the fields in <span>red *</span>
				</div>
			);

		setDomModal(
			// TODO: include in Modal once we start supporting school credits
			// subHeader={subHeader}
			<Modal stateProps={modalSchemaObj.stateProps} closeHandler={closeModal} submitHandler={signUp}
						title="Sign Up" 
						inputs={modalSchemaObj.inputs} buttonAltText="Submit Button" footer={modalSchemaObj.disclaimer}
						errorMsg={errorMsg} inputsToValidate={inputsToValidate}/>
		);
	}

	function checkUsername(...args) {
		const target = args[0].target;
		usernameAvailabilityAPI({username: args[1].value}, (resp) => displayUsernameResultIcon(resp, target));
	}

	function displayUsernameResultIcon(resp, event) {
		// remove existing checks or exes icons
		event.parentNode.childNodes.forEach(dom => {
			if (dom.tagName === "IMG" && dom.className === "") {
				dom.remove();
			}
		});

		if (!resp) {
			let img = document.createElement("img")
			img.src = checkAsset;
			img.alt = "Check icon indicating that the username is available";
			img.style.display = "inline-block";
			img.style.marginLeft = "5px";
			img.style.height = "25px";
			event.parentNode.insertBefore(img, event.nextSibling);
		} 
		else {
			let img = document.createElement("img")
			img.src = exAsset;
			img.alt = "Cross icon indicating that the username is available";
			img.style.display = "inline-block";
			img.style.marginLeft = "5px";
			img.style.height = "25px";
			event.parentNode.insertBefore(img, event.nextSibling);
		}
	}

	function closeModal(e, type) {
		if (["modal-block", "cancel", "fullRegistration", "icon"].includes(e.target.className)) {
			type ? hideModal("law") : hideModal("default");
		}
	}
	function hideModal(modalType) {
		const modalMap = {
			default: setDomModal,
			law: setLawModal
		};

		setTimeout(() => {
			modalMap[modalType](null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function openModalLaw(type) {
		if (type === "terms") {
			setLawModal(
				<TermsNConditionsModal closeHandler={(e) => closeModal(e, "law")}/>
			);
		}
		else if (type === "privacy") {
			setLawModal(
				<PrivacyPolicyModal closeHandler={(e) => closeModal(e, "law")}/>
			);
		}
	}

	function signUp(signUpStates, ...args) {
		const spinnerContainerStyle = {
			position: "absolute",
			margin: "auto",
			left: "0",
			right: "0"
		};
		setSpinner(<Spinner spinnerContainerStyle={spinnerContainerStyle}/>);

		const payload = {
			email: signUpStates.signUpEmail.value,
			password: signUpStates.signUpPass.value,
			username: signUpStates.signUpUser.value,
			accessKey: "",
			timezone: getTimezoneOffset()
		};
		const credentials = {
			username: signUpStates.signUpUser.value,
			password: signUpStates.signUpPass.value
		};

		signUpAPI(payload, credentials,
			(data) => {
				props.logInUser(data.id);
				displaySignUpMessage(data.statusCode, payload.email);
				setTimeout(() => {
					window.location.reload();
				}, AUTO_RELOAD_TIMEOUT);
			}
		);
	}

	function sendFeedback() {
		const ownerId = localStorage.getItem("ownerId");

		const payload = {
			ownerId: ownerId,
			feedback: feedbackValue,
			type: "general"
		};
		
		if (!ownerId) {
			setEmailInputValue("");
			setFeedbackValue("");
			setAlert(
				<Alert closeHandler={closeAlert} type={ALERT_ERROR} msg={messageMap("action.needLogin", "validation")} />
			);
		}
		else {
			saveGeneralOrBugFeedbackAPI(payload, resp => {
				setAlert(
					<Alert closeHandler={closeAlert} type={ALERT_INFORMATION} msg={messageMap(resp, "api")} />
				);
			});
		}
	}

	function getEarlyAccess() {
		const payload = {
			email: emailInputValue
		};

		accessAPI(payload, resp => {
			setAlert(
				<Alert closeHandler={closeAlert} type="information" msg={messageMap(resp, "api")} />
			);
		});
	}

	function changeEmailInput(event) {
		setEmailInputValue(event.target.value);
		if (!emailValidator(event.target.value)) {
			setEmailInputClassName("email-warning");
		}
		else {
			setEmailInputClassName("");
		}
	}

	function displaySignUpMessage(statusCode, email) {
		setSpinner(null);

		hideModal("default");
		let message = messageMap(statusCode, "api");
		message = message.replace("${email}", email);
		setAlert(
			<Alert closeHandler={closeAlert} type={ALERT_SUCCESS} msg={message + " " +messageMap("accounts.update.reload", "api")}/>
		);
	}

	function closeAlert () {
		setAlert(null);
	}

	return (
		<div className="home">
			<Helmet>
				<title>{messageMap("aboutUs.title", "headerTag")}</title>
				<meta name="description" content={messageMap("aboutUs.description", "headerTag")}></meta>
			</Helmet>

			{spinner}

			{alert}

			<div className="intro-container">
				<BrandIntroFull clickHandler={openModal}/>
			</div>
			<img src={rectangleTopAsset} className="rectangle-top"></img>

			{learnerDetails}
			{creatorDetails}

			{/* TODO: uncomment and replace early access input once we launch */}
			<div className="email-update">
				{/* <div className="title">
					Get Updates
				</div>
				<div className="description">
					Want to suggest some classes, give some feedback, and/or receive occasional promotions? 
					Just leave your email and your thoughts below and we'll send them your way. Thanks!!
				</div>
				<input className={`email ${emailInputClassName}`} type="email"
					placeholder={messageMap("email", "labelPlaceholder")} onChange={changeEmailInput}></input>
				<textarea className="feedback" cols="50" rows="3" type="text"
					placeholder={messageMap("feedback", "labelPlaceholder")}
					onChange={(event) => setFeedbackValue(event.target.value)}></textarea>
				<button className="submit" onClick={sendFeedback}>Submit</button> */}

				{/* <div className="title">
					EXER INSTITUTE
				</div>
				<input className={`email ${emailInputClassName}`} type="email" placeholder="Email" onChange={changeEmailInput}></input> */}
				{/* className="submit" */}
				<button className={ctaDisplay} onClick={openModal}>
					{messageMap("home.action", "generic")}
				</button>
			</div>

			{domModal}

			{lawModal}

		</div>
	)
}

Home.propTypes = {
	logInUser: PropTypes.func.isRequired
};

export default connect(
	null,
	{logInUser}
)(Home);