import React, {Fragment} from 'react';
import PropTypes from "prop-types";

export default class TranscriptText extends React.Component {
	render() {
		let transcriptArr = [];
		const startTimeToTextMultiMap = this.props.startTimeToTextMultiMap;

		Object.entries(startTimeToTextMultiMap).forEach(([key, value]) => {
			let currentCueClassName = this.props.currentCue === key ? "cue current" : "cue";

			transcriptArr.push(
				<div key={key} className={currentCueClassName}>
					<button onClick={() => this.props.videoRef.current.currentTime = startTimeToTextMultiMap[key].videoStartTime} >
						<div className="timestamp">{key}</div>
						<div className="text">{value.text}</div>
					</button>
				</div>
			);
		});

		return (
			<Fragment>
				{transcriptArr}
			</Fragment>
		);
	}
}

TranscriptText.propTypes = {
	currentCue: PropTypes.string.isRequired,
	startTimeToTextMultiMap: PropTypes.object.isRequired,
	videoRef: PropTypes.object.isRequired
};