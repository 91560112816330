import React, {useState, useRef, useEffect} from 'react';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import account from "redux/selectors/accountSelector";

import Alert, {SUCCESS, ERROR} from "templates/Alert";
import TextEditor from "templates/TextEditor";

import messageMap from "Utilities/MessageMaps";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import {addStringDurations} from "pages/Classes/Videos/utilities/EditVideosListUtilities";

import {createModuleAPI, editModuleAPI} from "apis/controllers/video/VideoModuleController";
import {listAllVideosAPI} from "apis/controllers/video/VideoController";

import closeAsset from "assets/icons/common/close.svg";


/**
 * @description Component for creating and editing module.
 * @param {String} moduleId? // module's ID to be edited
 * @param {String} modalPurpose // For determining if module is for creating or editing module. Default is 'create'
 * @param {String} moduleName? // name of module
 * @param {String} moduleDescription? // module's description
 * @param {Array} videoIds // list of video IDs
 * @param {String} creatorUsername? // username of module creator
 * @param {String} submitText? // Text for submit button. Default is 'Create Module'
 */
function Module(props) {
	const [alert, setAlert] = useState(null),
				[videoMembers, setVideoMembers] = useState(null);

				// Value refs
	const moduleNameRef = useRef(props.moduleName ? props.moduleName : ""),
				moduleDescriptionRef = useRef(props.moduleDescription ? props.moduleDescription : ""),
				vidIdsToModuleMemberMapRef = useRef(),
				// DOM refs
				inputModuleRef = useRef();

	useEffect(() => {
		renderVideoMembers();
	}, []);

	function renderVideoMembers() {
		const pathVariables = {
			pageCount: 1,
			pageSize: 20,
			downloadTypes: ["thumbnail"]
		};
		const payload = {
			ownerId: props.ownerId,
			// fields that the UI wants to display
			// although not indicated, we're also going to allow the user to replace their uploaded video file
			filters: [
				"id",
				"title",
				"uploadDate",
				"duration",
				"thumbnailUploadLocation", 
				"thumbnailDescription",
				"thumbnailFileType"
			],
			videoIds: props.videoIds
		};

		listAllVideosAPI(pathVariables, payload, resp => {
			let videoMembers = [];
			let videoIdToModuleMemberMap = {};

			Array.isArray(resp) && resp.forEach(el => {
				const videoId = el.videoId;
				const userName = el.uploaderUsername ? el.uploaderUsername : "unknown";
				const fileObj = el.downloadTypeToFiles["thumbnail"][0];
				let dataUrl = fileObj.file;
				let videoTitle = el.title;

				if (videoTitle.length > 50) {
					videoTitle = videoTitle.slice(0, 45) + "...";
				}

				videoIdToModuleMemberMap[videoId] = el;

				videoMembers.push(
					<div key={videoId} videoid={videoId} className="module-member-container">
						<button className="remove-button" onClick={hideVideoMember}>
							<img className="remove-icon" src={closeAsset} alt={messageMap("alerts.close", "image")}></img>
						</button>

						<img className="thumbnail"
									alt={el.thumbnailDescription ? el.thumbnailDescription : ""}
									src={dataUrl}></img>

						<div className="title-user">
							<div className="video-title">
								{videoTitle}
							</div>
							<div className="video-uploader">
								{userName}
							</div>
						</div>
					</div>
				);
			});

			vidIdsToModuleMemberMapRef.current = videoIdToModuleMemberMap;
			setVideoMembers(videoMembers);
		});
	}

	function hideVideoMember(e) {
		if (Object.keys(vidIdsToModuleMemberMapRef.current).length === 2) {
			const alertContainerStyle = {
				"marginBottom": "25px"
			};

			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					customiconid="validation"
					msg={messageMap("module.memberCount", "validation")}
					alertContainerStyle={alertContainerStyle}/>
			);
		}
		else {
			const target = e.target;
			let videoIdToRemove;
	
			if (target.tagName === "IMG") {
				target.parentNode.parentNode.setAttribute("class", "module-member-container hide");
				videoIdToRemove = target.parentNode.parentNode.attributes.videoid.value;
			}
			else if (target.tagName === "BUTTON") {
				target.parentNode.setAttribute("class", "module-member-container hide");
				videoIdToRemove = target.parentNode.attributes.videoid.value;
			}
	
			delete vidIdsToModuleMemberMapRef.current[videoIdToRemove];
		}
	}

	function checkModuleName(e) {
		const currentModuleName = e.target.value;
		if (!checkHasExplicitWords(currentModuleName)) {
			moduleNameRef.current = currentModuleName;
		}
		else {
			e.target.value = "";
			const alertContainerStyle = {
				"top": "75px"
			};
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					customiconid="validation"
					msg={messageMap("input.explicit.text", "validation")}
					alertContainerStyle={alertContainerStyle} />
			);
		}
	}

	function closeAlert() {
		setAlert(null);
	}

	function checkTextareaText(text) {
		if (!checkHasExplicitWords(text)) {
			moduleDescriptionRef.current = text;
		}
		else {
			const alertContainerStyle = {
				"top": "75px"
			};
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					customiconid="validation"
					msg={messageMap("input.explicit.text", "validation")}
					alertContainerStyle={alertContainerStyle} />
			);
		}
	}

	function bundleVideos() {
		if (!moduleNameRef.current || !moduleDescriptionRef.current || props.videoIds == null) {
			const alertContainerStyle = {
				"top": "75px"
			};
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					customiconid="validation"
					msg={messageMap("input.module.missing", "validation")}
					alertContainerStyle={alertContainerStyle} />
			);

			return;
		}

		let totalDuration = "";
		for (const [key, value] of Object.entries(vidIdsToModuleMemberMapRef.current)) {
			totalDuration = addStringDurations(totalDuration, value.duration);
		}

		const payload = {
			playlistName: moduleNameRef.current,
			description: moduleDescriptionRef.current,
			playlistType: "module",
			itemIds: props.videoIds,
			totalDuration: totalDuration,
			creatorUsername: props.creatorUsername,
			creatorOwnerId: props.ownerId
		};

		createModuleAPI(payload, resp => {
			let alertType = resp.includes("failed") ? ERROR : SUCCESS;

			setAlert(
				<Alert type={alertType} closeHandler={closeAlert}
								msg={messageMap(resp, "api")}/>
			);
		});
	}

	function updateModule() {
		let totalDuration = "";
		for (const [key, value] of Object.entries(vidIdsToModuleMemberMapRef.current)) {
			totalDuration = addStringDurations(totalDuration, value.duration);
		}

		const pathVariables = {
			moduleId: props.moduleId
		};
		let payload = {
			playlistName: moduleNameRef.current,
			description: moduleDescriptionRef.current,
			itemIds: Object.keys(vidIdsToModuleMemberMapRef.current),
			totalDuration: totalDuration
		};

		editModuleAPI(pathVariables, payload, resp => {
			let alertType = SUCCESS;
			let alertMsg = [messageMap(resp, "api")];

			if (resp.includes("failed") || resp.includes("missing")) {
				alertType = ERROR;
				let errMsgs = resp.split("_");
				errMsgs[errMsgs.length - 1] === "" && errMsgs.pop();

				errMsgs.forEach(msg => {
					alertMsg.push(messageMap(msg, "api"));
				});
			}

			setAlert(
				<Alert type={alertType} closeHandler={closeAlert}
								msg={alertMsg}/>
			);
		});
	}


	return (
		<div className="module-container">
			{alert}

			<input className="module-name" onChange={checkModuleName} ref={inputModuleRef}
							placeholder={props.moduleName ? props.moduleName : messageMap("video.placeholder.moduleTitle", "generic")}></input>
			*

			<div className="video-members">
				{videoMembers}
			</div>

			<div className="text-editor">
				<TextEditor onChangeHandler={checkTextareaText}
									placeholder={props.moduleDescription ? props.moduleDescription : messageMap("video.placeholder.moduleDescription", "generic")}>
				</TextEditor>
			</div>
			<div className="text-editor-requirement">*</div>

			<button className="submit-module"
							onClick={props.modalPurpose === "update" ? updateModule : bundleVideos}>
				{
					props.submitText
					?
					props.submitText
					:
					messageMap("editVideoPage.modules.create", "button")
				}
			</button>
		</div>
	);
}


Module.propTypes = {
	moduleId: PropTypes.string,
	modalPurpose: PropTypes.string,
	moduleName: PropTypes.string,
	moduleDescription: PropTypes.string,
	videoIds: PropTypes.array.isRequired,
	creatorUsername: PropTypes.string,
	submitText: PropTypes.string,

	// Redux props
	ownerId: PropTypes.string
};

export default connect(
	account,
	null
)(Module);
