import React, { useState } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import { ConnectedPurchasePopUp } from './PurchasePopUp';

import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from 'Utilities/Validators/IdentityValidators';
import { askUserToAuthFirst } from 'templates/customModals/utilities/SignUpLoginUtilities';

function PayToWatchButton(props) {
  const [showPopUp, setShowPopUp] = useState(false);

  const isUserSignedIn = isValidIdentity(props.ownerId);

  function openBuyPopUp() {
    setShowPopUp(true);
    if (!isUserSignedIn) {
      askUserToAuthFirst(props.setAuthModals);
    }
  }

  return (
    <div className="purchase-button-container">
      {
        props.price ?
          <button className="purchase-button" onClick={openBuyPopUp}
            onKeyDown={openBuyPopUp}>
            {messageMap("buy.text", "button")}
          </button>
          : null
      }

      {
        (showPopUp && isUserSignedIn)
        &&
        <ConnectedPurchasePopUp {...props} setShowPopUp={setShowPopUp} />
      }
    </div>
  )
}

PayToWatchButton.propTypes = {
  ownerId: PropTypes.string,// Pulled from account
  price: PropTypes.number,
  setAuthModals: PropTypes.shape(
    {
      setSignUpModal: PropTypes.func,
      setLogInModal: PropTypes.func
    }
  )
}

export const ConnectedPayToWatchButton = connect(
  account,
  null
)(PayToWatchButton);