import React from 'react';
import PropTypes from "prop-types";

import checkMarkAsset from "../../assets/icons/common/green check mark.svg";
import messageMap from 'Utilities/MessageMaps';

const SubscriptionCard = ({ title, price, period, buttonText, buttonProps, subHeader, features }) => {
  return (
    <div className="subscription-card">
      <h2 className="subscription-title">{title}</h2>
      <h3 className={"subscription-price" + (price.length > 5 ? "-shrink" : "")}>
        {price}
        <span className="subscription-period"> {period}</span>
      </h3>
      <button className="subscription-button" {...buttonProps}>
        {buttonText}
      </button>
      <p className="subscription-subheader">{subHeader}</p>
      <div className="features-container">
        {features.map((feature, index) => (
          <div key={index} className="feature-container">
            <img
              src={checkMarkAsset}
              alt={messageMap("checkMark", "image")}
              className="feature-checkmark"
            />
            <div className="feature-text">
              {feature}
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};

SubscriptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonProps: PropTypes.object.isRequired,
  subHeader: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SubscriptionCard;
