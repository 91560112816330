import React, {useEffect, useState, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";
import Alert, {ERROR} from "templates/Alert";
import Modal from "templates/Modal";

import messageMap from "Utilities/MessageMaps";
import { convertMilitaryTimeTo12HrFormat } from "Utilities/Time";
import {daySchedules} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import closeAsset from "assets/icons/common/close.svg";


function Schedule(props) {

	const [schedules, setSchedules] = useState(props.schedules),
		[cancellableSchedule, setCancellableSchedule] = useState();

	const unavailableInputRef = useRef(),
		availableInputRef = useRef(),
		availabilityTime1Ref = useRef(),
		availabilityTime2Ref = useRef();

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.schedules) {
			setCancellableSchedule(createCancellableSchedule(props.schedules));
		}
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			hideModal();
		}
	}
	function hideModal() {
		setTimeout(() => {
			props.setModal(null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function showCancellableSchedule(e) {
		const day = e.target.getAttribute("scheduleKey");

		if (day === "chooseDay") {
			return;
		}

		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal} submitHandler={e => setDaySchedule(day)}
						modalClass="become-tutor-modal"
						title={`${messageMap("profilePage.tutoring.modal.schedule.setScheduleTitle", "generic")} ${day}`}
						submitText={messageMap("tutoring.modal.setSchedule", "button")}>
				<div className="multi-dom-line schedules">
					<div className="schedule-option margin-right-48">
						<input id="unavailable" className="page-field-value radio-input radio" type="radio"
							ref={unavailableInputRef}
							onChange={e => updateSchedule(e, day, "unavailable")}>
						</input>
						<label htmlFor="unavailable">
							{messageMap("tutoring.profile.unavailable", "labelPlaceholder")}
						</label>
					</div>

					<div className="schedule-option">
						<input id="available" className="page-field-value radio-input radio" type="radio"
							ref={availableInputRef}
							onChange={e => updateSchedule(e, day, "available")}>
						</input>
						<input className="page-field-value time" type="time" ref={availabilityTime1Ref}
							onChange={e => updateSchedule(e, day, "from")}>
						</input>
						<div className="dash">-</div>
						<input className="page-field-value time" type="time" ref={availabilityTime2Ref}
							onChange={e => updateSchedule(e, day, "to")}>
						</input>
					</div>
				</div>
			</Modal>
		);
	}

	function updateSchedule(e, day, specifier) {
		let currentDaySchedule = schedules;
		if (currentDaySchedule[day] == null) {
			currentDaySchedule[day] = {};
		}

		if (specifier === "unavailable") {
			unavailableInputRef.current.checked = true;
			availableInputRef.current.checked = false;

			delete currentDaySchedule[day]["from"];
			delete currentDaySchedule[day]["to"];
			currentDaySchedule[day]["unavailable"] = true;
			availabilityTime1Ref.current.value = null;
			availabilityTime2Ref.current.value = null;

			setSchedules(currentDaySchedule);
			props.setSchedules(currentDaySchedule);
		}
		else {
			unavailableInputRef.current.checked = false;
			availableInputRef.current.checked = true;

			if (["from", "to"].includes(specifier)) {
				delete currentDaySchedule[day]["unavailable"];
				currentDaySchedule[day][specifier === "from" ? "from" : "to"] = e.target.value;
				setSchedules(currentDaySchedule);
				props.setSchedules(currentDaySchedule);
			}
		}
	}

	function setDaySchedule(day) {
		let currentDaySchedule = schedules;
		const fromTime = currentDaySchedule[day]["from"];
		const toTime = currentDaySchedule[day]["to"];
		const fromNumber = Number(fromTime.replace(":", ""));
		const toNumber = Number(toTime.replace(":", ""));

		if (currentDaySchedule[day] &&
				([true, false].includes(currentDaySchedule[day]["unavailable"])
					|| (fromTime && toTime))) {
			if ((fromTime == null || toTime == null)
				&& (unavailableInputRef.current.checked == null)
			) {
				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("schedule.unfilled", "validation")} />
				);
			}
			else if (fromNumber > toNumber) {
				props.setAlert(
					<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("schedule.invalidTime", "validation")} />
				);
			}
			else {
				hideModal();
	
				setCancellableSchedule(createCancellableSchedule(currentDaySchedule));
			}
		}
	}

	function createCancellableSchedule(modifiableSchedule) {
		let scheduleDOMs = [];
		// TODO: create day schedule entry
		for (const [key, value] of Object.entries(modifiableSchedule)) {
			const isUnavailable = Object.keys(value).includes("unavailable");
			let daySchedule = (
				isUnavailable
				?
				(
					<div className="day-schedule">
						{messageMap("profilePage.tutoring.modal.schedule.unavailable", "generic")}
					</div>
				)
				: (
					<Fragment>
						<div className="day-times">
							{convertMilitaryTimeTo12HrFormat(value["from"])}
						</div>
						<div className="day-to">
							{messageMap("profilePage.tutoring.modal.schedule.to", "generic")}
						</div>
						<div className="day-times">
							{convertMilitaryTimeTo12HrFormat(value["to"])}
						</div>
					</Fragment>
				)
			);

			scheduleDOMs.push(
				<div key={key} className="schedule-container">
					<div className="day-schedule">
						{`${key}: `}
					</div>
					{daySchedule}
					<button className="remove-cancellation-policy"
						onClick={e => removeScheduledDay(key)}>
						<img className="x-icon" src={closeAsset} alt="ex icon"></img>
					</button>
				</div>
			);
		}

		return scheduleDOMs;
	}

	function removeScheduledDay(day) {
		let currentSchedule = schedules;
		delete currentSchedule[day];

		setSchedules(currentSchedule);
		props.setSchedules(currentSchedule);
		setCancellableSchedule(createCancellableSchedule(currentSchedule));
	}

	return (
		<div className="page-field multi-lines">
			<label className="page-field-label align-to-form">
				<div className="div-label">
					{messageMap("profilePage.tutoring.modal.application.schedule", "generic")}
				</div>
				*
			</label>
			<div className="inline-elements">
				<div className="multi-line-inlines">
					<Dropdown customDropdownItemAttribute="scheduleKey" customContainerClass="tutoring-application no-right-margin"
						dropdownOptions={daySchedules} dropdownItemClickHandler={showCancellableSchedule}/>
					<div className="schedule-info">
						{messageMap("profilePage.tutoring.modal.schedule.unscheduledDays", "generic")}
					</div>
					<div>
						{cancellableSchedule}
					</div>
				</div>
			</div>
		</div>
	);
}

Schedule.propTypes = {
	setModal: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	schedules: PropTypes.object,
	setSchedules: PropTypes.func.isRequired
};

export default Schedule;


