import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/physiology/thumbnailCombinedSystem.jpg";
import combinedSystemsAsset from "assets/topicArticles/LearningOptimizations/physiology/combinedSystems.svg";
import emptyComparisonsAsset from "assets/topicArticles/LearningOptimizations/physiology/emptyComparisons.svg";
import learningSpeedAsset from "assets/topicArticles/LearningOptimizations/physiology/learningSpeedComparison.svg";
import useSpeedAsset from "assets/topicArticles/LearningOptimizations/physiology/useSpeedComparison.svg";
import flexibilityAsset from "assets/topicArticles/LearningOptimizations/physiology/flexibilityComparison.svg";


export const physiologySystemsCombinedArticle = {
	id: "V. Physiology_4_2 How information travels in our brain: combining the two systems (Part 3)",
	title: messageMap("combinedSystem.article.title", "learningOptimization"),
	description: messageMap("combinedSystem.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("combinedSystem.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4",
		"text5", "text6", "html1", "text7", "text8", "text9", "text10", "text11",
		"text12", "text13", "html2", "text14", "html3", "text15", "html4", "text16", "html5",
		"text17", "text18",
		"text19", "text20", "text21"
	],
	datePublished: "2023-12-29",
	dateModified: "2024-06-26"
};

export const physiologySystemsCombinedArticleTexts = {
	text1: {
		text: messageMap("combinedSystem.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("combinedSystem.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("combinedSystem.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("combinedSystem.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("combinedSystem.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("combinedSystem.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("combinedSystem.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("combinedSystem.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("combinedSystem.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("combinedSystem.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("combinedSystem.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("combinedSystem.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text13: {
		text: messageMap("combinedSystem.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("combinedSystem.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("combinedSystem.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("combinedSystem.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("combinedSystem.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text18: {
		text: messageMap("combinedSystem.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text20: {
		text: messageMap("combinedSystem.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text21: {
		text: messageMap("combinedSystem.sources.s2", "learningOptimization"),
		type: SOURCE
	}
};

export const physiologySystemsCombinedArticleHTMLs = {
	html1: (
		<img key="combinedSystemsAsset" src={combinedSystemsAsset} alt="model of how the declarative and procedural system combine and talk to each other" style={{width: "-webkit-fill-available"}} />
	),
	html2: (
		<img key="emptyComparisonsAsset" src={emptyComparisonsAsset} alt="empty 3x2 comparison between declarative and procedural systems on speed of learning, speed of use, and application flexibility." style={{width: "350px"}} />
	),
	html3: (
		<img key="learningSpeedAsset" src={learningSpeedAsset} alt="comparison between declarative and procedural system on learning speed, with declarative system being faster than the procedural system." style={{width: "350px"}} />
	),
	html4: (
		<img key="useSpeedAsset" src={useSpeedAsset} alt="comparison between declarative and procedural system on use speed, with declarative system being much slower than the procedural system." style={{width: "350px"}} />
	),
	html5: (
		<img key="flexibilityAsset" src={flexibilityAsset} alt="comparison between declarative and procedural system on application flexibility, with declarative system being much more flexible than the procedural system." style={{width: "350px"}} />
	)
};


