import { create, all } from "mathjs";
import messageMap from "Utilities/MessageMaps";


/**
 * @description Full description of math.js configuration can be found here: https://mathjs.org/docs/core/configuration.html
 * The following is the abbreviated version.
 * @param {Number} epsilon 
 * @param {String} matrix 
 * @param {String} number 
 * @param {Number} precision 
 * @param {Boolean} predictable 
 * @param {Object} randomSeed 
 */
export default function getMathJS(epsilon = 1e-12, matrix = "Matrix", number = "number", 
																precision = 64, predictable = false, randomSeed = null) {
	// create a mathjs instance with configuration
	const config = {
		epsilon: epsilon,
		matrix: matrix,
		number: number,
		precision: precision,
		predictable: predictable,
		randomSeed: randomSeed
	}
	return create(all, config);
}

/**
 * @returns 0: negative, 1: positive
 */
export function flipCoinForPositiveOrNegativeNumber() {
	return Math.floor(Math.random() * 2);
}

/**
 * @returns 0 = ['+'], 1 = ['+', '-'], 2 = ['+', '-', '*'], 3 = ['+', '-', '*', '/']
 */
export function rollNSidedDice(nSide) {
	return Math.floor(Math.random() * nSide);
}


const intToSymbolMap = {
	"-1": "=",
	0: "+",
	1: "-",
	2: "*",
	3: "/"
};
export function convertIntToSymbol(intSymbol) {
	return intToSymbolMap[intSymbol];
}

export function getRandomNumberBetween(min, max) {
	return Math.round(Math.random() * (max - min) + min);
}

export function getMinMaxRangeFromDigitCount(digitCount) {
	return [Math.pow(10, digitCount - 1), Math.pow(10, digitCount) - 1];
}

/**
 * @description based on the de-facto shuffle algo - Fisher-Yates (Knuth) Shuffle: https://bost.ocks.org/mike/shuffle/
 * @param {Array} array array elements to shuffle
 */
export function shuffle(array) {
	let currentIndex = array.length;

	// While there remain elements to shuffle...
	while (currentIndex !== 0) {

		// Pick a remaining element...
		let randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}
}


// need to directly reference the number keys, since different languages have different naming patterns for numbers
export const numberWords = {
	1: messageMap("numberWords.one", "math"), 2: messageMap("numberWords.two", "math"), 3: messageMap("numberWords.three", "math"), 4: messageMap("numberWords.four", "math"), 5: messageMap("numberWords.five", "math"), 6: messageMap("numberWords.six", "math"), 7: messageMap("numberWords.seven", "math"), 8: messageMap("numberWords.eight", "math"), 9: messageMap("numberWords.nine", "math"),
	10: messageMap("numberWords.ten", "math"), 11: messageMap("numberWords.eleven", "math"), 12: messageMap("numberWords.twelve", "math"), 13: messageMap("numberWords.thirteen", "math"), 14: messageMap("numberWords.fourteen", "math"), 15: messageMap("numberWords.fifteen", "math"), 16: messageMap("numberWords.sixteen", "math"), 17: messageMap("numberWords.seventeen", "math"), 18: messageMap("numberWords.eighteen", "math"), 19: messageMap("numberWords.nineteen", "math"),
	20: messageMap("numberWords.twenty", "math"), 21: messageMap("numberWords.twentyOne", "math"), 22: messageMap("numberWords.twentyTwo", "math"), 23: messageMap("numberWords.twentyThree", "math"), 24: messageMap("numberWords.twentyFour", "math"), 25: messageMap("numberWords.twentyFive", "math"), 26: messageMap("numberWords.twentySix", "math"), 27: messageMap("numberWords.twentySeven", "math"), 28: messageMap("numberWords.twentyEight", "math"), 29: messageMap("numberWords.twentyNine", "math"),
	30: messageMap("numberWords.thirty", "math"), 31: messageMap("numberWords.thirtyOne", "math"), 32: messageMap("numberWords.thirtyTwo", "math"), 33: messageMap("numberWords.thirtyThree", "math"), 34: messageMap("numberWords.thirtyFour", "math"), 35: messageMap("numberWords.thirtyFive", "math"), 36: messageMap("numberWords.thirtySix", "math"), 37: messageMap("numberWords.thirtySeven", "math"), 38: messageMap("numberWords.thirtyEight", "math"), 39: messageMap("numberWords.thirtyNine", "math"),
	40: messageMap("numberWords.forty", "math"), 41: messageMap("numberWords.fortyOne", "math"), 42: messageMap("numberWords.fortyTwo", "math"), 43: messageMap("numberWords.fortyThree", "math"), 44: messageMap("numberWords.fortyFour", "math"), 45: messageMap("numberWords.fortyFive", "math"), 46: messageMap("numberWords.fortySix", "math"), 47: messageMap("numberWords.fortySeven", "math"), 48: messageMap("numberWords.fortyEight", "math"), 49: messageMap("numberWords.fortyNine", "math"),
	50: messageMap("numberWords.fifty", "math"), 51: messageMap("numberWords.fiftyOne", "math"), 52: messageMap("numberWords.fiftyTwo", "math"), 53: messageMap("numberWords.fiftyThree", "math"), 54: messageMap("numberWords.fiftyFour", "math"), 55: messageMap("numberWords.fiftyFive", "math"), 56: messageMap("numberWords.fiftySix", "math"), 57: messageMap("numberWords.fiftySeven", "math"), 58: messageMap("numberWords.fiftyEight", "math"), 59: messageMap("numberWords.fiftyNine", "math"),
	60: messageMap("numberWords.sixty", "math"), 61: messageMap("numberWords.sixtyOne", "math"), 62: messageMap("numberWords.sixtyTwo", "math"), 63: messageMap("numberWords.sixtyThree", "math"), 64: messageMap("numberWords.sixtyFour", "math"), 65: messageMap("numberWords.sixtyFive", "math"), 66: messageMap("numberWords.sixtySix", "math"), 67: messageMap("numberWords.sixtySeven", "math"), 68: messageMap("numberWords.sixtyEight", "math"), 69: messageMap("numberWords.sixtyNine", "math"),
	70: messageMap("numberWords.seventy", "math"), 71: messageMap("numberWords.seventyOne", "math"), 72: messageMap("numberWords.seventyTwo", "math"), 73: messageMap("numberWords.seventyThree", "math"), 74: messageMap("numberWords.seventyFour", "math"), 75: messageMap("numberWords.seventyFive", "math"), 76: messageMap("numberWords.seventySix", "math"), 77: messageMap("numberWords.seventySeven", "math"), 78: messageMap("numberWords.seventyEight", "math"), 79: messageMap("numberWords.seventyNine", "math"),
	80: messageMap("numberWords.eighty", "math"), 81: messageMap("numberWords.eightyOne", "math"), 82: messageMap("numberWords.eightyTwo", "math"), 83: messageMap("numberWords.eightyThree", "math"), 84: messageMap("numberWords.eightyFour", "math"), 85: messageMap("numberWords.eightyFive", "math"), 86: messageMap("numberWords.eightySix", "math"), 87: messageMap("numberWords.eightySeven", "math"), 88: messageMap("numberWords.eightyEight", "math"), 89: messageMap("numberWords.eightyNine", "math"),
	90: messageMap("numberWords.ninety", "math"), 91: messageMap("numberWords.ninetyOne", "math"), 92: messageMap("numberWords.ninetyTwo", "math"), 93: messageMap("numberWords.ninetyThree", "math"), 94: messageMap("numberWords.ninetyFour", "math"), 95: messageMap("numberWords.ninetyFive", "math"), 96: messageMap("numberWords.ninetySix", "math"), 97: messageMap("numberWords.ninetySeven", "math"), 98: messageMap("numberWords.ninetyEight", "math"), 99: messageMap("numberWords.ninetyNine", "math"),
	100: messageMap("numberWords.oneHundred", "math")
};

export const ordinalWord = {
	1: messageMap("ordinalWord.one", "math"), 2: messageMap("ordinalWord.two", "math"), 3: messageMap("ordinalWord.three", "math"), 4: messageMap("ordinalWord.four", "math"), 5: messageMap("ordinalWord.five", "math"), 6: messageMap("ordinalWord.six", "math"), 7: messageMap("ordinalWord.seven", "math"), 8: messageMap("ordinalWord.eight", "math"), 9: messageMap("ordinalWord.nine", "math"),
	10: messageMap("ordinalWord.ten", "math"), 11: messageMap("ordinalWord.eleven", "math"), 12: messageMap("ordinalWord.twelve", "math"), 13: messageMap("ordinalWord.thirteen", "math"), 14: messageMap("ordinalWord.fourteen", "math"), 15: messageMap("ordinalWord.fifteen", "math"), 16: messageMap("ordinalWord.sixteen", "math"), 17: messageMap("ordinalWord.seventeen", "math"), 18: messageMap("ordinalWord.eighteen", "math"), 19: messageMap("ordinalWord.nineteen", "math"),
	20: messageMap("ordinalWord.twenty", "math"), 21: messageMap("ordinalWord.twentyOne", "math"), 22: messageMap("ordinalWord.twentyTwo", "math"), 23: messageMap("ordinalWord.twentyThree", "math"), 24: messageMap("ordinalWord.twentyFour", "math"), 25: messageMap("ordinalWord.twentyFive", "math"), 26: messageMap("ordinalWord.twentySix", "math"), 27: messageMap("ordinalWord.twentySeven", "math"), 28: messageMap("ordinalWord.twentyEight", "math"), 29: messageMap("ordinalWord.twentyNine", "math"),
	30: messageMap("ordinalWord.thirty", "math"), 31: messageMap("ordinalWord.thirtyOne", "math"), 32: messageMap("ordinalWord.thirtyTwo", "math"), 33: messageMap("ordinalWord.thirtyThree", "math"), 34: messageMap("ordinalWord.thirtyFour", "math"), 35: messageMap("ordinalWord.thirtyFive", "math"), 36: messageMap("ordinalWord.thirtySix", "math"), 37: messageMap("ordinalWord.thirtySeven", "math"), 38: messageMap("ordinalWord.thirtyEight", "math"), 39: messageMap("ordinalWord.thirtyNine", "math"),
	40: messageMap("ordinalWord.forty", "math"), 41: messageMap("ordinalWord.fortyOne", "math"), 42: messageMap("ordinalWord.fortyTwo", "math"), 43: messageMap("ordinalWord.fortyThree", "math"), 44: messageMap("ordinalWord.fortyFour", "math"), 45: messageMap("ordinalWord.fortyFive", "math"), 46: messageMap("ordinalWord.fortySix", "math"), 47: messageMap("ordinalWord.fortySeven", "math"), 48: messageMap("ordinalWord.fortyEight", "math"), 49: messageMap("ordinalWord.fortyNine", "math"),
	50: messageMap("ordinalWord.fifty", "math"), 51: messageMap("ordinalWord.fiftyOne", "math"), 52: messageMap("ordinalWord.fiftyTwo", "math"), 53: messageMap("ordinalWord.fiftyThree", "math"), 54: messageMap("ordinalWord.fiftyFour", "math"), 55: messageMap("ordinalWord.fiftyFive", "math"), 56: messageMap("ordinalWord.fiftySix", "math"), 57: messageMap("ordinalWord.fiftySeven", "math"), 58: messageMap("ordinalWord.fiftyEight", "math"), 59: messageMap("ordinalWord.fiftyNine", "math"),
	60: messageMap("ordinalWord.sixty", "math"), 61: messageMap("ordinalWord.sixtyOne", "math"), 62: messageMap("ordinalWord.sixtyTwo", "math"), 63: messageMap("ordinalWord.sixtyThree", "math"), 64: messageMap("ordinalWord.sixtyFour", "math"), 65: messageMap("ordinalWord.sixtyFive", "math"), 66: messageMap("ordinalWord.sixtySix", "math"), 67: messageMap("ordinalWord.sixtySeven", "math"), 68: messageMap("ordinalWord.sixtyEight", "math"), 69: messageMap("ordinalWord.sixtyNine", "math"),
	70: messageMap("ordinalWord.seventy", "math"), 71: messageMap("ordinalWord.seventyOne", "math"), 72: messageMap("ordinalWord.seventyTwo", "math"), 73: messageMap("ordinalWord.seventyThree", "math"), 74: messageMap("ordinalWord.seventyFour", "math"), 75: messageMap("ordinalWord.seventyFive", "math"), 76: messageMap("ordinalWord.seventySix", "math"), 77: messageMap("ordinalWord.seventySeven", "math"), 78: messageMap("ordinalWord.seventyEight", "math"), 79: messageMap("ordinalWord.seventyNine", "math"),
	80: messageMap("ordinalWord.eighty", "math"), 81: messageMap("ordinalWord.eightyOne", "math"), 82: messageMap("ordinalWord.eightyTwo", "math"), 83: messageMap("ordinalWord.eightyThree", "math"), 84: messageMap("ordinalWord.eightyFour", "math"), 85: messageMap("ordinalWord.eightyFive", "math"), 86: messageMap("ordinalWord.eightySix", "math"), 87: messageMap("ordinalWord.eightySeven", "math"), 88: messageMap("ordinalWord.eightyEight", "math"), 89: messageMap("ordinalWord.eightyNine", "math"),
	90: messageMap("ordinalWord.ninety", "math"), 91: messageMap("ordinalWord.ninetyOne", "math"), 92: messageMap("ordinalWord.ninetyTwo", "math"), 93: messageMap("ordinalWord.ninetyThree", "math"), 94: messageMap("ordinalWord.ninetyFour", "math"), 95: messageMap("ordinalWord.ninetyFive", "math"), 96: messageMap("ordinalWord.ninetySix", "math"), 97: messageMap("ordinalWord.ninetySeven", "math"), 98: messageMap("ordinalWord.ninetyEight", "math"), 99: messageMap("ordinalWord.ninetyNine", "math"),
	100: messageMap("ordinalWord.oneHundred", "math")
};

export const ordinalNumber = {
	1: messageMap("ordinalNumber.one", "math"), 2: messageMap("ordinalNumber.two", "math"), 3: messageMap("ordinalNumber.three", "math"), 4: messageMap("ordinalNumber.four", "math"), 5: messageMap("ordinalNumber.five", "math"), 6: messageMap("ordinalNumber.six", "math"), 7: messageMap("ordinalNumber.seven", "math"), 8: messageMap("ordinalNumber.eight", "math"), 9: messageMap("ordinalNumber.nine", "math"),
	10: messageMap("ordinalNumber.ten", "math"), 11: messageMap("ordinalNumber.eleven", "math"), 12: messageMap("ordinalNumber.twelve", "math"), 13: messageMap("ordinalNumber.thirteen", "math"), 14: messageMap("ordinalNumber.fourteen", "math"), 15: messageMap("ordinalNumber.fifteen", "math"), 16: messageMap("ordinalNumber.sixteen", "math"), 17: messageMap("ordinalNumber.seventeen", "math"), 18: messageMap("ordinalNumber.eighteen", "math"), 19: messageMap("ordinalNumber.nineteen", "math"),
	20: messageMap("ordinalNumber.twenty", "math"), 21: messageMap("ordinalNumber.twentyOne", "math"), 22: messageMap("ordinalNumber.twentyTwo", "math"), 23: messageMap("ordinalNumber.twentyThree", "math"), 24: messageMap("ordinalNumber.twentyFour", "math"), 25: messageMap("ordinalNumber.twentyFive", "math"), 26: messageMap("ordinalNumber.twentySix", "math"), 27: messageMap("ordinalNumber.twentySeven", "math"), 28: messageMap("ordinalNumber.twentyEight", "math"), 29: messageMap("ordinalNumber.twentyNine", "math"),
	30: messageMap("ordinalNumber.thirty", "math"), 31: messageMap("ordinalNumber.thirtyOne", "math"), 32: messageMap("ordinalNumber.thirtyTwo", "math"), 33: messageMap("ordinalNumber.thirtyThree", "math"), 34: messageMap("ordinalNumber.thirtyFour", "math"), 35: messageMap("ordinalNumber.thirtyFive", "math"), 36: messageMap("ordinalNumber.thirtySix", "math"), 37: messageMap("ordinalNumber.thirtySeven", "math"), 38: messageMap("ordinalNumber.thirtyEight", "math"), 39: messageMap("ordinalNumber.thirtyNine", "math"),
	40: messageMap("ordinalNumber.forty", "math"), 41: messageMap("ordinalNumber.fortyOne", "math"), 42: messageMap("ordinalNumber.fortyTwo", "math"), 43: messageMap("ordinalNumber.fortyThree", "math"), 44: messageMap("ordinalNumber.fortyFour", "math"), 45: messageMap("ordinalNumber.fortyFive", "math"), 46: messageMap("ordinalNumber.fortySix", "math"), 47: messageMap("ordinalNumber.fortySeven", "math"), 48: messageMap("ordinalNumber.fortyEight", "math"), 49: messageMap("ordinalNumber.fortyNine", "math"),
	50: messageMap("ordinalNumber.fifty", "math"), 51: messageMap("ordinalNumber.fiftyOne", "math"), 52: messageMap("ordinalNumber.fiftyTwo", "math"), 53: messageMap("ordinalNumber.fiftyThree", "math"), 54: messageMap("ordinalNumber.fiftyFour", "math"), 55: messageMap("ordinalNumber.fiftyFive", "math"), 56: messageMap("ordinalNumber.fiftySix", "math"), 57: messageMap("ordinalNumber.fiftySeven", "math"), 58: messageMap("ordinalNumber.fiftyEight", "math"), 59: messageMap("ordinalNumber.fiftyNine", "math"),
	60: messageMap("ordinalNumber.sixty", "math"), 61: messageMap("ordinalNumber.sixtyOne", "math"), 62: messageMap("ordinalNumber.sixtyTwo", "math"), 63: messageMap("ordinalNumber.sixtyThree", "math"), 64: messageMap("ordinalNumber.sixtyFour", "math"), 65: messageMap("ordinalNumber.sixtyFive", "math"), 66: messageMap("ordinalNumber.sixtySix", "math"), 67: messageMap("ordinalNumber.sixtySeven", "math"), 68: messageMap("ordinalNumber.sixtyEight", "math"), 69: messageMap("ordinalNumber.sixtyNine", "math"),
	70: messageMap("ordinalNumber.seventy", "math"), 71: messageMap("ordinalNumber.seventyOne", "math"), 72: messageMap("ordinalNumber.seventyTwo", "math"), 73: messageMap("ordinalNumber.seventyThree", "math"), 74: messageMap("ordinalNumber.seventyFour", "math"), 75: messageMap("ordinalNumber.seventyFive", "math"), 76: messageMap("ordinalNumber.seventySix", "math"), 77: messageMap("ordinalNumber.seventySeven", "math"), 78: messageMap("ordinalNumber.seventyEight", "math"), 79: messageMap("ordinalNumber.seventyNine", "math"),
	80: messageMap("ordinalNumber.eighty", "math"), 81: messageMap("ordinalNumber.eightyOne", "math"), 82: messageMap("ordinalNumber.eightyTwo", "math"), 83: messageMap("ordinalNumber.eightyThree", "math"), 84: messageMap("ordinalNumber.eightyFour", "math"), 85: messageMap("ordinalNumber.eightyFive", "math"), 86: messageMap("ordinalNumber.eightySix", "math"), 87: messageMap("ordinalNumber.eightySeven", "math"), 88: messageMap("ordinalNumber.eightyEight", "math"), 89: messageMap("ordinalNumber.eightyNine", "math"),
	90: messageMap("ordinalNumber.ninety", "math"), 91: messageMap("ordinalNumber.ninetyOne", "math"), 92: messageMap("ordinalNumber.ninetyTwo", "math"), 93: messageMap("ordinalNumber.ninetyThree", "math"), 94: messageMap("ordinalNumber.ninetyFour", "math"), 95: messageMap("ordinalNumber.ninetyFive", "math"), 96: messageMap("ordinalNumber.ninetySix", "math"), 97: messageMap("ordinalNumber.ninetySeven", "math"), 98: messageMap("ordinalNumber.ninetyEight", "math"), 99: messageMap("ordinalNumber.ninetyNine", "math"),
	100: messageMap("ordinalNumber.oneHundred", "math")
};

export const romanNumerals = {
	1: messageMap("romanNumerals.one", "math"), 2: messageMap("romanNumerals.two", "math"), 3: messageMap("romanNumerals.three", "math"), 4: messageMap("romanNumerals.four", "math"), 5: messageMap("romanNumerals.five", "math"), 6: messageMap("romanNumerals.six", "math"), 7: messageMap("romanNumerals.seven", "math"), 8: messageMap("romanNumerals.eight", "math"), 9: messageMap("romanNumerals.nine", "math"),
	10: messageMap("romanNumerals.ten", "math"), 11: messageMap("romanNumerals.eleven", "math"), 12: messageMap("romanNumerals.twelve", "math"), 13: messageMap("romanNumerals.thirteen", "math"), 14: messageMap("romanNumerals.fourteen", "math"), 15: messageMap("romanNumerals.fifteen", "math"), 16: messageMap("romanNumerals.sixteen", "math"), 17: messageMap("romanNumerals.seventeen", "math"), 18: messageMap("romanNumerals.eighteen", "math"), 19: messageMap("romanNumerals.nineteen", "math"),
	20: messageMap("romanNumerals.twenty", "math"), 21: messageMap("romanNumerals.twentyOne", "math"), 22: messageMap("romanNumerals.twentyTwo", "math"), 23: messageMap("romanNumerals.twentyThree", "math"), 24: messageMap("romanNumerals.twentyFour", "math"), 25: messageMap("romanNumerals.twentyFive", "math"), 26: messageMap("romanNumerals.twentySix", "math"), 27: messageMap("romanNumerals.twentySeven", "math"), 28: messageMap("romanNumerals.twentyEight", "math"), 29: messageMap("romanNumerals.twentyNine", "math"),
	30: messageMap("romanNumerals.thirty", "math"), 31: messageMap("romanNumerals.thirtyOne", "math"), 32: messageMap("romanNumerals.thirtyTwo", "math"), 33: messageMap("romanNumerals.thirtyThree", "math"), 34: messageMap("romanNumerals.thirtyFour", "math"), 35: messageMap("romanNumerals.thirtyFive", "math"), 36: messageMap("romanNumerals.thirtySix", "math"), 37: messageMap("romanNumerals.thirtySeven", "math"), 38: messageMap("romanNumerals.thirtyEight", "math"), 39: messageMap("romanNumerals.thirtyNine", "math"),
	40: messageMap("romanNumerals.forty", "math"), 41: messageMap("romanNumerals.fortyOne", "math"), 42: messageMap("romanNumerals.fortyTwo", "math"), 43: messageMap("romanNumerals.fortyThree", "math"), 44: messageMap("romanNumerals.fortyFour", "math"), 45: messageMap("romanNumerals.fortyFive", "math"), 46: messageMap("romanNumerals.fortySix", "math"), 47: messageMap("romanNumerals.fortySeven", "math"), 48: messageMap("romanNumerals.fortyEight", "math"), 49: messageMap("romanNumerals.fortyNine", "math"),
	50: messageMap("romanNumerals.fifty", "math"), 51: messageMap("romanNumerals.fiftyOne", "math"), 52: messageMap("romanNumerals.fiftyTwo", "math"), 53: messageMap("romanNumerals.fiftyThree", "math"), 54: messageMap("romanNumerals.fiftyFour", "math"), 55: messageMap("romanNumerals.fiftyFive", "math"), 56: messageMap("romanNumerals.fiftySix", "math"), 57: messageMap("romanNumerals.fiftySeven", "math"), 58: messageMap("romanNumerals.fiftyEight", "math"), 59: messageMap("romanNumerals.fiftyNine", "math"),
	60: messageMap("romanNumerals.sixty", "math"), 61: messageMap("romanNumerals.sixtyOne", "math"), 62: messageMap("romanNumerals.sixtyTwo", "math"), 63: messageMap("romanNumerals.sixtyThree", "math"), 64: messageMap("romanNumerals.sixtyFour", "math"), 65: messageMap("romanNumerals.sixtyFive", "math"), 66: messageMap("romanNumerals.sixtySix", "math"), 67: messageMap("romanNumerals.sixtySeven", "math"), 68: messageMap("romanNumerals.sixtyEight", "math"), 69: messageMap("romanNumerals.sixtyNine", "math"),
	70: messageMap("romanNumerals.seventy", "math"), 71: messageMap("romanNumerals.seventyOne", "math"), 72: messageMap("romanNumerals.seventyTwo", "math"), 73: messageMap("romanNumerals.seventyThree", "math"), 74: messageMap("romanNumerals.seventyFour", "math"), 75: messageMap("romanNumerals.seventyFive", "math"), 76: messageMap("romanNumerals.seventySix", "math"), 77: messageMap("romanNumerals.seventySeven", "math"), 78: messageMap("romanNumerals.seventyEight", "math"), 79: messageMap("romanNumerals.seventyNine", "math"),
	80: messageMap("romanNumerals.eighty", "math"), 81: messageMap("romanNumerals.eightyOne", "math"), 82: messageMap("romanNumerals.eightyTwo", "math"), 83: messageMap("romanNumerals.eightyThree", "math"), 84: messageMap("romanNumerals.eightyFour", "math"), 85: messageMap("romanNumerals.eightyFive", "math"), 86: messageMap("romanNumerals.eightySix", "math"), 87: messageMap("romanNumerals.eightySeven", "math"), 88: messageMap("romanNumerals.eightyEight", "math"), 89: messageMap("romanNumerals.eightyNine", "math"),
	90: messageMap("romanNumerals.ninety", "math"), 91: messageMap("romanNumerals.ninetyOne", "math"), 92: messageMap("romanNumerals.ninetyTwo", "math"), 93: messageMap("romanNumerals.ninetyThree", "math"), 94: messageMap("romanNumerals.ninetyFour", "math"), 95: messageMap("romanNumerals.ninetyFive", "math"), 96: messageMap("romanNumerals.ninetySix", "math"), 97: messageMap("romanNumerals.ninetySeven", "math"), 98: messageMap("romanNumerals.ninetyEight", "math"), 99: messageMap("romanNumerals.ninetyNine", "math"),
	100: messageMap("romanNumerals.oneHundred", "math"),
	500: messageMap("romanNumerals.fiveHundred", "math"),
	1000: messageMap("romanNumerals.oneThousand", "math")
};


