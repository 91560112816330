import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

function StaticImages(props) {

	return (
		<div className={`static-image ${props.staticImageContainerClass}`}>
			<div className={`image ${props.imgContainerClass}`}>
				<img className={props.images.left.class ? props.images.left.class : ""} src={props.images.left.img} alt={props.images.left.alt}></img>
				{
					props.images.right ?
					<img className={props.images.right.class} src={props.images.right.img} alt={props.images.right.alt}></img>
					: null
				}
			</div>
			<div className="image-description">
				{props.description}
			</div>
		</div>
	);
}

StaticImages.propTypes = {
	staticImageContainerClass: PropTypes.string,
	imgContainerClass: PropTypes.string,
	images: PropTypes.object.isRequired,
	description: PropTypes.object
};

export default StaticImages;

