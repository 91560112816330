import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import Spinner from "templates/Spinner";

import messageMap from "Utilities/MessageMaps";
import { AUTO_REDIRECT_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import { saveUserChargeablePaymentMethodAPI } from "apis/controllers/person/PersonPaymentInfoController";


function PaymentMethodSavedConfirmation(props) {

	const history = useHistory();

	useEffect(() => {
		saveChargeablePaymentMethod();

		setTimeout(() => {
			history.push("/profile?show=financials");
		}, AUTO_REDIRECT_TIMEOUT * 1.25);
	}, []);

	function saveChargeablePaymentMethod() {
		const urlParams = new URLSearchParams(window.location.search);
		const pathVariables = {
			method: urlParams.get("method")
		};
		const payload = {
			ownerId: urlParams.get("ownerId"),
			setupIntentId: urlParams.get("setup_intent")
		};
		saveUserChargeablePaymentMethodAPI(pathVariables, payload, resp => {
			// not displaying anything, since this component will only be used if the saving of the payment method was successful
		});
	}

	return (
		<div className="payment-method-saved-confirmation">
			<div className="redirect-message">
				{messageMap("payments.confirmation.savedPaymentThankYou", "generic")}
			</div>

			<Spinner/>
		</div>
	);
}

export default connect(
	account,
)(PaymentMethodSavedConfirmation);
