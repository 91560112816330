import React from "react";

import { messageMapGeneric } from "Utilities/MessageMaps";


function UserSafety(props) {
	return (
		<div>
			<div className="title">
				{messageMapGeneric("tutoringPage.faqs.tutees.userSafety")}
			</div>

			<div className="faq-text">
				{messageMapGeneric("tutoringPage.faqs.tutees.userSafetyDescriptionFull1")}
			</div>
			<div className="faq-text">
				{messageMapGeneric("tutoringPage.faqs.tutees.userSafetyDescriptionFull2")}
			</div>
		</div>
	);
}

export default UserSafety;
