import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/learningMaterials/thumbnailLearningMaterials.jpg";
import learningMaterialsAsset from "assets/topicArticles/LearningOptimizations/learningMaterials/learningMaterials.svg";



export const learningMaterialsLearningMaterialsArticle = {
	id: "IV. Learning Materials_3_2 How to choose the right learning materials",
	title: messageMap("learningMaterials.article.title", "learningOptimization"),
	description: messageMap("learningMaterials.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("learningMaterials.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4", "text5", "text6",
		"text7", "text8",
		"text9", "text10",
		"text11", "text12",
		"text13", "text14",
		"text15", "text16",
		"text17", "text18", "html1", "text19",
		"text20", "text21", "text22", "text23"
	],
	datePublished: "2023-11-30",
	dateModified: "2024-06-24"
};

export const learningMaterialsLearningMaterialsArticleTexts = {
	text1: {
		text: messageMap("learningMaterials.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("learningMaterials.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("learningMaterials.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("learningMaterials.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("learningMaterials.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("learningMaterials.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("learningMaterials.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text8: {
		text: messageMap("learningMaterials.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("learningMaterials.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text10: {
		text: messageMap("learningMaterials.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("learningMaterials.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text12: {
		text: messageMap("learningMaterials.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("learningMaterials.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text14: {
		text: messageMap("learningMaterials.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("learningMaterials.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text16: {
		text: messageMap("learningMaterials.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("learningMaterials.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text18: {
		text: messageMap("learningMaterials.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("learningMaterials.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text21: {
		text: messageMap("learningMaterials.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text22: {
		text: messageMap("learningMaterials.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text23: {
		text: messageMap("learningMaterials.sources.s3", "learningOptimization"),
		type: SOURCE
	}
};

export const learningMaterialsLearningMaterialsArticleHTMLs = {
	html1: (
		<img key="learningMaterialsList" src={learningMaterialsAsset}
			alt="diagram of learning materials and what makes a good learning material"
			style={{width: "-webkit-fill-available"}} />
	)
};

