import React, {useState, useEffect, Fragment, useRef} from 'react';
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import videojs from "video.js";

import payingAccount from "redux/selectors/payingAccountSelector";

import Alert, {SUCCESS} from "templates/Alert";
import Pagination from "templates/Pagination";
import Spinner from "templates/Spinner";
import Modal from "templates/Modal";
import ConfirmModal from "templates/customModals/ConfirmModal";
import Module from "pages/Classes/Videos/components/Module";

import messageMap from "Utilities/MessageMaps";
import { MODAL_CLOSE_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';
import { onKeyDown } from "Utilities/Accessibility";
import {CATEGORIES} from "Utilities/Constants/MediaConstants";
import {getViewCount, goToPageHandler, generateStructuredData, getPriceInShinyNeurons } from "pages/Classes/Videos/VideoListUtilities";
import {getTableHeaderSchema, getRowSchema,
				getListAllAPI, getEntriesCountAPI, getMetadataAPI, getDeleteAPI,
				getRowLogicValues, getEditModal} from "pages/Classes/Videos/utilities/EditVideosListUtilities";

import {editUploadedVideoAPI, getVideoHLSResourceAPI} from "apis/controllers/video/VideoController";

import deleteAsset from "assets/icons/common/delete.svg";
import editAsset from "assets/icons/common/edit.svg";
import moduleAsset from "assets/icons/video/bundling/module.svg";
import classAsset from "assets/icons/video/bundling/class.svg";
import filterAsset from "assets/icons/common/filter.svg";

function EditVideosList(props) {

	const urlLocation = window.location.href;
	const paginationRegEx = new RegExp("(?<=page=)[0-9]");

	const [alert, setAlert] = useState(null),
				[spinner, setSpinner] = useState(""),
				[modal, setModal] = useState(),
				// will be used for infinite scrolling and pagination
				[currentPage, setCurrentPage] = useState(
					urlLocation.includes("page") ? paginationRegEx.exec(urlLocation)[0] - 1 : 0
				),
				[pageSize, setPageSize] = useState(10),
				[totalPageCount, setTotalPageCount] = useState(0),
				// DOM view states
				[pageNumbersDom, setPageNumbersDom] = useState(null),
				[videosDom, setVideosDom] = useState([]),
				[confirmUpdate, setConfirmUpdate] = useState(null),
				// json-ld
				[structuredListJSON, setStructuredListJSON] = useState();

	// value refs
	const checkboxRefs = useRef({}),
				filterValueRef = useRef("videos"),
				checkedEntityIDsRef = useRef([]),
				pendingEditsRef = useRef({}),
				earningsRef = useRef(0),
				// DOM refs
				autoSaveTimeoutRef = useRef({}),
				editButtonRef = useRef(),
				editButtonTextRef = useRef(),
				deleteButtonRef = useRef(),
				deleteButtonTextRef = useRef(),
				moduleRef = useRef(),
				classRef = useRef(),
				videoRef = useRef(),
				videoSrcRef = useRef(),
				subtitleSrcRef = useRef(),
				filterRef = useRef(),
				dropdownRef = useRef(),
				videosRadioRef = useRef(),
				modulesRadioRef = useRef(),
				classesRadioRef = useRef(),
				totalEarningsRef = useRef();

	const history = useHistory();

	useEffect(() => {
		renderTableDom();
		createPageNavigation();
		// TODO: revisit infinite scrolling later
		// checkCurrentScrollPosition();
	}, [currentPage, props.shinyNeuronsConversionRate]);

	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	function savePendingEdits(videoId, clearEdit) {
		let editPathVariables = {
			ownerId: ownerId,
			videoId: videoId
		};

		let formPayload = {
			...pendingEditsRef.current[videoId],
			category: CATEGORIES.TEACH
		};

		editUploadedVideoAPI(editPathVariables, formPayload, Object.keys(formPayload), resp => {
			setAlert(
				<Alert type={SUCCESS} closeHandler={closeAlert}
								msg={messageMap(resp["SUCCESS"], "api")}/>
			);

			if (clearEdit == true)
				delete pendingEditsRef.current[videoId];
		});
	}

	function updatePendingEdits(videoId, field, val, timeOut) {
		// Park pending edits.
		pendingEditsRef.current[videoId] = pendingEditsRef.current[videoId] || {};
		if (field != null && val != null) {
			pendingEditsRef.current[videoId][field] = val;
		}
		// Auto-save handling.
		if (autoSaveTimeoutRef.current[videoId]) {// Clear old timers.
			clearTimeout(autoSaveTimeoutRef.current[videoId]);
		}
		if (timeOut) {// Set up a new timer if a timeOut was passed.
			autoSaveTimeoutRef.current[videoId] = setTimeout(() => savePendingEdits(videoId), timeOut);
		}
		else {
			savePendingEdits(videoId);
		}
	}
	function createVideosDomBlocks() {
		const pathVariables = {
			pageCount: currentPage,
			pageSize: pageSize,
			downloadTypes: ["thumbnail", "mentalModel"]
		};
		const payload = {
			ownerId: ownerId,
			// fields that the UI wants to display
			// although not indicated, we're also going to allow the user to replace their uploaded video file
			filters: [
				"id",
				"title",
				"uploadDate",
				"duration",
				"mentalModelIds",
				"isPublic",
				"thumbnailUploadLocation", 
				"thumbnailDescription",
				"thumbnailFileType",
				"viewCount",
				"price",
				"earnings",
				// for indicating that the API is getting called from edit video page
				"editVideoPage"
			]
		};
		const spinnerContainerStyle = {
			position: "absolute",
			margin: "auto",
			left: "0",
			right: "0"
		};
		setSpinner(<Spinner spinnerContainerStyle={spinnerContainerStyle}
												loadingText={messageMap("spinner.fetch.getting", "button")}/>);
		getListAllAPI(filterValueRef.current)(pathVariables, payload, response => {
			renderTable(response);
			setSpinner(null);
			setStructuredListJSON(response.length ? generateStructuredData(response, filterValueRef.current) : null);
		});
	}

	function createModulesDomBlocks() {
		const pathVariables = {
			pageCount: currentPage,
			pageSize: pageSize
		};
		const payload = {
			ownerId: ownerId
		};
		const spinnerContainerStyle = {
			position: "absolute",
			margin: "auto",
			left: "0",
			right: "0"
		};

		setSpinner(<Spinner spinnerContainerStyle={spinnerContainerStyle}
												loadingText={messageMap("spinner.fetch.getting", "button")}/>);
		getListAllAPI(filterValueRef.current)(pathVariables, payload, response => {
			renderTable(response);
			setSpinner(null);
			setStructuredListJSON(response.length ? generateStructuredData(response, filterValueRef.current) : null);
		});
	}

	function renderTable(resp) {
		earningsRef.current = 0;
		checkboxRefs.current = {};
		let tableHeader = [
			messageMap("video.editPage.videosTableHeaders.title", "generic"),
			messageMap("video.editPage.videosTableHeaders.mentalModel", "generic"),
			messageMap("video.editPage.videosTableHeaders.uploadDate", "generic"),
			messageMap("video.editPage.videosTableHeaders.viewCount", "generic"),
			messageMap("video.editPage.videosTableHeaders.pricing", "generic"),
			messageMap("video.editPage.videosTableHeaders.earnings", "generic")
			// TODO: uncomment once we have paid videos
			// , "Free / Paid"
		];

		if (filterValueRef.current === "modules") {
			tableHeader = [
				messageMap("video.editPage.videosTableHeaders.title", "generic"),
				messageMap("video.editPage.videosTableHeaders.uploadDate", "generic")
			];
		}

		let headerDom = [],
				tableBody = [];
		const respLength = resp.length;
		const thCheckboxClass = respLength ? "header" : "header invert";
		let headerCheckbox;

		if (respLength) {
			headerCheckbox = (
				<input type="checkbox" className={thCheckboxClass} ref={(el) => (checkboxRefs.current[0] = el)}
							onClick={e => handleCheckbox(0, respLength)}
							onKeyPress={e => onKeyDown(e, handleCheckbox, [0, respLength], true)}/>
			);
		}
		else {
			headerCheckbox = (
				<input type="checkbox" checked={false} className={thCheckboxClass} ref={(el) => (checkboxRefs.current[0] = el)}
							onClick={e => handleCheckbox(0, respLength)}
							onKeyPress={e => onKeyDown(e, handleCheckbox, [0, respLength], true)}/>
			);
		}

		headerDom.push(
			<th key="checkbox">
				{headerCheckbox}
			</th>
		);
		tableHeader.forEach(label => {
			headerDom.push(<th key={label} className="header-text">
				{label}
			</th>);
		});

		resp.forEach((el, index) => {
			const iteratorValues = {
				item: el,
				index: index
			};
			let onClickHandlers = {
				handleCheckboxHandler: handleCheckbox,
				openEntityThumbnailModalHandler: openVideoModal,
				openMentalModelModalHandler: openMentalModelModal,
				getViewCountHandler: getViewCount,
				currencySymbol: props.currencySymbol,
				getPriceInShinyNeurons: getPriceInShinyNeurons,
				updatePendingEdits: updatePendingEdits
			};

			if (filterValueRef.current === "modules") {
				delete onClickHandlers["openEntityThumbnailModalHandler"];
			}

			earningsRef.current += el.earnings;

			const domRefs = {
				checkboxRefs: checkboxRefs
			};
			tableBody.push(getRowSchema(filterValueRef.current)(
				iteratorValues,
				getRowLogicValues(filterValueRef.current)(el),
				onClickHandlers,
				domRefs,
				props.shinyNeuronsConversionRate
			));
		});

		setVideosDom(
			<Fragment>
				<table className="table-list">
					<thead>
						<tr>
							{headerDom}
						</tr>
					</thead>
					<tbody>
						{tableBody}
					</tbody>
				</table>
			</Fragment>
		);
	}

	function handleCheckbox(checkboxIndex) {
		checkedEntityIDsRef.current = [];

		let currentCheckbox = checkboxRefs.current;
		const chosenCheckbox = currentCheckbox[checkboxIndex];
		const numCheckboxes = Object.keys(currentCheckbox);

		if (chosenCheckbox.getAttribute("class") === "header") {
			if (chosenCheckbox.checked) {
				let checkedRows = 0;

				for (let i = 1; i < numCheckboxes.length; ++i) {
					const curCheckbox = currentCheckbox[i];
					if (curCheckbox !== null) {
						curCheckbox.checked = true;
						++checkedRows;

						const entityId = curCheckbox.getAttribute("entityId");
						if (entityId) {
							checkedEntityIDsRef.current.push(entityId);
						}
					}
				}

				toggleFilterToChildNodes(deleteButtonRef.current, "delete-container", "delete-action", "");
				toggleFilterToChildNodes(editButtonRef.current, "edit-container invert", "edit-action invert", "invert");

				if (checkedRows > 20) {
					toggleFilterToChildNodes(moduleRef.current, "module-container invert", "module-action invert", "invert");
				}
				else {
					toggleFilterToChildNodes(moduleRef.current, "module-container", "module-action", "");
				}
			}
			else {
				for (let i = 1; i < numCheckboxes.length; ++i) {
					if (currentCheckbox[i] !== null) {
						currentCheckbox[i].checked = false;
					}
				}

				toggleFilterToChildNodes(deleteButtonRef.current, "delete-container invert", "delete-action invert", "invert");
				toggleFilterToChildNodes(moduleRef.current, "module-container invert", "module-action invert", "invert");
			}
		}
		else {
			currentCheckbox[0].checked = false;
			let numChecked = 0;

			for (let i = 1; i < numCheckboxes.length; ++i) {
				const curCheckbox = currentCheckbox[i];
				if (curCheckbox !== null && curCheckbox.checked) {
					checkedEntityIDsRef.current.push(curCheckbox.getAttribute("entityId"));
					++numChecked;
				}
			}

			if (numChecked) {
				toggleFilterToChildNodes(deleteButtonRef.current, "delete-container", "delete-action", "");
				
				if (numChecked > 1) {
					toggleFilterToChildNodes(editButtonRef.current, "edit-container invert", "edit-action invert", "invert");
					
					if (numChecked > 20) {
						toggleFilterToChildNodes(moduleRef.current, "module-container invert", "module-action invert", "invert");
					}
					else {
						toggleFilterToChildNodes(moduleRef.current, "module-container", "module-action", "");
					}
				}
				else {
					toggleFilterToChildNodes(editButtonRef.current, "edit-container", "edit-action", "");
				}
			}
			else {
				toggleFilterToChildNodes(editButtonRef.current, "edit-container invert", "edit-action invert", "invert");
				toggleFilterToChildNodes(deleteButtonRef.current, "delete-container invert", "delete-action invert", "invert");
				toggleFilterToChildNodes(moduleRef.current, "module-container invert", "module-action invert", "invert");
			}
		}
	}

	function toggleFilterToChildNodes(targetNode, toClassName, toChildClassName1, toChildClassName2) {
		targetNode.className = toClassName;
		targetNode.childNodes[0].className = toChildClassName1;
		targetNode.childNodes[1].className = toChildClassName2;
	}

	function openVideoModal(videoId) {
		const pathVariables = {
			videoId: videoId
		};
		const spinnerContainerStyle = {
			position: "absolute",
			margin: "auto",
			left: "0",
			right: "0"
		};

		setSpinner(
			<Spinner spinnerContainerStyle={spinnerContainerStyle}
							loadingText={messageMap("spinner.fetch.loading", "button")}/>
		);

		getVideoHLSResourceAPI(pathVariables, ownerId, resp => {
			const modalContainerStyle = {
				maxWidth: 1280,
				padding: 0,
				width: "auto",
				height: 720,
			};

			setModal(
				<Modal closeHandler={e => closeModal(e)} closeType="xButton" modalContainerStyle={modalContainerStyle}>
					<video ref={videoRef} className="video-js"
									controls controlsList="nodownload"
									disablePictureInPicture>
						<source ref={videoSrcRef} type="application/x-mpegURL" />
						<track default kind="captions" srcLang="en"/>
						{messageMap("video.browserSupport", "generic")}
					</video>
				</Modal>
			);
			setSpinner(null);

			// subtitleSrcRef.current.src = resp["subtitles"];
			videoSrcRef.current.src = resp["hls"];
			videoSrcRef.current = videojs(videoRef.current, {
				responsive: true
			});
			// DO NOT UNCOMMENT THIS CODE AND REMOVE THIS COMMENT. WE DON'T AUTOMATICALLY PLAY VIDEOS; IT'S AGAINST ACCESSIBILITY RULES
			// videoSrcRef.current.play();
		});
	}

	function openMentalModelModal(byteFile) {
		const titleStyle = {
			fontSize: "24px",
			lineHeight: "normal",
			marginBottom: "24px"
		};
		const modalContainerStyle = {
			maxWidth: 1280,
			padding: "32px",
			height: "auto",
			fontFamily: 'Montserrat-Regular',
			backgroundColor: "#fff",
			borderRadius: "16px"
		};
		const closeButtonStyle = {
			marginTop: "-20px",
			marginRight: "-20px"
		};

		setModal(
			<Modal closeHandler={e => closeModal(e)} closeType="xButton"
							modalContainerStyle={modalContainerStyle}
							titleStyle={titleStyle} closeButtonStyle={closeButtonStyle}
							title={messageMap("learnPage.mentalModel.title", "generic")}>
				<img className="mentalModel"
						// TODO: Create mental model image description for accessibility (This should be populated by the user). Using placeholder for now
						src={byteFile} alt={"A mental model of the concepts discussed in the video"}></img>
			</Modal>
		);
	}

	function closeConfirmModal(e, closeArgs) {
		if (e !== null && ["cancel"].includes(e.target.className)) {
			setConfirmUpdate(null);
		}
		else if (closeArgs !== undefined) {
			deleteItems(closeArgs)
		}
	}

	function closeModal(e) {
		const target = e.target;
		const customiconid = target.getAttribute("customiconid");

		if (["modal-block", "icon", "close-button"].includes(target.className) && !["upload", "validation", "edit"].includes(customiconid)) {
			setTimeout(() => {
				setModal(null);
			}, MODAL_CLOSE_TIMEOUT);

			renderTableDom();
		}
	}

	function closeAlert() {
		setAlert(null);
	}

	function toggleFilterDropdown() {
		if (dropdownRef.current.className.includes("hide")) {
			dropdownRef.current.className = "filter-dropdown";
		}
		else {
			dropdownRef.current.className = "filter-dropdown hide";
		}
	}

	function filterList(e) {
		const radioValue = e.target.value;

		unCheckOtherRadios(radioValue);
		changeActionButtonLabels();
		toggleFilterDropdown();
		setCurrentPage(0);
		renderTableDom();
		createPageNavigation();
	}

	function changeActionButtonLabels() {
		if (filterValueRef.current === "videos") {
			editButtonTextRef.current.innerText = messageMap("editVideoPage.videos.edit", "button");
			deleteButtonTextRef.current.innerText = messageMap("editVideoPage.videos.delete", "button");

			moduleRef.current.className = "module-container invert";
		}
		else if (filterValueRef.current === "modules") {
			editButtonTextRef.current.innerText = messageMap("editVideoPage.modules.edit", "button");
			deleteButtonTextRef.current.innerText = messageMap("editVideoPage.modules.delete", "button");
			
			moduleRef.current.className = "module-container invert hide";
		}
	}

	// TODO: uncomment 'renderModuleThumbnailBackground' when we start supporting classes
	function unCheckOtherRadios(checkRadio) {
		filterValueRef.current = checkRadio;

		if (checkRadio === "videos") {
			modulesRadioRef.current.checked = false;
			// classesRadioRef.current.checked = false;
			totalEarningsRef.current.className = "total-earnings";
		}
		else if (checkRadio === "modules") {
			videosRadioRef.current.checked = false;
			// classesRadioRef.current.checked = false;
			totalEarningsRef.current.className = "total-earnings hide";
		}
		else if (checkRadio === "classes") {
			videosRadioRef.current.checked = false;
			modulesRadioRef.current.checked = false;
			totalEarningsRef.current.className = "total-earnings hide";
		}
	}

	function confirmDeletion(e) {
		const target = e.target;

		if (!target.className.includes("invert")) {
			let currentCheckboxObj = checkboxRefs.current;
			const numCheckboxes = Object.keys(currentCheckboxObj);
			let checkedEntityIds = [],
					videoTitles = [];
			for (let i = 1; i < numCheckboxes.length; ++i) {
				const currentCheckbox = currentCheckboxObj[i];
				if (currentCheckbox !== null && currentCheckbox.checked) {
					checkedEntityIds.push(currentCheckbox.getAttribute("entityId"));

					let videoTitle = currentCheckbox.getAttribute("videotitle");
					if (videoTitle.length > 25) {
						videoTitle = videoTitle.substring(0, 21) + "...";
					}
					videoTitles.push(videoTitle);
				}
			}

			setConfirmUpdate(
				<ConfirmModal title={messageMap("video.action.delete", "validation")}
													subHeader={messageMap("video.action.deleteSubheader", "validation") + videoTitles.join(", ")}
													closeArgs={checkedEntityIds}
													closeModal={closeConfirmModal}
													confirmType="verify"/>
			);
		}
	}

	function editEntityMeta(e) {
		const target = e.target;

		if (target.className.includes("invert")) {
			return;
		}

		let currentCheckbox = checkboxRefs.current;
		const numCheckboxes = Object.keys(currentCheckbox);
		let entityId;

		for (let i = 0; i < numCheckboxes.length; ++i) {
			let currentDom = currentCheckbox[i];

			if (currentDom && currentDom.checked) {
				entityId = currentDom.getAttribute("entityId");
			}
		}

		let pathVariables = {
			videoId: entityId
		};
		let videoPayload = {
			ownerId: ownerId,
			filters: [
				"id",
				"title",
				"description",
				"videoUploadLocation",
				"thumbnailUploadLocation", 
				"thumbnailDescription",
				"mentalModelIds",
				"topicIDs",
				"subtitleUploadLocation",
				"viewCount",
				"price",
				"earnings"
			]
		};

		if (filterValueRef.current === "modules") {
			delete pathVariables.videoId;
			pathVariables["moduleId"] = entityId;

			delete videoPayload.filters;
			videoPayload["filters"] = [
				"id",
				"ownerId",
				"moduleName",
				"description",
				"videoIds"
			];
		}

		getMetadataAPI(filterValueRef.current)(pathVariables, videoPayload, resp => {
			setModal(
				getEditModal(filterValueRef.current)(resp, closeModal)
			);
		});

	}

	function bundleVideo(e) {
		const target = e.target;

		if (!target.className.includes("invert")) {
			const modalContainerStyle = {
				maxWidth: 1280,
				padding: "20px",
				width: "500px"
			};
			const titleStyle = {
				marginBottom: "10px"
			};
	
			setModal(
				<Modal title="Module Name" closeHandler={closeModal} closeType="xButton"
								modalContainerStyle={modalContainerStyle} titleStyle={titleStyle}>
					<Module videoIds={checkedEntityIDsRef.current}
												creatorUsername={props.username}/>
				</Modal>
			);
		}
	}

	function renderTableDom() {
		setPageSize(10);

		if (filterValueRef.current === "videos") {
			createVideosDomBlocks();
		}
		else if (filterValueRef.current === "modules") {
			createModulesDomBlocks();
		}
	}

	function deleteItems(entityIdsToDelete) {
		const spinnerContainerStyle = {
			position: "absolute",
			margin: "auto",
			left: "0",
			right: "0"
		};
		setSpinner(<Spinner spinnerContainerStyle={spinnerContainerStyle}
												loadingText={messageMap("spinner.action.delete", "button")}/>);

		let pathVariables = {
			videoIds: entityIdsToDelete,
			category: CATEGORIES.TEACH
		};

		if (filterValueRef.current === "modules") {
			pathVariables = {
				moduleId: entityIdsToDelete
			};
		}

		getDeleteAPI(filterValueRef.current)(pathVariables, resp => {
			setSpinner(null);
			setConfirmUpdate(null);
			setCurrentPage(0);
			renderTableDom();
			setAlert(
				<Alert type={SUCCESS} closeHandler={closeAlert}
								msg={messageMap(resp, "api")}/>
			);
		});
	}

	function videoClickHandler(videoData) {
		let urlTitle = videoData.title;
		urlTitle = "/learn?id=" + videoData.videoId + "&channel=" + videoData.uploaderUsername + "&title=" + videoData.title.replaceAll(" ", "_");

		window.scrollTo(0, 0);
		history.push({
			pathname: urlTitle,
			state: {
				videoId: videoData.videoId
			}
		});
	}

	function createPageNavigation() {
		getEntriesCountAPI(filterValueRef.current)(ownerId, response => {
			const numberOfPages = response / pageSize;
			const finalPageNumber = Math.ceil(numberOfPages);

			setTotalPageCount(finalPageNumber);

			if (finalPageNumber !== 0) {
				setPageNumbersDom(
					<Pagination baseUrl="/edit-videos" itemCount={response} currentPage={currentPage}
											pageSize={pageSize} totalPageCount={finalPageNumber}
											pageHandler={goToPage}
					/>);
			}
		});
	}

	function goToPage(pageNumber, totalPageNumber) {
		goToPageHandler(pageNumber, currentPage, totalPageNumber, setCurrentPage);
	}

	return (
		<div className="edit-videos-list-container">
			<Helmet>
				<title>{messageMap("editVideos.title", "headerTag")}</title>
				<meta name="description" content={messageMap("editVideos.description", "headerTag")}></meta>
				<script type="application/ld+json">{structuredListJSON}</script>
			</Helmet>

			{alert}
			{spinner}

			<div className="edit-videos-list">
				<div className="fixed-upper-container">
					<h1 className="title">
						{messageMap("video.editPage.title", "generic")}
					</h1>

					<div className="list-container">
						<div className="actions-container">
							<button className="edit-container invert" ref={editButtonRef}
											onClick={editEntityMeta}>
								<img className="edit-action invert"
											src={editAsset} alt={messageMap("rowActions.edit", "image")}></img>
								<div className="invert" ref={editButtonTextRef}>
									{messageMap("editVideoPage.videos.edit", "button")}
								</div>
							</button>
							<button className="delete-container invert" ref={deleteButtonRef}
											onClick={confirmDeletion}>
								<img className="delete-action invert"
											src={deleteAsset} alt={messageMap("rowActions.delete", "image")}></img>
								<div className="invert" ref={deleteButtonTextRef}>
									{messageMap("editVideoPage.videos.delete", "button")}
								</div>
							</button>
							<button className="module-container invert" ref={moduleRef}
											onClick={bundleVideo}>
								<img className="module-action invert"
										src={moduleAsset} alt={messageMap("rowActions.module", "image")}></img>
								<div className="invert">
									{messageMap("editVideoPage.modules.bundle", "button")}
								</div>
							</button>

							<div className="filter-container">
								<button className="filter-video" onClick={toggleFilterDropdown}>
									<img className="delete-action" ref={filterRef}
												src={filterAsset} alt={messageMap("rowActions.module", "image")}
												aria-describedby="filterTooltip"></img>
									{messageMap("editVideoPage.filter", "button")}
								</button>
								<div className="filter-dropdown hide" ref={dropdownRef}>
									<fieldset>
										<div>
											<input type="radio" name="videos" id="videos" value="videos"
															defaultChecked ref={videosRadioRef}
															onClick={filterList}
															onKeyPress={e => onKeyDown(e, filterList, [e], true)}></input>
											<label htmlFor="videos">
												{messageMap("video.editPage.dropdown.videos", "generic")}
											</label>
										</div>
										<div>
											<input type="radio" name="modules" id="modules" value = "modules"
															ref={modulesRadioRef}
															onClick={filterList}
															onKeyPress={e => onKeyDown(e, filterList, [e], true)}></input>
											<label htmlFor="modules">
												{messageMap("video.editPage.dropdown.modules", "generic")}
											</label>
										</div>
										{/* TODO: uncomment once we're supporting classes */}
										{/* <div>
											<input type="radio" name="classes" id="classes" value="classes"
															ref={classesRadioRef}
															onClick={filterList}
															onKeyPress={e => onKeyDown(e, filterList, [e], true)}></input>
											<label htmlFor="classes">
												{messageMap("video.editPage.dropdown.classes", "generic")}
											</label>
										</div> */}
									</fieldset>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="relative-bottom-container">
					{videosDom}
				</div>
				<div className="total-earnings" ref={totalEarningsRef}>
					{messageMap("video.editPage.totalEarnings", "generic")} = {props.currencySymbol}{earningsRef.current}
				</div>
				{pageNumbersDom}
			</div>

			{modal}
			{confirmUpdate}
		</div>
	);
}

EditVideosList.defaultProps = {
	currencySymbol: "$"
};

EditVideosList.propTypes = {
	// redux props
	ownerId: PropTypes.string,
	username: PropTypes.string,
	currencySymbol: PropTypes.string,
	shinyNeuronsConversionRate: PropTypes.number
};

export default connect(
	payingAccount,
	null
)(EditVideosList);