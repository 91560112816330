import {getRandomNumberBetween} from "pages/Classes/Practice/utilities/MathUtilities";

/**** Experience & Level Calculation *****/
// Each topic will be classified as one of the 3 difficulties to help
// determine which experience gain function to use. We made this design choice to encourage
// learners to tackle harder questions, since these questions help simulate how concepts
// are actually applied in real-life scenarios.
export const HARD = "hard";
export const MEDIUM = "medium";
export const EASY = "easy";

export const LEVEL_EXP_DIFFERENCE = 150;

export function hardExpGain(topicLevel, currentLevel) {
	return Math.floor(((25 * (topicLevel / currentLevel)) / 100) * LEVEL_EXP_DIFFERENCE);
}
export function mediumExpGain(topicLevel, currentLevel) {
	return Math.floor(((21 * (topicLevel / currentLevel)) / 100) * LEVEL_EXP_DIFFERENCE);
}
export function easyExpGain(topicLevel, currentLevel) {
	return Math.floor(((15 * (topicLevel / currentLevel)) / 100) * LEVEL_EXP_DIFFERENCE);
}
// To help determine how much additional experience a user needs to level up
// We're not doing log or exponential experience calculation, which is the most common models,
// because our experience goal for including levels and experience gain feedback is to serve as
// a form motivation for learners to keep learning, especially when they start tackling challenging
// problems in higher levels
export function calculateExpForLevel(level) {
	return 150 * (level - 1);
}


/*********** Reward Calculation **********/

// Reward 1 - 3 hints per module
export function calculateHintsToReward() {
	return getRandomNumberBetween(1, 3);
}

// Smallest class has 3 modules, highest is 12
export function calculateAnswersToReward(numModules) {
	return Math.round(numModules / 5);
}

// This'll guarantee at least 1 free Level per class
export function calculateLevelToReward(numModules) {
	return Math.round(numModules / 25);
}

