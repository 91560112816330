import React from "react";

import messageMap from "Utilities/MessageMaps";

export const correctFormFieldsInRed = (
  <div className="error-msg">
    {messageMap("account.signUp.errorMsg.msg", "generic")}
    <span>
      {messageMap("account.signUp.errorMsg.highlight", "generic")}
    </span>
  </div>
);