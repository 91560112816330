import React, {useEffect, useState, Fragment} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setProfileSubNav } from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";

import Alert, {SUCCESS, ERROR} from "templates/Alert";
import Tooltip from "templates/Tooltip";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";
import { ACCOUNT } from "templates/utilities/PageSubMenu";

import { 
	getUserPaymentInfoAPI, getUserChargeablePaymentMethodAPI
} from "apis/controllers/person/PersonPaymentInfoController";
import { withdrawEarningsAPI } from "apis/controllers/transactions/PayoutsController";

import labelInfoAsset from "assets/icons/common/labelInfo.svg";
import creditCardAsset from "assets/images/payments/creditCard.svg";
import cashAppAsset from "assets/images/payments/cashApp.svg";
import amazonPayAsset from "assets/images/payments/amazonPay.svg";
import bancontactAsset from "assets/images/payments/bancontact.svg";
import iDealAsset from "assets/images/payments/iDeal.svg";


function FinancialsSubPage(props) {

	const ownerId = localStorage.getItem("ownerId");

	// DON'T CHANGE THE KEYS! They map directly to Stripe's payment method identifier
	const paymentMethodMap = {
		card: creditCardAsset,
		cashapp: cashAppAsset,
		amazon_pay: amazonPayAsset,
		bancontact: bancontactAsset,
		ideal: iDealAsset
	};

	// Value states
	const [hasChargeablePaymentMethods, setHasChargeablePaymentMethods] = useState(null);
	const [stripeConnectPayoutMethod, setStripeConnectPayoutMethod] = useState(null),
		[isConnectAccountVerified, setIsConnectAccountVerified] = useState(false);

	const [earnings, setEarnings] = useState(props.earnings);

	// DOM states
	const [payoutMethodTooltip, setPayoutMethodTooltip] = useState(),
		[withdrawTooltip, setWithdrawTooltip] = useState(),
		[earningsTooltip, setEarningsTooltip] = useState();
	const [withdrawButtonClickable, setWithdrawButtonClickable] = useState("not-allowed");

	useEffect(() => {
		checkIfUserHasChargeablePaymentMethod();
		checkStripeConnectAccountStatus();
	}, []);

	function checkIfUserHasChargeablePaymentMethod() {
		getUserChargeablePaymentMethodAPI(ownerId, resp => {
			setHasChargeablePaymentMethods(resp);
		});
	}

	function checkStripeConnectAccountStatus() {
		getUserPaymentInfoAPI(ownerId, resp => {
			let hasPayoutMethod = false;
			if (resp.lastFourCardNumber != null) {
				hasPayoutMethod = true;
				setStripeConnectPayoutMethod("card");
			}
			else if (resp.lastFourAccountNumber != null) {
				hasPayoutMethod = true;
				setStripeConnectPayoutMethod("bank");
			}

			const isAccountVerified = resp.isAccountVerified;
			if (hasPayoutMethod && isAccountVerified && earnings > props.minimumCurrencyWithdrawal) {
				setWithdrawButtonClickable("");
			}
			setIsConnectAccountVerified(isAccountVerified);
		});
	}

	function withdrawEarnings() {
		if (["card", "bank"].includes(stripeConnectPayoutMethod) && withdrawButtonClickable === "") {
			withdrawEarningsAPI(ownerId, resp => {
				let allMsgs = [];
				let msgType = "";

				if (resp["SUCCESS"]) {
					msgType = SUCCESS;
					resp["SUCCESS"].split("_").forEach(msg => {
						allMsgs.push(messageMap(msg, "api"));
					});
				}
				else if (resp["ERROR"]) {
					msgType = ERROR;
					resp["ERROR"].split("_").forEach(msg => {
						allMsgs.push(messageMap(msg, "api"));
					});
				}

				props.setAlert(
					<Alert msg={allMsgs.join(" ")} closeHandler={props.closeAlert} type={msgType} />
				);
				setEarnings(resp["EARNINGS"]);
			});
		}
	}

	function togglePayoutMethodTooltip(showOrHide) {
		if (showOrHide === "show") {
			setPayoutMethodTooltip(
				<Tooltip classStr="tooltip-bottom-left tutoring" subheader={messageMap("financials.stripeConnect", "tooltip")} />
			);
		}
		else {
			setPayoutMethodTooltip(null);
		}
	}
	function toggleWithdrawTooltip(showOrHide) {
		if (withdrawButtonClickable === "not-allowed") {
			if (showOrHide === "show") {
				let tooltip = messageMap("financials.stripeLinking", "tooltip");
				if (!isConnectAccountVerified) {
					tooltip = messageMap("financials.unverifiedStripeConnect", "tooltip");
				}
				else if (earnings < props.minimumCurrencyWithdrawal) {
					tooltip = `${messageMap("financials.belowMinimumWithdrawal", "tooltip")} $${props.minimumCurrencyWithdrawal}`;
				}

				setWithdrawTooltip(
					<Tooltip classStr="tooltip-bottom-middle financials" subheader={tooltip}
						ariaReference="accountLinkingTooltip" />
				);
			}
			else {
				setWithdrawTooltip(null);
			}
		}
	}
	function toggleEarningsTooltip(showOrHide) {
		if (showOrHide === "show") {
			setEarningsTooltip(
				<Tooltip classStr="tooltip-bottom-left earnings" subheader={messageMap("financials.earnings", "tooltip")} />
			);
		}
		else {
			setEarningsTooltip(null);
		}
	}

	function navigateToSetupConnectAccount() {
		defaultLinkAction();
		props.setProfileSubNav(ACCOUNT);
	}

	return (
		<div>
			<h1 className="page-title">
				{messageMap("profile.financials", "subPageNavigation")}
			</h1>

			<div className="page-field">
				<label className="page-field-label financial-label">
					{messageMap("profilePage.financials.fields.paymentMethod", "generic")}
				</label>

				<div className="inline-elements">
					{
						!hasChargeablePaymentMethods
						? (
							<Link to="/payment-method" className="page-field update-field add-payment"
								onClick={defaultLinkAction}>
								{messageMap("financials.addPayment", "button")}
							</Link>
						)
						: (
							<Fragment>
								<img className="payment-method" src={paymentMethodMap[hasChargeablePaymentMethods]} alt={messageMap("tutoring.greenCheck", "image")} />
								{messageMap(`payments.saveMethod.${hasChargeablePaymentMethods}`, "generic")}
								<Link to="/payment-method" className="page-field update-field change-payment left-margin"
									onClick={defaultLinkAction}>
									{messageMap("financials.changePayment", "button")}
								</Link>
							</Fragment>
						)
					}
				</div>
			</div>

			<div className="page-field">
				<label className="page-field-label financial-label">
					<div className="div-label">
						{messageMap("profilePage.financials.fields.payoutMethod", "generic")}
					</div>
					<img src={labelInfoAsset} alt="information" tabIndex={0}
						onMouseOver={e => togglePayoutMethodTooltip("show")} onFocus={e => togglePayoutMethodTooltip("show")}
						onMouseLeave={e => togglePayoutMethodTooltip("hide")} onBlur={e => togglePayoutMethodTooltip("hide")} ></img>
					{payoutMethodTooltip}
				</label>

				<div className="inline-elements">
					{
						props.areRequiredPersonalInformationFilled ?
						(
							stripeConnectPayoutMethod == null
							? (
								<Link to={{
									pathname: "/stripe-connect",
									state: {
										purpose: "create",
										country: props.country,
										requiredFields: props.requiredFields,
										banking: {
											accountNumberSample: props.accountNumberSample,
											routingNumberSample: props.routingNumberSample
										}
									}
								}} className={"page-field update-field add-payment"}
								onClick={defaultLinkAction}>
									{messageMap("financials.createStripeConnectAccount", "button")}
								</Link>
							)
							: (
								<Fragment>
									<div className="payout-method">
										{messageMap(`profilePage.financials.fieldValues.${stripeConnectPayoutMethod}`, "generic")}
									</div>
								<Link to={{
									pathname: "/stripe-connect",
									state: {
										purpose: "updatePayout",
										country: props.country,
										requiredFields: props.requiredFields,
										banking: {
											accountNumberSample: props.accountNumberSample,
											routingNumberSample: props.routingNumberSample
										}
									}
								}} className="page-field update-field change-payment"
								onClick={defaultLinkAction}>
										{messageMap("financials.changePayout", "button")}
									</Link>
								</Fragment>
							)
						)
						: (
							<Fragment>
								<Link to="/profile?show=account&requireFill=true" onClick={navigateToSetupConnectAccount}
									className="page-field update-field change-payment">
									{messageMap("profilePage.tutoring.modal.stripe.linkAccount", "generic")}
								</Link>
							</Fragment>
						)
					}
				</div>
			</div>

			<div className="page-field withdraw-earnings">
				<label className="page-field-label financial-label">
					<div className="div-label">
						{messageMap("profilePage.financials.fields.earnings", "generic")}
					</div>
					<img src={labelInfoAsset} alt="information" tabIndex={0}
						onMouseOver={e => toggleEarningsTooltip("show")} onFocus={e => toggleEarningsTooltip("show")}
						onMouseLeave={e => toggleEarningsTooltip("hide")} onBlur={e => toggleEarningsTooltip("hide")} ></img>
					{earningsTooltip}
				</label>
				<div className="inline-elements">
					<div className="earnings">
						{props.currencySymbol}{earnings.toFixed(2)}
					</div>
					<button className={`page-field update-field withdraw-button ${withdrawButtonClickable}`}
						onClick={withdrawEarnings}
						onMouseOver={e => toggleWithdrawTooltip("show")} onFocus={e => toggleWithdrawTooltip("show")}
						onMouseLeave={e => toggleWithdrawTooltip("hide")} onBlur={e => toggleWithdrawTooltip("hide")}>
						{messageMap("financials.withdrawEarnings", "button")}
					</button>
					{withdrawTooltip}
				</div>
			</div>

		</div>
	);
}

FinancialsSubPage.defaultProps = {
	currencySymbol: "$",
	minimumCurrencyWithdrawal: 0,
	areRequiredPersonalInformationFilled: false
};

FinancialsSubPage.propTypes = {
	currencySymbol: PropTypes.string,
	minimumCurrencyWithdrawal: PropTypes.number,
	earnings: PropTypes.number,

	country: PropTypes.string.isRequired,
	accountNumberSample: PropTypes.string.isRequired,
	routingNumberSample: PropTypes.string.isRequired,
	requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
	areRequiredPersonalInformationFilled: PropTypes.bool.isRequired,

	// click handlers
	closeAlert: PropTypes.func.isRequired,

	// parent state setters
	setAlert: PropTypes.func.isRequired,

	// redux props
	setProfileSubNav: PropTypes.func.isRequired
};

export default connect(
	account,
	{
		setProfileSubNav
	}
)(FinancialsSubPage);

