import request, {POST, GET} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";

const BASE = "/services/knowledge";

const SAVE_VIEW = BASE + "/save/view";
const GET_VIEWS = BASE + "/get/views";
const DELETE_VIEW = BASE + "/delete/view?viewId=${viewId}";


/**
 * @param {Object} payload = {
 * 	viewName: String,
 * 	workbookName: String,
 * 	sheetName: String,
 * 	rootName: String,
 * 	depth: Integer,
 * 	zoomLevel: Integer,
 * 	ownerId: String
 * }
 */
export function saveViewAPI(payload, callback) {
	request(SAVE_VIEW, POST, payload, callback);
}

/**
 * @param {Array} pathVariables = viewId
 */
export function deleteViewAPI(pathVariables, callback) {
	const api = $replace(DELETE_VIEW, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {String} payload = ownerId
 */
export function getViewsAPI(payload, callback) {
	request(GET_VIEWS, POST, payload, callback);
}



