import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import { onKeyDown } from "Utilities/Accessibility";

import addAsset from "assets/icons/practice/add.svg";


/**
 * @param {Object} value // can be either an image or a number
 * @param {String} alt? // Image alt if value is an image
 * @param {Number} itemCount? // If 'StatBag' is being used as a bag, itemCount indicate the count of the bag item
 * @param {String} label // label for stat or bag item
 * @param {Function} clickHandler? Handler for item clicks
 * @param {Boolean} showPlus? For determining if item can be additive
 * @param {String} customContainerClass? Custom container class
 */
function StatBag(props) {

	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	return (
		<div className={`stat-bag-container ${props.customContainerClass}`}
					role="button" tabIndex={0}
					onClick={props.clickHandler} onKeyPress={e => onKeyDown(e, props.clickHandler, [e])}>

			{
				typeof props.itemCount === "number"
				? (
					<div className="bag-count">
						{props.itemCount}
					</div>
				)
				: null
			}

			<div className="stat-value">
				{typeof value === "number" ? value : (
					<img src={value} alt={props.alt} className="value-picture"></img>
				)}
			</div>
			<div className={"stat-label"}>
				{props.label}
			</div>

			{
				props.clickHandler && props.showPlus
				? (
					<button className={typeof value === "number" ? "add-stat" : "add-stat bag"}
							onClick={props.clickHandler}>
						<img src={addAsset} alt={`add ${props.label} button`}></img>
					</button>
				)
				: null
			}
		</div>
	);
}

StatBag.defaultProps = {
	showPlus: false
};

StatBag.propTypes = {
	value: PropTypes.any.isRequired,
	label: PropTypes.string.isRequired,

	alt: PropTypes.string,
	itemCount: PropTypes.number,
	clickHandler: PropTypes.func,
	showPlus: PropTypes.bool,
	customContainerClass: PropTypes.string
};

export default StatBag;

