/* eslint-disable no-template-curly-in-string */
import request, { POST } from "Utilities/Fetches";

const BASE = "/services/knowledge/metadata";

const LIST_ALL_KNOWLEDGE_METADATA = BASE + "/list/all";


/**
 * @param {Array} payload // list of topics
 */
export function getAllKnowledgeMetadata(payload, callback) {
	request(LIST_ALL_KNOWLEDGE_METADATA, POST, payload, callback);
}
