import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import profileSelector from "redux/selectors/profileSelector";

import PageLayout from "templates/PageLayout";
import { 
	SESSIONS, REQUESTS, TUTOR_REQUESTS, APPLICATIONS,
	SAVED_TUTORS, TUTORS_LIST, MESSAGES,
	TUTOR_FAQS, TUTOR_POLICIES, WHY_EXER,
	TUTORING_PAGE_MENU
} from "templates/utilities/PageSubMenu";

import TutorSessions from "pages/Tutoring/subPages/TutorSessions";
import RequestTutoring from "pages/Tutoring/subPages/RequestTutoring";
import SessionsJobBoard from "pages/Tutoring/subPages/SessionsJobBoard";
import TutorApplications from "pages/Tutoring/subPages/TutorApplications";
import SavedTutors from "pages/Tutoring/subPages/SavedTutors";
import TutorsList from "pages/Tutoring/subPages/TutorsList";
import TutoringMessagesWrapper from "./subPages/TutoringMessagesWrapper";
import TutoringFAQs from "./subPages/TutoringFAQs";
import TutoringPolicies from "./subPages/TutoringPolicies";
import WhyExer from "./subPages/WhyExer";

import messageMap from "Utilities/MessageMaps";

import { getOpenRequestsCountAPI } from "apis/controllers/tutoring/SessionRequestController";


function Tutoring(props) {

	const menuKeysToExclude = !props.isATutor ? [TUTOR_REQUESTS] : null;

	const [alert, setAlert] = useState(),
		[modal, setModal] = useState(),
		[modal1, setModal1] = useState(),
		[loginModal, setLoginModal] = useState();
	const [tutorSessionsDom, setTutorSessionsDom] = useState();
	const [requestTutoringDom, setRequestTutoringDom] = useState();
	const [sessionRequestBoardDom, setSessionRequestBoardDom] = useState();
	const [tutorApplicationsDom, setTutorApplicationsDom] = useState();
	const [savedTutorsDom, setSavedTutorsDom] = useState();
	const [tutorsListDom, setTutorsListDom] = useState();
	const [pubNubChatWrapper, setPubNubChatWrapper] = useState();

	// pageLayout states & ref
	const [pageLayoutKey, setPageLayoutKey] = useState("-1");
	const pageLayoutVisibilityRef = useRef();
	const customMenuItemsDomRef = useRef({});

	const sessionsSubPageRef = useRef(),
		requestsSubPageRef = useRef(),
		sessionsJobBoard = useRef(),
		applicationsSubPageRef = useRef(),
		savedTutorsSubPageRef = useRef(),
		tutorsListSubPageRef = useRef(),
		messagesSubPageRef = useRef(),
		faqsSubPageRef = useRef(),
		tutorPoliciesSubPageRef = useRef(),
		whyExerSubPageRef = useRef();

	useEffect(() => {
		const callback = navigateToSubPage;
		showOpenTutoringRequestCount(callback);
	}, [props.tutoringSubNav, props.ownerId]);

	function navigateToSubPage() {
		const urlLocation = window.location.href;
		if (urlLocation.includes("show")) {
			const subNavRegEx = new RegExp("(?<=show=).*");
			const regWithPages = new RegExp("(?<=show=).*(?=&)");

			if (subNavRegEx.exec(urlLocation)[0].includes("&")) {
				subMenuButtonClickHandler(regWithPages.exec(urlLocation)[0]);
			}
			else {
				subMenuButtonClickHandler(subNavRegEx.exec(urlLocation)[0]);
			}
		}
	}

	function showOpenTutoringRequestCount(callback) {
		if (props.isATutor) {
			const pathVariables = {
				tutorOwnerId: props.ownerId
			};
			getOpenRequestsCountAPI(pathVariables, resp => {
				const count = resp > 99 ? "..." : resp;

				customMenuItemsDomRef.current = {
					[TUTOR_REQUESTS]: (
						<div className="open-requests-count">
							{`(${count})`}
						</div>
					)
				};
				setPageLayoutKey(resp + "");
				pageLayoutVisibilityRef.current.className = "tutoring-pages";
				callback != null && callback();
			});
		}
		else {
			pageLayoutVisibilityRef.current.className = "tutoring-pages";
			callback != null && callback();
		}
	}

	function refreshOpenRequestCount(newPageLayoutKey) {
	// showOpenTutoringRequestCount();
		// setPageLayoutKey(newPageLayoutKey);
	}

	function subMenuButtonClickHandler(clickedMenuKey) {
		const menuKeyToRefMap = {
			[SESSIONS]: sessionsSubPageRef,
			[REQUESTS]: requestsSubPageRef,
			[TUTOR_REQUESTS]: sessionsJobBoard,
			[APPLICATIONS]: applicationsSubPageRef,
			[SAVED_TUTORS]: savedTutorsSubPageRef,
			[TUTORS_LIST]: tutorsListSubPageRef,
			[MESSAGES]: messagesSubPageRef,
			[TUTOR_FAQS]: faqsSubPageRef,
			[TUTOR_POLICIES]: tutorPoliciesSubPageRef,
			[WHY_EXER]: whyExerSubPageRef
		};
		// instead of relying on redux for proper state management, just re-render on ever page navigation
		const menuKeyToRenderFunctionMap = {
			[SESSIONS]: renderTutorSessions,
			[REQUESTS]: renderRequestTutoring,
			[TUTOR_REQUESTS]: renderSessionsJobBoard,
			[APPLICATIONS]: renderTutorApplications,
			[SAVED_TUTORS]: renderSavedTutors,
			[TUTORS_LIST]: renderTutorsList,
			[MESSAGES]: renderPubNubChatWrapper
		};

		Object.keys(menuKeyToRefMap).forEach(key => {
			menuKeyToRefMap[key].current.className = "tutoring-page hide";
		});

		if (clickedMenuKey.includes("&")) {
			clickedMenuKey = clickedMenuKey.split("&")[0];
		}

		menuKeyToRenderFunctionMap[clickedMenuKey] && menuKeyToRenderFunctionMap[clickedMenuKey]();
		menuKeyToRefMap[clickedMenuKey].current.className = "tutoring-page";
	}

	function renderTutorSessions() {
		const timestamp = new Date();
		setTutorSessionsDom(
			<TutorSessions key={timestamp.getTime()} setAlert={setAlert}
				setModal={setModal} setModal1={setModal1} setLoginModal={setLoginModal} />
		);
	}

	function renderRequestTutoring() {
		const timestamp = new Date();
		setRequestTutoringDom(
			<RequestTutoring key={timestamp.getTime()} setAlert={setAlert}
				setModal={setModal} setModal1={setModal1} setLoginModal={setLoginModal} />
		);
	}

	function renderSessionsJobBoard() {
		const timestamp = new Date();
		setSessionRequestBoardDom(
			<SessionsJobBoard key={timestamp.getTime()} refreshOpenRequestCount={refreshOpenRequestCount}
				setAlert={setAlert} setModal={setModal} setModal1={setModal1} />
		);
	}

	function renderTutorApplications() {
		const timestamp = new Date();
		setTutorApplicationsDom(
			<TutorApplications key={timestamp.getTime()} setAlert={setAlert} setModal={setModal} setModal1={setModal1} />
		);
	}

	function renderSavedTutors() {
		const timestamp = new Date();
		setSavedTutorsDom(
			<SavedTutors key={timestamp.getTime()} setAlert={setAlert}
				setModal={setModal} setModal1={setModal1} setLoginModal={setLoginModal} />
		);
	}

	function renderTutorsList() {
		const timestamp = new Date();
		setTutorsListDom(
			<TutorsList key={timestamp.getTime()} useFilter={true} purpose={"viewTutors"} setAlert={setAlert}
				setModal={setModal} setModal1={setModal1} setLoginModal={setLoginModal} />
		);
	}

	function renderPubNubChatWrapper() {
		const timestamp = new Date();
		setPubNubChatWrapper(
			<TutoringMessagesWrapper key={timestamp.getTime()} setAlert={setAlert}
				setModal={setModal} setModal1={setModal1} setLoginModal={setLoginModal} />
		);
	}

	return (
		<div className="tutoring-pages hide" ref={pageLayoutVisibilityRef}>
			<Helmet>
				<title>{messageMap("tutoring.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tutoring.description", "headerTag")}></meta>
			</Helmet>

			{alert}
			{modal}
			{modal1}
			{loginModal}

			{/* use a state as a key to force refresh */}
			<PageLayout key={pageLayoutKey} customClass="tutoring" menuKeysToExclude={menuKeysToExclude}
				subPageType={TUTORING_PAGE_MENU} customMenuItemsDom={customMenuItemsDomRef}
				pageItemClickHandler={subMenuButtonClickHandler}>
				<div>
					<div className="tutoring-page" ref={sessionsSubPageRef}>
						{tutorSessionsDom}
					</div>

					<div className="tutoring-page hide" ref={requestsSubPageRef}>
						{requestTutoringDom}
					</div>

					<div className="tutoring-page hide" ref={sessionsJobBoard}>
						{sessionRequestBoardDom}
					</div>

					<div className="tutoring-page hide" ref={applicationsSubPageRef}>
						{tutorApplicationsDom}
					</div>

					<div className="tutoring-page hide" ref={savedTutorsSubPageRef}>
						{savedTutorsDom}
					</div>

					<div className="tutoring-page hide" ref={tutorsListSubPageRef}>
						{tutorsListDom}
					</div>

					<div className="tutoring-page hide" ref={messagesSubPageRef}>
						<div className="messages-sub-page">
							{pubNubChatWrapper}
						</div>
					</div>

					<div className="tutoring-page hide" ref={faqsSubPageRef}>
						<TutoringFAQs setModal={setModal} setModal1={setModal1}/>
					</div>

					<div className="tutoring-page hide" ref={tutorPoliciesSubPageRef}>
						<TutoringPolicies setModal={setModal} setModal1={setModal1} />
					</div>

					<div className="tutoring-page hide" ref={whyExerSubPageRef}>
						<WhyExer />
					</div>
				</div>
			</PageLayout>
		</div>
	);
}

Tutoring.propTypes = {
	// Redux props
	tutoringSubNav: PropTypes.string,
	ownerId: PropTypes.string,
	isATutor:  PropTypes.bool
};

export default connect(
	profileSelector
)(Tutoring);