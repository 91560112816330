import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Alert, {ERROR, SUCCESS} from "templates/Alert";
import Modal from "templates/Modal";

import messageMap from "Utilities/MessageMaps";
import { checkHasExplicitWords } from "Utilities/Validators/ContentValidator.js";
import defaultLinkAction from "Utilities/LinkActions";

import { createGroupAPI, joinGroupAPI } from "apis/controllers/GroupsController";



function GroupsSubPage(props) {

	const ownerId = localStorage.getItem("ownerId");

	const [groupCode, setGroupCode] = useState();

	const groupNameRef = useRef();


	function createGroup() {
		if (checkHasExplicitWords(groupNameRef.current)) {
			props.setAlert(
				<Alert closeHandler={props.closeAlert} type={ERROR} msg={messageMap("input.explicit.text", "validation")} />
			);
		}
		else {
			const payload = {
				ownerId: ownerId,
				groupName: groupNameRef.current
			};
			createGroupAPI(payload, (resp) => {
				setGroupAlert(resp["MESSAGES"][0]);
				window.location.reload();
			});
		}
	}

	function joinGroup() {
		const payload = {
			ownerId: ownerId,
			joinGroupCode: groupCode
		};
		joinGroupAPI(payload, (resp) => {
			setGroupAlert(resp[0]);
			window.location.reload();
		});
	}

	function setGroupAlert(messageKey) {
		const alertType = messageKey.toLowerCase().includes("success") ? SUCCESS : ERROR;
		let msg = messageMap(messageKey, "api");

		if (alertType === SUCCESS) {
			msg = msg + " " +messageMap("groups.reload", "api")
		}

		props.setAlert(
			<Alert closeHandler={props.closeAlert} type={alertType} msg={msg} />
		);
	}

	function closeGroupNameDisplay(e) {
		if (e != null && !e.target.className.includes("do-not-close")) {
			props.setGroupNameDisplay(null);
		}
	}

	function onChangeInput(e) {
		const target = e.target,
			text = target.value;

		setGroupCode(text);
		if (text === "") {
			target.className = "page-field-value";
		}
	}

	function createGroupName() {
		props.setGroupNameDisplay(
			<Modal closeHandler={closeGroupNameDisplay} submitText={messageMap("groups.createGroup", "button")}
							submitHandler={createGroup} title={messageMap("profilePage.groups.createGroup.title", "generic")}
							modalClass="group-name-display" >

				<div className="do-not-close">
					<div className="do-not-close subtext">
						{messageMap("profilePage.groups.createGroup.subtext", "generic")}
					</div>
					<div className="do-not-close label">
						<label htmlFor="groupName" className="page-field-label do-not-close">
							{messageMap("profilePage.groups.createGroup.groupName", "generic")}
						</label>
					</div>
					<input id="groupName" className="do-not-close"
						maxLength={20} aria-describedby="groupNameToolTip"
						placeholder={messageMap("profilePage.groups.createGroup.name", "generic")}
						onChange={e => { groupNameRef.current = e.target.value }}
						onBlur={e => { groupNameRef.current = e.target.value }}
					></input>
				</div>
			</Modal>
		);
	}

	return (
		<div>
			<h1 className="page-title">
				{messageMap("profile.groups", "subPageNavigation")}
			</h1>

			<div className="page-field">
				<div className="page-field-label">
					{messageMap("profilePage.subHeader.groupCode", "generic")}
				</div>
				<div className="inline-elements">
					<input id="group-code" placeholder={(props.isMemberOfGroup ? "" : messageMap("profilePage.groups.code", "generic"))}
						className={"page-field-value" + (props.isMemberOfGroup ? " not-allowed" : "")}
						disabled={props.isMemberOfGroup} maxLength={20}
						onChange={onChangeInput}
					/>
					<button className={"page-field update-field join-group" + (props.isMemberOfGroup ? " not-allowed" : "")}
						disabled={props.isMemberOfGroup} onClick={joinGroup}>
						{messageMap("groups.join", "button")}
					</button>
				</div>
			</div>

			<div className="page-field">
				<div className="inline-elements">
					<button className={"page-field update-field" + (props.isMemberOfGroup ? " not-allowed" : "")}
						style={{ marginLeft: "0px" }} disabled={props.isMemberOfGroup}
						onClick={createGroupName}>
						{messageMap("groups.createGroup", "button")}
					</button>
					{
						props.isMemberOfGroup && (
							<Link to="/groups" className="page-field update-field" onClick={defaultLinkAction}>
								{messageMap("links.groups", "button")}
							</Link>
						)
					}
				</div>
			</div>
		</div>
	);
}


GroupsSubPage.propTypes = {
	isMemberOfGroup: PropTypes.bool.isRequired,

	// click handlers
	closeAlert: PropTypes.func.isRequired,

	// parent state setters
	setGroupNameDisplay: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired
};

export default GroupsSubPage;


