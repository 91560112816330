import React, {Fragment, useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";

import TabbedPages from "templates/TabbedPages";
import TopicDetailsPriceBreakdown from "./TopicDetailsPriceBreakdown";

import { getTutorScheduledSessionsAPI } from "apis/controllers/tutoring/SessionRequestController";


function SubjectTopicPriceBreakdown(props) {

	const [topicRatesDom, setTopicRatesDom] = useState(),
		[filledSlotSessions, setFilledSlotSessions] = useState();

	useEffect(() => {
		getTutorsScheduledSessions(showSubMenuPage);
	}, []);

	function showSubMenuPage(filledSlotSessions) {
		let topicRatesMap = {};
		props.teachingRates.split("|").forEach(rate => {
			const topicRate = rate.split("_");
			topicRatesMap[topicRate[0]]  = topicRate[1];
		});

		let subjectToTopicObj = {};
		let subjectTabMenu = {};
		const isUsingFlatRate = Object.keys(topicRatesMap).includes("flatRate");
		props.subjectToTopicsMap.split("|").forEach(subjectTopicStr => {
			const subjectTopic = subjectTopicStr.split("_");
			const subject = subjectTopic[0];

			if (subjectToTopicObj[subject] == null) {
				subjectToTopicObj[subject] = [];
			}

			let rate = topicRatesMap[subjectTopic[1]];
			if (rate != null) {
				if (isUsingFlatRate) {
					rate = topicRatesMap["flatRate"];
				}
	
				subjectToTopicObj[subject].push({
					topic: subjectTopic[1],
					rate: rate
				});
				subjectTabMenu[subject] = subject;
			}
		});

		let topicRates = [];
		for (const [key, value] of Object.entries(subjectToTopicObj)) {
			topicRates.push(
				<div key={key} className="subject-topic-group">
					<div className="subject-label">
						{key}
					</div>
					<div>
						{createTopicRatesBreakdown(key, subjectToTopicObj, filledSlotSessions)}
					</div>
				</div>
			);
		}

		setTopicRatesDom(topicRates);
	}

	function getTutorsScheduledSessions(callback) {
		if (props.showBookOption) {
			const pathVariables = {
				ownerId: props.tutorOwnerId
			};
	
			getTutorScheduledSessionsAPI(pathVariables, resp => {
				let filledSlotSessions = [];
				resp.forEach(session => {
					if (session.urgency !== "now") {
						filledSlotSessions.push(
							{
								date: session.urgency,
								duration: session.duration
							}
						);
					}
				});
	
				setFilledSlotSessions(filledSlotSessions);
				callback(filledSlotSessions);
			});
		}
		else {
			callback();
		}
	}

	function createTopicRatesBreakdown(chosenSubject, subjectToTopicsMap, filledSlotSessions) {
		let topicRates = [];

		const initialTopicRatesList = subjectToTopicsMap[chosenSubject];
		initialTopicRatesList.forEach((topicRateTuple, index) => {
			topicRates.push(
				<Fragment key={topicRateTuple.topic}>
					<TopicDetailsPriceBreakdown setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert}
						topic={topicRateTuple.topic} rate={topicRateTuple.rate} subject={chosenSubject} 
						showBookOption={props.showBookOption} filledSlotSessions={filledSlotSessions} schedule={props.schedule}
						// TODO: update this from @Chris's code
						isSubscriber={true}
						tutorTier={props.tutorTier} tutorFirstName={props.tutorFirstName} />
					{
						(index !== initialTopicRatesList.length - 1) && <hr />
					}
				</Fragment>
			);
		});

		return topicRates;
	}

	return (
		<div className="subject-topic-details">
			{topicRatesDom}
		</div>
	);
}

SubjectTopicPriceBreakdown.propTypes = {
	tutorFirstName: PropTypes.string.isRequired,
	tutorProfileId: PropTypes.string.isRequired,
	tutorOwnerId: PropTypes.string.isRequired,
	schedule: PropTypes.string.isRequired,
	showBookOption: PropTypes.bool,
	teachingRates: PropTypes.string.isRequired,
	subjectToTopicsMap: PropTypes.string.isRequired,
	tutorTier: PropTypes.string.isRequired,

	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired
};

export default SubjectTopicPriceBreakdown;

