export const environmentMessageMap = {
	sight: {
		article: {
			title: "How the things we see affect our learning",
			description: "Learn how light and the objects around us affect our learning.",
			thumbnailAlt: "An upstairs view that makes the ceiling look like an eye."
		},
		texts: {
			header1: "Module Introduction",
			p1: `Environment. For this first module, <b>we'll learn how what we see, hear,  smell, and feel affects 
				our learning, and the things that we can do to improve them</b>. But before we get into the details, how do you think our 
				surroundings affect our learning?`,
			p2: `If you thought anything about how it affects our attention, and therefore our ability to learn effectively, then 
				you're already on the right track. But, there are a few other subtle things that also affect us subconsciously, which 
				relates to learning. So, without further ado, let's get started.`,
			header2: "Introduction",
			p3: `In this first article about how our environment affects our learning, we'll first look into our <b>sight</b>. 
				We're going to learn how lighting and the objects around us can influence our ability to concentrate.`,
			p4: `Try to think of the times when lighting was affecting your concentration. What about the lighting that 
				caused your concentration to improve, or worsen, or remain the same? Did it have something to do with how bright 
				it was? What about how the light was distributed and/or angled? Did that make any difference? Perhaps the color of 
				the light did? What do you think?`,
			header3: "Light's intensity",
			p5: `As you might already know, a <b>light's intensity</b>, which is measured in lumens, <b>affects our concentration</b>, mainly 
				because it's hard to read when the light is too dim, which usually causes us to squint our eyes. But, while a 
				simple solution to this is to go where there is ample lighting, does the light's color also matter? What happens 
				if the light is blue, yellow, or red? And, what if it's angled?`,
			header4: "Light's color",
			p6: `Well, according to research, it turns out that all these variables, the variables being the color of the light 
				and how it's angled, not only affect our ability to concentrate but also our overall health and well-being [3]. 
				According to Dr. Petra Studer, in her study on how red and blue light affect our sleep and attention, <b>our body, and 
				therefore our mind, is more alert when we're exposed to blue light, as opposed to red light.</b> `,
			p7: `Blue light subconsciously reminds us of mornings, when we're getting ready for the day ahead, and are therefore more 
				alert, compared to red light, which reminds us of the evening, when we typically relax and unwind, and get ready for sleep. 
				This is also another reason why, if you use your phone before sleeping, it's harder to fall asleep if you're exposed to blue 
				light, which is why some phones have a setting that automatically adjusts its lighting to a red shade when it's getting late. 
				So, as a tip, if you want to fall asleep earlier and easier, try to limit your screen time before going to sleep.`,
			header5: "Light's angle",
			p8: `So, now we know that brightness and the light's color do affect our ability to concentrate, what about how the light is 
				angled or distributed? If you've ever watched a movie where the cop takes the suspect into the interrogation room and has this 
				low-hanging bulb directly a few feet above the table, you'll now learn why it was made that way. According to the Illuminating 
				Engineering Society, there are <b>different psychological impacts depending on how lighting is positioned relative to us</b> [2]. The closer 
				the light is from our head and if it's pointing downward, the more tense the feeling becomes. If the light is at the room's perimeter 
				with warmer colors, then the mood is generally relaxed. If there is lighting near the top of the ceiling, it gives a feeling of spaciousness. 
				And, if the light is a little dim and dark near the perimeter, then we get a feeling of privacy and intimacy, this is why high-end restaurants 
				are usually dim. As to how all these angles relate to our concentration, studies show that our mind perform optimally if we're in a calm and 
				relaxing environment, so choosing a place where there's warm lighting near the room's perimeter would be ideal.`,
			header6: "Objects around us",
			p9: `Next up concerning our eyes are the objects we see around us. Can you think of some reason why the objects around us might improve, worsen, 
				or not affect our concentration?`,
			p10: `While objects around us eventually start blending into the background, some objects continue to be attention grabbers. This is why multiple 
				studies reveal that students demonstrated smaller learning gains when the walls were highly decorated, compared to when they were removed. This 
				is also the reason why libraries are minimally decorated. Although you might find this point obvious, what isn't so obvious is that <b>some objects 
				can also have a direct impact on our perceived identities, and therefore our performance.</b>`,
			p11: `Studies have shown that wall decorations and environmental objects that have the potential to reduce our identities to mere ornamental representations 
				directly impact our performance [1]. To put into an example, if a female Filipino student, who aspires to become a top engineer one day, is sitting in a classroom 
				that contains a poster of people who can be identified as Filipino, working in another profession, let's say nursing, and if that student consciously, or 
				subconsciously, interprets these posters as representations of what it means to be a professional Filipina in the workforce, that student might feel discouraged, 
				less engaged; her competitiveness and drive to become a top engineer might decrease; and, all of a sudden, if it's not properly addressed, not only is her academic 
				future at risk but also her future as a successful person, who should've instead been empowered to forge her path.`,
			p12: `Now, this just concerns things around us that possibly send discriminating messages, but sources of discrimination can come from other places as well, like people. 
				Since this topic of discrimination and how it affects our learning is a big topic, we're going to have a module dedicated to addressing this issue, including other factors 
				relating to our mentality and attitude.`,
			header7: "Conclusion",
			p13: `To summarize what we've learned, <b>it's important to be in a place where there's plenty of warm lighting, and where the lighting is angled in a way that calms and relaxes 
				us. Additionally, we'd prefer to study in a place that isn't visually stimulating and has decorations that don't relegate our identity to mere ornamental representations.</b>`
		},
		sources: {
			s1: `Cheryan, S., Ziegler, S. A., Plaut, V. C., & Meltzoff, A. N. (n.d.). Designing classrooms to maximize student achievement. https://journals.sagepub.com/doi/10.1177/2372732214548677 `,
			s2: `Illuminating Engineering Society. (2024, May 23). https://www.ies.org/ `,
			s3: `Studer, P., Brucker, J. M., Haag, C., Van Doren, J., Moll, G. H., Heinrich, H., & Kratz, O. (2018, October 28). Effects of blue- and red-enriched light on attention and sleep in typically 
				developing adolescents. Science Direct. https://www.sciencedirect.com/science/article/abs/pii/S0031938418305031 `
		}
	},
	sound: {
		article: {
			title: "How the things we hear affect our learning",
			description: "Learn how vocal and non-vocal sounds contribute to cognitive load.",
			thumbnailAlt: "Headphones on top of a both with 2 colors."
		},
		texts: {
			header1: "Introduction",
			p1: `The next one on our list regarding how our environment affects our learning is hearing. There are a lot of 
				different sounds in our environment, whether they're from someone talking, someone playing some music, or 
				just someone making a racket outside. How each of these affects our ability to focus, and feel certain emotions, 
				will vary. Think of the times when you've heard these different sounds, someone's voice, music, and noise. What 
				did these sounds do to your concentration and, if it affected at all, your emotions?`,
			p2: `Because sound is a broad topic, we'll simplify our analysis by first removing any reference to anything we 
				consider as noise. Nobody wants to hear noise and we already know that noise is distracting and can be annoying.`,
			header2: `Vocal and non-vocal sound`,
			p3: `Next, anything that isn't noise we'll refer to as either vocal or non-vocal sound. The reason for this is due 
				to how our brain behaves when we listen to vocal and non-vocal sounds. Listening to, or hearing, someone talk, or 
				sing, usually prompts us to listen to what they're saying, and doing so redirects our attention from our task to 
				the speaker.`,
			header3: `Effects of non-vocal sound`,
			p4: `Listening to non-vocal sounds has a more varied effect. Depending on the non-vocal sound, we'll experience varying 
				emotions while listening to them, and, sometimes, elicit imageries in our mind. While feeling and imagining things while 
				learning have been shown to improve our ability to remember the things we're learning, since our brain might associate 
				the emotions, or imageries, we're feeling, or imagining, at that time with the concepts we were learning, feeling, or 
				imagining, too much can distract us from what we're learning. The key is finding the midway point, where we're both able 
				to feel, or imagine, while still staying focused on what we're learning; finding this point will require us to carefully 
				choose what music to listen to, if we decide to listen to any music to begin with, and carefully adjusting the music's volume.`,
			p5: `This is partially <b>why studies on how music affects our brain during studying vary [1]; it's mainly due to the different emotional 
				and cognitive effects they have on us.</b> Some music motivates us, some makes us dance, some makes us think, and some even makes us 
				re-experience parts of our lives.`,
			p6: `These are the reasons why we should consider what kind of music we listen to if we decide to listen to something while studying. 
				If we listen to music that has lyrics, the singing might distract us. If we listen to non-vocal music, this has the potential to improve 
				or impair our ability to learn.`,
			p7: `It's generally easier to not listen to music at all while studying, since the simple act of listening to any kind of music places additional 
				cognitive load. `,
			header4: "Cognitive Load",
			p8: `<b><u>Cognitive load</u> is additional work that your brain needs to do, to make sense of things.</b> So when you're studying something completely 
				new, your brain works harder because it's doing a lot of extra work trying to make sense of these new things, compared to when you're doing 
				something you already know.`,
			p9: `Anyway, back to our topic, research suggests that <b>people don't learn any better or worse because of the gentle music playing in the 
				background. It only becomes a problem when it becomes intrusive and detracts from learning.</b>`,
			header5: "Conclusion",
			p10: `In summary, <b>we should remove any noise and music that might distract us and only consider listening to music if it can help us 
				concentrate and motivate us in our studies.</b>`
		},
		sources: {
			s1: `Segaren, S. (2019, January 1). Does listening to music help you become a better student?. Study International. https://www.studyinternational.com/news/does-listening-to-music-while-studying-make-you-a-better-student/ `,
			s2: "Every Brain Needs Music: The Neuroscience of Making and Listening to Music by Larry Sherman and Dennies Plies"
		}
	},
	scents: {
		article: {
			title: "How scents affect our learning",
			description: "Learn how scents affects our emotions, memories, and cause physiological effects.",
			thumbnailAlt: "Incense burning."
		},
		texts: {
			header1: "Introduction",
			p1: `Now for our sense of smell. Although we typically associate our sense of smell with our ability to 
				detect if something smells good or bad, the things we smell can also actually affect our mood and ability 
				to concentrate and think. But before we get into the details, let's look back at our past when we've smelled 
				some stuff, either intentionally, or to some unfortunate people, unintentionally, and how those moments made 
				us feel, and think.`,
			header2: "Eliciting emotions and memories",
			p2: `As you might remember, <b>the things we smell also elicit certain emotions and memories from our past; this 
				is similar to the effects of the music we listen to.</b> And like listening to different music, different scents 
				can calm us down, elevate our mood, and even make us more alert. `,
			header3: "Physiological effects",
			p3: `For example, essential oils. Although they're mostly used in spas, <b>growing research in the past decade has 
				shown that they can lower our stress, improve our alertness, and even boost our memory.</b> Sage helps reduce blood 
				pressure, while peppermint improves stress relief. Rosemary enhances our brainpower and even elevates our mood, 
				while cinnamon helps improve focus. [1, 3]`,
			p4: `As an added bonus, these oils have also been shown to cure some ailments, such as nose congestion, colds, 
				headaches, anxiety, and even inflammations.`,
			header4: "Caution",
			p5: `But before you go rushing outside to buy some of these oils, as with most things that are beneficial to us, 
				we need to <b>practice moderation</b>, since prolonged exposure to oils can expose us to cardiac risks; the typical advice 
				is to not exposure yourself to more than an hour a day. Always be sure to read the bottle's fine print and to do 
				some additional research, and maybe consult your doctor, if it's safe for you to use these oils, or even practice 
				aroma therapy.`,
			header5: "Where to get them",
			p6: `Although these oils often come in 5 to 7 seven-dollar bottles, they're often not available in your local stores, 
				so you might have to order them online. You can also just get the plants themselves from your local stores that have 
				a gardening department. If that's still not possible, you can always go to a place where 
				there's a lot of oxygen.`,
			header6: "Finding the right environment",
			p7: `Since our body relies on oxygen to use the stored energy in our food, and because our brain's primary fuel is 
				carbohydrates, it would then follow that being in an environment with adequate oxygen would allow us to efficiently 
				use our body's carbohydrates reserve [2]. This is why <b>studying where there are plenty of plants and trees puts us in a 
				calm and alert state, which, according to research, is the optimal state to be in when studying.</b> We'll go into more 
				detail on how we can directly induce our brain to be in this state in the 6th module, where we analyze how our mind works 
				while learning. `,
			header7: "Conclusion",
			p8: `So, in summary, like sound, <b>your surrounding's smell can be beneficial for us, if it helps us stay calm and alert.</b> 
				Additionally, <b>we would want to be in a place where there's plenty of oxygen so that we efficiently use the energy stored in our food.</b>`
		},
		sources: {
			s1: "ABC News Network. (n.d.). 6 Scents That Have the Power to Heal. ABC News. https://abcnews.go.com/Health/Wellness/scents-power-heal/story?id=24109788",
			s2: "Higgins, J. (2015, December 10). Buildings with fresher air linked to better thinking. The Seattle Times. https://www.seattletimes.com/education-lab/buildings-with-fresher-air-linked-to-better-thinking/ ",
			s3: "Sowndhararajan, K., & Kim, S. (2016, November 29). Influence of fragrances on human psychophysiological activity: With special reference to human electroencephalographic response. Scientia pharmaceutica. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5198031/ "
		}
	},
	feeling: {
		article: {
			title: "How our feelings affect our learning",
			description: "Learn how our environment's temperature and the people we surround ourselves our learning.",
			thumbnailAlt: "A toy figure made of cardboard looking a bit empty."
		},
		texts: {
			header1: "Introduction",
			p1: `Now that we have covered our senses of seeing, hearing, and smelling, it's time to go to our final point, 
				which is feeling, and our topic for this would be temperature and people. If we were to picture the ideal place 
				to study, what would the temperature of that ideal place be and would there be any people around? If there are 
				going to be people around, would they be anyone you know, or don't know? How do you think the people and the 
				place's temperature affect our ability to study?`,
			header2: "Temperature",
			p2: `Since all of us live in a wide temperature range, from the blazing dessert heat to the icy snowfields, what is 
				considered <b>a suitable room temperature when studying would ultimately depend on what we're used to</b>, even though 
				studies say that 68o - 74oF, or 20o - 23.33oC, is the ideal room temperature range when studying. The main idea 
				behind this is that you're neither sweating nor shivering, so much that you find it hard to concentrate.`,
			header3: "The people around us",
			p3: `And lastly, people. In the context of a learning environment, the kinds of people you surround yourself with 
				can have a significant impact on your learning, both at the time of learning and your future as a successful 
				student. For example, when participating in a group discussion, sharing ideas from a diverse group of people can 
				provide a more thorough exploration of the topic. The chances of viewing the topic from numerous angles increase, 
				which greatly improves how you understand the subject, and understanding concepts in greater depth is the very 
				hallmark of learning. However, the wrong kind of crowd can also make learning difficult, boring, and discouraging. 
				So whenever possible, choose the right people to surround yourself with.`,
			p4: `Additionally, to highlight just how important the people around are, research conducted by James Coleman showed 
				that, <b>although school is a major part of a student's academic future, what has a greater impact is the student's family's 
				educational background and a diverse socioeconomic mix in the classroom.</b> The study showed that if your parents had a 
				solid education and that your classes have a good mix of people coming from diverse backgrounds, then you are more likely 
				to succeed academically. This study has been replicated and confirmed by many other studies that followed this.`,
			header4: "Conclusion",
			p5: `So to summarize what we've learned, <b>be in a comfortable environment, although not too comfortable that you might 
				just end up sleeping and surround yourself with people that cares about your education.</b>`,
			p6: `And that brings us to the end of this first module on optimizing your learning. Summarizing what we learned from this 
				module, <b>you should be in a comfortable state and place where you can fully concentrate on what you're learning. Your 
				senses shouldn't be distracted by what's going on in your environment, so that your mind is only concerned with what's 
				directly in front of you and that you don't experience unnecessary cognitive load.</b>`,
			p7: `Consider the following places when deciding where to study:`,
			p8: `	1. Your bedroom's desk.`,
			p9: `		<div style="margin-left: 24px;">a. This desk needs to be uncluttered and that it's mostly intended for getting work done.</div>`,
			p10: `	2. School or public library`,
			p11: `	3. Front porch`,
			p12: `	4. Backyard`,
			p13: `	5. Café or coffee shop`,
			p14: `	6. Bookstore`,
			p15: `	7. Park`,
			p16: `	8. Empty classroom`,
			p17: `	9. Tutoring/study centers`
		},
		sources: {
			s1: `Cheryan, S., Ziegler, S. A., Plaut, V. C., & Meltzoff, A. N. (n.d.). Designing classrooms to maximize student achievement. https://journals.sagepub.com/doi/10.1177/2372732214548677 `,
			s2: `Dickinson, E. E. (2016, December 2). Coleman report set the standard for the study of Public Education. The Hub. https://hub.jhu.edu/magazine/2016/winter/coleman-report-public-education/ `
		}
	}
};