import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import StaticImages from "assets/topicArticles/Math/components/StaticImages";

import thumbnailAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/thumbnailPositions.jpg";

import personAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/person.svg";
import personBehindFenceAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/person-behind-fence.png";
import ballAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/ball.svg";
import fenceAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/fence.svg";
import treeAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/tree.svg";


export const spatialRelationsImgAltsMap = {
	person: "Picture of a person.",
	ball: "Picture of a basketball.",
	fence: "Picture of a black and white fence.",
	tree: "Picture of a black tree."
};

export const spatialRelationsArticle = {
	id: "II: Relations_A. Spatial Relations: Up, Down, Left, Right, Forward, and Backward",
	title: messageMap("spatialRelations.article.title", "math"),
	description: messageMap("spatialRelations.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("spatialRelations.article.thumbnailAlt", "math"),
	bodyFormatOrder: ["text1", "text2", "text3", "html1", "html2", "html3", "html4", "text4", "html5", "html6", "text5"],
	datePublished: "2024-06-04",
	dateModified: "2024-06-04"
};

export const spatialRelationsArticleTexts = {
	text1: {
		text: messageMap("spatialRelations.spatialRelationsArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("spatialRelations.spatialRelationsArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("spatialRelations.spatialRelationsArticleTexts.text3", "math"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("spatialRelations.spatialRelationsArticleTexts.text4", "math"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("spatialRelations.spatialRelationsArticleTexts.text5", "math"),
		type: DESCRIPTION
	}
};

export const spatialRelationsArticleHTMLs = {
	html1: (
		<StaticImages key="right" images={{
			left: {
				img: ballAsset,
				alt: spatialRelationsImgAltsMap["ball"],
				class: "thirty-two-height"
			},
			right: {
				img: personAsset,
				alt: spatialRelationsImgAltsMap["person"]
			}
		}} description={
			<div>
				{messageMap("comparisons.position.personRelative", "math")} <p className="underline bold"> {messageMap("comparisons.position.right", "math")} </p> {messageMap("comparisons.position.ballRelative", "math")}
			</div>
		} />
	),
	html2: (
		<StaticImages key="left" images={{
			left: {
				img: personAsset,
				alt: spatialRelationsImgAltsMap["person"]
			},
			right: {
				img: ballAsset,
				alt: spatialRelationsImgAltsMap["ball"],
				class: "thirty-two-height"
			}
		}} description={
			<div>
				{messageMap("comparisons.position.personRelative", "math")} <p className="underline bold"> {messageMap("comparisons.position.left", "math")} </p> {messageMap("comparisons.position.ballRelative", "math")}
			</div>
		} />
	),
	html3: (
		<StaticImages key="front" images={{
			left: {
				img: fenceAsset,
				alt: spatialRelationsImgAltsMap["fence"]
			},
			right: {
				img: personAsset,
				alt: spatialRelationsImgAltsMap["person"],
				class: "minus-forty-eight-left-margin"
			}
		}} description={
			<div>
				{messageMap("comparisons.position.personRelative", "math")} <p className="underline bold"> {messageMap("comparisons.position.front", "math")} </p> {messageMap("comparisons.position.fenceRelative", "math")}
			</div>
		} imgContainerClass="forty-four-margin-right" />
	),
	html4: (
		<StaticImages key="behind" images={{
			left: {
				img: personBehindFenceAsset,
				alt: spatialRelationsImgAltsMap["person"]
			},
		}} description={
			<div> 
				{messageMap("comparisons.position.personRelative", "math")} <p className="underline bold"> {messageMap("comparisons.position.behind", "math")} </p> {messageMap("comparisons.position.fenceRelative", "math")}
			</div>
		} imgContainerClass="sixty-four-margin-right"/>
	),
	html5: (
		<StaticImages key="under" images={{
			left: {
				img: treeAsset,
				alt: spatialRelationsImgAltsMap["tree"],
				class: "eighty-height"
			},
			right: {
				img: personAsset,
				alt: spatialRelationsImgAltsMap["person"],
				class: "thirty-two-height minus-forty-left-margin"
			},
		}} description={
			<div>
				{messageMap("comparisons.position.personRelative", "math")} <p className="underline bold"> {messageMap("comparisons.position.under", "math")} </p> {messageMap("comparisons.position.treeRelative", "math")}
			</div>
		} imgContainerClass="forty-four-margin-right"/>
	),
	html6: (
		<StaticImages key="above" images={{
			left: {
				img: personAsset,
				alt: spatialRelationsImgAltsMap["person"],
				class: "thirty-two-height minus-twenty-four-top-margin"
			},
			right: {
				img: treeAsset,
				alt: spatialRelationsImgAltsMap["tree"],
				class: "minus-forty-eight-left-margin"
			},
		}} description={
			<div>
				{messageMap("comparisons.position.personRelative", "math")} <p className="underline bold"> {messageMap("comparisons.position.above", "math")} </p> {messageMap("comparisons.position.treeRelative", "math")}
			</div>
		}
			imgContainerClass="fifty-two-margin-right self-start-align-items"
			staticImageContainerClass="twenty-margin-left"/>
	)
};




