import React, { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet";

import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { promiseAll, POST, GET } from "Utilities/Fetches";
import messageMap from "Utilities/MessageMaps";

import Spinner from "templates/Spinner";

import { GET_CLIENT_SECRET_TO_SAVE_USER_CHARGEABLE_PAYMENT_METHOD } from "apis/controllers/person/PersonPaymentInfoController";
import { GET_STRIPE_PUB_KEY } from "apis/controllers/thirdParty/APIKeyController";


function PaymentMethod(props) {

	const [embeddedSaveablePaymentMethod, setEmbeddedSaveablePaymentMethod] = useState(),
		[isLoading, setIsLoading] = useState(),
		[message, setMessage] = useState();

	useEffect(() => {
		showEmbeddedSaveablePaymentMethod();
	}, [props.ownerId]);

	function showEmbeddedSaveablePaymentMethod() {
		setIsLoading(true);

		const apiArr = [
			{
				api: GET_STRIPE_PUB_KEY,
				type: GET
			}, {
				api: GET_CLIENT_SECRET_TO_SAVE_USER_CHARGEABLE_PAYMENT_METHOD,
				type: POST,
				payload: props.ownerId
			}
		];

		promiseAll(apiArr, resp => {
			const stripePubKey = resp[0].data;
			const paymentMethodObject = resp[1].data;

			if (stripePubKey && paymentMethodObject.clientSecretToken) {
				const options = {
					clientSecret: paymentMethodObject.clientSecretToken,
					loader: "always",
					// reference: https://docs.stripe.com/elements/appearance-api
					appearance: {
						theme: "stripe",
						disableAnimations: false
					}
				};

				setEmbeddedSaveablePaymentMethod(
					<Elements stripe={loadStripe(stripePubKey)} options={options} >
						<StripSavePaymentMethodForm returnUrl={paymentMethodObject.returnUrl}
							setIsLoading={setIsLoading} setMessage={setMessage} ownerId={props.ownerId} />
					</Elements>
				);
			}
		});
	}

	return (
		<div className="payment-method-page">
			<Helmet>
				<title>{messageMap("savePaymentMethod.title", "headerTag")}</title>
				<meta name="description" content={messageMap("savePaymentMethod.description", "headerTag")}></meta>
			</Helmet>

			<div className="title">
				{messageMap("payments.saveMethod.title", "generic")}
			</div>

			{
				isLoading && <Spinner containerClass="payment-method-spinner" />
			}
			{embeddedSaveablePaymentMethod}
			{
				message && <div className="save-payment-method-message">{message}</div>
			}
		</div>
	);
}

function StripSavePaymentMethodForm(props) {
	const stripe = useStripe();
	const elements = useElements();

	const [paymentInfo, setPaymentInfo] = useState();

	async function handleSubmit(e) {
		e.preventDefault();

		props.setIsLoading(true);

		// if stripe hasn't loaded, return
		if (!stripe || !elements) {
			return null;
		}

		async function confirmPaymentSetup() {
			try {
				const error = await stripe.confirmSetup({
					elements,
					confirmParams: {
						return_url: props.returnUrl + `?method=${paymentInfo.value.type}&ownerId=${props.ownerId}`
					}
				});
				return error;
			}
			catch (error) {
				console.error("Error saving preferred payment: ", error);
				return error;
			}
		}

		const error = await confirmPaymentSetup();

		// Triggers if there is an immediate error when confirming the payment. 
		// Otherwise, your customer will be redirected to your `return_url`. For 
		// some payment methods like iDEAL, your customer will be redirected to 
		// an intermediate site first to authorize the payment, then redirected 
		// to the `return_url`.
		if (error && Object.keys(error).length) {
			props.setMessage(error.message);
		}

		props.setIsLoading(false);
	}

	function somethingChanged(theChange) {
		setPaymentInfo(theChange);
	}

	function paymentElementReady() {
		props.setIsLoading(false);
	}

	return (
		<Fragment>
			<form className="form-container" onSubmit={handleSubmit} >
				<PaymentElement onReady={paymentElementReady} onChange={somethingChanged} />
				<button className="save-payment-button" >
					{messageMap("submit.text", "button")}
				</button>
			</form>
		</Fragment>
	);
}

PaymentMethod.propTypes = {
	// redux props
	ownerId: PropTypes.string.isRequired
};

StripSavePaymentMethodForm.propTypes = {
	setIsLoading: PropTypes.func.isRequired,
	setMessage: PropTypes.func.isRequired,
	returnUrl: PropTypes.string.isRequired,
	ownerId: PropTypes.string.isRequired
};

export default connect(
	account
)(PaymentMethod);
