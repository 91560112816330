/* eslint-disable no-template-curly-in-string */
import { LOGIN_VO, ACCOUNT_VO } from "apis/models/Accounts";

import { ACCOUNT_UPDATE_FORM_DATA } from "Utilities/Constants/MediaConstants";
import request, { GET, POST } from "Utilities/Fetches";
import { getFormData } from "Utilities/Upload";
import { $replace } from "Utilities/Strings";
import { modelValidator } from "Utilities/Validators/APIValidators";


const BASE = "/services/accounts";

const LOGIN = BASE + "/login";
const RECOVER_ACCOUNT = BASE + "/recover";
const RESET_PASSWORD = BASE + "/resetPassword";
const AUTO_LOGIN = BASE + "/auto/login?updatedUsername=${updatedUsername}";
const SIGN_UP = BASE + "/signUp";
const LOG_OUT = BASE + "/logOut";
const USERNAME_AVAILABILITY = BASE + "/username/availability/${username}";
export const GET_ACCOUNT_SETTINGS = BASE + "/settings/get";
const SAVE_ACCOUNT_SETTINGS = BASE + "/settings/save?ownerId=${ownerId}";
const SEND_VERIFICATION_EMAIL = BASE + "/send/verification";
const VERIFY_TOKEN = BASE + "/verify/${token}";

export const GET_USER_TYPE = BASE + "/get/user/type";


/**
 * @param {String} authToken
 */
export function loginAPI(payload, credentials, callback, errorHandler) {
	const headerArgs = {
		Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`
	};
	modelValidator(LOGIN_VO, payload) && request(LOGIN, POST, payload, callback, errorHandler, null, null, headerArgs);
}

/**
 * @param {String} email
 */
export function recoverAccountAPI(payload, callback) {
	request(RECOVER_ACCOUNT, POST, payload, callback);
}

/**
 * @param {Object} payload = {
 * 	recoveryToken: String,
 * 	password: String
 * }
 */
export function resetPasswordAPI(payload, callback) {
	request(RESET_PASSWORD, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	updatedUsername: String
 * }
 * @param {String} payload = authToken
 */
export function autoLoginAPI(pathVariables, payload, callback) {
	const api = $replace(AUTO_LOGIN, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} payload  = ACCOUNT_VO
 */
export function signUpAPI(payload, credentials, callback, errorHandler) {
	const headerArgs = {
		Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`
	};
	modelValidator(ACCOUNT_VO, payload) && request(SIGN_UP, POST, payload, callback, errorHandler, null, null, headerArgs);
}

/**
 * @param {String} payload = ownerId
 */
export function logOutAPI(payload, callback) {
	request(LOG_OUT, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	username: String
 * }
 */
export function usernameAvailabilityAPI(pathVariables, callback) {
	const api = $replace(USERNAME_AVAILABILITY, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} payload = AccountSettingsVo {
 * 	ownerId: String,
 * 	field: String // optional, use this if you want to fetch a specific field
 * }
 */
export function getAccountSettingsAPI(payload, callback) {
	request(GET_ACCOUNT_SETTINGS, POST, payload, callback);
}

/**
 * @description model validation isn't required, since we're allowing users to update as many and as little fields as they'd like
 * @param {Object} payload = ACCOUNT_VO
 */
export function saveAccountSettingsAPI(pathVariables, formDataPayload, relevantFields, callback) {
	const api = $replace(SAVE_ACCOUNT_SETTINGS, pathVariables);
	const formData = getFormData(formDataPayload, relevantFields, ACCOUNT_UPDATE_FORM_DATA);

	request(api, POST, formData, callback, null, null, false);
}

/**
 * @param {String} payload = ownerId
 */
export function sendVerificationEmailAPI(payload, callback) {
	request(SEND_VERIFICATION_EMAIL, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	token: String
 * }
 */
export function verifyTokenAPI(pathVariables, callback) {
	const api = $replace(VERIFY_TOKEN, pathVariables);
	request(api, GET, null, callback);
}

/************************** User Types **********************/
/**
 * @param {String} payload = ownerId
 */
export function getUserTypeAPI(payload, callback) {
	request(GET_USER_TYPE, POST, payload, callback);
}
