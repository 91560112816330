export const KEY_TO_URL_MAP = {
	// platform links
	exploreTutor: "/tutoring?show=tutorsList",
	becomeATutor: "/profile?show=tutoring",
	videos: "/video-list",
	articles: "/topics-info-list",
	practice: "/practice-category",
	// company links
	aboutUs: "/about-us",
	// contactUs: "", // implement later
	reportIssue: "", // no actual link
	// resource links
	subscriptions: "/subscription",
	tuteePolicy: "/tutee-policy",
	tutorPolicy: "/tutor-policy",
	// faq: "" // implement later
};
