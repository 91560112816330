import React from "react";

import { messageMapDocument } from "Utilities/MessageMaps";


function TutorCancellationPolicy(props) {
	return (
		<div className="sub-section-div">
			<h3 className="western">
				{messageMapDocument("teacherPolicy.section2.point3.text")}
			</h3>
			<p className="margin-bottom-spacing">
				{messageMapDocument("teacherPolicy.section2.point3.point1.text")}
			</p>
			<p className="margin-bottom-spacing">
				{messageMapDocument("teacherPolicy.section2.point3.point2.text")}
			</p>

		</div>
	);
}

export default TutorCancellationPolicy;

