export function getFormData(formDataPayload, relevantFields, formDataType) {
	const formData = new FormData();

	for (let i = 0; i < relevantFields.length; ++i) {
		const formKey = relevantFields[i];

		if (Array.isArray(formDataPayload[formKey])) {
			formDataPayload[formKey].forEach(payloadData => {
				formData.append(formDataType[formKey], payloadData);
			});
		}
		else {
			formData.append(formDataType[formKey], formDataPayload[formKey]);
		}
	}

	return formData;
}