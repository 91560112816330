import React, {useRef} from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import messageMap, { messageMapGeneric, messageMapButton } from "Utilities/MessageMaps";
import { TUTEES_TAB, TUTORS_TAB, TUTORING_FAQ_TABS } from "templates/utilities/PageTabs";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import TabbedPages from "templates/TabbedPages";
import Modal from "templates/Modal";

import TutorRequirements from "pages/FAQs/TutoringFAQs/tutors/TutorRequirements";
import ApplicationProcess from "pages/FAQs/TutoringFAQs/tutors/ApplicationProcess";
import PricingStructure from "pages/FAQs/TutoringFAQs/tutors/PricingStructure";
import PaymentStructure from "pages/FAQs/TutoringFAQs/tutors/PaymentStructure";
import TutoringProcess from "pages/FAQs/TutoringFAQs/tutors/TutoringProcess";

import FifteenMinuteTrial from "pages/FAQs/TutoringFAQs/tutees/FifteenMinuteTrials";
import UserSafety from "pages/FAQs/TutoringFAQs/tutees/UserSafety";
import LessonUrgency from "pages/FAQs/TutoringFAQs/tutees/LessonUrgency";


function TutoringFAQs(props) {

	const tutorFAQsArray = [
		{
			titleKey: "tutoringRequirements",
			component: TutorRequirements
		}, {
			titleKey: "applicationProcess",
			component: ApplicationProcess
		}, {
			titleKey: "pricingStructure",
			component: PricingStructure
		}, {
			titleKey: "paymentStructure",
			component: PaymentStructure
		}, {
			titleKey: "tutoringProcess",
			component: TutoringProcess
		},
	];

	const tuteeFAQsArray = [
		{
			titleKey: "fifteenMinuteTrials",
			component: FifteenMinuteTrial
		}, {
			titleKey: "userSafety",
			component: UserSafety
		}, {
			titleKey: "lessonUrgency",
			component: LessonUrgency
		}
	];

	const tuteeTabRef = useRef(),
		tutorTabRef = useRef();

	function showSubMenuPage(chosenMenuPage) {
		if (chosenMenuPage === TUTEES_TAB) {
			tuteeTabRef.current.className = "faqs-for-users";
			tutorTabRef.current.className = "faqs-for-users hide";
		}
		else if (chosenMenuPage === TUTORS_TAB) {
			tuteeTabRef.current.className = "faqs-for-users hide";
			tutorTabRef.current.className = "faqs-for-users";
		}
	}

	function showFAQ(component) {
		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal} modalClass="tutoring faq-modal" >
				{
					React.createElement(component)
				}
			</Modal>
		);
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	return (
		<div className="tutoring-faqs">
			<Helmet>
				<title>{messageMap("tutoringFAQs.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tutoringFAQs.description", "headerTag")}></meta>
			</Helmet>

			<div className="header">
				{messageMapGeneric("tutoringPage.faqs.header")}
			</div>

			<TabbedPages customClass={"center"} tabType={TUTORING_FAQ_TABS} tabClickHandler={showSubMenuPage} />

			<div className="faqs-for-users" ref={tuteeTabRef}>
				{
					tuteeFAQsArray.map(card =>
						<div key={card.titleKey} className="faq-card">
							<div className="card-title">
								{messageMapGeneric(`tutoringPage.faqs.tutees.${card.titleKey}`)}
							</div>
							<div className="card-description">
								{messageMapGeneric(`tutoringPage.faqs.tutees.${card.titleKey}Description`)}
							</div>
							<button className="read-more-button" onClick={e => showFAQ(card.component)}>
								{messageMapButton("tutoring.readMore")}
							</button>
						</div>
					)
				}
			</div>

			<div className="faqs-for-users hide" ref={tutorTabRef}>
				{
					tutorFAQsArray.map(card =>
						<div key={card.titleKey} className="faq-card">
							<div className="card-title">
								{messageMapGeneric(`tutoringPage.faqs.tutors.${card.titleKey}`)}
							</div>
							<div className="card-description">
								{messageMapGeneric(`tutoringPage.faqs.tutors.${card.titleKey}Description`)}
							</div>
							<button className="read-more-button" onClick={e => showFAQ(card.component)}>
								{messageMapButton("tutoring.readMore")}
							</button>
						</div>
					)
				}
			</div>
		</div>
	);
}

TutoringFAQs.propTypes = {
	setModal: PropTypes.func.isRequired
};

export default TutoringFAQs;

