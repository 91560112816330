// this was designed this way for easier maintenance, scalability, and to support multiple other languages

import {apiWordMap} from "i18n/APIMessageMap";
import {buttonMessageMap} from "i18n/ButtonMessageMap";
import {documentMessageMap} from "i18n/DocumentMessageMap";
import {genericMessageMap} from "i18n/GenericMessageMap";
import {headerTagMessageMap} from "i18n/HeadTagMessageMap";
import {imageMessageMap} from "i18n/ImageMessageMap";
import {labelPlaceholderMessageMap} from "i18n/LabelPlaceholderMessageMap";
import {notificationMessageMap} from "i18n/NotificationMessageMap";
import {subPageNavigationMessageMap} from "i18n/SubPageNavigationMessageMap";
import { pageMenuNavigationMessageMap } from "i18n/PageMenuNavigationMessageMap";
import {tooltipMessageMap} from "i18n/TooltipMessageMap";
import {validationWordMap} from "i18n/ValidationMessageMap";
// subjects message maps
import {mathMessageMap} from "i18n/workbookMaps/gradeSchool/math/MathMessageMap";
import {learningOptimizationMessageMap} from "i18n/workbookMaps/gradeSchool/learningOptimizations/LearningOptimizationMessageMap";
// tutoring message map
import { tutoringMessageMap } from "i18n/tutoring/TutoringMessageMap";

const WORD_MAP = {
	// for API response messages
	"api": apiWordMap,
	// also includes links
	"button": buttonMessageMap,
	// documents like privacy policy, terms and conditions
	"document": documentMessageMap,
	"generic": genericMessageMap,
	// for <header/> metadata
	"headerTag": headerTagMessageMap,
	// this message map will be mainly for the 'alt' attribute for images
	"image": imageMessageMap,
	// input placeholders and input labels
	"labelPlaceholder": labelPlaceholderMessageMap,
	"notification": notificationMessageMap,
	"subPageNavigation": subPageNavigationMessageMap,
	"pageMenuNavigation": pageMenuNavigationMessageMap,
	"tooltip": tooltipMessageMap,
	// for input validation and user action confirmation
	"validation": validationWordMap,

	// ASSET MAPS BY SUBJECT
	// NOTE*: All subject-related article strings are to be housed in their own message maps;
	// This also includes images, imageAlts, buttons, and placeholders that will be shown in the BODY of TopicInfo.jsx and ChangeableShape.jsx
	"math": mathMessageMap,
	// for optimizing learning
	"learningOptimization": learningOptimizationMessageMap,

	// Tutoring Map(s)
	"tutoring": tutoringMessageMap
};

/**
 * @description simple parser that maps a msgKey to its corresponding text in a given map
 * NOTE*: we'll use TEXT (all uppercase) as a special keyword in all maps
 * @param {String} msgKey used to search for text, is case-sensitive
 * @param {String} mapType map in which key is to be searched in
 * @returns text
 */
// TODO: when users are able to choose their language, or when we're able to determine their language preference, update this function to refer to the correct map
export default function messageMap(msgKey, mapType) {
	const msgArr = msgKey.split("."),
				msgArrLength = msgArr.length;
	let currentMap = WORD_MAP[mapType],
			count = 0;

	while (count < msgArrLength) {
		currentMap = currentMap[msgArr[count]];
		++count;
	}

	return currentMap;
}

export function messageMapGeneric(msgKey) {
	return messageMap(msgKey, "generic");
}

export function messageMapImage(msgKey) {
	return messageMap(msgKey, "image");
}

export function messageMapDocument(msgKey) {
	return messageMap(msgKey, "document");
}

export function messageMapButton(msgKey) {
	return messageMap(msgKey, "button");
}

export function messageMapValidation(msgKey) {
	return messageMap(msgKey, "validation");
}
