import {
	twoDShapesDifficultyMap, twoDShapesToAnswerMap, twoDShapesToHintsMap,
	twoDShapesToAssetsMap, twoDShapesToImgAltsMap
} from "assets/topicArticles/Math/Level 01 Math/I- Figures/A. Naming 2D Shapes";
import {
	threeDShapesDifficultyMap, threeDShapesToAnswerMap, threeDShapesToHintsMap,
	threeDShapesToImgAltsMap, threeDShapesToAssetsMap
} from "assets/topicArticles/Math/Level 01 Math/I- Figures/B. Naming 3D Shapes";

const twoDShapesToAttributesMap = {
	difficultyLevels: twoDShapesDifficultyMap,
	alt: twoDShapesToImgAltsMap,
	images: twoDShapesToAssetsMap,
	answers: twoDShapesToAnswerMap,
	hints: twoDShapesToHintsMap,
	totalQuestions: 13
};

const threeDShapesToAttributesMap = {
	difficultyLevels: threeDShapesDifficultyMap,
	alt: threeDShapesToImgAltsMap,
	images: threeDShapesToAssetsMap,
	answers: threeDShapesToAnswerMap,
	hints: threeDShapesToHintsMap,
	totalQuestions: 15
};

export const matchingKeysToElementsMap = {
	"2dShapes": twoDShapesToAttributesMap,
	"3dShapes": threeDShapesToAttributesMap
};



