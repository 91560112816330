import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";

import messageMap from "Utilities/MessageMaps";
import {spokenLanguages} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";

import closeAsset from "assets/icons/common/close.svg";


function FluentLanguages(props) {

	const [cancellableLanguages, setCancellableLanguages] = useState(),
		[hasHitMaxFluentLanguages, setHasHitMaxFluentLanguages] = useState(false);

	const fluentLanguagesRef = useRef([]);

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.fluentLanguages && props.fluentLanguages.length) {
			fluentLanguagesRef.current = props.fluentLanguages;

			const chosenFluentLanguagesDOM = createCancellableLanguages();
			setCancellableLanguages(chosenFluentLanguagesDOM);
		}
	}

	function showFluentLanguages(e) {
		const language = e.target.getAttribute("languageKey");

		if (language === "chooseLanguage") {
			return;
		}

		fluentLanguagesRef.current = fluentLanguagesRef.current.includes(language) ? fluentLanguagesRef.current : fluentLanguagesRef.current.concat(language);
		props.setFluentLanguages(fluentLanguagesRef.current);

		const chosenFluentLanguagesDOM = createCancellableLanguages();
		setCancellableLanguages(chosenFluentLanguagesDOM);

		// set arbitrary limit of 10 languages
		fluentLanguagesRef.current.length === 10 && setHasHitMaxFluentLanguages(true);
	}

	function removeCancellableLanguage(language) {
		fluentLanguagesRef.current.splice(fluentLanguagesRef.current.indexOf(language), 1);
		props.setFluentLanguages(fluentLanguagesRef.current);

		const chosenFluentLanguagesDOM = createCancellableLanguages();
		setCancellableLanguages(chosenFluentLanguagesDOM);
		setHasHitMaxFluentLanguages(false);
	}

	function createCancellableLanguages() {
		let chosenFluentLanguagesDOM = [];
		const chosenFluentLanguagesList = fluentLanguagesRef.current;
		for (let i = 0; i < chosenFluentLanguagesList.length; ++i) {
			const savedLanguage = chosenFluentLanguagesList[i];

			chosenFluentLanguagesDOM.push(
				<div key={savedLanguage} className="fluent-languages-container">
					<div className="fluent-language">
						{savedLanguage}
					</div>
					<button className="remove-fluent-language" onClick={e => removeCancellableLanguage(savedLanguage)}>
						<img className="x-icon" src={closeAsset} alt="ex icon"></img>
					</button>
				</div>
			);
		}

		return chosenFluentLanguagesDOM;
	}

	return (
		<div className="page-field multi-lines">
			<label className="page-field-label">
				{messageMap("profilePage.tutoring.modal.application.fluentLanguages", "generic")}
			</label>
			<div className="inline-elements">
				<div className="multi-line-inlines">
					<Dropdown customDropdownItemAttribute="languageKey"
						customContainerClass={`tutoring-application no-right-margin ${hasHitMaxFluentLanguages && "not-allowed"}`}
						dropdownOptions={spokenLanguages} dropdownItemClickHandler={showFluentLanguages} />
					{cancellableLanguages}
				</div>
			</div>
		</div>
	);
}

FluentLanguages.propTypes = {
	fluentLanguages: PropTypes.array,
	setFluentLanguages: PropTypes.func.isRequired
};

export default FluentLanguages;

