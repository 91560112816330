import { fileSizeConvert, MB } from "Utilities/FileSizeConverter";
import { messageMapValidation } from "Utilities/MessageMaps";


/**
 * @description checks if inputVal satisfies the following:
 * 	1) is at least 7 characters long
 * 	2) contains at least 1 uppercase letter
 * 	3) contains at least 1 lowercase letter
 * 	4) contains at least 1 number
 * 	5) contains at least one of the following special characters: !@#$%^&*
 * @param {String} inputVal password to validate
 * @returns {Boolean} if inputVal satisfies password requirements
 */
export function passwordValidator(inputVal) {
	let validationStatus = {};

	validationStatus["passed"] = false;
	validationStatus["hasLower"] = RegExp("^(?=.*[a-z])").test(inputVal);
	validationStatus["hasUpper"] = RegExp("^(?=.*[A-Z])").test(inputVal);
	validationStatus["hasNumber"] = RegExp("^(?=.*[0-9])").test(inputVal);
	validationStatus["hasSpecial"] = RegExp("^(?=.*[!@#$%^&*])").test(inputVal);
	validationStatus["hasLength"] = RegExp("^(?=.{7,})").test(inputVal);

	validationStatus["hasLower"] && validationStatus["hasUpper"] 
	&& validationStatus["hasNumber"] && validationStatus["hasSpecial"] 
	&& validationStatus["hasLength"] && (validationStatus["passed"] = true);

	return validationStatus;
}

/**
 * @description checks if email has an @ and if it has a '.'
 * @param {String} inputVal email to validate
 * @returns {Boolean} if inputVal is actually an email
 */
export function emailValidator(inputVal) {
	return RegExp("^(?=.*[@])(?=.*[.])").test(inputVal);
}

/**
 * @description checks if username is at least 5 characters long and is no more than 20 characters long. Only allow alphanumeric characters
 * @param {String} inputVal userName to validate
 * @returns {Boolean} if inputVal satisfies userName requirements
 */
export function userNameValidator(inputVal) {
	let validationStatus = {};

	validationStatus["hasLength"] = RegExp("^(?=[a-zA-Z0-9]{5,20})").test(inputVal);

	validationStatus["hasLength"] && (validationStatus["passed"] = true);

	return validationStatus;
}

/**
 * @description 
 * @param {String} inputVal input value to validate
 * @returns {Boolean} if inputVal satisfies userName requirements
 */
export function stringLengthValidator(inputVal, requiredLength) {
	return inputVal.length === requiredLength;
}

/**
 */
export function numberValidator(value) {
	let isValid = true;

	const numberRegExp = new RegExp("[^0-9]");
	if (numberRegExp.exec(value) && numberRegExp.exec(value)[0]) {
		isValid = false;
	}

	return isValid;
}

/**
 * @description simply checks for invalid characters as user types
 */
export function phoneNumberValidatorOnChange(value) {
	let isValid = true,
		errorMsgs = "";

	// const phoneNumberRegExp = new RegExp("[0-9]{3}\-[0-9]{3}\-[0-9]{4}");
	const phoneNumberRegExp = new RegExp("[^0-9-()]");
	if (phoneNumberRegExp.exec(value) && phoneNumberRegExp.exec(value)[0]) {
		isValid = false;
		errorMsgs = messageMapValidation("input.phoneNumber.invalidNumber");
	}


	return [isValid, errorMsgs];
}

/**
 * @description expects the format: (###)-###-####
 */
export function phoneNumberValidatorComplete(value) {
	let isValid = true,
		errorMsgs = "";

	const phoneNumberRegExp = /\([0-9]{3}\)-[0-9]{3}-[0-9]{4}/;
	if (phoneNumberRegExp.exec(value) == null) {
		isValid = false;
		errorMsgs = messageMapValidation("input.phoneNumber.invalidFormat");
	}


	return [isValid, errorMsgs];
}

/**
 * @param {Array} acceptedFileTypes 
 * @param {Array} widthRange [minWidth, maxWidth] unit is pixels
 * @param {Array} heightRange [minHeight, maxHeight] unit is pixels
 * @param {Number} memoryLimit unit is MB
 * @param {Object} imgData 
 * @param {Object} uploadedFile 
 */
export function imageValidator(acceptedFileTypes, widthRange, heightRange, memoryLimit, imgData, uploadedFile) {
	let errorMsgs = "",
		hasViolations = false;

	const fileTypes = acceptedFileTypes.map((fileType) => `image/${fileType}`);
	if (!fileTypes.includes(uploadedFile.type)) {
		errorMsgs += (messageMapValidation("img.fileType.notAccepted") + uploadedFile.type +  " ");
		errorMsgs += (messageMapValidation("img.fileType.whatIsAccepted") + acceptedFileTypes.join(", ") +  " ");
		hasViolations = true;
	}

	if (widthRange != null) {
		if (widthRange[0] != null && imgData.target.naturalWidth < widthRange) {
			hasViolations = true;
			errorMsgs += `${messageMapValidation("img.dimensions.underWidth")} ${widthRange[0]} ${messageMapValidation("img.dimensions.pixelsUnder")}`;
		}
		else if (widthRange[1] != null && imgData.target.naturalWidth > widthRange) {
			hasViolations = true;
			errorMsgs += `${messageMapValidation("img.dimensions.overWidth")} ${widthRange[1]} ${messageMapValidation("img.dimensions.pixelsOver")}`;
		}
	}
	if (heightRange != null) {
		if (heightRange[0] != null && imgData.target.naturalHeight < heightRange) {
			hasViolations = true;
			errorMsgs += `${messageMapValidation("img.dimensions.underHeight")} ${heightRange[0]} ${messageMapValidation("img.dimensions.pixelsUnder")}`;
		}
		else if (heightRange[1] != null && imgData.target.naturalWidth > heightRange) {
			hasViolations = true;
			errorMsgs += `${messageMapValidation("img.dimensions.overHeight")} ${heightRange[1]} ${messageMapValidation("img.dimensions.pixelsOver")}`;
		}
	}

	if (memoryLimit != null && memoryLimit !== 0 && memoryLimit < fileSizeConvert(uploadedFile.size, MB)) {
		errorMsgs += (messageMapValidation("img.fileSize.overMemoryLimit1") + memoryLimit + messageMapValidation("img.fileSize.overMemoryLimit2") + " ");
		hasViolations = true;
	}

	const filename = uploadedFile.name;
	if (filename.includes(" ")) {
		errorMsgs += (messageMapValidation("img.filename.noSpaces") + " ");
		hasViolations = true;
	}
	else if (filename.search(/[~!@#$%^&*()_+]/) !== -1) {
		errorMsgs += (messageMapValidation("img.filename.noSpecialCharacters") + " ");
		hasViolations = true;
	}

	return {
		errorMsgs: errorMsgs,
		hasViolations: hasViolations
	}
}
