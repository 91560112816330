import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/wellBeing/thumbnailDiet.jpg";
import nervousSystemAsset from "assets/topicArticles/LearningOptimizations/wellBeing/nervousSystem.png";
import nerveCellAsset from "assets/topicArticles/LearningOptimizations/wellBeing/nerveCell.png";
import carbsAsset from "assets/topicArticles/LearningOptimizations/wellBeing/carbs.png";
import fatsAsset from "assets/topicArticles/LearningOptimizations/wellBeing/fats.png";


export const wellBeingDietArticle = {
	id: "III. Well Being_2_0 How our diet affects our brain health and the foods we should eat and avoid",
	title: messageMap("diet.article.title", "learningOptimization"),
	description: messageMap("diet.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("diet.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2",
		"text3", "text4", "text5",
		"text6", "text7", "text8",
		"text9", "text10", "text11", "html1", "text12", "html2", "text13", "text14",
		"text15", "text16", "html3", "text17", "text18",
		"text19", "html4", "text20", "text21", "text22", "text23", "text24", "text25", "text26", "text27",
		"text28", "text29",
		"text30", "text31",
		"text32", "text33",
		"text34", "text35",
		"text36", "text37", "text38", "text39", "text40"
	],
	datePublished: "2022-03-06",
	dateModified: "2024-06-23"
};

export const wellBeingDietArticleTexts = {
	text1: {
		text: messageMap("diet.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("diet.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("diet.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("diet.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("diet.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("diet.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text7: {
		text: messageMap("diet.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("diet.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("diet.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text10: {
		text: messageMap("diet.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("diet.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("diet.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("diet.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("diet.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("diet.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text16: {
		text: messageMap("diet.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("diet.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("diet.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("diet.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text20: {
		text: messageMap("diet.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("diet.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("diet.texts.p16", "learningOptimization"),
		type: DESCRIPTION
	},
	text23: {
		text: messageMap("diet.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text24: {
		text: messageMap("diet.texts.p18", "learningOptimization"),
		type: DESCRIPTION
	},
	text25: {
		text: messageMap("diet.texts.p19", "learningOptimization"),
		type: DESCRIPTION
	},
	text26: {
		text: messageMap("diet.texts.p20", "learningOptimization"),
		type: DESCRIPTION
	},
	text27: {
		text: messageMap("diet.texts.p21", "learningOptimization"),
		type: DESCRIPTION
	},
	text28: {
		text: messageMap("diet.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text29: {
		text: messageMap("diet.texts.p22", "learningOptimization"),
		type: DESCRIPTION
	},
	text30: {
		text: messageMap("diet.texts.header8", "learningOptimization"),
		type: SUB_HEADER
	},
	text31: {
		text: messageMap("diet.texts.p23", "learningOptimization"),
		type: DESCRIPTION
	},
	text32: {
		text: messageMap("diet.texts.header9", "learningOptimization"),
		type: SUB_HEADER
	},
	text33: {
		text: messageMap("diet.texts.p24", "learningOptimization"),
		type: DESCRIPTION
	},
	text34: {
		text: messageMap("diet.texts.header10", "learningOptimization"),
		type: SUB_HEADER
	},
	text35: {
		text: messageMap("diet.texts.p25", "learningOptimization"),
		type: DESCRIPTION
	},
	text36: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text37: {
		text: messageMap("diet.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text38: {
		text: messageMap("diet.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text39: {
		text: messageMap("diet.sources.s3", "learningOptimization"),
		type: SOURCE
	},
	text40: {
		text: messageMap("diet.sources.s4", "learningOptimization"),
		type: SOURCE
	}
};


export const wellBeingDietArticleHTMLs = {
	html1: (
		<img key="nervousSystem" src={nervousSystemAsset} alt="Nervous system" />
	),
	html2: (
		<img key="nerveCell" src={nerveCellAsset} alt="nerve cell components" />
	),
	html3: (
		<img key="carbs" src={carbsAsset} alt="different types of carbohydrates" />
	),
	html4: (
		<img key="fats" src={fatsAsset} alt="different types of fats" />
	)
};
