import {
	SET_OWNER_ID, REMOVE_OWNER_ID,
	SET_LOG_IN_TIMEOUT_START_TIME, CLEAR_LOG_IN_TIMEOUT,
	SET_IP, SET_IP_LOCKED, SET_IP_CREATED_AT,
  UPDATE_NEURONS_BALANCE,
	UPDATE_SHINY_NEURONS_BALANCE} from "redux/actions/accountConstants";

import {CLEAR_API_KEYS} from "redux/actions/apiConstants";

import {INCREMENT_LOGIN_ATTEMPT_COUNT, CLEAR_LOGIN_ATTEMPT_COUNT,
				OPEN_SIGNUP_MODAL, CLOSE_SIGNUP_MODAL,
				OPEN_LOGIN_MODAL, CLOSE_LOGIN_MODAL,
				CLEAR_NOTIFICATIONS, UNCLEAR_NOTIFICATIONS} from "redux/actions/genericConstants";

import { SET_SHINY_NEURONS_CONVERSION_RATE } from "./metadataConstants";

import { SET_PAYMENT_INFO, CLEAR_PAYMENT_INFO } from "./paymentConstants";

import {SET_QUEST_PATH, SET_CURRENT_TOPIC} from "./knowledgeConstants";

import {SET_PROFILE_SUB_NAV, SET_TUTORING_SUB_NAV} from "./subNavConstants";


/******************** Account actions *******************/
export function logInUser(user) {
	return {
		type: SET_OWNER_ID,
		payload: user
	};
}
export function logOutUser() {
	return {
		type: REMOVE_OWNER_ID
	};
}
export function setLogInTimeoutStartTime(startTime, duration, callback) {
	return {
		type: SET_LOG_IN_TIMEOUT_START_TIME,
		payload: {
			duration: duration,
			startTime: startTime,
			callback: callback
		}
	};
}
export function clearLogInTimeout() {
	return {
		type: CLEAR_LOG_IN_TIMEOUT
	};
}

export function setIP(ip) {
	return {
		type: SET_IP,
		payload: {
			ip: ip
		}
	}
}
export function setIPLocked(isLocked) {
	return {
		type: SET_IP_LOCKED,
		payload: {
			isLocked: isLocked
		}
	}
}
export function setIPCreatedAt(createdAt) {
	return {
		type: SET_IP_CREATED_AT,
		payload: {
			createdAt: createdAt
		}
	}
}

/******************** API Keys actions *******************/
export function clearApiKeys() {
	return {
		type: CLEAR_API_KEYS
	};
}

/******************** Global generic actions *******************/
export function incrementLoginAttemptCount() {
	return {
		type: INCREMENT_LOGIN_ATTEMPT_COUNT
	};
}
export function clearLoginAttemptCount() {
	return {
		type: CLEAR_LOGIN_ATTEMPT_COUNT
	};
}

export function openSignUpModal() {
	return {
		type: OPEN_SIGNUP_MODAL
	};
}
export function closeSignUpModal() {
	return {
		type: CLOSE_SIGNUP_MODAL
	};
}

export function openLoginModal() {
	return {
		type: OPEN_LOGIN_MODAL
	};
}
export function closeLoginModal() {
	return {
		type: CLOSE_LOGIN_MODAL
	};
}

export function clearNotificationsAction() {
	return {
		type: CLEAR_NOTIFICATIONS
	};
}
export function unclearNotificationAction() {
	return {
		type: UNCLEAR_NOTIFICATIONS
	};
}

/******************** Payment actions *******************/
export function setShinyNeuronConversionRate(conversionRate) {
	return {
		type: SET_SHINY_NEURONS_CONVERSION_RATE,
		payload: conversionRate
	};
}

export function setPaymentInfo(paymentInfo) {
  const { imgRef, price, quantity, description, purpose } = paymentInfo;
  return {
    type: SET_PAYMENT_INFO,
    payload: { imgRef, price, quantity, description, purpose }
  };
}
export function clearPaymentInfo() {
	return {
		type: CLEAR_PAYMENT_INFO
	};
}

export function updateShinyNeuronsBalance(shinyNeurons) {
	return {
		type: UPDATE_SHINY_NEURONS_BALANCE,
		payload: { shinyNeurons }
	};
}

/******************** Knowledge Quest actions *******************/
export function setQuestPath(questPath) {
	return {
		type: SET_QUEST_PATH,
		payload: questPath
	};
}

export function setCurrentTopic(topicToSet) {
	return {
		type: SET_CURRENT_TOPIC,
		payload: topicToSet
	};
}

/******************** Sub Navigations actions *******************/
export function setProfileSubNav(profileSubPage) {
	return {
		type: SET_PROFILE_SUB_NAV,
		payload: profileSubPage
	};
}
export function setTutoringSubNav(tutoringSubPage) {
	return {
		type: SET_TUTORING_SUB_NAV,
		payload: tutoringSubPage
	};
}

