import React from "react";

import messageMap from "Utilities/MessageMaps";

import informationAsset from "assets/icons/alerts/information.svg";
import heartAsset from "assets/icons/class/overview/heart.svg";
import shareAsset from "assets/icons/class/overview/share.svg";
import giftAsset from "assets/icons/class/overview/gift.svg";
import checklistAsset from "assets/icons/class/overview/checklist.svg";
import importantAsset from "assets/icons/class/overview/important.svg";
import barsAsset from "assets/icons/class/overview/bars.svg";
import structureAsset from "assets/icons/class/overview/structure.svg";
import runAsset from "assets/icons/class/overview/run.svg";
import chestAsset from "assets/icons/class/overview/chest.svg";
import guidepostAsset from "assets/icons/class/overview/guidepost.svg";
import instructorAsset from "assets/icons/class/overview/instructor.svg";
import updateAsset from "assets/icons/class/overview/update.svg";
import bulbAsset from "assets/icons/class/overview/bulb.svg";
import reviewsAsset from "assets/icons/class/overview/reviews.svg";
import videoAsset from "assets/icons/class/overview/video.svg";
import nodeAsset from "assets/icons/class/overview/node.svg";

export function courseDetailsSchema(refList, localCourseDetails, createReviewSection, response) {
	return  [
		{
			icon: "checklist",
			iconPath: checklistAsset,
			alt: messageMap("classPage.tooltips.checklist.alt", "generic"),
			description: messageMap("classPage.tooltips.checklist.text", "generic"),
			ref: refList.requirementsRef,
			toggleRef: refList.reqChevRef,
			details: localCourseDetails.requirements
		}, {
			icon: "important",
			iconPath: importantAsset,
			alt: messageMap("classPage.tooltips.relevance.alt", "generic"),
			description: messageMap("classPage.tooltips.relevance.text", "generic"),
			ref: refList.relevanceRef,
			toggleRef: refList.relevanceChevRef,
			details: localCourseDetails.relevance
		}, {
			icon: "bars",
			iconPath: barsAsset,
			alt: messageMap("classPage.tooltips.coverage.alt", "generic"),
			description: messageMap("classPage.tooltips.coverage.text", "generic"),
			ref: refList.coverageRef,
			toggleRef: refList.coverageChevRef,
			details: localCourseDetails.coverage
		}, {
			icon: "structure",
			iconPath: structureAsset,
			alt: messageMap("classPage.tooltips.structure.alt", "generic"),
			description: messageMap("classPage.tooltips.structure.text", "generic"),
			ref: refList.structureRef,
			toggleRef: refList.structureChevRef,
			details: localCourseDetails.structure
		}, 
		// {
		// 	icon: "run",
		// 	iconPath: runAsset,
		// 	alt: messageMap("classPage.tooltips.pacing.alt", "generic"),
		// 	description: messageMap("classPage.tooltips.pacing.text", "generic"),
		// 	ref: refList.pacingRef,
		// 	toggleRef: refList.pacingChevRef,
		// 	details: localCourseDetails.pacing
		// }, 
		{
			icon: "chest",
			iconPath: chestAsset,
			alt: messageMap("classPage.tooltips.goals.alt", "generic"),
			description: messageMap("classPage.tooltips.goals.text", "generic"),
			ref: refList.goalsRef,
			toggleRef: refList.goalsChevRef,
			details: localCourseDetails.goals
		}, {
			icon: "guidepost",
			iconPath: guidepostAsset,
			alt: messageMap("classPage.tooltips.guidepost.alt", "generic"),
			description: messageMap("classPage.tooltips.guidepost.text", "generic"),
			ref: refList.guidepostRef,
			toggleRef: refList.guideChevRef,
			details: localCourseDetails.guidance
		}, {
			icon: "instructor",
			iconPath: instructorAsset,
			alt: messageMap("classPage.tooltips.instructor.alt", "generic"),
			description: messageMap("classPage.tooltips.instructor.text", "generic"),
			ref: refList.instructorRef,
			toggleRef: refList.instructorChevRef,
			details: localCourseDetails.instructor
		}, {
			icon: "update",
			iconPath: updateAsset,
			alt: messageMap("classPage.tooltips.updates.alt", "generic"),
			description: messageMap("classPage.tooltips.updates.text", "generic"),
			ref: refList.updateRef,
			toggleRef: refList.updateChevRef,
			details: localCourseDetails.updates
		}, 
		// {
		// 	icon: "bulb",
		// 	iconPath: bulbAsset,
		// 	alt: "A bulb icon. Click to see related courses",
		// 	description: "Related Courses",
		// 	details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
		// 	sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
		// 	Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
		// 	nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
		// 	reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
		// 	pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa 
		// 	qui officia deserunt mollit anim id est laborum.`
		// }, 
		{
			icon: "reviews",
			iconPath: reviewsAsset,
			alt: messageMap("classPage.tooltips.reviews.alt1", "generic"),
			description: messageMap("classPage.tooltips.reviews.text", "generic"),
			ref: refList.reviewsRef,
			toggleRef: refList.reviewsChevRef,
			details: localCourseDetails.reviews,
			children: createReviewSection(response[1].data, response[2].data)
		}
	]
}

export function overviewSchema(openModal, focusOnSection) {
	return [
		{
			icon: "video",
			iconPath: videoAsset,
			alt: messageMap("classPage.tooltips.videoIntro.alt", "generic"),
			description: messageMap("classPage.tooltips.videoIntro.text", "generic"),
			clickHandler: openModal
		}, {
			icon: "node",
			iconPath: nodeAsset,
			alt:  messageMap("classPage.tooltips.node.alt", "generic"),
			description: messageMap("classPage.tooltips.node.text", "generic"),
			clickHandler: openModal
		}, {
			icon: "chest",
			iconPath: chestAsset,
			alt: messageMap("classPage.tooltips.goals.alt", "generic"),
			description: messageMap("classPage.tooltips.goals.text", "generic"),
			clickHandler: focusOnSection
		}, {
			icon: "review",
			iconPath: reviewsAsset,
			alt: messageMap("classPage.tooltips.reviews.alt2", "generic"),
			description: messageMap("classPage.tooltips.reviews.text", "generic"),
			clickHandler: focusOnSection
		}
	];
}

// TODO: firstVideoData should be whatever video the user left from most recently
export function overviewActionsSchema(classBought, firstVideoData, classId) {
	const videoTitle = firstVideoData ? firstVideoData.title.replaceAll(" ", "_") : '';
	let idObj;
	if (classBought.current && classId) {
		idObj = {
			videoId: firstVideoData.id,
			classId: classId
		}
	}

	return [
		// TODO: uncomment when we have more courses
		// {
		// 	icon: heartAsset,
		// 	label: "Wishlist",
		// 	alt: "A heart icon. Click to add to wishlist."
		// }, 
		// TODO: uncomment when we allow social media sharing
		// {
		// 	icon: shareAsset,
		// 	label: "Share",
		// 	alt: "An arrow icon. Click to share to others."
		// }, 
		// TODO: uncomment when we allow this functionality (recipient to be identified by their name and email)
		// {
		// 	icon: giftAsset,
		// 	label: "Gift this course",
		// 	alt: "A gift icon. Click to send the course to a friend"
		// },
		{
			label: classBought.current ? "Go to the course" : "Buy now",
			link: classBought.current ? "/learn?title=" + videoTitle : "&checkout=true",
			id: idObj
		}
	];
}

export function modalSchema(classCategory, classCat, classId) {
	return {
		video: {
			closeType: "xButton",
			content: (
				<video controls>
					<source src={"/" + process.env.PUBLIC_URL + "videos/real1.mp4"} type="video/mp4"/>
				</video>
			),
			modalContainerStyle: {
				"padding": "0"
			},
			closeButtonStyle: {
				"position": "absolute",
				"marginLeft": "600px",
				"marginTop": "15px",
				"zIndex": "1"
			}
		},
		node: {
			closeType: "xButton",
			modalContainerStyle: {
				"backgroundColor": "white",
				"padding": "0",
				"height": "80vh",
				"width": "75vw"
			},
			closeButtonStyle: {
				"position": "absolute",
				"marginLeft": "600px",
				"marginTop": "15px",
				"zIndex": "1"
			},
			icon: {
				type: informationAsset,
				tooltip: {
					subheader: "The class will cover these topics",
					containerStyle: {
						"marginTop": "-30px"
					}
				}
			},
			stateProps: [{
				state: "iconTooltipDisplay",
				defValue: "hide"
			}],
			content: (
				<div>
					{/* TODO: this should eventually be modifiable */}
					<img src={"/" + process.env.PUBLIC_URL + `images/classes/${classCategory.current ? classCategory.current : classCat}/${classId}/mentalMap.svg`} 
							alt={messageMap("learn.mentalModel.text", "image")}
							style={{
								height: "80vh",
								width: "75vw",
								"padding": "50px",
								"paddingBottom": "100px"
							}}></img>
				</div>
			)
		}
	};
}

export const tableOfContentsSchema = {
	"checklist": "Requirements",
	"important": "Relevance",
	"bars": "Conceptual coverage",
	"structure": "Structure",
	// commenting out, since content design isn't final
	// "run": "Recommended pacing",
	"chest": "Goals",
	"guidepost": "Things to keep in mind",
	"update": "Latest updates",
	"review": "Reviews"
}