import request, {GET, POST} from "Utilities/Fetches";

const BASE = "/services/payments";

const GET_SHINY_NEURONS_CONVERSION_RATE = BASE + "/get/shinyNeurons/conversionRate";
const QUOTE = BASE + "/quote";
const CREATE_PAYMENT_INTENT = BASE + "/create/paymentIntent";
const FINALIZE_PAYMENT_INTENT = BASE + "/finalize/paymentIntent";


export function getShinyNeuronsConversionRateAPI(callback) {
	request(GET_SHINY_NEURONS_CONVERSION_RATE, GET, null, callback);
}

/**
 * Returns neuron quotes.
 */
export function quoteAPI(callback, fallback) {
	request(QUOTE, GET, null, callback, fallback);
}

/**
 * @param {Object} payload  = {
 * 	ownerId: String,
 * 	paymentAmount: Number,
 * 	quantityPurchased: Number,
 * 	paymentDescription: String,
 * 	paymentPurpose: String,
 * 	isPaymentOffSession: Boolean?,
 * 	sessionId: String?,
 * 	tutorOwnerId: String?
 * }
 */
export function createPaymentIntentAPI(payload, callback) {
	request(CREATE_PAYMENT_INTENT, POST, payload, callback);
}

/**
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	clientSecret: String,
* 	paymentIntentId: String
 * }
 */
export function finalizePaymentIntentAPI(payload, callback) {
	request(FINALIZE_PAYMENT_INTENT, POST, payload, callback);
}

