import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/thumbnailPrescience.jpg";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";


export const mentalityAndAttitudePrescienceArticle = {
	id: "II. Mentality & Attitude_1_2 How prescience and our expectations shape our resilience",
	title: messageMap("prescience.article.title", "learningOptimization"),
	description: messageMap("prescience.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("prescience.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2",
		"text3", "text4",
		"text5", "text6", "text7", "text8",
		"text9", "text10", "text11",
		"text12", "text13",
		"text14", "text15",
		"text16", "text17", "text18", "text19", "text20"
	],
	datePublished: "2023-12-16",
	dateModified: "2024-06-21"
};

export const mentalityAndAttitudePrescienceArticleTexts = {
	text1: {
		text: messageMap("prescience.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("prescience.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("prescience.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("prescience.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("prescience.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("prescience.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("prescience.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("prescience.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("prescience.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text10: {
		text: messageMap("prescience.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("prescience.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("prescience.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text13: {
		text: messageMap("prescience.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("prescience.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text15: {
		text: messageMap("prescience.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text17: {
		text: messageMap("prescience.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text18: {
		text: messageMap("prescience.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text19: {
		text: messageMap("prescience.sources.s3", "learningOptimization"),
		type: SOURCE
	},
	text20: {
		text: messageMap("prescience.sources.s4", "learningOptimization"),
		type: SOURCE
	}
};

