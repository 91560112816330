export const mentalityAndAttitudeMessageMap = {
	misconception: {
		article: {
			title: "Common misconceptions about learning and how learning actually works",
			description: "Learn the misconceptions behind looking everything up, superfast learning, superfast memory, instant expertise, and brain games.",
			thumbnailAlt: "A typewriter containing a paper that has the text 'Fake News'."
		},
		texts: {
			header1: "Module introduction",
			p1: `Welcome to another module about optimizing your learning. In this module, <b>we'll learn how our mentality and 
				attitude affect our learning, and what we can do to improve it. We'll learn the common misconceptions about 
				learning, how our intentions can shape how our mind works, the effects of prescience, how our expectations 
				and state of mind can affect our performance, and the power of perseverance.</b>`,
			p2: `We have a lot to learn in this module, but, before getting to our first topic, we need to define some 
				key terms. What exactly do we mean by mentality and attitude?  And, what's the difference between prescience 
				and expectations? Aren't these two pairs basically the same thing? Well, looking at the Merriam-Webster dictionary 
				definition, <b><u>mentality</u> is one's mode or way of thought; this basically means it's how we understand and interpret 
				things. And by <u>attitude</u>, we mean a mental and/or emotional position towards a fact or state. To put it plainly, 
				it's our mannerism towards a person and/or thing.</b>`,
			p3: `And finally, prescience and expectations. <b><u>Prescience</u> is our ability to know, for a fact, that a specific event 
				will happen in the future, while <u>expectation</u> is the act or state of looking forward to something.</b> Now, having that 
				out of the way, before moving to the first topic, let's get our brains active and going by trying to think of the 
				many things we think about when we're learning. Going through school, what are some of the beliefs, expectations, 
				and attitudes that you've developed, and are these beliefs and expectations generally accurate? Did your attitude 
				have, in any way, affected how things eventually turned out?`,
			p4: `Having thought about these questions, let's try to keep them at the back of our minds, since we might learn that 
				some of our long-held beliefs might turn out to be false and counterproductive in our progress to become better learners.`,
			p5: `That's it for this module's introduction. Let's start our first topic where we clarify the many misconceptions 
				that people have about learning.`,
			header2: "Introduction",
			p6: `Misconceptions. They're everywhere in our lives and, although most of them are benign, some can be quite 
				concerning. In this article, we'll learn <b>(1) how you just can't google everything and expect to always 
				understand things, (2) the misconception behind superfast learning, (3) super memory, (4) being an instant 
				expert, and (5) the false promise behind brain games.</b>`,
			p7: `I'm sure some of us might have already encountered people and/or companies that have promised these things 
				by using their product, and that some of us were smart enough to avoid them. But to the unfortunate few who 
				ended up buying them, we'll go over the reasons why their claims are largely false.`,
			p8: `Before we start clarifying these misconceptions, let's briefly think to ourselves why they are misconceptions. 
				What do we know about the process of learning that can help explain why we can't just google everything we don't 
				know? Why is there no such thing as superfast learning, super memory, and being an instant expert?`,
			header3: "Looking everything up",
			p9: `To start things off, a growing number of people are starting to believe that you don't need to understand and 
				remember everything you learned, since you can just look everything up. While this is true in some cases, having 
				this mentality places a dangerous precedent. Why? Because knowledge is a collection of interconnected information. 
				For example, if we're trying to learn a new word, and its definition is also hard to understand, we then have to 
				understand parts of its definition before we can understand the whole definition; to understand the whole, we must 
				understand its parts. If we were to do this for every complicated thing we encounter, our brain wouldn't be able to 
				keep up, since it's simply too much cognitive load to ask our brain to understand and remember a concept's parts and 
				piece everything together. This is why we don't teach college-level material to elementary or middle school students 
				and tell them to google anything they don't understand. So, <b>we can't just look everything up, since it places too much 
				cognitive load on our brain and this cognitive load stems from trying to understand the whole concept, even if we don't 
				understand parts of the concept.</b>`,
			header4: "Learning imitations",
			p10: `Next misconceptions include superfast learning, super memory, and the promise of becoming immediate experts. I see 
				this everywhere on the internet and I even hear about it from working professionals. While we can actually greatly improve 
				our learning speed and improve our ability to recall certain facts more quickly, given that we get enough practice and 
				exposure in a specific field, <b>the process of becoming an expert takes time.</b>`,
			p11: `And while some of the techniques they teach in the programs that promise these things are sometimes useful, they're only 
				an imitation of what actual learning is.`,
			header5: "What is learning?",
			p12: `But, before we unravel these imitations, it's important to understand what learning actually means. Depending on where you 
				look, you'll surely get many definitions, but, overall, <b>it's the process of building on prior knowledge and/or skills through 
				instruction, experience, or through studying.</b> With that in mind, let's start unraveling some more misconceptions.`,
			header6: "Superfast learning",
			p13: `Superfast learning. <b>How quickly you can learn a new concept depends on what you already know</b>, since information is built on 
				top of each other, <b>and if your brain is primed for the new information</b>, which basically means that your brain, after receiving 
				some sort of stimulus, is in a specific state that affects how it responds to a follow-up stimulus; this response can be either 
				to more easily, or arduously, accept the new information. To put into an example, if you're asked to learn some new concept in 
				math, say algebra, and you already have a solid foundation in arithmetic, it'll be a lot easier for you to understand the new 
				concepts, compared to someone who is just learning their addition and subtraction. To give an example about priming your brain, 
				let's say that you're told, ahead of time, that you will learn about rates of change. Because you were told ahead of time about 
				this, chances are, your mind subconsciously tried recollecting everything you knew about rates of change. So when you actually do 
				start learning about rates of change in class, because your mind was primed for this information, which makes any knowledge you have 
				about rates of change feel fresher, you will more likely have a better and faster time learning about rates of change, compared to 
				someone who wasn't told anything about it beforehand. So, how quickly you learn new concepts depends on what you already know and 
				if you were primed to receive the new concepts.`,
			p14: `<b>It'll also depend on how well you organize your thoughts.</b> Yes, you heard that right; there is actually a way to organize our 
				thoughts to make our learning and the way we remember faster and more efficient. Since this is a big enough topic to merit its own 
				set of articles, we'll analyze this more in our metacognition articles.`,
			header7: "Super memory",
			p15: `For super memory, <b>it's more about how a piece of information in your head, and sometimes your muscles, is tied to another set of 
				information and how often the neural path, which contains this information, gets used whenever you're recalling something.</b> For example, 
				when we're reviewing for a test, some information feels fresher than others, since our reviewing helped reinforce the neural paths 
				containing that information. <b>The more we review something, the faster we'll be able to recall it.</b>`,
			p16: `While there are a lot of books written about people with super memory and even competitions held, where people compete to memorize as 
				many things as they can, given an amount of time, usually decks of cards, or long strings of numbers, it's, again, not actual learning. 
				What they're doing is using memory techniques to create an informational glue to bind unrelated information together.`,
			p17: `For example, you might've heard of mnemonics, where you take the first letter of each word and turn them into a memorable phrase, or 
				sentence, such as 'My Very Educated Mother Just Served Us Nine Pizzas', which is a mnemonic for the former set of nine planets, when Pluto 
				used to be classified as a planet. Other techniques include acronyms and memory palaces.`,
			p18: `So, while these techniques are very useful when you want to remember something as fast as possible, like a list of groceries, they give 
				the false impression that the person is learning, since the information they stored in their heads isn't strongly built on prior knowledge 
				or skills. Furthermore, these information sets, or information islands, as I like to call them, are also easier to forget, because they're not 
				built on and connected to established knowledge. We'll go more in detail in our physiology articles on why some “studying” methods, such as 
				memorization, are inefficient use of your time.`,
			header8: "Instant experts",
			p19: `The next misconception on our list is becoming an instant expert. Although many people already know that we can't become experts overnight, 
				we want to be thorough in our approach and clarify this possible misconception anyway, and we'll do this simply by telling what research already 
				says. <b>It's impossible. To become an expert, you need to, among other things, dedicate a lot of time, be goal-oriented, constantly obtain supportive 
				and helpful feedback, put in accumulated successful practice, and frequently review and analyze your progress.</b> We'll learn more about what 
				each of these means and how to properly become an expert in our how-to-become-an-expert articles. For now, it's simple to say that there's no such 
				thing as overnight experts.`,
			header9: "Brain games",
			p20: `And finally, for our last misconception, brain games that promise to make you smarter by playing their games. It's a multibillion-dollar industry, 
				but a good majority of them are based on unfounded claims, and the scientific sources that these companies usually cite are dubious. Additionally, 
				there's a pressing issue on the games' transferability, whether playing a certain game would make you better in certain areas, such as abstract and 
				creative thinking, which can apply to subjects such as math, physics, and the arts. It's often pointed out that <b>people will benefit more if they simply 
				engage the skills and knowledge that they want to work on directly.</b> To put it as an example, if I'm trying to get better at drawing, instead of playing 
				a game that hopes to improve this skill, I will make more progress if I simply draw more. A more thorough exploration of this topic is provided via the 
				sources below.`,
			header10: "Conclusion",
			p21: `So, to wrap up this article, <b>(1) we can't just look everything up, since information is built on top of and is related to other information; (2) how 
				fast we learn depends on what we already know, if our brain is primed for the information, and how efficiently we organize our thoughts; (3) having superfast 
				memory isn't always a sign of learning, since they don't build on top of our prior knowledge, skill, or experience; (4) we can't be instant expert, since real 
				expertise comes from dedicated practice, patience, feedback, and time; and (5) we need to be cautious of brain games, since solid research is still lacking 
				to support brain games' overall utility in actually making us any smarter.</b>`
		},
		sources: {
			s1: "Christodoulou, D. (2020). Seven myths about education Daisy Christodoulou. MTM. ",
			s2: "A consensus on the Brain Training Industry from the scientific community (full). Stanford Center on Longevity. (2017, December 22). https://longevity.stanford.edu/a-consensus-on-the-brain-training-industry-from-the-scientific-community-2/ ",
			s3: "Ericsson, A. (2016). Peak: Secrets from the New Science of Expertise. Audible Studios on Brilliance Audio. ",
			s4: "Oakley, B. A., Rogowsky, B., & Sejnowski, T. J. (2021). Uncommon sense teaching: Practical insights in brain science to help students learn. TarcherPerigee, an imprint of Penguin Random House LLC. "
		}
	},
	intentions: {
		article: {
			title: "How our intentions affect how our mind works",
			description: "Learn how staying engaged and multitasking affect how our mind works .",
			thumbnailAlt: "Text 'goal' on top of a black background."
		},
		texts: {
			header1: "Introduction",
			p1: `Let's now learn how intentions can affect how our mind works. But before diving 
				into the details, let's connect to the topic more by looking into our usual intentions when we're studying. 
				What is our usual intention when we're forced to study? What is our usual intention when we're studying a 
				subject that we like? And, how do these intentions affect our ability to concentrate and retain what we're 
				learning?`,
			header2: "Intentions",
			p2: `As you might've noticed, <b>our intentions when we're about to study, and while we study, have a significant 
				effect on our concentration, how quickly we learn the material, and how well we can retain the new information.</b> 
				This aligns perfectly with what research says.`,
			header3: "Multitasking",
			p3: `Furthermore, <b>our intention to learn can be greatly improved if we don't multitask</b>; this way, it'll be easier 
				to focus on one thing at a time and reduce unnecessary cognitive load. Despite what research says about multitasking, 
				there are still people who believe they could effectively multitask, even though they were also shown to be the 
				worst performers in tests; the multitaskers were poorly organized and were failing to use their memories efficiently; 
				they also reported heightened levels of stress and frustration due to task switching.`,
			header4: "Conclusion",
			p4: `To summarize, <b>by staying engaged and focused, we prime our brain to more efficiently receive, remember, 
				and analyze information, and our overall process is greatly optimized if we don't multitask.</b>`
		},
		sources: {
			s1: `Autin, F., & Croizet, J.-C. (2012). Improving working memory efficiency by reframing metacognitive interpretation of task difficulty. Journal of Experimental Psychology: General, 141(4), 610-618. https://doi.org/10.1037/a0027478 `
		}
	},
	prescience: {
		article: {
			title: "How prescience and our expectations shape our resilience",
			description: "Learn how prescience and expectations affects resilience and progress.",
			thumbnailAlt: "Stationary binoculars overlooking a city."
		},
		texts: {
			header1: "Introduction",
			p1: `On to our next topic about mentality and attitude, we'll learn about prescience, the knowledge of things, 
				before they happen, or exist. Do you remember your response to the questions we asked in this module's introduction 
				article, specifically regarding how your beliefs might affect the outcome of things? In addition to that, try to think 
				of the times when you're absolutely sure that certain things would happen and that they actually happened. How did 
				your mind and body react when that happened?`,
			header2: "Prescience",
			p2: `As you might've noticed, knowing events before they occur improves how our body responds to the stimulus. <b>If 
				you know what's going to happen, you're more prepared and resilient, especially when things get difficult or don't 
				go as planned.</b>`,
			header3: "Your journey ahead",
			p3: `So, to give a brief overview of the many great things that you might encounter on your journey for the pursuit of 
				knowledge, skills, and greater consciousness, it will be fun; it'll be cool and awesome; but, it'll also be 
				challenging. Your imagination will be stretched. How you see the world will be reformatted in many ways that you can count 
				and even notice. You might even get headaches trying to make sense of things. And you, as a person, might even change. But, 
				as you continue in this journey, you will notice that, because of the vast knowledge and experience you've acquired through 
				the years, you are more capable of handling certain tasks; your skills in creating things are honed; the many challenges you've 
				faced in the past become mere child's play; and you are more adept at withstanding both mental and physical difficulties. Taken 
				to extremes, you may find yourself standing alongside famous mathematicians, scientists, engineers, athletes, and artists. 
				You may even advance the pool of human knowledge and/or push beyond what we understood as our physical limits.`,
			p4: `<b>Knowing that the journey will be long and arduous, and yet fun and exciting, it's important to be prepared and to have the 
				right approach</b>; this is one of the biggest reasons why we created this platform, to help you use your time wisely and to get 
				to where you want to be as efficiently as possible, by using proven methodologies through decades of research.`,
			p5: `Now that you have a slightly better idea of the journey ahead, it's time for us to discuss how expectations from ourselves 
				can greatly improve our progress in this journey.`,
			header4: "Self-expectations",
			p6: `Many psychologists, sociologists, and even athletes can attest to the power of setting self-expectations. The most common 
				example of this is through self-talk, where we remind ourselves of the things that we've accomplished and what 
				we're capable of, to help energize ourselves and to get us more focused on the task at hand. This concept is true for 
				any challenges we face in life. <b>The positive effects of self-talk, which have been researched numerous times, have been shown 
				to significantly improve our effectiveness in successfully completing extremely challenging tasks, in addition to drastically 
				improving our endurance and resilience.</b> Examples of when you want to use self-talk include finishing the remaining repetitions 
				in a workout, preparing yourself to go on stage, and competing in some sports.`,
			p7: `On the other side of this, <b>people who set low expectations for themselves have demonstrated lower quality of work and much 
				lower resilience.</b> So, if we believe and expect that we can do something, in addition to using positive self-talk, our chances 
				of successfully completing that task increase.`,
			header5: "Caution around self-expectations",
			p8: `I do need to point out one small caveat. Although setting expectations for oneself has been shown to improve one's performance 
				in completing tasks, setting expectations that are just too high can be debilitating to our health as well. <b>One reason why setting 
				expectations improves our performance is due to self-induced stress. Contrary to common belief, stress has been shown to improve 
				our immune system, and our heart health, and even boost our memory. Stress only becomes a problem when it's consistent over a 
				prolonged period of time, spanning from several weeks to several months, and when we don't take rest seriously.</b> Eventually, our 
				immune system weakens, we get depressed, and anxious, have higher blood pressure, and even develop heart disease. We'll discuss the 
				details of how to best handle stress in our well-being articles.`,
			header6: "Conclusion",
			p9: `So, to sum things up, <b>by knowing what's ahead of you, you improve your resilience, and setting the right balance of self-expectations, 
				not too hard and not too easy, will greatly quicken your progress on who you eventually want to be.</b>`
		},
		sources: {
			s1: `American Psychological Association. (n.d.-b). Apa PsycNet. American Psychological Association. https://psycnet.apa.org/record/1991-02964-001`,
			s2: `American Psychological Association. (n.d.). Apa PsycNet. American Psychological Association. https://psycnet.apa.org/record/1991-02964-001 `,
			s3: `Hattie, J., & Yates, G. C. R. (2014). Visible learning and the science of how we learn. Routledge, Taylor & Francis Group. `,
			s4: 'Stixrud, W. R., & Johnson, N. (2019). The self-driven child: The science and sense of giving your kids more control over their lives. Penguin Books. '
		}
	},
	stateOfMind: {
		article: {
			title: "How our state of mind influences our learning",
			description: "Learn how positive and negative input affect our mood and productivity and how we can improve them.",
			thumbnailAlt: "A man high up in mountains overlooking clouds in the distance."
		},
		texts: {
			header1: "Introduction",
			p1: `Now that we've finished covering prescience and expectations, it's now time to briefly learn how our 
				state of mind affects our overall performance. Let's try to recall some of the times when we tried to learn 
				or study something when we were sad, angry, or happy. Did our state of mind help, or hinder, our ability to 
				learn and concentrate?`,
			header2: "Negative and positive input",
			p2: `If you happen to notice a positive correlation between your state of mind and your learning, then you're 
				already in the right direction. In several studies that aimed to find connections between people's mood and 
				their overall performance, it has been shown that <b>people who report being happy, energized, and calm experience 
				at least 12% greater productivity. Conversely, people who report feeling stressed, tired, frustrated, etc., in 
				addition to being exposed to negative inputs, for example depressing news, have been shown to have decreased productivity.</b>`,
			header3: "Improving our mood",
			p3: `As we all know, there are many things that we can do to change and improve our mood, but, since this is a 
				fairly large topic, we decided to dedicate a set of articles discussing this, in our well-being module. But, 
				to give a brief rundown on some of them, they are the following: <b>(1) Find a relaxing environment, ideally where 
				there are some plants and trees. (2) Do some breathing exercises. (3) Re-focus your thoughts on something more 
				positive. And, (4) maintain a healthy diet and exercise regularly.</b>`,
			header4: "Conclusion",
			p4: `To summarize what we've learned in this short article, <b>we need to try to remain calm, happy, and energized, 
				so we can boost our productivity. And any time we find ourselves straying from this, we can (1) find a relaxing 
				environment, ideally where there are some plants and trees, (2) do some breathing exercises, (3) re-focus our 
				thoughts on something more positive, and (4) maintain a healthy diet and exercise regularly.</b>`
		},
		sources: {
			s1: "Caillet, A., Hirshberg, J., & Petti, S. (2024, April 15). How your state of mind affects your performance. Harvard Business Review. https://hbr.org/2014/12/how-your-state-of-mind-affects-your-performance ",
			s2: "Dalton, P. S., Gonzalez, V. H., & Noussair, C. N. (2016). Exposure to poverty and productivity. SSRN Electronic Journal. https://doi.org/10.2139/ssrn.2814946 ",
			s3: "Oswald, A. J., Proto, E., & Sgroi, D. (2015). Happiness and productivity. Journal of Labor Economics, 33(4), 789-822. https://doi.org/10.1086/681096 "
		}
	},
	pepTalk: {
		article: {
			title: "Perseverance: a small pep talk",
			description: "Learn how the most accomplished people in history started from humble beginnings.",
			thumbnailAlt: "Chalk marks on a wall that says 'Dream Big'."
		},
		texts: {
			header1: "Introduction",
			p1: `And finally, for our last point in this module, perseverance. In our everyday pursuit of the many things we 
				want in life, we seldom think of the many challenges we've faced in the past that helped shape who we are today. 
				So, to help us appreciate the many challenges we overcame and to better prepare us for the challenges ahead, instead 
				of starting this article with a question prompt, I thought it'd be nice to let you guys know of my own struggles, so 
				we can use my experience as an example to show you that, if I can do it, then so can you.`,
			header2: "Feeble beginnings to realization",
			p2: `We're all born with varying strengths and weaknesses, some are smarter than others, some are stronger, quicker, 
				more artistic, etc. Unfortunately, I wasn't lucky enough to be born into either the smart or athletic bunch. When I 
				was just a kid back in the Philippines, my mom realized that my reasoning skills were weak, and that I was lagging 
				behind in spelling, grammar, and reading; she would also later add that I was physically weak, due to my asthma. Well, 
				to at least help me academically, she decided to hire a tutor. But even after hiring a tutor and coming to America at 
				the age of 10, my dad noticed that I was still lacking in a few areas, specifically in writing and math. So, he decided 
				to teach me during his spare time. Eventually, with my parents' help, a few years passed, and I somehow managed to 
				get into this competitive high school in San Francisco.`,
			p3: `But after getting accepted, I quickly realized that, despite the amount of effort I was putting into my studies, 
				I was finding myself struggling a lot, while it seemed like my peers were just coasting and still managing to get 
				good grades. I became frustrated, envious, and even irritated.`,
			p4: `Because of my competitive nature, I became obsessed with learning how people get to be so smart. At some point, 
				I even started buying into the idea that maybe some people might just be born smart, same with how some people are 
				just more athletic, and more artistic, and that there's nothing that the struggling people can do to change that. 
				And to cope with this realization, I even got into the habit of seeing whether the person I'm talking to is smarter 
				or dumber than me, just so I can feel better about myself knowing that I'm at least smarter than this person.`,
			p5: `It wasn't until I got to college that I realized how my mentality and behavior surrounding intelligence and our 
				capability for change was completely wrong. I realized that, yes, some people are born smarter, stronger, faster, 
				and more artistic than others, but this doesn't mean that they'll continue to be in years to come, if they don't 
				put in the work. People are born with a set of traits that they inherit from their parents, but it's ultimately up 
				to them to forge the kind of people they're going to be. With enough support, commitment, and opportunities, people 
				can become who they really want to be, and research into becoming an expert supports all these. If you ever look at 
				the top athletes, scientists, and engineers in our history, you will notice their maniacal focus on their craft eventually 
				made them the people we know them to be. And some of these admirable people came from very humble beginnings.`,
			header3: "History's inspiring people",
			p6: `Albert Einstein, who's considered to be the greatest physicist of all time due to his theory of relativity, had 
				ADHD. He was very slow to learn how to talk; he dropped out of school when he was 15; and, when he took the entrance 
				exam for a polytechnic school in Zurich, he failed. `,
			p7: `George Washington Carver, who became Iowa State's 1st black faculty member and is known for improving the US's 
				agricultural economy by promoting nitrogen-providing peanuts as an alternative crop to cotton to prevent soil depletion, 
				was born into slavery. He was kidnapped when he was just a week old and has struggled against discrimination his whole 
				life. And when he was applying to universities to improve his education, he was consistently denied because of his skin color.`,
			p8: `These are just examples of what effort, consistency, and dedication can get you. Despite the odds these people faced, 
				they decided to persevere, and now, their image is forever immortalized in our history books.`,
			header4: "Where we are now",
			p9: `As for my story, I graduated as a computer engineer, became a software engineer, and became a part of a company's software 
				architecture team in 3.5 years. While my competitive nature is still there to help me keep pushing forward, to grow as a 
				better person, and to learn as much as I can about intelligence, I'm now focused on efficiently cultivating the full potential 
				of the coming generation. That means you, who's reading this article right now, are in for a treat. I will continue providing 
				updates on anything I find helpful in helping you reach your utmost potential. I'm also planning on creating classes that help 
				people develop in other dimensions, besides academically, such as emotionally and psychologically, so keep a look out for those.`,
			p10: `I know this article isn't as research-focused and as short as the others, but I feel that these things need to be said for a 
				lot of people who don't believe in their potential. No matter the obstacles, no matter the challenges, things can improve if we 
				keep pushing forward. If you're still not convinced, the article following this provides evidence of what I'm talking about.`
		},
		sources: {
			s1: `30 star athletes who came from humble beginnings - page 30. Sport Scroll. (2023, July 5). https://sportscroll.com/30-star-athletes-who-came-from-humble-beginnings/30/ `,
			s2: "A&E Television Networks. (n.d.). Albert Einstein: Fact or fiction?. History.com. https://www.history.com/topics/inventions/einsteins-life-facts-and-fiction ",
			s3: "Dean, T. (n.d.). You'd never guess these famous people have ADHD. news 24 | Life. https://www.news24.com/health24/medical/adhd/about-adhd/Ten-of-the-most-successful-people-with-ADHD-20140718 ",
			s4: "Doc, T. (n.d.). 11 Great Scientists Who Rose From Harsh Beginnings. Famous Scientists: The Art of Genius. https://www.famousscientists.org/scientists-harsh-begin/ "
		}
	},
	perseveranceScience: {
		article: {
			title: "The science behind perseverance",
			description: "Learn how confidence and having the right approach allow us to reach unprecedented heights in our lives.",
			thumbnailAlt: "Man in a lab examining something under a microscope while a computer captures some data."
		},
		texts: {
			header1: "Introduction",
			p1: `In this article, we'll focus on <b>the science regarding our mentality when we decide to persevere. We'll also 
				learn what our approach should be for the long run, some routines that we need to get accustomed to, and 
				the roadblocks that we'll encounter along the way.</b> Please take note that what we're about to discuss can be a 
				little abstract, since this has been extracted from numerous scenarios and studies.`,
			header2: "Low and high confidence",
			p2: `Starting with what research says about confidence. <b>If you have low self-confidence, you're more likely to perform 
				poorer and have less mastery overall, and these two effects can lower your confidence even further. Conversely, 
				if you have high self-confidence, you're more likely to perform better and have higher mastery, which, again, feeds 
				into the cycle of your self-confidence.</b> The same concept applies if you're interested in the subject, or not.`,
			header3: "The right approach",
			p3: `As you can see, we need to first <b>start with the belief that we can do it</b>. Once we get into this mindset, we can <b>then 
				work on our approach on how to solve our problems</b>, regardless of how big, or small they may be. For every problem, there 
				is a solution. The question is: 'how do we get the solution?'. But, what are solutions really? Aren't solutions just ideas 
				that happen to fix the problem? And aren't ideas just some creative ways of making use of some information? So the question 
				now becomes: 'how do we get the right information we need to fix the problem'?`,
			p4: `Well, there are many ways to get information, but we need to first <b>make sure that we understand the problem completely</b>. 
				By doing so, we avoid wasting a lot of time finding a solution to something that isn't actually the problem to begin with. 
				Additionally, this is also an opportunity to see what other people have already tried to solve the problem; looking into 
				this can help save us some time getting to the solution. So, the first step is understanding the problem. `,
			p5: `Once we get this down, we can then <b>focus on obtaining the information</b>. The most common way to get information is to either 
				ask (1) people who are familiar with the subject, usually experts, or (2) we can do our own research, or both. We then need 
				to ensure that the information is relevant to the problem by learning how to apply it to the problem. This way, we can also get 
				rid of informational noise, which is basically information that can distract us and information that doesn't serve our purpose.`,
			p6: `Next, after getting the information that we need, we need to also <b>consider any additional resources that we can use to fix 
				the problem</b>. This is usually the part where things get really tricky, depending on how small or big the problem you're trying to 
				solve. There's no simple straightforward answer to this, since it'll greatly depend on your current situation, financial capabilities, 
				the people you know, the kind of resources you have access to, what you currently can and can't do, and much more. Since discussion 
				of this can get complicated very quickly and will eventually go outside the scope of learning, we'll proceed with the discussion 
				under the assumption that you already have all the resources you need to solve your problem.`,
			p7: `At this point, you might've already arrived at the solution, but it might not be the best solution. Or, you actually arrived at 
				the solution, but how you applied it was wrong, or can be improved. Or maybe, the information you applied turned out not to be the 
				solution, in which case you need to go back to the drawing board and go through the process again. As you can see, this is where 
				<b>you need to be creative and persistent</b>, since this might also mean that you need to re-frame the problem, to understand the problem 
				from a different angle, which means that you might also need to change your approach overall. If you continue to follow this process, 
				you'll eventually arrive at the solution.`,
			header4: "Scientific approach",
			p8: `The reason why I can confidently say these things is because research supports it, and it's almost the same method that scientists 
				and inventors follow when they try to come up with solutions themselves; scientists often call this method the 'scientific method'. 
				And if you look around you, <b>the things we take for granted nowadays, such as our smartphones and computers, are all formerly goals of 
				people who were trying to solve a problem</b>. So, it might not be much of a stretch to say that challenges in life are inherently doable.`,
			header5: "Roadblocks",
			p9: `Now that you're aware of the overall approach, we should now learn those roadblocks I mentioned earlier. Since these challenges 
				you're facing might take a while to solve, there's a chance that you might doubt yourself and/or feel discouraged along the way, especially 
				when you think you failed.`,
			p10: `I say 'you think you failed' because failure in itself is actually progress; you now have a better understanding of the problem and what 
				works and what doesn't, compared to when you just started. <b>The only time you actually fail is when you decide to give up.</b>`,
			p11: `Anyway, back to the topic. Feeling doubtful and discouraged is completely normal, since you most likely have no freaking idea what 
				you're doing or have never done something similar before. Many people before you have gone through this experience and have even been ridiculed 
				by a lot of people; I know I've been. So it's important to allow yourself to understand and process these feelings. By doing so, you can better 
				understand your struggles and what you can do to improve your situation.`,
			header6: "Focusing on what matters",
			p12: `But while it's important to understand and process our difficulties, we shouldn't dwell on this kind of mentality forever, since it eventually 
				becomes unproductive. It also makes it more likely for us to give up, and ultimately even risk the very reasons we started in the first place. 
				Furthermore, <b>research shows that students who focus on working on the task more, instead of their current capabilities, end up performing better 
				and have higher chances of success.</b> Why do you think this is? It's because <b>the students were allowing themselves to improve</b>, which is why <b>we also 
				need to cultivate a growth mindset</b>, the mindset that, through dedication and hard work, we can become better; who we are right now and who we will 
				be tomorrow can be completely different, if we give ourselves the chance to change for the better.`,
			header7: "We're living a marathon, not a race",
			p13: `And finally, to conclude this article, we have to remind ourselves that we're not on a race, but on a marathon. We have to learn to pace ourselves, 
				to take breaks more seriously, and to appreciate the many successes we've had in the past whenever we get a chance, so that we can remind ourselves 
				that, if we put our mind and heart into it, we can achieve anything.`,
			header8: "Conclusion",
			p14: `To summarize what we learned in this article, we need to believe that we can do it. <b>We need to have the right approach to the problem by understanding 
				the problem, obtaining the right information, and correctly applying what we learned. We then acknowledge, understand, learn, and move on from our self-doubt. 
				We have to learn to pace ourselves. And finally, we need to have the right mindset, that we can do it, that problems are solvable, that we need to allow 
				ourselves to grow, and that it's a marathon, not a race.</b>`,
			p15: `And that's all for our mentality and attitude module. To summarize what we've learned, <b>there's basically no magic formula to getting past the hard work 
				of getting smarter; what we can do is to optimize the entire learning process by developing the right plan, the right mentality, the right approach, and by 
				properly pacing ourselves.</b> And if anyone noticed that a lot of these have many similarities with how top athletes are coached, that's exactly one of the main 
				ideas of this entire class; we need to have the same mental fortitude if we want to get anywhere. I know this is a lot to take in, and I hope you guys took 
				the time to analyze how these concepts can apply to your studies, and any other challenges you might have outside of school moving forward. We'll also learn 
				more about perseverance in our how-to-become-an-expert module, so stay tuned. Until next time.`
		},
		sources: {
			s1: `Blackwell, L. S., Trzesniewski, K., & Dweck, C. S. (n.d.). (PDF) implicit theories of intelligence predict achievement across an adolescent transition: A 
				longitudinal study and an intervention. Research Gate. https://www.researchgate.net/publication/6477294_Implicit_Theories_of_Intelligence_Predict_Achievement_Across_an_Adolescent_Transition_A_Longitudinal_Study_and_an_Intervention `,
			s2: "Duckworth, A. (2018). Grit: The power of passion and perseverance. Scribner. ",
			s3: `Friedman, R. A. (2015, October 23). Can you get smarter?. The New York Times. https://www.nytimes.com/2015/10/25/opinion/sunday/can-you-get-smarter.html `,
			s4: `Ruhl, C. (2024, February 1). Theories of intelligence in psychology. Simply Psychology. https://www.simplypsychology.org/intelligence.html `
		}
	}
};