import React from "react";
import PropTypes from "prop-types";

import Pane from "templates/Pane";
import PublicTutorProfile from "../PublicTutorProfile";


function TutorProfilePane(props) {

	return (
		<Pane customClass="tutor-profile">

			<PublicTutorProfile isSaved={props.isSaved} tutorDetails={props.tutorDetails} showBookOption={props.showBookOption}
				setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} closePane={props.closePane} />

		</Pane>
	);
}

TutorProfilePane.propTypes = {
	isSaved: PropTypes.bool.isRequired,
	tutorDetails: PropTypes.object.isRequired,
	showBookOption: PropTypes.bool,

	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	closePane: PropTypes.func.isRequired,
};

export default TutorProfilePane;