import React, {useState, useEffect, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import {numberWords, ordinalWord, ordinalNumber, romanNumerals} from "pages/Classes/Practice/utilities/MathUtilities";
import messageMap from "Utilities/MessageMaps";

import basketballAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/basketball.svg";


function CountableObjects(props) {

	const inputCountRef = useRef(),
		stepSizeRef = useRef();

	const [countableObject, setCountableObject] = useState(),
		[objectCount, setObjectCount] = useState(props.objectCount),
		[stepSize, setStepSize] = useState(1);

	useEffect(() => {
		const numberOfObjects = !props.useOrdinals ? objectCount : 1;
		let objectList = [];
		for (let i = 0; i < numberOfObjects; ++i) {
			objectList.push(
				<img key={i} src={basketballAsset} alt="Basketball"></img>
			);
		}

		const objectBundle = (
			<Fragment>
				<div className={`object-images ${props.widthClass} ${!props.usePictures && "hide"}`}>
					{objectList}
				</div>
				<div className="number-word">
					{props.useOrdinals ? ordinalWord[objectCount] : (props.useRomanNumerals ? romanNumerals[objectCount] : numberWords[objectCount])}
				</div>
				<div className="object-count">
					{
						props.countable
						?
						<div className="changeable-count">
							<div className="count-text">
								{messageMap("comparisons.quantity.count", "math")}
							</div>
							<input className="prop-change" type="number" min={0} max={99} step={stepSize}
								value={numberOfObjects}
								onChange={e => changeObjectCount(null, e)} ref={inputCountRef}></input>
							<div className="change-prop-container">
								<button className="chevron-up"
									onClick={e => changeObjectCount("increase")}></button>
								<button className="chevron-down"
									onClick={e => changeObjectCount("decrease")}></button>
							</div>

							{
								props.changeableSteps
								?
								<Fragment>
									<div className="step-size-text">
										{messageMap("comparisons.quantity.stepSize", "math")}
									</div>
									<input className="prop-change" type="number" min={1} max={10}
										onChange={e => changeInputStepSize(null, e)} ref={stepSizeRef}></input>
									<div className="change-prop-container">
										<button className="chevron-up"
											onClick={e => changeInputStepSize("increase")}></button>
										<button className="chevron-down"
											onClick={e => changeInputStepSize("decrease")}></button>
									</div>
								</Fragment>
								: null
							}
						</div>
						: (!props.useOrdinals ? numberOfObjects : ordinalNumber[objectCount])
					}
				</div>
			</Fragment>
		);

		setCountableObject(objectBundle);
	}, [objectCount, stepSize]);

	function changeInputStepSize(increaseOrDecrease, e) {
		let newStepSize;

		if (!increaseOrDecrease && newStepSize !== stepSize) {
			newStepSize = Number(e.target.value);
			if (newStepSize > -1) {
				setStepSize(newStepSize);
			}
		}
		else if (["increase", "decrease"].includes(increaseOrDecrease)) {
			if (increaseOrDecrease === "increase" && stepSize !== 11) {
				newStepSize = stepSize + 1;
			}
			else if (increaseOrDecrease === "decrease" && stepSize !== 0) {
				newStepSize = stepSize - 1;
			}

			if (newStepSize !== -1 && newStepSize != null) {
				stepSizeRef.current.value = newStepSize;
				setStepSize(newStepSize);
			}
		}
	}

	function changeObjectCount(increaseOrDecrease, count) {
		let newCount;
		if (!increaseOrDecrease) {
			count = Number(count.target.value);
			if (count > -1) {
				newCount = count;
				inputCountRef.current.value = newCount;
				setObjectCount(count);
				props.changeHandler && props.changeHandler(newCount, props.position);
			}
		}
		else if (["increase", "decrease"].includes(increaseOrDecrease)) {
			if (increaseOrDecrease === "increase" && objectCount !== 99) {
				newCount = objectCount + (props.changeableSteps ? stepSize : 1);
			}
			else if (increaseOrDecrease === "decrease" && objectCount !== 0) {
				newCount = objectCount - (props.changeableSteps ? stepSize : 1);
			}

			if (newCount !== -1 && newCount != null) {
				inputCountRef.current.value = newCount;
				setObjectCount(newCount);
				props.changeHandler && props.changeHandler(newCount, props.position);
			}
		}
	}

	return (
		<div className={`countable-objects ${props.widthClass}`}>
			{countableObject}
		</div>
	);
}

CountableObjects.defaultProps = {
	widthClass: "",
	usePictures: true,
	useOrdinals: false,
	useRomanNumerals: false,
	changeableSteps: false
};

CountableObjects.propTypes = {
	countable: PropTypes.bool,
	widthClass: PropTypes.string,
	objectCount: PropTypes.number.isRequired,
	changeHandler: PropTypes.func,
	position: PropTypes.any,
	usePictures: PropTypes.bool,
	useOrdinals: PropTypes.bool,
	useRomanNumerals: PropTypes.bool,
	changeableSteps: PropTypes.bool
};

export default CountableObjects;

