import messageMap from "Utilities/MessageMaps";

// user types
export const DEFAULT_TYPE = "default";
export const TUTOR_TYPE = "tutor";

export const REQUIRED_FIELDS_KEY_LABEL_MAP = {
	// personal information
	firstLastName: messageMap("account.fields.name", "generic").toLowerCase(),
	firstLastNameKana: messageMap("account.fields.nameKana", "generic").toLowerCase(),
	firstLastNameKanji: messageMap("account.fields.nameKanji", "generic").toLowerCase(),
	dateOfBirth: messageMap("account.fields.dob", "generic").toLowerCase(),
	// contact information
	phoneNumber: messageMap("account.fields.phone", "generic").toLowerCase(),
	// location
	line1Address: messageMap("account.fields.streetAddress", "generic").toLowerCase(),
	postalCodeAddress: messageMap("account.fields.postalCode", "generic").toLowerCase(),
	postalCodeAddressKana: messageMap("account.fields.postalCodeKana", "generic").toLowerCase(),
	postalCodeAddressKanji: messageMap("account.fields.postalCodeKanji", "generic").toLowerCase(),
	cityAddress: messageMap("account.fields.city", "generic").toLowerCase(),
	stateAddress: messageMap("account.fields.state", "generic").toLowerCase()
};

