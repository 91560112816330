export async function downloadFile(fileBlob) {
	const file = await fetch(fileBlob);
	const blobFile = await file.blob();
	const dataUrl = URL.createObjectURL(blobFile);

	const link = document.createElement('a');
	link.href = dataUrl;
	link.download = fileBlob.replace(/^.*[\\/]/, '');
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export async function showPdfFileInTab(fileBlob) {
	const file = await fetch(fileBlob);
	const blobFile = await file.blob();
	const dataUrl = URL.createObjectURL(blobFile);

	window.open(dataUrl, '_blank');
}

export function getFileFormatFromTargetFile(targetFile) {
	return targetFile.name.split(".").at(-1);
}
