import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/physiology/thumbnailEfficientStorage.jpg";
import efficientStorageAsset from "assets/topicArticles/LearningOptimizations/physiology/efficientStorage.svg";



export const physiologyEfficientStorageArticle = {
	id: "V. Physiology_4_3 How information gets stored in our brain and how we can do it more efficiently",
	title: messageMap("efficientStorage.article.title", "learningOptimization"),
	description: messageMap("efficientStorage.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("efficientStorage.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4", "text5", "text6",
		"text7", "text8", "text9", "text10",
		"text11", "text12", "text13", "text14", "text15",
		"text16", "text17", "text18", "text19", "text20", "text21",
		"text22", "text23", "text24", "text25", "text26",
		"text27", "text28", "html1", "text29",
		"text30", "text31", "text32", "text33"
	],
	datePublished: "2023-12-29",
	dateModified: "2024-06-26"
};

export const physiologyEfficientStorageArticleTexts = {
	text1: {
		text: messageMap("efficientStorage.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("efficientStorage.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("efficientStorage.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("efficientStorage.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("efficientStorage.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("efficientStorage.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("efficientStorage.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text8: {
		text: messageMap("efficientStorage.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("efficientStorage.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("efficientStorage.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("efficientStorage.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text12: {
		text: messageMap("efficientStorage.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("efficientStorage.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("efficientStorage.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("efficientStorage.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("efficientStorage.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text17: {
		text: messageMap("efficientStorage.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("efficientStorage.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("efficientStorage.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("efficientStorage.texts.p16", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("efficientStorage.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("efficientStorage.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text23: {
		text: messageMap("efficientStorage.texts.p18", "learningOptimization"),
		type: DESCRIPTION
	},
	text24: {
		text: messageMap("efficientStorage.texts.p19", "learningOptimization"),
		type: DESCRIPTION
	},
	text25: {
		text: messageMap("efficientStorage.texts.p20", "learningOptimization"),
		type: DESCRIPTION
	},
	text26: {
		text: messageMap("efficientStorage.texts.p21", "learningOptimization"),
		type: DESCRIPTION
	},
	text27: {
		text: messageMap("efficientStorage.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text28: {
		text: messageMap("efficientStorage.texts.p22", "learningOptimization"),
		type: DESCRIPTION
	},
	text29: {
		text: messageMap("efficientStorage.texts.p23", "learningOptimization"),
		type: DESCRIPTION
	},
	text30: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text31: {
		text: messageMap("efficientStorage.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text32: {
		text: messageMap("efficientStorage.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text33: {
		text: messageMap("efficientStorage.sources.s3", "learningOptimization"),
		type: SOURCE
	}
};

export const physiologyEfficientStorageArticleHTMLs = {
	html1: (
		<img key="efficientStorageAsset" src={efficientStorageAsset} alt="model of how information progresses from sensory memory, to short-term and working memory, and to long-term memory" style={{width: "-webkit-fill-available"}} />
	)
};

