/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace, fillOptionalArgs} from "Utilities/Strings";

import { ACCEPT_DECISION, DECLINE_DECISION } from "pages/Tutoring/utilities/TutoringRequestConstants";

const BASE = "/services/tutor/sessions";
// for tutees
const CREATE_TUTOR_SESSIONS_REQUEST = BASE + "/create";
const DELETE_TUTOR_SESSIONS_REQUEST = BASE + "/delete";
const EDIT_TUTOR_SESSIONS_REQUEST = BASE + "/edit";
const ACCEPT_TUTOR_REQUEST_APPLICATION = BASE + `/${ACCEPT_DECISION}/application?tutorProfileId=\${tutorProfileId}&sessionRequestId=\${sessionRequestId}`;
const DECLINE_TUTOR_REQUEST_APPLICATION = BASE + `/${DECLINE_DECISION}/application?tutorProfileId=\${tutorProfileId}&sessionRequestId=\${sessionRequestId}`;
// for tutors
export const GET_TUTOR_SCHEDULED_SESSIONS = BASE + "/get/tutor/scheduled?ownerId=${ownerId}";
export const GET_TUTOR_LIVE_SESSIONS = BASE + "/get/tutor/live?ownerId=${ownerId}";
export const GET_TUTOR_COMPLETED_SESSIONS = BASE + "/get/tutor/completed?ownerId=${ownerId}";
const APPLY_TO_SESSION_REQUEST = BASE + "/apply?sessionRequestId=${sessionRequestId}";
const ACCEPT_TUTOR_REQUEST = BASE + `/${ACCEPT_DECISION}/request?tutorProfileId=\${tutorProfileId}&sessionRequestId=\${sessionRequestId}`;
const DECLINE_TUTOR_REQUEST = BASE + `/${DECLINE_DECISION}/request?tutorProfileId=\${tutorProfileId}&sessionRequestId=\${sessionRequestId}`;
// for tutors and tutees
export const GET_TUTOR_SESSION_REQUESTS = BASE + "/get/tutor/requests?sessionStates=${sessionStates}&sentOrReceived=${sentOrReceived}&intendedUser=${intendedUser}&tutorOwnerId=${tutorOwnerId}";
const GET_OPEN_REQUESTS_COUNT = BASE + "/get/openRequests/count?tutorOwnerId=${tutorOwnerId}";
const CANCEL_TUTOR_SESSION = BASE + "/cancel?sessionRequestId=${sessionRequestId}&canceller=${canceller}";
const JOIN_TUTOR_SESSION = BASE + "/join?sessionRequestId=${sessionRequestId}&joiner=${joiner}";
const CHECK_OTHER_PARTY_PRESENCE = BASE + "/check/otherParty/presence?sessionRequestId=${sessionRequestId}&joiner=${joiner}";
const LEAVE_TUTOR_SESSION = BASE + "/leave?sessionRequestId=${sessionRequestId}&leaver=${leaver}";
const REPORT_TUTOR_SESSION = BASE + "/report?sessionRequestId=${sessionRequestId}&reporter=${reporter}";


/************************* FOR TUTEES *******************************/
/**
 * @param {Object} payload  = {
 * 	ownerId: String,
 * 	subject: String,
 * 	topic: String,
 * 	urgency: String,
 * 	duration: Number, // required only if urgency was set to later
 * 	receiverPreference: String,
 * 	description: String
 * }
 */
export function saveTutorRequestAPI(payload, callback) {
	request(CREATE_TUTOR_SESSIONS_REQUEST, POST, payload, callback);
}

/**
 * @param {Object} payload  = {
 * 	ownerId: String,
 * 	id: String
 * }
 */
export function deleteTutorRequestAPI(payload, callback) {
	request(DELETE_TUTOR_SESSIONS_REQUEST, POST, payload, callback);
}

/**
 * @param {Object} payload  = {
 * 	ownerId: String,
 * 	id: String
 * 	subject: String,
 * 	topic: String,
 * 	urgency: String,
 * 	duration: Number, // required only if urgency was set to later
 * 	receiverPreference: String,
 * 	description: String
 * }
 */
export function editTutorRequestAPI(payload, callback) {
	request(EDIT_TUTOR_SESSIONS_REQUEST, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	sessionStates: [String],
 * 	sentOrReceived: String,
 * 	intendedUser: String,
 * 	tutorOwnerId: String
 * }
 * @param {String} payload = ownerId
 */
export function getTutorRequestsAPI(pathVariables, payload, callback) {
	const newPathVariables = fillOptionalArgs(pathVariables, {tutorOwnerId: "", sentOrReceived : ""});
	const api = $replace(GET_TUTOR_SESSION_REQUESTS, newPathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	tutorOwnerId: String
 * }
 */
export function getOpenRequestsCountAPI(pathVariables, callback) {
	const api = $replace(GET_OPEN_REQUESTS_COUNT, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	tutorProfileId: String,
 * 	sessionRequestId: String
 * }
 * @param {String} payload = ownerId
 */
export function acceptTutorRequestApplicationAPI(pathVariables, payload, callback) {
	const api = $replace(ACCEPT_TUTOR_REQUEST_APPLICATION, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	tutorProfileId: String,
 * 	sessionRequestId: String
 * }
 * @param {String} payload = ownerId
 */
export function declineTutorRequestApplicationAPI(pathVariables, payload, callback) {
	const api = $replace(DECLINE_TUTOR_REQUEST_APPLICATION, pathVariables);
	request(api, POST, payload, callback);
}



/************************* FOR TUTORS *******************************/
/**
 * @param {Object} pathVariables  = {
 * 	ownerId: String
 * }
 */
export function getTutorScheduledSessionsAPI(pathVariables, callback) {
	const api = $replace(GET_TUTOR_SCHEDULED_SESSIONS, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	ownerId: String
 * }
 */
export function getTutorLiveSessionsAPI(pathVariables, callback) {
	const api = $replace(GET_TUTOR_LIVE_SESSIONS, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	ownerId: String
 * }
 */
export function getTutorPastSessionsAPI(pathVariables, callback) {
	const api = $replace(GET_TUTOR_COMPLETED_SESSIONS, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	sessionRequestId: String
 * }
 * @param {String} payload = ownerId
 */
export function applyToSessionRequestAPI(pathVariables, payload, callback) {
	const api = $replace(APPLY_TO_SESSION_REQUEST, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	tutorProfileId: String,
 * 	sessionRequestId: String
 * }
 * @param {String} payload = ownerId
 */
export function acceptTutorRequestAPI(pathVariables, payload, callback) {
	const api = $replace(ACCEPT_TUTOR_REQUEST, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	tutorProfileId: String,
 * 	sessionRequestId: String
 * }
 * @param {String} payload = ownerId
 */
export function declineTutorRequestAPI(pathVariables, payload, callback) {
	const api = $replace(DECLINE_TUTOR_REQUEST, pathVariables);
	request(api, POST, payload, callback);
}


/************************* FOR TUTEES & TUTORS *******************************/
/**
 * @param {Object} pathVariables = {
 * 	sessionRequestId: String,
 * 	canceller: String
 * }
 * @param {String} payload = ownerId
 */
export function cancelTutorSessionAPI(pathVariables, payload, callback) {
	const api = $replace(CANCEL_TUTOR_SESSION, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	sessionRequestId: String,
 * 	joiner: String
 * }
 * @param {String} payload = ownerId
 */
export function joinTutorSessionAPI(pathVariables, payload, callback) {
	const api = $replace(JOIN_TUTOR_SESSION, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	sessionRequestId: String,
 * 	joiner: String
 * }
 */
export function checkOtherPartyPresenceAPI(pathVariables, callback) {
	const api = $replace(CHECK_OTHER_PARTY_PRESENCE, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	sessionRequestId: String,
 * 	leaver: String
 * }
 */
export function leaveTutorSessionAPI(pathVariables, callback) {
	const api = $replace(LEAVE_TUTOR_SESSION, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	sessionRequestId: String,
 * 	reporter: String
 * }
 * @param {Object} payload  = {
 * 	ownerId: String,
 * 	misconducts: [String],
 * 	additionalDetails: String
 * }
 */
export function reportTutorSessionAPI(pathVariables, payload, callback) {
	const api = $replace(REPORT_TUTOR_SESSION, pathVariables);
	request(api, POST, payload, callback);
}
