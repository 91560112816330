import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import {DETAILS, WAS_BOUGHT} from "apis/controllers/ClassesController";
import {saveClazzFeedbackAPI, CLASS} from "apis/controllers/customer/FeedbackController";
import {getVideoMetadataAPI, getClassVideoIntroAPI} from "apis/controllers/video/VideoController";

import {getUniversalDateFromTimestamp} from "Utilities/Time";
import { promiseAll, GET, POST } from "Utilities/Fetches";
import messageMap from "Utilities/MessageMaps";
import {camelize, $replace} from "Utilities/Strings";
import {createOverviews, createOverviewActions, createCourseDetails, sortResponse} from "./ClassOverviewUtilities";

import Alert, {INFORMATION} from "templates/Alert";
import Modal from "templates/Modal";
import TableOfContents from "templates/TableOfContents";

import {courseDetailsSchema, overviewSchema, overviewActionsSchema, modalSchema, tableOfContentsSchema} from "./schemas/classOverviewSchemas";

import accountAsset from "assets/icons/common/account.svg";

export default function ClassOverview(props) {
	const [modal, setModal] = useState(null),
				[courseDetailsDom, setCourseDetailsDom] = useState(null),
				[alert, setAlert] = useState(null);

	const history = useHistory();

	// local value refs
	const classId = useRef(props.routerProp && props.routerProp.location.state.classId),
				classCategory = useRef(null),
				courseDetails = useRef(null),
				tableNavDisplay = useRef("hide"),
				classBought = useRef(false),
				videoInformation = useRef(null);

	// DOM Refs
	// toggle refs
	const requirementsRef = useRef(),
				relevanceRef = useRef(),
				coverageRef = useRef(),
				structureRef = useRef(),
				pacingRef = useRef(),
				goalsRef = useRef(),
				guidepostRef = useRef(),
				instructorRef = useRef(),
				updateRef = useRef(),
				reviewsRef = useRef();

	// chevron refs for toggling
	const reqChevRef = useRef(),
				relevanceChevRef = useRef(),
				coverageChevRef = useRef(),
				structureChevRef = useRef(),
				pacingChevRef = useRef(),
				goalsChevRef = useRef(),
				guideChevRef = useRef(),
				instructorChevRef = useRef(),
				updateChevRef = useRef(),
				reviewsChevRef = useRef();

	const newCommentRef = useRef();

	const ownerId = localStorage.getItem("ownerId");

	const iconNameToRefMap = {
		"checklist": requirementsRef,
		"important": relevanceRef,
		"bars": coverageRef,
		"structure": structureRef,
		"run": pacingRef,
		"chest": goalsRef,
		"guidepost": guidepostRef,
		"instructor": instructorRef,
		"update": updateRef,
		"review": reviewsRef
	};

	useEffect(() => {
		if (!classCategory.current && !courseDetails.current) {
			getClassDetails();
		}
		// if (!videoInformation.current) {
		// 	getVideoInformation();
		// }
	}, [videoInformation.current]);

	function goToLink(path, idObj) {
		window.scrollTo(0, 0);
		history.push({
			pathname: path,
			state: idObj
		});
	}

	function getVideoInformation() {
		const metadataPayload = {
			ownerId: ownerId,
			filters: []
		};

		getVideoMetadataAPI({videoId: ""}, metadataPayload, resp => {
			videoInformation.current = resp;
		});
	}

	function getClassDetails() {
		const apiArr = [
			{
				api: $replace(DETAILS, {classId: classId.current}),
				type: "GET",
				payload: null
			}, {
				api: $replace(CLASS, {classId: classId.current}),
				type: "GET",
				payload: null
			}, {
				api: $replace(WAS_BOUGHT, {classId: classId.current}),
				type: POST,
				payload: ownerId
			}
		];

		promiseAll(apiArr, response => {
			renderCourseDetails(response);
		});
	}

	function renderCourseDetails(response) {
		const localCourseDetails = response[0].data,
					classCat = localCourseDetails.category.toLowerCase();
		classBought.current = response[2].data;
		courseDetails.current = localCourseDetails;
		classCategory.current = classCat;

		setCourseDetailsDom(createCourseDetails(toggleDetails, openModal, classCat, courseDetailsSchema({
			requirementsRef: requirementsRef,
			reqChevRef: reqChevRef,
			relevanceRef: relevanceRef,
			relevanceChevRef: relevanceChevRef,
			coverageRef: coverageRef,
			coverageChevRef: coverageChevRef,
			structureRef: structureRef,
			structureChevRef: structureChevRef,
			pacingRef: pacingRef,
			pacingChevRef: pacingChevRef,
			goalsRef: goalsRef,
			goalsChevRef: goalsChevRef,
			guidepostRef: guidepostRef,
			guideChevRef: guideChevRef,
			instructorRef: instructorRef,
			instructorChevRef: instructorChevRef,
			updateRef: updateRef,
			updateChevRef: updateChevRef,
			reviewsRef: reviewsRef,
			reviewsChevRef: reviewsChevRef
		}, localCourseDetails, createReviewSection, response)));
	}

	const overviews = createOverviews(overviewSchema(openModal, focusOnSection));
	// TODO: implement
	const overviewActions = createOverviewActions(goToLink, videoInformation.current, overviewActionsSchema(classBought, courseDetails.current ? courseDetails.current.moduleList[0].videoList[0] : null, courseDetails.current ? courseDetails.current.id : null));

	function toggleDetails(ref, chevronRef) {
		const className = ref.current.className;

		if (className === "details") {
			ref.current.className = className.replace("details", "details-collapsed");
		}
		else if (className === "details-collapsed") {
			ref.current.className = className.replace("details-collapsed", "details");
		}

		chevronRef.current.className = chevronRef.current.className === "chevron-down" ? "chevron-up" : "chevron-down";
	}

	function openModal(imgName, classCat) {
		const modalMap = modalSchema(classCategory, classCat, classId.current);
		const modalType = modalMap[imgName];

		if (imgName === "video") {
			getClassVideoIntroAPI({classId: classId.current}, resp => {
				modalType.content = (
					<video controls>
						{/* src={"/" + process.env.PUBLIC_URL + "videos/real1.mp4"} */}
						<source src={resp} type="video/mp4"/>
					</video>
				);

				setModal(
					<Modal closeType={modalType.closeType} closeHandler={e => closeModal(e)}
								icon={modalType.icon}
								modalContainerStyle={modalType.modalContainerStyle} closeButtonStyle={modalType.closeButtonStyle}
								stateProps={modalType.stateProps}>
						{modalType.content}
					</Modal>
				);
			});
		}
		else {
			setModal(
				<Modal closeType={modalType.closeType} closeHandler={e => closeModal(e)}
							icon={modalType.icon}
							modalContainerStyle={modalType.modalContainerStyle} closeButtonStyle={modalType.closeButtonStyle}
							stateProps={modalType.stateProps}>
					{modalType.content}
				</Modal>
			);
		}
	}

	function closeModal(e) {
		["modal-block", "icon", "close-button"].includes(e.target.className) && setModal(null);
	}

	function focusOnSection(iconName) {
		const scrollIntoViewOptions = {
			behavior: "smooth", 
			block: "center", 
			inline: "start"
		},
		iconNameToRefMap = {
			"checklist": requirementsRef,
			"important": relevanceRef,
			"bars": coverageRef,
			"structure": structureRef,
			"run": pacingRef,
			"chest": goalsRef,
			"guidepost": guidepostRef,
			"instructor": instructorRef,
			"update": updateRef,
			"review": reviewsRef
		};

		if (typeof iconName === "object") {
			iconName = iconName.target.getAttribute("name");
			updateTableNavDisplay();
		}

		iconNameToRefMap[iconName].current.scrollIntoView(scrollIntoViewOptions);
	}

	function createReviewSection(response, wasBought) {
		let insertFeedback,
				reviews = [];

		if (wasBought) {
			insertFeedback = (
				<div className="feedback-container">
					{messageMap("classPage.feedback.question", "generic")}
					<textarea className="feedback" rows="3" 
										placeholder={messageMap("classPage.feedback.placeholder", "generic")}
										ref={newCommentRef}>
					</textarea>
					<button onClick={submitClassReview} >
						{messageMap("submit.text", "button")}
					</button>
				</div>
			);
		}

		response = sortResponse(response);

		response.forEach(review => {
			const tsDate = new Date(review.timestamp);
			const timestamp = getUniversalDateFromTimestamp(review.timestamp);

			reviews.push(
				<div key={`${review.name}-${tsDate}`} className="reviews">
					<div className="img-container">
						<img src={accountAsset} alt={messageMap("account.profile", "image")}></img>
					</div>
					<div className="review-details-container">
						<div>
							<span className="reviewer-name">
								{review.name}
							</span>
							<span className="review-timestamp">
								{timestamp}
							</span>
						</div>
						<div className="review">
							{review.feedback}
						</div>
					</div>
				</div>
			);
		});
		
		return (
			<div>
				{insertFeedback}
				{reviews}
			</div>
		);
	}

	function submitClassReview() {
		const payload = {
			feedback: newCommentRef.current.value,
			classId: classId.current,
			ownerId: ownerId,
			type: "clazz"
		};
		saveClazzFeedbackAPI(payload, (resp) => {
			setAlert(
				<Alert type={INFORMATION} msg={messageMap(resp, "api")} closeHandler={() => {setAlert(null)}}/>
			);
			getClassDetails();
			newCommentRef.current.value = "";
		});
	}

	function updateTableNavDisplay() {
		tableNavDisplay.current.className = tableNavDisplay.current.className.includes("hide") ? "navigation" : "navigation hide";
	}

	return (
		<div>
			{alert}

			<div className="class-overview">
				<div className="course-briefing">
					<div className="img-container">
						<img className="img" 
							// TODO: fix this pathing
							// src={"/" + process.env.PUBLIC_URL + `images/classes/${classCategory.current}/${classKey.current}/${classKey.current}.jpg`} 
							src={"/" + process.env.PUBLIC_URL + `images/classes/${classCategory.current}/${classId.current}/${classId.current}.jpg`} 
							alt={messageMap("bookmark.notSaved", "image")}
							></img>
					</div>

					<div className="briefing-container">
						<h1 className="title">
							{courseDetails.current && courseDetails.current.title ? courseDetails.current.title : ""}
						</h1>
						<h2 className="subheader">
							{courseDetails.current && courseDetails.current.description ? courseDetails.current.description : ""}
						</h2>
						<div className="overview-container">
							{overviews}
							<div className="briefing-actions">
								{/* TODO: uncomment once it's implemented */}
								{overviewActions}
							</div>
						</div>
					</div>
				</div>

				<div className="course-details">
					<h3 className="title">
						{messageMap("classPage.details.text", "generic")}
					</h3>
					<TableOfContents list={tableOfContentsSchema} iconNameToRefMap={iconNameToRefMap}></TableOfContents>
					{courseDetailsDom}
				</div>
			</div>

			{modal}
		</div>
	);
}