import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/learningMaterials/thumbnailGoalsEvaluation.jpg";
import engRoadmapAsset from "assets/topicArticles/LearningOptimizations/learningMaterials/engRoadmap.svg";


export const learningMaterialsGoalsEvaluationsArticle = {
	id: "IV. Learning Materials_3_1 How to set goals and evaluate your skills and knowledge to pick the right learning materials",
	title: messageMap("goalsEvaluation.article.title", "learningOptimization"),
	description: messageMap("goalsEvaluation.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("goalsEvaluation.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3",
		"text4", "text5",
		"text6", "text7", "text8", "text9", "text10", "text11", "text12",
		"text13", "text14", "text15", "text16",
		"text17", "text18", "html1", "text19", "text20", "text21",
		"text22", "text23", "text24",
		"text25", "text26",
		"text27", "text28", "text29", "text30"
	],
	datePublished: "2023-11-30",
	dateModified: "2024-06-24"
};

export const learningMaterialsGoalsEvaluationsArticleTexts = {
	text1: {
		text: messageMap("goalsEvaluation.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("goalsEvaluation.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("goalsEvaluation.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("goalsEvaluation.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("goalsEvaluation.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("goalsEvaluation.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text7: {
		text: messageMap("goalsEvaluation.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("goalsEvaluation.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("goalsEvaluation.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("goalsEvaluation.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("goalsEvaluation.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("goalsEvaluation.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("goalsEvaluation.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text14: {
		text: messageMap("goalsEvaluation.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("goalsEvaluation.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("goalsEvaluation.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("goalsEvaluation.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text18: {
		text: messageMap("goalsEvaluation.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("goalsEvaluation.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("goalsEvaluation.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("goalsEvaluation.texts.p16", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("goalsEvaluation.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text23: {
		text: messageMap("goalsEvaluation.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text24: {
		text: messageMap("goalsEvaluation.texts.p18", "learningOptimization"),
		type: DESCRIPTION
	},
	text25: {
		text: messageMap("goalsEvaluation.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text26: {
		text: messageMap("goalsEvaluation.texts.p19", "learningOptimization"),
		type: DESCRIPTION
	},
	text27: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text28: {
		text: messageMap("goalsEvaluation.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text29: {
		text: messageMap("goalsEvaluation.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text30: {
		text: messageMap("goalsEvaluation.sources.s3", "learningOptimization"),
		type: SOURCE
	}
};

export const learningMaterialsGoalsEvaluationsArticleHTMLs = {
	html1: (
		<img key="engRoadmap" src={engRoadmapAsset} alt="engineering roadmap" style={{width: "-webkit-fill-available"}} />
	)
};



