export const level05MathMessageMap = {
	countingTo100UsingNumeralPatterns: {
		article: {
			title: "Counting to 100 Using Numeral Patterns",
			description: "Learn the basics of number patterns by counting to 100 and using small groups of numbers.",
			thumbnailAlt: "A close-up picture of an ammonite fossil showing the shell's pattern."
		},
		countingTo100UsingNumeralPatternsArticleTexts: {
			text1: "What is a pattern and how do they relate to math?",
			text2: `A pattern is something that regularly repeats. Patterns are everywhere in our lives; 
				they can be found in nature, art, the universe, and math. Discovering and defining these patterns 
				are a big part of studying math. Let's start our pattern-finding by playing with the 
				numbers and step size below.`,
			text3: `Start by setting the count to 0, the step size to 10, and increasing the count. Repeat the 
				same process, but change the step to 5, then to 2. Notice how the step size affects the number 
				whenever you increase or decrease the count.`,
			text4: "Try to test yourself if you can count up to 100 with different step sizes and check by playing with the arrows."
		}
	},
	orderingWithOrdinals: {
		article: {
			title: "Ordering with Ordinals",
			description: "Learn what ordinals are and how they are used for ordering.",
			thumbnailAlt: "A picture of three medals for first, second, and third place."
		},
		orderingWithOrdinalsArticleTexts: {
			text1: "What are ordinals?",
			text2: `Ordinals describe the order or position of things in a list. From left to right and top to bottom, notice how an ordinal can be described 
				in two ways, one through words and one through numbers:`
		}
	},
	numbersWithRomanNumerals: {
		article: {
			title: "Numbering with Roman Numerals",
			description: "Learn how roman numerals are used to indicate quantity.",
			thumbnailAlt: "A picture of three medals for first, second, and third place."
		},
		numbersWithRomanNumeralsArticleTexts: {
			text1: "What are Roman numerals?",
			text2: `Roman numerals are a numeral system created by the ancient Romans to indicate quantity, similar to how we use numbers to indicate quantity. 
				The numbers we use were actually created many centuries ago and were introduced to Europeans by Arabic speakers. Take a look at the following Roman 
				numerals and see how they compare to numbers:`,
			text3: `Notice how smaller symbols, for example 'I', when placed to the left of a bigger symbols, for example 'V', results to a subtraction, for 
				example 4 (IV). If the smaller symbol, for example 'I', is placed to the right of a bigger symbol, for example 'V', results to an addition, for 
				example 6 (VI). This is the basic rule when reading roman numerals.`,
			text4: "Roman numerals up to 100",
			text5: "Note*: You can use these roman symbols to go up to 499. To save on space, we're only showing the remaining symbols without their quantity in the following:"
		}
	}
};
