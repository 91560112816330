import React from "react";

import { messageMapDocument } from "Utilities/MessageMaps";


function TutorSessionPolicy(props) {

	return (
		<div className="sub-section-div">
			<h3 className="western">
				{messageMapDocument("teacherPolicy.section2.point2.text")}
			</h3>
			<p className="margin-bottom-spacing">
				{messageMapDocument("teacherPolicy.section2.point2.point1.text")}
			</p>
			<ul>
				<li>
					<p className="margin-bottom-spacing">
						<span>
							{messageMapDocument("teacherPolicy.section2.point2.point2.text1")}
						</span>
						{messageMapDocument("teacherPolicy.section2.point2.point2.text2")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						<span>
							{messageMapDocument("teacherPolicy.section2.point2.point3.text1")}
						</span>
						{messageMapDocument("teacherPolicy.section2.point2.point3.text2")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						<span>
							{messageMapDocument("teacherPolicy.section2.point2.point4.text1")}
						</span>
						{messageMapDocument("teacherPolicy.section2.point2.point4.text2")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						<span>
							{messageMapDocument("teacherPolicy.section2.point2.point5.text1")}
						</span>
						{messageMapDocument("teacherPolicy.section2.point2.point5.text2")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						<span>
							{messageMapDocument("teacherPolicy.section2.point2.point6.text1")}
						</span>
						{messageMapDocument("teacherPolicy.section2.point2.point6.text2")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						<span>
							{messageMapDocument("teacherPolicy.section2.point2.point7.text1")}
						</span>
						{messageMapDocument("teacherPolicy.section2.point2.point7.text2")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						<span>
							{messageMapDocument("teacherPolicy.section2.point2.point8.text1")}
						</span>
						{messageMapDocument("teacherPolicy.section2.point2.point8.text2")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						<span>
							{messageMapDocument("teacherPolicy.section2.point2.point9.text1")}
						</span>
						{messageMapDocument("teacherPolicy.section2.point2.point9.text2")}
					</p>
				</li>
			</ul>
		</div>
	);
}

export default TutorSessionPolicy;


