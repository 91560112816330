import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import Modal from "templates/Modal";
import RequestItem from "pages/Tutoring/subPages/subMenus/components/RequestItem";

import messageMap from "Utilities/MessageMaps";
import { calculateTutorAvgRating } from "pages/Tutoring/utilities/TutorProfileUtility";
import { 
	TUTEE_USER, TUTOR_USER,
	OPEN_STATE, PENDING_STATE, SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE, TUTEE_NO_SHOW_STATE, TUTOR_NO_SHOW_STATE,
	DISPUTED_STATE, DISPUTE_RESOLVED_STATE
} from "pages/Tutoring/utilities/TutoringRequestConstants";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import { getReviewsAPI } from "apis/controllers/tutoring/TutoringReviewController";
import { getTutorRequestsAPI } from "apis/controllers/tutoring/SessionRequestController";


function RequestList(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	const [requestList, setRequestList] = useState(null),
		[averageStarRatings, setAverageStarRatings] = useState(null),
		[completedSessionsCount, setCompletedSessionsCount] = useState(0);

	useEffect(() => {
		displayAllRequests();
		getTutoringReviews();
	}, []);

	function getTutoringReviews() {
		const pathVariables = {
			ownerId: props.ownerId,
			reviewFromWhichUserType: props.intendedUser === TUTOR_USER ? TUTEE_USER : TUTOR_USER
		};
		getReviewsAPI(pathVariables, resp => {
			const ratings = calculateTutorAvgRating(resp, "star-icon");
			setAverageStarRatings(ratings.length ? ratings : null);
		});
	}

	function displayAllRequests() {
		let states = props.listType === COMPLETED_STATE 
			? [COMPLETED_STATE, TUTEE_NO_SHOW_STATE, TUTOR_NO_SHOW_STATE, DISPUTED_STATE, DISPUTE_RESOLVED_STATE] 
			: [props.listType];

		const pathVariables = {
			sessionStates: states,
			sentOrReceived: props.sentOrReceived ? props.sentOrReceived : "",
			intendedUser: props.intendedUser,
			tutorOwnerId: props.isForPublic ? props.tutorOwnerId : ""
		};

		getTutorRequestsAPI(pathVariables, ownerId, resp => {
			let listDom = [];

			resp.forEach(request => {
				let description = request.description;
				if (request.description.length > 30) {
					const trimmedDescription = request.description.substring(0, 20).trim();
					description = (
						<Fragment>
							{`${trimmedDescription}...`}
							<button className="read-more" onClick={e => showDescription(request.description)}>
								{messageMap("tutoring.readMore", "button")}
							</button>
						</Fragment>
					);
				}

				let urgencyClass = "urgency";

				if (request.urgency !== "now") {
					urgencyClass = "";
				}
				const nowKey = Date.now();

				if (PENDING_STATE === props.listType) {
					for (const [key, value] of Object.entries(request.tutorIdToTutorName)) {
						listDom.push(
							<RequestItem key={`${nowKey}_${request.id}`} intendedUser={props.intendedUser} isForPublic={props.isForPublic}
								sentOrReceived={props.sentOrReceived}
								requestId={request.id} itemType={props.listType}
								profilePictureUploadLocation={request.profilePictureUploadLocation}
								otherPartyName={value} tutorId={key} tutorOwnerId={request.tutorIdToTutorOwnerId[key]} subject={request.subject} topic={request.topic}
								sessionPrice={request.tutorIdToTutorTopicRate[key]}
								urgency={request.urgency} urgencyClass={urgencyClass} duration={request.duration}
								receiverPreference={request.receiverPreference}
								description={description} originalDescription={request.description} state={request.state}
								setTutorProfilePane={props.setTutorProfilePane}
								setModal={props.setModal} setModal1={props.setModal1}  setAlert={props.setAlert}
								reRenderList={displayAllRequests} refreshOpenRequestCount={props.refreshOpenRequestCount} />
						);
					}
				}
				else {
					let additionalAttributes = {};
					if ([OPEN_STATE, SCHEDULED_STATE, LIVE_STATE, COMPLETED_STATE].includes(props.listType)) {
						additionalAttributes["intendedUser"] = props.intendedUser;
						additionalAttributes["sentOrReceived"] = props.sentOrReceived;
						additionalAttributes["profilePictureUploadLocation"] = request.profilePictureUploadLocation;
						additionalAttributes["otherPartyName"] = request.acceptingPartyName;
						additionalAttributes["tutorId"] = request.acceptedTutorProfileId;
						additionalAttributes["tutorOwnerId"] = request.acceptedTutorOwnerId;
						additionalAttributes["state"] = request.state;
						additionalAttributes["createdAt"] = request.createdAt;
						additionalAttributes["zoomUrl"] = request.zoomUrl
						additionalAttributes["cancellationPolicy"] = request.acceptingPartyCancellationPolicy
						additionalAttributes["setTutorProfilePane"] = props.setTutorProfilePane;
						additionalAttributes["tuteeJoinedTimestamp"] = request.tuteeJoinedTimestamp;
						additionalAttributes["tutorJoinedTimestamp"] = request.tutorJoinedTimestamp;
						additionalAttributes["reRenderNextListStateHandler"] = props.reRenderNextListStateHandler;
						additionalAttributes["currentUserRating"] = request.currentUserRating;
						additionalAttributes["otherPartyRating"] = request.otherPartyRating;
					}

					listDom.push(
						<RequestItem key={`${nowKey}_${request.id}`} requestId={request.id} itemType={props.listType}
							isForPublic={props.isForPublic}
							subject={request.subject} topic={request.topic}

							urgency={request.urgency} urgencyClass={urgencyClass} duration={request.duration}
							receiverPreference={request.receiverPreference}
							description={description} originalDescription={request.description}
							setModal={props.setModal} setModal1={props.setModal1}  setAlert={props.setAlert}
							reRenderList={displayAllRequests} refreshOpenRequestCount={props.refreshOpenRequestCount}
							{...additionalAttributes} />
					);
				}
			});

			setRequestList(listDom);

			if (props.listType === COMPLETED_STATE ) {
				setCompletedSessionsCount(listDom.length);
			}

			props.emptyListHandler && listDom.length === 0 
				&& props.emptyListHandler(props.sentOrReceived, listDom.length, props.listType, props.intendedUser);
		});
	}

	function showDescription(description) {
		props.setModal(
			<Modal closeType="xButton" closeHandler={closeModal}>
				<div style={{fontSize: "16px"}}>
					{description}
				</div>
			</Modal>
		);
	}

	function closeModal(e) {
		if (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className)) {
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	return (
		<Fragment>
			{
				props.listType === COMPLETED_STATE && !props.isForPublic && (
					<Fragment>
						<div className="overall-rating">
							{messageMap("tutoringPage.session.overallRating", "generic")}
						</div>
						<div className="average-star-ratings-container">
							{averageStarRatings != null ? averageStarRatings : messageMap("tutoringPage.session.notRated", "generic")}
						</div>
						<div className="session-count">
							{`${messageMap("tutoringPage.session.sessionCount", "generic")} ${completedSessionsCount}`}
						</div>
					</Fragment>
				)
			}
			{requestList}
		</Fragment>
	);
}

RequestList.defaultProps = {
	intendedUser: TUTEE_USER,
	sentOrReceived: ""
};

RequestList.propTypes = {
	isForPublic: PropTypes.bool,
	tutorOwnerId: PropTypes.string,
	intendedUser: PropTypes.string.isRequired,
	listType: PropTypes.string.isRequired,
	sentOrReceived: PropTypes.string,

	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func,
	setAlert: PropTypes.func.isRequired,
	setTutorProfilePane: PropTypes.func,
	emptyListHandler: PropTypes.func,
	reRenderNextListStateHandler: PropTypes.func,
	refreshOpenRequestCount: PropTypes.func,
	// redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(RequestList);

