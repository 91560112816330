export const CORRECT = "correct";
export const WRONG = "wrong";

export const TRUE_FALSE = "trueFalse";
export const MULTIPLE_CHOICE = "multipleChoice";
export const FILL_IN_THE_BLANK = "fillInTheBlank";

export const HINTS = "hints";
export const ANSWERS = "answers";
export const LEVELS = "levels";

export const LOCAL_HINT = "local_hint";
export const LOCAL_ANSWER = "local_answer";

