/* eslint-disable no-template-curly-in-string */
import {
	VIDEO_UPLOAD_FORM_DATA,
	VIDEO_TITLE, VIDEO_DURATION, VIDEO, THUMBNAIL, THUMBNAIL_DESCRIPTION,
	SUBTITLES, MENTAL_MODELS, PRICE, TOPIC_ID, TOPIC_TYPE, DESCRIPTION, CATEGORY
} from "Utilities/Constants/MediaConstants";
import {PAGE_SIZE} from "Utilities/Constants/PageConstants";

import request, {GET, POST} from "Utilities/Fetches";
import {getFormData} from "Utilities/Upload";
import {$replace, fillOptionalArgs} from "Utilities/Strings";

const BASE = "/services/video";

export const GET_VIDEO_SEARCH_LIST = BASE + "/get/search/strings";

export const LIST_ALL_VIDEOS = BASE + "/list/all?pageCount=${pageCount}&pageSize=${pageSize}&downloadTypes=${downloadTypes}";
const LIST = BASE + "/list/${classId}";
export const GET_VIDEO_METADATA = BASE + "/get/metadata/${videoId}";
const GET_VIDEO_ENTRIES_COUNT = BASE + "/get/count";
const GET_CLASS_VIDEO_INTRO = BASE + "/${classId}/intro";
const IS_VIDEO_WATCHABLE = BASE + "/isWatchable/${videoId}";
export const GET_VIDEO_HLS_RESOURCE = BASE + "/get/hls/${videoId}";
const GET_VIDEO_TRANSCRIPT = BASE + "/transcript/${videoId}";
const GET_VIDEO_MENTAL_MODEL = BASE + "/mentalmodel";

const UPLOAD_VIDEO = BASE + "/upload?ownerId=${ownerId}";
const EDIT_UPLOADED_VIDEO = BASE + "/edit?ownerId=${ownerId}&videoId=${videoId}";
const DELETE_VIDEO = BASE + "/delete?videoIds=${videoIds}&category=${category}";


export function getVideoSearchStrings(callback) {
	request(GET_VIDEO_SEARCH_LIST, GET, null, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	pageCount: Number,
 * 	pageSize: Number,
 * 	downloadTypes: [String]
 * }
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	filters: [String]
 * }
 */
export function listAllVideosAPI(pathVariables, payload, successCallback, errorCallback = null) {
	let newPathVariables = fillOptionalArgs(pathVariables, {pageSize: PAGE_SIZE.TEN});
	const api = $replace(LIST_ALL_VIDEOS, newPathVariables);
	request(api, POST, payload, successCallback, errorCallback, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 */
export function listAPI(pathVariables, callback) {
	const api = $replace(LIST, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = {
 * 	ownerId = String,
 * 	filters = [String]
 * }
 */
export function getVideoMetadataAPI(pathVariables, payload, callback) {
	const api = $replace(GET_VIDEO_METADATA, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {String} payload = ownerId
 */
export function getVideoEntriesCountAPI(payload, callback) {
	request(GET_VIDEO_ENTRIES_COUNT, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {String} payload = ownerId
 */
export function getVideoHLSResourceAPI(pathVariables, payload, callback) {
	const api = $replace(GET_VIDEO_HLS_RESOURCE, pathVariables);
	request(api, POST, payload, callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 */
export function getClassVideoIntroAPI(pathVariables, callback) {
	const api = $replace(GET_CLASS_VIDEO_INTRO, pathVariables);
	request(api, GET, null, callback, null, null, true);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {String} payload = ownerId
 */
export function getVideoTranscriptAPI(pathVariables, payload, callback) {
	const api = $replace(GET_VIDEO_TRANSCRIPT, pathVariables);
	request(api, POST, payload, callback, null, null, false);
}

/**
 * @param {Object} payload = VIDEO_MENTAL_MODEL_VO
 */
export function getVideoMentalModelAPI(payload, callback) {
	request(GET_VIDEO_MENTAL_MODEL, POST, payload, callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	ownerId: String
 * }
 */
export function uploadVideoAPI(pathVariables, formDataPayload, callback) {
	const relevantFields = [
		VIDEO_TITLE,
		VIDEO_DURATION,
		VIDEO,
		THUMBNAIL,
		THUMBNAIL_DESCRIPTION,
		SUBTITLES,
		MENTAL_MODELS,
		PRICE,
		TOPIC_ID,
		// either predefined (topic from our Excel files), or "missingAnItem", which corresponds
		// to "missingItem" feedbackType for submitMissingItemsSuggestion() in KnowledgeTree.jsx
		TOPIC_TYPE,
		DESCRIPTION,
		CATEGORY
	];
	const api = $replace(UPLOAD_VIDEO, pathVariables);

	// TODO: find a way to validate formData object fields
	request(api, POST, getFormData(formDataPayload, relevantFields, VIDEO_UPLOAD_FORM_DATA), callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	ownerId: String,
 * 	videoId: videoId
 * }
 */
export function editUploadedVideoAPI(pathVariables, formDataPayload, fieldsToValidate, callback) {
	const api = $replace(EDIT_UPLOADED_VIDEO, pathVariables);

	request(api, POST, getFormData(formDataPayload, fieldsToValidate, VIDEO_UPLOAD_FORM_DATA), callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: videoId,
 * 	category: category
 * }
 */
export function deleteVideoContentAPI(pathVariables, callback) {
	const api = $replace(DELETE_VIDEO, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	filters = [String]
 * }
 */
export function isVideoWatchableAPI(pathVariables, payload, callback) {
	const api = $replace(IS_VIDEO_WATCHABLE, pathVariables);
	request(api, POST, payload, callback);
}