import React, {useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";

import {getTopic} from "pages/Classes/TopicsInfoList/utilities/TopicsInfoUtilities";
import { getQuestDetailsFromId } from "diagrams/utilities/NetworkGenerator";

import { getAllKnowledgeMetadata } from "apis/controllers/knowledge/KnowledgeMetadataController";

import videoAsset from "assets/icons/navigation/pageSwitching/video.svg";
import questionsAsset from "assets/icons/navigation/pageSwitching/questions.svg";
import infoTextAsset from "assets/icons/navigation/pageSwitching/infoTexts.svg";


/**
 * @param {String} curPage current page
 * @param {String} leftPage page to navigate using left button
 * @param {String} rightPage page to navigate using right button
 * 
 * @param {String} workbook can be one of the following ["GradeSchool", "Degrees", "Jobs"]
 * @param {String} subject node type above topics
 * @param {String} topicId specific topicID from excel file
 * @param {String} isOnJourney keep the context if user is on a quest
 */
function PageSwitcher(props) {

	const iconsMap = {
		video: videoAsset,
		practice: questionsAsset,
		info: infoTextAsset,
	};

	const iconsAltMap = {
		video: messageMap("pageSwitching.video", "image"),
		practice: messageMap("pageSwitching.practice", "image"),
		info: messageMap("pageSwitching.info", "image"),
	};

	const [workbook, setWorkbook] = useState(props.workbook),
		[subject, setSubject] = useState(props.subject),
		[topicId, setTopicId] = useState(props.topicId),
		[isOnJourney, setIsOnJourney] = useState(props.isOnJourney),
		// links
		[videoLink, setVideoLink] = useState(),
		[videoLinkState, setVideoLinkState] = useState("");

	// DOM refs
	const leftIconRef = useRef(),
		rightIconRef = useRef(),
		// value refs
		infoAvailabilityRef = useRef(null),
		videoAvailabilityRef = useRef(null),
		questionsAvailabilityRef = useRef(null);

	const pageTypeToLinksMap = {
		video: {
			pathname: videoLink,
			state: videoLinkState
		},
		practice: {
			pathname: "/exercise?subject=" + subject.toLowerCase() + "&topic=" + topicId.replaceAll(" ", "-"),
			state: {
				topicId: topicId,
				topicDetails: questionsAvailabilityRef.current,
				workbook: workbook,
				subject: subject,
				isJourney: isOnJourney
			}
		},
		info: {
			pathname: "/topic-info?subject=" + subject + "&topicId=" + topicId.replaceAll(" ", "-"),
			state: {
				topicDetails: infoAvailabilityRef.current && infoAvailabilityRef.current.metadata,
				workbook: workbook,
				subject: subject,
				isJourney: isOnJourney
			}
		},
	};

	useEffect(() =>{
		checkIfVideoIsAvailable(() => {
			checkIfInfoArticleIsAvailable();
			checkIfPracticeQuestionsAreAvailable();
	
			disableUnavailableResources();
		});
	}, []);

	function checkIfInfoArticleIsAvailable() {
		const topicDetails = getTopic(workbook, subject, topicId);
		infoAvailabilityRef.current = topicDetails && Object.keys(topicDetails).length && Object.keys(topicDetails.metadata).length ? topicDetails : null;
	}

	function checkIfVideoIsAvailable(callback) {
		getAllKnowledgeMetadata({objectList: [topicId]}, resp => {
			videoAvailabilityRef.current = resp[0];

			if (videoAvailabilityRef.current) {
				const videoMetadata = videoAvailabilityRef.current.videoMetadataSet[0];
				setVideoLink("/learn?id=" + videoMetadata.id + "&channel=" + videoMetadata.uploaderUsername + "&title=" + videoMetadata.title.replaceAll(" ", "_"));
				setVideoLinkState({
					videoIds: [videoMetadata.id],
					isJourney: isOnJourney
				});
			}

			callback();
		});
	}

	function checkIfPracticeQuestionsAreAvailable() {
		const questDetails = getQuestDetailsFromId(topicId);
		if (questDetails[0][topicId].practiceArgs) {
			questionsAvailabilityRef.current = questDetails[0][topicId];
		}
	}

	function disableUnavailableResources() {
		if ([props.leftPage, props.rightPage].includes(PRACTICE_PAGE) && !questionsAvailabilityRef.current) {
			if (props.leftPage === PRACTICE_PAGE) {
				hideLeftOptions();
			}
			else {
				hideRightOptions();
			}
		}
		if ([props.leftPage, props.rightPage].includes(VIDEO_PAGE) && !videoAvailabilityRef.current) {
			if (props.leftPage === VIDEO_PAGE) {
				hideLeftOptions();
			}
			else {
				hideRightOptions();
			}
		}
		if ([props.leftPage, props.rightPage].includes(INFO_PAGE) && !infoAvailabilityRef.current) {
			if (props.leftPage === INFO_PAGE) {
				hideLeftOptions();
			}
			else {
				hideRightOptions();
			}
		}
	}

	function hideLeftOptions() {
		leftIconRef.current.className = "page-button hide";
	}

	function hideRightOptions() {
		rightIconRef.current.className = "page-button hide";
	}


	return (
		<div className="page-switcher">
			<div className="pages-buttons-container">
				<Link to={{
					pathname: pageTypeToLinksMap[props.leftPage].pathname,
					state: pageTypeToLinksMap[props.leftPage].state
				}} className="page-button left-page" ref={leftIconRef}
				onClick={defaultLinkAction}>
					<img src={iconsMap[props.leftPage]} alt={iconsAltMap[props.leftPage]} />
				</Link>
				<button className="page-button current-page" disabled>
					<img src={iconsMap[props.curPage]} alt={iconsAltMap[props.curPage]}  />
				</button>
				<Link to={{
					pathname: pageTypeToLinksMap[props.rightPage].pathname,
					state: pageTypeToLinksMap[props.rightPage].state
				}} className="page-button right-page" ref={rightIconRef}
				onClick={defaultLinkAction}>
					<img src={iconsMap[props.rightPage]} alt={iconsAltMap[props.rightPage]} />
				</Link>
			</div>
		</div>
	);
}

PageSwitcher.propTypes = {
	curPage: PropTypes.string.isRequired,
	leftPage: PropTypes.string.isRequired,
	rightPage: PropTypes.string.isRequired,

	workbook: PropTypes.string.isRequired,
	subject: PropTypes.string.isRequired,
	topicId: PropTypes.string.isRequired,
	isOnJourney: PropTypes.bool
};

export default PageSwitcher;

// only available pages to switch to
export const VIDEO_PAGE = "video";
export const PRACTICE_PAGE = "practice";
export const INFO_PAGE = "info";
