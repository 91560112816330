/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace, fillOptionalArgs} from "Utilities/Strings";
import {PAGE_SIZE} from "Utilities/Constants/PageConstants";

const BASE = "/services/module";

export const GET_MODULE_SEARCH_LIST = BASE + "/get/search/strings";

export const LIST_ALL_MODULES = BASE + "/list/all?pageCount=${pageCount}&pageSize=${pageSize}";
const GET_MODULE_METADATA = BASE + "/get/metadata/${moduleId}";
const GET_MODULE_ENTRIES_COUNT = BASE + "/get/count";

const CREATE_MODULE = BASE + "/save?";
const EDIT_MODULE = BASE + "/edit?&moduleId=${moduleId}";
const DELETE_MODULE = BASE + "/delete?moduleId=${moduleId}";



export function getModuleSearchStrings(callback) {
	request(GET_MODULE_SEARCH_LIST, GET, null, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	pageCount: Number,
 * 	pageSize: Number
 * }
 * @param {Object} payload = {
 * 	ownerId: String
 * }
 */
export function listAllModulesAPI(pathVariables, payload, callback) {
	let newPathVariables = fillOptionalArgs(pathVariables, {pageSize: PAGE_SIZE.TEN});
	const api = $replace(LIST_ALL_MODULES, newPathVariables);
	request(api, POST, payload, callback, null, null, false);
}


/**
 * @param {Object} payload = {
 * 	playlistName: String,
 * 	playlistType: String - value should always be 'module',
 * 	itemIds: [String],
 * 	totalDuration: String - total minutes:seconds,
 * 	creatorUsername: String,
 * 	creatorOwnerId: String
 * }
 */
export function createModuleAPI(payload, callback) {
	request(CREATE_MODULE, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	moduleId: String
 * }
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	filters = [String]
 * }
 */
export function getModuleMetadataAPI(pathVariables, payload, callback) {
	const api = $replace(GET_MODULE_METADATA, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {String} payload = ownerId
 */
export function getModuleEntriesCountAPI(payload, callback) {
	request(GET_MODULE_ENTRIES_COUNT, POST, payload, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	ownerId: String
 * 	moduleId: String
 * }
 * @param {Object} payload  = {
 * 	playlistName: String,
 * 	playlistType: String - value should always be 'module',
 * 	description: String,
 * 	itemIds: [String],
 * 	totalDuration: String - total minutes:seconds,
 * 	creatorOwnerId: String
 * }
 */
export function editModuleAPI(pathVariables, payload, callback) {
	const api = $replace(EDIT_MODULE, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	moduleId: String
 * }
 */
export function deleteModuleAPI(pathVariables, callback) {
	const api = $replace(DELETE_MODULE, pathVariables);
	request(api, GET, null, callback);
}

