import React from "react";

import { messageMapDocument } from "Utilities/MessageMaps";


function TuteeCancellationPolicy(props) {
	return (
		<div className="sub-section-div">
			<h3 className="western">
				{messageMapDocument("tuteePolicy.section1.point3.text")}
			</h3>
			<p className="margin-bottom-spacing">
				{messageMapDocument("tuteePolicy.section1.point3.point1.text1")}
			</p>
			<p className="margin-bottom-spacing">
				{messageMapDocument("tuteePolicy.section1.point3.point1.text2")}
			</p>
			<p className="margin-bottom-spacing">
				{messageMapDocument("tuteePolicy.section1.point3.point1.text3")}
			</p>
		</div>
	);
}

export default TuteeCancellationPolicy;
