import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import Tooltip from "templates/Tooltip";

import majorsList from "metadata/majors.json";

import {listAPI} from "apis/controllers/ClassesController";
import {listAPI as subjectListAPI} from "apis/controllers/SubjectsController";
import {uploadVideoAPI} from "apis/controllers/video/VideoController";

import messageMap from "Utilities/MessageMaps";

import informationNoBorderAsset from "assets/icons/common/information-no-border.svg";

// TODO: update this page to our needs and move all text to using i18n keys
export default function CreateClass() {
	const [subjectList, setSubjectList] = useState([]),
				[classList, setClassList] = useState([]),
				[classClassName, setClassClassName] = useState("hidden"),
				[newSubject, setNewSubject] = useState(null),
				[newSubjectClassName, setNewSubjectClassName] = useState("hidden"),
				[newClassClassName, setNewClassClassName] = useState("hidden"),
				[newClass, setNewClass] = useState(null),
				[uploadedFiles, setUploadedFiles] = useState(null);

	useEffect(() => {
		getSubjectList();
	}, []);

	const history = useHistory();
	const buttonList = createButtons([
		{
			className: "submit",
			text: "Create Class"
		}, {
			className: "save",
			text: "Finish Later"
		}, {
			className: "cancel",
			text: "Cancel",
			action: () => {
				window.scrollTo(0, 0);
				history.push("/");
			}
		}
	]);
	const tooltips = createToolTips({
		class: "Examples include Math, Science, History, English, etc. If the class isn't included in the list, please select 'Other'.",
		subject: "Examples include Geometry, Physics, European History, Grammar, etc. If the subject isn't included in the list, please select 'Other'.",
	});

	function getSubjectList() {
		subjectListAPI(response => {
			let subjectList = [];
			response.forEach(el => {
				subjectList.push(el.category);
			});

			setSubjectList(subjectList);
		});
	}

	function showTopicsOptions(event, type) {
		const value = event.target.value;
		if (value !== "Select One") {
			if (value === "Other") {
				if (type === "subject") {
					setNewSubjectClassName("");
					setClassClassName("hidden");
				}
				else if (type === "class") {
					setNewClassClassName("");
				}
			}
			else {
				if (type === "subject") {
					setNewSubjectClassName("hidden");
					setClassClassName("");
					listAPI({subject: value}, response => {
						let classList = [];
						response.forEach(el => {
							classList.push(el.title);
						});
						setClassList(classList);
					});
				}
				else if  (type === "class") {
					setNewClassClassName("hidden");
				}
			}
		}
		else {
			if (type === "subject") {
				setNewSubjectClassName("hidden");
				setClassClassName("hidden");
				setClassList([]);
			}
			else if (type === "class") {
				setNewClassClassName("hidden");
			}
		}
	}

	// need to include file check
	function uploadFile() {
		// const payload = {
		// 	ownerId: localStorage.getItem("ownerId")
		// 	// videoFile: {
		// 	// 	lastModified: uploadedFiles.lastModified,
		// 	// 	lastModifiedDate: uploadedFiles.lastModifiedDate,
		// 	// 	name: uploadedFiles.name,
		// 	// 	size: uploadedFiles.size,
		// 	// 	type: uploadedFiles.type,
		// 	// 	webkitRelativePath: uploadedFiles.webkitRelativePath
		// 	// }
		// 	videoFile: new FormData(uploadedFiles)
		// };

		const formData = new FormData();
		formData.append("file", uploadedFiles);

		// const payload = new FormData(uploadedFiles);

		uploadVideoAPI(formData, resp => {
			console.log("resp: ", resp);
		});
	}

	return (
		<div className="create-class">
			<div className="title">
				Create a New Class
			</div>

			<div>
				<input type="file" onChange={e => setUploadedFiles(e.target.files[0])}></input>
				<button onClick={uploadFile}></button>
			</div>

			<div className="class-fields">

				<div className="required-desc">
					<span className="symbol">*</span> are required fields
				</div>

				<div className="subject-class">
					<div className="inline-option">
						<label htmlFor="subject">
							Subject
							<span className="symbol">*</span>
							{tooltips.class}
						</label>
						<select id="subject"
										onChange={(e) => showTopicsOptions(e, "subject")} onBlur={(e) => showTopicsOptions(e, "subject")}>
							{createList(subjectList)}
						</select>
						<input className={`new-option ${newSubjectClassName}`} type="text" onChange={(event) => setNewSubject(event.target.value)}></input>
					</div>
					<div className={`inline-option ${classClassName}`}>
						<label htmlFor="class">
							Class
							<span className="symbol">*</span>
							{tooltips.subject}
						</label>
						<select id="class" 
										onChange={(e) => showTopicsOptions(e, "class")} onBlur={(e) => showTopicsOptions(e, "class")}>
							{classList.length && createList(classList)}
						</select>
						<input className={`new-option ${newClassClassName}`} type="text" onChange={(event) => setNewClass(event.target.value)}></input>
					</div>
				</div>

				<div className="subheading">
					Class Categories
				</div>

				<div className="categories">
					<div className="inline-option">
						<label htmlFor="careers">
							Which career(s) is the class used for?{tooltips.career}
						</label>
						<label htmlFor="degrees">
							Which college major(s) is the class used for?{tooltips.major}
						</label>
						<label htmlFor="skills">
							Which skill(s)/trade(s) does the class teach?{tooltips.skill}
						</label>
					</div>
					<div className="inline-option inputs">
						<input type="text" id="careers">
						</input>
						<input type="text" id="degrees">
						</input>
						<input type="text" id="skills">
						</input>
					</div>
				</div>

			</div>

			<div className="create-actions">
				{buttonList}
			</div>
		</div>
	);
}

function createList(options) {
	let list = [];

	list.push(
		<option key="Select One" value="Select One">
			Select One
		</option>
	);

	options.forEach(opt => {
		list.push(
			<option key={opt} value={opt}>
				{opt}
			</option>
		);
	});

	list.push(
		<option key="Other" value="Other">
			Other
		</option>
	);

	return list;
}

function createButtons(buttons) {
	let buttonsArr = [];

	buttons.forEach(button => {
		buttonsArr.push(
			<button key={button.className} className={button.className} 
							onClick={button.action}>
				{button.text}
			</button>
		);
	});

	return buttonsArr;
}

function createToolTips(tooltipObjects) {
	let tooltips = {};

	Object.entries(tooltipObjects).forEach(([key, value]) => {
		tooltips[key] = <InformationTooltip text={value}/>
	});

	return tooltips;
}

function InformationTooltip(props) {
	const [tooltipStyle, setTooltipStyle] = useState({"display": "none"});
	const displayOn = {
		"marginTop": "-85px",
		"width": "400px"
	},
	displayOff = {
		"display": "none"
	},
	subheaderStyle = {
		"fontSize": "12px"
	}

	return (
		<div className="information-tooltip">
			<img src={informationNoBorderAsset}
						alt={messageMap("moreInfo", "image")} aria-describedby="createClassTooltip"
						onMouseOver={() => setTooltipStyle(displayOn)} onFocus={() => setTooltipStyle(displayOn)}
						onMouseOut={() => setTooltipStyle(displayOff)} onBlur={() => setTooltipStyle(displayOff)}
						></img>
			<Tooltip ariaReference="createClassTooltip" containerStyle={tooltipStyle} subheaderStyle={subheaderStyle} subheader={props.text}/>
		</div>
	);
}