export const LOGIN_VO = {
	username: {
		type: "string"
	},
	password: {
		type: "string"
	},
	timezone: {
		type: "string"
	}
};

export const ACCOUNT_VO = {
	email: {
		type: "string"
	},
	password: {
		type: "string"
	},
	username: {
		type: "string"
	},
	accessKey: {
		type: "string"
	}
};