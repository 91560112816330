import React, {useEffect, useState, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import {matchingKeysToElementsMap} from "pages/Classes/Practice/utilities/MatchingUtilities";
import {rollNSidedDice} from "pages/Classes/Practice/utilities/MathUtilities";

import TrueFalse from "../answerInputs/TrueFalse";
import FillInTheBlank from "../answerInputs/FillInTheBlank";
import MultipleChoice from "../answerInputs/MultipleChoice";

import {CORRECT, WRONG} from "pages/Classes/Practice/constants/PracticeConstants";


/**
 * @param {Function} setGenerateNewQuestionFunc // function to generate the next question
 * @param {Function} setUserAnswer // function to record user's answer
 * @param {Function} setProblemHint // function to pass hints to 'Practice' parent component
 * @param {Function} setCorrectAnswer // function to pass the answer to 'Practice' parent component
 * @param {Object} answerCorrectness // if current answer is right/wrong
 * @param {Function} setAnswerCorrectness // function for component to pass if user's answer is right/wrong
 * @param {Object} finishedAllQuestions // if user have finished all questions
 * @param {Function} setFinishedAllQuestions // function for component to pass if user has answered all possible questions for a topic
 * 
 * @param {String} exerciseMode // string key belonging to the following parent object = {
 * 	trueFalse: "True - False",
 * 	multipleChoice: "Multiple Choice",
 * 	fillInTheBlank: "Fill-in-the-Blank"
 * }
 * @param {String} matchingId // Identifier for the type of matching
 * 
 */
function Matching(props) {

	const [matchingProblem, setMatchingProblem] = useState();

	const matchAnswerRef = useRef(),
				currentDifficultyLevelRef = useRef(1),
				clearResponseRefComponentRef = useRef(),
				solvedProblemsRef = useRef([]);

	useEffect(() => {
		resetRefValues();
		attachParentProps();
		generateMatchingElements();
	}, [props.exerciseMode, props.matchId]);

	function resetRefValues() {
		currentDifficultyLevelRef.current = 1;
		solvedProblemsRef.current = [];

		props.setFinishedAllQuestions(false);
	}

	function attachParentProps() {
		props.setGenerateNewQuestionFunc(generateNewQuestion);

		props.setUserAnswer(recordedAnswer);
	}

	function generateNewQuestion(clearDifficulty) {
		clearDifficulty && resetRefValues();
		generateMatchingElements();

		clearResponseRefComponentRef.current();
	}

	function recordedAnswer() {
		if (props.answerCorrectness.current === CORRECT) {
			solvedProblemsRef.current.push(matchAnswerRef.current.toLowerCase());

			const matchingMap = matchingKeysToElementsMap[props.matchId];
			let baseIndex = getStartingIndex(matchingMap);
			if (!props.finishedAllQuestions.current
				&& matchingMap.difficultyLevels[currentDifficultyLevelRef.current].length + baseIndex === solvedProblemsRef.current.length) {
				if (solvedProblemsRef.current.length === matchingMap.totalQuestions) {
					props.setFinishedAllQuestions(true);
				}

				currentDifficultyLevelRef.current = currentDifficultyLevelRef.current + 1;
			}
		}
	}

	function getStartingIndex(matchingMap) {
		let baseIndex = 0;
		let numLevelsPassed = currentDifficultyLevelRef.current - 1;
		// helps ensure that we're not solving the same problems over and over again
		while (numLevelsPassed) {
			const numKeys = matchingMap.difficultyLevels[numLevelsPassed].length;
			for (let i = 0; i < numKeys; ++i) {
				++baseIndex;
			}

			--numLevelsPassed;
		}

		return baseIndex;
	}

	// Elements: Pictures, answer box(es)
	function generateMatchingElements() {
		const matchingMap = matchingKeysToElementsMap[props.matchId];
		const difficultyLevelsMap = matchingMap.difficultyLevels[currentDifficultyLevelRef.current];

		const unsolvedArrayEls = difficultyLevelsMap.filter(el => {
			return !solvedProblemsRef.current.includes(el);
		})
		const keyToUse = unsolvedArrayEls[rollNSidedDice(unsolvedArrayEls.length)];
		const imgAssetMap = matchingMap.images;
		const answer = matchingMap.answers[keyToUse];

		matchAnswerRef.current = answer;
		props.setCorrectAnswer(answer);
		props.setProblemHint(matchingMap.hints[keyToUse]);

		const imgSrc = imgAssetMap[keyToUse];
		const imgAlt = matchingMap.alt[keyToUse];

		let possibleOptionsCopy = [...Object.values(matchingMap.answers)];

		setMatchingProblem(
			<Fragment>
				<div className="picture-container">
					<img className="picture-to-match"
								src={imgSrc} alt={imgAlt}></img>
				</div>
				<div className="matching-input-container">
					{
						props.exerciseMode === "trueFalse"
						? (
								<TrueFalse matchId={props.matchId} answer={matchAnswerRef} possibleOptions={possibleOptionsCopy}
									setAnswerCorrectness={props.setAnswerCorrectness}
									setClearResponseFunc={recordClearResponseFunc}/>
						)
						: (
							props.exerciseMode === "multipleChoice"
							? (
									<MultipleChoice matchId={props.matchId} answer={matchAnswerRef} possibleOptions={possibleOptionsCopy}
										setAnswerCorrectness={props.setAnswerCorrectness}
										setClearResponseFunc={recordClearResponseFunc}/>
							)
							: (
									<FillInTheBlank matchId={props.matchId} answer={matchAnswerRef}
										setAnswerCorrectness={props.setAnswerCorrectness}
										setClearResponseFunc={recordClearResponseFunc}/>
							)
						)
					}
				</div>
			</Fragment>
		);
	}

	function recordClearResponseFunc(func) {
		clearResponseRefComponentRef.current = func;
	}


	return (
		<div className="matching-container">
			{matchingProblem}
		</div>
	);
}


Matching.propTypes = {
	matchId: PropTypes.string.isRequired,
	exerciseMode: PropTypes.string.isRequired,

	// ref props to pass to parent
	answerCorrectness: PropTypes.object.isRequired,
	setAnswerCorrectness: PropTypes.func.isRequired,
	finishedAllQuestions: PropTypes.object.isRequired,
	setFinishedAllQuestions: PropTypes.func.isRequired,
	setCorrectAnswer: PropTypes.func.isRequired,
	setProblemHint: PropTypes.func.isRequired,
	setUserAnswer: PropTypes.func.isRequired,
	setGenerateNewQuestionFunc: PropTypes.func.isRequired
};

export default Matching;
