import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { onKeyDown } from "Utilities/Accessibility";

import chevronAsset from "assets/icons/common/chevron.svg";


function Dropdown(props) {

	const [dropdownOptions, setDropdownOptions] = useState(),
		[selectedOption, setSelectedOption] = useState(),
		[selectedOptionAttribute, setSelectedOptionAttribute] = useState(),
		// specifically for filtering
		[checkedFilters, setCheckedFilters] = useState([]);

	const chevronRef = useRef(),
		dropdownOptionsRef = useRef();

	useEffect(() => {
		createDropdownOptions();
		props.preselectedKey && setPreselectedValue();
	}, []);

	function createDropdownOptions() {
		let dropdownItems = [];
		let selectedAttribute = {};
		let selectedOption;

		if (props.dropdownOptions) {
			Object.keys(props.dropdownOptions).forEach(element => {
				let customAttributes = {};
				if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
					customAttributes = {
						[props.customDropdownItemAttribute.toLowerCase()]: element
					};
				}

				let innerHtml = props.dropdownOptions[element];
				if (props.filterMode) {
					let cleanedElement = element.replaceAll(/[ &$-.]/g, "");
					for (let i = 0; i < cleanedElement.length; ++i) {
						// check if character is a number
						if (!isNaN(parseFloat(cleanedElement[i])) && isFinite(cleanedElement[i])) {
							cleanedElement = cleanedElement.replaceAll(cleanedElement[i], String.fromCharCode(96 + cleanedElement[i]));
						}
					}

					innerHtml = (
						<Fragment>
							{/* don't use id for <input/> and htmlFor <label/>, since it simulates a click, 
							which messes with props.dropdownItemClickHandler. To bypass this, we're relying on
							domkey for queryselectAll and keeping the original value in orgikey attribute */}
							<input type="checkbox" domkey={`${cleanedElement}_${props.purpose}`} origkey={element}/>
							<label domkey={`${cleanedElement}_${props.purpose}`} origkey={element}>
								{props.dropdownOptions[element]}
							</label>
						</Fragment>
					);
				}

				if ((props.filterMode && element !== props.excludeFromList)
						|| !props.filterMode) {
					dropdownItems.push(
						<div key={element} {...customAttributes} tabIndex={0} role="button"
							onClick={dropdownItemClickHandler}
							onKeyDown={e => onKeyDown(e, dropdownItemClickHandler, [e])}>
							{innerHtml}
						</div>
					);
				}
			});

			if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
				selectedAttribute = {
					[props.customDropdownItemAttribute.toLowerCase()]: Object.keys(props.dropdownOptions)[0]
				};
			}

			selectedOption = Object.values(props.dropdownOptions)[0];
		}

		if (props.dropdownOptions) {
			selectedOption = Object.values(props.dropdownOptions)[0];
		}
		else {
			selectedOption = "";
		}

		setSelectedOptionAttribute(selectedAttribute);
		setSelectedOption(selectedOption);
		setDropdownOptions(dropdownItems);
	}

	function setPreselectedValue() {
		const selectedAttribute = {
			[props.customDropdownItemAttribute.toLowerCase()]: props.preselectedKey
		};
		const selectedOption = props.dropdownOptions[props.preselectedKey];

		setSelectedOptionAttribute(selectedAttribute);
		setSelectedOption(selectedOption);
	}

	function dropdownItemClickHandler(e) {
		const target = e.target;

		let selectedOption;
		let selectedAttribute = {};
		if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
			const customKey = target.getAttribute(props.customDropdownItemAttribute);
			selectedOption = props.dropdownOptions[customKey];

			selectedAttribute = {
				[props.customDropdownItemAttribute.toLowerCase()]: customKey
			};
		}
		else {
			selectedOption = target.innerText;
		}

		let copiedCheckedFilters = checkedFilters;
		if (props.filterMode) {
			const domKey = target.getAttribute("domkey");
			const origKey = target.getAttribute("origKey");
			// non-React way, but it gets the job done really simply
			let inputEl = document.querySelectorAll(`input[domkey=${domKey}]`);

			if (copiedCheckedFilters.includes(origKey)) {
				copiedCheckedFilters.splice(copiedCheckedFilters.indexOf(origKey), 1);
				inputEl[0] && (inputEl[0].checked = false);
			}
			else {
				copiedCheckedFilters.push(origKey);
				inputEl[0] && (inputEl[0].checked = true);
			}

			setCheckedFilters(copiedCheckedFilters);
		}

		!props.filterMode && setSelectedOptionAttribute(selectedAttribute);
		!props.filterMode && setSelectedOption(selectedOption);
		props.dropdownItemClickHandler && props.dropdownItemClickHandler(e, props.customDropdownItemAttribute, copiedCheckedFilters);
	}

	function toggleDropdownDisplay(e) {
		if (!props.customContainerClass.includes("not-allowed")) {
			const target = e.target;
			const attributeValue = target.getAttribute(props.customDropdownItemAttribute);
			if (props.filterMode
					&& ((!attributeValue && (target.tagName === "INPUT" || target.tagName === "LABEL"))
					|| (attributeValue && attributeValue !== props.excludeFromList && target.tagName === "DIV"))
				) {
				return;
			}
	
			if (dropdownOptionsRef.current.className === "dropdown-options hide") {
				document.querySelectorAll(`div[class="dropdown-options"]`).forEach(openedDropdowns => {
					openedDropdowns.setAttribute("class", "dropdown-options hide");
				});
	
				dropdownOptionsRef.current.className = "dropdown-options";
				chevronRef.current.className = "chevron-icon-up";
			}
			else {
				dropdownOptionsRef.current.className = "dropdown-options hide";
				chevronRef.current.className = "chevron-icon-down";
			}
		}
	}

	return (
		<div className={`dropdown-container ${props.customContainerClass} ${props.filterMode && "filter-mode"}`}
			onClick={toggleDropdownDisplay} onKeyDown={e => onKeyDown(e, toggleDropdownDisplay, [e])}>
			<div className="dropdown-selector">
				<div ref={props.selectedOptionParentRef}  {...selectedOptionAttribute} >
					{selectedOption}
				</div>
				<img src={chevronAsset} alt="chevron" className="chevron-icon-down" ref={chevronRef}></img>
			</div>
			<div className="dropdown-options hide" ref={dropdownOptionsRef}>
				{dropdownOptions}
			</div>
		</div>
	);
}

Dropdown.defaultProps = {
	customContainerClass: "",
	dropdownOptions: {},
	filterMode: false
};

Dropdown.propTypes = {
	preselectedKey: PropTypes.string,
	customContainerClass: PropTypes.string,
	dropdownOptions: PropTypes.object.isRequired,
	dropdownItemClickHandler: PropTypes.func,
	customDropdownItemAttribute: PropTypes.string.isRequired,

	filterMode: PropTypes.bool,
	excludeFromList: PropTypes.string,
	purpose: PropTypes.string,

	// parent ref
	selectedOptionParentRef: PropTypes.object
};

export default Dropdown;


