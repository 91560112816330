import React from 'react';

import messageMap from "Utilities/MessageMaps";

import {videosSchema, modulesSchema} from "pages/Classes/Videos/schemas/EditVideosListSchemas";

import {listAllVideosAPI, getVideoEntriesCountAPI, 
				getVideoMetadataAPI, deleteVideoContentAPI} from "apis/controllers/video/VideoController";
import {listAllModulesAPI, getModuleEntriesCountAPI,
				getModuleMetadataAPI, deleteModuleAPI} from "apis/controllers/video/VideoModuleController";


/************ SCHEMA UTILITIES ***********/

const tableHeaderSchemaMap = {
	videos: videosSchema.tableHeader,
	modules: modulesSchema.tableHeader
};
export function getTableHeaderSchema(filterValue) {
	return tableHeaderSchemaMap[filterValue];
}


const rowSchemaMap = {
	videos: videosSchema.row,
	modules: modulesSchema.row
};
export function getRowSchema(filterValue) {
	return rowSchemaMap[filterValue];
}


/************ API UTILITIES ***********/

const listAllAPIMap = {
	videos: listAllVideosAPI,
	modules: listAllModulesAPI
};
export function getListAllAPI(filterValue) {
	return listAllAPIMap[filterValue];
}


const entriesCountAPIMap = {
	videos: getVideoEntriesCountAPI,
	modules: getModuleEntriesCountAPI
};
export function getEntriesCountAPI(filterValue) {
	return entriesCountAPIMap[filterValue];
}


const metadataAPIMap = {
	videos: getVideoMetadataAPI,
	modules: getModuleMetadataAPI
};
export function getMetadataAPI(filterValue) {
	return metadataAPIMap[filterValue];
}


const deleteAPIMap = {
	videos: deleteVideoContentAPI,
	modules: deleteModuleAPI
};
export function getDeleteAPI(filterValue) {
	return deleteAPIMap[filterValue];
}


/************ Video & Module UTILITIES ***********/

function getVideoRowLogicValues(item) {
	const fileObjMap = item.downloadTypeToFiles;
	const date = new Date(item.uploadDate * 1000);
	const thumbnailFile = fileObjMap["thumbnail"][0].file;
	const mentalModelFile = fileObjMap["mentalModel"].length ? fileObjMap["mentalModel"][0].file : null;
	const isProcessing = item.downloadStatus ? (
		<div className="processing-div-block">
			{messageMap("video.processing", "generic")}
		</div>
	) : "";
	let videoTitle = item.title;
	if (videoTitle.length > 50) {
		videoTitle = videoTitle.slice(0, 45) + "...";
	}
	let earnings = item.earnings ? item.earnings.toFixed(2): null;

	return {
		isProcessing: isProcessing,
		thumbnailFile: thumbnailFile,
		videoTitle: videoTitle,
		fullTitle: item.title,
		mentalModelFile: mentalModelFile,
		date: date.toDateString(),
		earnings: earnings
	};
}
function getModuleRowLogicValues(item) {
	const date = new Date(item.creationDate * 1000);
	const thumbnailFile = item.thumbnailFile;
	let playlistName = item.playlistName;
	if (playlistName.length > 50) {
		playlistName = playlistName.slice(0, 45) + "...";
	}

	return {
		thumbnailFile: thumbnailFile,
		playlistName: playlistName,
		fullPlaylistName: item.playlistName,
		date: date.toDateString()
	};
}
const rowLogicValuesMap = {
	videos: getVideoRowLogicValues,
	modules: getModuleRowLogicValues
};
export function getRowLogicValues(filterValue) {
	return rowLogicValuesMap[filterValue];
}


const editModalMap = {
	videos: videosSchema.editModal,
	modules: modulesSchema.editModal
};
export function getEditModal(filterValue) {
	return editModalMap[filterValue];
}


export function addStringDurations(currentDuration, durationToAdd) {
	if (!currentDuration.includes(":")) {
		currentDuration = "0:00";
	}

	let curTimeSplit = currentDuration.split(":");
	let timeSplit = durationToAdd.split(":");
	// ordering: second, minutes, hours
	let newDuration = [];

	for (let i = 0; i < curTimeSplit.length; ++i) {
		let curTimeEl = parseInt(curTimeSplit[curTimeSplit.length - 1 - i]);
		let timeEl = parseInt(timeSplit[timeSplit.length - 1 - i]);

		let timeElTotal = (curTimeEl + timeEl) / 60;

		if (timeElTotal > 1) {
			newDuration[i] = String(Math.round((timeElTotal - 1) * 60));

			let followingTimeVal = curTimeSplit[curTimeSplit.length - 1 - i - 1];
			if (followingTimeVal) {
				curTimeSplit[curTimeSplit.length - 1 - i - 1] = String(parseInt(followingTimeVal) + 1);
			}
		}
		else {
			newDuration[i] = String(Math.round(timeElTotal * 60));
		}

		if (newDuration[i].length === 1 
				&& (newDuration.length === 1 
					|| (newDuration.length === 2 && curTimeSplit.length > 2))) {
			newDuration[i] = String(	"0" + Math.round(	parseInt(newDuration[i])	)	);
		}
	}

	if (newDuration.length === 2) {
		currentDuration = [newDuration[1], newDuration[0]].join(":");
	}
	else {
		currentDuration = [newDuration[2], newDuration[1], newDuration[0]].join(":");
	}

	return currentDuration;
}
