export function convertArrayToObject(array) {
	return array.reduce(
		(newObj, arrayEl) => {
			newObj[arrayEl] = arrayEl;
			return newObj;
		}, {}
	);
}

export function checkObjectKeysExist(object, keysToCheck) {
	let allPresent = true;

	for (let i = 0; i < keysToCheck.length; ++i) {
		if (object[keysToCheck[i]] == null) {
			allPresent = false;
			break;
		}
	}

	return allPresent;
}