export const mathMessageMap = {
	elementaryMath: "Elementary math",
	middleSchoolMath: "Middle school math",
	algebra: "Algebra",
	geometry: "Geometry",
	trigonometry: "Trigonometry",
	statistics: "Statistics",
	calculus: "Calculus",
	discreteMath: "Discrete math",
	linearAlgebra: "Linear algebra"
};