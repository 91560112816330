import React, {useState, Fragment} from 'react';

import Team from "pages/About/Team";
import SignUpModal from "templates/customModals/SignUpModal";
import LoginModal from "templates/customModals/LoginModal";

import messageMap from "Utilities/MessageMaps";
import { MODAL_CLOSE_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';

import rectangleAsset from "assets/images/home/rectangle.png";

function About() {

	const [signUpModal, setSignUpModal] = useState(),
				[loginModal, setLoginModal] = useState();

	function openSignUpModal() {
		setSignUpModal(
			// TODO: use Redux to have NavBar handle this instead
			<SignUpModal title={messageMap("account.signUp.signUpFirst", "generic")} 
									closeModal={closeSignUpModal}
									subHeader={(
										<Fragment>
											<div>
												{messageMap("account.signUp.existingAccount", "generic")}
												<button onClick={() => openAnotherModal("login", 1)}
													style={{border: "none", color: "#007bff", backgroundColor: "white"}}>
													{messageMap("links.login", "button")}
												</button>
											</div>
										</Fragment>
									)}/>
		);
	}

	function openAnotherModal(type) {
		if (type === "login") {
			setLoginModal(
				<LoginModal closeHandler={closeLoginModal} submitHandler={closeLoginModal}/>
			);
		}
	}

	function closeSignUpModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className))) {
			hideSignUpModal("signUp");
		}
	}

	function closeLoginModal(e, args) {
		if (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className)) {
			hideSignUpModal("login");
		}
		else if (e === null && args === "loginSuccess") {
			hideSignUpModal("login");
			hideSignUpModal("signUp");
		}
	}

	function hideSignUpModal(modalType) {
		const setModalMap = {
			login: setLoginModal,
			signUp: setSignUpModal
		};

		setTimeout(() => {
			setModalMap[modalType](null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	return (
		<div className="about">

			<div className="message-container">
				<div className="message">
					{/* <div className="headline">
						<h2>
							{messageMap("aboutUs.headline.title", "document")}
						</h2>
						<p>
							{messageMap("aboutUs.headline.subheader", "document")}
						</p>
					</div> */}

					<div className="mission-statement">
						<h3 className="mission-headline">
							{messageMap("aboutUs.mission.title", "document")}
						</h3>

						<div className="mission-column-container">
							<div className="mission-column first">
								<p>
									{messageMap("aboutUs.mission.p1", "document")}
								</p>
								<p>
									{messageMap("aboutUs.mission.p2", "document")}
								</p>
								<p>
									{messageMap("aboutUs.mission.p3", "document")}
								</p>
							</div>
							<div className="mission-column">
								<p>
									{messageMap("aboutUs.mission.p4", "document")}
								</p>
								<p>
									{messageMap("aboutUs.mission.p5", "document")}
								</p>
								<p>
									{messageMap("aboutUs.mission.p6", "document")}
								</p>
							</div>
						</div>
					</div>
				</div>
				<img src={rectangleAsset} className="rectangle"></img>
			</div>

			<Team />

			<div className="cta-container">
				<div className="cta-message">
					{messageMap("home.cta.message", "generic")}
				</div>
				<button className="join-button" onClick={openSignUpModal}>
					{messageMap("home.joinCta", "button")}
				</button>
			</div>

			{loginModal}
			{signUpModal}
		</div>
	);
}

export default About;