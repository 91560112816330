import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/learningMaterials/thumbnailMisconceptions.jpg";



export const learningMaterialsMisconceptionArticle = {
	id: "IV. Learning Materials_3_0 Misconceptions about learning preferences",
	title: messageMap("learningMisconceptions.article.title", "learningOptimization"),
	description: messageMap("learningMisconceptions.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("learningMisconceptions.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4",
		"text5", "text6", "text7",
		"text8", "text9", "text10",
		"text11", "text12",
		"text13", "text14", "text15",
		"text16", "text17", "text18",
		"text19", "text20", "text21",
		"text22", "text23", "text24", "text25", "text26"
	],
	datePublished: "2023-11-30",
	dateModified: "2024-06-24"
};

export const learningMaterialsMisconceptionArticleTexts = {
	text1: {
		text: messageMap("learningMisconceptions.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("learningMisconceptions.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("learningMisconceptions.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("learningMisconceptions.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("learningMisconceptions.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("learningMisconceptions.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("learningMisconceptions.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("learningMisconceptions.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text9: {
		text: messageMap("learningMisconceptions.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("learningMisconceptions.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("learningMisconceptions.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text12: {
		text: messageMap("learningMisconceptions.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("learningMisconceptions.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text14: {
		text: messageMap("learningMisconceptions.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("learningMisconceptions.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("learningMisconceptions.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text17: {
		text: messageMap("learningMisconceptions.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("learningMisconceptions.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("learningMisconceptions.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text20: {
		text: messageMap("learningMisconceptions.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("learningMisconceptions.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text23: {
		text: messageMap("learningMisconceptions.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text24: {
		text: messageMap("learningMisconceptions.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text25: {
		text: messageMap("learningMisconceptions.sources.s3", "learningOptimization"),
		type: SOURCE
	},
	text26: {
		text: messageMap("learningMisconceptions.sources.s4", "learningOptimization"),
		type: SOURCE
	}
};


