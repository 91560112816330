/* eslint-disable no-template-curly-in-string */
import {EARLY_ACCESS} from "apis/models/Promotions";

import request, {POST, GET} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const BASE = "/services/promo";

const ACCESS = BASE + "/early/access";
const LIST_ALL = BASE + "/list/all?ownerId=${ownerId}";
const MARK_SEEN = BASE + "/mark/seen";


/**
 * @param {*} pathVariables = {
 * 	ownerId: String
 * }
 */
export function listAllPromoCodesAPI(pathVariables, callback) {
	const api = $replace(LIST_ALL, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} payload  = PROMOTION_VO
 */
export function markSeenPromotion(payload, callback) {
	request(MARK_SEEN, POST, payload, callback);
}

/**
 * @param {String} payload = email
 */
export function accessAPI(payload, callback) {
	modelValidator(EARLY_ACCESS, payload) && request(ACCESS, POST, payload, callback);
}