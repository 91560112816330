import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from "Utilities/Validators/IdentityValidators";

import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";

import TutorsList from "pages/Tutoring/subPages/TutorsList";


function SavedTutors(props) {

	const identityIsValid = isValidIdentity(props.ownerId);

	const [signUpFirst, setSignUpFirst] = useState();
	const [savedTutors, addSavedTutors] = useState();

	useEffect(() => {
		getSavedTutors();
	}, []);

	function getSavedTutors() {
		if (identityIsValid) {
			const curDate = new Date();
			// key is to force refresh
			addSavedTutors(
				<TutorsList key={curDate.getTime()} useFilter={true} purpose={"viewSaved"} setAlert={props.setAlert}
					setModal={props.setModal} setModal1={props.setModal1} />
			);
		}
		else {
			setSignUpFirst(
				<div className="empty-list-container">
					<div className="empty-list">
						{messageMap("tutoringPage.tutorList.signUpFirstSaved", "generic")}
						<button className="sign-up-button"
							onClick={e => askUserToSignUpFirst(props.setModal, props.setModal1)}>
							{messageMap("link.text", "button")}
						</button>
					</div>
				</div>
			);
		}
	}

	return (
		<div className="saved-tutors-page">
			<Helmet>
				<title>{messageMap("savedTutors.title", "headerTag")}</title>
				<meta name="description" content={messageMap("savedTutors.description", "headerTag")}></meta>
			</Helmet>
			{
				props.ownerId ?
				(
					savedTutors
				)
				: (
					signUpFirst
				)
			}
		</div>
	);
}

SavedTutors.propTypes = {
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setLoginModal: PropTypes.func,

	// redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(SavedTutors);


