import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/thumbnailIntentions.jpg";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";


export const mentalityAndAttitudeIntentionsArticle = {
	id: "II. Mentality & Attitude_1_1 How our intentions affect how our mind works",
	title: messageMap("intentions.article.title", "learningOptimization"),
	description: messageMap("intentions.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("intentions.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2",
		"text3", "text4",
		"text5", "text6",
		"text7", "text8",
		"text9", "text10"
	],
	datePublished: "2023-12-16",
	dateModified: "2024-06-21"
};

export const mentalityAndAttitudeIntentionsArticleTexts = {
	text1: {
		text: messageMap("intentions.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("intentions.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("intentions.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("intentions.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("intentions.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("intentions.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("intentions.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text8: {
		text: messageMap("intentions.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text10: {
		text: messageMap("intentions.sources.s1", "learningOptimization"),
		type: SOURCE
	}
};


