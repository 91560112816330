import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/environment/thumbnailSmell.jpg";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";


export const environmentSmellArticle = {
	id: "I. Environment_0_2 How scents affect our learning",
	title: messageMap("scents.article.title", "learningOptimization"),
	description: messageMap("scents.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("scents.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2",
		"text3", "text4",
		"text5", "text6", "text7",
		"text8", "text9",
		"text10", "text11",
		"text12", "text13",
		"text14", "text15",
		"text16", "text17", "text18", "text19"
	],
	datePublished: "2023-11-30",
	dateModified: "2024-06-21"
};

export const environmentSmellArticleTexts = {
	text1: {
		text: messageMap("scents.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("scents.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("scents.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("scents.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("scents.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("scents.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("scents.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("scents.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text9: {
		text: messageMap("scents.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("scents.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text11: {
		text: messageMap("scents.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("scents.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text13: {
		text: messageMap("scents.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("scents.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text15: {
		text: messageMap("scents.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text17: {
		text: messageMap("scents.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text18: {
		text: messageMap("scents.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text19: {
		text: messageMap("scents.sources.s3", "learningOptimization"),
		type: SOURCE
	}
};


