import { GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK } from "diagrams/utilities/NetworkConstants";

// MATH
// level 01 math
import {naming2DShapesArticle, naming2DShapesArticleTexts, naming2DShapesArticleHTMLs} from "assets/topicArticles/Math/Level 01 Math/I- Figures/A. Naming 2D Shapes";
import {naming3DShapesArticle, naming3DShapesArticleTexts, naming3DShapesArticleHTMLs} from "assets/topicArticles/Math/Level 01 Math/I- Figures/B. Naming 3D Shapes";
import {spatialRelationsArticle, spatialRelationsArticleTexts, spatialRelationsArticleHTMLs} from "assets/topicArticles/Math/Level 01 Math/II- Relations/A. Spatial Relations- Up, Down, Left, Right, Forward, and Backward";
import {comparingQuantitiesArticle, comparingQuantitiesArticleTexts, comparingQuantitiesArticleHTMLs} from "assets/topicArticles/Math/Level 01 Math/II- Relations/B. Comparing Quantities Through Pictures";
import {comparingSizesArticle, comparingSizesArticleTexts, comparingSizesArticleHTMLs} from "assets/topicArticles/Math/Level 01 Math/II- Relations/C. Comparing Sizes Through Pictures";
import {comparingLengthsArticle, comparingLengthsArticleTexts, comparingLengthsArticleHTMLs} from "assets/topicArticles/Math/Level 01 Math/II- Relations/D. Comparing Lengths Through Pictures";
import {countingWithPicturesNNumbersArticle} from "assets/topicArticles/Math/Level 01 Math/III- Numbers/A. Counting with Pictures and Numbers";
import {singleDigitAdditionArticle, singleDigitAdditionArticleTexts, singleDigitAdditionArticleHTMLs} from "assets/topicArticles/Math/Level 01 Math/III- Numbers/B. Single-Digit Addition with Pictures and Numbers";
import {singleDigitSubtractionArticle, singleDigitSubtractionArticleTexts, singleDigitSubtractionArticleHTMLs} from "assets/topicArticles/Math/Level 01 Math/III- Numbers/C. Single-Digit Subtraction with Pictures and Numbers";
// level 05 math
import {countingTo100UsingNumeralPatternsArticle, countingTo100UsingNumeralPatternsArticleTexts, countingTo100UsingNumeralPatternsArticleHTMLs} from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/A. How to Count to 100 using Numeral Patterns - Skip Counting by 10s";
import {countingTo100UsingNumeralPatternsArticleB} from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/B. How to Count to 100 using Numeral Patterns - Skip Counting by 5s";
import {countingTo100UsingNumeralPatternsArticleC} from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/C. How to Count to 100 using Numeral Patterns - Skip Counting by 2s";
import {orderingWithOrdinalsArticle, orderingWithOrdinalsArticleTexts, orderingWithOrdinalsArticleHTMLs} from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/D. How Ordering Works and What are Ordinals";
import {numbersWithRomanNumeralsArticle, numbersWithRomanNumeralsArticleTexts, numbersWithRomanNumeralsArticleHTMLs} from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/E. What are Roman Numerals";


// learning optimization
// environment
import {environmentEyeArticle, environmentEyeArticleTexts} from "assets/topicArticles/LearningOptimizations/environment/sight";
import {environmentSound, environmentSoundTexts} from "assets/topicArticles/LearningOptimizations/environment/hearing";
import {environmentSmellArticle, environmentSmellArticleTexts} from "assets/topicArticles/LearningOptimizations/environment/smelling";
import {environmentFeelingsArticles, environmentFeelingsArticlesTexts} from "assets/topicArticles/LearningOptimizations/environment/feelings";
// mentality and attitude
import {mentalityAndAttitudeMisconceptionArticle, mentalityAndAttitudeMisconceptionArticleTexts} from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/misconceptions";
import {mentalityAndAttitudeIntentionsArticle, mentalityAndAttitudeIntentionsArticleTexts} from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/intentions";
import {mentalityAndAttitudePrescienceArticle, mentalityAndAttitudePrescienceArticleTexts} from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/prescience";
import {mentalityAndAttitudeStateOfMindArticle, mentalityAndAttitudeStateOfMindArticleTexts} from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/stateOfMind";
import {mentalityAndAttitudePepTalkArticle, mentalityAndAttitudePepTalkArticleTexts} from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/pepTalk";
import {mentalityAndAttitudePerseveranceScienceArticle, mentalityAndAttitudePerseveranceScienceArticleTexts} from "assets/topicArticles/LearningOptimizations/mentalityAndAttitude/perseveranceScience";
// well being
import {wellBeingDietArticle, wellBeingDietArticleTexts, wellBeingDietArticleHTMLs} from "assets/topicArticles/LearningOptimizations/wellBeing/diet";
import {wellBeingExerciseArticle, wellBeingExerciseArticleTexts} from "assets/topicArticles/LearningOptimizations/wellBeing/exercise";
import {wellBeingGoodBadStressArticle, wellBeingGoodBadStressArticleTexts} from "assets/topicArticles/LearningOptimizations/wellBeing/goodBadStress";
import {wellBeingHandlingStressArticle, wellBeingHandlingStressArticleTexts} from "assets/topicArticles/LearningOptimizations/wellBeing/handlingStress";
import {wellBeingRestSleepArticle, wellBeingRestSleepArticleTexts} from "assets/topicArticles/LearningOptimizations/wellBeing/restSleep";
import {wellBeingRestBreakArticle, wellBeingRestBreakArticleTexts, wellBeingRestBreakArticleHTMLs} from "assets/topicArticles/LearningOptimizations/wellBeing/restBreak";
// learning materials
import {learningMaterialsMisconceptionArticle, learningMaterialsMisconceptionArticleTexts} from "assets/topicArticles/LearningOptimizations/learningMaterials/misconceptions";
import {learningMaterialsGoalsEvaluationsArticle, learningMaterialsGoalsEvaluationsArticleTexts, learningMaterialsGoalsEvaluationsArticleHTMLs} from "assets/topicArticles/LearningOptimizations/learningMaterials/goalsEvaluations";
import {learningMaterialsLearningMaterialsArticle, learningMaterialsLearningMaterialsArticleTexts, learningMaterialsLearningMaterialsArticleHTMLs} from "assets/topicArticles/LearningOptimizations/learningMaterials/learningMaterials";
// physiology
import {physiologyDeclarativeSystemArticle, physiologyDeclarativeSystemArticleTexts, physiologyDeclarativeSystemArticleHTMLs} from "assets/topicArticles/LearningOptimizations/physiology/declarativeSystem";
import {physiologyProceduralSystemArticle, physiologyProceduralSystemArticleTexts, physiologyProceduralSystemArticleHTMLs} from "assets/topicArticles/LearningOptimizations/physiology/proceduralSystem";
import {physiologySystemsCombinedArticle, physiologySystemsCombinedArticleTexts, physiologySystemsCombinedArticleHTMLs} from "assets/topicArticles/LearningOptimizations/physiology/systemsCombined";
import {physiologyEfficientStorageArticle, physiologyEfficientStorageArticleTexts, physiologyEfficientStorageArticleHTMLs} from "assets/topicArticles/LearningOptimizations/physiology/efficientStorage";
import {physiologyAcquiringAndRefining1Article, physiologyAcquiringAndRefining1ArticleTexts, physiologyAcquiringAndRefining1ArticleHTMls} from "assets/topicArticles/LearningOptimizations/physiology/acquiringAndRefining1";


const availableTopics = {
	[GRADE_SCHOOL_WORKBOOK]: {
		math: {
			// Level 1
			"I: Figures_A. Naming 2D Shapes": {
				metadata: naming2DShapesArticle,
				text: naming2DShapesArticleTexts,
				html: naming2DShapesArticleHTMLs
			},
			"I: Figures_B. Naming 3D Shapes": {
				metadata: naming3DShapesArticle,
				text: naming3DShapesArticleTexts,
				html: naming3DShapesArticleHTMLs
			},
			"II: Relations_A. Spatial Relations: Up, Down, Left, Right, Forward, and Backward": {
				metadata: spatialRelationsArticle,
				text: spatialRelationsArticleTexts,
				html: spatialRelationsArticleHTMLs
			},
			"II: Relations_B. Comparing Quantities Through Pictures": {
				metadata: comparingQuantitiesArticle,
				text: comparingQuantitiesArticleTexts,
				html: comparingQuantitiesArticleHTMLs
			},
			"II: Relations_C. Comparing Sizes Through Pictures": {
				metadata: comparingSizesArticle,
				text: comparingSizesArticleTexts,
				html: comparingSizesArticleHTMLs
			},
			"II: Relations_D. Comparing Lengths Through Pictures": {
				metadata: comparingLengthsArticle,
				text: comparingLengthsArticleTexts,
				html: comparingLengthsArticleHTMLs
			},
			"III: Numbers_A. Counting with Pictures and Numbers": {
				metadata: countingWithPicturesNNumbersArticle,
				text: comparingQuantitiesArticleTexts,
				html: comparingQuantitiesArticleHTMLs
			},
			"III: Numbers_B. Single Digit Addition with Pictures and Numbers": {
				metadata: singleDigitAdditionArticle,
				text: singleDigitAdditionArticleTexts,
				html: singleDigitAdditionArticleHTMLs
			},
			"III: Numbers_C. Single Digit Subtraction with Pictures and Numbers": {
				metadata: singleDigitSubtractionArticle,
				text: singleDigitSubtractionArticleTexts,
				html: singleDigitSubtractionArticleHTMLs
			},
			// Level 5
			"I: Numbers and Ordering_A. How to Count to 100 using Numeral Patterns Skip Counting by 10s": {
				metadata: countingTo100UsingNumeralPatternsArticle,
				text: countingTo100UsingNumeralPatternsArticleTexts,
				html: countingTo100UsingNumeralPatternsArticleHTMLs
			},
			"I: Numbers and Ordering_B. How to Count to 100 using Numeral Patterns Skip Counting by 5s": {
				metadata: countingTo100UsingNumeralPatternsArticleB,
				text: countingTo100UsingNumeralPatternsArticleTexts,
				html: countingTo100UsingNumeralPatternsArticleHTMLs
			},
			"I: Numbers and Ordering_C. How to Count to 100 using Numeral Patterns Skip Counting by 2s": {
				metadata: countingTo100UsingNumeralPatternsArticleC,
				text: countingTo100UsingNumeralPatternsArticleTexts,
				html: countingTo100UsingNumeralPatternsArticleHTMLs
			},
			"I: Numbers and Ordering_D. How Ordering Works and What are Ordinals": {
				metadata: orderingWithOrdinalsArticle,
				text: orderingWithOrdinalsArticleTexts,
				html: orderingWithOrdinalsArticleHTMLs
			},
			"I: Numbers and Ordering_E. What are Roman Numerals": {
				metadata: numbersWithRomanNumeralsArticle,
				text: numbersWithRomanNumeralsArticleTexts,
				html: numbersWithRomanNumeralsArticleHTMLs
			}
		},
		optimizing_learning: {
			"I. Environment_0_0 How the things we see affect our learning": {
				metadata: environmentEyeArticle,
				text: environmentEyeArticleTexts
			},
			"I. Environment_0_1 How sounds affect our learning": {
				metadata: environmentSound,
				text: environmentSoundTexts
			},
			"I. Environment_0_2 How scents affect our learning": {
				metadata: environmentSmellArticle,
				text: environmentSmellArticleTexts
			},
			"I. Environment_0_3 How our feelings affect our learning": {
				metadata: environmentFeelingsArticles,
				text: environmentFeelingsArticlesTexts
			},
			"II. Mentality & Attitude_1_0 Common misconceptions about learning and how learning actually works": {
				metadata: mentalityAndAttitudeMisconceptionArticle,
				text: mentalityAndAttitudeMisconceptionArticleTexts
			},
			"II. Mentality & Attitude_1_1 How our intentions affect how our mind works": {
				metadata: mentalityAndAttitudeIntentionsArticle,
				text: mentalityAndAttitudeIntentionsArticleTexts
			},
			"II. Mentality & Attitude_1_2 How prescience and our expectations shape our resilience": {
				metadata: mentalityAndAttitudePrescienceArticle,
				text: mentalityAndAttitudePrescienceArticleTexts
			},
			"II. Mentality & Attitude_1_3 How our state of mind influences our learning": {
				metadata: mentalityAndAttitudeStateOfMindArticle,
				text: mentalityAndAttitudeStateOfMindArticleTexts
			},
			"II. Mentality & Attitude_1_4 Perseverance: a small pep talk": {
				metadata: mentalityAndAttitudePepTalkArticle,
				text: mentalityAndAttitudePepTalkArticleTexts
			},
			"II. Mentality & Attitude_1_5 The science behind perseverance": {
				metadata: mentalityAndAttitudePerseveranceScienceArticle,
				text: mentalityAndAttitudePerseveranceScienceArticleTexts
			},
			"III. Well Being_2_0 How our diet affects our brain health and the foods we should eat and avoid": {
				metadata: wellBeingDietArticle,
				text: wellBeingDietArticleTexts,
				html: wellBeingDietArticleHTMLs
			},
			"III. Well Being_2_1 How exercise keeps our brain healthy": {
				metadata: wellBeingExerciseArticle,
				text: wellBeingExerciseArticleTexts
			},
			"III. Well Being_2_2 How to identify good stress vs bad stress and its effects on our brain": {
				metadata: wellBeingGoodBadStressArticle,
				text: wellBeingGoodBadStressArticleTexts
			},
			"III. Well Being_2_3 Ways for handling stress": {
				metadata: wellBeingHandlingStressArticle,
				text: wellBeingHandlingStressArticleTexts
			},
			"III. Well Being_2_4 How to get good quality rest: sleep": {
				metadata: wellBeingRestSleepArticle,
				text: wellBeingRestSleepArticleTexts
			},
			"III. Well Being_2_5 How to get good quality rest: breaks": {
				metadata: wellBeingRestBreakArticle,
				text: wellBeingRestBreakArticleTexts,
				html: wellBeingRestBreakArticleHTMLs
			},
			"IV. Learning Materials_3_0 Misconceptions about learning preferences": {
				metadata: learningMaterialsMisconceptionArticle,
				text: learningMaterialsMisconceptionArticleTexts
			},
			"IV. Learning Materials_3_1 How to set goals and evaluate your skills and knowledge to pick the right learning materials": {
				metadata: learningMaterialsGoalsEvaluationsArticle,
				text: learningMaterialsGoalsEvaluationsArticleTexts,
				html: learningMaterialsGoalsEvaluationsArticleHTMLs
			},
			"IV. Learning Materials_3_2 How to choose the right learning materials": {
				metadata: learningMaterialsLearningMaterialsArticle,
				text: learningMaterialsLearningMaterialsArticleTexts,
				html: learningMaterialsLearningMaterialsArticleHTMLs
			},
			"V. Physiology_4_0 How information travels in our brain: the declarative system (Part 1)": {
				metadata: physiologyDeclarativeSystemArticle,
				text: physiologyDeclarativeSystemArticleTexts,
				html: physiologyDeclarativeSystemArticleHTMLs
			},
			"V. Physiology_4_1 How information travels in our brain: the procedural system (Part 2)": {
				metadata: physiologyProceduralSystemArticle,
				text: physiologyProceduralSystemArticleTexts,
				html: physiologyProceduralSystemArticleHTMLs
			},
			"V. Physiology_4_2 How information travels in our brain: combining the two systems (Part 3)": {
				metadata: physiologySystemsCombinedArticle,
				text: physiologySystemsCombinedArticleTexts,
				html: physiologySystemsCombinedArticleHTMLs
			},
			"V. Physiology_4_3 How information gets stored in our brain and how we can do it more efficiently": {
				metadata: physiologyEfficientStorageArticle,
				text: physiologyEfficientStorageArticleTexts,
				html: physiologyEfficientStorageArticleHTMLs
			},
			"V. Physiology_4_4 How knowledge is acquired and refined (Part 1)": {
				metadata: physiologyAcquiringAndRefining1Article,
				text: physiologyAcquiringAndRefining1ArticleTexts,
				html: physiologyAcquiringAndRefining1ArticleHTMls
			}
		}
	}
}

export function getAllAvailableTopics() {
	let availableTopicsToDisplay = [];

	[GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK].forEach(workbookType => {
		const availableSubjects = availableTopics[workbookType] ? Object.keys(availableTopics[workbookType]) : [];
		availableSubjects.forEach(availableSubject => {
			for (const [key, value] of Object.entries(availableTopics[workbookType][availableSubject])) {
				// this check exists because we're using the same article for 'B' and 'C'
				if (!["I: Numbers and Ordering_B. How to Count to 100 using Numeral Patterns Skip Counting by 5s", "I: Numbers and Ordering_C. How to Count to 100 using Numeral Patterns Skip Counting by 2s"].includes(key)) {
					availableTopicsToDisplay.push(
						{
							[key]: value.metadata,
							workbook: workbookType,
							subject: availableSubject
						}
					);
				}
			}
		});
	});

	return availableTopicsToDisplay;
}

export function getTopic(workbook, subject, topicId) {
	return availableTopics[workbook][subject.toLowerCase()][topicId];
}

export function findTexts(workbook, subject, topicId) {
	return availableTopics[workbook][subject.toLowerCase()][topicId].text;
}

export function findHTMLs(workbook, subject, topicId) {
	return availableTopics[workbook][subject.toLowerCase()][topicId].html;
}

export function getNextTopic(workbook, subject, topicId) {
	const topicsInSubject = Object.keys(availableTopics[workbook][subject.toLowerCase()]);
	const topicIndex = topicsInSubject.indexOf(topicId);

	return topicIndex + 1 >= topicsInSubject.length ? null : topicsInSubject[topicIndex + 1];
}

export function getPreviousTopic(workbook, subject, topicId) {
	const topicsInSubject = Object.keys(availableTopics[workbook][subject.toLowerCase()]);
	const topicIndex = topicsInSubject.indexOf(topicId);

	return topicIndex - 1 < 0 ? null : topicsInSubject[topicIndex - 1];
}

// Reference:
// 1.) https://developers.google.com/search/docs/appearance/structured-data/article
export function generateTopicsInfoStructuredData(topicDetails) {
	return JSON.stringify(
		{
			"@context": "https://schema.org",
			"@type": "Article",
			"headline": topicDetails.title,
			"image": [
				topicDetails.thumbnail
			],
			"datePublished": topicDetails.datePublished,
			"dateModified": topicDetails.dateModified,
			"author": [{
					"@type": "Organization",
					"name": "Exer Institute",
					"url": "https://exerinstitute.com"
				}
			]
		}
	);
}