import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";

/**
 * @description spinner copied from https://loading.io/css/, 1st in 3rd row
 * @param {Object} spinnerContainerStyle? will be mainly used for spinner positioning
 * @param {String} containerClass? custom class for spinner container
 * @param {String} loadingText? optional text to show underneath the spinner
 * @param {String} durationText? additional custom text underneath loading text for specifying how long the spinner might last
 */
export default function Spinner(props) {
	const [loadingText, setLoadingText] = useState(null),
				[background, setBackground] = useState("spinner-background no-text"),
				[spinnerBlockStyle, setSpinnerBlockStyle] = useState();

	useEffect(() => {
		if (props.loadingText) {
			setLoadingText(
				<div className="loading-text-container">
					{props.loadingText}
				</div>
			);
			setBackground("spinner-background with-text");
			setSpinnerBlockStyle(
				{
					marginLeft: "27.5%"
				}
			);
		}
	}, []);

	return (
		<div className={`spinner ${props.containerClass}`} style={props.spinnerContainerStyle}>
			<div className={background}>
				<div className="spinner-block" style={spinnerBlockStyle}>
					<div className="spinner-container">
						<div className="lds-default">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				</div>

				{loadingText}
				{
					props.durationText ? 
					<div className="duration-text">{props.durationText}</div>
					: null
				}
			</div>
		</div>
	);
}

Spinner.defaultProps = {
	containerClass: ""
};

Spinner.propTypes = {
	spinnerContainerStyle: PropTypes.object,
	containerClass: PropTypes.string,
	loadingText: PropTypes.string,
	durationText: PropTypes.string
};