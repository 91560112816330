export function onKeyDown(e, callback, args, isInput = false) {
	if (callback && (["Enter", " "].includes(e.key) || [13, 32].includes(e.keyCode))) {
		if (isInput) {
			e.target.checked = !e.target.checked;
		}

		if (args) {
			callback(...args);
		}
		else {
			callback();
		}
	}
}

export function escape(e, callback, args) {
	if (callback && (["Escape"].includes(e.key) || [27].includes(e.keyCode))) {
		if (args) {
			callback(...args);
		}
		else {
			callback();
		}
	}
}
