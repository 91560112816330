import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/thumbnailShell.jpg";


export const countingTo100UsingNumeralPatternsArticleC = {
	id: "I: Numbers and Ordering_C. How to Count to 100 using Numeral Patterns Skip Counting by 2s",
	title: messageMap("countingTo100UsingNumeralPatterns.article.title", "math"),
	description: messageMap("countingTo100UsingNumeralPatterns.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("countingTo100UsingNumeralPatterns.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "html1"
	],
	datePublished: "2024-06-20",
	dateModified: "2024-06-20"
};