/* eslint-disable no-template-curly-in-string */
import request, {POST} from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";

const BASE = "/services/tutoring/record";

export const GET_CHAT_CHANNELS = BASE + "/get/channelList";
export const GET_INTERVIEW_COUNT = BASE + "/get/interviewCount";
const DELETE_CHAT_CHANNEL = BASE + "/delete/channel?channelId=${channelId}";


/**
 * @param {String} payload = ownerId
 */
export function getChatChannelsAPI(payload, callback) {
	request(GET_CHAT_CHANNELS, POST, payload, callback);
}

/**
 * @param {String} payload = ownerId
 */
export function getInterviewCountAPI(payload, callback) {
	request(GET_INTERVIEW_COUNT, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	channelId: String
 * }
 * @param {String} payload = ownerId
 */
export function deleteChatChannelAPI(pathVariables, payload, callback) {
	const api = $replace(DELETE_CHAT_CHANNEL, pathVariables);
	request(api, POST, payload, callback);
}


