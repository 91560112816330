import { getRandomInt } from "Utilities/Algos";

export function sortResponse(objectArr) {
	for (var i = 0; i < objectArr.length; ++i) {
		let ts = objectArr[i].timestamp;
		ts = ts.substr(0, ts.indexOf("."));
		objectArr[i].timestamp = new Date(ts).getTime();
	}

	objectArr.sort(function (x, y) {
		return y.timestamp - x.timestamp;
	});

	return objectArr;
}

export function recurseToFindNextTopic(parentTree, parentKey, tree, topicToSearch) {
	for (const [key, value] of Object.entries(tree.children)) {
		const valChildrenKeys = Object.keys(value.children);
		if (valChildrenKeys.includes(topicToSearch)) {
			// topic isn't at the end of the module
			if (valChildrenKeys.indexOf(topicToSearch) < valChildrenKeys.length - 1) {
				const nextTopic = valChildrenKeys[valChildrenKeys.indexOf(topicToSearch) + 1];
				return [nextTopic, value.children[nextTopic]];
			}
			// topic is at the end of the module, search next module or class
			else {
				const keyArray = Object.keys(tree.children);
				const curKeyIndexPos = keyArray.indexOf(key);

				const parentKeyArray = Object.keys(parentTree.children);
				const parentKeyIndexPos = parentKeyArray.indexOf(parentKey);

				// we're not at the last module -> get first topic of next module
				if (curKeyIndexPos < keyArray.length - 1) {
					const nextModule = tree.children[keyArray[curKeyIndexPos + 1]];
					const nextModuleChildren = Object.keys(nextModule.children);

					return [nextModuleChildren[0], nextModule.children[nextModuleChildren[0]]];
				}
				// we're at the last module -> go to next class & fetch first topic of first module
				else if (parentKeyIndexPos < parentKeyArray.length - 1) {
					const nextClass = parentTree.children[parentKeyArray[parentKeyIndexPos + 1]];
					const nextClassChildren = Object.keys(nextClass.children);
					const nextModule = nextClass.children[nextClassChildren[0]];
					const nextModuleChildren = Object.keys(nextModule.children);

					return [nextModuleChildren[0], nextModule.children[nextModuleChildren[0]]];
				}
				// we're at the last class at its last module. We actually never hit this.
				// We just return undefined when we reach the last topic of the last module of the last class
				else {
					return null;
				}
			}
		}
		else {
			const nextTopicFound = recurseToFindNextTopic(tree, key, value, topicToSearch);
			if (nextTopicFound && nextTopicFound.length) {
				return nextTopicFound;
			}
		}
	}
}

export function recurseToFindPreviousTopic(parentTree, parentKey, tree, topicToSearch) {
	for (const [key, value] of Object.entries(tree.children)) {
		const valChildrenKeys = Object.keys(value.children);
		if (valChildrenKeys.includes(topicToSearch)) {
			// topic isn't at the beginning of the module
			if (valChildrenKeys.indexOf(topicToSearch) > 0) {
				const prevTopic = valChildrenKeys[valChildrenKeys.indexOf(topicToSearch) - 1];
				return [prevTopic, value.children[prevTopic]];
			}
			// topic is at the beginning of the module, search previous module or class
			else {
				const keyArray = Object.keys(tree.children);
				const curKeyIndexPos = keyArray.indexOf(key);

				const parentKeyArray = Object.keys(parentTree.children);
				const parentKeyIndexPos = parentKeyArray.indexOf(parentKey);

				// we're not at the last module -> get first topic of next module
				if (curKeyIndexPos > 0) {
					const previousModule = tree.children[keyArray[curKeyIndexPos - 1]];
					const previousModuleChildren = Object.keys(previousModule.children);
					const prevTopic = previousModuleChildren.slice(-1)[0];

					return [prevTopic, previousModule.children[prevTopic]];
				}
				// we're at the last module -> go to next class & fetch first topic of first module
				else if (parentKeyIndexPos > 0) {
					const previousClass = parentTree.children[parentKeyArray[parentKeyIndexPos - 1]];
					const previousClassChildren = Object.keys(previousClass.children);
					const previousModule = previousClass.children[previousClassChildren[0]];
					const previousModuleChildren = Object.keys(previousModule.children);
					const prevTopic = previousModuleChildren.slice(-1)[0];

					return [prevTopic, previousModule.children[prevTopic]];
				}
				// we're at the last class at its last module. We actually never hit this.
				// We just return undefined when we reach the last topic of the last module of the last class
				else {
					return null;
				}
			}
		}
		else {
			const previousTopicFound = recurseToFindPreviousTopic(tree, key, value, topicToSearch);
			if (previousTopicFound && previousTopicFound.length) {
				return previousTopicFound;
			}
		}
	}
}

export function randomizeColorPerUser(objectArr) {
	const colorChoices = ["#92a8d1", "#034f84", "#f7cac9", "#f7786b", "#b1cbbb", "#eea29a", "#c94c4c"];
	let userToColorMap = {};

	for (var i = 0; i < objectArr.length; ++i) {
		if (!(objectArr[i].name in userToColorMap)) {
			userToColorMap[objectArr[i].name] = colorChoices[getRandomInt(0, 8)];
		}

		objectArr[i].color = userToColorMap[objectArr[i].name];
	}

	return objectArr;
}

// Reference:
// 1.) https://developers.google.com/search/docs/appearance/structured-data/learning-video
// 2.) https://developers.google.com/search/docs/appearance/structured-data/video#video-object
export function generateLearningVideoStructuredData(apiResponse, staticStructuredData) {
	const videoHLSLink = apiResponse[0].data;
	const videoMetadata = apiResponse[1].data;
	const duration = videoMetadata.duration.split(":");

	let structuredData = {};
	staticStructuredData.split("|").forEach(data => {
		if (data.includes("learningResourceType")) {
			if (structuredData["learningResourceType"] == null) {
				structuredData["learningResourceType"] = [];
			}
			structuredData["learningResourceType"].push(data.split("_")[1]);
		}
		else {
			structuredData["educationalLevel"] = data.split("_")[1];
		}
	});

	return JSON.stringify(
		{
			"@context": "https://schema.org",
			"@type": ["VideoObject", "LearningResource"],
			"name": videoMetadata.title,
			"description": videoMetadata.description,
			"educationalLevel": structuredData["educationalLevel"],
			"learningResourceType": structuredData["learningResourceType"],
			"contentUrl": videoHLSLink.hls,
			"thumbnailUrl": [
				videoMetadata.thumbnailUploadLocation
			],
			"uploadDate": new Date(videoMetadata.uploadDate * 1000),
			"duration": "PT0H" + duration[0] + "M" + duration[1] + "S"
		}
	);
}