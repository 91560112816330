import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";

import Alert, {SUCCESS} from "templates/Alert";
import Modal from "templates/Modal";

import {listAPI} from "apis/controllers/ClassesController";
import {listAPI as subjectListAPI} from "apis/controllers/SubjectsController";

import messageMap from "Utilities/MessageMaps";
import {makeCards} from "pages/Classes/ClassListUtilities";

import searchAsset from "assets/icons/class/list/search.svg";

// TODO: use messageMap keys when we start using this page again
export default function ClassList(props) {
	const [categories, setCategories] = useState([]),
				[subjectList, setSubjectList] = useState([]),
				[classListModal, setClassListModal] = useState(null),
				[alert, setAlert] = useState(null);
	const history = useHistory();

	useEffect(() => {
		// TODO: uncomment 'getSubjectList' and remove 'getClassList' once we support more classes
		// The idea is to open a modal containing a list of classes, when a user chooses a subject type
		// getSubjectList();
		getClassList();
		createCategories();
	}, []);

	function getSubjectList() {
		subjectListAPI(response => {
			setSubjectList(
				makeCards(response, getClassList, "subjectList")
			);
		});
	}

	function createCategories() {
		let categories = [];

		["Career", "Major", "Subject", "Skill"].forEach(e => {
			categories.push(
				<button key={e} className="category">
					{e}
				</button>
			);
		});

		setCategories(categories);
	}

	function getClassList(category) {
		// TODO: remove category line once we support more classes
		// category = "Education";

		listAPI(null, response => {
			const closeButtonStyle = {
				"marginTop": "-15px",
				"marginRight": "-20px"
			},
			titleStyle = {
				"fontSize": "1.5rem",
				"color": "#ffbe5b"
			},
			modalContainerStyle = {
				"backgroundColor": "#412B69",
				"backgroundImage": "linear-gradient(transparent, #39265E)"
			},
			descriptionStyle = {
				"color": "#ffbe5b"
			};
			// TODO: might need to be re-designed
			// const modal = ( 
			// 	<Modal closeHandler={() => setClassListModal(null)} closeType={"xButton"} title={"Choose Your Class"} 
			// 					titleStyle={titleStyle} closeButtonStyle={closeButtonStyle} modalContainerStyle={modalContainerStyle}>
			// 					{makeCards(response, goToSubjectOverviewPage, "classList", descriptionStyle)}
			// 	</Modal>
			// );

			// setClassListModal(modal);

			setSubjectList(
				makeCards(response, goToSubjectOverviewPage, "classList")
			);
		});
	}

	function goToSubjectOverviewPage(path, id) {
		window.scrollTo(0, 0);
		history.push({
			pathname: path,
			state: {
				classId: id
			}
		});
	}

	return (
		<div className="class-list">
			<div className="list-container">
				{/* TODO: uncomment all once we get more classes */}
				{/* replace className with 'title', once we support more classes */}
				<div className="temp-title">
					{/* What Do You Want To Learn Today? */}
					Take Charge of your Learning Today
				</div>

				{/* <div className="search">
					<img src={searchAsset} 
							alt={messageMap("search", "image")}></img>
					<input type="text" placeholder={messageMap("search", "labelPlaceholder")}></input>
				</div>

				<div className="categories">
					<div className="title">
						Categories
					</div>
					<div className="options">
						{categories}
					</div>
				</div> */}

				<hr />

				<div className="subject-list">
					{subjectList}
				</div>

			</div>

			{classListModal}

			{alert}
		</div>
	);
}