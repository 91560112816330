import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

import {connect} from "react-redux";
import store from "redux/store";
import {logInUser, setLogInTimeoutStartTime, clearLogInTimeout,
				incrementLoginAttemptCount, clearLoginAttemptCount, setIPLocked} from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";
import {LOG_IN_TIMEOUT} from "redux/actions/accountConstants";

import messageMap from "Utilities/MessageMaps";
import { getTimezoneOffset } from "templates/utilities/CalendarUtilities";

import {modalSchema} from "templates/schemas/navBarSchemas";

import Modal from "templates/Modal";

import {loginAPI} from "apis/controllers/person/AccountsController";
import {unblockIpAPI, blockIpPostAPI} from "apis/controllers/IPsController";


/**
 * @description custom modal for Logging in
 * @param {String} title? custom login title
 * @param {Function} closeHandler close handler for modal
 * @param {Function} submitHandler? for a custom submit handler
 */
function LoginModal(props) {
	const globalStoreState = store.getState();
	const storedTimedOutStartTime = Number(globalStoreState.account.timedOutStartTime);

	const [loginAttemptCount, setLoginAttemptCount] = useState(globalStoreState.generic.loginAttemptCount),
				[modal, setModal] = useState(null);

	const title = props.title != null ? props.title : messageMap("account.login.header", "generic");
	const loginModalSchema = modalSchema(props.closeHandler, null, null)["login"];
	const history = useHistory();

	useEffect(() => {
		if (loginAttemptCount >= 3 || storedTimedOutStartTime || props.ipLocked) {
			checkForTimeout();

			setModal(
				<Modal modalStyle={{"zIndex": "11"}} title={title} stateProps={loginModalSchema.stateProps} inputs={loginModalSchema.inputs}
							closeHandler={e => props.closeHandler(e)} disableForms={true} 
							disabledMsg={messageMap("account.login.attempts", "generic")}/>
			);
		}
		else {
			setModal(
				<Modal modalStyle={{"zIndex": "11"}} title={title} stateProps={loginModalSchema.stateProps} inputs={loginModalSchema.inputs}
							closeHandler={e => props.closeHandler(e)} submitHandler={logIn}/>
			);
		}
	}, [loginAttemptCount]);

	function checkForTimeout() {
		const currentTimestamp = Date.now();

		if (storedTimedOutStartTime === 0 || (storedTimedOutStartTime + LOG_IN_TIMEOUT > currentTimestamp)) {
			let newTimeoutDuration = LOG_IN_TIMEOUT;
			if (storedTimedOutStartTime + LOG_IN_TIMEOUT > currentTimestamp) {
				newTimeoutDuration = LOG_IN_TIMEOUT - (currentTimestamp - storedTimedOutStartTime);
			}

			props.setIPLocked(true);
			blockIpPostAPI({ip: props.ip});
			props.setLogInTimeoutStartTime(Date.now(), newTimeoutDuration, () => {
				unblockLoginInput();
			});
		}
		else if (storedTimedOutStartTime + LOG_IN_TIMEOUT < currentTimestamp) {
			unblockLoginInput();
		}
	}

	function unblockLoginInput() {
		props.clearLogInTimeout();
		props.clearLoginAttemptCount();
		unblockIpAPI({ip: props.ip});
		props.setIPLocked(false);
	}

	function pushHistoryTo(url) {
		window.scrollTo(0, 0);
		history.push(url);
	}

	function logIn(loginStates, statusHandler, inputDisabler) {
		const payload = {
			username: loginStates.loginUser.value,
			password: loginStates.loginPass.value,
			timezone: getTimezoneOffset()
		};
		const credentials = {
			username: loginStates.loginUser.value,
			password: loginStates.loginPass.value
		};

		if (payload.username != null && payload.password != null) {
			loginAPI(payload, credentials, (response) => {
				if (!response) {
					let msg;

					if (loginAttemptCount < 3) {
						msg = (
							<div className="error-msg">
								{messageMap("account.usernamePassword", "validation")}
							</div>
						);

						props.incrementLoginAttemptCount();
						setLoginAttemptCount(loginAttemptCount + 1);
					}
	
					statusHandler(msg);
				}
				else {
					props.logInUser(response);
					if (history.location.pathname === "/") {
						window.scrollTo(0, 0);
						pushHistoryTo("/video-list");
					}

					props.submitHandler(null, "loginSuccess");
					window.location.reload();
				}
			},
			errorResponse => {
				setLoginAttemptCount(loginAttemptCount + 1);
				statusHandler((
					<div className="error-msg">
						{messageMap("account.usernamePassword", "validation")}
					</div>
				));
			});
		}
	}

	return (
		<div>
			{modal}
		</div>
	);
}

LoginModal.propTypes = {
	title: PropTypes.string,
	closeHandler: PropTypes.func.isRequired,
	submitHandler: PropTypes.func,

	// Redux props
	logInUser: PropTypes.func.isRequired,
	setLogInTimeoutStartTime: PropTypes.func.isRequired,
	clearLogInTimeout: PropTypes.func.isRequired,
	incrementLoginAttemptCount: PropTypes.func.isRequired,
	clearLoginAttemptCount: PropTypes.func.isRequired,
	setIPLocked: PropTypes.func.isRequired,
	ip: PropTypes.string,
	ipLocked: PropTypes.any
};

export default connect(
	account,
	{logInUser, setLogInTimeoutStartTime, clearLogInTimeout, incrementLoginAttemptCount, clearLoginAttemptCount, setIPLocked}
)(LoginModal);