/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";


const BASE = "/services/tutoring/review";

const CREATE_REVIEW = BASE + "/create";
export const GET_REVIEWS = BASE + "/get?ownerId=${ownerId}&reviewFromWhichUserType=${reviewFromWhichUserType}";
export const GET_REVIEWS_BY_OWNER_ID = BASE + "/get/list?ownerIds=${ownerIds}";


/**
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	sessionId: String,
 * 	rating: Number,
 * 	reviewComment: String,
 * 	reviewFromWhichUserType: String
 * }
 */
export function createReviewAPI(payload, callback) {
	request(CREATE_REVIEW, POST, payload, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	ownerId: String
 * }
 */
export function getReviewsAPI(pathVariables, callback) {
	const api = $replace(GET_REVIEWS, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	ownerIds: [String]
 * }
 */
export function getReviewsByOwnerIdAPI(pathVariables, callback) {
	const api = $replace(GET_REVIEWS_BY_OWNER_ID, pathVariables);
	request(api, GET, null, callback);
}


