import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/wellBeing/thumbnailExercise.jpg";


export const wellBeingExerciseArticle = {
	id: "III. Well Being_2_1 How exercise keeps our brain healthy",
	title: messageMap("exercise.article.title", "learningOptimization"),
	description: messageMap("exercise.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("exercise.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3",
		"text4", "text5", "text6", "text7",
		"text8", "text9", "text10", "text11", "text12", "text13", "text14", "text15",
		"text16", "text17",
		"text18", "text19", "text20", "text21"
	],
	datePublished: "2022-03-06",
	dateModified: "2024-06-23"
};

export const wellBeingExerciseArticleTexts = {
	text1: {
		text: messageMap("exercise.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("exercise.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("exercise.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("exercise.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("exercise.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("exercise.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("exercise.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("exercise.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text9: {
		text: messageMap("exercise.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("exercise.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("exercise.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("exercise.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("exercise.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("exercise.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("exercise.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("exercise.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text17: {
		text: messageMap("exercise.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text19: {
		text: messageMap("exercise.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text20: {
		text: messageMap("exercise.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text21: {
		text: messageMap("exercise.sources.s3", "learningOptimization"),
		type: SOURCE
	}
};

