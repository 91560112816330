import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";
import Alert, {ERROR} from "templates/Alert";
import Modal from "templates/Modal";
import Tooltip from "templates/Tooltip";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import {numberValidator} from "Utilities/Validators/InputValidators";
import {topicsInSubjects} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import closeAsset from "assets/icons/common/close.svg";


function TeachingRates(props) {

	let hasActiveTutoringSessions = props.hasActiveTutoringSessions;

	const [ratesPreference, setRatesPreference] = useState(props.teachingRates ? props.teachingRates : {}),
		[cancellableTopicRates, setCancellableTopicRates] = useState();

	const [blockedEditingTooltip, setBlockedEditingTooltip] = useState();

	const flatRateRef = useRef(),
		flatRate1 = useRef(),
		flatRate2 = useRef(),
		variableRateRef = useRef(),
		variableWholeNumberRef = useRef(0),
		variableDecimalNumberRef = useRef(0);

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.teachingRates && Object.keys(props.teachingRates).length) {
			if (props.teachingRates["flatRate"] != null) {
				flatRateRef.current.checked = true;

				const priceBreakdown = String(props.teachingRates["flatRate"]).split(".");
				flatRate1.current.value = priceBreakdown[0];
				flatRate2.current.value = priceBreakdown[1];
			}
			else {
				variableRateRef.current.checked = true;

				setCancellableTopicRates(createCancellableTopicRates(props.teachingRates));
				setRatesPreference(props.teachingRates);
			}
		}
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			variableWholeNumberRef.current = 0;
			variableDecimalNumberRef.current = 0;
			hideModal();
		}
	}
	function hideModal() {
		setTimeout(() => {
			props.setModal(null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function setRatePrice(e, teachableTopics) {
		const target = e.target;

		if (hasActiveTutoringSessions) {
			target.value = "";

			if (target.type === "radio") {
				target.checked = false;
			}
		}
		else {
			const rateableTopic = target.getAttribute("rateabletopic");

			if (["flatRateInput", "flatRate1", "flatRate2"].includes(rateableTopic)) {
				if (["flatRate1", "flatRate2"].includes(rateableTopic)) {
					const inputValue = target.value;
	
					if (!numberValidator(inputValue)) {
						flatRateRef.current.checked = false;
	
						props.setAlert(
							<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.notNumber", "validation")} />
						);
						e.target.value = "";
					}
					else if (rateableTopic === "flatRate1" && inputValue > 999) {
						flatRateRef.current.checked = false;
	
						props.setAlert(
							<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.price.tooHighWhole", "validation")} />
						);
						e.target.value = 999;
					}
					else if (rateableTopic === "flatRate2" && inputValue > 99) {
						flatRateRef.current.checked = false;
	
						props.setAlert(
							<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.price.tooHighDecimal", "validation")} />
						);
						e.target.value = 99;
					}
					else {
						flatRateRef.current.checked = true;
						variableRateRef.current.checked = false;
						setCancellableTopicRates(null);
	
						let updatedRates = ratesPreference;
	
						if (rateableTopic === "flatRate1") {
							updatedRates["flatRate"] = inputValue;
						}
						else if (rateableTopic === "flatRate2") {
							updatedRates["flatRate"] = Number(`${Math.round(updatedRates["flatRate"])}.${inputValue}`);
						}
						setRatesPreference(updatedRates);
						props.setTeachingRates(updatedRates);
					}
				}
				else if ("flatRateInput" === rateableTopic) {
					variableRateRef.current.checked = false;
					setCancellableTopicRates(null);
				}
			}
			else {
				flatRateRef.current.checked = false;
	
				let updatedRates = ratesPreference;
				delete updatedRates["flatRate"];
				flatRate1.current.value = "";
				flatRate2.current.value = "";
	
				setRatesPreference(updatedRates);
				props.setTeachingRates(updatedRates);
	
				if (teachableTopics != null) {
					flatRateRef.current.checked = false;
					variableRateRef.current.checked = true;
	
					props.setModal(
						<Modal closeType="xButton" closeHandler={closeModal} submitHandler={e => setVariableTopicPriceRate(rateableTopic)}
									modalClass="become-tutor-modal"
									title={`${messageMap("profilePage.tutoring.modal.price.setPriceTitle", "generic")} ${teachableTopics[rateableTopic]}`}
									submitText={messageMap("tutoring.modal.setPriceButton", "button")}>
							<div className="multi-dom-line">
								<input className="page-field-value" type="number"
									onChange={e => updateVariableTopicPriceRate(e, "whole")}>
								</input>
								<div className="decimal-point">.</div>
								<input className="page-field-value" type="number"
									onChange={e => updateVariableTopicPriceRate(e, "decimal")}>
								</input>
							</div>
						</Modal>
					);
				}
			}
		}
	}
	
	function updateVariableTopicPriceRate(e, placement) {
		const inputValue = e.target.value;

		if (!numberValidator(inputValue)) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.notNumber", "validation")} />
			);
			e.target.value = "";
		}
		else if (placement === "whole" && inputValue > 999) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.price.tooHighWhole", "validation")} />
			);

			variableWholeNumberRef.current = 999;
			e.target.value = 999;
		}
		else if (placement === "decimal" && inputValue > 99) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.price.tooHighDecimal", "validation")} />
			);

			variableDecimalNumberRef.current = 99;
			e.target.value = 99;
		}
		else {
			if (placement === "whole") {
				variableWholeNumberRef.current = inputValue;
			}
			else if (placement === "decimal") {
				variableDecimalNumberRef.current = inputValue;
			}
		}
	}

	function setVariableTopicPriceRate(rateableTopic) {
		let newVariableTopicPrice = ratesPreference;
		newVariableTopicPrice[[rateableTopic]] = Number(`${variableWholeNumberRef.current}.${variableDecimalNumberRef.current}`);

		setCancellableTopicRates(createCancellableTopicRates(newVariableTopicPrice));
		setRatesPreference(newVariableTopicPrice);
		props.setTeachingRates(newVariableTopicPrice);
		hideModal();
	}

	function removeCancellableTopicRate(topicToCancel) {
		if (!hasActiveTutoringSessions) {
			let updatedRates = ratesPreference;
			delete updatedRates[topicToCancel];
	
			setRatesPreference(updatedRates);
			props.setTeachingRates(updatedRates);
			setCancellableTopicRates(createCancellableTopicRates(updatedRates));
		}
	}

	function createCancellableTopicRates(topicEntries) {
		let cancellableDivs = [];
		for (const [key, value] of Object.entries(topicEntries)) {
			let count = 0;
			// although count is being used as a protection against an infinite loop, this also limits the number
			// of subjects tutors can qualify for
			while (topicsInSubjects[props.subjectsToTeachRef[count]][key] == null && count < 25) {
				++count;
			}
			const topicLabel = topicsInSubjects[props.subjectsToTeachRef[count]][key];

			cancellableDivs.push(
				<div key={key} className="rated-topics-container">
					<div className="rated-topic">
						{`${topicLabel}:`}
					</div>
					<div className="topic-rate">
						{`${"$"}${value.toFixed(2)}/hr`}
					</div>
					<button className={`remove-rated-topic ${hasActiveTutoringSessions && "not-allowed"}`}
						onClick={e => removeCancellableTopicRate(key)}
						onMouseOver={e => toggleBlockedRateEditingTooltip("show")} onFocus={e => toggleBlockedRateEditingTooltip("show")}
						onMouseLeave={e => toggleBlockedRateEditingTooltip("hide")} onBlur={e => toggleBlockedRateEditingTooltip("hide")}>
						<img className="x-icon" src={closeAsset} alt="ex icon"></img>
					</button>
				</div>
			);
		}

		return cancellableDivs;
	}

	function toggleBlockedRateEditingTooltip(showOrHide) {
		if (hasActiveTutoringSessions) {
			if (showOrHide === "show") {
				setBlockedEditingTooltip(
					<Tooltip classStr="tooltip-bottom-left blocked-rate-editing-tooltip" subheader={messageMap("tutor.blockedRateEditing", "tooltip")} />
				);
			}
			else {
				setBlockedEditingTooltip(null);
			}
		}
	}

	return (
		<div className="page-field multi-lines">
			<label className="page-field-label variable-rate-info">
				<div className="div-label">
					{messageMap("profilePage.tutoring.modal.price.label", "generic")}
				</div>
				*
				{blockedEditingTooltip}
			</label>
			<div className="inline-elements flex-wrap-max-width multi-lines">
				<div className="multi-input-radios">
					<input className={`page-field-value radio-input inline-inputs ${hasActiveTutoringSessions && "not-allowed"}`}
						type="radio"
						ref={flatRateRef} rateabletopic="flatRateInput"
						onClick={setRatePrice} onKeyDown={e => onKeyDown(e, setRatePrice, [e], true)}
						onMouseOver={e => toggleBlockedRateEditingTooltip("show")} onFocus={e => toggleBlockedRateEditingTooltip("show")}
						onMouseLeave={e => toggleBlockedRateEditingTooltip("hide")} onBlur={e => toggleBlockedRateEditingTooltip("hide")}>
					</input>
					<input id="flatRate" type="number"
						className={`page-field-value inline-inputs no-right-margin flat-rates ${hasActiveTutoringSessions && "not-allowed"}`}
						rateabletopic="flatRate1" ref={flatRate1}
						onChange={setRatePrice}
						onMouseOver={e => toggleBlockedRateEditingTooltip("show")} onFocus={e => toggleBlockedRateEditingTooltip("show")}
						onMouseLeave={e => toggleBlockedRateEditingTooltip("hide")} onBlur={e => toggleBlockedRateEditingTooltip("hide")}>
					</input>
					<div className="decimal-point">.</div>
					<input id="flatRate" type="number"
						className={`page-field-value inline-inputs flat-rates ${hasActiveTutoringSessions && "not-allowed"}`}
						rateabletopic="flatRate2" ref={flatRate2}
						onChange={setRatePrice}
						onMouseOver={e => toggleBlockedRateEditingTooltip("show")} onFocus={e => toggleBlockedRateEditingTooltip("show")}
						onMouseLeave={e => toggleBlockedRateEditingTooltip("hide")} onBlur={e => toggleBlockedRateEditingTooltip("hide")}>
					</input>
					<label className="page-field-label tutor-rates">
						{messageMap("tutoring.profile.flatRate", "labelPlaceholder")}
					</label>
				</div>

				<div className="multi-line-inlines">
					<div className="multi-input-radios">
						<input id="variableRateRadio" type="radio"
							className={`page-field-value radio-input inline-inputs ${hasActiveTutoringSessions && "not-allowed"}`}
							ref={variableRateRef} rateabletopic="variableRateInput"
							onClick={setRatePrice} onKeyDown={e => onKeyDown(e, setRatePrice, [e], true)}
							onMouseOver={e => toggleBlockedRateEditingTooltip("show")} onFocus={e => toggleBlockedRateEditingTooltip("show")}
							onMouseLeave={e => toggleBlockedRateEditingTooltip("hide")} onBlur={e => toggleBlockedRateEditingTooltip("hide")}>
						</input>
						<Dropdown dropdownItemClickHandler={e => setRatePrice(e, props.teachableTopics)}
							customContainerClass={`tutoring-application ${hasActiveTutoringSessions && "not-allowed"}`}
							dropdownOptions={props.teachableTopics} customDropdownItemAttribute="rateabletopic"/>
						<label htmlFor="variableRateRadio" className="page-field-label">
							{messageMap("tutoring.profile.variableRate", "labelPlaceholder")}
						</label>
					</div>
					<div className="variable-rate-info tutor-rates">
						{messageMap("profilePage.tutoring.modal.price.variablePriceInfo", "generic")}
					</div>
					<div>
						{cancellableTopicRates}
					</div>
				</div>
			</div>
		</div>
	);
}

TeachingRates.defaultProps = {
	hasActiveTutoringSessions: false
};

TeachingRates.propTypes = {
	// parent handlers
	setModal: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,

	teachingRates: PropTypes.object,
	teachableTopics: PropTypes.object.isRequired,
	subjectsToTeachRef: PropTypes.array.isRequired,
	setTeachingRates: PropTypes.func.isRequired,
	hasActiveTutoringSessions: PropTypes.bool.isRequired
};

export default TeachingRates;

