import React from "react";
import { useHistory } from "react-router-dom";

import messageMap from "Utilities/MessageMaps";


function SubscriptionSubPage(props) {
	const history = useHistory();

	function redirectToSubscriptionPage() {
		history.push("/subscription");
	}

	return (
		<div>
			<h1 className="page-title">
				{messageMap("profile.subscription", "subPageNavigation")}
			</h1>

			<div className="page-field">
				<div className="inline-elements">
					<div className="subscription-plan-label">
						{messageMap("profilePage.subscription.fields.subscriptionPlan", "generic")}
					</div>
					<button className={"page-field update-field"}
						onClick={redirectToSubscriptionPage}>
						{messageMap("learnMore.text", "button")}
					</button>
				</div>

			</div>
		</div>
	);
}

export default SubscriptionSubPage;

