/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";

const BASE = "/services/person/paymentInfo";

export const GET_USER_PAYMENT_INFO = BASE + "/get/user/paymentInfo";
const GET_USER_CHARGEABLE_PAYMENT_METHOD = BASE + "/get/user/chargeablePaymentMethod";
export const GET_CLIENT_SECRET_TO_SAVE_USER_CHARGEABLE_PAYMENT_METHOD = BASE + "/get/chargeablePaymentMethod/clientSecret";
const SAVE_USER_CHARGEABLE_PAYMENT_METHOD = BASE + "/save/chargeablePaymentMethod?method=${method}";



/**
 * @param {String} payload = ownerId
 */
export function getUserPaymentInfoAPI(payload, callback) {
	request(GET_USER_PAYMENT_INFO, POST, payload, callback);
}

/**
 * @param {String} payload = ownerId
 */
export function getUserChargeablePaymentMethodAPI(payload, callback) {
	request(GET_USER_CHARGEABLE_PAYMENT_METHOD, POST, payload, callback);
}

/**
 * @param {Object} payload = {
 * 	ownerId: String,
 * 	paymentIntentId: String
 * }
 */
export function getClientSecretToSaveUserChargeablePaymentMethodAPI(payload, callback) {
	request(GET_CLIENT_SECRET_TO_SAVE_USER_CHARGEABLE_PAYMENT_METHOD, POST, payload, callback);
}

/**
 * @description payment method actually only gets saved if it's chargeable off-session, meaning outside of a checkout session
 * @param {Object} pathVariables = {
 * 	method: String
 * }
 * @param {Object} payload = {
 * 	ownerId: String
 * 	setupIntentId: String
 * }
 */
export function saveUserChargeablePaymentMethodAPI(pathVariables, payload, callback) {
	const api = $replace(SAVE_USER_CHARGEABLE_PAYMENT_METHOD, pathVariables);
	request(api, POST, payload, callback);
}

