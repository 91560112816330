/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";

const BASE = "/services/api";

const GET_WOLFRAM_ALPHA_STEP_BY_STEP_ANSWER = BASE + "/get/wolframalpha/stepByStep/answer?input=${input}";

const GET_CALENDLY_TOKEN = BASE + "/get/calendly/token";
const SEND_CALENDLY_SCHEDULE_EMAIL = BASE + "/send/calendly/scheduleEmail";

export const GET_PUBNUB_KEYSETS = BASE + "/get/pubNub/keys";

export const GET_STRIPE_PUB_KEY = BASE + "/get/stripe/pubKey";


/**
 * @param {String} payload = ownerId
 * @param {Object} pathVariables = {
 * 	input: String
 * }
 */
export function getWolframAlphaStepByStepAnswerAPI(payload, pathVariables, callback) {
	const api = $replace(GET_WOLFRAM_ALPHA_STEP_BY_STEP_ANSWER, pathVariables);
	request(api, POST, payload, callback);
}

export function getCalendlyTokenAPI(callback) {
	request(GET_CALENDLY_TOKEN, GET, null, callback);
}

/**
 * @param {CalendlyVo} payload = {
 * 	ownerId: String,
 * 	startTime: String,
 * 	endTime: String,
 * 	meetingUrl: String,
 * 	meetingPassword: String,
 * 	calendlyUri: String
 * }
 */
export function sendCalendlyNotificationToSupportAPI(payload, callback) {
	request(SEND_CALENDLY_SCHEDULE_EMAIL, POST, payload, callback);
}

export function getPubNubKeysetAPI(callback) {
	request(GET_PUBNUB_KEYSETS, GET, null, callback);
}


export function getStripePubKeyAPI(callback) {
	request(GET_STRIPE_PUB_KEY, GET, null, callback);
}