import React, {useEffect, useState, useRef} from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logInUser } from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";

import Spinner from "templates/Spinner";
import Alert, { ERROR, INFORMATION } from "templates/Alert";
import Tooltip from "templates/Tooltip";
import Dropdown from "templates/Dropdown";
import Modal from "templates/Modal";
import ConfirmModal from "templates/customModals/ConfirmModal";
import EditOneFieldValueModalContent from "./components/accountComponents/EditOneFieldValueModalContent";
import EditTwoFieldValuesModalContent from "./components/accountComponents/EditTwoFieldValuesModalContent";
import EditUsernameModalContent from "./components/accountComponents/EditUsernameModalContent";
import EditPasswordModalContent from "./components/accountComponents/EditPasswordModalContent";
import EditEmailModalContent from "./components/accountComponents/EditEmailModalContent";
import EditAddressModalContent from "./components/accountComponents/EditAddressModalContent";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import { getUserBrowser, getUserPlatform } from "Utilities/UserMetadata";
import {imageValidator} from "Utilities/Validators/InputValidators";
import { 
	AUTH_TOKEN, OWNER_ID,
	USERNAME, PASSWORD, 
	PROFILE_PICTURE, FIRST_NAME, LAST_NAME, FIRST_NAME_KANA, LAST_NAME_KANA,
	FIRST_NAME_KANJI, LAST_NAME_KANJI, DATE_OF_BIRTH,
	EMAIL, PHONE_NUMBER, 
	STREET_ADDRESS_1, STREET_ADDRESS_2, POSTAL_CODE, POSTAL_CODE_KANA, POSTAL_CODE_KANJI,
	CITY, STATE, COUNTRY,
	IP, BROWSER, PLATFORM
} from "Utilities/Constants/MediaConstants";
import { fullCountriesList, availableCountries, countriesToAlphaCode } from "./utilities/TutoringSubPageConstants";
import { 
	SAVE_RELOAD_TIMEOUT, ALERT_THIRTY_SECONDS_TIMEOUT, MODAL_CLOSE_TIMEOUT
} from "Utilities/Constants/TimeoutConstants";
import { REQUIRED_FIELDS_KEY_LABEL_MAP } from "./utilities/AccountUtility";

import { saveAccountSettingsAPI } from "apis/controllers/person/AccountsController";
import { getIPAPI } from "apis/controllers/IPsController";

import labelInfoAsset from "assets/icons/common/labelInfo.svg";
import blankProfilePhotoAsset from "assets/icons/common/blank-profile-photo.svg";
import editFieldAsset from "assets/icons/common/editField.svg";
import { getFileFormatFromTargetFile } from "Utilities/FileUtility";


function AccountsSubPage(props) {

	const history = useHistory();

	// metadata loader
	const [metaContainerState, setMetaContainerState] = useState(),
		[newProfilePicture, setNewProfilePicture] = useState(props.profilePictureUploadLocation || blankProfilePhotoAsset),
		[profilePicFile, setProfilePicFile] = useState(props.profilePictureUploadLocation || blankProfilePhotoAsset),
		[validProfilePhoto, setValidProfilePhoto] = useState(true);
	/******************* Login *******************/
	const [username, setUsername] = useState(props.acctUsername),
		[password, setPassword] = useState("password");
	/********** Personal Information *****************/
	const [firstName, setFirstName] = useState(props.firstName),
		[lastName, setLastName] = useState(props.lastName),
		[firstNameKana, setFirstNameKana] = useState(props.firstNameKana ? props.firstNameKana : ""),
		[lastNameKana, setLastNameKana] = useState(props.lastNameKana ? props.lastNameKana : ""),
		[firstNameKanji, setFirstNameKanji] = useState(props.firstNameKanji ? props.firstNameKanji : ""),
		[lastNameKanji, setLastNameKanji] = useState(props.lastNameKanji ? props.lastNameKanji : ""),
		[dateOfBirth, setDateOfBirth] = useState(props.dob ? props.dob : "");
	/*********** Contact Information ***********/
	const [emailAddress, setEmailAddress] = useState(props.emailAddress),
		[phone, setPhone] = useState(props.phone ? props.phone : "");
	/*********** Location *************/
	const [streetAddress1, setStreetAddress1] = useState(props.streetAddress1),
		[streetAddress2, setStreetAddress2] = useState(props.streetAddress2),
		[postalCode, setPostalCode] = useState(props.postalCode),
		[postalCodeKana, setPostalCodeKana] = useState(props.postalCodeKana),
		[postalCodeKanji, setPostalCodeKanji] = useState(props.postalCodeKanji),
		[city, setCity] = useState(props.city),
		[state, setState] = useState(props.state),
		[country, setCountry] = useState(props.country);

	const [addressTooltip, setAddressTooltip] = useState(),
		[countryTooltip, setCountryTooltip] = useState();
	const [countryRowVisibility, setCountryRowVisibility] = useState("hide");
	const [spinner, setSpinner] = useState("");

	const updateAllButtonRef = useRef();

	const hasACountry = props.country != null;

	const nameObj = {
		label: "name",
		value: `${firstName || ""} ${lastName || ""}`,
		requirementName: "firstLastName",
		handler: showTwoFieldEditModal,
		modalData: {
			titleKey: "editName",
			key1: "firstName",
			value1: firstName,
			key2: "lastName",
			value2: lastName,
			newLabelKey1: "newFirstName",
			newLabelKey2: "newLastName",
			updateKey: "changeName"
		}
	};
	const dateObj = {
		label: "dob",
		value: dateOfBirth,
		requirementName: "dateOfBirth",
		handler: showOneFieldEditModal,
		modalData: {
			titleKey: "editDob",
			key1: "dob",
			value1: dateOfBirth,
			inputType: "date",
			newLabelKey1: "newDob",
			updateKey: "changeDob"
		}
	};
	const defaultPersonalInformationMap = {
		name: nameObj
	}
	const personalInformationMap = {
		name: nameObj,
		dob: dateObj
	};
	const japanPersonalInformationMap = {
		nameKana: {
			label: "nameKana",
			value: `${firstNameKana} ${lastNameKana}`,
			requirementName: "firstLastNameKana",
			handler: showTwoFieldEditModal,
			modalData: {
				titleKey: "editNameKana",
				key1: "firstNameKana",
				value1: firstNameKana,
				key2: "lastNameKana",
				value2: lastNameKana,
				newLabelKey1: "newFirstNameKana",
				newLabelKey2: "newLastNameKana",
				updateKey: "changeNameKana"
			}
		},
		nameKanji: {
			label: "nameKanji",
			value: `${firstNameKanji} ${lastNameKanji}`,
			requirementName: "firstLastNameKanji",
			handler: showTwoFieldEditModal,
			modalData: {
				titleKey: "editNameKanji",
				key1: "firstNameKanji",
				value1: firstNameKanji,
				key2: "lastNameKanji",
				value2: lastNameKanji,
				newLabelKey1: "newFirstNameKanji",
				newLabelKey2: "newLastNameKanji",
				updateKey: "changeNameKanji"
			}
		},
		dob: dateObj
	};
	const personalInformationMapToUse = hasACountry ? 
		(props.country && props.country === "JP" 
			? japanPersonalInformationMap : personalInformationMap)
		: defaultPersonalInformationMap;

	const emailObj = {
		label: "email",
		value: emailAddress,
		requirementName: "email",
		handler: showEmailEditModal
	};
	const defaultContactInformationMap = {
		email: emailObj
	};
	const contactInformationMap = {
		email: emailObj,
		phone: {
			label: "phone",
			value: phone,
			requirementName: "phoneNumber",
			handler: showOneFieldEditModal,
			modalData: {
				// we're not including metadata for phone number formatting, 
				// since different countries have different number format
				titleKey: "editPhone",
				key1: "phone",
				value1: phone,
				inputType: "tel",
				newLabelKey1: "newPhone",
				updateKey: "changePhone"
			}
		}
	};
	const contactInformationMapToUse = hasACountry ? contactInformationMap : defaultContactInformationMap;

	useEffect(() => {
		const unlisten =  history.listen((location) => {
			const urlParams = new URLSearchParams(window.location.search);
			if (urlParams.get("requireFill") != null) {
				const requiredFields = props.requiredFields;

				if (requiredFields == null && props.country == null) {
					setCountryRowVisibility(null);
					props.setAlert(
						<Alert closeHandler={props.closeAlert} type={INFORMATION}
							msg={messageMap("account.fields.fillCountry", "generic")} />
					);
				}
				else {
					let requiredFieldLabels = [];
					// please don't try to "optimize" this by using a map, since there are array elements 
					// that are meant to not be shown
					for (let i = 0; i < requiredFields.length; ++i) {
						if (REQUIRED_FIELDS_KEY_LABEL_MAP[requiredFields[i]] != null) {
							requiredFieldLabels.push(REQUIRED_FIELDS_KEY_LABEL_MAP[requiredFields[i]]);
						}
					}
		
					props.setAlert(
						<Alert closeHandler={props.closeAlert} type={INFORMATION} timeout={ALERT_THIRTY_SECONDS_TIMEOUT}
							msg={`${messageMap("account.fillRequiredFields", "generic")} ${requiredFieldLabels.join(", ")}`} />
					);
				}
			}
		});

		return () => {
			unlisten();
		}
	}, []);

	function showUpdateConfirmModal(e) {
		if (e.target.className === "page-action not-allowed") {
			return;
		}

		props.setConfirmUpdate(
			<ConfirmModal title={messageMap("account.update", "validation")} closeModal={updateUserAccount}
				closeArgs={"confirmed"} confirmType="verify" />
		);
	}

	function updateUserAccount(e, confirmation) {
		if (e == null || (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className))) {
			props.setConfirmUpdate(null);

			if (confirmation === "confirmed") {
				let payload = {
					[USERNAME]: username !== props.acctUsername ? username : null,
					[PASSWORD]: password !== "password" && password !== "" ? password : null,
					// personal information
					[PROFILE_PICTURE]: profilePicFile !== blankProfilePhotoAsset ? profilePicFile : null,
					[FIRST_NAME]: firstName !== props.firstName ? firstName : null,
					[LAST_NAME]: lastName !== props.lastName ? lastName : null,
					[FIRST_NAME_KANA]: firstNameKana !== props.firstNameKana ? firstNameKana : null,
					[LAST_NAME_KANA]: lastNameKana !== props.lastNameKana ? lastNameKana : null,
					[FIRST_NAME_KANJI]: firstNameKanji !== props.firstNameKanji ? firstNameKanji : null,
					[LAST_NAME_KANJI]: lastNameKanji !== props.lastNameKanji ? lastNameKanji : null,
					[DATE_OF_BIRTH]: dateOfBirth !== props.dob ? dateOfBirth : null,
					// contact information
					[EMAIL]: emailAddress !== props.emailAddress ? emailAddress : null,
					[PHONE_NUMBER]: phone !== props.phone ? phone : null,
					// location
					[STREET_ADDRESS_1]: streetAddress1 !== props.streetAddress1 ? streetAddress1 : null,
					[STREET_ADDRESS_2]: streetAddress2 !== props.streetAddress2 ? streetAddress2 : null,
					[POSTAL_CODE]: postalCode !== props.postalCode ? postalCode : null,
					[POSTAL_CODE_KANA]: postalCodeKana !== props.postalCodeKana ? postalCodeKana : null,
					[POSTAL_CODE_KANJI]: postalCodeKanji !== props.postalCodeKanji ? postalCodeKanji : null,
					[CITY]: city !== props.city ? city : null,
					[STATE]: state !== props.state ? state : null,
					[COUNTRY]: country !== props.country ? country : null
				};
				let invalidMsgKeys = [];
				if (invalidMsgKeys.length) {
					let errorMsg = "";
	
					invalidMsgKeys.forEach(errorKey => {
						errorMsg += messageMap(errorKey, "validation") + " ";
					});
					props.setAlert(
						<Alert closeHandler={props.closeAlert} type={ERROR} msg={errorMsg} />
					);
	
					return;
				}

				getIPAPI(ip => {
					payload = {
						[AUTH_TOKEN]: props.authToken,
						[IP]: ip,
						[BROWSER]: getUserBrowser(),
						[PLATFORM]: getUserPlatform(),
						...payload
					};
					const pathVariables = {
						[OWNER_ID]: localStorage.getItem("ownerId")
					};
					let fieldsToValidate = [];
					for (const [key, value] of Object.entries(payload)) {
						value && fieldsToValidate.push(key);
					}

					setSpinner(
						<Spinner containerClass="signUp-spinner"  />
					);
					saveAccountSettingsAPI(pathVariables, payload, fieldsToValidate, response => {
						setSpinner(null);
						let allMsgs = [];
						response.responseCodes.forEach(respCode => {
							allMsgs.push(messageMap(respCode, "api"));
						});

						props.setAlert(
							<Alert closeHandler={props.closeAlert} type={INFORMATION} msg={allMsgs.join(" ") + " " +messageMap("accounts.update.reload", "api")} />
						);
	
						props.logInUser(response.sessionVo);
						setTimeout(() => {
							window.location.reload();
						}, SAVE_RELOAD_TIMEOUT);
					});
				});
			}
		}
	}

	function showUsernameEditModal() {
		props.setModal(
			<Modal closeType="xButton" modalClass="account" closeHandler={closeModal}
				title={messageMap("account.fields.modal.editUsername", "generic")} >
				<EditUsernameModalContent username={username} updateField={newUpdateField}
					setAlert={props.setAlert} setModal={props.setModal} />
			</Modal>
		);
	}

	function showPasswordEditModal() {
		props.setModal(
			<Modal closeType="xButton" modalClass="account" closeHandler={closeModal}
				title={messageMap("account.fields.modal.editPassword", "generic")} >
				<EditPasswordModalContent updateField={newUpdateField} setModal={props.setModal} />
			</Modal>
		);
	}

	function showOneFieldEditModal(modalObject) {
		props.setModal(
			<Modal closeType="xButton" modalClass="account" closeHandler={closeModal}
				title={messageMap(`account.fields.modal.${modalObject.titleKey}`, "generic")} >
				<EditOneFieldValueModalContent
					newLabelKey1={modalObject.newLabelKey1} inputType={modalObject.inputType}
					updateField={newUpdateField} updateButtonKey={modalObject.updateKey}
					fieldOneKey={modalObject.key1} fieldOneValue={modalObject.value1} 
					setAlert={props.setAlert} setModal={props.setModal} />
			</Modal>
		);
	}

	function showTwoFieldEditModal(modalObject) {
		props.setModal(
			<Modal closeType="xButton" modalClass="account" closeHandler={closeModal}
				title={messageMap(`account.fields.modal.${modalObject.titleKey}`, "generic")} >
				<EditTwoFieldValuesModalContent
					newLabelKey1={modalObject.newLabelKey1} newLabelKey2={modalObject.newLabelKey2}
					updateField={newUpdateField} updateButtonKey={modalObject.updateKey}
					fieldOneKey={modalObject.key1} fieldTwoKey={modalObject.key2} 
					fieldOneValue={modalObject.value1} fieldTwoValue={modalObject.value2} 
					setAlert={props.setAlert} setModal={props.setModal} />
			</Modal>
		);
	}

	function showAddressEditModal() {
		props.setModal(
			<Modal closeType="xButton" modalClass="account" closeHandler={closeModal}
				title={messageMap("account.fields.modal.editAddress", "generic")} >
				<EditAddressModalContent updateField={newUpdateField} 
					setAlert={props.setAlert} setModal={props.setModal} requiredFields={props.requiredFields}
					streetAddress1={streetAddress1} streetAddress2={streetAddress2} postalCode={postalCode}
					postalCodeKana={postalCodeKana} postalCodeKanji={postalCodeKanji}
					city={city} state={state} country={country} />
			</Modal>
		);
	}

	function showEmailEditModal() {
		props.setModal(
			<Modal closeType="xButton" modalClass="account" closeHandler={closeModal}
				title={messageMap("account.fields.modal.editEmail", "generic")} >
				<EditEmailModalContent email={emailAddress} updateField={newUpdateField}
					setAlert={props.setAlert} setModal={props.setModal} />
			</Modal>
		);
	}

	function closeModal(e, inputType) {
		if (e == null || (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className))) {
			props.setConfirmUpdate(null);
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	const originalSetValueMap = {
		profilePicFile: setProfilePicFile,
		// login
		username: setUsername,
		password: setPassword,
		// personal information
		firstName: setFirstName,
		lastName: setLastName,
		firstNameKana: setFirstNameKana,
		lastNameKana: setLastNameKana,
		firstNameKanji: setFirstNameKanji,
		lastNameKanji: setLastNameKanji,
		dob: setDateOfBirth,
		// contact information
		email: setEmailAddress,
		phone: setPhone,
		// location
		streetAddress1: setStreetAddress1,
		streetAddress2: setStreetAddress2,
		postalCode: setPostalCode,
		postalCodeKana: setPostalCodeKana,
		postalCodeKanji: setPostalCodeKanji,
		city: setCity,
		state: setState,
		country: setCountry
	};
	function newUpdateField(field, value) {
		if (field === "phone" && value.match(/[()]/)) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={messageMap("input.phoneNumber.invalidCharacters", "validation")} />
			);
		}
		else {
			originalSetValueMap[field](value);
			updateAllButtonRef.current.className = "page-action";
		}
	}

	function analyzeProfilePicture(e, acceptedFileFormats) {
		if (!e.target.files.length) {
			return;
		}

		const target = e.target;
		const targetFile = target.files[0];
		const fileFormat = getFileFormatFromTargetFile(targetFile);

		if (!acceptedFileFormats.includes(fileFormat)) {
			const msg = `${messageMap("img.fileType.notAccepted", "validation") + fileFormat}. ${messageMap("img.fileType.whatIsAccepted", "validation") + acceptedFileFormats}`;
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={msg} />
			);

			setNewProfilePicture(null);
			setProfilePicFile(null);
		}
		else {
			const htmlTag = (
				<img src={URL.createObjectURL(targetFile)} alt="uploaded profile pic" style={{display: "none"}}
					onLoad={imgData => checkProfilePicConstraints(imgData, targetFile)}></img>
			);
	
			setMetaContainerState(htmlTag);
		}
	}

	function checkProfilePicConstraints(imgData, uploadedProfilePic) {
		const {errorMsgs, hasViolations} = imageValidator(["jpg", "jpeg", "png", "webp", "svg", "gif"], [0, null], [0, null], 1, imgData, uploadedProfilePic);

		if (hasViolations) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} msg={errorMsgs} />
			);
			setValidProfilePhoto(errorMsgs);
			setNewProfilePicture(null);
			setProfilePicFile(null);
		}
		else {
			setValidProfilePhoto(true);
			setNewProfilePicture(URL.createObjectURL(uploadedProfilePic));
			setProfilePicFile(uploadedProfilePic);
			updateAllButtonRef.current.className = "page-action";
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function toggleAddressTooltip(showOrHide) {
		if (showOrHide === "show") {
			setAddressTooltip(
				<Tooltip classStr="tooltip-bottom-left tutoring" subheader={messageMap("account.address", "tooltip")} />
			);
		}
		else {
			setAddressTooltip(null);
		}
	}
	function toggleCountryTooltip(showOrHide) {
		if (showOrHide === "show") {
			setCountryTooltip(
				<Tooltip classStr="tooltip-bottom-left tutoring" subheader={messageMap("account.country", "tooltip")} />
			);
		}
		else {
			setCountryTooltip(null);
		}
	}

	function checkCountryAvailability(e) {
		const chosenCountry = e.target.getAttribute("countrykey");

		if (chosenCountry === "chooseCountry") {
			return;
		}
		if (availableCountries.includes(chosenCountry)) {
			newUpdateField("country", countriesToAlphaCode[chosenCountry]);
		}
		else {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					msg={`${chosenCountry} ${messageMap("account.fields.countryNotSupported", "generic")}`} />
			);
			updateAllButtonRef.current.className = "page-action not-allowed";
		}
	}

	return (
		<div>
			{metaContainerState}
			{spinner}

			<h1 className="page-title">
				{messageMap("profile.account", "subPageNavigation")}
			</h1>

			<div className="page-field">
				<label htmlFor="profilePicture" className="page-field-label">
					{messageMap("account.fields.profilePicture", "generic")}
				</label>

				<div className="inline-elements profile-picture">
					<img src={newProfilePicture} alt={messageMap("account.profilePicture", "image")} className="profile-picture" />
					<label className="upload-container">
						<input className="file-upload" type="file" accept=".png, .jpeg, .jpg, .webp, .svg, .gif"
							required="required" aria-required="true"
							onChange={e => analyzeProfilePicture(e, ["png", "jpeg", "jpg", "webp", "svg", "gif"])}
							onClick={e => analyzeProfilePicture(e, ["png", "jpeg", "jpg", "webp", "svg", "gif"])} />
						{messageMap("profilePage.tutoring.modal.uploads.changePhoto", "generic")}
					</label>
				</div>
			</div>

			<div className="page-field">
				<label className="page-field-label">
					<div className="div-label">
						{messageMap("account.fields.username", "generic")}
					</div>
					*
				</label>

				<div className="inline-elements">
					<div className="field-value">
						{username}
					</div>
					<div className="edit-field" role="button" tabIndex={0}
						onClick={e => showUsernameEditModal()} onKeyDown={e => onKeyDown(e, showUsernameEditModal, [])}>
						<img src={editFieldAsset} alt={messageMap("account.edit", "image")} ></img>
					</div>
				</div>
			</div>

			<div className="page-field">
				<label className="page-field-label">
					<div className="div-label">
						{messageMap("account.fields.password", "generic")}
					</div>
					*
				</label>

				<div className="inline-elements">
					<div className="field-value">
						{"**************"}
					</div>
					<div className="edit-field" role="button" tabIndex={0}
						onClick={e => showPasswordEditModal()} onKeyDown={e => onKeyDown(e, showPasswordEditModal, [])}>
						<img src={editFieldAsset} alt={messageMap("account.edit", "image")} ></img>
					</div>
				</div>
			</div>

			{/******************** Personal Information *********************/}
			{
				Object.keys(personalInformationMapToUse).map(fieldData =>
					<div className="page-field" key={fieldData}>
						<label className="page-field-label">
							<div className="div-label">
								{messageMap(`account.fields.${personalInformationMapToUse[fieldData]["label"]}`, "generic")}
							</div>
							{
								props.requiredFields && props.requiredFields.includes(personalInformationMapToUse[fieldData]["requirementName"]) && "*"
							}
						</label>

						<div className="inline-elements">
							<div className="field-value">
								{personalInformationMapToUse[fieldData]["value"]}
							</div>
							<div className="edit-field" role="button" tabIndex={0}
								onClick={e => personalInformationMapToUse[fieldData]["handler"](personalInformationMapToUse[fieldData]["modalData"])}
								onKeyDown={e => onKeyDown(e, personalInformationMapToUse[fieldData]["handler"], [personalInformationMapToUse[fieldData]["modalData"]])}>
								<img src={editFieldAsset} alt={messageMap("account.edit", "image")} ></img>
							</div>
						</div>
					</div>
				)
			}

			{/******************* Contact Information ******************/}
			{
				Object.keys(contactInformationMapToUse).map(fieldData =>
					<div className="page-field" key={fieldData}>
						<label className="page-field-label">
							<div className="div-label">
								{messageMap(`account.fields.${contactInformationMapToUse[fieldData]["label"]}`, "generic")}
							</div>
							{
								props.requiredFields && props.requiredFields.includes(contactInformationMapToUse[fieldData]["requirementName"]) && "*"
							}
						</label>

						<div className="inline-elements">
							<div className="field-value">
								{contactInformationMapToUse[fieldData]["value"]}
							</div>
							<div className="edit-field" role="button" tabIndex={0}
								onClick={e => contactInformationMapToUse[fieldData]["handler"](contactInformationMapToUse[fieldData]["modalData"])}
								onKeyDown={e => onKeyDown(e, contactInformationMapToUse[fieldData]["handler"], [contactInformationMapToUse[fieldData]["modalData"]])}>
								<img src={editFieldAsset} alt={messageMap("account.edit", "image")} ></img>
							</div>
						</div>
					</div>
				)
			}

			{/******************** Location ********************/}
			{
				hasACountry ?
				(
					<div className="page-field">
						<label className="page-field-label">
							<div className="div-label">
								{messageMap("account.fields.address", "generic")}
							</div>
							{
								props.requiredFields && 
									[
										"line1Address", "line2Address", "postalCodeAddress", "postalCodeAddressKana", "postalCodeAddressKanji",
										"cityAddress", "stateAddress"
									].some(el => props.requiredFields.includes(el)) && "*"
							}
							<img src={labelInfoAsset} alt="information" tabIndex={0}
								onMouseOver={e => toggleAddressTooltip("show")} onFocus={e => toggleAddressTooltip("show")}
								onMouseLeave={e => toggleAddressTooltip("hide")} onBlur={e => toggleAddressTooltip("hide")} ></img>
							{addressTooltip}
						</label>

						<div className="inline-elements">
							<div className="field-value">
								{
									[
										streetAddress1, streetAddress2, postalCode, postalCodeKana, postalCodeKanji,
										city, state, country
									].filter(addr => addr !== null).join(", ")
								}
							</div>
							<div className="edit-field" role="button" tabIndex={0}
								onClick={e => showAddressEditModal()}
								onKeyDown={e => onKeyDown(e, showAddressEditModal, [])}>
								<img src={editFieldAsset} alt={messageMap("account.edit", "image")} ></img>
							</div>
						</div>
					</div>
				)
				: null
			}
			<div className={`page-field ${countryRowVisibility}`}>
				<label className="page-field-label">
					<div className="div-label">
						{messageMap("account.fields.country", "generic")}
					</div>
					*
					<img src={labelInfoAsset} alt="information" tabIndex={0}
						onMouseOver={e => toggleCountryTooltip("show")} onFocus={e => toggleCountryTooltip("show")}
						onMouseLeave={e => toggleCountryTooltip("hide")} onBlur={e => toggleCountryTooltip("hide")} ></img>
					{countryTooltip}
				</label>
				<div className="inline-elements">
					<Dropdown customDropdownItemAttribute="countrykey" customContainerClass="tutoring-application no-right-margin"
						dropdownOptions={fullCountriesList} dropdownItemClickHandler={checkCountryAvailability} />
				</div>
			</div>

			<button className="page-action not-allowed"
				onClick={showUpdateConfirmModal} ref={updateAllButtonRef}>
				{messageMap("update.text", "button")}
			</button>
		</div>
	);
}

AccountsSubPage.defaultProps = {
	requiredFields: []
};

AccountsSubPage.propTypes = {
	// login
	acctUsername: PropTypes.string.isRequired, // cannot use prop names similar to what's used in react-redux, else it'll get overwritten
	// personal information
	profilePictureUploadLocation: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	dob: PropTypes.string,
	firstNameKana: PropTypes.string,
	lastNameKana: PropTypes.string,
	firstNameKanji: PropTypes.string,
	lastNameKanji: PropTypes.string,
	// contact information
	emailAddress: PropTypes.string.isRequired,
	phone: PropTypes.string,
	// location
	streetAddress1: PropTypes.string,
	streetAddress2: PropTypes.string,
	postalCode: PropTypes.string,
	city: PropTypes.string,
	state: PropTypes.string,
	country: PropTypes.string,
	postalCodeKana: PropTypes.string,
	postalCodeKanji: PropTypes.string,
	// others
	requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
	isTutor: PropTypes.bool,
	earnings: PropTypes.number.isRequired,
	minimumCurrencyWithdrawal: PropTypes.number.isRequired,

	// click handlers
	closeAlert: PropTypes.func.isRequired,
	setConfirmUpdate: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	reRenderAccounts: PropTypes.func.isRequired,

	// parent state setters
	setAlert: PropTypes.func.isRequired,

	// Redux props
	logInUser: PropTypes.func.isRequired,
	authToken: PropTypes.string.isRequired
};

export default connect(
	account,
	{ logInUser }
)(AccountsSubPage);

