export const VIDEO_FEEDBACK_VO = {
	ownerId: {
		type: "string"
	},
	feedback: {
		type: "string"
	},
	classId: {
		type: "string"
	},
	videoId: {
		type: "string"
	}
};

export const NOTE_VO = {
	ownerId: {
		type: "string"
	},
	videoId: {
		type: "string",
		required: false
	},
	note: {
		type: "string",
		required: false
	},
	videoTs: {
		type: "string"
	}
};

export const VIDEO_MENTAL_MODEL_VO = {
	videoId: {
		type: "string",
		required: false
	},
	mentalModels: {
		type: "list"
	},
};

export const UPLOAD_VO = {
	ownerId: {
		type: "string"
	},
	videoTitle: {
		type: "string",
		required: false
	},
	video: {
		type: "object"
	},
	thumbnail: {
		type: "object"
	},
	description: {
		type: "string"
	},
	category: {
		type: "string"
	},
	purpose: {
		type: "string"
	}
};