import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import {DESCRIPTION, SUB_HEADER, SOURCE} from "assets/topicArticles/articleConstants";
import { findWorkbookFromSubject } from "diagrams/utilities/NetworkGenerator";

import {
	findTexts, findHTMLs, getTopic, getNextTopic,
	getPreviousTopic, generateTopicsInfoStructuredData
} from "pages/Classes/TopicsInfoList/utilities/TopicsInfoUtilities";

import PageSwitcher, {VIDEO_PAGE, PRACTICE_PAGE, INFO_PAGE} from "pages/Classes/Components/PageSwitcher";
import TopicNavigator from "pages/Classes/Components/TopicNavigator";


function TopicInfo(props) {

	const [pageSwitcher, setPageSwitcher]= useState(),
		[topicNavigator, setTopicNavigator] = useState(),
		[body, setBody] = useState(),
		[topicNavigatorCounter, setTopicNavigatorCounter] = useState(0);

	const topicDetailsRef = useRef(getStateProp("topicDetails")),
		topicWorkbookRef = useRef(getStateProp("workbook")),
		topicSubjectRef = useRef(getStateProp("subject")),
		isOnJourneyRef = useRef(getStateProp("isJourney") || false);

	useEffect(() => {
		populateMissingProps();
		renderBody();
		checkNextNPreviousTopicAvailability();
		renderPageSwitcher();
	}, [topicNavigatorCounter]);

	function getStateProp(propName) {
		return props && props.routerProp && props.routerProp[propName];
	}

	function renderPageSwitcher() {
		setPageSwitcher(
			<PageSwitcher key={topicNavigatorCounter} curPage={INFO_PAGE} leftPage={VIDEO_PAGE} rightPage={PRACTICE_PAGE}
				workbook={topicWorkbookRef.current} subject={topicSubjectRef.current}
				topicId={topicDetailsRef.current.id}  isOnJourney={isOnJourneyRef.current} />
		);
	}

	function populateMissingProps() {
		if (!topicDetailsRef.current || !topicWorkbookRef.current || !topicSubjectRef.current
			|| (props.routerProp && props.routerProp.topicDetails.id !== topicDetailsRef.current.id)
		) {
			const urlLocation = window.location.href;
			const topicIdRegEx = new RegExp("(?<=topicId=).*");
			const subjectRegEx = new RegExp("(?<=subject=).*(?=&topicId)");

			const subject = subjectRegEx.exec(urlLocation)[0];
			const topicId = topicIdRegEx.exec(urlLocation)[0].replaceAll("-", " ");
			const workbook = findWorkbookFromSubject(subject);

			topicWorkbookRef.current = workbook;
			topicSubjectRef.current = subject;
			topicDetailsRef.current = getTopic(workbook, subject, topicId).metadata;
		}
	}

	function renderBody() {
		const textMap = getBodyTextObjects();
		const htmlMap = getBodyHTMLObjects();

		let bodyElements = [];
		topicDetailsRef.current.bodyFormatOrder.forEach(order => {
			let bodyElement = order.includes("text") ? textMap[order] : htmlMap[order];
			bodyElements.push(bodyElement);
		});
		setBody(bodyElements);
	}

	function getBodyTextObjects() {
		const texts = findTexts(topicWorkbookRef.current, topicSubjectRef.current, topicDetailsRef.current.id);
		let textObjects = {};
		let sourceCount = 1;
		for (const [key, value] of Object.entries(texts)) {

			if (value.type === SUB_HEADER) {
				textObjects[key] = (
					<h2 className={value.type} key={key}
							dangerouslySetInnerHTML={
								{ __html: (value.type === SOURCE ? `[${sourceCount}] ${value.text}` : value.text) }
							}
						>
					</h2>
				);
			}
			else {
				textObjects[key] = (
					<div className={value.type} key={key}
							dangerouslySetInnerHTML={
								{ __html: (value.type === SOURCE ? `[${sourceCount}] ${value.text}` : value.text) }
							}
						>
					</div>
				);
			}

			value.type === SOURCE && ++sourceCount;
		}

		return textObjects;
	}

	function getBodyHTMLObjects() {
		const htmls = findHTMLs(topicWorkbookRef.current, topicSubjectRef.current, topicDetailsRef.current.id);
		let htmlObjects = {};
		if (htmls) {
			for (const [key, value] of Object.entries(htmls)) {
				htmlObjects[key] = value;
			}
		}

		return htmlObjects;
	}

	function checkNextNPreviousTopicAvailability() {
		const nextTopic = getNextTopic(topicWorkbookRef.current, topicSubjectRef.current, topicDetailsRef.current.id);
		const prevTopic = getPreviousTopic(topicWorkbookRef.current, topicSubjectRef.current, topicDetailsRef.current.id);

		const hidePrevTopicButton = prevTopic ? "" : "hide";
		const hideNextTopicButton = nextTopic ? "" : "hide";

		let nextLink;
		let previousLink;

		if (nextTopic) {
			nextLink = {
				pathname: "/topic-info?subject=" + topicSubjectRef.current + "&topicId=" + nextTopic.replaceAll(" ", "-"),
				state: {
					topicDetails: getTopic(topicWorkbookRef.current, topicSubjectRef.current, nextTopic).metadata,
					workbook: topicWorkbookRef.current,
					subject: topicSubjectRef.current,
					isOnJourney: false
				}
			};
		}
		if (prevTopic) {
			previousLink = {
				pathname: "/topic-info?subject=" + topicSubjectRef.current + "&topicId=" + prevTopic.replaceAll(" ", "-"),
				state: {
					topicDetails: getTopic(topicWorkbookRef.current, topicSubjectRef.current, prevTopic).metadata,
					workbook: topicWorkbookRef.current,
					subject: topicSubjectRef.current,
					isOnJourney: false
				}
			};
		}

		setTopicNavigator(
			<TopicNavigator goToPreviousTopic={goToPreviousTopic} goToNextTopic={goToNextTopic}
				nextLink={nextLink} previousLink={previousLink}
				showOrHideLeftButton={hidePrevTopicButton} showOrHideRightButton={hideNextTopicButton} />
		);
	}

	function goToPreviousTopic() {
		// force trigger useEffect
		setTopicNavigatorCounter(topicNavigatorCounter - 1);
	}

	function goToNextTopic() {
		// force trigger useEffect
		setTopicNavigatorCounter(topicNavigatorCounter + 1);
	}


	return (
		<div className="topic-info">
			<Helmet>
				<title>{topicDetailsRef.current && topicDetailsRef.current.title}</title>
				<meta name="description" content={topicDetailsRef.current && topicDetailsRef.current.description}></meta>
				<script type="application/ld+json">{topicDetailsRef.current && generateTopicsInfoStructuredData(topicDetailsRef.current)}</script>
			</Helmet>

			<h1 className="title">
				{topicDetailsRef.current && topicDetailsRef.current.title}
			</h1>

			<div className="topic-resource-links-container">
				{pageSwitcher}

				{topicNavigator}
			</div>

			<div className="topic-description">
				{body}
			</div>

			<div className="topic-resource-links-container">
				{pageSwitcher}

				{topicNavigator}
			</div>
		</div>
	);
}

TopicInfo.propTypes = {
	routerProp: PropTypes.object
};

export default TopicInfo;