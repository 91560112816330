import React from "react";

import messageMap from "Utilities/MessageMaps";

import ChangeableShape from "assets/topicArticles/Math/components/ChangeableShape";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import StaticImages from "assets/topicArticles/Math/components/StaticImages";

import thumbnail from "assets/topicArticles/Math/Level 01 Math/I- Figures/naming2DShapes.jpg";

import shapePartsAsset from "assets/topicArticles/Math/Level 01 Math/I- Figures/2Dsides.svg";

import triangleAsset from "assets/images/practice/math/2dShapes/triangle.svg";
import circleAsset from "assets/images/practice/math/2dShapes/circle.svg";
import semiCircleAsset from "assets/images/practice/math/2dShapes/semiCircle.svg";
import squareAsset from "assets/images/practice/math/2dShapes/square.svg";
import rectangleAsset from "assets/images/practice/math/2dShapes/rectangle.svg";
import rhombusAsset from "assets/images/practice/math/2dShapes/rhombus.svg";
import trapezoidAsset from "assets/images/practice/math/2dShapes/trapezoid.svg";
import hexagonAsset from "assets/images/practice/math/2dShapes/hexagon.svg";
import pentagonAsset from "assets/images/practice/math/2dShapes/pentagon.svg";
import heptagonAsset from "assets/images/practice/math/2dShapes/heptagon.svg";
import octagonAsset from "assets/images/practice/math/2dShapes/octagon.svg";
import nonagonAsset from "assets/images/practice/math/2dShapes/nonagon.svg";
import decagonAsset from "assets/images/practice/math/2dShapes/decagon.svg";

export const twoDShapesDifficultyMap = {
	1: ["triangle", "circle", "semicircle", "square", "rectangle"],
	2: ["rhombus", "trapezoid", "pentagon", "hexagon", "heptagon", "octagon", "nonagon", "decagon"]
};
export const twoDShapesToAnswerMap = {
	"triangle": "Triangle",
	"circle": "Circle",
	"semicircle": "Semicircle",
	"square": "Square",
	"rectangle": "Rectangle",
	"rhombus": "Rhombus",
	"trapezoid": "Trapezoid",
	"pentagon": "Pentagon",
	"hexagon": "Hexagon",
	"heptagon": "Heptagon",
	"octagon": "Octagon",
	"nonagon": "Nonagon",
	"decagon": "Decagon"
};
export const twoDShapesToHintsMap = {
	"triangle": messageMap("naming2DShapes.twoDShapesToHintsMap.triangle", "math"),
	"circle": messageMap("naming2DShapes.twoDShapesToHintsMap.circle", "math"),
	"semicircle": messageMap("naming2DShapes.twoDShapesToHintsMap.semicircle", "math"),
	"square": messageMap("naming2DShapes.twoDShapesToHintsMap.square", "math"),
	"rectangle": messageMap("naming2DShapes.twoDShapesToHintsMap.rectangle", "math"),
	"rhombus": messageMap("naming2DShapes.twoDShapesToHintsMap.rhombus", "math"),
	"trapezoid": messageMap("naming2DShapes.twoDShapesToHintsMap.trapezoid", "math"),
	"pentagon": messageMap("naming2DShapes.twoDShapesToHintsMap.pentagon", "math"),
	"hexagon": messageMap("naming2DShapes.twoDShapesToHintsMap.hexagon", "math"),
	"heptagon": messageMap("naming2DShapes.twoDShapesToHintsMap.heptagon", "math"),
	"octagon": messageMap("naming2DShapes.twoDShapesToHintsMap.octagon", "math"),
	"nonagon": messageMap("naming2DShapes.twoDShapesToHintsMap.nonagon", "math"),
	"decagon": messageMap("naming2DShapes.twoDShapesToHintsMap.decagon", "math")
};
export const twoDShapesToAssetsMap = {
	"triangle": triangleAsset,
	"circle": circleAsset,
	"semicircle": semiCircleAsset,
	"square": squareAsset,
	"rectangle": rectangleAsset,
	"rhombus": rhombusAsset,
	"trapezoid": trapezoidAsset,
	"pentagon": pentagonAsset,
	"hexagon": hexagonAsset,
	"heptagon": heptagonAsset,
	"octagon": octagonAsset,
	"nonagon": nonagonAsset,
	"decagon": decagonAsset
};
export const twoDShapesToImgAltsMap = {
	"triangle": messageMap("naming2DShapes.twoDShapesToImgAltsMap.triangle", "math"),
	"circle": messageMap("naming2DShapes.twoDShapesToImgAltsMap.circle", "math"),
	"semicircle": messageMap("naming2DShapes.twoDShapesToImgAltsMap.semicircle", "math"),
	"square": messageMap("naming2DShapes.twoDShapesToImgAltsMap.square", "math"),
	"rectangle": messageMap("naming2DShapes.twoDShapesToImgAltsMap.rectangle", "math"),
	"rhombus": messageMap("naming2DShapes.twoDShapesToImgAltsMap.rhombus", "math"),
	"trapezoid": messageMap("naming2DShapes.twoDShapesToImgAltsMap.trapezoid", "math"),
	"pentagon": messageMap("naming2DShapes.twoDShapesToImgAltsMap.pentagon", "math"),
	"hexagon": messageMap("naming2DShapes.twoDShapesToImgAltsMap.hexagon", "math"),
	"heptagon": messageMap("naming2DShapes.twoDShapesToImgAltsMap.heptagon", "math"),
	"octagon": messageMap("naming2DShapes.twoDShapesToImgAltsMap.octagon", "math"),
	"nonagon": messageMap("naming2DShapes.twoDShapesToImgAltsMap.nonagon", "math"),
	"decagon": messageMap("naming2DShapes.twoDShapesToImgAltsMap.decagon", "math")
};

const edgeCountToShape = {
	3: "triangle",
	4: "square",
	5: "pentagon",
	6: "hexagon",
	7: "heptagon",
	8: "octagon",
	9: "nonagon",
	10: "decagon"
};
const edgeCountToShapeTip = {
	3: messageMap("naming2DShapes.edgeCountToShapeTip.3", "math"),
	4: messageMap("naming2DShapes.edgeCountToShapeTip.4", "math"),
	5: messageMap("naming2DShapes.edgeCountToShapeTip.5", "math"),
	6: messageMap("naming2DShapes.edgeCountToShapeTip.6", "math"),
	7: messageMap("naming2DShapes.edgeCountToShapeTip.7", "math"),
	8: messageMap("naming2DShapes.edgeCountToShapeTip.8", "math"),
	9: messageMap("naming2DShapes.edgeCountToShapeTip.9", "math"),
	10: messageMap("naming2DShapes.edgeCountToShapeTip.10", "math")
};
const alterableProperties = {
	edges: {
		range: [3, 10],
		keysMap: edgeCountToShape,
		tipMap: edgeCountToShapeTip,
		nameMap: twoDShapesToAnswerMap,
		imgMap: twoDShapesToAssetsMap,
		imAltMap: twoDShapesToImgAltsMap
	}
};
const staticProperties = {
	"triangle": {
		sides: 3,
		corners: 3
	},
	"square": {
		sides: 4,
		corners: 4
	},
	"pentagon": {
		sides: 5,
		corners: 5
	},
	"hexagon": {
		sides: 6,
		corners: 6
	},
	"heptagon": {
		sides: 7,
		corners: 7
	},
	"octagon": {
		sides: 8,
		corners: 8
	},
	"nonagon": {
		sides: 9,
		corners: 9
	},
	"decagon": {
		sides: 10,
		corners: 10
	},
	"circle": {
		sides: 0,
		corners: 0
	},
	"semicircle": {
		sides: 2,
		corners: 0
	},
	"rectangle": {
		sides: 4,
		corners: 4
	},
	"rhombus": {
		sides: 4,
		corners: 4
	},
	"trapezoid": {
		sides: 4,
		corners: 4
	}
}

export const naming2DShapesArticle = {
	id: "I: Figures_A. Naming 2D Shapes",
	title: messageMap("naming2DShapes.article.title", "math"),
	description: messageMap("naming2DShapes.article.description", "math"),
	thumbnail: thumbnail,
	thumbnailAlt: messageMap("naming2DShapes.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1",
		"text3", "html2", "html3", "html4",
		"text4", "html5", "html6", "html7", "text5"
	],
	datePublished: "2024-06-03",
	dateModified: "2024-06-03"
};

export const naming2DShapesArticleTexts = {
	text1: {
		text: messageMap("naming2DShapes.naming2DShapesArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("naming2DShapes.naming2DShapesArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("naming2DShapes.naming2DShapesArticleTexts.text3", "math"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("naming2DShapes.naming2DShapesArticleTexts.text4", "math"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("naming2DShapes.naming2DShapesArticleTexts.text5", "math"),
		type: DESCRIPTION
	}
}

export const naming2DShapesArticleHTMLs = {
	html1: (
		<StaticImages key="shapeParts" imgContainerClass="center"
			images={{
				left: {
					img: shapePartsAsset,
					alt: "Showing a shapes side and corner.",
					class: "two-hundred-eighty-height"
				}
			}} />
	),
	html2: (
		<ChangeableShape key="triangle" domkey="triangle" img={triangleAsset} imgAlt={twoDShapesToImgAltsMap["triangle"]}
			shapeName="Triangle"
			description={messageMap("naming2DShapes.edgeCountToShapeTip.3", "math")}
			staticProperties={staticProperties} alterableProperties={alterableProperties} />
	),
	html3: (
		<ChangeableShape key="circle" domkey="circle" img={circleAsset} imgAlt={twoDShapesToImgAltsMap["circle"]}
			shapeName="Circle" staticProperties={staticProperties}
			description={messageMap("naming2DShapes.naming2DShapesArticleHTMLs.circle", "math")}/>
	),
	html4: (
		<ChangeableShape key="semicircle" domkey="semicircle" img={semiCircleAsset} imgAlt={twoDShapesToImgAltsMap["semicircle"]}
			shapeName="Semicircle" staticProperties={staticProperties}
			description={messageMap("naming2DShapes.naming2DShapesArticleHTMLs.semicircle", "math")}/>
	),
	html5: (
		<ChangeableShape key="rectangle" domkey="rectangle" img={rectangleAsset} imgAlt={twoDShapesToImgAltsMap["rectangle"]}
			shapeName="Rectangle" staticProperties={staticProperties}
			description={messageMap("naming2DShapes.naming2DShapesArticleHTMLs.rectangle", "math")}/>
	),
	html6: (
		<ChangeableShape key="rhombus" domkey="rhombus" img={rhombusAsset} imgAlt={twoDShapesToImgAltsMap["rhombus"]}
			shapeName="Rhombus" staticProperties={staticProperties}
			description={messageMap("naming2DShapes.naming2DShapesArticleHTMLs.rhombus", "math")}/>
	),
	html7: (
		<ChangeableShape key="trapezoid" domkey="trapezoid" img={trapezoidAsset} imgAlt={twoDShapesToImgAltsMap["trapezoid"]}
			shapeName="Trapezoid" staticProperties={staticProperties}
			description={messageMap("naming2DShapes.naming2DShapesArticleHTMLs.trapezoid", "math")}/>
	)
}


