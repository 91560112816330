import React, {useState} from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";

import Alert, {ERROR} from "templates/Alert";
import Modal from "templates/Modal";

import chevronAsset from "assets/icons/common/chevron.svg";

function BookSessionModal(props) {

	const [chosenDateTime, setChosenDateTime] = useState();

	function validateAndDisplayLaterSchedule() {
		let errorMsgs = [];

		if (chosenDateTime === "" || chosenDateTime == null) {
			errorMsgs.push(messageMap("schedule.invalidEarlierDate", "validation"));
		}
		else if (props.bookedTimes.includes(chosenDateTime.getTime())) {
			errorMsgs.push(messageMap("schedule.alreadyBooked", "validation"));
		}
		if (props.durationLaterMinutesRef.current.value === "") {
			errorMsgs.push(messageMap("schedule.missing.duration", "validation"));
		}
		if (props.durationLaterMinutesRef.current.value != null && props.durationLaterMinutesRef.current.value % 15 !== 0) {
			errorMsgs.push(messageMap("schedule.invalidStartingMinute", "validation"));
		}

		if (errorMsgs.length) {
			props.setAlert(
				<Alert type={ERROR} customClass="tutoring" 
					closeHandler={closeAlert} msg={errorMsgs.join(" ")} />
			);
		}
		else {
			props.submitHandler();
		}
	}

	function updateSchedule(dateTime) {
		let timestamp = new Date();
		// requesting scheduled sessions should be at least 12 hours ahead
		timestamp.setHours(timestamp.getHours() + 12);
		const chosenTime = new Date(dateTime);

		if (timestamp.getTime() > chosenTime.getTime()) {
			props.setAlert(
				<Alert type={ERROR} customClass="tutoring" closeHandler={closeAlert} 
					msg={messageMap("schedule.invalidEarlierDate", "validation")} />
			);
		}
		else if (props.bookedTimes.includes(chosenTime.getTime())) {
			props.setAlert(
				<Alert type={ERROR} closeHandler={closeAlert} customClass="tutoring" 
					msg={messageMap("schedule.alreadyBooked", "validation")} />
			);
		}
		else {
			props.chosenDateTime.current = chosenTime;
		}

		setChosenDateTime(chosenTime);
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function updateLaterDuration(durationDirection) {
		if ((durationDirection === "decrease" && Number(props.durationLaterMinutesRef.current.value) === 15)
			|| (durationDirection === "increase" && Number(props.durationLaterMinutesRef.current.value) === 60)) {
			return;
		}
		else if (props.durationLaterMinutesRef.current.value <= 60 && props.durationLaterMinutesRef.current.value >= 0) {
			if (durationDirection === "increase") {
				props.durationLaterMinutesRef.current.value = Number(props.durationLaterMinutesRef.current.value) + 15;
			}
			else if (durationDirection === "decrease") {
				props.durationLaterMinutesRef.current.value = Number(props.durationLaterMinutesRef.current.value) - 15;
			}
		}
	}

	return (
		<Modal closeHandler={props.closeModal} submitHandler={validateAndDisplayLaterSchedule}
				modalClass="tutoring" submitText={messageMap("tutoring.modal.setSchedule", "button")}>

				<div className="schedule-option">
					<label htmlFor="dateAndTime" className="schedule-label">
						{messageMap("tutoringPage.request.modal.dateTime", "generic")}
					</label>
					<input id="dateAndTime" className="schedule-value time" type="datetime-local"
						onChange={e => updateSchedule(e.target.value)}>
					</input>
				</div>

				<div className="schedule-option">
					<label htmlFor="duration" className="schedule-label">
						{messageMap("tutoringPage.request.modal.duration", "generic")}
					</label>
					<div className="arrowed-input-container">
						<input id="duration" className="schedule-value duration"
							type="number" min="15" max="240" step="15"
							ref={props.durationLaterMinutesRef}>
						</input>
						<div className="inline-chevron-div">
							<div className="chevron-container">
								<img src={chevronAsset} alt={messageMap("chevron.down", "image")}
									className="chevron-up" tabIndex={0} role="button"
									onClick={e => updateLaterDuration("increase")}
									onKeyDown={e => onKeyDown(e, updateLaterDuration, ["increase"])}></img>
								<img src={chevronAsset} alt={messageMap("chevron.up", "image")}
								className="chevron-down" tabIndex={0} role="button"
								onClick={e => updateLaterDuration("decrease")}
								onKeyDown={e => onKeyDown(e, updateLaterDuration, ["decrease"])}></img>
						</div>
					</div>
					<div>
						{messageMap("tutoringPage.request.modal.minutes", "generic")}
					</div>
				</div>
			</div>
		</Modal>
	);
}

BookSessionModal.propTypes = {
	setAlert: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,

	submitHandler: PropTypes.func.isRequired,
	bookedTimes: PropTypes.array.isRequired,

	// parent refs
	chosenDateTime: PropTypes.object.isRequired,
	durationLaterMinutesRef: PropTypes.object.isRequired
};

export default BookSessionModal

