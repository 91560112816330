import React, {useRef, useEffect} from 'react';
import PropTypes from "prop-types";
import videojs from "video.js";
import 'video.js/dist/video-js.css';

import messageMap from "Utilities/MessageMaps";


/**
 * @param {String} hlsLink // link to hls video in S3
 * @param {String} transcriptSrc // link to transcript file in S3
 * @param {Function} onEndedHandler? // triggers when video finishes playing
 * @param {Function} onCanPlayHandler? // triggers when video can start playing
 * @param {Function} onSeekedHandler? // triggers when user is scrubbing through video
 * @param {Function} onTimeUpdateHandler? // triggers when video ticks
 * @param {Object} controllerRef? ref for pausing, stopping, controlling the video
 * @param {Object} transcriptRef? ref for putting the transcript url link
 */
function VideoPlayer(props) {

	const videoPlayerContainerRef = useRef(),
				videoSrcRef = useRef(),
				playerRef = useRef();

	useEffect(() => {
		if (!videoSrcRef.current) {
			props.controllerRef.current = document.createElement("video-js", {
				innerText: messageMap("video.browserSupport", "generic")
			});
			props.controllerRef.current.classList.add('video-js');

			props.controllerRef.current.appendChild(
				document.createElement("track", {
					ref: props.transcriptRef ? props.transcriptRef : null,
					default: true,
					kind: "captions",
					srcLang: "en",
					src: props.transcriptSrc ? props.transcriptSrc : null
				})
			);
			videoPlayerContainerRef.current.appendChild(props.controllerRef.current);

			const player = videoSrcRef.current = videojs(props.controllerRef.current, {
				controlsList: "nodownload",
				autoplay: true,
				controls: true,
				disablePictureInPicture: true,
				playbackRates: [0.5, 1, 1.5, 2],
				enableSmoothSeeking: false,
				responsive: true,
				fluid: true,
				controlBar: {
					skipButtons: {
						forward: 10,
						backward: 10
					}
				},
				sources: [{
					src: props.hlsLink,
					type: "application/x-mpegURL",
				}],
				title: "test title"
			}, () => {
				videojs.log('player is ready');
				player.children()[0].setAttribute("title", props.videoTitle);
				player.children()[0].setAttribute("description", props.videoDescription);
				handlePlayerReady && handlePlayerReady(player);
			});
		}
		else if (playerRef.current != null) {
			playerRef.current.src(props.hlsLink);
		}
	});

	function handlePlayerReady(player) {
		playerRef.current = player;

		// You can handle player events here, for example:
		player.on("waiting", () => {
			videojs.log("player is waiting");
		});

		player.on("dispose", () => {
			videojs.log("player will dispose");
		});

		player.on("ended", () => {
			props.onEndedHandler && props.onEndedHandler();
		});

		player.on("canplay", e => {
			// TODO: fix later
			// props.onCanPlayHandler && props.onCanPlayHandler(e);
		});

		player.on("seeked", () => {
			props.onSeekedHandler && props.onSeekedHandler();
		});

		player.on("timeupdate", e => {
			// TODO: fix later
			// props.onTimeUpdateHandler && props.onTimeUpdateHandler(e);
		});
	}

	return (
		<div ref={videoPlayerContainerRef}>
		</div>
	);
}

VideoPlayer.propTypes = {
	videoTitle: PropTypes.string.isRequired,
	videoDescription: PropTypes.string.isRequired,

	hlsLink: PropTypes.string.isRequired,
	transcriptSrc: PropTypes.string,
	onEndedHandler: PropTypes.func,
	onCanPlayHandler: PropTypes.func,
	onSeekedHandler: PropTypes.func,
	onTimeUpdateHandler: PropTypes.func,

	controllerRef: PropTypes.object,
	transcriptRef: PropTypes.object
};

export default VideoPlayer;

