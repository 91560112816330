import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import messageMap from 'Utilities/MessageMaps';

import closeAsset from "assets/icons/common/close.svg";
import checkAsset from "assets/icons/alerts/check.svg";
import warningAsset from "assets/icons/alerts/warning.svg";
import cancelAsset from "assets/icons/alerts/cancel.svg";
import informationAsset from "assets/icons/alerts/information.svg";



export const SUCCESS = "success";
export const INFORMATION = "information";
export const WARNING = "warning";
export const ERROR = "error";

/**
 * @param {String} type, // alert type: ["success", "warning", "error", "information"]
 * @param {String} msg, // message to display
 * @param {Function} closeHandler, // handler defined by parent to dismount the component
 * @param {Number} timeout? // automatically closes window after timeout value (in milliseconds)
 * @param {Boolean} showMsgType? // flag whether to include msg type (Success:, Warning:, Error:, Info:) in imgMap
 * @param {Object} textStyle? // for customizing text css
 * @param {Object} iconStyle? // for customizing icon css
 * @param {String} customClass? // custom class
 * @param {Object} alertContainerStyle? // for customizing alert container css
 */
export default function Alert(props) {
	const [alertDisplay, setAlertDisplay] = useState(props.customClass);

	useEffect(() => {
		setTimeout(() => {
			setAlertDisplay("hide");
			props.closeHandler();
		}, props.timeout);
	});

	const imgMap = {
		success: {
			svg: checkAsset,
			alt: messageMap("alerts.check", "image"),
			msg: "Success:"
		},
		warning: {
			svg: warningAsset,
			alt: messageMap("alerts.warning", "image"),
			msg: "Warning:"
		},
		error: {
			svg: cancelAsset,
			alt: messageMap("alerts.cancel", "image"),
			msg: "Error:"
		},
		information: {
			svg: informationAsset,
			alt: messageMap("alerts.information", "image"),
			msg: "Info:"
		}
	};

	return (
		<div role="status" className={`alert ${alertDisplay}`} style={props.alertContainerStyle}>
			<div className={props.type}>
				<span className="icon-container" style={props.iconStyle}>
					<img className="icon" src={imgMap[props.type].svg} alt={imgMap[props.type].alt}></img>
				</span>
				<span className="label">
					{props.showMsgType ? imgMap[props.type].msg : ""}
				</span>
				<button className="close-alert" type="submit" onClick={() => props.closeHandler()}>
					<img className="icon" src={closeAsset} alt={messageMap("alerts.close", "image")}
								customiconid={props.customiconid}></img>
				</button>
				<span className="text" style={props.textStyle}>
					{props.msg}
				</span>
			</div>
		</div>
	)
}

Alert.defaultProps = {
	timeout: 10000
};

Alert.propTypes = {
	customiconid: PropTypes.string,
	customClass: PropTypes.string,
	iconStyle: PropTypes.object,
	textStyle: PropTypes.object,
	alertContainerStyle: PropTypes.object,

	timeout: PropTypes.number,

	showMsgType: PropTypes.bool,
	display: PropTypes.string,

	type: PropTypes.string.isRequired,
	msg: PropTypes.string.isRequired,

	closeHandler: PropTypes.func.isRequired

};