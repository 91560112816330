import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import { TUTOR_USER, OPEN_STATE } from "../utilities/TutoringRequestConstants";

import messageMap from "Utilities/MessageMaps";

import { SUBJECT_FILTER } from "templates/utilities/FilterUtility";
import SideFilter from "templates/SideFilter";
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";
import RequestList from "pages/Tutoring/subPages/subMenus/RequestList";

import { getTutorProfileAPI } from "apis/controllers/tutoring/TutorProfileController";


function SessionsJobBoard(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	const [filterDom, setFilterDom] = useState(null);
	const [emptyTutoringRequests, setEmptyTutoringRequests] = useState(null);

	const isEmptyValueRef = useRef(false);

	useEffect(() => {
		filterList();
	}, [props.ownerId]);

	function filterList() {
		const pathVariables = {
			tutorOwnerId: ownerId
		};
		getTutorProfileAPI(pathVariables, resp => {
			let subjects = new Set();
			resp.subjectToTopicsMap.split("|").forEach(subjectTopic => {
				subjects.add(subjectTopic.split("_")[0]);
			});

			if (isEmptyValueRef.current) {
				setFilterDom(null);
			}
			else {
				setFilterDom(
					<SideFilter filterCategories={[SUBJECT_FILTER]} filterKeys={subjects}
						filterHandler={filterList} purpose="job-board" />
				);
			}
		});
	}

	function indicateEmptyList() {
		setFilterDom(null);
		isEmptyValueRef.current = true;
		if (ownerId) {
			setEmptyTutoringRequests(
				<div className="empty-list-container">
					{messageMap("tutoringPage.tutorList.emptyOpenRequestsTutor", "generic")}
				</div>
			);
			if (emptyTutoringRequests == null) {
				// be careful when setting the value, it can lead to an infinite refresh
				props.refreshOpenRequestCount("0");
			}
		}
		else {
			setEmptyTutoringRequests(
				<div className="empty-list-container">
					<div className="empty-list">
						{`${messageMap("tutoringPage.tutorList.signUpFirstTutoringRequests", "generic")} `}
						<button className="sign-up-button"
							onClick={e => askUserToSignUpFirst(props.setModal, props.setModal1)}>
							{messageMap("link.text", "button")}
						</button>
					</div>
				</div>
			);
		}
	}

	return (
		<div className={`tutoring-job-board ${filterDom === null && "full-mini-page"}`}>
			<Helmet>
				<title>{messageMap("tutoringRequests.title", "headerTag")}</title>
				<meta name="description" content={messageMap("tutoringRequests.description", "headerTag")}></meta>
			</Helmet>

			<div className="filter-container">
				{filterDom}
			</div>

			<div className="request-group">
				<div className="open-sessions">
					{/* TODO: include logic to check the tutor's availability to see if the tutor can apply to the request */}
					<RequestList intendedUser={TUTOR_USER} listType={OPEN_STATE} emptyListHandler={indicateEmptyList}
						setModal={props.setModal} setModal1={props.setModal1} setAlert={props.setAlert} />
					{emptyTutoringRequests}
				</div>
			</div>
		</div>
	);
}

SessionsJobBoard.propTypes = {
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	refreshOpenRequestCount: PropTypes.func.isRequired,

	// redux props
	ownerId: PropTypes.string
};

export default connect(
	account
)(SessionsJobBoard);

