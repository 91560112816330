import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import Arithmetic from "pages/Classes/Practice/components/Math/Arithmetic";

import thumbnailAsset from "assets/topicArticles/Math/Level 01 Math/III- Numbers/thumbnailAddition.jpg";


export const singleDigitAdditionArticle = {
	id: "III: Numbers_B. Single Digit Addition with Pictures and Numbers",
	title: messageMap("singleDigitAddition.article.title", "math"),
	description: messageMap("singleDigitAddition.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("singleDigitAddition.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1",
		"text3", "html2"
	],
	datePublished: "2024-06-05",
	dateModified: "2024-06-05"
};

export const singleDigitAdditionArticleTexts = {
	text1: {
		text: messageMap("singleDigitAddition.singleDigitAdditionArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("singleDigitAddition.singleDigitAdditionArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("singleDigitAddition.singleDigitAdditionArticleTexts.text3", "math"),
		type: DESCRIPTION
	}
};

export const singleDigitAdditionArticleHTMLs = {
	html1: (
		<Arithmetic key="static" showExpressionSolution={true} usePictures={true} />
	),
	html2: (
		<Arithmetic key="dynamic" showExpressionSolution={true} usePictures={true} countable={true} />
	)
};


