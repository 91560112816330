import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from "prop-types";

import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";

import messageMap from "Utilities/MessageMaps";
import Spinner from "templates/Spinner";

import { getApplicationModeAPI } from "apis/controllers/ApplicationModeController";
import { createPaymentIntentAPI } from "apis/controllers/transactions/PaymentsController";


export default function StripeCheckoutForm(props) {

	const [renderCustomCheckout, setRenderCustomCheckout] = useState();

	useEffect(() => {
		getApplicationModeAPI(mode => {
			if (props.clientSecret != null && props.returnUrl) {
				renderActualCheckoutStripeForm(mode, props.clientSecret, props.returnUrl);
			}
			else {
				const payload = {
					ownerId: props.ownerId,
					paymentAmount: props.price,
					quantityPurchased: props.quantity,
					paymentDescription: props.paymentDescription,
					paymentPurpose: props.paymentPurpose,
					sessionId: props.sessionId
				};
	
				createPaymentIntentAPI(payload, resp => {
					renderActualCheckoutStripeForm(mode, resp.clientSecret, resp.baseUrl);
				});
			}
		});
	}, [props.price, props.quantity]);

	function renderActualCheckoutStripeForm(mode, clientSecret, baseUrl) {
		const options = {
			clientSecret: clientSecret,
			loader: "always",
			// reference: https://docs.stripe.com/elements/appearance-api
			appearance: {
				theme: "stripe",
				disableAnimations: false
			}
		};

		setRenderCustomCheckout(
			<Elements stripe={loadStripe(props.stripePubKey)} options={options}>
				<ActualCheckoutStripeForm mode={mode}
					confirmationRedirection={baseUrl} paymentPurpose={props.paymentPurpose}
					clientSecret={clientSecret} ownerId={props.ownerId}
					tutorId={props.tutorId} sessionId={props.sessionId} />
			</Elements>
		);
	}

	return (
		// need this div to help record 'pay' actions from users
		<Fragment>
			{renderCustomCheckout}
		</Fragment>
	);
}

function ActualCheckoutStripeForm(props) {

	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [paymentInfo, setPaymentInfo] = useState();

	// payment tests: https://docs.stripe.com/testing#cards
	async function handleSubmit(e) {
		e.preventDefault();

		setIsLoading(true);

		// if stripe hasn't loaded, return
		if (!stripe || !elements) {
			return null;
		}

		async function confirmPaymentAsync() {
			try {
				const baseUrl = props.mode === "dev" ? "http://localhost:3000/payment-confirmation" : "https://exerinstitute.com/payment-confirmation";
				let returnUrl = baseUrl + `?method=${paymentInfo.value.type}&ownerId=${props.ownerId}&paymentPurpose=${props.paymentPurpose}`;
				if (props.tutorId != null && props.sessionId != null) {
					returnUrl = baseUrl + `?tutorId=${props.tutorId}&sessionId=${props.sessionId}&confirmationRedirection=${props.confirmationRedirection}&paymentPurpose=${props.paymentPurpose}`;
				}

				const { error } = await stripe.confirmPayment({
					elements,
					confirmParams: {
						return_url: returnUrl
					}
				});
				return error;
			}
			catch (error) {
				console.error("Error confirming payment: ", error);
				return error;
			}
		}

		const error = await confirmPaymentAsync();

		// Triggers if there is an immediate error when confirming the payment. 
		// Otherwise, your customer will be redirected to your `return_url`. For 
		// some payment methods like iDEAL, your customer will be redirected to 
		// an intermediate site first to authorize the payment, then redirected 
		// to the `return_url`.
		if (error && Object.keys(error).length) {
			setMessage(error.message);
		}

		setIsLoading(false);
	}

	function paymentElementReady() {
		setIsLoading(false);
	}

	function someChange(theChange) {
		setPaymentInfo(theChange);
	}

	return (
		<Fragment>
			{
				isLoading && <Spinner containerClass="pay-spinner" />
			}
			<form onSubmit={handleSubmit}>
				<PaymentElement onReady={paymentElementReady} onChange={someChange} />
				<button className="pay-button" disabled={isLoading}>
					<span id="button-text">
						{messageMap("checkout.pay", "button")}
					</span>
				</button>
				{
					message && <div className="payment-message">{message}</div>
				}
			</form>
		</Fragment>
	);
}

StripeCheckoutForm.defaultProps = {
	sessionId: null,
	paymentPurpose: "shinyNeurons"
};

StripeCheckoutForm.propTypes = {
	stripePubKey: PropTypes.string.isRequired,
	price: PropTypes.number.isRequired,
	quantity: PropTypes.number.isRequired,
	paymentDescription: PropTypes.string.isRequired,
	ownerId: PropTypes.string.isRequired,

	// props that will only get passed to StripeCheckoutForm if a user's saved payment method gets declined
	returnUrl: PropTypes.string,
	clientSecret: PropTypes.string,
	tutorId: PropTypes.string,
	sessionId: PropTypes.string,
	paymentPurpose: PropTypes.string
};

ActualCheckoutStripeForm.propTypes = {
	mode: PropTypes.string.isRequired,
	confirmationRedirection: PropTypes.string.isRequired,
	paymentPurpose: PropTypes.string.isRequired,
	clientSecret: PropTypes.string.isRequired,
	ownerId: PropTypes.string.isRequired,

	tutorId: PropTypes.string,
	sessionId: PropTypes.string
}
