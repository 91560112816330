export const level01MathMessageMap = {
	naming2DShapes: {
		twoDShapesToHintsMap: {
			triangle: "What is the name of a shape that has three sides and three angles? The word 'tri' is often used to mean three.",
			circle: "What is the name of a shape that has no corner and edges and has smooth curves?",
			semicircle: "What is this shape a half of? The word 'semi' means half.",
			square: "What is the name of a shape that has 4 sides and 4 angles?",
			rectangle: "What is the name of a shape that has 4 sides, with each pair having equal lengths, but the two pairs have unequal lengths?",
			rhombus: "What is the name of a shape that has 4 equal length sides, but has 2 small angles and 2 big angles?",
			trapezoid: "What is the name of a shape that has 4 sides, where 2 opposite lines never touch and have unequal lengths?",
			pentagon: "What is the name of a shape that has 5 equal sides? The word 'penta' means five.",
			hexagon: "What is the name of a shape that has 6 equal sides? The word 'hexa' means six.",
			heptagon: "What is the name of a shape that has 7 equal sides? The word 'hepta' means seven.",
			octagon: "What is the name of a shape that has 8 equal sides? The word 'octa' means eight.",
			nonagon: "What is the name of a shape that has 9 equal sides? The word 'nona' means nine.",
			decagon: "What is the name of a shape that has 10 equal sides? The word 'deca' means ten."
		},
		twoDShapesToImgAltsMap: {
			triangle: "Two-dimensional shape with 3 sides.",
			circle: "Two-dimensional shape with no sides.",
			semicircle: "Two-dimensional shape that is half a circle.",
			square: "Two-dimensional shape that has 4 equal length sides.",
			rectangle: "Two-dimensional shape that has 4 sides, with each pair having equal lengths, but the two pairs have unequal lengths.",
			rhombus: "Two-dimensional shape that has 4 equal length sides, but has 2 acute angles and 2 obtuse angles.",
			trapezoid: "Two-dimensional shape that has 4 sides, where 2 opposite parallel lines have unequal lengths.",
			pentagon: "Two-dimensional shape with 5 equal sides.",
			hexagon: "Two-dimensional shape with 6 equal sides.",
			heptagon: "Two-dimensional shape with 7 equal sides.",
			octagon: "Two-dimensional shape with 8 equal sides.",
			nonagon: "Two-dimensional shape with 9 equal sides.",
			decagon: "Two-dimensional shape with 10 equal sides."
		},
		edgeCountToShapeTip: {
			3: "'Tri-' means three.",
			4: "'Square' is from the Latin word 'quadra' which means four.",
			5: "'Penta-' means five.",
			6: "'Hexa-' means six.",
			7: "'Hepta-' means seven.",
			8: "'Octa-' means eight.",
			9: "'Nona-' means nine.",
			10: "'Deca-' means ten."
		},
		naming2DShapesArticleTexts: {
			text1: "What are 2D shapes?",
			text2: "Shapes are defined by their structures. 2D shapes have sides and corners. A shape's side is one straight line and a corner is where lines meet.",
			text3: "Regular 2D Shapes",
			text4: "Irregular 2D Shapes",
			text5: "Notice how a regular shape's name depends on the number of sides the shape has and an irregular shape's name doesn't."
		},
		naming2DShapesArticleHTMLs: {
			circle: "A circle has 1 big curve around its shape, but it has no corners.",
			semicircle: "A semicircle that is half a circle.",
			rectangle: "A rectangle has 4 sides, with each pair having equal lengths, but the two pairs have unequal lengths.",
			rhombus: "A rhombus has 4 equal length sides, but has 2 acute angles and 2 obtuse angles.",
			trapezoid: "A trapezoid has 4 sides, where 2 opposite parallel lines have unequal lengths."
		},
		article: {
			title: "Naming 2D Shapes",
			description: "Learn what defines a 2D shape and how its name relates to its properties.",
			thumbnailAlt: "Picture of 2d shapes"
		}
	},
	naming3DShapes: {
		threeDShapesToHintsMap: {
			cube: "What is the name of a 3D figure that has 6 square faces, 12 edges, and 8 vertices?",
			sphere: "What is the name of a 3D figure that is perfectly round in shape?",
			ellipsoid: "What is the name of a 3D figure that has a surface where all planar cross-sections are either ellipses or circles?",
			cone: "What is the name of a 3D figure that has a circular base and a point vertex at the top?",
			cylinder: "What is the name of a 3D figure that consists of two identical and parallel circular bases connected by a curved surface?",

			triangularPrism: "What is the name of a 3D figure that has 5 faces, 2 triangle bases, and 3 rectangular lateral faces?",
			squarePrism: "What is the name of a 3D figure that has 6 faces, 2 square bases, and 4 rectangular lateral faces?",
			rectangularPrism: "What is the name of a 3D figure that has 6 rectangular faces, 8 vertices, and 12 edges?",
			pentagonalPrism: "What is the name of a 3D figure that has 7 faces, 2 pentagonal bases, and 5 rectangular lateral faces?",
			hexagonalPrism: "What is the name of a 3D figure that has 8 faces, 2 hexagonal bases, and 6 rectangular lateral faces?",

			triangularPyramid: "What is the name of a 3D figure that has 4 faces, 1 triangular base, and 3 triangular lateral faces?",
			squarePyramid: "What is the name of a 3D figure that has 5 faces, 1 square base, and 4 triangular lateral faces?",
			pentagonalPyramid: "What is the name of a 3D figure that has 6 faces, 1 pentagonal base, and 5 triangular lateral faces?",
			hexagonalPyramid: "What is the name of a 3D figure that has 7 faces, 1 hexagonal base, and 6 6 triangular lateral faces?",

			torus: "What is the name of a 3D figure that has a shape of a doughnut or inner tube, with a hollow center?"
		},
		threeDShapesToImgAltsMap: {
			cube: "A 3D figure that has 6 square faces, 12 edges, and 8 vertices.",
			sphere: "A 3D figure that is perfectly round in shape.",
			ellipsoid: "A 3D figure that has a surface where all planar cross-sections are either ellipses or circles.",
			cone: "A 3D figure that has a circular base and a point vertex at the top.",
			cylinder: "A 3D figure that consists of two identical and parallel circular bases connected by a curved surface.",

			triangularPrism: "A 3D figure that has 5 faces, 2 triangle bases, and 3 rectangular lateral faces.",
			squarePrism: "A 3D figure that has 6 faces, 2 square bases, and 4 rectangular lateral faces.",
			rectangularPrism: "A 3D figure that has 6 rectangular faces, 8 vertices, and 12 edges.",
			pentagonalPrism: "A 3D figure that has 7 faces, 2 pentagonal bases, and 5 rectangular lateral faces.",
			hexagonalPrism: "A 3D figure that has 8 faces, 2 hexagonal bases, and 6 rectangular lateral faces.",

			triangularPyramid: "A 3D figure that has 4 faces, 1 triangular base, and 3 triangular lateral faces.",
			squarePyramid: "A 3D figure that has 5 faces, 1 square base, and 4 triangular lateral faces.",
			pentagonalPyramid: "A 3D figure that has 6 faces, 1 pentagonal base, and 5 triangular lateral faces.",
			hexagonalPyramid: "A 3D figure that has 7 faces, 1 hexagonal base, and 6 6 triangular lateral faces.",

			torus: "A 3D figure that has a shape of a doughnut or inner tube, with a hollow center."
		},
		prismBaseEdgeCountToShapeTip: {
			3: "The 'triangular' part of the word refers to the front and back faces. 'Prism' is the cube-like body.",
			4: "The 'square' part of the word refers to the front and back faces. 'Prism' is the cube-like body.",
			5: "The 'pentagonal' part of the word refers to the front and back faces. 'Prism' is the cube-like body.",
			6: "The 'hexagonal' part of the word refers to the front and back faces. 'Prism' is the cube-like body.",
		},
		pyramidBaseEdgeCountToShapeTip: {
			3: "The 'triangular' part of the word refers to the bottom faces. 'Pyramid' is the pyramid-like body.",
			4: "The 'square' part of the word refers to the bottom faces. 'Pyramid' is the pyramid-like body.",
			5: "The 'pentagonal' part of the word refers to the bottom faces. 'Pyramid' is the pyramid-like body.",
			6: "The 'hexagonal' part of the word refers to the bottom faces. 'Pyramid' is the pyramid-like body.",
		},
		naming3DShapesArticleTexts: {
			text1: "What are 3D shapes?",
			text2: `3D shapes have faces, edges, and vertices. A shape's face is shape's flat surface. 
				An edge is where two faces meet. A vertex is where multiple faces meet.`,
			text3: "Spheres",
			text4: "3D Shapes with Circle Faces",
			text5: "Prisms",
			text6: "Pyramids",
			text7: "Special 3D Shapes",
			text8: "Similar to regular 2D shapes, notice how the names for prisms and pyramids also depend on a shape's property, in this case its face edge count."
		},
		naming3DShapesArticleHTMLs: {
		sphere: "The sphere uses one circular face throughout its shape.",
			ellipsoid: "The ellipsoid uses an ellipse face to give its elongated shape.",
			cone: "The cone has a circle at its base that gives shape to its body.",
			cylinder: "The cylinder have two circles at its top and bottom faces that gives shape to its body.",
			cube: "The cube is a special type of prism that has the same face on every side.",
			rectangularPrism: "The 'rectangular' part of the word refers to the front and back faces. 'Prism' is the cube-like body.",
			torus: "Also called the doughnut, the torus is a special shape that has no edges and vertices, but has 1 face used throughout shape, like the sphere and ellipsoid."
		},
		article: {
			title: "Naming 3D Shapes",
			description: "Learn what defines a 3D shape and how its name relates to its properties.",
			thumbnailAlt: "Picture of 3d shapes",
		}
	},
	spatialRelations: {
		article: {
			title: "Spatial Relations: Up, Down, Left, Right, Forward, and Backward",
			description: "Learn how your position depends on the objects around you.",
			thumbnailAlt: "A white-colored arrow pointing left."
		},
		spatialRelationsArticleTexts: {
			text1: "What is a position?",
			text2: "A position is a place where someone or something is located relative to an object.",
			text3: "Horizontal positions",
			text4: "Vertical positions",
			text5: "Notice how the position depends on where the person is from the object."
		}
	},
	comparingQuantities: {
		article: {
			title: "Comparing Quantities Through Pictures",
			description: "Learn how to count and compare quantities.",
			thumbnailAlt: "Picture of numbers from 1 to 9."
		},
		comparingQuantitiesArticleTexts: {
			text1: "Why are numbers so important?",
			text2: `Numbers are used everywhere, from making phones and constructing buildings to calculating money
						 and building rockets that goes to space. Our society would not be here without numbers. 
						 So, it is important that everyone learns how to count.`,
			text3: "Change the number of basketballs and count them.",
			text4: "Notice that as you count higher and higher in numbers, some of the words repeat, especially after 20 (twenty).",
			text5: "What is a quantity and how do we compare them?",
			text6: `A quantity is the amount or number of something. We compare quantities by using phrases such as 
							'more than', 'less than' and 'equals to'. Play around with following quantities to learn more.`
		}
	},
	comparingSizes: {
		article: {
			title: "Comparing Sizes Through Pictures",
			description: "Learn how to compare objects with different sizes.",
			thumbnailAlt: "Different sizes of eggs."
		},
		comparingSizesArticleTexts: {
			text1: "How do you compare objects with different sizes?",
			text2: "Object sizes are compared using the words 'bigger' and 'smaller'. Change the following objects' sizes to see how they compare with each other."
		}
	},
	comparingLengths: {
		article: {
			title: "Comparing Lengths Through Pictures",
			description: "Learn how to compare objects width different lengths.",
			thumbnailAlt: "Pencils with different lengths."
		},
		comparingLengthsArticleTexts: {
			text1: "How do you compare objects with different dimensions?",
			text2: `A dimension is a measurement of an object's side and this side can be its length, width, or height.
					 Objects' dimensions are compared using the words 'shorter' and 'longer'. The following shows an object's length, width, and height.`,
			text3: "For now, we'll focus on comparing an object's length. Change the following objects' length to see how they compare with each other:"
		}
	},
	countingWithPicturesNNumbers: {
		article: {
			title: "Counting with Pictures and Numbers Review",
			description: "Review on how to count using pictures.",
			thumbnailAlt: "A close-up picture of an abacus."
		}
	},
	singleDigitAddition: {
		article: {
			title: "Single-Digit Addition with Pictures and Numbers",
			description: "Review on how to count using pictures and add them.",
			thumbnailAlt: "The math expression 1 + 1 = 3 on a blackboard."
		},
		singleDigitAdditionArticleTexts: {
			text1: "What is addition?",
			text2: `Addition is finding the total of two or more numbers. We find the total by adding the 
				numbers using the plus '+' sign. The following is how we use the plus sign:`,
			text3: "The above is a math expression. Play around with the following expression to see how they add up:"
		}
	},
	singleDigitSubtraction: {
		article: {
			title: "Single-Digit Subtraction with Pictures and Numbers",
			description: "Learn how to subtract numbers using pictures.",
			thumbnailAlt: "Yellow text showing minus 2."
		},
		singleDigitSubtractionArticleTexts: {
			text1: "What is subtraction?",
			text2: `Subtraction is finding the difference between two or more numbers. We find the difference by subtracting the 
				numbers using the minus '-' sign. The following is how we use the minus sign:`,
			text3: "The above is a math expression. Play around with the following expression to see how their difference:"
		}
	}
};