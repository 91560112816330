import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/physiology/thumbnailDeclarative.jpg";
import freeBodyAsset from "assets/topicArticles/LearningOptimizations/physiology/freeBody.png";
import derivationAsset from "assets/topicArticles/LearningOptimizations/physiology/derivation.png";
import hippocampusAsset from "assets/topicArticles/LearningOptimizations/physiology/hippocampus.png";
import neocortexAsset from "assets/topicArticles/LearningOptimizations/physiology/neocortex.png";
import declarativeSystemAsset from "assets/topicArticles/LearningOptimizations/physiology/declarativeSystem.svg";
import distinctConceptsAsset from "assets/topicArticles/LearningOptimizations/physiology/distinctConcepts.svg";
import chunkAsset from "assets/topicArticles/LearningOptimizations/physiology/chunk.svg";


export const physiologyDeclarativeSystemArticle = {
	id: "V. Physiology_4_0 How information travels in our brain: the declarative system (Part 1)",
	title: messageMap("declarativeSystem.article.title", "learningOptimization"),
	description: messageMap("declarativeSystem.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("declarativeSystem.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4", "text5",
		"text6", "text7", "text8", "text9",
		"text10", "text11", "text12", "html1", "text13", "html2", "text14",
		"text15", "text16",
		"text17", "text18", "html3", "text19", "html4", "text20",
		"text21", "text22", "html5", "text23", "text24", "text25", "text26",
		"text27", "text28", "text29",
		"text30", "text31", "html6", "text32", "html7", "text33",
		"text34", "text35",
		"text36", "text37", "text38", "text39", "text40"
	],
	datePublished: "2023-12-29",
	dateModified: "2024-06-26"
};

export const physiologyDeclarativeSystemArticleTexts = {
	text1: {
		text: messageMap("declarativeSystem.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("declarativeSystem.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("declarativeSystem.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("declarativeSystem.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("declarativeSystem.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("declarativeSystem.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text7: {
		text: messageMap("declarativeSystem.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("declarativeSystem.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("declarativeSystem.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("declarativeSystem.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text11: {
		text: messageMap("declarativeSystem.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("declarativeSystem.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("declarativeSystem.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("declarativeSystem.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("declarativeSystem.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text16: {
		text: messageMap("declarativeSystem.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("declarativeSystem.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text18: {
		text: messageMap("declarativeSystem.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("declarativeSystem.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("declarativeSystem.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("declarativeSystem.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text22: {
		text: messageMap("declarativeSystem.texts.p16", "learningOptimization"),
		type: DESCRIPTION
	},
	text23: {
		text: messageMap("declarativeSystem.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text24: {
		text: messageMap("declarativeSystem.texts.p18", "learningOptimization"),
		type: DESCRIPTION
	},
	text25: {
		text: messageMap("declarativeSystem.texts.p19", "learningOptimization"),
		type: DESCRIPTION
	},
	text26: {
		text: messageMap("declarativeSystem.texts.p20", "learningOptimization"),
		type: DESCRIPTION
	},
	text27: {
		text: messageMap("declarativeSystem.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text28: {
		text: messageMap("declarativeSystem.texts.p21", "learningOptimization"),
		type: DESCRIPTION
	},
	text29: {
		text: messageMap("declarativeSystem.texts.p22", "learningOptimization"),
		type: DESCRIPTION
	},
	text30: {
		text: messageMap("declarativeSystem.texts.header8", "learningOptimization"),
		type: SUB_HEADER
	},
	text31: {
		text: messageMap("declarativeSystem.texts.p23", "learningOptimization"),
		type: DESCRIPTION
	},
	text32: {
		text: messageMap("declarativeSystem.texts.p24", "learningOptimization"),
		type: DESCRIPTION
	},
	text33: {
		text: messageMap("declarativeSystem.texts.p25", "learningOptimization"),
		type: DESCRIPTION
	},
	text34: {
		text: messageMap("declarativeSystem.texts.header9", "learningOptimization"),
		type: SUB_HEADER
	},
	text35: {
		text: messageMap("declarativeSystem.texts.p26", "learningOptimization"),
		type: DESCRIPTION
	},
	text36: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text37: {
		text: messageMap("declarativeSystem.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text38: {
		text: messageMap("declarativeSystem.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text39: {
		text: messageMap("declarativeSystem.sources.s3", "learningOptimization"),
		type: SOURCE
	},
	text40: {
		text: messageMap("declarativeSystem.sources.s4", "learningOptimization"),
		type: SOURCE
	}
};

export const physiologyDeclarativeSystemArticleHTMLs = {
	html1: (
		<img key="freeBodyAsset" src={freeBodyAsset} alt="physics free body diagram of a block being pulled" style={{width: "-webkit-fill-available"}} />
	),
	html2: (
		<img key="derivationAsset" src={derivationAsset} alt="derivation of f=ma formula" style={{width: "350px"}} />
	),
	html3: (
		<img key="hippocampusAsset" src={hippocampusAsset} alt="black and white sketch of brain with hippocampus colored" style={{width: "350px"}} />
	),
	html4: (
		<img key="neocortexAsset" src={neocortexAsset} alt="neocortex region inside the cortex" style={{width: "350px"}} />
	),
	html5: (
		<img key="declarativeSystemAsset" src={declarativeSystemAsset} alt="components of declarative system, containing working memory, hippocampus, and long-term memory" style={{width: "-webkit-fill-available"}} />
	),
	html6: (
		<img key="distinctConceptsAsset" src={distinctConceptsAsset} alt="three separate blocks with the labels formula, application, and derivation respectively" style={{width: "-webkit-fill-available"}} />
	),
	html7: (
		<img key="chunkAsset" src={chunkAsset} alt="the same three blocks earlier but now interconnected" style={{width: "-webkit-fill-available"}} />
	)
};

