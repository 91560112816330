/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";

const BASE = "/services/classes";

const LIST = BASE + "/list?subject=${subject}";
const LIST_ALL = BASE + "/list";
export const DETAILS = BASE + "/details/${classId}";
export const WAS_BOUGHT = BASE + "/wasBought/${classId}";


/**
 * @param {Object} pathVariables  = {
 * 	subject: String
 * }
 */
export function listAPI(pathVariables, callback) {
	const api = pathVariables != null ? $replace(LIST, pathVariables) : LIST_ALL;
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 */
export function detailsAPI(pathVariables, callback) {
	const api = $replace(DETAILS, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	classId: String
 * }
 * @param {String} payload = ownerId
 */
export function wasBoughtAPI(pathVariables, payload, callback) {
	const api = $replace(WAS_BOUGHT, pathVariables);
	request(api, POST, callback);
}