/* eslint-disable no-template-curly-in-string */
import request, { POST } from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";

const BASE = "/services/video/bookmark";

const SAVE_BOOKMARK = BASE + "/save/${videoId}";
const GET_BOOKMARK = BASE + "/get/${videoId}";
const DELETE_BOOKMARK = BASE + "/delete/${videoId}";
const GET_BOOKMARK_LIST = BASE + "/get/list/${classId}";

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {String} payload = ownerId
 */
export function saveBookmarkAPI(pathVariables, payload, callback) {
	const api = $replace(SAVE_BOOKMARK, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {String} payload = ownerId
 */
export function checkIfBookmarkedAPI(pathVariables, payload, callback) {
	const api = $replace(GET_BOOKMARK, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {String} payload = ownerId
 */
export function deleteBookmarkAPI(pathVariables, payload, callback) {
	const api = $replace(DELETE_BOOKMARK, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 * @param {String} payload = ownerId
 */
export function getBookmarkListAPI(pathVariables, payload, callback) {
	const api = $replace(GET_BOOKMARK_LIST, pathVariables);
	request(api, POST, payload, callback);
}