import React, { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";

import Alert, { ERROR } from "templates/Alert";

import messageMap from "Utilities/MessageMaps";
import { checkHasExplicitWords } from "Utilities/Validators/ContentValidator";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";


function EditOneFieldValueModalContent(props) {

	const [newFirstValue, setNewFirstValue] = useState(props.fieldOneValue),
		[isNewFirstValueValid, setIsNewFirstValueValid] = useState(true);

	const updateFieldsButtonRef = useRef();

	function onChangeField(e, changeType) {
		const target = e.target,
			text = target.value;

		setNewFirstValue(text);

		if (text === "") {
			target.className = "page-field-input";
			return;
		}

		let isFirstValid = isNewFirstValueValid;

		if (changeType === props.fieldOneKey) {
			if (!checkHasExplicitWords(text)) {
				setIsNewFirstValueValid(true);
				isFirstValid = true;
				target.className = "page-field-input";
			}
			else {
				setIsNewFirstValueValid(false);
				isFirstValid = false;
				target.className = "page-field-input warning";
			}
		}

		updateFieldsButtonRef.current.className = isFirstValid ? "update-two-fields" : "update-two-fields not-allowed";
	}

	function checkBeforeUpdatingForm() {
		if (newFirstValue === props.fieldOneValue) {
			props.setAlert(
				<Alert closeHandler={closeAlert} type={ERROR} msg={messageMap(`account.${props.fieldOneKey}.sameAsOld`, "validation")} />
			);
		}
		else if (isNewFirstValueValid && updateFieldsButtonRef.current.className === "update-two-fields") {
			props.updateField(props.fieldOneKey, newFirstValue);
			
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	return (
		<Fragment>
			<div className="field-generic">
				{messageMap(`account.fields.modal.${props.newLabelKey1}`, "generic")}
			</div>
			<input placeholder={props.fieldOneValue} className="page-field-input"
				maxLength={50} type={props.inputType}
				onChange={e => onChangeField(e, props.fieldOneKey)} >
			</input>

			<button className="update-two-fields not-allowed"
				onClick={checkBeforeUpdatingForm} ref={updateFieldsButtonRef} >
				{messageMap(`account.${props.updateButtonKey}`, "button")}
			</button>
		</Fragment>
	);
}

EditOneFieldValueModalContent.defaultProps = {
	inputType: "text"
};

EditOneFieldValueModalContent.propTypes = {
	setModal: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,

	fieldOneValue: PropTypes.string,
	fieldOneKey: PropTypes.string,
	inputType: PropTypes.string,

	newLabelKey1: PropTypes.string,
	updateButtonKey: PropTypes.string.isRequired,
	updateField: PropTypes.func.isRequired
};

export default EditOneFieldValueModalContent;
