import mathAsset from "assets/images/knowledge/topics/math.jpg";
import englishAsset from "assets/images/knowledge/topics/english.png";
import historyAsset from "assets/images/knowledge/topics/history.png";
import economicsAsset from "assets/images/knowledge/topics/economics.png";
import psychologyAsset from "assets/images/knowledge/topics/psychology.png";
import artAsset from "assets/images/knowledge/topics/art.jpeg";
import languageAsset from "assets/images/knowledge/topics/language.jpeg";
import healthAsset from "assets/images/knowledge/topics/health.jpeg";
import scienceAsset from "assets/images/knowledge/topics/science.png";


const topicIDToImageAssetMap = {
	"Economics and Personal Finance": economicsAsset,
	"History": historyAsset,
	"Psychology": psychologyAsset,
	"English and Language Arts": englishAsset,
	"Art": artAsset,
	"Foreign Language": languageAsset,
	"Health and Fitness": healthAsset,
	"Science": scienceAsset,
	"Math": mathAsset
};

const topicIDToAvailabilityMap = {
	"Economics and Personal Finance": 0,
	"History": 0,
	"Psychology": 0,
	"English and Language Arts": 0,
	"Art": 0,
	"Foreign Language": 0,
	"Health and Fitness": 0,
	"Science": 0,
	"Math": 1
};

export function getTopicAsset(nodeId) {
	return topicIDToImageAssetMap[nodeId];
}

export function getTopicAvailability(nodeId) {
	return topicIDToAvailabilityMap[nodeId];
}
