import React, {useEffect, useState, useRef, Fragment} from "react";
import PropTypes from "prop-types";

import Dropdown from "templates/Dropdown";

import messageMap from "Utilities/MessageMaps";
import {
	teachableSubjects, topicsInSubjects, topicsDifficultyOrder
} from "pages/Profile/subPages/utilities/TutoringSubPageConstants";


function SubjectTopicDropdowns(props) {

	const [priceableTopicCeiling, setPriceableTopicCeiling] = useState(),
		[newLineDropdown, setNewLineDropdown] = useState();

	useEffect(() => {
		setSavedValues();
	}, []);

	function setSavedValues() {
		if (props.preSelectedTopic) {
			if (props.useNewLine) {
				const topicLabel = (
					<label className="page-field-label">
						{props.separateTopicLabel}
					</label>
				);

				setNewLineDropdown(
					<Fragment>
						{topicLabel}
						<div className="inline-elements">
							<Dropdown key={props.preSelectedSubject} dropdownItemClickHandler={e => savePriceableTopics(e, topicsInSubjects[props.preSelectedSubject], props.preSelectedSubject)}
								customContainerClass="tutoring-application no-right-margin" dropdownOptions={topicsInSubjects[props.preSelectedSubject]}
								customDropdownItemAttribute="topTopic" preselectedKey={props.preSelectedTopic}/>
						</div>
					</Fragment>
				);
			}
			else {
				setPriceableTopicCeiling(
					<Dropdown key={props.preSelectedTopic} customDropdownItemAttribute="topTopic" customContainerClass="tutoring-application no-right-margin"
						dropdownItemClickHandler={e => savePriceableTopics(e, topicsInSubjects[props.preSelectedSubject], props.preSelectedSubject)}
						dropdownOptions={topicsInSubjects[props.preSelectedSubject]}
						preselectedKey={props.preSelectedTopic} />
				);
			}
		}
	}

	// is only used when tutor is applying for the first time
	function savePriceableTopics(e, topicsInSubject, subjectName) {
		const topTopic = e.target.getAttribute("topTopic");
		const topTopicDifficulty = topicsDifficultyOrder[subjectName][topTopic];
		let teachableTopics = {};

		for (const [key, value] of Object.entries(topicsInSubject)) {
			// the 'key === topTopic' part is so that we don't include topics with the same difficulty
				if (topicsDifficultyOrder[subjectName][key] < topTopicDifficulty || key === topTopic) {
				teachableTopics[key] = value;
			}
		}

		saveChosenTopics(subjectName, topTopic, teachableTopics);
	}

	function saveChosenTopics(subjectName, topTopic, teachableTopics) {
		let updatedSubjectsToTeach = {
			[subjectName]: Object.keys(teachableTopics)
		};

		if (props.setSubjectsToTopics) {
			if (props.singleTopic) {
				updatedSubjectsToTeach = {
					[subjectName]: topTopic
				};
				props.setSubjectsToTopics(updatedSubjectsToTeach);
			}
			else {
				props.setSubjectsToTopics(updatedSubjectsToTeach);
			}
		}
	}

	function showAvailableTopics(e) {
		const subjectName = e.target.getAttribute("subjectKey");

		if (subjectName === "chooseSubject") {
			return;
		}

		let topicLabel;
		if (props.separateTopicLabel) {
			topicLabel = (
				<label className="page-field-label">
					{props.separateTopicLabel}
				</label>
			);
		}

		// pre-select default initial topic
		saveChosenTopics(subjectName, Object.keys(topicsInSubjects[subjectName])[0], topicsInSubjects[subjectName]);

		if (props.useNewLine) {
			setNewLineDropdown(
				<Fragment>
					{topicLabel}
					<div className="inline-elements">
						<Dropdown key={subjectName} dropdownItemClickHandler={e => savePriceableTopics(e, topicsInSubjects[subjectName], subjectName)}
							customContainerClass="tutoring-application no-right-margin" dropdownOptions={topicsInSubjects[subjectName]}
							customDropdownItemAttribute="topTopic"/>
					</div>
				</Fragment>
			);
		}
		else {
			setPriceableTopicCeiling(
				<Fragment>
					{topicLabel}
					<Dropdown key={subjectName} dropdownItemClickHandler={e => savePriceableTopics(e, topicsInSubjects[subjectName], subjectName)}
						customContainerClass="tutoring-application no-right-margin" dropdownOptions={topicsInSubjects[subjectName]}
						customDropdownItemAttribute="topTopic"/>
				</Fragment>
			);
		}
	}

	return (
		<Fragment>
			<div className="page-field">
				<label className="page-field-label">
					{props.defaultLabel}
				</label>
				<div className="inline-elements">
					<Dropdown customDropdownItemAttribute="subjectKey"
						customContainerClass={`tutoring-application ${props.disableSubjectDropdown && "not-allowed"} ${props.useNewLine && "no-right-margin"}`}
						dropdownOptions={teachableSubjects} useNewLine={true} dropdownItemClickHandler={showAvailableTopics}
						preselectedKey={props.preSelectedSubject} />
					{priceableTopicCeiling}
				</div>
			</div>
			<div className="page-field">
				{newLineDropdown}
			</div>
		</Fragment>
	);
}

SubjectTopicDropdowns.defaultProps = {
	preSelectedSubject: null,
	defaultLabel: (
		<Fragment>
			<div className="div-label">
				{messageMap("profilePage.tutoring.modal.application.topicSubject", "generic")}
			</div>
			*
		</Fragment>
	),
	useNewLine: false
};

SubjectTopicDropdowns.propTypes = {
	isModal: PropTypes.bool,
	setSubjectsToTopics: PropTypes.func,

	preSelectedTopic: PropTypes.string,
	preSelectedSubject: PropTypes.string,
	isApplication: PropTypes.bool.isRequired,
	disableSubjectDropdown: PropTypes.bool,

	defaultLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
	separateTopicLabel: PropTypes.string,
	useNewLine: PropTypes.bool,
	singleTopic: PropTypes.bool
};

export default SubjectTopicDropdowns;

