import React from 'react';

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import {
	KNOWLEDGE_NETWORK_IDS_TRIE_MAP, AUGMENTED_KNOWLEDGE_NETWORK_IDS_TRIE_MAP,
	ID_TO_OBJECT_KEYS_MAP_MAP,
} from "diagrams/utilities/NetworkGenerator";
import { GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK } from "diagrams/utilities/NetworkConstants";
import {searchWordInTrie, createListFromPrefixTree} from "Utilities/Algos";


export function getSearchSuggestions(initialChars, workbookInUse, onClickHandler, onMouseEnterHandler,
																		defaultTrie = KNOWLEDGE_NETWORK_IDS_TRIE_MAP[workbookInUse],
																		charSearchTrie = AUGMENTED_KNOWLEDGE_NETWORK_IDS_TRIE_MAP[workbookInUse],
																		idToObjectMap = ID_TO_OBJECT_KEYS_MAP_MAP[workbookInUse],
																		searchType = "videos", onlyAvailableResource) {
	let origResults;
	let useRunningWordMatches = true;
	if (initialChars === "") {
		origResults = searchWordInTrie(initialChars, defaultTrie);
	}
	else {
		useRunningWordMatches = false;
		origResults = searchWordInTrie(initialChars, charSearchTrie);
		// leaving for debugging
		// console.log("charSearchTrie: ", charSearchTrie);
		// console.log("origResults: ", origResults);
	}
	let wordMap = origResults[1];
	let dropdownList;
	if (initialChars === "") {
		dropdownList = createListFromPrefixTree(wordMap[1], false);
	}
	else {
		dropdownList = createListFromPrefixTree(wordMap[1], true);
	}

	const runningWordMatch = wordMap[0];
	let strToResultItemMap = {};
	let strToNodeItemMap = {};

	if (runningWordMatch && runningWordMatch.toUpperCase() === initialChars.toUpperCase()) {
		for (let i = 0; i < dropdownList.length; ++i) {
			let str = useRunningWordMatches ? runningWordMatch + dropdownList[i] : dropdownList[i];
			const cleanedStr = str.replace("\n", "");
			const nodeItem = idToObjectMap && idToObjectMap[cleanedStr] ? idToObjectMap[cleanedStr] : null;
			const isTopLevel = searchType === "workbook" && initialChars === "" ? ( (!str.includes("_") && ["school"].includes(nodeItem.type))
																									|| (["job"].includes(nodeItem.type))
																									|| (["subject"].includes(nodeItem.type)) )
																						: true;

			if ((onlyAvailableResource && nodeItem.practiceArgs && ["topic"].includes(searchType)) || !onlyAvailableResource) {
				let keyStr = str;
				let displayStr = str.split("_");
				displayStr = displayStr[displayStr.length - 1];
				const resultItem = (
					<div key={keyStr} className="dropdown-item-container"
							tabIndex="0" role="button"
							onClick={e => onClickHandler(keyStr, nodeItem)}
							onKeyPress={e => onKeyDown(e, onClickHandler, [keyStr, nodeItem])}
							onMouseEnter={e => {
								onMouseEnterHandler && onMouseEnterHandler(e, keyStr, "1")
								}}>
						<p className="dropdown-item" >
							{displayStr}
						</p>
						<p className="chevron-right"></p>
					</div>
				);
				if (!Object.keys(strToResultItemMap).includes(str) && 
							( ([GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK].includes(workbookInUse)
									&& nodeItem && isTopLevel && ["knowledge", "topic"].includes(searchType))
								||
								(searchType === "videos")
							)) {
	
					strToNodeItemMap[str] = nodeItem
					strToResultItemMap[str] = resultItem;
				}
			}
		}
	}

	return [strToResultItemMap, strToNodeItemMap];
}

export function getTooltipFromWorkbookAndNodeType(workbookName, nodeType, itemName, nodeResources) {
	let tooltipAction;
	let availabilityText;

	if ([JOBS_WORKBOOK, DEGREES_WORKBOOK, GRADE_SCHOOL_WORKBOOK].includes(workbookName)) {
		if (nodeType.includes("topic")) {
			if (!nodeResources) {
				availabilityText = messageMap("knowledgeTree.uploadMissingVideoTopic", "generic") + itemName + "'";
				tooltipAction = "upload";
			}
			else {
				console.log("nodeResources: ", nodeResources);
				availabilityText = messageMap("knowledgeTree.learn", "generic") + itemName + "'";
				tooltipAction = "learn";
			}
		}
	}

	return [tooltipAction, availabilityText];
}

export function getFeedbackFromWorkbookAndNodeType(workbookName, nodeType, itemName) {
	let feedback;
	let feedbackClassName = "missing-item hide";
	let feedbackType;

	if (workbookName === GRADE_SCHOOL_WORKBOOK) {
		if (nodeType.includes("root")) {
			feedback = messageMap("knowledgeTree.feedback.grades.root", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "subjects";
		}
		else if (nodeType.includes("module")) {
			feedback = messageMap("knowledgeTree.feedback.grades.module1", "generic") + itemName + messageMap("knowledgeTree.feedback.grades.module2", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "topics";
		}
		else if (nodeType.includes("subject")) {
			feedback = messageMap("knowledgeTree.feedback.grades.subtree1", "generic") + itemName + messageMap("knowledgeTree.feedback.grades.subtree2", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "classes";
		}
	}
	else if (workbookName === DEGREES_WORKBOOK) {
		if (nodeType.includes("root")) {
			feedback = messageMap("knowledgeTree.feedback.degrees.root", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "degrees";
		}
		else if (nodeType.includes("class")) {
			feedback = messageMap("knowledgeTree.feedback.degrees.class1", "generic") + itemName + messageMap("knowledgeTree.feedback.degrees.class2", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "topics";
		}
		else if (nodeType.includes("subtree") || nodeType.includes("degree")) {
			feedback = messageMap("knowledgeTree.feedback.degrees.subtree1", "generic") + itemName + messageMap("knowledgeTree.feedback.degrees.subtree2", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "classes";
		}
	}
	else if (workbookName === JOBS_WORKBOOK) {
		if (nodeType.includes("root")) {
			feedback = messageMap("knowledgeTree.feedback.jobs.root", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "jobs";
		}
		else if (nodeType === "profile") {
			feedback = messageMap("knowledgeTree.feedback.jobs.profile", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "profileItems";
		}
		else if (nodeType === "skills_knowledge") {
			feedback = messageMap("knowledgeTree.feedback.jobs.skillsKnowledge", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "skillsAndKnowledgeItems";
		}
		else if (nodeType === "skills_knowledge_subtree") {
			feedback = messageMap("knowledgeTree.feedback.jobs.skillsKnowledgeSubtree1", "generic") + itemName + messageMap("knowledgeTree.feedback.jobs.skillsKnowledgeSubtree2", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "skillsAndKnowledgeItemsSubtree";
		}
		if (nodeType === "interview") {
			feedback = messageMap("knowledgeTree.feedback.jobs.interview", "generic");
			feedbackClassName = "missing-item";
			feedbackType = "interviewItems";
		}
	}

	return [feedback, feedbackClassName, feedbackType];
}

export function getAssistanceFromWorkbookAndNodeType(workbookName, nodeType) {
	let assistance;

	if (workbookName === GRADE_SCHOOL_WORKBOOK) {
		if (nodeType.includes("subject")) {
			assistance = messageMap("knowledgeTree.assistance.grades.subject", "generic");
		}
		else if (nodeType.includes("class")) {
			assistance = messageMap("knowledgeTree.assistance.grades.class", "generic");
		}
		else if (nodeType.includes("topic")) {
			assistance = messageMap("knowledgeTree.assistance.grades.topic", "generic");
		}
	}
	else if (workbookName === DEGREES_WORKBOOK) {
		if (nodeType.includes("degree")) {
			assistance = messageMap("knowledgeTree.assistance.degrees.degree", "generic");
		}
		else if (nodeType.includes("class")) {
			assistance = messageMap("knowledgeTree.assistance.degrees.class", "generic");
		}
		else if (nodeType.includes("module")) {
			assistance = messageMap("knowledgeTree.assistance.degrees.module", "generic");
		}
	}
	else if (workbookName === JOBS_WORKBOOK) {
		if (nodeType.includes("profile")) {
			assistance = messageMap("knowledgeTree.assistance.jobs.profile", "generic");
		}
		else if (nodeType.includes("skills_knowledge_subtree")) {
			assistance = messageMap("knowledgeTree.assistance.jobs.skillsKnowledgeSubtree", "generic");
		}
		else if (nodeType.includes("skills_knowledge")) {
			assistance = messageMap("knowledgeTree.assistance.jobs.skillsKnowledge", "generic");
		}
		else if (nodeType.includes("interview")) {
			assistance = messageMap("knowledgeTree.assistance.jobs.interview", "generic");
		}
	}

	return assistance;
}


