import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";

/**
 * @param {String} title? // tooltips title
 * @param {Object} titleStyle? // custom title styling
 * @param {String} subheader? // tooltip subheader
 * @param {Object} subheaderStyle? // custom subheader style
 * @param {String} type? // if the tooltip is specifically for {password, username}
 * @param {String} ariaReference // id DOM reference for which the tooltip is being associated with
 * @param {Object} list? list = [{ // this is used for dynamically rendering <ul>s
 * 	key: string, // will be used as a validator reference
 * 	text: string
 * }]
 * @param {String} classStr? // choose among already specified tooltip positioning in _tooltip.scss. "tooltip-bottom-left" is the default
 * @param {Object} containerStyle? // custom container style
 * @param {Boolean} verticalAlign? // dynamically vertical align middle modal depending on content height
 * @param {Object} passValidProgress? // TODO: might be a bug
 */
export default function Tooltip(props) {
	const tooltipRef = useRef(),
				list = props.list && (
					<ul>
						{createList(props.list, props.type, props.passValidProgress)}
					</ul>
				),
				classStr = props.classStr ? props.classStr : "tooltip-bottom-left",
				subHeader = props.subheader && (<h2 style={props.subheaderStyle}>{props.subheader}</h2>);

	useEffect(() => {
		props.verticalAlign && (tooltipRef.current.style.marginTop = "-" + tooltipRef.current.clientHeight + "px");
	});

	return (
		<div className={classStr} ref={tooltipRef} style={props.containerStyle}
				role="tooltip" id={props.ariaReference}>
			<h1 style={props.titleStyle}>{props.title}</h1>
			{subHeader}
			{list}
		</div>
	);
}

Tooltip.propTypes = {
	titleStyle: PropTypes.object,
	subheaderStyle: PropTypes.object,
	containerStyle: PropTypes.object,

	verticalAlign: PropTypes.bool,

	title: PropTypes.string,
	subheader: PropTypes.string,
	classStr: PropTypes.string,
	type: PropTypes.string,

	ariaReference: PropTypes.string,

	list: PropTypes.arrayOf(PropTypes.object),

	// TODO: this might be a bug
	passValidProgress: PropTypes.object
};

function createList(list, type, passValidProgress) {
	let listElArr = [];

	for (var i = 0; i < list.length; ++i) {
		let dom = <li key={i}>{list[i].text}</li>;

		if (["password", "username"].includes(type)) {
			const pillType = passValidProgress && passValidProgress[list[i].key] ? "pill-success" : "pill";
			dom = (
				<li className="list-no-style" key={i}>
					<span className={pillType}></span>
					<span>{list[i].text}</span>
				</li>
			);
		}

		listElArr.push(dom);
	}

	return listElArr;
}