import messageMap from "Utilities/MessageMaps";

export const dayOfWeekToIntMap = {
	Sunday: 0,
	Monday: 1,
	Tuesday: 2,
	Wednesday: 3,
	Thursday: 4,
	Friday: 5,
	Saturday: 6
};

export const intToDayOfWeekMap = {
	0: messageMap("calendar.days.sunday", "generic"),
	1: messageMap("calendar.days.monday", "generic"),
	2: messageMap("calendar.days.tuesday", "generic"),
	3: messageMap("calendar.days.wednesday", "generic"),
	4: messageMap("calendar.days.thursday", "generic"),
	5: messageMap("calendar.days.friday", "generic"),
	6: messageMap("calendar.days.saturday", "generic")
};

export const monthMap = {
	0: messageMap("calendar.months.january", "generic"),
	1: messageMap("calendar.months.february", "generic"),
	2: messageMap("calendar.months.march", "generic"),
	3: messageMap("calendar.months.april", "generic"),
	4: messageMap("calendar.months.may", "generic"),
	5: messageMap("calendar.months.june", "generic"),
	6: messageMap("calendar.months.july", "generic"),
	7: messageMap("calendar.months.august", "generic"),
	8: messageMap("calendar.months.september", "generic"),
	9: messageMap("calendar.months.october", "generic"),
	10: messageMap("calendar.months.november", "generic"),
	11: messageMap("calendar.months.december", "generic")
};

export const FIFTEEN_MINUTES = 15;
export const THIRTY_MINUTES = 30;
export const FORTY_FIVE_MINUTES = 45;
export const SIXTY_MINUTES = 60;

export const timeSlotsMap = {
	[FIFTEEN_MINUTES]: `${FIFTEEN_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[THIRTY_MINUTES]: `${THIRTY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[FORTY_FIVE_MINUTES]: `${FORTY_FIVE_MINUTES} ${messageMap("calendar.minSlots", "generic")}`,
	[SIXTY_MINUTES]: `${SIXTY_MINUTES} ${messageMap("calendar.minSlots", "generic")}`
};

// for getting user's offset and converting to UTC (same as GTM)
export function getTimezoneOffset() {
	function calc(n) {
		return (n < 10 ? '0' : '') + n;
	}

	let offset = new Date().getTimezoneOffset();
	const sign = offset < 0? '+' : '-';
	offset = Math.abs(offset);

	return "GMT" + sign + calc(offset / 60 | 0) + ":" + calc(offset % 60);
}
