import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import basketballAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/basketball.svg";


function SizableObjects(props) {

	const [objectSize, setObjectSize] = useState(48);


	function changeObjectSize(increaseOrDecrease) {
		let newSize;

		if (increaseOrDecrease === "increase") {
			newSize = objectSize + 24;
			setObjectSize(newSize);
		}
		else if (increaseOrDecrease === "decrease" && objectSize !== 24) {
			newSize = objectSize - 24;
			setObjectSize(newSize);
		}

		props.changeHandler && props.changeHandler(newSize, props.position);
	}

	return (
		<div className="sizable-objects">
			<div className="object">
				<img src={props.img ? props.img : basketballAsset} alt={props.imgAlt ? props.imgAlt : "Basketball"} style={{width: `${objectSize}px`}}></img>
			</div>

			<div className="changeable-size-container">
				<div className="change-text">
					Increase and decrease object
					{
						props.sizing ? " length:" : " size:"
					}
				</div>
				<div className="change-object-size-container">
					<button className="chevron-up"
						onClick={e => changeObjectSize("increase")}></button>
					<button className="chevron-down"
						onClick={e => changeObjectSize("decrease")}></button>
				</div>
			</div>
		</div>
	);
}

SizableObjects.propTypes = {
	changeHandler: PropTypes.func.isRequired,
	position: PropTypes.string,
	img: PropTypes.string,
	sizing: PropTypes.string
};

export default SizableObjects;

