import React, { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { passwordValidator } from "Utilities/Validators/InputValidators";
import { onKeyDown } from "Utilities/Accessibility";
import { MODAL_CLOSE_TIMEOUT } from "Utilities/Constants/TimeoutConstants";

import hidePassAsset from "assets/icons/navigation/hide_pass.svg";
import revealPassAsset from "assets/icons/navigation/reveal_pass.svg";


function EditPasswordModalContent(props) {

	const [newPassword, setNewPassword] = useState(),
		[passwordConfirm, setPasswordConfirm] = useState(),
		[isNewPasswordValid, setIsNewPasswordValid] = useState(true),
		[isPasswordConfirmValid, setIsPasswordConfirmValid] = useState(false);

	const updatePasswordButtonRef = useRef(),
		passwordInputRef = useRef(),
		passwordInputConfirmRef = useRef(),
		passwordRevealIconRef = useRef(),
		passwordLockIconRef = useRef(),
		passwordConfirmLockIconRef = useRef(),
		passwordConfirmRevealIconRef = useRef();

	const onChangeMap = {
		password: setNewPassword,
		passwordConfirm: setPasswordConfirm
	};

	const isValidMap = {
		password: isNewPasswordValid,
		passwordConfirm: isPasswordConfirmValid
	};
	const isValidSetterMap = {
		password: setIsNewPasswordValid,
		passwordConfirm: setIsPasswordConfirmValid
	};

	function onChangePassword(e, changeType) {
		const target = e.target,
			text = target.value;

		onChangeMap[changeType](text);

		if (text === "") {
			target.className = "page-field-input";
			return;
		}

		let isPasswordValid = isValidMap["password"],
			isConfirmPasswordValid = isValidMap["passwordConfirm"];
		let passwordValidationResults = passwordValidator(text);
		if (changeType === "password") {
			passwordInputConfirmRef.current.value = "";

			if (passwordValidationResults.passed) {
				isValidSetterMap[changeType](true);
				isPasswordValid = true;
				target.className = "page-field-input";
				passwordInputConfirmRef.current.className = "page-field-input";
			}
			else {
				isValidSetterMap[changeType](false);
				isPasswordValid = false;
				target.className = "page-field-input warning";
				passwordInputConfirmRef.current.className = "page-field-input not-allowed";
			}
		}
		else if (changeType === "passwordConfirm") {
			if (text === newPassword) {
				isValidSetterMap[changeType](true);
				isConfirmPasswordValid = true;
				target.className = "page-field-input";
			}
			else {
				isValidSetterMap[changeType](false);
				isConfirmPasswordValid = false;
				target.className = "page-field-input warning";
			}
		}

		updatePasswordButtonRef.current.className = isPasswordValid && isConfirmPasswordValid ? "update-password" : "update-password not-allowed";
	}

	function togglePasswordDisplay(inputRef, iconToHideRef, iconToShowRef) {
		if (inputRef.current.getAttribute("type") === "password") {
			inputRef.current.setAttribute("type", "text");
		}
		else {
			inputRef.current.setAttribute("type", "password");
		}

		iconToHideRef.current.className = "password-icon hide";
		iconToShowRef.current.className = "password-icon";
	}

	function checkBeforeUpdatingForm() {
		if (isNewPasswordValid && isPasswordConfirmValid && updatePasswordButtonRef.current.className === "update-password") {
			props.updateField("password", newPassword);
			
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	return (
		<Fragment>
			<div className="field-requirements">
				{messageMap("account.password.text", "validation")}
			</div>

			<div className="field-name">
				{messageMap("account.fields.modal.newPassword", "generic")}
			</div>
			<input type="password" placeholder="**************" className="page-field-input"
				onChange={e => onChangePassword(e, "password")}
				ref={passwordInputRef}>
			</input>
			<img className="password-icon" src={hidePassAsset} alt={messageMap("account.passwordLock", "image")}
				role="button" tabIndex={0} ref={passwordLockIconRef}
				onClick={(e) => togglePasswordDisplay(passwordInputRef, passwordLockIconRef, passwordRevealIconRef)}
				onKeyPress={(e) => onKeyDown(e, togglePasswordDisplay, [passwordInputRef, passwordLockIconRef, passwordRevealIconRef])}>
			</img>
			<img className="password-icon hide" src={revealPassAsset} alt={messageMap("account.passwordLock", "image")}
				role="button" tabIndex={0} ref={passwordRevealIconRef}
				onClick={(e) => togglePasswordDisplay(passwordInputRef, passwordRevealIconRef, passwordLockIconRef)}
				onKeyPress={(e) => onKeyDown(e, togglePasswordDisplay, [passwordInputRef, passwordRevealIconRef, passwordLockIconRef])}>
			</img>

			<div className="field-name">
				{messageMap("account.fields.modal.reTypePassword", "generic")}
			</div>
			<input type="password" className="page-field-input not-allowed"
				onChange={e => onChangePassword(e, "passwordConfirm")}
				ref={passwordInputConfirmRef}>
			</input>
			<img className="password-icon" src={hidePassAsset} alt={messageMap("account.passwordLock", "image")}
				role="button" tabIndex={0} ref={passwordConfirmLockIconRef}
				onClick={(e) => togglePasswordDisplay(passwordInputConfirmRef, passwordConfirmLockIconRef, passwordConfirmRevealIconRef)}
				onKeyPress={(e) => onKeyDown(e, togglePasswordDisplay, [passwordInputConfirmRef, passwordConfirmLockIconRef, passwordConfirmRevealIconRef])}>
			</img>
			<img className="password-icon hide" src={revealPassAsset} alt={messageMap("account.passwordLock", "image")}
				role="button" tabIndex={0} ref={passwordConfirmRevealIconRef}
				onClick={(e) => togglePasswordDisplay(passwordInputConfirmRef, passwordConfirmRevealIconRef, passwordConfirmLockIconRef)}
				onKeyPress={(e) => onKeyDown(e, togglePasswordDisplay, [passwordInputConfirmRef, passwordConfirmRevealIconRef, passwordConfirmLockIconRef])}>
			</img>

			<button className="update-password not-allowed"
				onClick={checkBeforeUpdatingForm} ref={updatePasswordButtonRef} >
				{messageMap("account.changePassword", "button")}
			</button>
		</Fragment>
	);
}

EditPasswordModalContent.propTypes = {
	setModal: PropTypes.func.isRequired,
	updateField: PropTypes.func.isRequired
};

export default EditPasswordModalContent;

