import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

import { onKeyDown } from "Utilities/Accessibility";

import {rollNSidedDice, shuffle} from "pages/Classes/Practice/utilities/MathUtilities";

import {CORRECT, WRONG} from "pages/Classes/Practice/constants/PracticeConstants";

/**
 * @param {String} matchId // parent prop for re-rendering
 * @param {Object} answer // to determine if user's answer is right/wrong
 * @param {Function} setAnswerCorrectness // parent function used to check if user's answer is right/wrong
 * @param {Function} setClearResponseFunc // to clear user's answer
 * @param {Array} possibleOptions // for creating user options
 */
function MultipleChoice(props) {

	const [choices, setChoices] = useState();

	// DOM refs
	const mc1Ref = useRef(),
		mc2Ref = useRef(),
		mc3Ref = useRef(),
		mc4Ref = useRef(),
		keysToMCOptionRef = useRef({});

	const mcInputRefMap = {
		0: mc1Ref,
		1: mc2Ref,
		2: mc3Ref,
		3: mc4Ref
	};

	useEffect(() => {
		attachParentProps();
		randomizePicLabel();
	}, [props.matchId]);

	function attachParentProps() {
		props.setClearResponseFunc(clearResponse);
	}

	function clearResponse() {
		Object.keys(mcInputRefMap).forEach(key => {
			mcInputRefMap[key].current.checked = false;
		});
		keysToMCOptionRef.current = {};

		randomizePicLabel();
	}

	function randomizePicLabel() {
		let options = [props.answer.current.toLowerCase()];
		let possibleOptionsCopy = props.possibleOptions.map(option => option.toLowerCase());
		possibleOptionsCopy.splice(possibleOptionsCopy.indexOf(props.answer.current.toLowerCase()), 1);

		while (options.length !== 4) {
			const chosenOption = possibleOptionsCopy[rollNSidedDice(possibleOptionsCopy.length)];
			possibleOptionsCopy.splice(possibleOptionsCopy.indexOf(chosenOption), 1);
			options.push(chosenOption.toLowerCase());
		}

		shuffle(options);

		let domOptions = [];
		options.forEach((option, index) => {
			domOptions.push(
				<div key={option} className="mc-option">
					<label htmlFor={option}>
						{option}
					</label>
					<input className="radio-input" ref={mcInputRefMap[index]}
									onClick={solveMultipleChoice}
									onKeyPress={e => onKeyDown(e, solveMultipleChoice, [e], true)}
									type="radio" id={option} name={option} value={option}></input>
				</div>
			);

			keysToMCOptionRef.current[option] = mcInputRefMap[index];
		});

		setChoices(domOptions);
	}

	function solveMultipleChoice(event) {
		const clickedChoice = event.target.getAttribute("name");

		if (clickedChoice === props.answer.current.toLowerCase()) {
			props.setAnswerCorrectness(CORRECT);
		}
		else {
			props.setAnswerCorrectness(WRONG);
		}

		Object.keys(keysToMCOptionRef.current).forEach(key => {
			if (key !== clickedChoice) {
				keysToMCOptionRef.current[key].current.checked = false;
			}
		});
	}

	return (
		<div className="multiple-choice-component">
			<div className="mc-container">
				{choices}
			</div>
		</div>
	);
}


MultipleChoice.propTypes = {
	matchId: PropTypes.string.isRequired,
	answer: PropTypes.object.isRequired,
	possibleOptions: PropTypes.array.isRequired,
	setAnswerCorrectness: PropTypes.func.isRequired,
	setClearResponseFunc: PropTypes.func.isRequired
};

export default MultipleChoice;
