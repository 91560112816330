/* eslint-disable no-template-curly-in-string */
// import {IP_VO} from "apis/models/IPs";

import request, { GET, POST } from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";

const crypto = require("crypto");

const BASE = "/services/session";

const UNBLOCK = BASE + "/unblock/ip";
const BLOCK_IP_POST = BASE + "/block/ip";
const BLOCK_IP_GET = BASE + "/block/ip?ip=${ip}";


/**
 * @param {Object} payload = {
 * 	ip: String
 * }
 */
export function unblockIpAPI(payload) {
	payload.ip = crypto.createHash("sha512").update(payload.ip).digest("hex");
	// modelValidator(IP_VO, payload) &&
	request(UNBLOCK, POST, payload);
}

/**
 * @param {Object} payload  = {
 * 	ip: String
 * }
 */
export function blockIpPostAPI(payload) {
	payload.ip = crypto.createHash("sha512").update(payload.ip).digest("hex");
	// modelValidator(IP_VO, payload) &&
	request(BLOCK_IP_POST, POST, payload);
}

/**
 * @param {Object} pathVariables = {
 * 	ip: String
 * }
 */
export function blockIpGetAPI(pathVariables, callback) {
	pathVariables.ip = crypto.createHash("sha512").update(pathVariables.ip).digest("hex");
	const api = $replace(BLOCK_IP_GET, pathVariables);
	request(api, GET, null, callback);
}


export async function getIPAPI(callback) {
	await fetch("https://ipv4.icanhazip.com/")
		.then(response => {
			return response.text();
		})
		.then(ipv6 => {
			callback(ipv6.replace("\n", ""));
	});
}