import React  from "react";
import PropTypes from "prop-types";


function ComparisonRow(props) {
	return (
		<div className="row-container">
			<div className="label-column">
				<div>
					{props.label}
				</div>
				<div className="sub-label">
					{props.subLabel}
				</div>
			</div>
			<div className="tutor-summary">
				{props.tutor1Contents}
			</div>
			<div className="tutor-summary">
				{props.tutor2Contents}
			</div>
		</div>
	);
}

ComparisonRow.propTypes = {
	label: PropTypes.string.isRequired,
	subLabel: PropTypes.string,
	tutor1Contents: PropTypes.any.isRequired,
	tutor2Contents: PropTypes.any.isRequired
};

export default ComparisonRow;


