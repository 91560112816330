import messageMap from "Utilities/MessageMaps";

// Profile
import accountAsset from "assets/icons/navigation/pageSubMenus/account/user.svg";
import tutoringAsset from "assets/icons/navigation/pageSubMenus/account/tutoring.svg";
import subscriptionAsset from "assets/icons/navigation/pageSubMenus/account/subscription.svg";
import groupsAsset from "assets/icons/navigation/pageSubMenus/account/groups.svg";
import financialsAsset from "assets/icons/navigation/pageSubMenus/account/financials.svg";
// Tutoring
import sessionsAsset from "assets/icons/navigation/pageSubMenus/tutoring/sessions.svg";
import requestsAsset from "assets/icons/navigation/pageSubMenus/tutoring/requests.svg";
import applicationsAsset from "assets/icons/navigation/pageSubMenus/tutoring/applications.svg";
import savedTutorsAsset from "assets/icons/navigation/pageSubMenus/tutoring/savedTutors.svg";
import tutorsListAsset from "assets/icons/navigation/pageSubMenus/tutoring/tutorsList.svg";
import messagesAsset from "assets/icons/navigation/pageSubMenus/tutoring/messages.svg";
import tutorFaqsAsset from "assets/icons/navigation/pageSubMenus/tutoring/FAQs.svg";
import tutorPoliciesAsset from "assets/icons/navigation/pageSubMenus/tutoring/tutorPolicies.svg";
import whyExerAsset from "assets/icons/navigation/pageSubMenus/tutoring/whyExer.svg";


// Profile
export const ACCOUNT = "account";
export const TUTORING = "tutoring";
export const SUBSCRIPTION = "subscription";
export const GROUPS = "groups";
export const FINANCIALS = "financials";
const PROFILE_MENU = {
	[ACCOUNT]: accountAsset,
	[TUTORING]: tutoringAsset,
	[SUBSCRIPTION]: subscriptionAsset,
	[GROUPS]: groupsAsset,
	[FINANCIALS]: financialsAsset
};
const PROFILE_MENU_ALTS = {
	[ACCOUNT]: messageMap("subPages.profile.account", "image"),
	[TUTORING]: messageMap("subPages.profile.tutoring", "image"),
	[SUBSCRIPTION]: messageMap("subPages.profile.subscription", "image"),
	[GROUPS]: messageMap("subPages.profile.groups", "image"),
	[FINANCIALS]: messageMap("subPages.profile.financials", "image")
};
const PROFILE_MENU_LABELS = {
	[ACCOUNT]: messageMap("profile.account", "subPageNavigation"),
	[TUTORING]: messageMap("profile.tutoring", "subPageNavigation"),
	[SUBSCRIPTION]: messageMap("profile.subscription", "subPageNavigation"),
	[GROUPS]: messageMap("profile.groups", "subPageNavigation"),
	[FINANCIALS]: messageMap("profile.financials", "subPageNavigation")
};

// Tutoring
export const SESSIONS = "sessions";
export const REQUESTS = "requests";
export const TUTOR_REQUESTS = "tutorRequests";
export const APPLICATIONS = "applications";
export const SAVED_TUTORS = "savedTutors";
export const TUTORS_LIST = "tutorsList";
export const MESSAGES = "messages";
export const TUTOR_FAQS = "tutorFAQs";
export const TUTOR_POLICIES = "tutorPolicies";
export const WHY_EXER = "whyExer";
const TUTORING_MENU = {
	[SESSIONS]: sessionsAsset,
	[REQUESTS]: requestsAsset,
	[TUTOR_REQUESTS]: requestsAsset,
	[APPLICATIONS]: applicationsAsset,
	[SAVED_TUTORS]: savedTutorsAsset,
	[TUTORS_LIST]: tutorsListAsset,
	[MESSAGES]: messagesAsset,
	[TUTOR_FAQS]: tutorFaqsAsset,
	[TUTOR_POLICIES]: tutorPoliciesAsset,
	[WHY_EXER]: whyExerAsset
};
const TUTORING_MENU_ALTS = {
	[SESSIONS]: messageMap("subPages.tutoring.sessions", "image"),
	[REQUESTS]: messageMap("subPages.tutoring.requests", "image"),
	[TUTOR_REQUESTS]: messageMap("subPages.tutoring.requests", "image"),
	[APPLICATIONS]: messageMap("subPages.tutoring.applications", "image"),
	[SAVED_TUTORS]: messageMap("subPages.tutoring.savedTutors", "image"),
	[TUTORS_LIST]: messageMap("subPages.tutoring.tutorsList", "image"),
	[MESSAGES]: messageMap("subPages.tutoring.messages", "image"),
	[TUTOR_FAQS]: messageMap("subPages.tutoring.tutorFAQs", "image"),
	[TUTOR_POLICIES]: messageMap("subPages.tutoring.tutorPolicies", "image"),
	[WHY_EXER]: messageMap("subPages.tutoring.whyExer", "image")
};
const TUTORING_MENU_LABELS = {
	[SESSIONS]: messageMap("tutoring.sessions", "subPageNavigation"),
	[REQUESTS]: messageMap("tutoring.requests", "subPageNavigation"),
	[TUTOR_REQUESTS]: messageMap("tutoring.tutorRequests", "subPageNavigation"),
	[APPLICATIONS]: messageMap("tutoring.applications", "subPageNavigation"),
	[SAVED_TUTORS]: messageMap("tutoring.savedTutors", "subPageNavigation"),
	[TUTORS_LIST]: messageMap("tutoring.tutorsList", "subPageNavigation"),
	[MESSAGES]: messageMap("tutoring.messages", "subPageNavigation"),
	[TUTOR_FAQS]: messageMap("tutoring.tutorFAQs", "subPageNavigation"),
	[TUTOR_POLICIES]: messageMap("tutoring.tutorPolicies", "subPageNavigation"),
	[WHY_EXER]: messageMap("tutoring.whyExer", "subPageNavigation")
};


//************** Exports ************//
export const PROFILE_PAGE_MENU = "profile";
export const TUTORING_PAGE_MENU = "tutoring";


export const SUB_MENU_TYPES_IMAGES = {
	profile: PROFILE_MENU,
	tutoring: TUTORING_MENU
};
export const SUB_MENU_TYPES_ALTS = {
	profile: PROFILE_MENU_ALTS,
	tutoring: TUTORING_MENU_ALTS
};
export const SUB_MENU_TYPES_LABELS = {
	profile: PROFILE_MENU_LABELS,
	tutoring: TUTORING_MENU_LABELS
};


