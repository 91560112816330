// DON'T REMOVE 'Fragment', it's being used in vanilla JS when rendering the SVG
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { setQuestPath, setCurrentTopic } from 'redux/actions/actionTypes';
import navigationSelector from "redux/selectors/navigationSelector";

import PropTypes from "prop-types";

import * as d3 from "d3";

import {
	NODES_MAP, LINKS_MAP,
	generateKnowledgeNetwork, getQuestTree
} from "diagrams/utilities/NetworkGenerator";
import { 
	GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK,
	WORKBOOK_TO_QUESTABLE_ROOT_NODES_MAP 
} from "diagrams/utilities/NetworkConstants";
import { dragStarted, dragged, dragEnded } from "diagrams/utilities/events/Drag";
import { checkHasExplicitWords } from "Utilities/Validators/ContentValidator.js";
import { binarySearch } from "Utilities/Algos";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import {
	getTooltipFromWorkbookAndNodeType, getFeedbackFromWorkbookAndNodeType,
	getAssistanceFromWorkbookAndNodeType
} from "pages/KnowledgeTree/KnowledgeTreeUtilities";
import {askUserToSignUpFirst} from "templates/customModals/utilities/SignUpLoginUtilities";
import { MODAL_CLOSE_TIMEOUT } from 'Utilities/Constants/TimeoutConstants';

import Dropdown from "templates/Dropdown";
import Alert, {ERROR, SUCCESS} from "templates/Alert";
import Modal from "templates/Modal";
import Spinner from "templates/Spinner";
import Search from "templates/Search";

import { reportMissingItemsFeedbackAPI } from "apis/controllers/customer/FeedbackController";
import { saveViewAPI, deleteViewAPI, getViewsAPI } from "apis/controllers/knowledge/KnowledgeViewController";
import { getAllKnowledgeMetadata } from "apis/controllers/knowledge/KnowledgeMetadataController";
import { getQuestProgressAPI } from "apis/controllers/person/PersonProgressController";

import locateAsset from "assets/icons/knowledge/locate.svg";
import treeAsset from "assets/icons/knowledge/tree.svg";
import depthAsset from "assets/icons/knowledge/depth.svg";
import menuAsset from "assets/icons/knowledge/menu.svg";
import plusAsset from "assets/icons/knowledge/plus.svg";
import minusAsset from "assets/icons/knowledge/minus.svg";
import chevronLeftAsset from "assets/icons/knowledge/chevronLeft.svg";
import resetAsset from "assets/icons/knowledge/reset.svg";


/**
 * If you've never worked with D3 and creating SVGs before, I suggest going through the following documentations.
 * D3 has the highest learning curve compared to other visualization tools, but it also provides the most flexibility
 * 1.) D3 tutorial: https://www.d3indepth.com/
 * 2.) SVG elements, properties, and styling: https://css-tricks.com/svg-properties-and-css/
 * 3.) More in-depth D3 documentations: https://d3js.org/what-is-d3
 */
function KnowledgeTree(props) {
	const history = useHistory();

	// DOM refs
	const svgRef = useRef(),
		zoomOutRef = useRef(),
		treeTypeDropdownRef = useRef(),
		savedViewsDropdownRef = useRef(),
		savedViewsButtonRef = useRef(),
		savedViewsToDeleteButtonRef = useRef(),
		inputDepthRef = useRef(),
		missingAnItemRef = useRef(""),
		missingItemRef = useRef(""),
		locateButtonRef = useRef(),
		inputViewNameRef = useRef(),
		prevViewButtonRef = useRef(),
		hiddenMenuRef = useRef(),
		shadedRegionRef = useRef(),
		// function refs
		closeHandlerPointerReferenceRef = useRef(),
		clearSetInputPointerReferenceRef = useRef(),
		// value refs
		currentExcelWorkbookRef = useRef("GradeSchool"),
		currentExcelSheetRef = useRef("GradeSchool"),
		rootNodeRef = useRef("root"),
		nodeIdToDetailsMapRef = useRef(),
		questsProgressRef = useRef(),
		// a stack of height 10
		prevRootNodeRef = useRef([]),
		zoomLevelRef = useRef(0),
		depthLevelRef = useRef(1),
		svgValueRef = useRef(),
		nodesListRef = useRef(),
		nodesCircleRef = useRef(),
		tooltipsRef = useRef(),
		zoomAndPanRef = useRef(),
		zoomScaleRef = useRef(),
		viewNameToObjectMapRef = useRef({});

	const [alert, setAlert] = useState(null),
		[darkBackground, setDarkBackGround] = useState(false),
		[domModal, setDomModal] = useState(""),
		[loginModal, setLoginModal] = useState(null),
		[savedViews, setSavedViews] = useState(null),
		[spinner, setSpinner] = useState("");

	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	useEffect(() => {
		fetchSavedViews();
		// fetch progress on any quests (this is where they left off, not percentage of completion)
		fetchQuestsProgressAndOrGenerateKnowledgeNetwork();
	}, []);

	function fetchSavedViews() {
		if (ownerId) {
			getViewsAPI(ownerId, resp => {
				let savedViewOptions = [];
				let viewNameToObjectMap = {};
				let dropdownOptionsLabels = {};

				Array.isArray(resp) && resp.forEach(knowledgeView => {
					const viewName = knowledgeView.viewName;

					savedViewOptions.push(
						<option key={viewName}>
							{viewName}
						</option>
					);
					viewNameToObjectMap[viewName] = knowledgeView;
					dropdownOptionsLabels[viewName] = viewName;
				});

				viewNameToObjectMapRef.current = viewNameToObjectMap;
				setSavedViews(
					<Dropdown customDropdownItemAttribute="keyname"
						selectedOptionParentRef={savedViewsDropdownRef} customContainerClass="knowledge-saved-views"
						dropdownOptions={dropdownOptionsLabels}/>
				);

				if (savedViewOptions.length === 0) {
					savedViewsButtonRef.current.disabled = true;
					savedViewsToDeleteButtonRef.current.disabled = true;
				}
				else {
					savedViewsButtonRef.current.disabled = false;
					savedViewsToDeleteButtonRef.current.disabled = false;
				}

				prevViewButtonRef.current.disabled = true;
			});
		}
	}

	function fetchQuestsProgressAndOrGenerateKnowledgeNetwork() {
		if (ownerId) {
			getQuestProgressAPI(ownerId, resp => {
				questsProgressRef.current = resp;

				generateKnowledgeNetwork("root", depthLevelRef.current,
					0, currentExcelWorkbookRef.current,
					"id", renderKnowledgeTree);
			});
		}
		else {
			generateKnowledgeNetwork("root", depthLevelRef.current,
				0, currentExcelWorkbookRef.current,
				"id", renderKnowledgeTree);
		}
	}

	// To learn what any of these code mean, go to d3indepth.com
	function renderKnowledgeTree(resolution) {
		// Specify the dimensions of the chart.
		const width = window.screen.width;
		const height = window.screen.height - 250;

		// Specify the color scale.
		const color = d3.scaleOrdinal(d3.schemeCategory10);

		// The force simulation mutates links and nodes, so create a copy
		// so that re-evaluating this cell produces the same result.
		const linksList = LINKS_MAP[currentExcelWorkbookRef.current].map(d => Object.create(d));
		nodesListRef.current = NODES_MAP[currentExcelWorkbookRef.current].map(d => Object.create(d));

		// Create a simulation with several forces.
		const repulsion = -500 + (resolution * 75) < 0 ? -500 + (resolution * 75) : -500;
		const simulation = d3.forceSimulation(nodesListRef.current)
			.force("link", d3.forceLink(linksList).id(d => d.id)
				// sets default distance between nodes
				.distance(500))
			.force("charge", d3.forceManyBody()
				// '-' increases node repulsion, '+' increases attraction
				.strength(repulsion))
			.force("center", d3.forceCenter(width / 2, height / 2).strength(1))
			.force("collision", d3.forceCollide((e, d) => {
				// nothing sophisticated behind this calculation; it's all based on trial-and-error
				return 40 + (e.id.length / 2) * 3;
			}))
			.on("tick", ticked)
			.on("end", () => {
				// setSpinner(null);
			});

		zoomAndPanRef.current = d3.zoom().scaleExtent([0, 5])
			.on("zoom", zoom);

		// Create the SVG container.
		svgValueRef.current = d3.select("svg")
			.attr("width", width)
			.attr("height", height)
			.attr("viewBox", [0, -350, width, height * 1.9])
			.attr("style", "max-width: 100%; height: 93vh;")
			.call(zoomAndPanRef.current);

		// const connectionTypes = ["dependency", "requirement", "elective"];
		const connectionTypes = ["requirement"];
		const connectionColorMap = {
			dependency: "#ffc107", // yellow
			requirement: "#dc3545", // red
			elective: "#28a745" // green
		};
		const connectionColorTypeMap = {
			dependency: "yellow",
			requirement: "red",
			elective: "green"
		};

		svgValueRef.current.append("defs").selectAll("marker")
			.data(connectionTypes)
			.join("marker")
			.attr("id", d => d)
			.attr("viewBox", "0 -5 10 10")
			.attr("refX", 115)
			.attr("refY", 0)
			.attr("markerWidth", 10)
			.attr("markerHeight", 10)
			.attr("orient", "auto-start-reverse")
			.append("path")
			.attr("fill", d => {
				return connectionColorMap[d];
			})
			.attr("d", 'M0,-5L10,0L0,5');

		// Draw a line for each link
		const links = svgValueRef.current.append("g")
			.attr("fill", "none")
			.attr("stroke-width", 2.5)
			.selectAll("path")
			.data(linksList)
			.join("path")
			.attr("stroke", d => color(d.group))
			.attr("marker-start", d => {
				// commenting this out for now, since the color reference isn't working
				// const relationshipType = NODES_MAP[currentExcelWorkbookRef.current][d.index] && NODES_MAP[currentExcelWorkbookRef.current][d.index].relationship ? NODES_MAP[currentExcelWorkbookRef.current][d.index].relationship : null;
				// return `url(#${relationshipType.toLowerCase()})`;
				return `url(#requirement)`;
			})
			.attr("d", d => {
				return `M${d.source.x},${d.source.y}L${d.target.x},${d.target.y}`;
			});

		// Draw a circle for each node
		nodesCircleRef.current = svgValueRef.current.append("g")
			.selectAll()
			.data(nodesListRef.current)
			.join("foreignObject")
			.attr("width", 1)
			.attr("height", 1)
			.html(d => {
				const curNode = NODES_MAP[currentExcelWorkbookRef.current][d.index];
				let colorHex = color(curNode.group);

				// for blending colors
				if (typeof curNode.group === "string" && curNode.group.includes("|")) {
					const groupMix = curNode.group.split("|");
					let groupColor = [];

					for (const group of groupMix) {
						let newGroupColor = color(group).replace("#", "");
						newGroupColor = newGroupColor.match(/.{1,2}/g);
						newGroupColor = [parseInt(newGroupColor[0], 16), parseInt(newGroupColor[1], 16), parseInt(newGroupColor[2], 16)];

						if (groupColor.length) {
							groupColor = [
								Math.round((groupColor[0] + newGroupColor[0]) / 2),
								Math.round((groupColor[1] + newGroupColor[1]) / 2),
								Math.round((groupColor[2] + newGroupColor[2]) / 2)];
						}
						else {
							groupColor = newGroupColor;
						}
					}

					colorHex = `#${groupColor[0].toString(16)}${groupColor[1].toString(16)}${groupColor[2].toString(16)}`;
				}

				let questableDOM = "";
				if (((currentExcelWorkbookRef.current === GRADE_SCHOOL_WORKBOOK && curNode.type === "subject")
					|| (currentExcelWorkbookRef.current === DEGREES_WORKBOOK && d.type === "school")
					|| (currentExcelWorkbookRef.current === JOBS_WORKBOOK && curNode.type === "job"))) {

					if (WORKBOOK_TO_QUESTABLE_ROOT_NODES_MAP[currentExcelWorkbookRef.current].includes(curNode.id)) {
						if (questsProgressRef.current && questsProgressRef.current[curNode.id] != null) {
							questableDOM = `<button class="resume-start-button">
																								${messageMap("knowledgeTree.quest.resume", "button")}
																							</button>`;
						}
						else {
							questableDOM = `<button class="resume-start-button">
																								${messageMap("knowledgeTree.quest.start", "button")}
																							</button>`;
						}
					}
					else {
						questableDOM = `<div class="quest-construction">
																							[${messageMap("knowledgeTree.quest.construction", "generic")}]
																						</div>`;
					}
				}

				const circleNode = `
														<foreignObject>
															<div class="node-container" style="font-size: 20px; background-color: ${colorHex}">
																<div>${curNode.label}</div>
																${questableDOM}
															</div>
														</foreignObject>`;

				return circleNode;
			})
			.call(d3.drag()
				.on("start", e => dragStarted(e, simulation))
				.on("drag", e => dragged(e, simulation))
				.on("end", e => dragEnded(e, simulation)))
			.on("mouseenter", (event, data) => {
				setTooltipStyling(data.index, "tooltip", "20px");
				// hoverNode(data);
			})
			.on("mouseleave", (event, data) => {
				setTooltipStyling(data.index, "tooltip hide", "20px");
				// mouseleaveNode(data);
			})
			.on("click", (event, data) => {
				const node = NODES_MAP[currentExcelWorkbookRef.current][data.index];
				const hasChildren = node.hasChildren;

				if (event.target.tagName === "BUTTON") {
					startOrResumeQuest(node.id);
				}
				else if (hasChildren) {
					goToTopic(data.id, 0);
				}
				else if (nodeIdToDetailsMapRef.current && nodeIdToDetailsMapRef.current[node.id]) {
					executeTooltipAction("learn", node.id, null, nodeIdToDetailsMapRef.current[node.id].videoMetadataSet);
				}
				else {
					let showAssistance = true;

					if ([GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK].includes(currentExcelWorkbookRef.current)
						&& !node.type.includes("topic")
						&& node.type !== "skills_knowledge_subtree") {
						showAssistance = false;
					}

					showAssistance && showAssistancePrompt(node.id, node.type);
				}
			});

		getKnowledgeMetadata(NODES_MAP[currentExcelWorkbookRef.current]);

		// Set the position attributes of links and nodes each time the simulation ticks.
		function ticked() {
			const spinnerContainerStyle = {
				"position": "absolute",
				margin: "auto",
				left: "0",
				right: "0"
			};
			// setSpinner(<Spinner spinnerContainerStyle={spinnerContainerStyle}/>);

			links.attr("d", d => {
				return `M${d.source.x},${d.source.y}L${d.target.x},${d.target.y}`;
			});

			nodesCircleRef.current.attr("x", d => {
				return d.x - nodesCircleRef.current._groups[0][d.index].children[0].children[0].offsetWidth / 2;
			})
				.attr("y", d => {
					return d.y - nodesCircleRef.current._groups[0][d.index].children[0].children[0].offsetHeight / 2;
				});

			tooltipsRef.current.attr('x', d => {
				// default positioning of tooltip while things are still moving. This gets readjusted inside hoverNode()
				// return d.x + (d.id.length / zoomScaleRef.current) + 100;
				return d.x + 50;
			})
				.attr('y', d => {
					return d.y - 50;
				});
		}

		function zoom(e) {
			zoomScaleRef.current = e.transform.k;
			links.attr("transform", e.transform);
			nodesCircleRef.current.attr("transform", e.transform);
			tooltipsRef.current.attr("transform", e.transform);
		}
	}

	// when hovering over node, expand / contract size of node and tooltip, depending on zoom scale ('zoomScaleRef')
	function hoverNode(data) {
		if (data.id !== "root") {
			const index = data.index;
			let parentNode = nodesCircleRef.current._groups[0][index];
			const specificDOMNode = parentNode.children[0].children[0];
			// NOTE*: DO NOT MOVE THIS FONT-SIZING EXPRESSION ANY CLOSER TO 'setNodeStyling()', SINCE DOM ISN'T ABLE TO PROPERLY UPDATE WHEN WE ALSO ADJUST THE WIDTH & HEIGHT
			specificDOMNode.style.fontSize = (40 / zoomScaleRef.current) + "px";
			const originalDimension = specificDOMNode.getAttribute("originalDimension");
			let dimension;

			if (originalDimension !== null && originalDimension !== "" && originalDimension !== "null") {
				dimension = parseInt(originalDimension);
			}
			else {
				const width = specificDOMNode.getClientRects()[0].width;
				const height = specificDOMNode.getClientRects()[0].height;
				// to account for cases when DOM doesn't properly respect CSS's aspect ratio of class node-container
				// multiply by 1.5 to include additional 'padding'
				if (height !== width) {
					dimension = (height > width ? height : width) * 1.5;
				}
				else {
					dimension = height;
				}
			}

			specificDOMNode.setAttribute("originalDimension", dimension);
			setNodeStyling(index, dimension * 2 / zoomScaleRef.current + "px");

			// adjust node coord due to scale resize
			const xCoord = parentNode.x.animVal.value;
			const yCoord = parentNode.y.animVal.value;
			// Because the re-drawing of the circles start from the upper left corner down to lower right corner,
			// the new x will be more to the right and the new y will be a little lower, so we need to APPROXIMATE
			// the node's new x and y center coordinates, according to how much we're scaling it up and down.
			// We're dividing it by 2, since 'dimension' is just 1 side of a square
			const newXNodeCoord = xCoord - (dimension / 2) / zoomScaleRef.current;
			const newYNodeCoord = yCoord - (dimension / 2) / zoomScaleRef.current;
			setDOMNodeCoord(
				parentNode,
				newXNodeCoord,
				newYNodeCoord,
				xCoord, yCoord
			);

			// adjust tooltip coord due to scale resize
			setTooltipStyling(index, "tooltip", (20 / zoomScaleRef.current) + "px");
			const tooltipNode = tooltipsRef.current._groups[0][index];
			const tooltipXCoord = tooltipNode.x.animVal.value;
			const tooltipYCoord = tooltipNode.y.animVal.value;
			setDOMNodeCoord(
				tooltipNode,
				// these 2 new adjusted x and y coordinate calculations are based on purely trial and error
				// If you want to improve this, note that the x and y coordinates wouldn't show in the DOM until
				// you no longer have any calls to 'ticked()', else it'll default to the x and y coordinates
				// for tooltipsRef in ticked()
				newXNodeCoord + (dimension * 1.95) / zoomScaleRef.current,
				// TODO: adjust this according to the amount of text inside the tooltip
				newYNodeCoord + (dimension * 0.9) / zoomScaleRef.current,
				tooltipXCoord,
				tooltipYCoord
			);
		}
	}

	function mouseleaveNode(data) {
		const index = data.index;

		let parentNode = nodesCircleRef.current._groups[0][index];
		const specificDOMNode = parentNode.children[0].children[0];
		specificDOMNode.style["font-size"] = "20px";
		setNodeStyling(index, parseInt(parentNode.children[0].children[0].getAttribute("originalDimension")) + "px");
		setDOMNodeCoord(parentNode, parseInt(parentNode.getAttribute("origX")), parseInt(parentNode.getAttribute("origY")));
		setTooltipStyling(index, "tooltip hide", "20px");
	}

	function setTooltipStyling(index, clazz, fontSize) {
		const tooltipNode = tooltipsRef.current._groups[0][index];
		if (tooltipNode) {
			tooltipNode.setAttribute("class", clazz);
			tooltipNode.style.fontSize = fontSize;
		}
	}

	function setNodeStyling(index, dimension) {
		let parentNode = nodesCircleRef.current._groups[0][index];
		const specificDOMNode = parentNode.children[0].children[0];
		specificDOMNode.style["width"] = dimension;
		specificDOMNode.style["height"] = dimension;
	}

	function setDOMNodeCoord(domNode, xCoord, yCoord, origX = null, origY = null) {
		domNode.setAttribute("x", xCoord);
		domNode.setAttribute("y", yCoord);
		if (origX) {
			domNode.setAttribute("origX", origX);
			domNode.setAttribute("origY", origY);
		}
	}

	function recordPreviousChosenId(id) {
		if (prevRootNodeRef.current[prevRootNodeRef.current.length - 1] !== id) {
			prevViewButtonRef.current.disabled = false;

			if (prevRootNodeRef.current.length > 10) {
				prevRootNodeRef.current.shift();
			}
			prevRootNodeRef.current.push(id);
		}
	}

	function goToPreviousView() {
		if (prevRootNodeRef.current.length) {
			goToTopic(prevRootNodeRef.current.pop(), 0, false);

			if (prevRootNodeRef.current.length === 0) {
				prevViewButtonRef.current.disabled = true;
			}
		}
	}

	function setChosenIdRef(id) {
		rootNodeRef.current = id;
		clearSetInputPointerReferenceRef.current.set(id);
		setDropdownsToHide();

		let found = false;
		const sanitizedId = id.replace("\n", "");
		for (const node of NODES_MAP[currentExcelWorkbookRef.current]) {
			if (node.id === sanitizedId) {
				found = true;
			}
		}

		locateButtonRef.current.disabled = !found ? true : false;
	}

	function showAssistancePrompt(item, nodeType) {
		const assistanceMsg = getAssistanceFromWorkbookAndNodeType(currentExcelWorkbookRef.current, nodeType);
		showFeedbackPrompt(assistanceMsg, item, "missingItems");
	}

	function changeViewOnNode() {
		let id = rootNodeRef.current;
		id = id.replace("\n", "");
		let data = binarySearch(nodesListRef.current, 0, nodesListRef.current.length, id, "id");
		// Need the timeout, since immediate subsequent calls only calls the 2nd one
		svgValueRef.current.transition().call(zoomAndPanRef.current.translateTo, data.x, data.y);
		setTimeout(() => {
			svgValueRef.current.transition().call(zoomAndPanRef.current.scaleBy, 5);
		}, 150);
	}

	function executeTooltipAction(action, topic, itemType, nodesDetails, isOnJourney) {
		if (action === "upload") {
			if (ownerId) {
				history.push({
					pathname: `/upload-video`,
					state: {
						topic: topic
					}
				});
			}
			else {
				askUserToSignUpFirst(setDomModal, setLoginModal);
			}
		}
		else if (action === "learn") {
			const firstNode = nodesDetails[0];
			const urlTitle = "/learn?id=" + firstNode.id + "&channel=" + firstNode.uploaderUsername + "&title=" + firstNode.title.replaceAll(" ", "_");
			const state = {
				videoIds: Array.from(nodesDetails, node => node.id),
				isOnJourney: isOnJourney
			};

			history.push({
				pathname: urlTitle,
				state: state
			});
		}
		else if (action === "missingItem") {
			const itemTypeToTxtMap = {
				"subjects": messageMap("knowledgeTree.itemType.subjects", "generic"),
				"topics": messageMap("knowledgeTree.itemType.topics", "generic"),
				"classes": messageMap("knowledgeTree.itemType.classes", "generic"),
				"degrees": messageMap("knowledgeTree.itemType.degrees", "generic"),
				"jobs": messageMap("knowledgeTree.itemType.jobs", "generic"),
				"profileItems": messageMap("knowledgeTree.itemType.profileItems", "generic"),
				"skillsAndKnowledgeItems": messageMap("knowledgeTree.itemType.skillsAndKnowledgeItems", "generic"),
				"skillsAndKnowledgeItemsSubtree": messageMap("knowledgeTree.itemType.classes", "generic"),
				"interviewItems": messageMap("knowledgeTree.itemType.interviewItems", "generic")
			};
			let itemDisplay = topic;

			if (topic.includes("_")) {
				const topicTokens = topic.split("_");
				itemDisplay = topicTokens[topicTokens.length - 1];
			}

			const title = (
				<div>
					{itemTypeToTxtMap[itemType]}
					<strong>{itemDisplay}</strong>
					:
				</div>
			);

			showFeedbackPrompt(title, topic, "missingAnItem");
		}
	}

	function showFeedbackPrompt(title, item, feedbackType) {
		const feedbackInputRefMap = {
			missingAnItem: missingAnItemRef,
			missingItems: missingItemRef
		};

		const modalContainerStyle = {
			maxWidth: 650,
			width: "auto",
			height: "auto",
		};
		const closeButtonStyle = {
			marginTop: "-16px",
			marginRight: "-8px"
		};

		const inputStyle = {
			padding: "2.5px 10px",
			border: "1px solid #adb5bd",
			borderTop: "none",
			borderLeft: "none",
			borderRight: "none",
			width: "250px",
			height: "40px",
			margin: "25px 0"
		};
		const submitButton = {
			display: "block",
			margin: "auto",
			padding: "10px 20px",
			borderRadius: "60px",
			border: "none",
			backgroundColor: "#2c8aff",
			color: "white",
			fontFamily: 'Montserrat-Regular'
		};

		setDomModal(
			<Modal title={title} closeType={"xButton"} closeButtonStyle={closeButtonStyle}
				modalContainerStyle={modalContainerStyle}
				closeHandler={closeModal}>
				<div>
					<input type="text" style={inputStyle} ref={feedbackInputRefMap[feedbackType]}
						onChange={e => checkMissingItemInput(e, feedbackType)}></input>
					<button className="missingItemButton" style={submitButton}
						onClick={e => submitMissingItemsSuggestion(item, feedbackType)}>
						{messageMap("submit.text", "button")}
					</button>
				</div>
			</Modal>
		);
	}

	function closeModal(e) {
		const className = e.target.className;

		if (["modal-block", "cancel", "close-button", "submit", "icon"].includes(className)) {
			hideModal();
		}
	}
	function hideModal() {
		setTimeout(() => {
			setDomModal(null);
		}, MODAL_CLOSE_TIMEOUT);
	}

	function checkMissingItemInput(e, missingType) {
		const missingItemInputValue = e.target.value;

		if (checkHasExplicitWords(missingItemInputValue)) {
			const feedbackRefMap = {
				missingAnItem: missingAnItemRef,
				missingItems: missingItemRef
			};

			const alertContainerStyle = {
				"top": "75px"
			};
			setAlert(
				<Alert type={ERROR} closeHandler={closeAlert}
					msg={messageMap("input.explicit.text", "validation")}
					alertContainerStyle={alertContainerStyle}/>
			);
			feedbackRefMap[missingType].current.value = "";
		}
	}

	function getKnowledgeMetadata(nodeList) {
		let idsOfTopics = [];

		Array.isArray(nodeList) && nodeList.forEach(node => {
			if (node.type.includes("topic")) {
				idsOfTopics.push(node.id);
			}
		});

		if (idsOfTopics.length) {
			idsOfTopics.length && getAllKnowledgeMetadata({ objectList: idsOfTopics }, resp => {
				createTooltips(resp);
			});
		}
		else {
			createTooltips();
		}
	}

	function populateNodeIdToDetailsMapRef(resourceArray) {
		let nodeIdToDetailsMap = {};
		for (let i = 0; i < resourceArray.length; ++i) {
			const resource = resourceArray[i];
			nodeIdToDetailsMap[resource.topicID] = {
				problemIDs: resource.problemIDs,
				textIDs: resource.textIDs,
				videoMetadataSet: resource.videoMetadataSet
			};
		}

		nodeIdToDetailsMapRef.current = nodeIdToDetailsMap;

		return nodeIdToDetailsMap;
	}

	function createTooltips(resourceArray) {
		let nodeIdToDetailsMap = resourceArray ? populateNodeIdToDetailsMapRef(resourceArray) : {};

		tooltipsRef.current = svgValueRef.current.append("g")
			.selectAll()
			.data(nodesListRef.current)
			.join("foreignObject")
			.attr("class", "tooltip hide")
			.attr("width", 1)
			.attr("height", 1)
			.html(d => {
				let header = d.label.replaceAll("_", " ").split(" ");
				let finalHeader = header[0];
				for (let i = 1; i < header.length; ++i) {
					finalHeader += " " + header[i]
				}

				const curNode = NODES_MAP[currentExcelWorkbookRef.current][d.index];
				let availability = "";
				let availabilityClassName = "tooltip-action hide";
				let tooltipAction = "";

				let feedback = "";
				let feedbackClassName = "missing-item hide";
				let feedbackType = "";

				if (curNode.type) {
					const tooltipObj = getTooltipFromWorkbookAndNodeType(currentExcelWorkbookRef.current, curNode.type, finalHeader, nodeIdToDetailsMap[curNode.id]);
					tooltipAction = tooltipObj[0];
					availability = tooltipObj[1];
					availabilityClassName = availability ? "tooltip-action" : "tooltip-action hide";

					const feedbackObj = getFeedbackFromWorkbookAndNodeType(currentExcelWorkbookRef.current, curNode.type, finalHeader);
					feedback = feedbackObj[0];
					feedbackClassName = feedbackObj[1];
					feedbackType = feedbackObj[2];
				}

				const tooltipActionDOM = availabilityClassName === "tooltip-action"
					? `<button class=${availabilityClassName} action="${tooltipAction}"
																								nodeId="${curNode.id}">
																					${availability ? availability : ""}
																				</button>`
					: "";
				const missingItemDOM = feedbackClassName === "missing-item"
					? `<button class=${feedbackClassName} action="missingItem"
																							itemType=${feedbackType}>
																				${feedback ? feedback : ""}
																			</button>`
					: "";

				const tooltipDiv = `<Fragment class="tooltip-container">
																								<div class="header">
																									${finalHeader}
																								</div>
																								<div class="description">
																									${d.description ? d.description : ""}
																								</div>
																								<div class="button-container">
																									${tooltipActionDOM}
																									${missingItemDOM}
																								</div>
																							</Fragment>`
				return tooltipDiv;
			})
			.on("mouseenter", (event, data) => {
				setTooltipStyling(data.index, "tooltip", "20px");
				// hoverNode(data);
			})
			.on("mouseleave", (event, data) => {
				setTooltipStyling(data.index, "tooltip hide", "20px");
				// mouseleaveNode(data);
			})
			.on("click", (event, data) => {
				const target = event.target;
				const tagName = target.tagName;
				const funcMap = {
					"BUTTON": executeTooltipAction
				};

				if (tagName === "BUTTON") {
					const actionType = target.getAttribute("action");
					const itemType = target.getAttribute("itemType");
					const nodeId = target.getAttribute("nodeId");
					let nodesDetails = actionType === "learn" ? nodeIdToDetailsMapRef.current[nodeId].videoMetadataSet : null;
					funcMap[tagName](actionType, data.id, itemType, nodesDetails);
				}
			});
	}

	function submitMissingItemsSuggestion(topic, feedbackType) {
		if (ownerId) {
			const payload = {
				type: feedbackType,
				ownerId: ownerId,
				feedback: topic + "_" + missingAnItemRef.current.value
			};
			reportMissingItemsFeedbackAPI(payload, resp => {
				let messages = [];

				Array.isArray(resp) && resp.forEach(code => {
					messages.push(
						messageMap(resp, "api") + " "
					);
				});

				setAlert(
					<Alert closeHandler={closeAlert} msg={messages}
						type={resp.includes("failed") ? ERROR : SUCCESS} />
				);
				hideModal();
			});
		}
		else {
			askUserToSignUpFirst(setDomModal, setLoginModal);
		}
	}

	function closeAlert() {
		setAlert(null);
	}

	function showView() {
		clearSetInputPointerReferenceRef.current.clear();

		const selectedSavedView = savedViewsDropdownRef.current.getAttribute("keyname");
		const knowledgeView = viewNameToObjectMapRef.current[selectedSavedView];

		recordPreviousChosenId(rootNodeRef.current);
		rootNodeRef.current = knowledgeView.rootName;
		depthLevelRef.current = knowledgeView.depth;
		inputDepthRef.current.value = knowledgeView.depth;
		zoomLevelRef.current = knowledgeView.zoomLevel;
		currentExcelWorkbookRef.current = knowledgeView.workbookName;
		currentExcelSheetRef.current = knowledgeView.sheetName;

		clearSvg();
		generateKnowledgeNetwork(rootNodeRef.current, depthLevelRef.current,
			zoomLevelRef.current, currentExcelWorkbookRef.current,
			"id", renderKnowledgeTree);
	}

	function saveView() {
		if (inputViewNameRef.current.value === "") {
			setAlert(
				<Alert closeHandler={closeAlert} msg={messageMap("input.knowledge.viewName", "validation")}
					type={ERROR} />
			);

			return;
		}

		if (ownerId) {
			const payload = {
				viewName: inputViewNameRef.current.value,
				workbookName: currentExcelWorkbookRef.current,
				sheetName: currentExcelSheetRef.current,
				rootName: rootNodeRef.current,
				depth: depthLevelRef.current,
				zoomLevel: zoomLevelRef.current,
				ownerId: ownerId
			};

			saveViewAPI(payload, resp => {
				if (resp.length === 0) {
					setAlert(
						<Alert closeHandler={closeAlert} type={SUCCESS}
							msg={`'${inputViewNameRef.current.value}' ` + messageMap("knowledge.view.save.success", "api")} />
					);

					inputViewNameRef.current.value = "";
				}
				else {
					let messages = [];
					Array.isArray(resp) && resp.forEach(respCode => {
						messages.push(
							messageMap(respCode, "api") + " "
						);
					});
					setAlert(
						<Alert closeHandler={closeAlert} type={ERROR} msg={messages} />
					);
				}
				fetchSavedViews();
			});
		}
		else {
			askUserToSignUpFirst(setDomModal, setLoginModal);
		}
	}

	function deleteView() {
		clearSetInputPointerReferenceRef.current.clear();

		const selectedSavedView = savedViewsDropdownRef.current.getAttribute("keyname");
		const knowledgeView = viewNameToObjectMapRef.current[selectedSavedView];
		const pathVariables = {
			viewId: knowledgeView.id
		};

		deleteViewAPI(pathVariables, resp => {
			if (resp.includes("success")) {
				setAlert(
					<Alert closeHandler={closeAlert} msg={`'${knowledgeView.viewName}' ` + messageMap(resp, "api")}
						type={SUCCESS} />
				);

				fetchSavedViews();
			}
			else {
				setAlert(
					<Alert closeHandler={closeAlert} msg={messageMap(resp, "api") + ` '${knowledgeView.viewName}'`}
						type={ERROR} />
				);
			}
		});
	}

	function saveDepth() {
		depthLevelRef.current = inputDepthRef.current.value === "" ? 1 : inputDepthRef.current.value;
	}

	function setDepth() {
		saveDepth();
		clearSvg();
		generateKnowledgeNetwork(rootNodeRef.current, depthLevelRef.current,
			zoomLevelRef.current, currentExcelWorkbookRef.current,
			"id", renderKnowledgeTree);
	}

	function setTreeType() {
		clearSetInputPointerReferenceRef.current.clear();
		saveDepth();
		const chosenTreeType = treeTypeDropdownRef.current.getAttribute("keyname");
		currentExcelWorkbookRef.current = chosenTreeType;
		currentExcelSheetRef.current = chosenTreeType;
		goToTopic("root", 0);
	}

	function goToTopic(topic, zoomLevel, savePreviousChosenId = true) {
		clearSetInputPointerReferenceRef.current.clear();
		topic = topic.replace("\n", "");
		savePreviousChosenId && recordPreviousChosenId(rootNodeRef.current);
		rootNodeRef.current = topic;
		zoomLevelRef.current = zoomLevel;
		zoomOutRef.current.disabled = true;

		clearSvg();
		generateKnowledgeNetwork(topic, depthLevelRef.current,
			0, currentExcelWorkbookRef.current,
			"id", renderKnowledgeTree);
	}

	function startOrResumeQuest(rootNodeId) {
		if (ownerId) {
			const questTree = getQuestTree(rootNodeId, currentExcelWorkbookRef.current);
			let topicVideoToNavigateTo;

			// Resume recently watched topic
			if (questsProgressRef.current && questsProgressRef.current[rootNodeId]) {
				topicVideoToNavigateTo = questsProgressRef.current[rootNodeId];
			}
			// Start quest from first topic
			else {
				let curNode = questTree;
				let firstModuleTopics;
				while (!curNode.type.includes("topic")) {
					const curNodeChildren = curNode.children;
					const curNodeChildrenKeys = Object.keys(curNodeChildren);
					// this process of assuming that the first element is the first topic is okay, because our naming convention uses Roman Numerals and letters
					curNode = curNodeChildren[curNodeChildrenKeys[0]];
					if (curNode.type.includes("topic")) {
						firstModuleTopics = curNodeChildrenKeys;
						break;
					}
				}

				topicVideoToNavigateTo = firstModuleTopics[0];
			}

			props.setQuestPath(questTree);
			props.setCurrentTopic(topicVideoToNavigateTo);
			getAllKnowledgeMetadata({ objectList: [topicVideoToNavigateTo] }, resp => {
				populateNodeIdToDetailsMapRef(resp);
				executeTooltipAction("learn", topicVideoToNavigateTo, null, nodeIdToDetailsMapRef.current[topicVideoToNavigateTo].videoMetadataSet, true);
			});
		}
		else {
			askUserToSignUpFirst(setDomModal, setLoginModal);
		}
	}

	function zoomOut() {
		const newLevel = zoomLevelRef.current - 1;
		if (newLevel > -1) {
			clearSetInputPointerReferenceRef.current.clear();
			clearSvg();
			generateKnowledgeNetwork(rootNodeRef.current, depthLevelRef.current,
				newLevel, currentExcelWorkbookRef.current,
				"id", renderKnowledgeTree);
			zoomLevelRef.current = newLevel;
		}
	}

	function zoomIn() {
		const newLevel = zoomLevelRef.current + 1;
		// if (newLevel < KNOWLEDGE_TREE_HEIGHT_MAP[currentExcelWorkbookRef.current]) {
		clearSetInputPointerReferenceRef.current.clear();
		zoomOutRef.current.disabled = false;

		clearSvg();
		generateKnowledgeNetwork(rootNodeRef.current, depthLevelRef.current,
			newLevel, currentExcelWorkbookRef.current,
			"id", renderKnowledgeTree);
		zoomLevelRef.current = newLevel;
		// }
	}

	function clearSvg() {
		const myNode = svgRef.current;
		while (myNode.firstChild) {
			myNode.removeChild(myNode.lastChild);
		}
	}

	function hideDropdowns(event) {
		if (!["search-container", "search-input", "dropdown-item"].includes(event.target.className)) {
			setDropdownsToHide();
		}
	}

	function toggleMenu() {
		if (hiddenMenuRef.current.className === "hidden-menu-container hide") {
			setDarkBackGround(true);
			hiddenMenuRef.current.className = "hidden-menu-container";
			shadedRegionRef.current.className = "shaded-region";
		}
		else if (hiddenMenuRef.current.className === "hidden-menu-container") {
			setDarkBackGround(false);
			hiddenMenuRef.current.className = "hidden-menu-container hide";
			shadedRegionRef.current.className = "shaded-region hide";
		}
	}

	function setDropdownsToHide() {
		closeHandlerPointerReferenceRef.current();
	}

	return (
		<div className="knowledge-tree" onClick={hideDropdowns}>
			<Helmet>
				<title>{messageMap("knowledgeQuests.title", "headerTag")}</title>
				<meta name="description" content={messageMap("knowledgeQuests.description", "headerTag")}></meta>
			</Helmet>

			{spinner}
			{domModal}
			{loginModal}
			{darkBackground ? <div className="modalClass" /> : null}

			<div className={"knowledge-menu " + (darkBackground ? "bright-html-element" : "")}>
				<div className="knowledge-menu--absolute">
					<div className="hamburger-container">
						<div className="icon-container">
							<img src={menuAsset} alt={messageMap("knowledge.hamburger", "image")}
								tabIndex={0} role="button" onClick={toggleMenu} onKeyPress={e => onKeyDown(e, toggleMenu, [e])}></img>
						</div>
						<div className="hidden-menu-container hide" ref={hiddenMenuRef}>
							<div className="depth-container">
								<input className="depth-input" type="number" min="1" max="10" ref={inputDepthRef}
									placeholder="1"></input>
								<button className="depth-button" onClick={setDepth}>
									<img src={depthAsset} alt={messageMap("knowledge.depth", "image")}></img>
								</button>
							</div>

							<div className="new-views-container">
								<input className="save-view-input" type="text" maxlength="50"
									placeholder={messageMap("knowledgeTree.placeholders.saveView", "generic")} ref={inputViewNameRef}></input>
								<button className="save-view-button" onClick={saveView}>
									{messageMap("knowledgeTree.view.save", "button")}
								</button>
							</div>

							<div className="saved-views-container">
								<p className="saved-view-name">
									{messageMap("knowledgeTree.view.saved", "labelPlaceholder")}
								</p>

								<div className="view-actions-container">
									{savedViews}
									<button className="show-delete-view-button"
										onClick={showView} ref={savedViewsButtonRef}>
										{messageMap("knowledgeTree.view.show", "button")}
									</button>
									<button className="show-delete-view-button"
										onClick={deleteView} ref={savedViewsToDeleteButtonRef}>
										{messageMap("knowledgeTree.view.delete", "button")}
									</button>
								</div>
							</div>
						</div>
						<div className="shaded-region hide" ref={shadedRegionRef}></div>
					</div>

					<div className={"shown-menu " + (darkBackground ? "hide" : "")}>
						<Search searchCategory={currentExcelWorkbookRef.current} searchType="knowledge"
							chosenResultClickHandler={setChosenIdRef}
							nestedSearch={true}
							closeHandlerPointerReference={closeHandlerPointerReferenceRef}
							clearSetInputPointerReference={clearSetInputPointerReferenceRef}
							inputPlaceholder={messageMap("knowledgeTree.search.placeholder", "button")} />
						<button onClick={changeViewOnNode}
							className="locate-button" ref={locateButtonRef}>
							<img src={locateAsset} alt={messageMap("knowledge.locate", "image")}></img>
						</button>
						<button
							onClick={e => goToTopic(rootNodeRef.current, 0)}
							className="display-tree-button">
							<img src={treeAsset} alt={messageMap("knowledge.tree", "image")}></img>
						</button>

						<div className="workbook-container">
							<Dropdown selectedOptionParentRef={treeTypeDropdownRef}
								customDropdownItemAttribute="keyname"
								customContainerClass="knowledge-network"
								dropdownOptions={{
									[GRADE_SCHOOL_WORKBOOK]: messageMap("knowledgeTree.workbook.gradeSchool", "generic"),
									[DEGREES_WORKBOOK]: messageMap("knowledgeTree.workbook.degrees", "generic"),
									[JOBS_WORKBOOK]: messageMap("knowledgeTree.workbook.jobs", "generic")
								}}/>
							<button className="workbook-select-button"
								onClick={setTreeType}>
								{messageMap("knowledgeTree.workbook.setTreeType", "generic")}
							</button>
						</div>
					</div>

				</div>
			</div>

			{alert}

			<div className="nav-buttons">
				<button className="go-up-button" onClick={zoomIn}>
					<img src={plusAsset} alt={messageMap("knowledge.view.deeper", "image")}></img>
				</button>
				<button className="go-down-button" onClick={zoomOut}
					ref={zoomOutRef}>
					<img src={minusAsset} alt={messageMap("knowledge.view.higher", "image")}></img>
				</button>
				<button className="prev-view-button" onClick={goToPreviousView}
					ref={prevViewButtonRef}>
					<img src={chevronLeftAsset} alt={messageMap("knowledge.view.previous", "image")}></img>
				</button>
				<button className="root-button" onClick={e => goToTopic("root", 0)}>
					<img src={resetAsset} alt={messageMap("knowledge.view.root", "image")}></img>
				</button>
			</div>

			<div className="svg-container">
				<svg width="1000" height="1000" ref={svgRef}>
				</svg>
			</div>
		</div>
	);
}

KnowledgeTree.propTypes = {
	// Redux prop values
	ownerId: PropTypes.string,
	// Redux prop functions
	setQuestPath: PropTypes.func,
	setCurrentTopic: PropTypes.func

};

export default connect(
	navigationSelector,
	{ setQuestPath, setCurrentTopic }
)(KnowledgeTree);