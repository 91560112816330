import React from 'react';

import maintenanceAsset from "assets/images/maintenance/maintenance.svg";

export default function Maintenance() {
	return (
		<div className="maintenance">
			<img src={maintenanceAsset}></img>
		</div>
	);
}