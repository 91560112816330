import React from "react";

import { messageMapDocument } from "Utilities/MessageMaps";


function TutorPolicyViolations(props) {

	return (
		<div className="section-div">
			<h2 className="head-label">
				{messageMapDocument("teacherPolicy.section4.title")}
			</h2>
			<p className="margin-bottom-spacing">
				{messageMapDocument("teacherPolicy.section4.point1.text")}
			</p>
			<ul>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point2.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point3.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point4.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point5.text")}
					</p>
				</li>
			</ul>
			<p className="margin-bottom-spacing">
				{messageMapDocument("teacherPolicy.section4.point6.text")}
			</p>
			<ul>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point7.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point8.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point9.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point10.text")}
					</p>
				</li>
				<li>
					<p className="margin-bottom-spacing">
						{messageMapDocument("teacherPolicy.section4.point11.text")}
					</p>
				</li>
			</ul>
		</div>
	);
}

export default TutorPolicyViolations;

