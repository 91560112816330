import request, { POST } from "Utilities/Fetches";

const BASE = "/services/subscription";

const START_SUBSCRIPTION = BASE + "/start";
const CONTACT_SALES = BASE + "/post/contactSalesForm";

/**
 * @param {Object} payload = SUBSCRIPTION_VO
 */
export function startSubscriptionAPI(payload, callback) {
  request(START_SUBSCRIPTION, POST, payload, callback);
}

/**
 * @param {Object} payload = SUBSCRIPTION_VO
 * Parks the form information under SUBSCRIPTION_VO key - "billingDetails".
 */
export function subscriptionContactSalesAPI(payload, callback) {
  request(CONTACT_SALES, POST, payload, callback);
}