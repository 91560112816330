import React, { useState, useEffect }  from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setPaymentInfo } from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";

import {
	AUTO_REDIRECT_TIMEOUT, ALERT_THIRTY_SECONDS_TIMEOUT, MODAL_CLOSE_TIMEOUT
} from "Utilities/Constants/TimeoutConstants";
import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";
import { AUTH_TOKEN, OWNER_ID, SAVED_TUTORS } from "Utilities/Constants/MediaConstants";
import { parseDecisionResponse } from "pages/Tutoring/utilities/TutorRequestUtility";
import { ACCEPT_DECISION, DECLINE_DECISION } from "pages/Tutoring/utilities/TutoringRequestConstants";

import Alert, {SUCCESS, ERROR} from "templates/Alert";
import ConfirmModal from "templates/customModals/ConfirmModal";
import Spinner from "templates/Spinner";

import {askUserToSignUpFirst} from "templates/customModals/utilities/SignUpLoginUtilities";

import { saveAccountSettingsAPI } from "apis/controllers/person/AccountsController";
import { acceptTutorRequestApplicationAPI, declineTutorRequestApplicationAPI } from "apis/controllers/tutoring/SessionRequestController";
import { createPaymentIntentAPI } from "apis/controllers/transactions/PaymentsController";

import saveAsset from "assets/icons/common/save.svg";
import saveFilledAsset from "assets/icons/common/saveFilled.svg";
import checkboxUnchecked from "assets/icons/common/checkbox-unchecked.svg";
import checkboxChecked from "assets/icons/common/checkbox-checked.svg";


function TutorCard(props) {

	const ownerId = props.ownerId || localStorage.getItem("ownerId");

	const history = useHistory();

	const [learnMoreOverlay, setLearnMoreOverlay] = useState("hide"),
		[isSaved, setIsSaved] = useState(props.isSaved),
		[isCheckedImg, setIsCheckedImg] = useState(checkboxUnchecked),
		[isCheckboxChecked, setIsCheckboxChecked] = useState(false),
		// DOM state
		[subjectListDom, setSubjectListDom] = useState();

	useEffect(() => {
		getSubjectList();
	}, [props.ownerId]);

	function getSubjectList() {
		let subjectList = [];
		props.subjectList.split("|").forEach(subjectTopic => {
			const subject = subjectTopic.split("_")[0];
			!subjectList.includes(subject) && subjectList.push(subject);
		});

		setSubjectListDom(subjectList.join(", "));
	}

	function saveTutor() {
		if (props.ownerId == null) {
			askUserToSignUpFirst(props.setModal, props.setModal1);
		}
		else {
			const payload = {
				[AUTH_TOKEN]: props.authToken,
				[SAVED_TUTORS]: [props.tutorId]
			};
			const pathVariables = {
				[OWNER_ID]: ownerId
			};
			let fieldsToValidate = [];
			for (const [key, value] of Object.entries(payload)) {
				value && fieldsToValidate.push(key);
			}

			saveAccountSettingsAPI(pathVariables, payload, fieldsToValidate, resp => {
				const responseCodes = resp.responseCodes;
				let alertType = ERROR;
				let allMsgs = [];
				responseCodes.forEach(respCode => {
					if (respCode.includes("success")) {
						alertType = SUCCESS;
					}
					allMsgs.push(messageMap(respCode, "api"));
				});

				props.setAlert(
					<Alert closeHandler={closeAlert} customClass="tutoring" type={alertType} 
						msg={allMsgs} />
				);
				if (alertType === SUCCESS) {
					if (responseCodes.includes("accounts.update.savedTutor.success")) {
						setIsSaved(true);
					}
					else if (responseCodes.includes("accounts.update.unsavedTutor.success")) {
						setIsSaved(false);
					}
				}
			});
		}
	}

	function closeAlert() {
		props.setAlert(null);
	}

	function toggleLearnMoreOverlay(showOrHide) {
		if (["viewTutors", "viewSaved"].includes(props.purpose)) {
			setLearnMoreOverlay(showOrHide);
		}
	}

	function showConfirmationModal(decision) {
		let confirmModalTitle = decision === ACCEPT_DECISION ? messageMap("tutoringPage.request.modal.titleAcceptTutors", "generic") : messageMap("tutoringPage.request.modal.titleDeclineTutors", "generic");

		props.setModal(
			<ConfirmModal title={confirmModalTitle} closeArgs={decision} closeModal={executeDecision} confirmType="ensure" />
		);
	}

	function executeDecision(blank, decision) {
		if ([ACCEPT_DECISION, DECLINE_DECISION].includes(decision)) {
			props.setSpinner(
				<Spinner key="processing" containerClass="processing-payment-method"
					loadingText={`${messageMap("tutoringPage.request.processingPayment1", "generic")} $${Number(props.sessionPrice.toFixed(2))} ${messageMap("tutoringPage.request.processingPayment2", "generic")}`} />
			);

			// NOTE*: if you're updating this block, you need to update the same block in TutoringMessages & RequestItem
			if (decision === ACCEPT_DECISION) {
				const paymentDescription = `${messageMap("payments.checkout.tutoringSession", "generic")} ${props.firstName} ${props.lastName}`;

				const payload = {
					ownerId: props.ownerId,
					paymentAmount: props.sessionPrice,
					quantityPurchased: 1,
					paymentDescription: paymentDescription,
					paymentPurpose: "tutoringSession",
					isPaymentOffSession: true,
					sessionId: props.sessionId,
					tutorOwnerId: props.tutorOwnerId
				};
				createPaymentIntentAPI(payload, resp => {
					if (resp.status === "succeeded") {
						saveDecision(decision);
						props.setSpinner(null);
					}
					// offload work to Stripe for any other payment status
					else {
						props.setSpinner(
							<Spinner key="invalidPayment" containerClass="invalid-payment-method"
								loadingText={messageMap("tutoringPage.request.invalidPaymentOrAuthNeeded", "generic")} />
						);

						setTimeout(() => {
							props.setPaymentInfo({
								imgRef: 5,
								price: props.sessionPrice,
								quantity: 1,
								description: paymentDescription,
								purpose: "tutoringSession"
							});
							history.push({
								pathname: "/checkout",
								state: {
									stripePubKey: resp.stripePubKey,
									returnUrl: "tutoring_show:applications",
									clientSecret: resp.clientSecret,
									tutorId: props.tutorId,
									sessionId: props.sessionId
								}
							});
							props.setSpinner(null);
						}, AUTO_REDIRECT_TIMEOUT * 1.25);
					}
				});
			}
			else {
				saveDecision(decision);
				props.setSpinner(null);
			}
		}
		else {
			closeModal();
		}
	}

	function saveDecision(decision) {
		const decisionMap = {
			[ACCEPT_DECISION]: acceptTutorRequestApplicationAPI,
			[DECLINE_DECISION]: declineTutorRequestApplicationAPI
		};
		const pathVariables = {
			tutorProfileId: props.tutorId,
			sessionRequestId: props.requestId
		};

		decisionMap[decision](pathVariables, ownerId, resp => {
			const {msg, executionStatus} = parseDecisionResponse(resp);

			props.setAlert(
				<Alert type={executionStatus} closeHandler={closeAlert} customClass="tutoring" 
					msg={msg} timeout={ALERT_THIRTY_SECONDS_TIMEOUT} />
			);

			if (executionStatus === SUCCESS) {
				props.refreshList();
			}
		});
	}

	function closeModal(e) {
		if (e == null || (e != null && ["modal-block", "cancel", "icon", "close-button"].includes(e.target.className))) {
			setTimeout(() => {
				props.setModal(null);
			}, MODAL_CLOSE_TIMEOUT);
		}
	}

	function clickCheckbox() {
		if (isCheckboxChecked) {
			setIsCheckedImg(checkboxUnchecked);
			props.setClickedTutors(props.tutorOwnerId, "remove");
		}
		else {
			setIsCheckedImg(checkboxChecked);
			props.setClickedTutors(props.tutorOwnerId, "add");
		}
		setIsCheckboxChecked(!isCheckboxChecked);
	}

	return (
		<div className="tutor-card" role="button" tabIndex={0}
			onClick={props.learnMoreHandler}
			onKeyDown={e => onKeyDown(e, props.learnMoreHandler, [e])}>
			<div onMouseOver={e => toggleLearnMoreOverlay("show")} onFocus={e => toggleLearnMoreOverlay("show")}
					onMouseLeave={e => toggleLearnMoreOverlay("hide")} onBlur={e => toggleLearnMoreOverlay("hide")}>

				{
					["viewTutors", "viewSaved"].includes(props.purpose)
					? (
						<div className="learn-more-container"
							domkey="profileDetails">
							{/* this beats having to hack a custom checkbox using a label */}
							<img className="compare-checkbox" src={isCheckedImg} alt="checkbox svg"
								tabIndex={0} role="button"
								onClick={clickCheckbox} onKeyDown={e => onKeyDown(e, clickCheckbox, [e])} />
							<button className={`learn-more ${learnMoreOverlay === "show" ? "" : "hide"}`} domkey="profileDetails">
								{messageMap("tutoring.tutorList.learnMore", "button")}
							</button>
						</div>
					)
					: (
						<div className="tutee-options">
							{/* this beats having to hack a custom checkbox using a label */}
							<img className="compare-checkbox" src={isCheckedImg} alt="checkbox svg"
								tabIndex={0} role="button"
								onClick={clickCheckbox} onKeyDown={e => onKeyDown(e, clickCheckbox, [e])} />
							<div className="decision-options">
								<button className="hire-button" onClick={e => showConfirmationModal(ACCEPT_DECISION)}>
									{messageMap("tutoring.request.hire", "button")}
								</button>
								<button className="remove-button" onClick={e => showConfirmationModal(DECLINE_DECISION)} >
									{messageMap("tutoring.request.remove", "button")}
								</button>
							</div>
						</div>
					)
				}

				<div className={`profile-picture-container ${learnMoreOverlay === "hide" ? "" : "hover"}`}
					domkey="profileDetails">
					<img className="profile-picture" domkey="profileDetails"
						src={props.profilePicture} alt={messageMap("tutoring.tutorPicture", "image")}/>
				</div>
			</div>
			<div className="tutor-details">
				<div className="tutor-name-save-container">
					<div className="tutor-name" domkey="profileDetails">
						{`${props.firstName} ${props.lastName}`}
					</div>
					<button className="save-tutor" onClick={saveTutor} >
						<img src={isSaved ? saveFilledAsset : saveAsset} alt={isSaved ? messageMap("tutoring.tutorList.saved", "image") : messageMap("tutoring.tutorList.save", "image")} />
					</button>
				</div>

				<div className="occupation-degree">
					{props.occupation}
				</div>

				<div className="subject-list">
					{subjectListDom}
				</div>

				<button className="price-label" domkey="priceBreakdown"
					onClick={props.priceDetailsHandler}>
					{messageMap("tutoringPage.tutorList.price", "generic")}
				</button>
			</div>
		</div>
	);
}

TutorCard.propTypes = {
	// used for reviewing tutors
	requestId: PropTypes.string,
	refreshList: PropTypes.func.isRequired,
	setClickedTutors: PropTypes.func.isRequired,
	tutorOwnerId: PropTypes.string,

	// for saving tutors
	isSaved: PropTypes.bool.isRequired,
	purpose: PropTypes.string.isRequired,

	// for when user is paying for a tutoring session
	sessionId: PropTypes.string,
	sessionPrice: PropTypes.number,

	// props visible to users as text
	tutorId: PropTypes.string.isRequired,
	profilePicture: PropTypes.string.isRequired,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	profilePictureUploadLocation: PropTypes.string.isRequired,
	occupation: PropTypes.string.isRequired,
	subjectList: PropTypes.string.isRequired,

	// callbacks
	priceDetailsHandler: PropTypes.func.isRequired,
	learnMoreHandler: PropTypes.func.isRequired,

	// parent state setter
	setAlert: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	setModal1: PropTypes.func.isRequired,
	setSpinner: PropTypes.func,

	// redux props
	ownerId: PropTypes.string,
	setPaymentInfo: PropTypes.func.isRequired,
	authToken: PropTypes.string.isRequired
};

export default connect(
	account,
	{ setPaymentInfo }
)(TutorCard);