export const GRADE_SCHOOL_WORKBOOK = "GradeSchool";
export const DEGREES_WORKBOOK = "Degrees";
export const JOBS_WORKBOOK = "Jobs";

// will be used to determine which root level node is 'questable'
export const WORKBOOK_TO_QUESTABLE_ROOT_NODES_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: [
		// "Economics and Personal Finance", "History", "Psychology", "English and Language Arts",
		// "Art", "Foreign Language", "Health and Fitness", "Science",
		"Math",
		// this is a custom class created by our company
		"optimizing_learning"
	],
	[DEGREES_WORKBOOK]: [
		// "School of Agriculture, Agriculture Operations, and Related Programs", "School of Architecture and Related Programs",
		// "School of Area, Ethnic, Cultural, Gender and Group Studies", "School of Aviation", "School of Biological and Biomedical Sciences",
		// "School of Business, Management, Marketing, and Related Programs", "School of Communication, Journalism, and Related Programs",
		// "School of Computer and Information Sciences and Support Programs", "School of Education",
		// "School of Engineering", "School of English Language and Literature/Letters",
		// "School of Family and Human Sciences", "School of Foreign Languages, Literatures, and Linguistics",
		// "School of Health Professions and Related Programs", "School of History",
		// "School of Homeland Security, Law Enforcement, Firefighting", "School of Human Services",
		// "School of Legal Professions and Studies", "School of Liberal Arts and Sciences Studies and Humanities",
		// "School of Mathematics and Statistics", "School of Military Technologies and Applied Sciences",
		// "School of Natural Resources and Conservation", "School of Parks, Recreation, Leisure, and Fitness Studies",
		// "School of Personal and Culinary Services", "School of Philosophy and Religious Studies",
		// "School of Physical Sciences", "School of Precision Production", "School of Psychology",
		// "School of Social Sciences", "School of Theology and Religious Vocations", "School of Visual and Performing Arts"
	],
	[JOBS_WORKBOOK]: [
		"Software Engineer"
	]
};


// Will be used for the use case: Given a topic, find its root-level node. Instead of creating a "doubly-linked"
// tree, or relying on some complicated and potentially sophisticated algorithm to travel up the tree, we'll
// rely on group number from the "group" column in excel file
export const WORKBOOK_GROUP_NUMBERS_TO_ROOT_NODES_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: {
		1: "Economics and Personal Finance",
		2: "History",
		3: "Psychology",
		4: "English and Language Arts",
		5: "Art",
		6: "Foreign Language",
		7: "Health and Fitness",
		8: "Science",
		9: "Math"
	},
	[DEGREES_WORKBOOK]: {
		1: "School of Agriculture, Agriculture Operations, and Related Programs",
		2: "School of Architecture and Related Programs",
		3: "School of Area, Ethnic, Cultural, Gender and Group Studies",
		4: "School of Aviation",
		5: "School of Biological and Biomedical Sciences",
		6: "School of Business, Management, Marketing, and Related Programs",
		7: "School of Communication, Journalism, and Related Programs",
		8: "School of Computer and Information Sciences and Support Programs",
		9: "School of Education",
		10: "School of Engineering",
		11: "School of English Language and Literature/Letters",
		12: "School of Family and Human Sciences",
		13: "School of Foreign Languages, Literatures, and Linguistics",
		14: "School of Health Professions and Related Programs",
		15: "School of History",
		16: "School of Homeland Security, Law Enforcement, Firefighting",
		17: "School of Human Services",
		18: "School of Legal Professions and Studies",
		19: "School of Liberal Arts and Sciences Studies and Humanities",
		20: "School of Mathematics and Statistics",
		21: "School of Military Technologies and Applied Sciences",
		22: "School of Natural Resources and Conservation",
		23: "School of Parks, Recreation, Leisure, and Fitness Studies",
		24: "School of Personal and Culinary Services",
		25: "School of Philosophy and Religious Studies",
		26: "School of Physical Sciences",
		27: "School of Precision Production",
		28: "School of Psychology",
		29: "School of Social Sciences",
		30: "School of Theology and Religious Vocations",
		31: "School of Visual and Performing Arts"
	},
	[JOBS_WORKBOOK]: {
		1: "Software Engineer"
	}
};


