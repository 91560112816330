/* eslint-disable no-template-curly-in-string */
import {TUTOR_PROFILE_FORM_DATA} from "Utilities/Constants/MediaConstants";
import {PAGE_SIZE} from "Utilities/Constants/PageConstants";

import request, {GET, POST} from "Utilities/Fetches";
import {getFormData} from "Utilities/Upload";
import {$replace, fillOptionalArgs} from "Utilities/Strings";

const BASE = "/services/tutor/profile";

const SAVE_TUTOR_PROFILE = BASE + "/save?ownerId=${ownerId}";
export const GET_TUTOR_PROFILE = BASE + "/get?tutorOwnerId=${tutorOwnerId}";
export const GET_TUTOR_PROFILE_LIST = BASE + "/get/list?pageCount=${pageCount}&pageSize=${pageSize}";
const GET_TUTOR_PROFILES_COUNT = BASE + "/get/count";
const TOGGLE_PROFILE_VISIBILITY = BASE + "/toggle/visibility";




/**
 * @param {Object} pathVariables = {
 * 	ownerId: String
 * }
 */
export function saveTutorProfileAPI(pathVariables, formDataPayload, relevantFields, callback) {
	const api = $replace(SAVE_TUTOR_PROFILE, pathVariables);

	const formData = getFormData(formDataPayload, relevantFields, TUTOR_PROFILE_FORM_DATA);
	request(api, POST, formData, callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	tutorOwnerId: String
 * }
 */
export function getTutorProfileAPI(pathVariables, callback) {
	const api = $replace(GET_TUTOR_PROFILE, pathVariables);
	request(api, GET, null, callback);
}

export function getTutorProfileListAPI(pathVariables, payload, callback) {
	let newPathVariables = fillOptionalArgs(pathVariables, {pageSize: PAGE_SIZE.TEN});
	const api = $replace(GET_TUTOR_PROFILE_LIST, newPathVariables);
	request(api, POST, payload, callback);
}

export function getTutorEntriesCountAPI(payload, callback) {
	request(GET_TUTOR_PROFILES_COUNT, POST, payload, callback);
}

export function toggleTutorProfileVisibilityAPI(payload, callback) {
	request(TOGGLE_PROFILE_VISIBILITY, POST, payload, callback);
}

