const monthNumberToStringMap = {
	0: "Jan",
	1: "Feb",
	2: "Mar",
	3: "Apr",
	4: "May",
	5: "Jun",
	6: "Jul",
	7: "Aug",
	8: "Sep",
	9: "Oct",
	10: "Nov",
	11: "Dec"
};

/**
 * @description converts ts (ex. 1609942131000) into universal date format (DD/MM/YY)
 */
export function getUniversalDateFromTimestamp(timestamp) {
	const tsDate = new Date(timestamp),
				ts = tsDate.getDate() + " " + monthNumberToStringMap[tsDate.getMonth()] + " " + tsDate.getFullYear();

	return ts;
}

/**
 * @description converts timestamp (ex. 1609942131000) into US date format (MM/DD/YY)
 */
export function getUSDateFromTimestamp(timestamp) {
	const tsDate = new Date(timestamp),
				ts = tsDate.getMonth() + "/" + tsDate.getDate() + "/" + tsDate.getFullYear();

	return ts;
}

/**
 * @description converts arbitrary number of seconds into the following format: MM:SS, 
 * where M is minutes and S is seconds
 * @param {Number} seconds number of second 
 */
export function getMinuteSecondTimestamp(seconds) {
	const currentTime = seconds,
				minuteCount = Math.floor(currentTime / 60);

	let secondCount = Math.floor(currentTime - (60 * minuteCount));
			secondCount = (secondCount + "").length === 1 ? "0" + (secondCount + "") : secondCount;

	return {
		minutes: minuteCount,
		seconds: secondCount
	};
}

/**
 * @description converts 24-hr format (ex. 22:40) to 12-hr format (8:45 PM)
 */
export function convertMilitaryTimeTo12HrFormat(twentyFour) {
	const timeSplit = twentyFour.split(":");

	// already in 
	if (timeSplit[0] < 12) {
		return `${twentyFour} AM`;
	}
	else {
		const hour = timeSplit[0] - 12;

		return `${hour}:${timeSplit[1]} PM`;
	}
}

