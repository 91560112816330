import React, {useRef, Fragment} from 'react';
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { onKeyDown } from "Utilities/Accessibility";

import tableAsset from "assets/icons/common/table.svg";

/**
 * @param {Object} iconNameToRefMap // map of list item name and their corresponding ref
 * @param {Array} list // map of list item name and their innerText
 */
export default function TableOfContents(props) {
	const tableNavDisplay = useRef("hide");

	const domList = createListItems();

	function createListItems() {
		const propList = props.list;
		let list = [];
		for (let el in propList) {
			list.push(
				<li key={el} tabIndex={0} name={el}>
					{propList[el]}
				</li>
			);
		}

		return (
			<Fragment>
				{list}
			</Fragment>
		);
	}

	function updateTableNavDisplay() {
		tableNavDisplay.current.className = tableNavDisplay.current.className.includes("hide") ? "navigation" : "navigation hide";
	}

	function focusOnSection(iconName) {
		if (typeof iconName === "object" && iconName.target.getAttribute("name") != null) {
			iconName = iconName.target.getAttribute("name");
			updateTableNavDisplay();
		}
		else {
			return;
		}

		const scrollIntoViewOptions = {
			behavior: "smooth", 
			block: "start", 
			inline: "start"
		};

		props.iconNameToRefMap[iconName].current.scrollIntoView(scrollIntoViewOptions);
		setTimeout(() => {
			window.scrollBy({
				top: -120,
				left: 0,
				behavior: "smooth"
			});
		}, 725);
	}

	return (
		<div className="table-of-contents">
			<div className="table-container">
				<img src={tableAsset} alt={messageMap("navigation.tableOfContents", "image")}
							role="button" tabIndex={0}
							onClick={updateTableNavDisplay} onKeyPress={e => onKeyDown(e, updateTableNavDisplay, [e])}></img>
				<div className="navigation hide" ref={tableNavDisplay}>
					<ol onClick={focusOnSection} onKeyPress={focusOnSection}>
						{domList}
					</ol>
				</div>
			</div>
		</div>
	);
}

TableOfContents.propTypes = {
	iconNameToRefMap: PropTypes.object,
	list: PropTypes.object
};