/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";

const BASE = "/services/feedback";

const SAVE_GENERAL_OR_BUG_FEEDBACK = BASE + "/give/generalOrBugFeedback";
const SAVE_CLAZZ_FEEDBACK = BASE + "/give/clazzFeedback";
const REPORT_MISSING_ITEMS = BASE + "/report/missing/items";
export const CLASS = BASE + "/class/${classId}";



/**
 * @param {Object} payload = FEEDBACK_VO
 */
export function saveGeneralOrBugFeedbackAPI(payload, callback) {
	request(SAVE_GENERAL_OR_BUG_FEEDBACK, POST, payload, callback);
}

/**
 * @param {Object} payload = FEEDBACK_VO
 */
export function saveClazzFeedbackAPI(payload, callback) {
	request(SAVE_CLAZZ_FEEDBACK, POST, payload, callback);
}

/**
 * @param {Object} payload = FEEDBACK_VO
 */
export function reportMissingItemsFeedbackAPI(payload, callback) {
	request(REPORT_MISSING_ITEMS, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 */
export function classAPI(pathVariables, callback) {
	const api = $replace(CLASS, pathVariables);
	request(api, GET, null, callback);
}