import { convertArrayToObject } from "Utilities/ObjectUtility";

export const AUTH_TOKEN = "authToken";
export const OWNER_ID = "ownerId";
// login
export const USERNAME = "username";
export const PASSWORD = "password";
// personal information
export const PROFILE_PICTURE = "profilePicture";
export const FIRST_NAME = "firstName";
export const LAST_NAME = "lastName";
export const FIRST_NAME_KANA = "firstNameKana";
export const LAST_NAME_KANA = "lastNameKana";
export const FIRST_NAME_KANJI = "firstNameKanji";
export const LAST_NAME_KANJI = "lastNameKanji";
export const DATE_OF_BIRTH = "dateOfBirth";
// contact information
export const EMAIL = "email";
export const PHONE_NUMBER = "phoneNumber";
// location
export const STREET_ADDRESS_1 = "line1Address";
export const STREET_ADDRESS_2 = "line2Address";
export const POSTAL_CODE = "postalCodeAddress";
export const POSTAL_CODE_KANA = "postalAddressKana";
export const POSTAL_CODE_KANJI = "postalAddressKanji";
export const CITY = "cityAddress";
export const STATE = "stateAddress";
export const COUNTRY = "country";
// tutoring
export const SAVED_TUTORS = "savedTutors";
export const TUTOR_LIMIT_PER_SESSION = "tutorLimitPerSession";
// security
export const IP = "ip";
export const BROWSER = "browser";
export const PLATFORM = "platform";

export const ACCOUNT_UPDATE_FORM_DATA = convertArrayToObject([
	AUTH_TOKEN, OWNER_ID,
	USERNAME, PASSWORD,
	PROFILE_PICTURE, FIRST_NAME, LAST_NAME,
	FIRST_NAME_KANA, LAST_NAME_KANA, FIRST_NAME_KANJI, LAST_NAME_KANJI, DATE_OF_BIRTH,
	EMAIL, PHONE_NUMBER,
	STREET_ADDRESS_1, STREET_ADDRESS_2, POSTAL_CODE, POSTAL_CODE_KANA, POSTAL_CODE_KANJI, CITY, STATE, COUNTRY,
	SAVED_TUTORS, TUTOR_LIMIT_PER_SESSION,
	IP, BROWSER, PLATFORM
]);

export const CATEGORIES = {
	TEACH: "teach",
	QUESTION: "question",
	ACCOUNT: "account"
};

export const PURPOSE = {
	// for instructional videos
	TOPIC: "topic",
	THUMBNAIL: "thumbnail",
	MENTAL_MODEL: "mental_model",
	ADDITIONAL_RESOURCE: "additional_resources",
	// for questions: there's a good chance this part will change
	QUESTION: "question",
	ANSWER: "answer",
	// for account
	PROFILE_PICTURE: "profile_picture",
	VIDEO_INTRODUCTION: "video_introduction",
	PERSONAL_MISC: "personal_misc"
};

export const VIDEO_TITLE = "videoTitle";
export const VIDEO_DURATION = "videoDuration";
export const VIDEO = "video";
export const THUMBNAIL = "thumbnail";
export const THUMBNAIL_DESCRIPTION = "thumbnailDescription";
export const DESCRIPTION = "description";
export const SUBTITLES = "subtitles";
export const MENTAL_MODELS = "mentalModels";
export const PRICE = "price";
export const TOPIC_ID = "topicID";
export const TOPIC_TYPE = "topicType";
export const CATEGORY = "category";

export const VIDEO_UPLOAD_FORM_DATA = convertArrayToObject([
	VIDEO_TITLE, VIDEO_DURATION, VIDEO,
	THUMBNAIL, THUMBNAIL_DESCRIPTION,
	SUBTITLES, MENTAL_MODELS, PRICE,
	TOPIC_ID, TOPIC_TYPE,
	DESCRIPTION, CATEGORY
]);


export const SCREENING_QUESTIONS_RESPONSE = "screeningQuestionsResponse";
export const OCCUPATION = "occupation";
export const BIO = "bio";
export const NOTIFICATION_PREFERENCE = "notificationPreference";
export const TRIAL_PREFERENCE = "trialPreference";
export const FLUENT_LANGUAGES = "fluentLanguages";
export const TEACHING_RATES = "teachingRates";
export const CANCELLATION_POLICIES = "cancellationPolicies";
export const SCHEDULES = "schedules";
export const RESUME = "resume";
export const TEACHING_CERTIFICATION = "teachingCertification";
export const PROFESSIONAL_EXPERIENCE = "professionalExperience";
export const PROOF_OF_EDUCATION = "proofOfEducation";
export const LINKED_IN_URL = "linkedInUrl";
export const WEBSITE_URL = "webUrl";
export const SUBJECTS_TO_TOPICS_MAP = "subjectsToTopics";
export const ACCEPTED_AGREEMENTS = "acceptedAgreements";

// TODO: update this object to include fields left out from tutor application form
export const TUTOR_PROFILE_FORM_DATA = convertArrayToObject([
	// inactive profile fields
	COUNTRY, SCREENING_QUESTIONS_RESPONSE,
	PROFILE_PICTURE, FIRST_NAME, LAST_NAME,
	RESUME, TEACHING_CERTIFICATION, PROFESSIONAL_EXPERIENCE, PROOF_OF_EDUCATION,
	LINKED_IN_URL, WEBSITE_URL, 
	SUBJECTS_TO_TOPICS_MAP,
	// active profile fields
	OCCUPATION, BIO,
	PHONE_NUMBER, NOTIFICATION_PREFERENCE,
	TRIAL_PREFERENCE, FLUENT_LANGUAGES,
	TEACHING_RATES, CANCELLATION_POLICIES, SCHEDULES,
	ACCEPTED_AGREEMENTS
]);

// personal information
export const URL_BUSINESS_PROFILE = "urlBusinessProfile";
export const GOVT_ID_NUMBER = "govIdNumber";
export const SSN = "ssn";
// payout method
export const CARD_NUMBER = "cardNumber";
export const CARD_EXP_MONTH = "cardExpMonth";
export const CARD_EXP_YEAR = "cardExpYear";
export const CVC = "cvc";
export const BANK_COUNTRY = "bankCountry";
export const CHECKING_ACCOUNT_NUMBER = "checkingAccountNumber";
export const ROUTING_NUMBER = "routingNumber";
// Id & Address document verification
export const ID_DOCUMENT_1 = "idDocument1";
export const ID_DOCUMENT_2 = "idDocument2";
export const ADDRESS_DOCUMENT_1 = "addressDocument1";
export const ADDRESS_DOCUMENT_2 = "addressDocument2";
export const ID_DOCUMENT_NAME = "idDocumentName";
export const ADDRESS_DOCUMENT_NAME = "addressDocumentName";
// TOS
export const IP_TOS = "ipTOS";
export const USER_AGENT_TOS = "userAgentTOS";

export const STRIPE_CONNECT_ACCOUNT_FORM = convertArrayToObject([
	OWNER_ID,
	URL_BUSINESS_PROFILE, GOVT_ID_NUMBER, SSN,
	CARD_NUMBER, CARD_EXP_MONTH, CARD_EXP_YEAR, CVC,
	BANK_COUNTRY, CHECKING_ACCOUNT_NUMBER, ROUTING_NUMBER,
	ID_DOCUMENT_1, ID_DOCUMENT_2, ADDRESS_DOCUMENT_1, ADDRESS_DOCUMENT_2,
	ID_DOCUMENT_NAME, ADDRESS_DOCUMENT_NAME,
	IP_TOS, USER_AGENT_TOS
]);
