import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import defaultLinkAction from "Utilities/LinkActions";
import messageMap from "Utilities/MessageMaps";

import doubleArrowAsset from "assets/icons/navigation/double-arrow.svg";
import pageArrowAsset from "assets/icons/navigation/page-arrow.svg";

/**
 * @description Reusable pagination component
 * @param {Number} itemCount total item count to define pagination window size
 * @param {Number} pageSize number of item per page
 * @param {Number} currentPage current page within page window
 * @param {Number} totalPageCount total number of pages
 * @param {Function} pageHandler page click handler
 */
function Pagination(props) {

	const [pageNumberNavDom, setPageNumberNavDom] = useState(),
		[currentPage, setCurrentPage] = useState(Number(props.currentPage)),
		[pageLink, setPageLink] = useState();

	useEffect(() => {
		createPages();
	}, [currentPage]);

	function createPages() {
		const numberOfPages = props.itemCount / props.pageSize;
		const finalPageNumber = Math.ceil(numberOfPages);
		let leftMostPage = currentPage - 2 > 0 ? currentPage - 2 : 1;
		let rightMostPage = (leftMostPage + 4) <= finalPageNumber ? (leftMostPage + 4) : finalPageNumber;
	
		if (rightMostPage === finalPageNumber) {
			leftMostPage = rightMostPage - 4 > 1 ? rightMostPage - 4 : 1;
		}
	
		let pageNumberNav = [];
	
		for (let i = leftMostPage; i <= rightMostPage; ++i) {
			const pageClassName = i - 1 === currentPage ? "page-number active" : "page-number";
			let appendPage = "?page=";
			if (props.baseUrl.includes("?")) {
				appendPage = "&page=";
			}
			setPageLink(appendPage);
	
			pageNumberNav.push(
				<Link to={`${props.baseUrl}${appendPage}${i}`} className={pageClassName}
					onClick={e => scrollToTopOfPage(e, i - 0, props.totalPageCount)}
					key={`item-${i}-page-${currentPage}`} page={i} >
					{i}
				</Link>
			);
		}

		setPageNumberNavDom(pageNumberNav);
	}

	function scrollToTopOfPage(e, curPage, totalPageCount) {
		defaultLinkAction();
		setCurrentPage(Number(e.target.innerText) - 1);

		props.pageHandler(curPage, totalPageCount);
	}

	function goToFirstLastPage(firstOrLast) {
		defaultLinkAction();
		setCurrentPage(firstOrLast === "first" ? 0 : props.totalPageCount - 1);
		props.pageHandler(firstOrLast === "first" ? 1 : props.totalPageCount, props.totalPageCount);
	}

	function goToPrevNextPage(e, prevNext) {
		if ((prevNext === "next" && currentPage + 1 < props.totalPageCount)
			|| (prevNext === "prev" && currentPage - 1 > -1)) {
			const newPage = prevNext === "next" ? currentPage + 1 : currentPage - 1;

			setCurrentPage(newPage);
			defaultLinkAction();
			props.pageHandler(newPage + 1, props.totalPageCount);
		}
		else {
			e.preventDefault();
		}
	}

	return (
		<div className="pagination">
			<Link to={`${props.baseUrl}${pageLink}${1}`} className="first-last first"
				onClick={e => goToFirstLastPage("first")}>
				<img src={doubleArrowAsset} alt={messageMap("navigation.first", "image")}
					className="first-last-arrow-icon"></img>
			</Link>
			<Link to={`${props.baseUrl}${pageLink}${Number(currentPage)}`} className="left"
				onClick={e => goToPrevNextPage(e, "prev")}>
				<img src={pageArrowAsset} alt={messageMap("navigation.previous", "image")}
					className="left-arrow-icon"></img>
			</Link>
			{pageNumberNavDom}
			<Link to={`${props.baseUrl}${pageLink}${Number(currentPage) + 2}`} className="right"
				onClick={e => goToPrevNextPage(e, "next")}>
				<img src={pageArrowAsset} alt={messageMap("navigation.next", "image")}
					className="right-arrow-icon"></img>
			</Link>
			<Link to={`${props.baseUrl}${pageLink}${props.totalPageCount}`} className="first-last last"
				onClick={e => goToFirstLastPage("last")}>
				<img src={doubleArrowAsset} alt={messageMap("navigation.last", "image")}
					className="first-last-arrow-icon rotate"></img>
			</Link>
		</div>
	);
}

Pagination.propTypes = {
	baseUrl: PropTypes.string.isRequired,
	itemCount: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	totalPageCount: PropTypes.number.isRequired,
	pageHandler: PropTypes.func.isRequired
};

export default Pagination;