import React from "react";
import PropTypes from "prop-types";


function Pane(props) {

	return (
		<div className="pane-container">
			<div className={`pane ${props.customClass}`}>
				{props.children}
			</div>
			<div className={`background-pane ${props.customClass}`}></div>
		</div>
	);
}

Pane.propTypes = {
	children: PropTypes.any.isRequired,
	customClass: PropTypes.string
};

export default Pane;
